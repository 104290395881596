import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { Package } from 'app/_models/package';
import { DialogResultComponent } from 'app/_dialogs/dialog-result';
import { CreateFromStandardHelper } from 'app/_helpers/get-from-standard-helper';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageService } from 'app/_services/package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';

@Component({
  selector: 'app-standard-set-description',
  templateUrl: './set-description.component.html',
  styleUrls: ['./set-description.component.scss']
})
export class StandardSetDescriptionComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  currentPack: Package;
  currentSetId: number = 0;
  currentPackageName: string = '';
  currentPackageDescription: string = '';
  activeCompanyId: number = 0;
  isGottenPack: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private createFromStandardHelper: CreateFromStandardHelper,
    private packageService: PackageService,
    private authService: AuthenticationService,
    private userService: UserService,
    public  dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.sharedService.currentStandardSetTabId.next(0);

    this.sharedService.currentCompanyId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyId: number) => {
        this.activeCompanyId = companyId;
      });

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((setId: number) => {
        this.currentSetId = setId;
      });

    this.sharedPackageService.currentPackageName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageName: string) => {
        this.currentPackageName = packageName;
      });

    this.sharedPackageService.currentPackageDescription$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageDescription: string) => {
        this.currentPackageDescription = packageDescription;
      });
    this.getPackageInfo();
    this.getUserInfo();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        },
          error => {
            this.sharedService.checkSignatureExpired(error);
          });
    }
  }

  getPackageInfo(): void {
    const packageId = this.currentSetId;
    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(packageInfo => {
            this.currentPack = packageInfo;
          },
          error => {
            console.log('Package info', error);
          });
    }
  }

  copyPublicPackage(): void {
    if (this.currentPack) {
      const dialogRef = this.dialog.open(DialogResultComponent, {
        data: {
          dialogType: 'CopyPublicPackage',
          defaultName: this.currentPackageName,
          // defaultName: this.currentPack.name,
        },
      });

      dialogRef.afterClosed()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(newName => {
          this.isGottenPack = true;
          if (newName) {
            const companyId = this.activeCompanyId;
            this.createFromStandardHelper.addNewLocalPackage(newName, companyId, this.currentPack);
            this.sharedPackageService.copiedStandardPackId$.next(this.currentPack.id);
            this.router.navigateByUrl(`/`)
              .then(() => {
                this.router.navigateByUrl(`/questionnaires`);
              });
          }
        },
          error => console.log(error));
    }
  }

  toSettingsPage(): void {
    const setId = this.currentSetId;
    this.router.navigateByUrl(`/standard-sets/${setId}/settings`);
  }

}
