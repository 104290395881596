<div *ngIf="defaultMode" class="dialog-style">
  <h2 align="center">Enter {{dialogType}}</h2>

  <mat-form-field class="card-full-width">
    <input matInput placeholder={{dialogType}} [(ngModel)]="name" />
  </mat-form-field>

  <p></p>

  <div class="buttons-box">
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close(name)">
        OK
    </button>
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
        Cancel
    </button>
  </div>
</div>

<!-- <div *ngIf="copyPublicPackageMode" class="dialog-style"> -->
<div *ngIf="copyPublicPackageMode">
  <h2 align="center" i18n="@@enterName-questionnaire">Enter Questionnaire Title</h2>

  <mat-form-field class="card-item">
    <mat-placeholder class="placeholder" i18n="@@placeholder-packageName">Questionnaire Title</mat-placeholder>
    <input matInput [(ngModel)]="packageName" />
  </mat-form-field>

  <p></p>

  <div class="buttons-box">
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close(packageName)">
        OK
    </button>
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
        Cancel
    </button>
  </div>
</div>

<div *ngIf="withDescription" class="dialog-style">
  <h2 align="center" i18n="@@enterName-description">Enter name and description</h2>

  <mat-form-field *ngIf="withDescription" class="card-full-width">
    <input matInput placeholder="name" [(ngModel)]="description[0]" />
  </mat-form-field>

  <mat-form-field class="card-full-width">
    <input matInput placeholder="description" [(ngModel)]="description[1]" />
  </mat-form-field>

  <p></p>

  <div class="buttons-box">
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close(description)">
        OK
    </button>
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
        Cancel
    </button>
  </div>
</div>

<div *ngIf="changeCompanyMode" mat-dialog-content class="dialog-style">
  <h2 class="title" i18n="@@chooseCompany">Choose company</h2>

  <div *ngFor="let company of availableCompanies">
    <div (click)="chooseCompany(company)" class="raisedbox">
      <div [class]="isCompanyChosen(company) ? 'selected' : ''">
          <img *ngIf="company.companyLogoUrl" [src]='company.companyLogoUrl' class="company-logo-block" />
          <div *ngIf="!company.companyLogoUrl">
            <span class="company-logo-block initials-block">{{company.name | slice : 0 : 1}}</span>
          </div>
        <span class="company-name">{{company.name}}</span>
        <span *ngIf="isCompanyChosen(company)" class="icon-box">
          <mat-icon>check_circle</mat-icon>
        </span>
        <span *ngIf="!isCompanyChosen(company)" class="icon-box">
          <mat-icon>radio_button_unchecked</mat-icon>
        </span>
      </div>
    </div>
  </div>

  <div class="buttons-box two-buttons">
    <button [disabled]="!chosenCompanyExist" type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close(chosenCompanyId)">
        OK
    </button>
    <button type="button" mat-raised-button color="primary"
      class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
        Cancel
    </button>
  </div>
</div>

<div *ngIf="copyItemMode" class="dialog-style">
  <h2 align="center">Copy to a new tab</h2>

  <section (click)="toggleCopyTags(); $event.preventDefault();">
    <mat-checkbox [checked]="copyWithTags">Copy with tags</mat-checkbox>
  </section>

  <p></p>

  <section (click)="toggleCopyAttachments(); $event.preventDefault();">
    <mat-checkbox [checked]="copyWithAttachments">Copy with attachments</mat-checkbox>
  </section>

  <p></p>

  <mat-form-field class="card-full-width">
    <input
      matInput placeholder="New Tab"
      [matAutocomplete]="auto"
      [formControl]="stateCtrl"
      [(ngModel)]="copyItemName" />
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let tab of filteredTabs | async" [value]="tab">
      {{ tab }}
    </mat-option>
  </mat-autocomplete>

  <div class="buttons-box">
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="tryCopyItem()">
        OK
    </button>
    <button type="button" mat-raised-button color="accent"
      class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
        Cancel
    </button>
  </div>
</div>

<div *ngIf="addDepartment" class="dialog-style">
  <h2 align="center" i18n="@@createDepartment">Add department</h2>

  <mat-form-field class="card-full-width">
    <input matInput placeholder="Department name" [(ngModel)]="name" />
  </mat-form-field>

  <p></p>

  <div class="buttons-box">
    <button type="button" mat-raised-button color="accent"
            class="single-button-style" (click)="dialogRef.close(name)" i18n="@@createButton">
      ADD
    </button>
    <button type="button" mat-raised-button color="accent"
            class="single-button-style" (click)="dialogRef.close()" i18n="@@cancelButton">
      Cancel
    </button>
  </div>
</div>