<div class="header-text">
  YOUR CURRENT CONFIGURATION:
</div>

<div class="items-list" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropConfigItem($event)">
  <div class="config-item" cdkDrag *ngFor="let item of configItems; let idItem = index;">
    <div style="display: flex;flex-direction: row;">
      <div>
        <span>Tab</span> #{{idItem + 1}}: {{item.name}}
      </div>
      <div class="buttons-group">
        <span class="example-handle" cdkDragHandle>
          <svg width="18px" fill="#61728d" viewBox="0 0 24 24">
            <path
            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>

        </span>
      </div>
    </div>
  </div>
</div>

<div class="header-text">
  Available Pages:
</div>

<div class="items-list">
  <div class="config-item"
        *ngFor="let item of availableItems; let idItem = index; let odd=odd; let even=even;">
      <div>
        {{item.name}}
      </div>
      <div class="buttons-group">
        <mat-checkbox [(ngModel)]="item.enabled" (change)="configUpdateItem(item)" name="policy">
        </mat-checkbox>
      </div>
  </div>
</div>