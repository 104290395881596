import { Length, IsEmail } from 'class-validator';

export class ColleagueValidation {
  @Length(1, 20, {
    message: 'First Name should be from 1 to 20 characters'
  })
  firstName: string;

  @Length(1, 20, {
    message: 'Last Name should be from 1 to 20 characters'
  })
  lastName: string;

  @IsEmail({},
    { message: 'Invalid email format'
  })
  email: string;
}
