import {
  Component, OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Package } from 'app/_models/package';
import { UtilsService } from 'app/_services/utils.service';
import { PackageService } from 'app/_services/package.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';
import { SharedUserService } from 'app/_services/shared-user.service';
import { User } from 'app/_models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as QuillNamespace from 'quill';

const Quill: any = QuillNamespace;

@Component({
  selector: 'app-packages-create-package',
  templateUrl: './packages-create-package.component.html',
  styleUrls: ['./../packages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PackagesCreatePackageComponent implements OnInit {
  packageName: string = '';
  packageDescription: string = '';
  owner: boolean = false;
  activeCompanyId: number = 0;
  currentLanguage: string = '';

  editorForm: FormGroup;
  editorEnabled: boolean = false;
  config: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': ['', 'center', 'right'] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private packageService: PackageService,
    private amplitudeService: AmplitudeEventsService,
    private sharedUserService: SharedUserService,
    private sharedPackageService: SharedPackageService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) {
    this.editorForm = this.formBuilder.group({
      editor: ['', Validators.required]
    });
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.currentLanguage = this.utilsService.getCurrentLanguage();
  }


  getUserInfo(): void {
    this.sharedUserService.currentUser$
      .pipe(
        filter(user => user !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((user: User) => {
        this.activeCompanyId = user.activeCompanyId;
      },  error => {
        console.log('User info', error);
      });
  }

  addNewPackage(name: string, description: string): void {
    if (name && name.length > 0) {
      this.packageService.addPackage(name, description, this.activeCompanyId, this.currentLanguage)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((pack: Package) => {
          this.amplitudeService.addEvent('add custom questionnaire');
          this.sharedCompanyDataService.packages$.next([]);
          this.sharedPackageService.onRefreshPackage$.emit();
          this.addNewColleagueTableConfig(pack.id);
        }, error => {
          console.log(error);
        });
    }
  }

  addNewColleagueTableConfig(packageId: number): void {
    this.packageService.addColleagueTableConfig(packageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.onChangeMode();
      });
  }

  onSubmit(): void {
    this.packageDescription = this.editorForm.get('editor').value;
    this.addNewPackage(this.packageName, this.packageDescription);
  }

  onChangeMode(): void {
    this.router.navigate(['/questionnaires']);
  }

  maxLength(e: any): void {    // should it be limited?
    if (e.editor.getLength() > 1000) {
      console.log('max length 1000!');
      // e.editor.deleteText(1000, e.editor.getLength());
    }
  }
}
