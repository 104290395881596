<div class="authorization">
  <div *ngIf="!hideForm" class="authorization__container">

    <div class="logo-block">
      <img src="/assets/overmind_icons/logo.svg" alt="Logotype"/>
      <span class="logo-block__title">Teamheat</span>
    </div>

    <h2 class="login title" i18n="@@loginPage-reset-title">Reset Password</h2>

    <mat-card class="authorization__wrapper">
      <form class="form form--reset" name="form" (ngSubmit)="f.form.valid && onValidate()" #f="ngForm" autocomplete="off" novalidate>

        <div class="form__fields">
          <mat-form-field class="card-item">
            <input matInput placeholder="Email" class="form-control" name="email" [(ngModel)]="model.email"
                    #email="ngModel" required/>
          </mat-form-field>
        </div>

        <div class="form-group buttons-box">
          <div class="buttons-box__item">
            <button 
              [disabled]="loading || !model.email" 
              mat-raised-button 
              color="primary" 
              class="control-element" 
              i18n="@@loginPage-sendInstruction"
              type="submit">
                Send instructions
            </button>
            <button 
              [disabled]="loading" 
              mat-raised-button 
              color="primary" 
              class="control-element"
              (click)="toLoginPage()" 
              i18n="@@loginPage-title"
              type="button">
                Login page
            </button>
          </div>
        </div>

        <div *ngIf="infoBar.length > 0" class="alert alert-danger">
          <div *ngFor="let error of infoBar; let i = index;">
            {{error}}
          </div>
        </div>

      </form> <!-- /.form -->
    </mat-card> <!-- /.authorization__wrapper -->
  </div> <!-- /.authorization__container -->

  <div *ngIf="hideForm">
    <div class="message">
      <mat-card>
        <p i18n="@@loginPage-instructionMessage">Instructions were sent by email</p>
      </mat-card>
    </div>
  </div>
</div>