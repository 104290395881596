import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { validate } from 'class-validator';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AuthenticationService } from 'app/_services/authentication.service';
import { SharedService } from 'app/_services/shared.service';
import { UserService } from 'app/_services/user.service';
import { CompanyService } from 'app/_services/company.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { UserValidation } from './user-validation';

class RegistrationUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../authorization.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  model: RegistrationUser;
  loading: boolean = false;
  hideForm: boolean = false;
  isCreateEnabled: boolean = false;
  policyChecked: boolean = false;
  // error: string = '';
  infoBar: string[] = [];

  constructor(
    @Inject(LOCALE_ID) protected localeId: string,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private amplitudeService: AmplitudeEventsService) { }

  ngOnInit(): void {
    const model: RegistrationUser = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: ''
    };

    // console.log('locale? ', this.localeId);
    this.checkLocale();

    this.model = model;
  }

  checkLocale(): void {
    if ((this.localeId !== 'ru') && (this.localeId !== 'en')) {
      this.localeId = 'en';
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toLoginPage(): void {
    this.router.navigate(['/login']);
  }

  checkCreateEnabled(event: any): void {
    const firstName = this.model.firstName;
    const lastName = this.model.lastName;
    const email = this.model.email;
    const password = this.model.password;
    const passwordConfirmation = this.model.passwordConfirmation;

    if ((firstName) && (lastName) && (email) && (password)
      && (passwordConfirmation)) {
      this.isCreateEnabled = true;
    } else {
      this.isCreateEnabled = false;
    }
  }

  signup(): void {
    this.infoBar = [];
    this.loading = true;
    const item = this.model;
    if (item.password !== item.passwordConfirmation) {
      this.loading = false;
      this.infoBar.push('Passwords don\'t match');
    } else {
      this.onValidate();
    }
  }

  onValidate(): void {
    const newUserValidation = new UserValidation();
    newUserValidation.firstName = this.model.firstName;
    newUserValidation.lastName = this.model.lastName;
    newUserValidation.email = this.model.email;
    newUserValidation.password = this.model.password;

    validate(newUserValidation).then(errors => {
      if (errors.length > 0) {
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
      } else {
        // this.onUpdateAction();
        this.addNewUser();
        console.log('validation succeed');
      }
    });
  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  addNewUser(): void {
    const item = this.model;
    this.userService.addNewUser(item.firstName, item.lastName, item.email, item.password)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultMessage: any) => {
        this.hideForm = true;
        this.amplitudeService.addEvent('standard registration');
        this.login(item.email, item.password);
      },
      error => {
        console.log(error);
        this.infoBar.push(`Error when creating a new user.
          Please, make sure that your email address is not used or contact the site owner`);
        this.loading = false;
      });
  }

  login(email: string, password: string): void {
    this.authService.login(email, password)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result === true) {
          const newName = `${email}'s Company`;
          this.createDefaultCompany(newName);
        }
      },
      error => {
        console.log('ERROR ', error);
      });
  }

  createDefaultCompany(newCompanyName: string): void {
    if (newCompanyName) {
      const companyInfo = {
        name: newCompanyName,
        packages: [],
        logo: '',
      };

      this.companyService.addNewCompany(companyInfo)
        .pipe(
          switchMap(company => this.userService.setActiveCompany(company.id)),
          takeUntil(this.ngUnsubscribe)
        ).subscribe((activeCompany) => {
          this.sharedService.currentCompanyId.next(activeCompany.id);
          this.sharedService.onLogin();
          this.sharedService.loginMode$.emit(false);
          this.router.navigate(['/home/']);
        },
        error => {
          console.log(error);
        });
    }
  }

}
