import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Package } from 'app/_models/package';
import { DialogResultComponent } from 'app/_dialogs/dialog-result';
import { SnackBarComponent } from 'app/snack-bar';
import { CreateFromStandardHelper } from 'app/_helpers/get-from-standard-helper';

import { DialogHowToComponent } from 'app/_dialogs/dialog-how-to/dialog-how-to.component';
import { PackageService } from 'app/_services/package.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { AdvancedAdminConfig } from 'app/_models/advanced-admin-config';

class CategoryPackage {
  categoryName: string;
  publicPackages: Package[];
}

@Component({
  selector: 'app-standard-sets',
  templateUrl: './standard-sets.component.html',
  styleUrls: ['./standard-sets.component.scss', './../packages.component.scss'],
  providers: [SnackBarComponent]
})
export class PackagesStandardSetsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  publicPackages: Package[] = [];
  owner: boolean = false;
  activeCompanyId: number = 0;
  isGottenPack: boolean = false;
  isRussianLocale: boolean = false;
  categoryPackages: CategoryPackage[] = [];
  templatesOrderString: string = '';
  advancedConfig: AdvancedAdminConfig;

  constructor(
    private router: Router,
    private packageService: PackageService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private createFromStandardHelper: CreateFromStandardHelper,
    private authService: AuthenticationService,
    private userService: UserService,
    private snackBar: SnackBarComponent,
    public  dialog: MatDialog) { }

  ngOnInit(): void {
    this.checkLoggedIn();
    this.getAdvancedAdminConfig();

    this.sharedService.currentCompanyId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: number) => {
        this.activeCompanyId = value;
        this.checkCurrentLocale();
      });

    this.sharedPackageService.onCreateFromStandard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        // this.openSnackBar('New package was created');
        this.router.navigateByUrl(`/`)
          .then(() => {
            this.sharedService.currentTabId.next(0);
            this.router.navigateByUrl(`/questionnaires`);
          });
      });

    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onHowToDialog(): void {
    this.dialog.open(DialogHowToComponent, {
      data: {
        videoType: 'addStandardPackage'
      }
    });
  }

  checkLoggedIn(): void {
    const user = localStorage.getItem('currentUser');

    if (user) {
      const email = JSON.parse(user).email;

      this.sharedService.currentUser = {'email': email, 'canCreate': false,
        'canRead': true, 'canUpdate': false, 'canDelete': false};

      if (!this.sharedService.onLoginEmitted) {
        this.sharedService.onLogin$.emit();
        this.sharedService.onLoginEmitted = true;
      }
    }
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          const currentLocale = resultUser.currentLocale;
          if (currentLocale) {
            this.sharedService.currentLang.next(currentLocale);
            this.checkCurrentLocale();
          }
          // const lang = this.sharedService.currentLang.getValue();
          this.getPublicPackages(currentLocale);
        },
          error => {
            this.sharedService.checkSignatureExpired(error);
          });
    }
  }

  checkCurrentLocale(): void {
    const lang = this.sharedService.currentLang.getValue();
    if (lang === 'ru') {
      this.isRussianLocale = true;
    } else {
      this.isRussianLocale = false;
    }
  }

  getAdvancedAdminConfig(): void {
    this.userService.getAdvancedAdminConfig(1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((advancedConfig: AdvancedAdminConfig) => {
        if (advancedConfig) {
          this.advancedConfig = advancedConfig;
        }
      }, error => console.log(error));
  }

  getPublicPackages(currentLocale: string): void {
    // const categoryNames = [];
    this.packageService.getPublicPackagesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packList: Package[]) => {
          const filteredPackages = packList.filter(pack => pack.language === currentLocale);
          this.publicPackages = [];
          if (filteredPackages) {
            for (const singlePack of filteredPackages) {
              this.updateCategoryPackages(singlePack);
            }
            this.sortCategoryPackages();
          }
        },
        error => {
          console.log('Error with package ', error);
        });
  }

  // formCategories(): void {
  //   const newCategory: CategoryPackage = {
  //     categoryName: publicPackage.categoryName,
  //     publicPackages: newPackages
  //   };
  //   this.categoryPackages.push(newCategory);
  // }

  updateCategoryPackages(publicPackage: Package): void {
    if (publicPackage.categoryName.length > 0) {
      const category = this.categoryPackages.find(catPackage => catPackage.categoryName === publicPackage.categoryName);
      if (category) {
        category.publicPackages.push(publicPackage);
      } else {
        const newPackages = [];
        newPackages.push(publicPackage);
        const newCategory: CategoryPackage = {
          categoryName: publicPackage.categoryName,
          publicPackages: newPackages
        };
        this.categoryPackages.push(newCategory);
      }
    } else {
      let noCategoryName = 'No category';
      if (this.isRussianLocale) {
        noCategoryName = 'Без категории';
      }

      const category = this.categoryPackages.find(catPackage => catPackage.categoryName === noCategoryName);
      if (category) {
        category.publicPackages.push(publicPackage);
      } else {
        const newPackages = [];
        newPackages.push(publicPackage);
        const newCategory: CategoryPackage = {
          categoryName: noCategoryName,
          publicPackages: newPackages
        };
        this.categoryPackages.push(newCategory);
      }

    }
  }

  sortCategoryPackages(): void {
    this.categoryPackages.sort(function (a, b) {
      if (a.categoryName > b.categoryName) {
        return 1;
      }
      if (a.categoryName < b.categoryName) {
        return -1;
      }
      return 0;
    });

    const orderEn = this.advancedConfig.templatesOrderRu;
    let newOrder = orderEn.split(',');
    if (this.isRussianLocale) {
      const orderRu = this.advancedConfig.templatesOrderRu;
      newOrder = orderRu.split(',');
    }

    const orderedCategories = [];
    for (const singleItem of newOrder) {
      const categoryName = singleItem.trim();
      if (categoryName) {
        const findCategory = this.categoryPackages.find(item => item.categoryName === categoryName);
        if (findCategory) {
          orderedCategories.push(findCategory);
        }
        this.categoryPackages = this.categoryPackages.filter(function (item) {
          return item.categoryName !== categoryName;
        });
      }
    }
    for (const singleItem of this.categoryPackages) {
      orderedCategories.push(singleItem);
    }

    this.categoryPackages = orderedCategories;
  }

  openPackage(pack: Package): void {
    if (pack) {
      this.router.navigateByUrl(`/`)
        .then(() => {
          this.sharedPackageService.currentPackageId$.next(pack.id);
          this.sharedPackageService.currentPackageName$.next(pack.name);
          this.sharedService.scaleFeatureEnabled.next(pack.hasScale);
          this.sharedPackageService.onPackageSelect$.emit();
          const packId = pack.id;
          this.router.navigateByUrl(`/standard-sets/${packId}/description`);
        });
    }
  }

  // -------------------------
  // Copy public package logic
  // -------------------------

  copyPublicPackage(pack: Package): void {
    const dialogRef = this.dialog.open(DialogResultComponent, {
      data: {
        dialogType: 'CopyPublicPackage',
        defaultName: pack.name,
      },
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newName: string) => {
          this.isGottenPack = true;
          if (newName) {
            this.snackBar.openWithTimeout('New package is creating, please wait...', 50000);
            const companyId = this.activeCompanyId;
            this.createFromStandardHelper.addNewLocalPackage(newName, companyId, pack);
            // this.addNewLocalPackage(newName, pack);
            this.sharedPackageService.copiedStandardPackId$.next(pack.id);
          }
        },
        error => console.log(error));
  }

  getFirstParagraphDescription(description: string): string {
    if (description) {
      const firstLineTag = description.split('>');
      const firstTag = firstLineTag[0].split(' ');
      const closingTag = '</' + firstTag[0].split('<')[1] + '>';

      return description.split(closingTag)[0] + closingTag;
    } else {
      return '';
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
