import { Length, IsEmail } from 'class-validator';

export class UserValidation {
  @Length(4, 20, {
    message: 'First Name should be from 4 to 20 characters'
  })
  firstName: string;

  @Length(4, 20, {
    message: 'Last Name should be from 4 to 20 characters'
  })
  lastName: string;

  @IsEmail({},
    { message: 'Invalid email format'
  })
  email: string;

  @Length(6, 30, {
    message: 'Password should be from 6 to 30 characters'
  })
  password: string;
}
