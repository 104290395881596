<div class="mat-elevation-z4">
  <table *ngIf="dataSource" mat-table [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container [matColumnDef]="column" *ngFor="let column of displayColumnsNames; let i = index">
      <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" [index]="i">
        <span>{{displayColumnsLabels[column]}}</span>
      </th>
      <td mat-cell *matCellDef="let cell" class="chart align-top">
        <!-- <span (dblclick)=onCellClick(cell[column])>{{ cell[column] }}</span> -->
        <!-- <span 
          [innerHtml]="cell[column] | safeHtml"></span> -->
        <div [innerHtml]="cell[column] | safeHtml">
        </div>
        
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumnsNames; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumnsNames;" 
      class="example-element-row" 
      [class.example-expanded-row]="expandedRow === row"
      [ngClass]="getRowClass(row)"
      (click)="expandedRow = expandedRow === row ? null : row">
    </tr>
  </table>
  <ng-container *ngIf="!isVerboseMode">
    <button
      mat-raised-button
      color="accent"
      class="button-load-more"
      (click)="loadMore()"
      i18n="@@loadMore">
        Load more
    </button>
  </ng-container>
</div>