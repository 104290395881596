import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar/snack-bar.component';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';

import { CompanyService } from 'app/_services/company.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { ParameterVisibility } from 'app/_models/parameter-visibility';
import { ExtendedColleague, ExtendedDepartment } from '../local-models';
import { ColleagueTableParameter } from 'app/colleague-profile/local-models';
import { CompanyDataService } from 'app/_services/company-data.service';


@Component({
  selector: 'app-colleagues',
  templateUrl: './colleagues.component.html',
  styleUrls: ['./colleagues.component.scss', '../colleagues-page.component.scss']
})
export class ColleaguesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  colleaguesList: ExtendedColleague[] = [];
  departmentsList: ExtendedDepartment[] = [];
  defaultColleaguesList: ExtendedColleague[] = [];
  archivedColleaguesList: ExtendedColleague[] = [];
  parameterVisibilities: ParameterVisibility[] = [];
  colleaguesParameters: ColleagueTableParameter[] = [];
  isNonDepartmentColleaguesExist: boolean = false;

  colleagueSearch: string = '';
  isLoading: boolean = true;
  isCompanyEmpty: boolean = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarComponent,
    private companyService: CompanyService,
    private companyDataService: CompanyDataService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.checkIfCompanyEmpty();
    this.getColleaguesList();
    this.getArchivedColleaguesList();
    this.getColleaguesParameters();
    this.getDepartmentsList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkIfCompanyEmpty(): void {
    this.sharedCompanyDataService.isCompanyEmpty$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isCompanyEmpty: boolean) => {
        this.isCompanyEmpty = isCompanyEmpty;
      });
  }

  getColleaguesList(): void {
    this.sharedCompanyDataService.colleaguesList$
      .pipe(
        filter(colleaguesList => colleaguesList && colleaguesList.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleaguesList: ExtendedColleague[]) => {
        this.colleaguesList = colleaguesList;
        this.defaultColleaguesList = colleaguesList;
        this.isLoading = false;
        this.checkNonDepartmentColleagues(colleaguesList);
      });
  }

  getArchivedColleaguesList(): void {
    this.sharedCompanyDataService.archivedColleaguesList$
      .pipe(
        filter(archivedColleaguesList => archivedColleaguesList !== null && archivedColleaguesList.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((archivedColleaguesList: Colleague[]) => {
        this.archivedColleaguesList = archivedColleaguesList;
      });
  }

  getColleaguesParameters(): void {
    this.sharedCompanyDataService.colleagueParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parametersArray: ColleagueTableParameter[]) => {
        if (parametersArray && parametersArray.length > 0) {
          this.colleaguesParameters = [];
          parametersArray.forEach((parameters) => {
            this.colleaguesParameters.push(parameters);
          });

          this.getParameterVisibilities();
        }
      });
  }

  getParameterVisibilities(): void {
    this.sharedCompanyDataService.parameterVisibilities$
      .pipe(
        filter(parameterVisibilities => parameterVisibilities && parameterVisibilities.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((parameterVisibilities: ParameterVisibility[]) => {
        this.parameterVisibilities = parameterVisibilities;
        this.setParametersToColleagues();
      });
  }

  setParametersToColleagues(): void {
    this.parameterVisibilities.forEach((parameterVisibility) => {
      this.colleaguesParameters.forEach((parameter) => {
        if (parameter.packageParameterId === parameterVisibility.packageParameterId) {
          parameter.onColleagueCard = parameterVisibility.onColleagueCard;
        }
      });
    });

    this.colleaguesParameters = this.colleaguesParameters.filter(parameter => parameter.onColleagueCard);

    this.colleaguesList.forEach((colleague) => {
      colleague.parameters = this.colleaguesParameters.filter(parameter => parameter.colleagueId === colleague.id);
    });
  }

  getDepartmentsList(): void {
    this.sharedCompanyDataService.departmentsList$
      .pipe(
        filter(departmentsList => departmentsList !== null && departmentsList.length > 0),
        takeUntil(this.ngUnsubscribe),
        first()
      )
      .subscribe((departmentsList: Department[]) => {
        departmentsList.forEach((department) => {
          const newDepartment: ExtendedDepartment = department;
          newDepartment.isSelected = true;
          this.departmentsList.push(newDepartment);
        });
      });
  }

  onSelectDepartment(department: ExtendedDepartment): void {
    this.departmentsList.find(dep => dep.id === department.id).isSelected = !department.isSelected;
    this.colleaguesList = [];

    this.defaultColleaguesList.forEach((colleague) => {
      const colleagueDepartment: ExtendedDepartment = this.departmentsList.find(param => param.id === colleague.departmentId);

      if (colleagueDepartment && colleagueDepartment.isSelected) {
        this.colleaguesList.push(colleague);
      }

      if (colleague.departmentId === 0) {
        this.colleaguesList.push(colleague);
      }
    });
  }

  onArchiveColleague(colleague: Colleague): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Archive colleague?',
        dialogSubtitle: 'Are you sure you want to archive colleague?',
        dialogConfirmText: 'Archive',
        dialogConfirmType: 'archive',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.archiveColleague(colleague);
      }
    });
  }

  archiveColleague(colleague: Colleague): void {
    const colleagueInfo = {
      archived: true,
    };

    colleague.archived = true;

    this.companyService.updateColleague(colleague.id, colleagueInfo)
      .subscribe(() => {
        this.colleaguesList = this.colleaguesList.filter(param => param.id !== colleague.id);
        if (this.colleaguesList.length === 0) {
          this.isCompanyEmpty = true;
        }
        this.archivedColleaguesList.push(colleague);
        this.sharedCompanyDataService.updateArchivedColleaguesList(this.archivedColleaguesList);
        this.sharedCompanyDataService.updateColleaguesList(this.colleaguesList);
        this.openSnackBar('Colleague archived');
      },
      error => {
        this.openSnackBar('Can\'t delete');
        console.log('Colleagues info', error);
      });
  }

  onColleagueSearch(): void {
    const colleagueSearch = this.colleagueSearch.toLowerCase();

    if (colleagueSearch !== '') {
      this.colleaguesList = this.defaultColleaguesList.filter(param => param.firstName.toLowerCase().includes(colleagueSearch) ||
      param.lastName.toLowerCase().includes(colleagueSearch));
    } else {
      this.colleaguesList = this.defaultColleaguesList;
    }
  }

  checkNonDepartmentColleagues(colleagues: ExtendedColleague[]): void {
    const nonDeparmentColleagues = colleagues.filter(colleague => colleague.departmentId === 0);

    if (nonDeparmentColleagues.length > 0) {
      this.isNonDepartmentColleaguesExist = true;
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
