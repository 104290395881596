import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-package-config',
  templateUrl: './package-config.component.html',
  styleUrls: ['./package-config.component.scss']
})
export class PackageConfigComponent implements OnInit {

  // configItems: any = ['Dashboard', 'Questions', 'Parameters', 'Participants', 'Researches', 'Settings', 'Slack integration'];
  configItems: any = [];
  availableItems: any = [
    { name: 'Dashboard', enabled: true },
    { name: 'Questions', enabled: true },
    { name: 'Parameters', enabled: true },
    { name: 'Participants', enabled: true },
    { name: 'Researches', enabled: true },
    { name: 'Settings', enabled: true },
    { name: 'Slack integration', enabled: false },
  ];

  constructor() { }

  ngOnInit(): void {
    this.configUpdate();
  }

  configUpdate(): void {
    const newConfig = [];
    for (const singleItem of this.availableItems) {
      if (singleItem['enabled'] === true) {
        newConfig.push(singleItem);
      }
    }

    this.configItems = newConfig;
  }

  configUpdateItem(item: any): void {
    if (item.enabled === true) {
      const findItem = this.configItems.find(configItem => configItem.name === item.name);
      if (!findItem) {
        this.configItems.push(item);
      }
    } else {
      const newConfig = this.configItems.filter(configItem => configItem.name !== item.name);
      this.configItems = newConfig;
    }
  }

  dropConfigItem(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.configItems, event.previousIndex, event.currentIndex);
    const firstCard = this.configItems[event.previousIndex];
    const secondCard = this.configItems[event.currentIndex];

    // this.updateCardPosition(firstCard, event.previousIndex + 1);
    // this.updateCardPosition(secondCard, event.currentIndex + 1);
  }

  editItem(item: any): void {
    // console.log('Edit item ', item);
  }

}
