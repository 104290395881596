import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { ApiSharedDashboardWidget } from 'app/_api_models/api-shared-dashboard-widget';
import { environment } from '../../environments/environment';

export function parseApiSharedDashboardWidget(apiResponse: any): SharedDashboardWidget {
  let sharedDashboardWidgetObject;
  if (environment.production) {
    sharedDashboardWidgetObject = apiResponse;
  } else {
    sharedDashboardWidgetObject = ApiSharedDashboardWidget.check(apiResponse);
  }

  const widgetFilters = {};
  if (sharedDashboardWidgetObject.widget_filters.active_colleagues_ids) {
    widgetFilters['activeColleaguesIds'] = sharedDashboardWidgetObject.widget_filters.active_colleagues_ids;
  }
  if (sharedDashboardWidgetObject.widget_filters.active_parameters_ids) {
    widgetFilters['activeParametersIds'] = sharedDashboardWidgetObject.widget_filters.active_parameters_ids;
  }
  if (sharedDashboardWidgetObject.widget_filters.active_research_id) {
    widgetFilters['activeResearchId'] = sharedDashboardWidgetObject.widget_filters.active_research_id;
  }
  if (sharedDashboardWidgetObject.widget_filters.active_package_id) {
    widgetFilters['activePackageId'] = sharedDashboardWidgetObject.widget_filters.active_package_id;
  }
  if (sharedDashboardWidgetObject.widget_filters.sort_parameter_id) {
    widgetFilters['sortParameterId'] = sharedDashboardWidgetObject.widget_filters.sort_parameter_id;
  }
  if (typeof (sharedDashboardWidgetObject.widget_filters.show_parameters_names) !== 'undefined') {
    widgetFilters['showParametersNames'] = sharedDashboardWidgetObject.widget_filters.show_parameters_names;
  }
  if (typeof (sharedDashboardWidgetObject.widget_filters.hide_empty_cards) !== 'undefined') {
    widgetFilters['hideEmptyCards'] = sharedDashboardWidgetObject.widget_filters.hide_empty_cards;
  }
  if (typeof (sharedDashboardWidgetObject.widget_filters.show_latest_research) !== 'undefined') {
    widgetFilters['showLatestResearch'] = sharedDashboardWidgetObject.widget_filters.show_latest_research;
  }
  if (typeof (sharedDashboardWidgetObject.widget_filters.show_participants) !== 'undefined') {
    widgetFilters['showParticipants'] = sharedDashboardWidgetObject.widget_filters.show_participants;
  }

  if (sharedDashboardWidgetObject.widget_filters.plot_lines) {
    const resultLines = [];
    for (const singleLine of sharedDashboardWidgetObject.widget_filters.plot_lines) {
      const serialized = JSON.parse(singleLine);
      resultLines.push(serialized);
    }
    widgetFilters['plotLines'] = resultLines;
  }

  if (sharedDashboardWidgetObject.widget_filters.heatmap_parameters) {
    const resultParameters = [];
    for (const singleParameter of sharedDashboardWidgetObject.widget_filters.heatmap_parameters) {
      const serialized = JSON.parse(singleParameter);
      resultParameters.push(serialized);
    }
    widgetFilters['heatmapParameters'] = resultParameters;
  }

  const newDashboardWidget: SharedDashboardWidget = {
    id: sharedDashboardWidgetObject.id,
    widgetName: sharedDashboardWidgetObject.widget_name,
    orderPosition: sharedDashboardWidgetObject.order_position,
    sharedDashboardId: sharedDashboardWidgetObject.shared_dashboard_id,
    widgetTitle: sharedDashboardWidgetObject.widget_title,
    widgetFilters: widgetFilters
  };

  return newDashboardWidget;
}
