<div>
  <div cdkDropList (cdkDropListDropped)="dropCardOption($event)">
    <div cdkDrag [cdkDragData]="option"  *ngFor="let option of localCardOptions; let i = index" 
      class="answer-option" flex>
      <div class="answer-option-header">
        <span class="set-option-text"><span i18n="@@answerOptionLabel">Answer Option</span> {{i + 1}}</span>

        <div class="button-group">                
          <button
              class="delete-item-button"
              (click)="deleteOption(option, i)">
          </button>

          <span class="move-handle" cdkDragHandle>
            <svg width="18px" fill="#61728d" viewBox="0 0 24 24">
              <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </span>
          
        </div>
      </div>

      <mat-form-field class="input-white">
        <mat-placeholder class="placeholder"><span i18n="@@optionLabel">Option</span> #{{i + 1}}</mat-placeholder>
        <input matInput [(ngModel)]="option.text"/>
      </mat-form-field>
      
      <div cdkDropList (cdkDropListDropped)="dropCardAction($event, option.cardActions)">
        <div cdkDrag [cdkDragData]="singleAction" *ngFor="let singleAction of option.cardActions; let i = index" 
          class="answer-action">
          <div class="set-action-text">
            <span i18n="@@setActionLabel">Set action</span> 
            #{{i + 1}}
          </div>
          <div class="set-action-group">
            <mat-form-field>
              <mat-placeholder class="placeholder" i18n="@@placeholder-parameter">Parameter</mat-placeholder>
              <mat-select
                  style="margin-right: 10px;"
                  [(value)]="singleAction.parameter"
                  (selectionChange)="setActionValue($event, singleAction)"
              >
                <ng-container *ngIf="!isRelative">
                  <mat-option *ngFor="let singleParam of individualParameters"
                      [value]="singleParam.visibleName">
                    {{singleParam.visibleName}}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="isRelative">
                  <mat-option *ngFor="let singleParam of relativeParameters"
                      [value]="singleParam.visibleName">
                    {{singleParam.visibleName}}
                  </mat-option>
                </ng-container>
                <mat-option value="goto">GOTO</mat-option>
                <!-- <mat-option value="notify">NOTIFY</mat-option> -->
              </mat-select>
            </mat-form-field>

            <i class="material-icons arrow-rotated">
              keyboard_backspace
            </i>
            <mat-form-field>
              <input matInput placeholder="Option action" [(ngModel)]="singleAction.text"/>
            </mat-form-field>

            <i *ngIf="singleAction.parameter != 'goto'" class="material-icons help-tooltip" 
              matTooltip="{{tooltipActionText}}">
              help_outline
            </i>
            <i *ngIf="singleAction.parameter == 'goto'" class="material-icons help-tooltip"
              matTooltip="{{tooltipGotoText}}">
              help_outline
            </i>

            <!-- <div class="delete-label">
              <label
                  (click)="deleteOptionAction(option, i); $event.stopPropagation()" i18n="@@deleteButton">
                Delete
              </label>
            </div> -->

            <button
              class="delete-item-button"
              (click)="deleteOptionAction(option, i); $event.stopPropagation()">
            </button>

            <span class="move-handle" cdkDragHandle>
              <svg width="12px" fill="#61728d" viewBox="0 0 24 24">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                </path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </span>

          </div>
        </div>
      </div>

      <button
          class="adding-button mat-raised-button"
          mat-raised-button
          (click)="addCardOptionAction(option, option.cardActions.length)" i18n="@@addActionButton">
        Add Action
      </button>

    </div>
  </div>

  <div class="action-buttons">
    <button
      mat-raised-button
      color="accent"
      class="add-option"
      (click)="addCardOption()"
      i18n="@@addOptionButton">
        ADD OPTION
    </button>
    <button
      [disabled]="!questionText"
      mat-raised-button
      color="accent"
      (click)="saveLocalQuestion($event)"
      i18n="@@saveButton">
        SAVE
    </button>
  </div>
</div>
