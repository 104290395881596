import { Boolean, Number, String, Array, Record, Partial, Null, Union, Literal, Lazy } from 'runtypes';
import { ApiQuestionnaireCard } from 'app/_api_models/api-questionnaire-card';
import { ApiExperiment } from 'app/_api_models/api-experiment';

const ActiveStatus = Union(
  Literal('not_started'),
  Literal('active'),
  Literal('completed'),
  Literal('not_calculated'),
  Literal('post_calculated'),
  Literal('paused'),
  Literal('finished'),
);

export const ApiQuestionnaire = Record({
  id: Number,
  // start_date: InstanceOf(Date),
  anonymous: Boolean.Or(Null),
  start_offset: Number,
  package_id: Number,
  active_status: ActiveStatus.Or(Null),
  start_date: String.Or(Null),
  finish_date: String.Or(Null),
  interval: String.Or(Null),
  next_run: String.Or(Null),
  last_run: String.Or(Null),
  launch_options: String,
  days_enabled: String,
  need_calculation: Boolean,
}).And(Partial({
  questionnaire_cards: Array(ApiQuestionnaireCard),
  experiments: Array(ApiExperiment),
}));
