import { Boolean, Number, String, Record, Partial, Array } from 'runtypes';
import { ApiColleagueCardAnswer } from 'app/_api_models/api-colleague-card-answer';
import { ApiColleagueParameter } from 'app/_api_models/api-colleague-parameter';
import { ApiRelativeParameter } from './api-relative-parameter';

export const ApiExperimentAnswer = Record({
  id: Number,
  experiment_id: Number,
  questionnaire_id: Number,
  completed: Boolean,
  calculated: Boolean,
  anonymous: Boolean
}).And(Partial({
  email: String,
  colleague_id: Number,
  colleague_card_answers: Array(ApiColleagueCardAnswer),
  colleague_parameters: Array(ApiColleagueParameter),
  relative_parameters: Array(ApiRelativeParameter),
}));
