<!-- <div class="pane mat-elevation-z4"> -->
<div class="card-wrapper" flex>
  <div class="demo-header-group">
    <div class="demo-title">

      <img src="/assets/overmind_icons/demo-eye-hover.svg">

      <h2 i18n="@@demoMode-title">
        Preview
      </h2>
    </div>

    <div class="modal-close" (click)="goBack()">
      <img src="/assets/overmind_icons/demo-close.svg">
    </div>
  </div>

  <div *ngIf="!isCompleted&&activeCard" class="activeDemoCard">
    <mat-card class="question-card">
      <mat-card-header>
        <mat-card-title>
          <span i18n="@@question-label">Question</span> #{{questionNumber}}
          <ng-container *ngIf="activeCard.isRequired">
            <span style="color: green;" i18n="@@question-is-required">(required)</span>
          </ng-container>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <!-- <h1>
          {{activeCard.questionText}}
        </h1> -->
        <div *ngIf="activeCard&&activeCard.questionText"
          class="remove-all-styles"
          [innerHtml]="activeCard.questionText | safeHtml">
        </div>
        <div *ngIf="isOptionsQuestion">
          <mat-radio-group class="radio-group" [(ngModel)]="chosenOption">
            <mat-radio-button *ngFor="let option of activeCard.cardOptions" [value]="option">
              {{option.text}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="isTextQuestion">
          <mat-form-field>
            <textarea 
              class="forautosize" 
              [(ngModel)]="textInput" 
              matInput 
              autocomplete="off" 
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="6" 
              cdkAutosizeMaxRows="50">
            </textarea>
            <mat-icon class="icon-resize-style">format_line_spacing</mat-icon>
          </mat-form-field>
        </div>
        <div *ngIf="isRelativeQuestion">
          <mat-form-field>
            <textarea 
              class="forautosize" 
              [(ngModel)]="textInput" 
              matInput 
              autocomplete="off" 
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="6" 
              cdkAutosizeMaxRows="50">
            </textarea>
            <mat-icon class="icon-resize-style">format_line_spacing</mat-icon>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Select department</mat-label>
            <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()">
              <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
              <mat-option value="0">Non-department</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="this.activeColleagues">
            <mat-label>Select colleague</mat-label>
            <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()">
              <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">{{colleague.firstName}} {{colleague.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>

        <div *ngIf="isRelativeOptionsQuestion">
          <mat-radio-group class="radio-group" [(ngModel)]="chosenOption">
            <mat-radio-button *ngFor="let option of activeCard.cardOptions" [value]="option">
              {{option.text}}
            </mat-radio-button>
          </mat-radio-group>

          <mat-form-field>
            <mat-label>Select department</mat-label>
            <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()">
              <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
              <mat-option value="0">Non-department</mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field *ngIf="this.activeColleagues">
            <mat-label>Select colleague</mat-label>
            <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()">
              <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">{{colleague.firstName}}
                {{colleague.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isRelativeCheckboxesQuestion">
          <div class="checkbox-wrapper"> 
            <div class="checkboxes-group">
              <mat-checkbox *ngFor="let checkboxOption of checkboxOptions" [(ngModel)]="checkboxOption.selected">
                {{checkboxOption.text}}
              </mat-checkbox>
            </div>
          </div>

          <mat-form-field>
            <mat-label>Select department</mat-label>
            <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()">
              <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
              <mat-option value="0">Non-department</mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field *ngIf="this.activeColleagues">
            <mat-label>Select colleague</mat-label>
            <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()">
              <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">{{colleague.firstName}}
                {{colleague.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isRelativeScoreQuestion">
          <!-- <mat-radio-group class="score-group" style="margin-bottom: 20px;" [(ngModel)]="textInput">
            <mat-radio-button *ngFor="let division of activeCard.scoreDivisions" [value]="division.value" (change)="radioChange($event)">
              {{division.label}}
            </mat-radio-button>
          </mat-radio-group> -->
          <ng-container *ngIf="isScoreMulticolor">
            <mat-radio-group class="score-group-multicolor" style="margin-bottom: 20px;" [(ngModel)]="textInput">
              <mat-radio-button *ngFor="let division of activeCard.scoreDivisions" [value]="division.value" 
              class="radio-group-{{multicolorGroupSize}}" (change)="radioChange($event)">
                {{division.label}}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-container *ngIf="!isScoreMulticolor">
            <mat-radio-group class="score-group" style="margin-bottom: 20px;" [(ngModel)]="textInput">
              <mat-radio-button *ngFor="let division of activeCard.scoreDivisions" [value]="division.value" (change)="radioChange($event)">
                {{division.label}}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
        
          <mat-form-field>
            <mat-label>Select department</mat-label>
            <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()">
              <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
              <mat-option value="0">Non-department</mat-option>
            </mat-select>
          </mat-form-field>
        
          <mat-form-field *ngIf="this.activeColleagues">
            <mat-label>Select colleague</mat-label>
            <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()">
              <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">{{colleague.firstName}}
                {{colleague.lastName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isScoreQuestion" class="score-group-wrap">
          <ng-container *ngIf="isScoreMulticolor">
            <mat-radio-group class="score-group-multicolor" [(ngModel)]="textInput">
              <mat-radio-button *ngFor="let division of activeCard.scoreDivisions" [value]="division.value" 
              class="radio-group-{{multicolorGroupSize}}" (change)="radioChange($event)">
                {{division.label}}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <ng-container *ngIf="!isScoreMulticolor">
            <mat-radio-group class="score-group" [(ngModel)]="textInput">
              <mat-radio-button *ngFor="let division of activeCard.scoreDivisions" [value]="division.value" (change)="radioChange($event)">
                {{division.label}}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
        </div>
        <div *ngIf="isCheckboxesQuestion" class="checkbox-wrapper">
          <div class="checkboxes-group">
            <mat-checkbox *ngFor="let checkboxOption of checkboxOptions" 
              [(ngModel)]="checkboxOption.selected"
            >
              {{checkboxOption.text}}
            </mat-checkbox>
          </div>
        </div>
        <p class="answer-error">
          {{cardError}}
        </p>
      </mat-card-content>
      <mat-card-actions>
        <div class="action-buttons">
          <button *ngIf="questionNumber > 1"
              mat-raised-button
              color="accent"
              (click)="toPreviousQuestion()"
              i18n="@@prev-label">
            BACK
          </button>
          <button *ngIf="!isLastQuestion"
              mat-raised-button
              color="accent"
              (click)="toNextQuestion()"
              i18n="@@next-label">
            NEXT
          </button>
          <button *ngIf="isLastQuestion"
              mat-raised-button
              color="primary"
              (click)="toNextQuestion()"
              i18n="@@finish-label">
            FINISH
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>

  <div *ngIf="isCompleted" class="card-wrapper" class="activeDemoCard" flex>
    <mat-card class="message-after-answer">
      <mat-card-content style="align-items: center;">
        <h1 i18n="@@message-after-answer">
          Thank you for your time!
        </h1>
        <div class="answer-info">
          <span class="answer-label" i18n="@@demo-parameters">Individual Parameters: </span>
          <div *ngFor="let parameterResult of resultStateIndividual">
            <ng-container *ngIf="parameterResult.parameterType === 'text'">
              {{parameterResult.visibleName}}: {{parameterResult.textValue}}
            </ng-container>
            <ng-container *ngIf="parameterResult.parameterType === 'number'">
              {{parameterResult.visibleName}}: {{parameterResult.value}}
            </ng-container>
          </div>
          <br/>
          <span class="answer-label">Common Parameters: </span>
          <div *ngFor="let parameterResult of resultStateCommon">
            <ng-container *ngIf="parameterResult.parameterType === 'text'">
              {{parameterResult.visibleName}}: {{parameterResult.textValue}}
            </ng-container>
            <ng-container *ngIf="parameterResult.parameterType === 'number'">
              {{parameterResult.visibleName}}: {{parameterResult.value}}
            </ng-container>
          </div>
          <br/>
          <span class="answer-label">Relative Parameters: </span>
          <div *ngFor="let parameterResult of resultStateRelative">
            <ng-container *ngIf="parameterResult.parameterType === 'text'">
              {{parameterResult.visibleName}}: {{parameterResult.textValue}}
            </ng-container>
            <ng-container *ngIf="parameterResult.parameterType === 'number'">
              {{parameterResult.visibleName}}: {{parameterResult.value}}
            </ng-container>
            (for colleague: {{getColleagueName(parameterResult)}})
          </div>
        </div>
        <!-- <div class="answer-info">
          <span class="answer-label" i18n="@@demo-notify">Send notify: </span>{{shouldNotify}}
        </div> -->
      </mat-card-content>
    </mat-card>
  </div>


</div>