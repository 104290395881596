// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HighchartsChartModule } from 'highcharts-angular';

import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    // CKEditorModule,
    HighchartsChartModule,
  ],
  exports: [
    // CKEditorModule,
    HighchartsChartModule,
  ],
})

export class DashboardsModule { }
