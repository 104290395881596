import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Package } from 'app/_models/package';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PackageParameter } from 'app/_models/package-parameter';
import { CardOption } from 'app/_models/card-option';
import { CardAction } from 'app/_models/card-action';

import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { ScoreDivision } from 'app/_models/score-division';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { rejects } from 'assert';
import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';

@Injectable()
export class CreateFromStandardHelper implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private dashboardService: DashboardService,
    private questionnaireService: QuestionnaireService,
    private amplitudeService: AmplitudeEventsService,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  callbackOnCreation(): void {
    this.sharedPackageService.onRefreshPackage$.emit();
    this.sharedPackageService.onCreateFromStandard$.emit(true);
  }

  addNewLocalPackage(newName: string, companyId: number, pack: Package): void {
    if (companyId > 0) {
      const name = newName;
      this.loadPackage(pack.id).then((resultPackage: Package) => {
        const questionnaireToCopy = resultPackage.questionnaire;
        const dashboardToCopy = resultPackage.dashboardConfig;
        const packageParameters = resultPackage.packageParameters;
        const pqActions = resultPackage.postQuestionnaireActions;
        const description = resultPackage.description;
        const language = resultPackage.language;
        if (name && companyId && questionnaireToCopy) {
          const questionnaireId = questionnaireToCopy.id;

          this.packageService.createFromStandardSet(name, description, language)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((newPackage: Package) => {
            const newPackageId = newPackage.id;
            const newQuestionnaire = newPackage.questionnaire;
            const newQuestionnaireId = newQuestionnaire.id;
            const newDashboardId = newPackage.dashboardConfig.id;
            this.amplitudeService.addEvent('add questionnaire from standard');
            const promiseParams = this.copyPackageParameters(newPackageId, packageParameters);
            this.copyPublicQuestionnaire(newPackageId, questionnaireId, newQuestionnaireId);
            this.copyPQActions(pqActions, newPackageId);
            if (dashboardToCopy) {
              promiseParams.then((resultParameters: PackageParameter[]) => {
                this.copyPublicDashboard(newPackageId, dashboardToCopy.id, newDashboardId, resultParameters);
              }).catch(error => console.log(error));
            }
            // this.sharedPackageService.onRefreshPackage$.emit();
            }, error => {
            console.log('Error with package copying ', error);
            });
        }

      }).catch(error => console.log(error));
    }
  }

  loadPackage(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultPackage: Package) => {
          resolve(resultPackage);
        }, error => {
          reject(error);
        });
    });
  }

  copyPackageParameters(newPackageId: number, packageParameters: PackageParameter[]): Promise<any> {
    return new Promise((resolve, reject) => {
      if (newPackageId && packageParameters) {
        const promisesList = [];
        for (const singleParameter of packageParameters) {
          const newParameter: PackageParameter = {
            id: 0,
            visibleName: singleParameter.visibleName,
            formulaName: singleParameter.formulaName,
            groupName: singleParameter.groupName,
            packageId: newPackageId,
            commonValue: singleParameter.commonValue,
          };

          if (newParameter) {
            const singlePromise = this.copySingleParameter(newParameter);
            promisesList.push(singlePromise);
          }
        }
        Promise.all(promisesList).then((result: PackageParameter[]) => {
          resolve(result);
        }).catch(error => reject(error));
      } else {
        reject();
      }
    });
  }

  copySingleParameter(newParameter: PackageParameter): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.addNewParameter(newParameter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameter: PackageParameter) => {
          resolve(resultParameter);
        }, error => {
          reject(error);
        });
    });
  }

  copyPublicDashboard(packageId: number, dashboardId: number, newDashboardId: number, newParameters: PackageParameter[]): void {
    if (packageId && dashboardId) {
      this.dashboardService.getDashboardConfig(dashboardId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDashboard: DashboardConfig) => {
          const loadPromise = this.loadFilterParameters(resultDashboard);
          loadPromise.then((filterParameters: PackageParameter[]) => {
            for (const singleWidget of resultDashboard.dashboardWidgets) {
              const newFilters = this.formWidgetFilters(singleWidget.widgetFilters, filterParameters, newParameters);
              const widgetInfo: DashboardWidget = {
                id: 0,
                widgetName: singleWidget.widgetName,
                widgetTitle: singleWidget.widgetTitle,
                orderPosition: singleWidget.orderPosition,
                dashboardConfigId: newDashboardId,
                widgetFilters: newFilters
              };
              this.copySingleWidget(widgetInfo);
            }
          }).catch(error => console.log(error));
        });
    }
  }

  formWidgetFilters(inputFilters: any, filterParameters: PackageParameter[], newParameters: PackageParameter[]): any {
    const newFilters = {};
    const activeParametersIds = inputFilters.activeParametersIds;
    if (activeParametersIds && activeParametersIds.length > 0) {
      const newParamIds = [];
      for (const singleId of activeParametersIds) {
        const findFilterParam = filterParameters.find(filterParam => filterParam.id === singleId);
        if (findFilterParam) {
          const findNewParam = newParameters.find(newParam => newParam.formulaName === findFilterParam.formulaName);
          if (findNewParam) {
            newParamIds.push(findNewParam.id);
          }
        }
      }
      newFilters['activeParametersIds'] = newParamIds;
    }
    if (inputFilters.sortParameterId) {
      const findFilterParam = filterParameters.find(filterParam => filterParam.id === inputFilters.sortParameterId);
      if (findFilterParam) {
        const findNewParam = newParameters.find(newParam => newParam.formulaName === findFilterParam.formulaName);
        if (findNewParam) {
          newFilters['sortParameterId'] = findNewParam.id;
        }
      }
    }
    newFilters['hideEmptyCards'] = true;
    newFilters['showLatestResearch'] = true;
    newFilters['showParametersNames'] = true;
    newFilters['showParticipants'] = true;

    return newFilters;
  }

  loadFilterParameters(config: DashboardConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      const promiseList = [];
      for (const singleWidget of config.dashboardWidgets) {
        const activeParametersIds = singleWidget.widgetFilters.activeParametersIds;
        if (activeParametersIds && activeParametersIds.length > 0) {
          for (const singleParamId of activeParametersIds) {
            const singlePromise = this.loadSingleFilterParameter(singleParamId);
            promiseList.push(singlePromise);
          }
        }
      }
      Promise.all(promiseList).then(result => {
        resolve(result);
      }).catch(error => reject(error));
    });
  }

  loadSingleFilterParameter(parameterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getSinglePackageParameter(parameterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameter: PackageParameter) => {
          resolve(resultParameter);
        }, error => reject(error));
    });
  }

  copySingleWidget(widgetInfo: DashboardWidget): void {
    if (widgetInfo) {
      this.dashboardService.addDashboardWidget(widgetInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultWidget: DashboardWidget) => {
        }, error => {
          console.log(error);
        });
    }
  }

  copyPublicQuestionnaire(packageId: number, questionnaireId: number, newQuestionnaireId: number): void {
    if (packageId && questionnaireId) {
      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          const promiseOptions = this.loadCardOptions(resultQuestionnaire.questionnaireCards);
          promiseOptions.then((localCards: QuestionnaireCard[]) => {
            resultQuestionnaire.questionnaireCards = localCards;
            const promisesList = [];
            for (const card of localCards) {
              const promiseCard = this.copyQuestionnaireCard(card, newQuestionnaireId);
              promisesList.push(promiseCard);
            }
            Promise.all(promisesList).then(result => {
              this.callbackOnCreation();
            }).catch(error => console.log(error));
          }).catch(error => console.log(error));
        }, error => {
          console.log('Error with questionnaire copying ', error);
        });
    }
  }

  loadCardOptions(localCards: QuestionnaireCard[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      for (const singleCard of localCards) {
        for (const singleOption of singleCard.cardOptions) {
          const singlePromise = this.loadCardOption(singleOption.id);
          promisesList.push(singlePromise);
          singlePromise.then((resultOption: CardOption) => {
            singleOption.cardActions = resultOption.cardActions;
          }).catch(error => console.log(error));
        }
      }

      Promise.all(promisesList).then((result) => {
        resolve(localCards);
      }).catch(error => reject(error));
    });
  }

  loadCardOption(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.getCardOption(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultCard: CardOption) => {
          resolve(resultCard);
        }, error => {
          reject(error);
        });
    });
  }

  copyPQActions(pqActions: PostQuestionnaireAction[], newPackageId: number): void {
    if (pqActions) {
      for (const action of pqActions) {
        const newAction: PostQuestionnaireAction = {
          id: 0,
          text: action.text,
          parameter: action.parameter,
          orderPosition: action.orderPosition,
          actionType: action.actionType,
        };

        if (newAction) {
          this.packageService.addPostQuestionnaireAction(newPackageId, newAction)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((addedPQA: PostQuestionnaireAction) => {
              if (action.customCalculationId) {
                this.copyPythonCalculation(addedPQA.id, action.customCalculationId);
              }
              // console.log('Add pqa ', addedPQA);
            }, error => {
              console.log('PQA error ', error);
            });
        }
      }

    }
  }

  copyPythonCalculation(pqActionId: number, customCalculationId: number): any {
    if (customCalculationId) {
      this.packageService.getCustomCalculation(customCalculationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((calculationObject: PythonCustomCalculation) => {
          const code = calculationObject.text_value;
          this.packageService.addCustomCalculation(pqActionId, code)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resultPython: PythonCustomCalculation) => {
              // resolve(resultPython);
            }, error => console.log(error));
        }, error => console.log(error));
    }
  }

  copyQuestionnaireCard(card: QuestionnaireCard, newQuestionnaireId: number): Promise<any> {
    const newCard: QuestionnaireCard = {
      id: 0,
      cardOptions: [],
      scoreDivisions: [],
      questionText: card.questionText,
      questionnaireId: newQuestionnaireId,
      cardType: card.cardType,
      orderPosition: card.orderPosition,
      isRequired: card.isRequired,
      divisionsColorScheme: card.divisionsColorScheme,
    };

    return new Promise((resolve, reject) => {
      if (newCard) {
        this.questionnaireService.addQuestionCard(newCard)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((addedCard: QuestionnaireCard) => {
            if (addedCard) {
              const promisesList = [];
              const cardOptions = card.cardOptions;
              for (const singleOption of cardOptions) {
                const singlePromise = this.copyCardOption(singleOption, addedCard.id);
                promisesList.push(singlePromise);
              }
              const scoreDivisions = card.scoreDivisions;
              for (const singleDivision of scoreDivisions) {
                const singlePromise = this.copyScoreDivision(singleDivision, addedCard.id);
                promisesList.push(singlePromise);
              }
              Promise.all(promisesList).then(result => {
                resolve(result);
              }).catch(error => reject(error));
            } else {
              reject();
            }
          }, error => {
            console.log('Question card error ', error);
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copyScoreDivision(scoreDivision: ScoreDivision, newCardId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const newDivision: ScoreDivision = {
        id: 0,
        label: scoreDivision.label,
        value: scoreDivision.value,
      };

      if (newDivision) {
        this.questionnaireService.addCardScoreDivision(newCardId, newDivision)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((createdDivision: any) => {
            // console.log('Division created ', createdDivision);
            resolve(createdDivision);
          }, error => {
            console.log('Division error ', error);
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copyCardOption(cardOption: CardOption, newCardId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const newOption: CardOption = {
        id: 0,
        text: cardOption.text,
        cardActions: [],
        orderPosition: cardOption.orderPosition,
      };

      if (newOption) {
        this.questionnaireService.addCardOption(newCardId, newOption)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((createdOption: CardOption) => {
            if (createdOption) {
              const id = createdOption.id;
              const promiseOption = this.copyOptionActions(id, cardOption);
              promiseOption.then(result => {
                resolve(result);
              }).catch(error => reject(error));
            } else {
              reject();
            }
          }, error => {
            console.log('Option error ', error);
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copyOptionActions(newOptionId: number, cardOption: CardOption): Promise<any> {
    return new Promise((resolve, reject) => {
      if (cardOption) {
        const promisesList = [];
        const cardActions = cardOption.cardActions;
        for (const singleAction of cardActions) {
          const promiseAction = this.copySingleAction(newOptionId, singleAction);
          promisesList.push(promiseAction);
        }
        Promise.all(promisesList).then(result => {
          resolve(result);
        }).catch(error => reject(error));
      } else {
        reject();
      }
    });
  }

  copySingleAction(newOptionId: number, cardAction: CardAction): Promise<any> {
    return new Promise((resolve, reject) => {
      if (newOptionId && cardAction) {
        const newAction: CardAction = {
          id: 0,
          text: cardAction.text,
          parameter: cardAction.parameter,
          orderPosition: cardAction.orderPosition,
        };

        if (newAction) {
          this.questionnaireService.addCardAction(newOptionId, newAction)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((createdAction: CardAction) => {
              if (createdAction) {
                // console.log('Action created ', createdAction);
                resolve(createdAction);
              } else {
                reject();
              }
            }, error => {
              console.log('Action error ', error);
              reject(error);
            });
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  }

}
