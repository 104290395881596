// NOTE: TypeScript cannot statically verify that the fetched data is of the right type

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { parseApiQuestionnaire } from 'app/_api_validation/parse-api-questionnaire';
import { parseApiQuestionnaireCard } from 'app/_api_validation/parse-api-questionnaire-card';
import { parseApiCardOption } from 'app/_api_validation/parse-api-card-option';
import { parseApiCardAction } from 'app/_api_validation/parse-api-card-action';
import { parseApiScoreDivision } from 'app/_api_validation/parse-api-score-division';

import { ScoreDivision } from 'app/_models/score-division';
import { Questionnaire } from 'app/_models/questionnaire';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { parseApiQuestionnaireVerbose } from 'app/_api_validation/parse-api-questionnaire-verbose';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  private questionnairesUrl: string = environment.API_ENDPOINT + '/v1/questionnaires';
  private questionnaireVerboseUrl: string = environment.API_ENDPOINT + '/v1/questionnaire_verbose';
  private questionnaireCardsUrl: string = environment.API_ENDPOINT + '/v1/questionnaire_cards';
  private cardOptionsUrl: string = environment.API_ENDPOINT + '/v1/card_options';
  private cardActionsUrl: string = environment.API_ENDPOINT + '/v1/card_actions';
  private scoreDivisionsUrl: string = environment.API_ENDPOINT + '/v1/score_divisions';
  private sendReminderUrl: string = environment.API_ENDPOINT + '/v1/questionnaire_send_reminder';
  private sendIndividualReminderUrl: string = environment.API_ENDPOINT + '/v1/send_individual_reminder';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  getQuestionnaireBrief(id: number): Observable<Questionnaire> {
    const url = `${this.questionnairesUrl}/${id}`;
    return this.http
      .get<Questionnaire>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseQuestionnaire(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getQuestionnaireVerbose(id: number): Observable<Questionnaire> {
    const url = `${this.questionnaireVerboseUrl}/${id}`;
    return this.http
      .get<Questionnaire>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          const resultQuestionnaire = this.parseQuestionnaireVerbose(attributes, included);
          return resultQuestionnaire;
        }),
        catchError(this.handleError)
      );
  }

  addQuestionCard(cardInfo: QuestionnaireCard): Observable<QuestionnaireCard> {
    const url = this.questionnaireCardsUrl;
    const item = JSON.stringify({
      questionText: cardInfo.questionText,
      questionnaire_id: cardInfo.questionnaireId,
      cardType: cardInfo.cardType,
      order_position: cardInfo.orderPosition,
      is_required: cardInfo.isRequired,
      is_active: cardInfo.isActive,
      divisions_color_scheme: cardInfo.divisionsColorScheme,
      // score_divisions: cardInfo.scoreDivisions,
    });

    return this.http
      .post<QuestionnaireCard>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseQuestionnaireCard(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateQuestionCard(cardId: number, updatedFields: any): Observable<QuestionnaireCard> {
    const updatedCardFields = JSON.stringify(updatedFields);
    return this.http
      .put<QuestionnaireCard>(`${this.questionnaireCardsUrl}/${cardId}`, updatedCardFields, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseQuestionnaireCard(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeQuestionCard(cardId: number): Observable<any> {
    const url = `${this.questionnaireCardsUrl}/${cardId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getCardOption(id: number): Observable<CardOption> {
    const url = `${this.cardOptionsUrl}/${id}`;
    return this.http
      .get<CardOption>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseCardOption(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addCardOption(cardId: number, optionInfo: CardOption): Observable<CardOption> {
    const url = this.cardOptionsUrl;
    const item = JSON.stringify({
      text: optionInfo.text,
      order_position: optionInfo.orderPosition,
      card_actions: [],
      questionnaire_card_id: cardId,
    });

    return this.http
      .post<CardOption>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseCardOption(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  updateCardOption(cardOptionId: number, updatedFields: any): Observable<CardOption> {
    const newCardOption = JSON.stringify(updatedFields);
    return this.http
      .put<CardOption>(`${this.cardOptionsUrl}/${cardOptionId}`, newCardOption, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseCardOption(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  removeCardOption(cardOptionId: number): Observable<any> {
    const url = `${this.cardOptionsUrl}/${cardOptionId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  addCardScoreDivision(cardId: number, divisionInfo: ScoreDivision): Observable<ScoreDivision> {
    const url = this.scoreDivisionsUrl;
    const item = JSON.stringify({
      questionnaire_card_id: cardId,
      label: divisionInfo.label,
      value: divisionInfo.value,
    });

    return this.http
      .post<ScoreDivision>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseScoreDivision(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateCardScoreDivision(divisionId: number, divisionInfo: ScoreDivision): Observable<ScoreDivision> {
    const newdivisionInfo = JSON.stringify(divisionInfo);
    const url = `${this.scoreDivisionsUrl}/${divisionId}`;

    return this.http
      .put<ScoreDivision>(url, newdivisionInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseScoreDivision(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeCardScoreDivision(divisionId: number): Observable<any> {
    const url = `${this.scoreDivisionsUrl}/${divisionId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  addCardAction(cardOptionId: number, actionInfo: CardAction): Observable<CardAction> {
    const url = this.cardActionsUrl;
    const item = JSON.stringify({
      text: actionInfo.text,
      parameter: actionInfo.parameter,
      card_option_id: cardOptionId,
      order_position: actionInfo.orderPosition,
    });

    return this.http
      .post<CardAction>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCardAction(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateCardAction(cardActionId: number, updatedFields: any): Observable<CardAction> {
    const url = `${this.cardActionsUrl}/${cardActionId}`;
    const newCardAction = JSON.stringify(updatedFields);
    return this.http
      .put<CardAction>(url, newCardAction, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCardAction(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeCardAction(cardActionId: number): Observable<any> {
    const url = `${this.cardActionsUrl}/${cardActionId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  updateQuestionnaire(questionnaireId: number, updatedFields: any): Observable<Questionnaire> {
    const url = `${this.questionnairesUrl}/${questionnaireId}`;
    const newFields = JSON.stringify(updatedFields);

    return this.http
      .put<Questionnaire>(url, newFields, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseQuestionnaire(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  updateQuestionnaireResearchStatus(questionnaireId: number, activeStatus: string): Observable<any> {
    const url = `${this.questionnairesUrl}/${questionnaireId}`;
    const newActiveStatus = JSON.stringify({
      active_status: activeStatus,
    });

    return this.http
      .put<any>(url, newActiveStatus, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('Update QR status ', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  sendReminder(id: number): Observable<any> {
    const url = `${this.sendReminderUrl}/${id}`;
    return this.http
      .get<Questionnaire>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => 'OK'),
        catchError(this.handleError)
      );
  }

  sendIndividualReminder(packageId: number, evaluatorId: number): Observable<any> {
    const url = `${this.sendIndividualReminderUrl}`;
    const data = JSON.stringify({
      package_id: packageId,
      evaluator_id: evaluatorId
    });

    return this.http
      .post<Questionnaire>(url, data, this.getHttpOptions())
      .pipe(
        map((response: any) => 'OK'),
        catchError(this.handleError)
      );
  }

  startQuestionnaireResearch(questionnaireId: number, interval: string,
    startDate: string, finishDate: string, daysEnabled: string, startOffset: number, anonymous: boolean): Observable<any> {
    const updatedFields = JSON.stringify({
      active_status: 'active',
      interval: interval,
      start_date: startDate,
      next_run: startDate,
      finish_date: finishDate,
      days_enabled: daysEnabled,
      start_offset: startOffset,
      anonymous: anonymous,
    });

    return this.http
      .put<any>(`${this.questionnairesUrl}/${questionnaireId}`, updatedFields, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  private parseQuestionnaire(attributes: any, included: any): Questionnaire {
    return parseApiQuestionnaire(attributes, true, included);
  }

  private parseQuestionnaireVerbose(attributes, included: any): Questionnaire {
    return parseApiQuestionnaireVerbose(attributes, true, included);
  }

  private parseQuestionnaireCard(apiResponse: any): QuestionnaireCard {
    return parseApiQuestionnaireCard(apiResponse, true);
  }

  private parseCardOption(apiResponse: any, included: any): CardOption {
    return parseApiCardOption(apiResponse, true, included);
  }

  private parseCardAction(apiResponse: any): CardAction {
    return parseApiCardAction(apiResponse);
  }

  private parseScoreDivision(apiResponse: any): ScoreDivision {
    return parseApiScoreDivision(apiResponse);
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error); // for demo purposes only
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }
}
