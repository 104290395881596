import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  LOCALE_ID,
  Inject
} from '@angular/core';

import { Router, RouterEvent, Event, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DialogResultComponent } from 'app/_dialogs/dialog-result/dialog-result.component';
import { DialogAddComponent } from 'app/_dialogs/dialog-add/dialog-add.component';
import { environment } from '../environments/environment';

import { UserService } from 'app/_services/user.service';
import { UtilsService } from './_services/utils.service';
import { SharedService } from 'app/_services/shared.service';
import { CompanyService } from 'app/_services/company.service';
import { SharedUserService } from './_services/shared-user.service';
import { CompanyDataService } from './_services/company-data.service';
import { SharedPackageService } from './_services/shared-package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { SharedCompanyDataService } from './_services/shared-company-data.service';

import { User } from 'app/_models/user';
import { Company } from 'app/_models/company';


@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private attachmentsUrl: string = environment.ATTACHMENTS_URL;
  private baseUrl: string = environment.BASE_ANGULAR_URL;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  private languages: any = [
    { code: 'en', label: 'English'},
    { code: 'ru', label: 'Русский'},
  ];

  currentUser: User = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    activeCompanyId: 0,
    showNotifications: false,
    hasCalculator: false,
    showThumbnails: false,
    currentLocale: 'en',
  };

  currentCompany: Company = {
    id: 0,
    uuid: '',
    name: '',
    departments: [],
    ownerEmail: '',
    ownerId: 0,
    slackIntegrationToken: '',
    colleagueSharedLink: '',
  };

  userExists: boolean = false;
  loginMode: boolean = true;
  companyLogoUrl: any;
  isAdmin: boolean = false;
  notificationList: any = [];
  userId: number = 0;
  isPreviewMode: boolean = false;
  currentLang: string = 'en';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private utilityService: UtilsService,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private companyDataService: CompanyDataService,
    private sharedService: SharedService,
    private sharedUserService: SharedUserService,
    private sharedPackageService: SharedPackageService,
    private sharedCompanyDataService: SharedCompanyDataService,
    @Inject(LOCALE_ID) protected localeId: string
    ) {
      router.events.pipe(
        filter((e: Event): e is RouterEvent => e instanceof RouterEvent)
      ).pipe(takeUntil(this.ngUnsubscribe)).
      subscribe((e: RouterEvent) => {
        if (e instanceof NavigationEnd) {
          this.checkPreviewMode();
        }
      });
    }

  ngOnInit(): void {
    this.setTitle('TeamHeat');
    this.checkPreviewMode();

    this.sharedService.onLogin$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getUserInfo();
        this.checkUser();
      });

    this.sharedService.onLogout$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.currentCompany = null;
        this.userExists = null;
        this.logOut();
      });

    this.sharedService.demoMode$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isPreview: boolean) => {
        this.isPreviewMode = isPreview;
      });

    this.sharedService.loginMode$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        this.loginMode = result;
      });

    this.sharedService.onRefreshUserInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getUserInfo();
      });

    this.sharedService.onChangeCurrentCompany$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.companyService.getCompanyInfo(this.currentCompany.id);
      });

    const isLogged = this.authService.isLoggedIn();

    if (isLogged && !this.sharedService.onLoginEmitted) {
      this.sharedService.onLogin$.emit();
      this.sharedService.onLoginEmitted = true;
      this.sharedService.loginMode$.emit(false);
      this.loginMode = false;
    }
  }

  checkPreviewMode(): void {
    if (this.isPreviewMode) {
      if (window.location.href.indexOf('preview') > -1) {
        this.isPreviewMode = true;
      } else {
        this.isPreviewMode = false;
        location.reload();
      }
    } else {
      if (window.location.href.indexOf('preview') > -1) {
        this.isPreviewMode = true;
      } else {
        this.isPreviewMode = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  checkUser(): void {
    if (localStorage.getItem('currentUser')) {
      this.userExists = true;
    } else {
      this.userExists = false;
    }
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (!userId) {
      return;
    }

    this.userService.getUserInfo(userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.currentUser = user;
        this.isAdmin = user.admin;
        this.userId = user.id;
        this.sharedService.showThumbnails.next(user.showThumbnails);

        let currentCompany = 0;

        if (user.activeCompanyId) {
          currentCompany = user.activeCompanyId;
        } else {
          currentCompany = this.sharedService.currentCompanyId.value;
        }

        this.sharedService.currentCompanyId.next(currentCompany);
        this.sharedUserService.updateUserInfo(user);
        this.companyDataService.getCompanyData(currentCompany);
        this.onCreateFromStandard();
        this.getCurrentCompanyInfo(currentCompany);
        this.checkCurrentLocale(user.currentLocale);
      },
      error => {
        this.sharedService.checkSignatureExpired(error);
      });
  }

  getCurrentCompanyInfo(activeId: number): void {
    this.companyService.getCompanyInfo(activeId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((company: Company) => {
        this.updateCurrentCompanyInfo(company);
        this.sharedCompanyDataService.updateCompanyInfo(company);
      },
      error => {
        console.log(error);
      });
  }

  checkCurrentLocale(userLocale: string): void {
    const currentLocale = this.utilityService.getCurrentLanguage();
    const isRu = this.utilityService.checkUrlForSubstring('/ru/');
    const isEn = this.utilityService.checkUrlForSubstring('/en/');

    if (!isRu && !isEn) {
      return;
    }

    if (currentLocale !== userLocale) {
      this.changeCurrentLocale(userLocale);
    }
  }

  updateUserLocale(newLang: any): void {
    this.userService.updateUserLocale(this.userId, newLang.code)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.changeCurrentLocale(newLang.code);
      },
      error => {
        console.log('Unable to update', error);
      });
  }

  changeCurrentLocale(newLocale: string): void {
    this.sharedService.currentLang.next(newLocale);
    this.currentLang = newLocale;
    const newUrl = this.baseUrl + '/' + newLocale;
    window.location.href = newUrl;
  }


  updateCurrentCompanyInfo(newCompany: Company): void {
    const companyLogo = newCompany.logo;

    if (companyLogo && companyLogo.url) {
      this.companyLogoUrl = this.attachmentsUrl + companyLogo.url;
    } else {
      this.companyLogoUrl = null;
    }

    const currentCompany: Company = {
      id: newCompany.id,
      uuid: newCompany.uuid,
      name: newCompany.name,
      slackIntegrationToken: newCompany.slackIntegrationToken,
      departments: [],
      ownerEmail: newCompany.ownerEmail,
      ownerId: newCompany.ownerId,
      colleagueSharedLink: newCompany.colleagueSharedLink,
    };

    this.currentCompany = currentCompany;
  }

  changeCompany(): void {
    this.companyService.getUserCompanies(this.currentUser.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companies: Company[]) => {
        this.showUserCompanies(companies);
      },
      error => {
        console.log('User Company Error', error);
      });
  }

  showUserCompanies(userCompanies: Company[]): void {
    const dialogRef = this.dialog.open(DialogResultComponent, {
      data: {
        dialogType: 'ChangeCompany',
        companyList: userCompanies,
      },
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((chosenCompanyId: number) => {
        const newCompany = userCompanies.find(company => company.id === chosenCompanyId);
        if (newCompany.id === this.currentCompany.id) {
          return;
        }
        this.sharedCompanyDataService.clearSharedCompanyData();
        this.setUserActiveCompany(newCompany);
        this.getCurrentCompanyInfo(newCompany.id);
        this.companyDataService.getCompanyData(newCompany.id);
        this.sharedService.currentCompanyId.next(newCompany.id);
        this.sharedService.onChangeCurrentCompany$.emit();
        this.router.navigate(['/company']);
      },
      error => console.log('Error change company ', error));
  }

  setUserActiveCompany(newCompany: Company): void {
    const newCompanyId = newCompany.id;
    this.userService.setActiveCompany(newCompanyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateCurrentCompanyInfo(newCompany);
      },
      error => {
        console.log('Error with active company ', error);
      });
  }

  onCreateFromStandard(): void {
    this.sharedPackageService.onRefreshPackage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.companyDataService.getPackagesList(this.currentCompany.id);
      });
  }

  logOut(): void {
    this.authService.logout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.checkUser();
        this.loginMode = true;
        this.sharedService.loginMode$.emit(true);
        this.sharedCompanyDataService.clearSharedCompanyData();
        this.sharedPackageService.clearSharedService();
        this.sharedUserService.clearSharedUserService();
        this.sharedService.clearSharedService();
        this.router.navigate(['/login'])
          .then(() => {
            window.location.reload();
          });
      },
      error => {
        console.log('Logout error ', error);
      });
  }

  onAdminSettingsPage(): void {
    if (this.isAdmin) {
      this.router.navigate(['/admin-settings-page']);
    }
  }

  onLanguageChange(lang: any): void {
    this.updateUserLocale(lang);
  }

  onLogoClick(): void {
    this.router.navigate(['/home/']);
  }

  onSettingsPage(): void {
    this.router.navigate(['/settings']);
  }

  onFeedback(): void {
    this.router.navigate(['/feedback']);
  }

  onHelpPage(): void {
    this.router.navigate(['/help']);
  }

  onFaqPage(): void {
    window.open('https://teamheat.tech/faq', '_blank');
  }

  onDialogAdd(): void {
    this.dialog.open(DialogAddComponent, {
      position: {
        top: '94px',
      },
      data: {
        animal: 'unicorn'
      }
    });
  }

  changeTab(): void {
    this.sharedService.currentTabId.next(0);
  }
}
