import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  checkUrlForSubstring(substring: string): boolean {
    const link = window.location.href;
    if (substring && link.indexOf(substring) > -1) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentLanguage(): string {
    const link = window.location.href;

    if ('/ru/' && link.indexOf('/ru/') > -1) {
      return 'ru';
    } else {
      return 'en';
    }
  }
}
