import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CreateFromStandardHelper } from 'app/_helpers/get-from-standard-helper';
import { DialogResultComponent } from 'app/_dialogs/dialog-result';
import { Package } from 'app/_models/package';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageService } from 'app/_services/package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { SnackBarComponent } from 'app/snack-bar';

@Component({
  selector: 'app-standard-set-settings',
  templateUrl: './set-settings.component.html',
  styleUrls: ['./set-settings.component.scss'],
  providers: [SnackBarComponent]
})
export class StandardSetSettingsComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  currentPack: Package;
  currentSetId: number = 0;
  currentSetName: string = '';
  isGottenPack: boolean = false;
  activeCompanyId: number = 0;
  isAdmin: boolean = false;
  packageLanguage: string;
  currentCategoryName: string = '';
  // setCategoryName: string = '';

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private createFromStandardHelper: CreateFromStandardHelper,
    private snackBar: SnackBarComponent,
    private authService: AuthenticationService,
    private userService: UserService,
    public  dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.sharedService.currentStandardSetTabId.next(0);

    this.sharedService.currentCompanyId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyId: number) => {
        this.activeCompanyId = companyId;
      });

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((setId: number) => {
        this.currentSetId = setId;
      });

    this.sharedPackageService.currentPackageName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((setName: string) => {
        this.currentSetName = setName;
      });

    this.sharedPackageService.currentPackageCategoryName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((categoryName: string) => {
        this.currentCategoryName = categoryName;
      });

    this.sharedPackageService.currentPackageLanguage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageLanguage: string) => {
        this.packageLanguage = packageLanguage;
      });

    this.getPackageInfo();
    this.getUserInfo();
  }

  getPackageInfo(): void {
    const packageId = this.currentSetId;
    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
            this.currentPack = packageInfo;
          },
          error => {
            console.log('Package info', error);
          });
    }
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        },
          error => {
            this.sharedService.checkSignatureExpired(error);
          });
    }
  }

  onDeleteSet(): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onDeleteConfirm(this.currentSetId);
      }
    });
  }

  onDeleteConfirm(packageId: number): void {
    const currentUser = this.sharedService.currentUser;
    if (currentUser && packageId > 0) {
      this.packageService.removePackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          // console.log('Package deleted');
          this.sharedPackageService.onRefreshPackage$.emit();
          this.router.navigate(['/questionnaires']);
        },
          error => console.log(error));
    } else {
      console.log('Can\'t delete the package');
    }
  }

  onRenameSet(setId: number, newName: string): void {
    if (setId > 0 && newName) {
      const updatedFields = {
        name: newName,
      };

      this.packageService.updatePackage(setId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((newPackage: Package) => {
          this.sharedPackageService.onRefreshPackage$.emit();
          this.sharedPackageService.currentPackageName$.next(newName);
        },
          error => console.log(error));
    } else {
      console.log('Can\'t rename the package');
    }
  }

  onRenameCategorySet(setId: number, newCategoryName: string): void {
    if (setId > 0 && newCategoryName) {
      const updatedFields = {
        category_name: newCategoryName,
      };

      this.packageService.updatePackage(setId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((newPackage: Package) => {
          this.sharedPackageService.onRefreshPackage$.emit();
          this.sharedPackageService.currentPackageCategoryName$.next(newCategoryName);
        },
          error => console.log(error));
    } else {
      console.log('Can\'t rename the package');
    }
  }

  onLanguageUpdate(setId: number, packageId: number): void {
    if (this.packageLanguage && (setId > 0)) {
      const updatedFields = {
        language: this.packageLanguage,
      };

      this.packageService.updatePackage(setId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.sharedPackageService.onRefreshPackage$.emit();
          this.sharedPackageService.currentPackageLanguage$.next(this.packageLanguage);
        },
          error => console.log(error));
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  copyPublicPackage(): void {
    const dialogRef = this.dialog.open(DialogResultComponent, {
      data: {
        dialogType: 'CopyPublicPackage',
        defaultName: this.currentPack.name,
      },
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newName: string) => {
          this.isGottenPack = true;
          if (newName) {
            const companyId = this.activeCompanyId;
            this.createFromStandardHelper.addNewLocalPackage(newName, companyId, this.currentPack);
            this.router.navigateByUrl(`/`)
              .then(() => {
                this.router.navigateByUrl(`/questionnaires`);
              });
          }
        },
        error => console.log(error));
  }

}
