import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular';
import { Integrations } from "@sentry/tracing";

if (environment.production) {
  Sentry.init({
    dsn: "https://4361a9bf51bc426db6657099b3911863@o877443.ingest.sentry.io/5828597",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        // tracingOrigins: ["localhost", "https://app.teamheat.tech/", "https://staging-overmind.dotwrk.com/"],
        tracingOrigins: ["https://app.teamheat.tech/", "https://staging-overmind.dotwrk.com/"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  
  enableProdMode();
  console.log = function(): void {};
}

// platformBrowserDynamic().bootstrapModule(AppModule);
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));