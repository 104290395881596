<div *ngIf="isLoading" class="loading-container">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" class="colleague-notes">
  <div class="colleague-notes__content">
    <ng-container *ngIf="isViewMode && colleagueNotes.length > 0">
      <div class="colleague-notes__body">
        <div *ngFor="let note of colleagueNotes |
          paginate: {
            itemsPerPage: 10,
            currentPage: page,
            totalItems: notesLength
          }"
          class="colleague-note mat-elevation-z1">
          <div class="colleague-note__header">
            <h2 class="colleague-note__title">
              {{note.title}}
            </h2>
            <div class="colleague-note__info">
              <div class="colleague-note__date">
                {{note.updatedAt}}
              </div>
              <div class="colleague-note__author">
                {{note.user.firstName}} {{note.user.lastName}}
              </div>
              <button
                *ngIf="!isPublicProfile"
                (click)="onEditMode(note)"
                class="colleague-note__button colleague-note__button--edit"
                i18n="@@edit">
                Edit
              </button>
            </div>
          </div> <!-- /.colleague-note__header -->
          <div class="colleague-note__content" [innerHtml]="note.content">
          </div> <!-- /.colleague-note__content -->
        </div> <!-- /.colleague-note -->
      </div>

      <div class="pagination-container">
        <pagination-controls
          [previousLabel]="previousLabel"
          [nextLabel]="nextLabel"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </div>
    </ng-container>

    <ng-container *ngIf="isViewMode && colleagueNotes.length === 0">
      <div class="no-data">
        <p class="no-data__message" i18n="@@emptyNotes">This employee has no notes</p>
      </div>
    </ng-container>

    <ng-container *ngIf="isEditMode || isCreateMode">
      <div class="edit-note">
        <input
          [(ngModel)]="currentNote.title"
          class="edit-note__title"
          type="text"
          name="title"
          placeholder="Note title"
        >

        <quill-editor
          [modules]="modules"
          [(ngModel)]="currentNote.content">
        </quill-editor>

        <div class="edit-note__footer">
          <div class="edit-note__info">
            <div class="edit-note__author">
              {{currentNote.author}}
            </div>
            <div class="edit-note__date">
              {{currentNote.updatedAt}}
            </div>
          </div> <!-- /.edit-note__footer -->

          <div class="edit-note__buttons">
            <ng-container *ngIf="isEditMode; else createMode">
              <button (click)="confirmUpdateNote()" class="colleague-note__button" i18n="@@saveButton">Save</button>
              <button (click)="confirmDeleteNote()" class="colleague-note__button colleague-note__button--delete" i18n="@@deleteButton">Delete</button>
            </ng-container>
            <ng-template #createMode>
              <button (click)="confirmCreateNewNote()" class="colleague-note__button" i18n="@@createButton">Create</button>
            </ng-template>
          </div> <!-- /.edit-note__buttons -->
        </div> <!-- /.edit-note__footer -->
      </div> <!-- /.edit-note -->
    </ng-container>
  </div> <!-- /.colleague-notes__content -->

  <div class="colleague-notes__buttons">
    <button *ngIf="isViewMode && !isPublicProfile" (click)="onCreateMode()" class="colleague-note__button" i18n="@@createNewNote">
      Create new note
    </button>
    <button *ngIf="isEditMode || isCreateMode" (click)="onViewMode()" class="colleague-note__button" i18n="@@backButton">
      Back
    </button>
  </div>
</div> <!-- /.colleague-notes -->
