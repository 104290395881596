import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { PackageService } from '../_services/package.service';
import { SharedService } from '../_services/shared.service';
import { MembershipService } from '../_services/membership.service';
import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from '../snack-bar/snack-bar.component';

@Component({
  selector: 'app-public-packages',
  templateUrl: './public-packages.component.html',
  styleUrls: ['./public-packages.component.scss'],
  providers: [SnackBarComponent]
})
export class PublicPackagesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  packages: any = [];

  constructor(
    private packageService: PackageService,
    private sharedService: SharedService,
    private membershipService: MembershipService,
    private snackBar: SnackBarComponent) { }

  ngOnInit(): void {
    this.packageService.getPublicPackagesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packList) => {
        this.packages = packList;
        this.addEmptySlots();
      },
      error => {
        const error_object = JSON.parse(error._body);
        this.openSnackBar(error_object['message']);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addEmptySlots(): void {
    const count = this.packages.length;
    if (count < 4) {
      for (let i = 0; i < (4 - count); i++) {
        this.packages.push({name: ''});
      }
    }
  }

  addPublicPackage(pack: any): void {
    const userEmail = this.sharedService.currentUser['email'];
    if (userEmail && pack) {
      this.membershipService.addMembership(pack.id, userEmail)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(res => {
          location.reload();
        },
        error => {
          const error_object = JSON.parse(error._body);
          this.openSnackBar(error_object['message']);
        });
    }

  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
