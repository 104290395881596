import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { ScoreDivision } from 'app/_models/score-division';
import { SharedPackageService } from 'app/_services/shared-package.service';

class CheckboxOption extends CardOption {
  selected: boolean = false;
}

@Component({
  selector: 'app-set-single-card-view',
  templateUrl: './set-single-card-view.component.html',
  styleUrls: ['./set-single-card-view.component.scss']
})
export class SetSingleCardViewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() card: QuestionnaireCard;

  isOptions: boolean = false;
  isTextQuestion: boolean = false;
  isScoreQuestion: boolean = false;
  isCheckboxesQuestion: boolean = false;
  isInfoCard: boolean = false;
  isRelativeQuestion: boolean = false;
  cardActionsCount: number;
  resultActions: CardAction[] = [];
  chosenRadioOption: CardOption;
  textOption: CardOption;
  scoreDivisions: ScoreDivision[] = [];
  textInputValue: string = '';
  checkboxOptions: CheckboxOption[] = [];
  isScoreMulticolor: boolean = false;
  multicolorGroupSize: number = 3;

  constructor(
    private sharedPackageService: SharedPackageService,
  ) { }

  ngOnInit(): void {
    this.bootstrapCard();

    this.sharedPackageService.reloadLocalCard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newCard: QuestionnaireCard) => {
        if (newCard.id === this.card.id) {
          this.card = newCard;
          this.bootstrapCard();
        }
      });
  }

  emptyCard(): void {
    this.isOptions = false;
    this.isTextQuestion = false;
    this.isScoreQuestion = false;
    this.isCheckboxesQuestion = false;
    this.scoreDivisions = [];
    this.checkboxOptions = [];
    this.resultActions = [];
  }

  bootstrapCard(): void {
    this.emptyCard();
    switch (this.card.cardType) {
      case 'options':
        this.isOptions = true;
        break;
      case 'text-input':
        this.textOption = this.card.cardOptions[0];
        this.isTextQuestion = true;
        this.addActiveTextActions();
        break;
      case 'relative':
        this.textOption = this.card.cardOptions[0];
        this.isRelativeQuestion = true;
        this.addActiveTextActions();
        break;
      case 'score':
        this.textOption = this.card.cardOptions[0];
        this.scoreDivisions = this.card.scoreDivisions;
        this.sortScoreDivisions(this.scoreDivisions);
        this.addActiveTextActions();
        this.isScoreQuestion = true;
        break;
      case 'relative-score':
        this.textOption = this.card.cardOptions[0];
        this.scoreDivisions = this.card.scoreDivisions;
        this.sortScoreDivisions(this.scoreDivisions);
        this.addActiveTextActions();
        this.isScoreQuestion = true;
        break;
      case 'checkboxes':
        this.isCheckboxesQuestion = true;
        this.addCheckboxOptions(this.card.cardOptions);
        break;
      case 'info-card':
        this.isInfoCard = true;
        break;
      case 'relative-options':
        this.isOptions = true;
        break;
      case 'relative-checkboxes':
        this.isCheckboxesQuestion = true;
        this.addCheckboxOptions(this.card.cardOptions);
        break;
      default:
        console.log('Wrong option type');
        break;
    }

    const colorScheme = this.card.divisionsColorScheme;
    if (colorScheme === 'multicolor') {
      this.isScoreMulticolor = true;
    } else {
      this.isScoreMulticolor = false;
    }
    const divisionsLength = this.card.scoreDivisions.length;
    this.multicolorGroupSize = divisionsLength;
    this.cardActionsCount = this.getActionsCount();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addCheckboxOptions(cardOptions: CardOption[]): void {
    for (const singleOption of cardOptions) {
      const newOption: CheckboxOption = {
        ...singleOption,
        selected: false,
      };

      this.checkboxOptions.push(newOption);
    }
  }

  addActiveTextActions(): void {
    const textOption = this.card.cardOptions[0];
    if (textOption) {
      this.addOptionActions(textOption.cardActions);
    }
  }

  addOptionActions(cardActions: CardAction[]): void {
    for (const singleAction of cardActions) {
      this.resultActions.push(singleAction);
    }
  }

  radioChange(event: any): void {
    if (event.value) {
      this.chosenRadioOption = event.value;
      this.resultActions = [];
      this.addOptionActions(this.chosenRadioOption.cardActions);
    }
  }

  getActionsCount(): number {
    let count = 0;
    for (const singleOption of this.card.cardOptions) {
      for (const singleAction of singleOption.cardActions) {
        count = count + 1;
      }
    }

    return count;
  }

  changeCheckboxOption(event: any): void {
    this.resultActions = [];
    for (const singleOption of this.checkboxOptions) {
      if (singleOption.selected === true) {
        this.addOptionActions(singleOption.cardActions);
      }
    }
  }

  sortScoreDivisions(scoreDivisions: ScoreDivision[]): void {
    scoreDivisions.sort(function (a: any, b: any): number {
      return b.value - a.value;
    });
  }

}
