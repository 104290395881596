import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SharedService } from 'app/_services/shared.service';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PackageParameter } from 'app/_models/package-parameter';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { PythonCalculationHelperComponent } from 'app/python-calculation-helper';

@Component({
  selector: 'app-edit-pq-action',
  templateUrl: './edit-pq-action.component.html',
  styleUrls: [
    '../../package-details.component.scss',
    '../create-pq-action/create-pq-action.component.scss']
})
export class PackageEditPQActionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(PythonCalculationHelperComponent, { static: false }) pythonComponent: PythonCalculationHelperComponent;

  @Input() editedPQAction: PostQuestionnaireAction;
  @Input() currentPackageId: number;
  @Output() updatePQAction: EventEmitter<any> = new EventEmitter<any>();

  pqAction: PostQuestionnaireAction;
  editPQActionMode: boolean = true;
  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];

  isActionValid: boolean = false;
  isActionSaving: boolean = false;
  isCustomCalculation: boolean = false;
  isSimpleCalculation: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService) {
  }

  ngOnInit(): void {
    const newPQAction: PostQuestionnaireAction = {
      id: this.editedPQAction.id,
      parameter: this.editedPQAction.parameter,
      text: this.editedPQAction.text,
      orderPosition: this.editedPQAction.orderPosition,
      actionType: this.editedPQAction.actionType,
    };

    if (this.editedPQAction.customCalculationId) {
      newPQAction.customCalculationId = this.editedPQAction.customCalculationId
    }

    this.pqAction = newPQAction;
    this.checkActionType(newPQAction.actionType);
    this.getUserInfo();

    this.sharedPackageService.packageParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameters: PackageParameter[]) => {
        this.separateParameters(parameters);
      });
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        }, error => {
          this.sharedService.checkSignatureExpired(error);
        });
    }
  }

  separateParameters(parametersList: PackageParameter[]): void {
    this.individualParameters = [];
    this.commonParameters = [];
    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  handleInput(inputValue: any): void {
    if (inputValue && inputValue.length > 0) {
      this.validateAction();
    } else {
      this.isActionValid = false;
    }
  }

  // setOptionValue(event: any): void {
  //   // console.log('EVENT ', event);
  // }

  // onSelectionChange(event: any): void {
  //   const newCalculationType = event.value;
  //   if (newCalculationType === 'custom') {
  //     this.isCustomCalculation = true;
  //     this.pqAction.text = 'CUSTOM_CALCULATION';
  //   } else {
  //     this.isCustomCalculation = false;
  //   }
  // }

  onSelectionChange(event: any): void {
    const newCalculationType = event.value;
    if (newCalculationType === 'custom-simple') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = true;
      this.pqAction.text = 'CUSTOM_CALCULATION_SIMPLE';
      this.validateAction();
    } else if (newCalculationType === 'custom') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = false;
      this.pqAction.text = 'CUSTOM_CALCULATION';
      this.pqAction.parameter = '';
      this.isActionValid = true;
    } else {
      this.isCustomCalculation = false;
      this.validateAction();
    }
  }

  checkActionType(actionType: string): void {
    if (actionType === 'custom-simple') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = true;
    } else if (actionType === 'custom') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = false;
      this.pqAction.parameter = '';
      this.isActionValid = true;
    } else {
      this.isCustomCalculation = false;
    }
  }

  onParameterChange(event: any): void {
    this.validateAction();
  }

  onSaveAction(): void {
    if (this.isCustomCalculation) {
      this.pythonComponent.onSaveAction();
    }
    this.isActionSaving = true;

    const dataModel = this.pqAction;
    this.updatePQAction.emit(dataModel);
    this.editPQActionMode = false;
    this.sharedService.editPQActionMode$.emit(this.editPQActionMode);
  }

  validateAction(): void {
    const actionType = this.pqAction.actionType;
    const parameter = this.pqAction.parameter;
    const text = this.pqAction.text;

    if (!actionType || !parameter || !text) {
      console.log('Not valid ', text);
      this.isActionValid = false;
    } else if (this.isActionSaving) {
      this.isActionValid = false;
    } else {
      this.isActionValid = true;
    }
  }

  goBack(): void {
    this.editPQActionMode = false;
    this.sharedService.editPQActionMode$.emit(this.editPQActionMode);
  }
}
