<div *ngIf="!isCompleted&&isActive&&!isInvalid&&!isLocked&&!isExperimentCompleted" class="card-wrapper active-questionnaire" flex>
  <mat-card class="question-card" *ngIf="isLoaded">
    <mat-card-header>
      <mat-card-title>
        <span i18n="@@question-label">Question</span> #{{questionTitleCounter}}
        <ng-container *ngIf="isRequired">
          <span style="color: green;" i18n="@@question-is-required">(required)</span>
        </ng-container>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div *ngIf="questionText" class="remove-all-styles" [innerHtml]="questionText | safeHtml">
      </div>

      <!-- OPTIONS QUESTION -->
      <div *ngIf="isOptionsQuestion">
        <mat-radio-group class="radio-group" [(ngModel)]="chosenOption">
          <mat-radio-button style="margin: 5px;" *ngFor="let option of cardOptions" [value]="option">
            {{option.text}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- OPTIONS QUESTION END -->

      <!-- TEXT QUESTION -->
      <div *ngIf="isTextQuestion">
        <mat-form-field>
          <textarea
            class="forautosize"
            [(ngModel)]="textOption"
            matInput
            autocomplete="off"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="6"
            cdkAutosizeMaxRows="50">
          </textarea>
          <mat-icon class="icon-resize-style">format_line_spacing</mat-icon>
        </mat-form-field>
      </div>
       <!-- TEXT QUESTION END -->

      <!-- RELATIVE QUESTION -->
      <div *ngIf="isRelativeQuestion">
        <mat-form-field>
          <textarea
            class="forautosize"
            [(ngModel)]="textOption"
            matInput
            autocomplete="off"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="6"
            cdkAutosizeMaxRows="50">
          </textarea>
          <mat-icon class="icon-resize-style">format_line_spacing</mat-icon>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Select department</mat-label>
          <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()" [disabled]="isRelativeSubmission">
            <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
            <mat-option value="0">Non-department</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="this.activeColleagues">
          <mat-label>Select colleague</mat-label>
          <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()" [disabled]="isRelativeSubmission">
            <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">{{colleague.firstName}} {{colleague.lastName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- RELATIVE QUESTION END -->

      <!-- RELATIVE OPTIONS QUESTION -->
      <div *ngIf="isRelativeOptionsQuestion">
        <mat-radio-group class="radio-group" [(ngModel)]="chosenOption">
          <mat-radio-button style="margin: 5px;" *ngFor="let option of cardOptions" [value]="option">
            {{option.text}}
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
          <mat-label>Select department</mat-label>
          <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()" [disabled]="isRelativeSubmission">
            <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
            <mat-option value="0">Non-department</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.activeColleagues">
          <mat-label>Select colleague</mat-label>
          <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()" [disabled]="isRelativeSubmission">
            <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">
              {{colleague.firstName}} {{colleague.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- RELATIVE OPTIONS QUESTION END -->

      <!-- RELATIVE CHECKBOX QUESTION -->
      <div *ngIf="isRelativeCheckboxesQuestion">
        <div class="checkbox-wrapper">
          <div class="checkboxes-group">
            <mat-checkbox *ngFor="let checkboxOption of cardCheckboxOptions" [value]="checkboxOption"
              (click)="addCheckboxOption(checkboxOption)" [(ngModel)]="checkboxOption.selected">
              {{checkboxOption.text}}
            </mat-checkbox>
          </div>
        </div>

        <mat-form-field>
          <mat-label>Select department</mat-label>
          <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()" [disabled]="isRelativeSubmission">
            <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
            <mat-option value="0">Non-department</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.activeColleagues">
          <mat-label>Select colleague</mat-label>
          <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()" [disabled]="isRelativeSubmission">
            <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">
              {{colleague.firstName}} {{colleague.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- RELATIVE CHECKBOX QUESTION END -->

      <!-- RELATIVE SCORE QUESTION -->
      <div *ngIf="isRelativeScoreQuestion">
        <ng-container *ngIf="isScoreMulticolor">
          <mat-radio-group class="score-group-multicolor" style="margin-bottom: 20px;" [(ngModel)]="textOption">
            <mat-radio-button *ngFor="let option of scoreDivisions" class="radio-group-{{multicolorGroupSize}}" [value]="option.value">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
        <ng-container *ngIf="!isScoreMulticolor">
          <mat-radio-group class="score-group" style="margin-bottom: 20px;" [(ngModel)]="textOption">
            <mat-radio-button *ngFor="let option of scoreDivisions" [value]="option.value">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

        <mat-form-field>
          <mat-label>Select department</mat-label>
          <mat-select [(ngModel)]="this.selectedDepartmentIndex" (selectionChange)="setSelectedDepartment()" [disabled]="isRelativeSubmission">
            <mat-option value="{{department.id}}" *ngFor="let department of inputDepartments">{{department.name}}</mat-option>
            <mat-option value="0">Non-department</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.activeColleagues">
          <mat-label>Select colleague</mat-label>
          <mat-select [(ngModel)]="this.selectedColleagueIndex" (selectionChange)="setSelectedColleague()" [disabled]="isRelativeSubmission">
            <mat-option value="{{colleague.id}}" *ngFor="let colleague of activeColleagues">
              {{colleague.firstName}} {{colleague.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- RELATIVE SCORE QUESTION END -->

      <!-- SCORE QUESTION -->
      <div *ngIf="isScoreQuestion" class="score-group-wrap">
        <ng-container *ngIf="isScoreMulticolor">
          <mat-radio-group class="score-group-multicolor" [(ngModel)]="textOption">
            <mat-radio-button *ngFor="let option of scoreDivisions" class="radio-group-{{multicolorGroupSize}}" [value]="option.value">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
        <ng-container *ngIf="!isScoreMulticolor">
          <mat-radio-group class="score-group" [(ngModel)]="textOption">
            <mat-radio-button *ngFor="let option of scoreDivisions" [value]="option.value">
              {{option.label}}
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
      </div>
      <!-- SCORE QUESTION END -->

      <!-- CHECKBOX QUESTION -->
      <div *ngIf="isCheckboxesQuestion" class="checkbox-wrapper">
        <div class="checkboxes-group">
          <mat-checkbox *ngFor="let checkboxOption of cardCheckboxOptions"
            [value]="checkboxOption"
            (click)="addCheckboxOption(checkboxOption)"
            [(ngModel)]="checkboxOption.selected">
              {{checkboxOption.text}}
          </mat-checkbox>
        </div>
      </div>
      <!-- CHECKBOX QUESTION END -->
    
      <p class="answer-error">
        {{cardError}}
      </p>
    </mat-card-content>
    <mat-card-actions>
      <div class="action-buttons">
        <button *ngIf="currentQuestionNumber > 1"
                mat-raised-button
                color="accent"
                (click)="onClickPreviousQuestion()"
                i18n="@@prev-label">
          BACK
        </button>
        <button *ngIf="!isLastQuestion"
                mat-raised-button
                color="accent"
                (click)="onClickNextQuestion()"
                i18n="@@next-label">
          NEXT
        </button>
        <button *ngIf="isLastQuestion"
                mat-raised-button
                color="primary"
                (click)="onClickNextQuestion()"
                i18n="@@finish-label">
          FINISH
        </button>
      </div>
    </mat-card-actions>
  </mat-card>

  <mat-spinner *ngIf="!isLoaded"></mat-spinner>
</div>

<div *ngIf="isInvalid" class="card-wrapper" flex>
  <mat-card>
    <mat-card-content style="align-items: center;">
      <p i18n="@@fail-allQuestionAnswered">
        Sorry! Questionnaire validation failed.
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isCompleted" class="card-wrapper" flex>
  <mat-card>
    <mat-card-content style="align-items: center;">
      <p i18n="@@confirmation-allQuestionAnswered">
        You answered all questions! Thank you for your time!
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isLocked" class="card-wrapper" flex>
  <mat-card>
    <mat-card-content style="align-items: center;">
      <p i18n="@@locked-allQuestionAnswered">
        You have already opened this questionnaire.
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="!isActive&&!isCompleted&&!isLocked" class="card-wrapper" flex>
  <mat-card>
    <mat-card-content style="align-items: center;">
      <p i18n="@@expired-allQuestionAnswered">
        Sorry! Questionnaire expired.
      </p>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isExperimentCompleted" class="card-wrapper" flex>
  <mat-card>
    <mat-card-content style="align-items: center;">
      <p i18n="@@expired-allQuestionAnswered">
        You have already completed this questionnaire.
      </p>
    </mat-card-content>
  </mat-card>
</div>
