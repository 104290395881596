import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';
import { ApiPythonCustomCalculation } from 'app/_api_models/api-python-custom-calculation';
import { environment } from '../../environments/environment';

export function parseApiPythonCustomCalculation(apiResponse: any): PythonCustomCalculation {
  let calculationObject;
  if (environment.production) {
    calculationObject = apiResponse;
  } else {
    calculationObject = ApiPythonCustomCalculation.check(apiResponse);
  }

  const newCustomCalculation: PythonCustomCalculation = {
    id: calculationObject.id,
    text_value: calculationObject.text_value
  };

  return newCustomCalculation;
}
