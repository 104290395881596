import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { ChartHelper } from '../_helpers/chart-helper';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';

import { SnackBarComponent } from 'app/snack-bar';
import { Experiment } from 'app/_models/experiment';
import { Questionnaire } from 'app/_models/questionnaire';

import { UtilsService } from 'app/_services/utils.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedPackageService } from 'app/_services/shared-package.service';

@Component({
  selector: 'app-passage-brief-statistics',
  templateUrl: './passage-brief-statistics.component.html',
  styleUrls: ['./passage-brief-statistics.component.scss', '../widgets.scss'],
  providers: [SnackBarComponent]
})
export class PassageBriefStatisticsComponent implements OnInit, OnDestroy {
  @Input() userView: boolean;
  @Input() testData: boolean;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();

  public experiments: Experiment[] = [];
  public lastRun: string = '';
  public nextRun: string = '';
  public passagePercentage: string = '-';
  public started: boolean = false;

  private currentLang: string = '';
  private questionnaireId: number = 0;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarComponent,
    private chartHelper: ChartHelper,
    private utilsService: UtilsService,
    private questionnaireService: QuestionnaireService,
    private sharedPackageService: SharedPackageService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.utilsService.getCurrentLanguage();

    this.getExperiments();
    this.getQuestionnaireDetails();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getExperiments(): void {
    this.sharedPackageService.getPackageExperiments()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((experiments: Experiment[]) => {
        if (experiments) {
          this.findPackagePercentage(experiments);
          this.experiments = experiments;
        }
      }, error => console.log(error));
  }

  getQuestionnaireDetails(): void {
    this.sharedPackageService.getQuestionnaireDetails()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((questionnaire: Questionnaire) => {
        this.setQuestionnaireDetails(questionnaire);
      }, error => console.log(error));
  }

  setQuestionnaireDetails(questionnaire: Questionnaire): void {
    if (questionnaire && questionnaire.id > 0) {
      this.questionnaireId = questionnaire.id;
      this.started = true;
      this.formQuestionnaireDetails(questionnaire);
    }
  }

  onSendReminder(): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Send reminder?',
        dialogSubtitle: 'Are you sure you want to send reminder?',
        dialogConfirmText: 'Yes',
        dialogConfirmType: 'reminder',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if ((result === 'confirm') && (this.questionnaireId > 0)) {
        this.questionnaireService.sendReminder(this.questionnaireId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.openSnackBar('Reminder sent');
          }, error => console.log(error));
      }
    });
  }

  formQuestionnaireDetails(questionnaire: Questionnaire): void {
    const timeFormat = 'Do MMMM, HH:mm';
    const currentTimeZone = moment.tz.guess();

    if (questionnaire.lastRun) {
      const lastRun = moment(questionnaire.lastRun).locale(this.currentLang).tz(currentTimeZone).format(timeFormat);
      this.lastRun = lastRun;
    }

    if (questionnaire.nextRun) {
      const daysEnabled = questionnaire.daysEnabled;
      const startOffset = questionnaire.startOffset;
      const nextRun = questionnaire.nextRun;

      let counter = 0;
      let validNextRun = null;

      while ((!validNextRun) && (counter < 7)) {
        const nextPossibleRun = moment(nextRun).add(counter, 'days');
        validNextRun = this.checkNextRun(nextPossibleRun, daysEnabled, startOffset);
        counter = counter + 1;
      }

      if (validNextRun) {
        const finishDate = moment(questionnaire.finishDate);
        const nextPossibleRun = moment(validNextRun).locale(this.currentLang);
        const isBefore = moment(nextPossibleRun).isSameOrBefore(finishDate);
        if (isBefore) {
          this.nextRun = moment(nextPossibleRun).tz(currentTimeZone).format(timeFormat);
        }
      }
    }
  }

  checkNextRun(possibleRun: any, daysEnabled: string, startOffset: number): any {
    const parsedDays = daysEnabled.split(',');

    for (const singleValue of parsedDays) {
      const nextRun = moment(possibleRun);
      const nextRunTimezone = moment(nextRun).add(startOffset, 'minutes');
      const dayCode = nextRunTimezone.tz('UTC').format('ddd');
      if (dayCode.toLowerCase() === singleValue.toLowerCase()) {
        return nextRun;
      }
    }

    return null;
  }

  findPackagePercentage(experiments: Experiment[]): void {
    if (experiments && (experiments.length > 0)) {
      const sortedExperiments = experiments.sort((a: any, b: any): number => b.id - a.id);
      const lastExperiment = sortedExperiments[0];
      const totalAnswersCount = lastExperiment.experimentAnswers.length;
      let completedAnswersCount = 0;
      for (const singleAnswer of lastExperiment.experimentAnswers) {
        if (singleAnswer.completed) {
          completedAnswersCount = completedAnswersCount + 1;
        }
      }
      const percentage = (completedAnswersCount / totalAnswersCount) * 100;
      this.passagePercentage = this.chartHelper.precise(percentage) + '%';
    } else {
      this.passagePercentage = '-';
    }
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
