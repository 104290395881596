import { Number, Array, Record, Partial, Boolean, String } from 'runtypes';
import { ApiSharedDashboardWidget } from 'app/_api_models/api-shared-dashboard-widget';
import { ApiPackage } from 'app/_api_models/api-package';
import { ApiSharedDashboardPackage } from 'app/_api_models/api-shared-dashboard-package';

export const ApiSharedDashboard = Record({
  id: Number,
  company_id: Number,
  dashboard_name: String,
  is_public: Boolean,
  public_link: String
}).And(Partial({
  shared_widgets: Array(ApiSharedDashboardWidget),
  packages: Array(ApiPackage),
  shared_dashboard_packages: Array(ApiSharedDashboardPackage)
}));
