import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar/snack-bar.component';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { DialogUploadFileComponent } from '../dialog-upload-file/';


import { CompanyService } from 'app/_services/company.service';

import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { ExtendedColleague } from '../local-models';
import { Router } from '@angular/router';
import { SharedService } from 'app/_services/shared.service';
import { Company } from 'app/_models/company';
import { environment } from 'environments/environment';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

@Component({
  selector: 'app-colleagues-departments',
  templateUrl: './colleagues-departments.component.html',
  styleUrls: ['./colleagues-departments.component.scss']
})
export class ColleaguesDepartmentsComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  baseUrl: string = environment.BASE_ANGULAR_URL;
  actionsEnabled: boolean = true;
  safeURL: any;
  videoURL: any = 'https://www.youtube.com/embed/-Kee4l12G5g';
  colleagueShareUrl: string = '';
  resultColleagueShareUrl: string = '';
  isRussianLocale: boolean = false;
  isLoading: boolean = true;
  isEmptyCompany: boolean = true;

  archivedColleaguesList: ExtendedColleague[] = [];
  colleaguesList: ExtendedColleague[] = [];
  departmentsList: Department[] = [];
  nonDepartmentColleaguesList: ExtendedColleague[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private snackBar: SnackBarComponent,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.getCompany();
    this.getColleaguesList();
    this.getDepartmentsList();
  }

  getCompany(): void {
    this.sharedCompanyDataService.companyInfo$
      .pipe(
        filter(companyInfo => companyInfo !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((companyInfo: Company) => {
        this.colleagueShareUrl = companyInfo.colleagueSharedLink;
        this.resultColleagueShareUrl = this.baseUrl + '/add-colleague/' + this.colleagueShareUrl;
      }, error => {
        console.log(error);
      });
  }

  getColleaguesList(): void {
    this.sharedCompanyDataService.colleaguesList$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleaguesList: ExtendedColleague[]) => {
        this.colleaguesList = colleaguesList;
        if (colleaguesList ){
          this.nonDepartmentColleaguesList = colleaguesList.filter(colleague => colleague.departmentId === 0);
        }
      });
  }

  getDepartmentsList(): void {
    this.sharedCompanyDataService.departmentsList$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((departmentsList: Department[]) => {
        this.departmentsList = departmentsList;
        this.isLoading = false;
      });
  }

  onRemoveDepartment(department: Department): void {
    if (department) {
      const departmentId = department.id;
      const dialogRef = this.dialog.open(CustomAlertDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'confirm' && departmentId) {
          this.onDepartmentDelete(department);
        }
      });
    }
  }

  onDepartmentDelete(department: Department): void {
    const promisesList = [];

    for (const singleColleague of department.colleagues) {
      const colleagueId = singleColleague.id;
      const singlePromise = this.onUnbindColleague(colleagueId);
      this.nonDepartmentColleaguesList.push(singleColleague);
      promisesList.push(singlePromise);
    }

    Promise.all(promisesList)
      .then((colleaguesResult: any) => {
        this.companyService.removeDepartment(department.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultDepartment: Department) => {
            this.departmentsList = this.departmentsList.filter(dep => dep.id !== department.id);
            this.sharedCompanyDataService.onColleaguesRefresh$.emit();
          }, error => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onUnbindColleague(colleagueId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (colleagueId && colleagueId > 0) {
        const updatedFields = {
          department_id: 0,
        };

        this.companyService.updateColleague(colleagueId, updatedFields)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultColleague: Colleague) => {
            resolve(resultColleague);
          },
            error => {
              reject(error);
            });
      } else {
        reject();
      }
    });
  }

  onInfoPage(colleagueId: number): void {
    this.router.navigate([`/colleagues/${colleagueId}/data`]);
  }

  onEditPerson(colleagueId: number): void {
    this.router.navigate([`/colleagues/${colleagueId}/edit`]);
  }

  onArchiveColleague(colleague: Colleague): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Archive colleague?',
        dialogSubtitle: 'Are you sure you want to archive colleague?',
        dialogConfirmText: 'Archive',
        dialogConfirmType: 'archive',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.archiveColleague(colleague);
      }
    });
  }

  archiveColleague(colleague: Colleague): void {
    const colleagueInfo = {
      archived: true,
    };

    colleague.archived = true;
    this.companyService.updateColleague(colleague.id, colleagueInfo)
      .subscribe(() => {
        this.sharedCompanyDataService.onColleaguesRefresh$.emit();
        this.checkColleagues();
        this.openSnackBar('Colleague archived');
      },
      error => {
        this.openSnackBar('Can\'t delete');
        console.log('Colleagues info', error);
      });
  }

  checkColleagues(): void {
    if (this.colleaguesList.length === 0 && this.nonDepartmentColleaguesList.length === 0) {
      this.sharedCompanyDataService.updateCompanyEmpty(true);
    }
  }

  goToAddNewColleaguePage(): void {
    this.router.navigate(['/colleagues/create-colleague']);
  }

  goToAddNewDepartmentPage(): void {
    this.router.navigate(['/company/create-department']);
  }

  addFromFile(): void {
    const dialogRef = this.dialog.open(DialogUploadFileComponent, {
      data: {
        modeDialogUpload: 'true'
      }
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {},
      error => console.log('Error', error));
  }

  createSharedLink(): void {
    const companyId = this.sharedService.currentCompanyId.getValue();
    if (companyId > 0) {
      this.companyService.createSharedLink(companyId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultInfo: Company) => {
          this.colleagueShareUrl = resultInfo.colleagueSharedLink;
          this.resultColleagueShareUrl = this.baseUrl + '/add-colleague/' + this.colleagueShareUrl;
          this.afterLinkCopied();
        }, error => {
          console.log(error);
        });
    }
  }

  afterLinkCopied(): void {
    let messageLinkCopied = 'Link copied';
    if (this.isRussianLocale) {
      messageLinkCopied = 'Ссылка скопирована';
    }
    this.openSnackBar(messageLinkCopied);
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
