import { Component, OnInit, OnDestroy, OnChanges,
  Input, EventEmitter, Output, SimpleChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { PackageParameter } from 'app/_models/package-parameter';

@Component({
  selector: 'app-edit-text-input-question',
  templateUrl: './edit-text-input-question.component.html',
  styleUrls: [
    './edit-text-input-question.component.scss',
    '../../../package-details.component.scss'
  ]
})
export class EditTextInputQuestionComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() questionText: string;
  @Input() cardType: string;
  @Input() editedInputOption: CardOption;
  @Output() onSaveQuestion: EventEmitter<any> = new EventEmitter<any>();

  @Input() individualParameters: PackageParameter[] = [];
  @Input() commonParameters: PackageParameter[] = [];
  @Input() relativeParameters: PackageParameter[] = [];
  localCardOption: CardOption;
  tooltipActionText: string = `Введите данные в формате 'Параметр1 -> Параметр1 + N(значение для вычисления)'
    ('Параметр1 ->' задается в левом поле)`;
  tooltipGotoText: string = `Введите номер вопроса, к которому нужно перейти в формате '#N', где N - номер вопроса`;

  constructor(
    private sharedPackageService: SharedPackageService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setLocalOption(this.editedInputOption);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.editedInputOption) {
      this.setLocalOption(changes.editedInputOption.currentValue);
    }
  }

  setLocalOption(inputCardOption: CardOption): void {
    this.localCardOption = inputCardOption;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setActionValue(event: any, cardAction: CardAction): void {
    // console.log('SET ACTION VALUE: ', event);
  }

  dropCardAction(event: CdkDragDrop<string[]>, cardActions: CardAction[]): void {
    moveItemInArray(cardActions, event.previousIndex, event.currentIndex);
    const movedCardActions = cardActions[event.previousIndex];
    this.updateCardActionPosition(movedCardActions, event.previousIndex + 1);
    this.renumerateCardActions(cardActions);
  }

  renumerateCardActions(cardActions: CardAction[]): void {
    let nextPosition = 1;
    for (const action of cardActions) {
      action.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  updateCardActionPosition(targetAction: CardAction, newActionIndex: number): void {
    if (targetAction && newActionIndex) {
      targetAction.orderPosition = newActionIndex;
    }
  }

  addCardOptionAction(createdOption: CardOption): void {
    const orderPosition = createdOption.cardActions.length + 1;
    const newAction: CardAction = {
      id: 0,
      text: '',
      parameter: '',
      orderPosition: orderPosition
    };
    createdOption.cardActions.push(newAction);
  }

  deleteOptionAction(activeOption: CardOption, cardActionIndex: number): void {
    // console.log('Delete option actions ', activeOption, cardActionIndex);
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        activeOption.cardActions.splice(cardActionIndex, 1);
        this.renumerateCardActions(activeOption.cardActions);
      }
    });
  }

  saveLocalQuestion(event: any): void {
    this.onSaveQuestion.emit(event);
  }

  getTextInputOption(): CardOption {
    return this.localCardOption;
  }

}
