<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header subheader">
    <div class="card-header-group">
      <h2 class="title">
        <span i18n="@@question-label">Question</span> #{{editedQuestion.orderPosition}}
      </h2>
      <div class="float-left">
        <button
          mat-raised-button
          (click)="goBack()"
          i18n="@@cancelButton">
            Cancel
        </button>
        <button
          [disabled]="!card.questionText"
          mat-raised-button
          color="accent"
          class="header-button"
          (click)="onSaveQuestion()"
          i18n="@@saveButton">
            <i class="material-icons">
              check
            </i>
            Save
        </button>
        <button
            class="header-button"
            mat-raised-button
            color="primary"
            (click)="restoreInitialCard()">
          Restore
        </button>
        
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="scrolled-block" detect-scroll (onScroll)="handleScroll($event)">
  <div class="left-content-part">
    <mat-card class="new-question-card">

      <h2 class="title" i18n="@@editQuestion-label">
        Edit question
      </h2>

      <div>
        <app-questionnaire-card-description 
          [cardId]="card.id" 
          [editMode]="true"
          [cardDescription]="card.questionText"
          (onSaveDescription)="onSaveDescription($event)">
        </app-questionnaire-card-description>

        <section class="required-section">
          <mat-checkbox 
            class="required-margin"
            labelPosition="before"
            [(ngModel)]="card.isRequired">
              Required question
          </mat-checkbox>
        </section>

        <mat-form-field>
          <mat-placeholder class="placeholder" i18n="@@questionType-label">Question Type</mat-placeholder>
          <mat-select [(ngModel)]="currentCardType" (selectionChange)="setOptionValue($event)">
            <mat-option value="options" i18n="@@questionType-options">Question with options</mat-option>
            <mat-option value="checkboxes" i18n="@@questionType-checkbox">Checkboxes question</mat-option>
            <mat-option value="text-input" i18n="@@questionType-text-input">Open-ended question</mat-option>
            <mat-option value="relative" i18n="@@questionType-relative">Relative question</mat-option>
            <mat-option value="relative-score" i18n="@@questionType-relative-score">Relative score question</mat-option>
            <mat-option value="relative-options" i18n="@@questionType-relative-options">Relative options question</mat-option>
            <mat-option value="relative-checkboxes" i18n="@@questionType-relative-checkboxes">Relative checkboxes question</mat-option>
            <mat-option value="score" i18n="@@questionType-score">Score question</mat-option>
            <mat-option value="info-card" i18n="@@questionType-info-card">Info card</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="card.cardType === 'options' || card.cardType === 'relative-options'">
        <app-edit-options-question
          [isRelative]="card.cardType === 'relative-options'"
          [editedCardOptions]="card.cardOptions"
          [questionText]="card.questionText"
          [individualParameters]="individualParameters"
          [relativeParameters]="relativeParameters"
          [commonParameters]="commonParameters"
          (onSaveQuestion)="onSaveQuestion($event)"
        >
        </app-edit-options-question>
      </div>

      <div *ngIf="card.cardType === 'checkboxes' || card.cardType === 'relative-checkboxes'">
        <app-edit-checkboxes-question
          [isRelative]="card.cardType === 'relative-checkboxes'"
          [editedCardOptions]="card.cardOptions"
          [questionText]="card.questionText"
          [individualParameters]="individualParameters"
          [relativeParameters]="relativeParameters"
          [commonParameters]="commonParameters"
          (onSaveQuestion)="onSaveQuestion($event)"
        >
        </app-edit-checkboxes-question>
      </div>

      <div *ngIf="card.cardType === 'text-input' || card.cardType === 'relative'">
        <app-edit-text-input-question
          [editedInputOption]="card.cardOptions[0]"
          [cardType]="card.cardType"
          [questionText]="card.questionText"
          [individualParameters]="individualParameters"
          [commonParameters]="commonParameters"
          [relativeParameters]="relativeParameters"
          (onSaveQuestion)="onSaveQuestion($event)"
        >
        </app-edit-text-input-question>
      </div>

      <div *ngIf="card.cardType === 'score' || card.cardType === 'relative-score'">
        <app-edit-score-question
          [cardType]="card.cardType"
          [editedScoreDivisions]="card.scoreDivisions"
          [editedInputOption]="card.cardOptions[0]"
          [colorScheme] = "card.divisionsColorScheme"
          [questionText]="card.questionText"
          [individualParameters]="individualParameters"
          [commonParameters]="commonParameters"
          [relativeParameters]="relativeParameters"
          (onSaveQuestion)="onSaveQuestion($event)"
        >
        </app-edit-score-question>
      </div>

      <div *ngIf="card.cardType === 'info-card'">
        <app-edit-info-card
          [questionText]="card.questionText"
          (onSaveQuestion)="onSaveQuestion($event)"
        >
        </app-edit-info-card>
      </div>

    </mat-card>
  </div>

  <div class="right-content-part">
    <app-hints-page 
      [currentPackageId]="currentPackageId" 
      [isQuestionHints]="true" 
      [isPQActionHints]="false">
    </app-hints-page>
  </div>

</div>
