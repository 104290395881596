import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { AuthenticationService } from './authentication.service';
import { parseApiColleagueNote } from 'app/_api_validation/parse-api-colleague-note';
import { parseApiParameterVisibility } from 'app/_api_validation/parse-api-parameter-visibility';

import { ColleagueNote } from 'app/_models/colleague-note';
import { PackageParameter } from 'app/_models/package-parameter';
import { ColleagueTable } from 'app/colleague-profile/local-models';
import { ParameterVisibility } from 'app/_models/parameter-visibility';

@Injectable({
  providedIn: 'root'
})
export class ColleagueService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  private colleagueNotes: string = environment.API_ENDPOINT + '/v1/colleague_notes';
  private colleagueTableConfig: string = environment.API_ENDPOINT + '/v1/colleague_table_configs';
  private parameterVisibilities: string = environment.API_ENDPOINT + '/v1/parameter_visibilities';

  createNote(title: string, content: string, userId: number, colleagueId: number): Observable<ColleagueNote> {
    const url = `${this.colleagueNotes}`;
    const note = JSON.stringify({ title: title, content: content, user_id: userId,
      colleague_id: colleagueId});

    return this.http
      .post<ColleagueNote>(url, note, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getNotesLength(id: number): Observable<number> {
    const url = `${this.colleagueNotes}/${id}/length`;

    return this.http
      .get<ColleagueNote[]>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getNotes(id: number, page: number): Observable<ColleagueNote[]> {
    const url = `${this.colleagueNotes}/${id}`;
    const data = {page: page};
    const headers = {
      'Content-Type':  'application/json',
      'Authorization': 'Bearer ' + this.authService.getAccessToken(),
    }

    return this.http
      .get<ColleagueNote[]>(url, {headers: headers, params: data})
      .pipe(
        map((response: any) => this.parseMultipleColleaguNotes(response.data)),
        catchError(this.handleError)
      );
  }

  updateNote(id: number, title: string, content: string): Observable<ColleagueNote> {
    const url = `${this.colleagueNotes}/${id}`;
    const note = JSON.stringify({ id: id, title: title, content: content});

    return this.http
      .put<ColleagueNote>(url, note, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  deleteNote(id: number): Observable<ColleagueNote> {
    const url = `${this.colleagueNotes}/${id}`;

    return this.http
      .delete<ColleagueNote>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getParameterVisibility(packageId: number): Observable<ParameterVisibility> {
    const url = `${this.parameterVisibilities}/${packageId}`;

    return this.http
      .get<ParameterVisibility>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseParameterVisibility(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getParameterVisibilities(packageParameters: PackageParameter[]): Observable<ParameterVisibility[]> {
    const url = `${this.parameterVisibilities}/all`;
    const parameterIds = this.getParameterIdsArray(packageParameters);
    const item = JSON.stringify(parameterIds);

    return this.http
      .post<ParameterVisibility>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleParameterVisibilities(response.data)),
        catchError(this.handleError)
      );
  }

  updateParameter(id: number, onColleagueCard: boolean, onColleaguePage: boolean): Observable<ParameterVisibility> {
    const url = `${this.parameterVisibilities}/${id}`;
    const parameter = JSON.stringify({ id: id, on_colleague_card: onColleagueCard, on_colleague_page: onColleaguePage});

    return this.http
      .put<ParameterVisibility>(url, parameter, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  updateTableConfig(table: ColleagueTable): Observable<ColleagueTable> {
    const url = `${this.colleagueTableConfig}/${table.tableConfigId}`;
    const tableConfig = JSON.stringify({ is_visible: table.isVisible, order_position: table.orderPosition});

    return this.http
      .put<ColleagueTable>(url, tableConfig, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  private parseParameterVisibility(apiResponse: any): ParameterVisibility {
    return parseApiParameterVisibility(apiResponse);
  }

  private parseMultipleParameterVisibilities(apiResponse: any): ParameterVisibility {
    const mappedData = apiResponse.map(x => this.parseParameterVisibility(x.attributes) as ParameterVisibility);
    return mappedData;
  }

  private parseColleagueNote(apiResponse: any): ColleagueNote {
    return parseApiColleagueNote(apiResponse);
  }

  private parseMultipleColleaguNotes(apiResponse: any): ColleagueNote {
    const mappedData = apiResponse.map(x => this.parseColleagueNote(x.attributes) as ColleagueNote);
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error);
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }

  private getParameterIdsArray(packageParameters: PackageParameter[]): any[] {
    const params = [];

    packageParameters.forEach((parameter) => {
      const param = {
        id: parameter.id
      };
      params.push(param);
    });

    return params;
  }
}
