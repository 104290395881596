import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { User } from 'app/_models/user';
import { AuthenticationService } from 'app/_services/authentication.service';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { UserService } from 'app/_services/user.service';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-editor',
  templateUrl: './dashboard-editor.component.html',
  styleUrls: ['./dashboard-editor.component.scss']
})
export class DashboardEditorComponent implements OnInit, OnDestroy {
  public dashboardConfigWidgets: DashboardWidget[] = [];

  private dashboardInitialWidgets: DashboardWidget[] = [];
  private deletedWidgets: DashboardWidget[] = [];
  private isCreatingDashboard: boolean = false;
  private dashboardConfigId: number = 0;
  private packageId: number = 0;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private dashboardService: DashboardService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getDashboardConfig();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getDashboardConfig(): void {
    const dashboardConfig = this.sharedPackageService.dashboardConfig$.value;

    this.dashboardService.getDashboardConfig(dashboardConfig.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultConfig: DashboardConfig) => {
        this.packageId = dashboardConfig.packageId;
        this.dashboardConfigId = dashboardConfig.id;
        this.dashboardConfigWidgets = resultConfig.dashboardWidgets;
        this.dashboardInitialWidgets = [...resultConfig.dashboardWidgets];
        this.sharedPackageService.dashboardConfig$.next(resultConfig);
        this.reorderWidgetsArray();
      }, error => {
        console.log('Error ', error);
      });
  }

  onSaveDashboard(): void {
    const newWidgets = this.dashboardConfigWidgets.filter(widget => widget.id === 0);
    const existingWidgets = this.dashboardConfigWidgets.filter(widget => widget.id !== 0);
    const deletedWidgets = this.deletedWidgets;

    let newWidgetsRequest: Observable<any> = new Observable();
    let existingWidgetsRequest: Observable<any> = new Observable();
    let deletedWidgetsRequest: Observable<any> = new Observable();
    let allRequest = [];

    if (newWidgets.length > 0) {
      newWidgetsRequest = forkJoin(newWidgets.map(widget => this.dashboardService.addDashboardWidget(widget)));
      allRequest = allRequest.concat(newWidgetsRequest);
    }

    if (existingWidgets.length > 0) {
      existingWidgetsRequest = forkJoin(existingWidgets.map(widget => this.dashboardService.updateDashboardWidget(widget)));
      allRequest = allRequest.concat(existingWidgetsRequest);
    }

    if (deletedWidgets.length > 0) {
      deletedWidgetsRequest = forkJoin(deletedWidgets.map(widget => this.dashboardService.removeDashboardWidget(widget)));
      allRequest = allRequest.concat(deletedWidgetsRequest);
    }

    forkJoin(allRequest).subscribe((result) => {
      console.log(result);
      this.getDashboardConfig();
      this.router.navigateByUrl(`/questionnaires/${this.packageId}/dashboard`);
    });
  }

  onBack(): void {
    this.router.navigateByUrl(`/questionnaires/${this.packageId}/dashboard`);
  }

  onAddTextWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const textWidget: DashboardWidget = {
      id: 0,
      widgetName: 'card-text-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(textWidget);
  }

  onAddHeatmapWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const heatmapWidget: DashboardWidget = {
      id: 0,
      widgetName: 'heatmap-individual-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(heatmapWidget);
  }

  onAddLineCommonWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const linepWidget: DashboardWidget = {
      id: 0,
      widgetName: 'line-common-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(linepWidget);
  }

  onAddIndividualChartWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const linepWidget: DashboardWidget = {
      id: 0,
      widgetName: 'individual-chart-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(linepWidget);
  }

  onAddCommonTextCardWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const linepWidget: DashboardWidget = {
      id: 0,
      widgetName: 'common-text-card-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(linepWidget);
  }

  onAddBriefStatistics(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const statisticsWidget: DashboardWidget = {
      id: 0,
      widgetName: 'brief-statistics-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(statisticsWidget);
  }

  onAddSeparatorWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const separatorWidget: DashboardWidget = {
      id: 0,
      widgetName: 'separator-widget',
      widgetTitle: 'This is the default text. You can change it in the settings.',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(separatorWidget);
  }

  onAddDescriptionCardWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const descriptionWidget: DashboardWidget = {
      id: 0,
      widgetName: 'description-card-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(descriptionWidget);
  }

  onAddIndividualParameterWidget(): void {
    const nextPosition = this.dashboardConfigWidgets.length + 1;
    const barWidget: DashboardWidget = {
      id: 0,
      widgetName: 'individual-parameter-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      dashboardConfigId: this.dashboardConfigId,
      widgetFilters: {}
    };
    this.dashboardConfigWidgets.push(barWidget);
  }

  onDeleteWidget(widget: any): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        const filterWidget = this.dashboardConfigWidgets.filter(dashboardWidget => dashboardWidget.orderPosition !== widget.orderPosition);
        this.deletedWidgets.push(widget);
        this.dashboardConfigWidgets = filterWidget;
        this.reorderWidgetsArray();
      }
    });
  }

  onWidgetUp(widget: any): void {
    const findWidget = this.dashboardConfigWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widget.orderPosition);
    if (findWidget) {
      const widgetPosition = findWidget.orderPosition;
      const widgetAbove = this.dashboardConfigWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widgetPosition - 1);
      if (widgetAbove) {
        const abovePosition = widgetAbove.orderPosition;
        widgetAbove.orderPosition = widgetPosition;
        findWidget.orderPosition = abovePosition;
        this.reorderWidgetsArray();
      }
    }
  }

  onWidgetDown(widget: any): void {
    const findWidget = this.dashboardConfigWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widget.orderPosition);
    if (findWidget) {
      const widgetPosition = findWidget.orderPosition;
      const widgetBelow = this.dashboardConfigWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widgetPosition + 1);
      if (widgetBelow) {
        const belowPosition = widgetBelow.orderPosition;
        widgetBelow.orderPosition = widgetPosition;
        findWidget.orderPosition = belowPosition;
        this.reorderWidgetsArray();
      }
    }
  }

  onFiltersUpdate(widgetFilters: any, widget: any): void {
    widget.widgetFilters = widgetFilters;
  }

  onTitleUpdate(newTitle: string, widget: any): void {
    widget.widgetTitle = newTitle;
  }

  reorderWidgetsArray(): void {
    const newConfig = [];
    const sortedWidgets = this.dashboardConfigWidgets.sort((a: any, b: any): number => a.orderPosition - b.orderPosition);

    let orderCount = 1;
    for (const singleWidget of sortedWidgets) {
      singleWidget.orderPosition = orderCount;
      newConfig.push(singleWidget);
      orderCount = orderCount + 1;
    }

    this.dashboardConfigWidgets = newConfig;
  }
}
