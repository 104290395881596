<div layout="row" layout-padding>

<div flex="10"></div>

<div flex>
	<p align="center">Available Questionnaires:</p>
	<mat-grid-list cols="4" rowHeight="120px">
	  <mat-grid-tile
	      *ngFor="let pack of packages"
	      [colspan]="1"
	      [rowspan]="1"
	      (click)="addPublicPackage(pack)"
	  >
	  	<div class="tile-content-style">
		    {{pack.name}}
		    <div *ngIf="pack.id" class="inner">
		    	<mat-icon color="accent">&#xE89C;</mat-icon>
		    </div>
	  	</div>
	  </mat-grid-tile>
	</mat-grid-list>
</div>


<div flex="10"></div>
</div>