import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CardOption } from 'app/_models/card-option';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { ScoreDivision } from 'app/_models/score-division';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class SortQuestionnaireHelper {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
  ) { }

  sortQuestionnaireCards(rawCards: QuestionnaireCard[]): QuestionnaireCard[] {
    const sortedCards: QuestionnaireCard[] = [];
    for (const card of rawCards) {
      this.sortCardOptions(card);
      if ((card.cardType === 'score') || (card.cardType === 'relative-score')) {
        this.sortScoreDivisions(card.scoreDivisions);
      }
      sortedCards.push(card);
    }

    sortedCards.sort(function (a: any, b: any): number {
      return a.orderPosition - b.orderPosition;
    });

    return sortedCards;
  }

  sortCardOptions(questionnaireCard: QuestionnaireCard): void {
    if (questionnaireCard) {
      questionnaireCard.cardOptions.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });

      for (const singleOption of questionnaireCard.cardOptions) {
        this.sortCardActions(singleOption);
      }
    }
  }

  sortCardActions(cardOption: CardOption): void {
    if (cardOption) {
      cardOption.cardActions.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });
    }
  }

  sortScoreDivisions(scoreDivisions: ScoreDivision[]): void {
    scoreDivisions.sort(function (a: any, b: any): number {
      return b.value - a.value;
    });
  }
}
