import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { Experiment } from 'app/_models/experiment';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { PackageParameter } from 'app/_models/package-parameter';

import { ChartHelper } from '../_helpers/chart-helper';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { ExtendedParameter, WidgetFilters } from '../_helpers/local-models';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-line-common-widget',
  templateUrl: './line-common-widget.component.html',
  styleUrls: ['./line-common-widget.component.scss', '../widgets.scss']
})
export class LineCommonWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  experiments: Experiment[] = [];
  widgetFilters: WidgetFilters = {};
  inputWidgetFilters: WidgetFilters;
  commonParameters: ExtendedParameter[] = [];

  updateFlag: boolean = false;
  showChartMode: boolean = true;
  parametersEditMode: boolean = false;
  isAllCommonParametersSelected: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'spline',
      marginTop: 40,
      marginBottom: 160,
      height: 500,
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },

    xAxis: {
      accessibility: {
        rangeDescription: ''
      },
      // tickInterval: 1,
      categories: []
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    tooltip: {
      valueSuffix: ''
    },
    series: [],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'vertical',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  };

  constructor(private localDashboardHelper: LocalDashboardHelper, private chartHelper: ChartHelper) { }

  ngOnInit(): void {
    const promisesList = [];
    const promiseParameters = this.localDashboardHelper.getCachedPackageParameters();
    promiseParameters.then((parameters: PackageParameter[]) => {
      const filterParams = parameters.filter(param => param.groupName === 'common' && param.commonValue.type === 'number');
      for (const parameter of filterParams) {
        this.commonParameters.push({
          ...parameter,
          isActive: true
        });
      }
    }).catch(error => console.log(error));

    const promiseExperiments = this.localDashboardHelper.getCachedExperimentsBrief(true);
    promiseExperiments.then((experiments: Experiment[]) => {
      if (experiments) {
        const sortedExperimens = experiments.sort(function (a: any, b: any): number {
          return a.id - b.id;
        });
        this.experiments = sortedExperimens;
      }
    }).catch(error => console.log(error));

    const promiseWidget = this.localDashboardHelper.getDashboardWidget(this.inputWidgetId);
    promiseWidget.then((widget: DashboardWidget) => {
      this.inputWidgetFilters = widget.widgetFilters;
    }).catch(error => console.log(error));

    promisesList.push(promiseParameters);
    promisesList.push(promiseExperiments);
    promisesList.push(promiseWidget);

    Promise.all(promisesList).then((result) => {
      this.checkInputWidgets();
      this.formNewChart();
    }).catch(error => console.log(error));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  restoreInputWidgetsFilters(): void {
    const paramsIds = this.inputWidgetFilters.activeParametersIds;

    if (paramsIds) {
      this.commonParameters = this.chartHelper.restoreInputWidgetsFilters(paramsIds, this.commonParameters);
    }
  }

  widgetFiltersUpdate(): void {
    this.widgetFilters['activeParametersIds'] = this.chartHelper.widgetFiltersUpdate(this.commonParameters);

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  setActiveParameters(): void {
    this.commonParameters = this.chartHelper.setActiveParameters(this.commonParameters, this.isAllCommonParametersSelected);
    this.isAllCommonParametersSelected = !this.isAllCommonParametersSelected;
    this.widgetFiltersUpdate();
  }

  formNewChart(): void {
    const newData = [];

    const filteredParameters = this.commonParameters.filter(param => param.isActive);
    for (const parameter of filteredParameters) {
      const newLine = this.formCommonParameterLine(parameter);
      newData.push(newLine);
    }

    if (newData.length === 0) {
      newData.push(this.chartHelper.formTestData());
    }

    this.chartOptions.series = newData;
    this.chartOptions.xAxis = {
      categories: this.chartHelper.formExperimentsLabels(this.experiments)
    },
    this.updateFlag = true;
  }

  formCommonParameterLine(parameter: ExtendedParameter): any {
    const lineData = [];

    for (const experiment of this.experiments) {
      const experimentParams = experiment.experimentParameters;
      const findParam = experimentParams.find(param => param.packageParameterId === parameter.id);
      if (findParam) {
        const numValue = Number(findParam.commonValue.value) || 0;
        const preciseValue = this.chartHelper.precise(numValue);
        lineData.push(preciseValue);
      } else {
        lineData.push(0);
      }
    }

    const newLine = {
      name: parameter.visibleName,
      type: 'spline',
      data: lineData
    };

    return newLine;
  }

  onParametersEditMode(): void {
    this.parametersEditMode = true;
    this.showChartMode = false;
  }

  onShowChartMode(): void {
    this.showChartMode = true;
    this.parametersEditMode = false;
    this.formNewChart();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }
}
