import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CompanyService } from './company.service';
import { PackageService } from './package.service';
import { ColleagueService } from './colleague.service';
import { ExperimentService } from './experiment.service';
import { SharedCompanyDataService } from './shared-company-data.service';

import { Package } from 'app/_models/package';
import { Department } from 'app/_models/department';
import { PackageParameter } from 'app/_models/package-parameter';
import { ExtendedColleague } from 'app/colleagues-page/local-models';
import { ParameterVisibility } from 'app/_models/parameter-visibility';
import { ColleagueTableParameter, ExtendedParameter } from 'app/colleague-profile/local-models';
import { ColleagueParameter } from 'app/_models/colleague-parameter';

@Injectable({
  providedIn: 'root'
})
export class CompanyDataService implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private colleagues: ExtendedColleague[] = [];
  private archivedColleagues: ExtendedColleague[] = [];
  private packageParameters: PackageParameter[] = [];
  private parameterVisibilities: ParameterVisibility[] = [];

  constructor(
    private companyService: CompanyService,
    private packageService: PackageService,
    private colleagueService: ColleagueService,
    private experimentService: ExperimentService,
    private sharedCompanyDataService: SharedCompanyDataService,
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getCompanyData(companyId: number): void {
    this.getPackagesList(companyId);
    this.getColleaguesList(companyId);
    this.onColleaguesRefresh(companyId);
  }

  public getPackagesList(companyId: number): void {
    this.packageService.getCompanyPackages(companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packages: Package[]) => {
        this.getVerbosePackages(packages);
      }, error => console.log(error));
  }

  private getVerbosePackages(packages: Package[]): void {
    forkJoin(
      packages.map(pack => this.packageService.getPublicPackage(pack.id))
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((verbosePackages: Package[]) => {
        this.sharedCompanyDataService.updatePackages(verbosePackages);
        this.getPackageParameters(verbosePackages);
      }, error => console.log(error));
  }

  private getPackageParameters(packages: Package[]): void {
    let packageParameters: PackageParameter[] = [];

    packages.forEach((pack: Package) => {
      packageParameters = packageParameters.concat(pack.packageParameters);
    });

    this.sharedCompanyDataService.updatePackageParameters(packageParameters);
    this.getParameterVisibilities(packageParameters);
    this.packageParameters = packageParameters;
  }

  private getParameterVisibilities(packageParameters: PackageParameter[]): void {
    this.colleagueService.getParameterVisibilities(packageParameters)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameterVisibilities: ParameterVisibility[]) => {
        this.sharedCompanyDataService.addParameterVisibilities(parameterVisibilities);
        this.parameterVisibilities = parameterVisibilities;
        this.formExtendedParameters(packageParameters, parameterVisibilities);
        this.getColleaguesAnswers();
      }, error => console.log(error));
  }

  private formExtendedParameters(packageParameters: PackageParameter[], parameterVisibilities: ParameterVisibility[]): void {
    const extendedParameters: ExtendedParameter[] = [];
    const filterdedParameters = packageParameters.filter(parameter => parameter.groupName !== 'common');

    filterdedParameters.forEach((packageParameter: PackageParameter) => {
      const parameterVisibility = parameterVisibilities.find(param => param.packageParameterId === packageParameter.id);

      const newExtendedParameter: ExtendedParameter = {
        ...packageParameter,
        parameterVisibilitiesId: parameterVisibility.id,
        packageParameterId: parameterVisibility.packageParameterId,
        onColleagueCard: parameterVisibility.onColleagueCard,
        onColleaguePage: parameterVisibility.onColleaguePage
      };

      extendedParameters.push(newExtendedParameter);
    });

    this.sharedCompanyDataService.addExtendedParameters(extendedParameters);
  }

  private getColleaguesAnswers(): void {
    combineLatest([
      this.sharedCompanyDataService.packageParameters$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.parameterVisibilities$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.colleaguesList$.pipe(filter(result => result !== null))
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        const packageParameters = result[0];
        const parameterVisibilities = result[1];
        const colleaguesList = result[2];
        if (colleaguesList.length > 0 &&
            packageParameters.length > 0 &&
            parameterVisibilities.length > 0) {
          this.getColleaguesParameters();
        }
      }, error => console.log(error));
  }

  private getColleaguesParameters(): void {
    this.experimentService.getColleaguesParameters(this.colleagues)
      .subscribe((colleagueParameters: ColleagueParameter[]) => {
        this.formIndividualParameters(colleagueParameters);
      }, error => console.log(error));
  }

  private formIndividualParameters(colleagueParameters: ColleagueParameter[]): void {
    const resultParameters: ColleagueTableParameter[] = [];

    for (const singleParameter of colleagueParameters) {
      if (singleParameter.value === 0 && singleParameter.isNullValue) {
        continue;
      }
      const packageParam = this.packageParameters.find(param => param.id === singleParameter.packageParameterId &&
        param.commonValue.type === 'number');

      if (packageParam) {
        const parameterVisibility = this.parameterVisibilities.find(param => param.packageParameterId === packageParam.id);
        const parameterName = packageParam.visibleName;
        const onColleagueCard = parameterVisibility.onColleagueCard;
        const onColleaguePage = parameterVisibility.onColleaguePage;
        const packageParameterId = packageParam.id;
        const parameterValue = singleParameter.value.toFixed(2);

        const tableParam: ColleagueTableParameter = {
          parameterName,
          parameterValue,
          onColleagueCard,
          onColleaguePage,
          packageParameterId,
          colleagueId: singleParameter.colleagueId
        };

        resultParameters.push(tableParam);
      }
    }

    this.sharedCompanyDataService.updateColleagueParameters(resultParameters);
  }

  private getColleaguesList(companyId: number): void {
    this.companyService.getPublicColleaguesList(companyId)
      .pipe(
        filter(colleagues => colleagues && colleagues.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleagues: ExtendedColleague[]) => {
        this.archivedColleagues = colleagues.filter(colleague => colleague.archived);
        this.colleagues = colleagues.filter(colleague => !colleague.archived);
        this.sortColleagues();
        this.sharedCompanyDataService.updateArchivedColleaguesList(this.archivedColleagues);
        this.getDepartmentsList(companyId);

        if (this.colleagues.length > 0) {
          this.sharedCompanyDataService.updateCompanyEmpty(false);
        } else {
          this.sharedCompanyDataService.updateCompanyEmpty(true);
        }
      }, error => console.log(error));
  }

  private getDepartmentsList(companyId: number): void {
    this.companyService.getBriefDepartments(companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((departments: Department[]) => {
        this.sharedCompanyDataService.updateDepartmentsList(departments);
        this.setDepartmentsNameToColleagues(departments);
      }, error => console.log(error));
  }

  private setDepartmentsNameToColleagues(departments: Department[]): void {
    const colleaguesList: ExtendedColleague[] = this.colleagues.filter(colleague => colleague.departmentId === 0);

    departments.forEach((department: Department) => {
      this.colleagues.forEach((colleague: ExtendedColleague) => {
        if (department.id === colleague.departmentId) {
          const newColleague: ExtendedColleague = colleague;
          newColleague.departmentName = department.name;
          colleaguesList.push(newColleague);
        }
      });
    });

    this.sharedCompanyDataService.updateColleaguesList(colleaguesList);
  }

  private onColleaguesRefresh(companyId: number): void {
    this.sharedCompanyDataService.onColleaguesRefresh$
      .subscribe(() => {
        this.getColleaguesList(companyId);
      });
  }

  private sortColleagues(): void {
    this.colleagues.sort((a, b) => a.firstName.localeCompare(b.firstName));
    this.archivedColleagues.sort((a, b) => a.firstName.localeCompare(b.firstName));
  }
}
