<!-- COLLEAGUES -->
<section class="colleagues">
  <ng-container *ngIf="!isCompanyEmpty; else emptyCompany">
    <div *ngIf="isLoading" class="loading-container">
      <app-loading-spinner></app-loading-spinner>
    </div>

    <div *ngIf="!isLoading">
      <div class="colleagues-header">
        <div class="colleagues-header__main">
          <mat-form-field class="colleagues-search">
            <mat-label i18n="@@search">Search</mat-label>
            <input
              matInput type="text"
              [(ngModel)]="colleagueSearch"
              (input)="onColleagueSearch()"
              class="colleagues-search__input">
          </mat-form-field>
          <div class="departments-filter">
            <mat-chip-list aria-label="Departments" selectable multiple>
              <mat-chip
                class="departments-filter__item"
                *ngFor="let department of departmentsList"
                selected="{{department.isSelected}}"
                (click)="onSelectDepartment(department)">
                {{department.name}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div> <!-- /.colleagues-header -->

      <div class="colleagues-content">
        <ng-container *ngIf="colleaguesList.length > 0; else colleaguesNotFound">
          <ng-container *ngIf="departmentsList.length > 0">
            <ng-container *ngFor="let department of departmentsList">
              <div class="department" *ngIf="department.isSelected">
                <div class="department__header">
                  <h2 class="department__title">{{department.name}}</h2>
                  <div class="department__divider"></div>
                </div>
                <div class="department__colleagues">
                  <ng-container *ngFor="let colleague of colleaguesList">
                    <app-colleague-card *ngIf="colleague.departmentName === department.name"
                      [colleague]="colleague"
                      [isArchive]="false"
                      (onArchive)="onArchiveColleague($event)">
                    </app-colleague-card>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="department" *ngIf="isNonDepartmentColleaguesExist">
            <div class="department__header">
              <h2 class="department__title" i18n="@@nonDepartmentsColleagues">Non-department colleagues</h2>
              <div class="department__divider"></div>
            </div>
            <div class="department__colleagues">
              <ng-container *ngFor="let colleague of colleaguesList">
                <app-colleague-card *ngIf="colleague.departmentId === 0"
                  [colleague]="colleague"
                  [isArchive]="false"
                  (onArchive)="onArchiveColleague($event)">
                </app-colleague-card>
              </ng-container>
            </div> <!-- Non-deparment colleagues list -->
          </div> <!-- Non-department colleagues container -->
        </ng-container> <!-- If colleaguesList.length > 0-->
      </div> <!-- /.colleagues-content -->
    </div> <!-- If content is loaded -->
  </ng-container>
</section>
<!-- COLLEAGUES END -->
<div class="colleagues-content__list">
<!-- If company has no employees -->
<ng-template #emptyCompany>
  <div class="warning-message">
    <div class="warning-message__description" i18n="@@noColleagues">
      Your company has no colleagues
    </div>
    <div class="colleagues-management">
      <a class="colleagues-management__button add-person" routerLink="/colleagues/create-colleague" i18n="@@addNewColleague">
        Add new colleague
      </a>
    </div>
  </div>
</ng-template>

<!-- If the colleague search returned no results -->
<ng-template #colleaguesNotFound>
  <div class="warning-message" i18n="@@colleaguesNotFound">
    Colleagues not found
  </div>
</ng-template>
