import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { PackageParameter } from 'app/_models/package-parameter';
import { Questionnaire } from 'app/_models/questionnaire';
import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { PackageParticipant } from 'app/_models/package-participant';

@Component({
  selector: 'app-dashboard-colleague',
  templateUrl: './dashboard-colleague.component.html',
  styleUrls: ['./dashboard-colleague.component.scss']
})
export class DashboardColleagueComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  validationFailed: boolean = false;
  isBootstrapped: boolean = false;
  activeDashboard: SharedDashboard;
  sharedQuestionnaires: Questionnaire[] = [];
  sharedPackageParameters: PackageParameter[] = [];
  sharedPackageParticipants: PackageParticipant[] = [];

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.validateDashboard(params['token']);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  validateDashboard(token: string): void {
    if (token) {
      this.dashboardService.getPublicDashboard(token)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((sharedDashboard: SharedDashboard) => {
          if (sharedDashboard) {
            this.activeDashboard = sharedDashboard;
            const promisesList = [];
            promisesList.push(this.getSharedQuestionnaires());
            promisesList.push(this.getSharedPackageParameters());
            promisesList.push(this.getSharedPackageParticipants());
            this.sharedPackageParameters = [];
            this.sharedQuestionnaires = [];
            this.sharedPackageParticipants = [];
            Promise.all(promisesList).then((result) => {
              this.sharedQuestionnaires = result[0];
              for (const singlePackageParams of result[1]) {
                for (const singleParam of singlePackageParams) {
                  this.sharedPackageParameters.push(singleParam);
                }
              }
              for (const participantsArray of result[2]) {
                for (const singleParticipant of participantsArray) {
                  this.sharedPackageParticipants.push(singleParticipant);
                }
              }
              this.isBootstrapped = true;
            }).catch(error => console.log(error));
          } else {
            this.validationFailed = true;
          }
        },
        error => {
          console.log('Error: shared dashboard ', error);
        });
    }
  }

  getSharedQuestionnaires(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const questionnaireId = singlePackage.questionnaire.id;
        promisesList.push(this.loadSingleQuestionnaire(questionnaireId));
      }
      Promise.all(promisesList).then((result) => {
        resolve(result);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadSingleQuestionnaire(questionnaireId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.getQuestionnaireBrief(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          resolve(resultQuestionnaire);
        });
    });
  }

  getSharedPackageParameters(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const packageId = singlePackage.id;
        promisesList.push(this.loadPackageParameters(packageId));
      }
      Promise.all(promisesList).then((resultParams) => {
        resolve(resultParams);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadPackageParameters(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getPackageParameters(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameters: PackageParameter[]) => {
          resolve(resultParameters);
        });
    });
  }

  getSharedPackageParticipants(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const packageId = singlePackage.id;
        promisesList.push(this.loadPackageParticipants(packageId));
      }
      Promise.all(promisesList).then((resultParticipants) => {
        resolve(resultParticipants);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadPackageParticipants(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getPackageParticipants(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParticipants: PackageParticipant[]) => {
          resolve(resultParticipants);
        }, error => reject(error));
    });
  }

}
