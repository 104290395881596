<h2 matDialogTitle class="title-class">{{dialogTitle}}</h2>
<mat-dialog-content>
  <p>{{dialogSubtitle}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button matDialogClose="confirm" color="warn">
    {{dialogConfirmText}}
  </button>
  <button mat-raised-button matDialogClose="cancel" color="primary">
    {{dialogCancelText}}
  </button>
<!--   <button mat-raised-button (click)="dialogRef.close()" color="primary">Cancel</button> -->
</mat-dialog-actions>