import { Boolean, Number, String, Array, Record, Partial, Null } from 'runtypes';
import { ApiQuestionnaire } from 'app/_api_models/api-questionnaire';
import { ApiPackageParameter } from 'app/_api_models/api-package-parameter';
import { ApiPostQuestionnaireAction } from 'app/_api_models/api-post-questionnaire-action';
import { ApiDashboardConfig } from 'app/_api_models/api-dashboard-config';

export const ApiPackage = Record({
  id: Number,
  is_public: Boolean,
  name: String,
  category_name: String,
  has_scale: Boolean,
  created_from_public: Boolean,
  questionnaire: ApiQuestionnaire.Or(Null),
  dashboard_config: ApiDashboardConfig.Or(Null),
  language: String,
  multiple_submissions: Boolean,
  send_to_colleagues: Boolean,
  colleague_link: String,
  max_submissions: Number,
  relative_question: Number,
  one_day_notifications: Boolean,
  half_term_notifications: Boolean
}).And(Partial({
  package_parameters: Array(ApiPackageParameter),
  post_questionnaire_actions: Array(ApiPostQuestionnaireAction),
  description: String.Or(Null),
  company_id: Number.Or(Null)
}));
