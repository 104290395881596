import { Component, EventEmitter, Inject, OnInit, Output,
  ViewEncapsulation, ViewChild, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from 'app/_services/company.service';
import { UserService } from 'app/_services/user.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { User } from 'app/_models/user';
import { SnackBarComponent } from 'app/snack-bar/';

export interface DialogData {
  modeDialogUpload: 'true' | 'false';
}

class LocalColleague {
  firstName: string;
  lastName: string;
  email: string;
  departmentId: number;
  departmentName: string;
}

const colleagueMaxCounter: number = 1000;

@Component({
  selector: 'app-dialog-upload-file',
  templateUrl: 'dialog-upload-file.component.html',
  styleUrls: ['./../colleagues-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SnackBarComponent]
})

export class DialogUploadFileComponent implements OnInit, OnDestroy  {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();

  dialogUploadFileMode: boolean;
  public dialogType: string = '';

  // acceptedFileTypes: string = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms - excel";
  acceptedFileTypes: string = ".csv";
  colleagueAddCounter: number = 0;
  hasActiveCompany: boolean = true;
  companyId: number;
  hasStructure: boolean = false;
  departmentOrder: number;
  firstNameOrder: number;
  surnameOrder: number;
  emailOrder: number;
  newColleagues: LocalColleague[] = [];
  colleagues: Colleague[] = [];
  archivedColleagues: Colleague[] = [];
  departments: Department[] = [];
  uploadDisabled: boolean = false;

  @ViewChild('fileInput', { static: false }) fileInput: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogUploadFileComponent>,
    private companyService: CompanyService,
    private userService: UserService,
    private amplitudeService: AmplitudeEventsService,
    private snackBar: SnackBarComponent) {}

  ngOnInit(): void {
    this.dialogUploadFileMode = true;
    const userInfo = JSON.parse(localStorage.getItem('currentUser'));
    if (userInfo) {
      this.getUserInfo(userInfo.user_id);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onLoadClick(value: any): void {
    if (this.fileInput) {
      this.fileInput._inputElement.nativeElement.click();
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectEvent(file);
    }
  }

  selectEvent(file: File): void {
    if (file) {
      const reader: FileReader = new FileReader();
      const encoding = "UTF-8";
      reader.readAsText(file, encoding);

      reader.onload = (e) => {
        this.uploadDisabled = true;
        if (e) {
          const csv: any = e.target['result'];
          this.hasStructure = false;
          if (csv) {
            this.amplitudeService.addEvent('add csv with colleagues');
            const lines = csv.split('\n');
            this.formStructure(lines[0]);
            for (let i = 1; i < lines.length; i++) {
              this.parseSingleRecord(lines[i]);
            }
            this.createNewColleaguesFromRecords();
          } else {
            this.openSnackBar('Wrong file format');
          }
        } else {
          this.openSnackBar('Wrong file format');
        }
      };

    }
  }

  getUserInfo(userId: number): void {
    if (userId && userId > 0) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          if (resultUser && resultUser.activeCompanyId) {
            this.hasActiveCompany = true;
            this.companyId = resultUser.activeCompanyId;
            this.getColleaguesList(this.companyId);
            this.getArchivedColleagues(this.companyId);
          }
        },
        error => {
          console.log('User info', error);
        });
    }
  }

  getColleaguesList(companyId: number): void {
    if (companyId && companyId > 0) {
      this.companyService.getColleaguesList()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((colleagues: Colleague[]) => {
            if (colleagues) {
              this.colleagues = colleagues;
              this.getCompanyDepartments();
            }
          },
          error => {
            console.log('Colleagues info', error);
          });
    }
  }

  getArchivedColleagues(companyId: number): void {
    if (companyId && companyId > 0) {
      this.companyService.getCompanyArchivedColleagues(companyId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultColleagues: Colleague[]) => {
          this.archivedColleagues = resultColleagues;
        },
          error => {
            console.log('Archived collegues error ', error);
          });
    }
  }

  getCompanyDepartments(): void {
    this.companyService.getCompanyDepartments()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
          this.departments = result;
        },
        error => {
          console.log('departments error ', error);
        });
  }

  getDepartmentId(departmentName: string): number {
    if (departmentName) {
      const departmentLowercase = departmentName.toLowerCase().trim();
      const department = this.departments.find(dep => dep.name.toLowerCase() === departmentLowercase);
      if (department) {
        return department.id;
      }
    }

    return 0;
  }

  parseSingleRecord(recordStr: string): void {
    if (recordStr && this.hasStructure) {
      const fieldsArray = recordStr.split(',');
      const lastName = fieldsArray[this.surnameOrder - 1];
      const firstName = fieldsArray[this.firstNameOrder - 1];
      const email = fieldsArray[this.emailOrder - 1];
      const departmentName = fieldsArray[this.departmentOrder - 1];

      this.addNewColleagueToList(firstName, lastName, email, departmentName);
    }
  }

  addNewColleagueToList(firstName: string, lastName: string, email: string, departmentName: string): void {
    if (firstName && lastName && email) {
      const companyId = this.companyId;
      const departmentId = this.getDepartmentId(departmentName);

      const newColleague: LocalColleague = {
        firstName: firstName,
        lastName: lastName,
        email: email.replace(/[\s\n\r]+/g, ''),
        departmentId: departmentId,
        departmentName: departmentName
      };

      this.newColleagues.push(newColleague);
    }
  }

  createNewColleaguesFromRecords(): void {
    const promisesList = [];
    const departmentsNames = [];
    for (const singleColleague of this.newColleagues) {
      const departmentName = singleColleague.departmentName;
      departmentsNames.push(departmentName);
    }

    const uniqueDepartments = this.getUniqueDepartments(departmentsNames);
    // console.log('UNique departments ', uniqueDepartments);
    for (const departmentName of uniqueDepartments) {
      const hasDepartment = this.checkDepartmentExists(departmentName);
      if (departmentName && !hasDepartment) {
        const promiseDepartment = this.createNewDepartment(departmentName);
        promisesList.push(promiseDepartment);
      }
    }

    Promise.all(promisesList)
      .then((result: any) => {
        this.checkNextColleagueToAdd();
      })
      .catch((error) => {
        console.log('Upload error ', error);
      });
  }

  getUniqueDepartments(departmentsNames: string[]): string[] {
    const newDepartments = [];
    for (const departmentName of departmentsNames) {
      newDepartments.push(departmentName.trim());
    }
    const uniqueDepartments = Array.from(new Set(newDepartments));
    return uniqueDepartments;
  }

  createNewDepartment(departmentName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const departmentInfo = {
        name: departmentName.trim(),
        colleagues: [],
        company_id: this.companyId,
      };

      this.companyService.addNewDepartment(departmentInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDepartment: Department) => {
          if (resultDepartment) {
            this.departments.push(resultDepartment);
            resolve(resultDepartment);
          } else {
            reject();
          }
        },
          error => {
            console.log(error);
            reject(error);
          });
    });
  }

  checkNextColleagueToAdd(): void {
    this.colleagueAddCounter = this.colleagueAddCounter + 1;
    const nextColleague = this.newColleagues.pop();

    if ((this.colleagueAddCounter < colleagueMaxCounter) && (nextColleague)) {
      const colleaguePromise = this.addNewColleague(nextColleague);
      colleaguePromise
        .then(newRecord => {
          this.onAddFromList(newRecord);
          this.checkNextColleagueToAdd();
        }).catch(error => {
          this.dialogRef.close();
          this.uploadDisabled = false;
        });
    } else {
      this.dialogRef.close();
      this.uploadDisabled = false;
      // console.log('Can\'t add new colleague');
    }
  }

  addNewColleague(colleague: LocalColleague): Promise<any> {
    return new Promise((resolve, reject) => {
      if (colleague) {
        const companyId = this.companyId;
        const departmentId = this.getDepartmentId(colleague.departmentName);

        const colleagueInfo = {
          name: colleague.firstName,
          last_name: colleague.lastName,
          email: colleague.email.replace(/[\s\n\r]+/g, ''),
          department_id: departmentId,
          bio: '',
          company_id: companyId,
        };

        const isDuplicate = this.checkDuplicateRecord(colleagueInfo.email);
        if (!isDuplicate) {
          this.companyService.addColleague(colleagueInfo)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((newRecord: Colleague) => {
              resolve(newRecord);
            },
              error => {
                reject(error);
              });
        } else {
          const colleagueId = this.getColleagueId(colleagueInfo.email);
          if (colleagueId) {
            this.companyService.updateColleague(colleagueId, colleagueInfo)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((newRecord: Colleague) => {
                resolve(newRecord);
              },
                error => {
                  reject(error);
                });
          } else {
            reject();
          }
        }

      } else {
        reject();
      }
    });
  }

  formStructure(recordStr: string): void {
    const fieldsArray = recordStr.split(',');

    this.hasStructure = true;
    for (let i = 0; i < fieldsArray.length; i++) {
      let resultField = fieldsArray[i].toLowerCase();
      resultField = resultField.replace(/\s+/g, '');
      if ((resultField === 'отдел') || (resultField === 'department')) {
        this.departmentOrder = i + 1;
      } else if ((resultField === 'email') || (resultField === 'почта')) {
        this.emailOrder = i + 1;
      } else if ((resultField === 'фамилия') || (resultField === 'lastname'))  {
        this.surnameOrder = i + 1;
      } else if ((resultField === 'имя') || (resultField === 'firstname')) {
        this.firstNameOrder = i + 1;
      }
    }
  }

  checkDepartmentExists(departmentName: string): boolean {
    for (const singleDeparment of this.departments) {
      if (singleDeparment.name === departmentName) {
        return true;
      }
    }
    return false;
  }

  checkDuplicateRecord(newPersonEmail: string): boolean {
    if (newPersonEmail) {
      const emailLowercase = newPersonEmail.toLowerCase();
      const emailInList = this.colleagues.find(person => person.email.toLowerCase() === emailLowercase);
      if (emailInList) {
        return true;
      }

      const archivedInList = this.archivedColleagues.find(person => person.email.toLowerCase() === emailLowercase);
      if (archivedInList) {
        return true;
      }
    }

    return false;
  }

  getColleagueId(colleagueEmail: string): number {
    const emailLowercase = colleagueEmail.toLowerCase();
    const colleagueInList = this.colleagues.find(person => person.email.toLowerCase() === emailLowercase);
    if (colleagueInList) {
      return colleagueInList.id;
    }

    const archivedInList = this.archivedColleagues.find(person => person.email.toLowerCase() === emailLowercase);
    if (archivedInList) {
      return archivedInList.id;
    }
  }

  onAddFromList(person: Colleague): void {
    if (person) {
      this.colleagues.push(person);

      this.colleagues = this.colleagues.map((singlePerson: Colleague) => {
        return singlePerson;
      });
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
