import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Colleague } from 'app/_models/colleague';
import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { ColleagueParameter } from 'app/_models/colleague-parameter';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';

import { parseApiExperiment } from 'app/_api_validation/parse-api-experiment';
import { parseApiExperimentVerbose } from 'app/_api_validation/parse-api-experiment-verbose';
import { parseApiExperimentAnswer } from 'app/_api_validation/parse-api-experiment-answer';
import { parseApiExperimentParameter } from 'app/_api_validation/parse-api-experiment-parameter';
import { parseApiColleagueCardAnswer } from 'app/_api_validation/parse-api-colleague-card-answer';
import { parseApiColleagueParameter } from 'app/_api_validation/parse-api-colleague-parameter';
import { parseApiRelativeExperimentAnswer } from 'app/_api_validation/parse-api-relative-experiment-answer';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { DemoParameter } from 'app/_models/demo-parameter';
import { parseApiDemoView } from 'app/_api_validation/parse-api-demo-view';
import { ShortUrl } from 'app/_models/short-url';
import { parseApiShortUrl } from 'app/_api_validation/parse-api-short-urls';

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {
  private experimentsUrl: string = environment.API_ENDPOINT + '/v1/experiments';
  private experimentsVerboseUrl: string = environment.API_ENDPOINT + '/v1/experiments_verbose';
  private resetExperimentUrl: string = environment.API_ENDPOINT + '/v1/reset_experiment';
  private experimentAnswersUrl: string = environment.API_ENDPOINT + '/v1/experiment_answers';
  private relativeExperimentAnswersUrl: string = environment.API_ENDPOINT + '/v1/relative_experiment_answers';
  private colleagueCardAnswersUrl: string = environment.API_ENDPOINT + '/v1/colleague_card_answers';
  private multipleColleagueCardAnswersUrl: string = environment.API_ENDPOINT + '/v1/multiple_colleague_card_answers';
  private sendMailUrl: string = environment.API_ENDPOINT + '/v1/send_experiment_mail';
  private colleagueParametersUrl: string = environment.API_ENDPOINT + '/v1/colleague_parameters';
  private relativeParametersUrl: string = environment.API_ENDPOINT + '/v1/relative_parameters';
  private experimentParametersUrl: string = environment.API_ENDPOINT + '/v1/experiment_parameters';
  private calculateExperimentAnswerUrl: string = environment.API_ENDPOINT + '/v1/calculate_experiment_answer';
  private calculateDemoModeParametersUrl: string = environment.API_ENDPOINT + '/v1/calculate_demo_mode_parameters';
  private calculatePythonHelperUrl: string = environment.API_ENDPOINT + '/v1/calculate_python_helper';
  private calculatePythonHelperVerboseUrl: string = environment.API_ENDPOINT + '/v1/calculate_python_helper_verbose';
  private showPythonContextUrl: string = environment.API_ENDPOINT + '/v1/show_python_context';
  private logAnswerStatusUrl: string = environment.API_ENDPOINT + '/v1/log_answer_status';
  private experimentLinksUrl: string = environment.API_ENDPOINT + '/v1/short_urls';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  logExperimentAnswer(status: string, userEmail: string, answerId: number): Observable<any> {
    const url = this.logAnswerStatusUrl;
    const item = JSON.stringify({
      status: status,
      user_email: userEmail,
      answer_id: answerId,
    });
    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  calculatePythonTest(code: string, packageId: number): Observable<any> {
    const url = this.calculatePythonHelperUrl;
    const item = JSON.stringify({
      code: code,
      package_id: packageId
    });
    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  calculatePythonVerbose(code: string, packageId: number): Observable<any> {
    const url = this.calculatePythonHelperVerboseUrl;
    const item = JSON.stringify({
      code: code,
      package_id: packageId
    });
    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  showPythonContext(code: string, packageId: number): Observable<any> {
    const url = this.showPythonContextUrl;
    const item = JSON.stringify({
      code: code,
      package_id: packageId
    });
    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getExperiment(id: number): Observable<Experiment> {
    const url = `${this.experimentsUrl}/${id}`;
    return this.http
      .get<Experiment>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getVerboseExperiments(experiments: Experiment[]): Observable<Experiment[]> {
    const url = `${this.experimentsVerboseUrl}/all`;
    const experimentsIds = this.getColleaguesIdsArray(experiments);
    const param = JSON.stringify(experimentsIds);

    return this.http
      .post<Experiment>(url, param, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const verboseExperiments = [];

          response.forEach(experiment => {
            const verboseExperiment = JSON.parse(experiment);
            const attributes = verboseExperiment.data.attributes;
            const included = verboseExperiment.included;

            verboseExperiments.push(this.parseExperimentVerbose(attributes, included));
          });

          return verboseExperiments;
        }),
        catchError(this.handleError)
      );
  }

  getExperimentVerbose(id: number): Observable<Experiment> {
    const url = `${this.experimentsVerboseUrl}/${id}`;
    return this.http
      .get<Experiment>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('Response ', response);
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentVerbose(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getLastExperimentVerbose(id: number): Observable<Experiment> {
    const url = `${this.experimentsVerboseUrl}/${id}/last`;
    return this.http
      .get<Experiment>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (!response || !response.data) return null;

          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentVerbose(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addExperiment(experiment: Experiment): Observable<Experiment> {
    const url = this.experimentsUrl;
    const item = JSON.stringify({
      package_id: experiment.packageId,
      questionnaire_id: experiment.questionnaireId,
      start_date: experiment.startDate,
      finish_date: null,
      completed: false,
      calculated: false,
      experiment_answers: [],
      experiment_parameters: [],
    });

    return this.http
      .post<Experiment>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addNewExperimentAnswer(completedAnswer: ExperimentAnswer): Observable<ExperimentAnswer> {
    const url = this.experimentAnswersUrl;
    const item = JSON.stringify({
      experiment_id: completedAnswer.experimentId,
      anonymous: completedAnswer.anonymous,
      email: completedAnswer.email,
      colleague_id: completedAnswer.colleagueId,
      questionnaire_id: completedAnswer.questionnaireId,
      colleague_card_answers: [],
      colleague_parameters: [],
      completed: false,
      calculated: false,
    });

    return this.http
      .post<ExperimentAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  finishExperiment(experimentId: number): Observable<Experiment> {
    const url = `${this.experimentsUrl}/${experimentId}`;
    const item = JSON.stringify({
      completed: true,
      finish_date: new Date(),
    });

    return this.http
      .put<Experiment>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  setExperimentStarted(experimentId: number): Observable<Experiment> {
    const url = `${this.experimentsUrl}/${experimentId}`;
    const item = JSON.stringify({
      start_date: new Date(),
    });

    return this.http
      .put<Experiment>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  removeExperiment(experimentId: number): Observable<Experiment> {
    const url = `${this.experimentsUrl}/${experimentId}`;
    return this.http
      .delete<Experiment>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getLastColleagueAnswer(questionnaireId: number, colleagueId: number): Observable<ExperimentAnswer> {
    const url = `${this.experimentAnswersUrl}/last_colleague_answer`;

    const item = JSON.stringify({
      questionnaire_id: questionnaireId,
      colleague_id: colleagueId
    });

    return this.http
      .post <ExperimentAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (response.data === null) return;
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getExperimentAnswer(id: number): Observable<ExperimentAnswer> {
    const url = `${this.experimentAnswersUrl}/${id}`;
    return this.http
      .get <ExperimentAnswer>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getRelativeExperimentAnswer(id: number): Observable<RelativeExperimentAnswer> {
    const url = `${this.relativeExperimentAnswersUrl}/${id}`;
    return this.http
      .get<ExperimentAnswer>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseRelativeExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  setExperimentAnswerCompleted(experimentAnswerId: number): Observable<ExperimentAnswer> {
    const url = `${this.experimentAnswersUrl}/${experimentAnswerId}`;
    const item = JSON.stringify({
      completed: true,
    });

    return this.http
      .put<ExperimentAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  resetExperiment(experimentId: number): Observable<Experiment> {
    const url = `${this.resetExperimentUrl}`;
    const item = JSON.stringify({
      id: experimentId
    });

    return this.http
      .post<Experiment>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('Response ! ', response);
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  resetExperimentAnswerCalculated(experimentAnswerId: number): Observable<ExperimentAnswer> {
    const url = `${this.experimentAnswersUrl}/${experimentAnswerId}`;
    const item = JSON.stringify({
      calculated: false,
    });

    return this.http
      .put<ExperimentAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperimentAnswer(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  resetExperimentCalculated(experimentId: number): Observable<Experiment> {
    const url = `${this.experimentsUrl}/${experimentId}`;
    const item = JSON.stringify({
      calculated: false,
    });

    return this.http
      .put<Experiment>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseExperiment(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  sendExperimentMail(experimentAnswerId: number, email: string): Observable<any> {
    const item = JSON.stringify({
      experiment_answer_id: experimentAnswerId,
      email: email.replace(/[\s\n\r]+/g, ''),
    });

    return this.http
      .post<any>(`${this.sendMailUrl}`, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  addMultipleColleagueCardAnswers(cardAnswers: ColleagueCardAnswer[]): Observable<string> {
    const url = this.multipleColleagueCardAnswersUrl;
    const cards = this.stringifyCardAnswers(cardAnswers);
    const item = JSON.stringify(cards);

    return this.http
      .post<ColleagueCardAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }

  addColleagueCardAnswer(cardAnswer: ColleagueCardAnswer): Observable<ColleagueCardAnswer> {
    const url = this.colleagueCardAnswersUrl;
    const item = JSON.stringify({
      experiment_answer_id: cardAnswer.experimentAnswerId,
      questionnaire_card_id: cardAnswer.questionnaireCardId,
      colleague_id: cardAnswer.colleagueId,
      chosen_option_id: cardAnswer.chosenOptionId,
      order_position: cardAnswer.orderPosition,
      user_input: cardAnswer.userInput,
      anonymous: cardAnswer.anonymous,
      relative_colleague_id: cardAnswer.relativeColleagueId,
    });

    return this.http
      .post<ColleagueCardAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleagueCardAnswer(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getColleaguesParameters(colleagues: Colleague[]): Observable<ColleagueParameter[]> {
    const url = `${this.colleagueParametersUrl}/last_parameters`;
    const colleaguesIds = this.getColleaguesIdsArray(colleagues);
    const formattedColleaguesIds = JSON.stringify(colleaguesIds);

    return this.http
      .post<ColleagueParameter>(url, formattedColleaguesIds, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagueParameter(response)),
        catchError(this.handleError)
      );
  }

  addColleagueParameter(newParameter: ColleagueParameter): Observable<ColleagueParameter> {
    const url = this.colleagueParametersUrl;
    const item = JSON.stringify({
      value: newParameter.value,
      experiment_answer_id: newParameter.experimentAnswerId,
      package_parameter_id: newParameter.packageParameterId,
      colleague_id: newParameter.colleagueId,
    });

    return this.http
      .post<ColleagueParameter>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleagueParameter(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeColleagueParameter(parameterId: number): Observable<any> {
    const url = `${this.colleagueParametersUrl}/${parameterId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  removeRelativeParameter(parameterId: number): Observable<any> {
    const url = `${this.relativeParametersUrl}/${parameterId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  removeRelativeExperimentAnswer(answerId: number): Observable<any> {
    const url = `${this.relativeExperimentAnswersUrl}/${answerId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  addExperimentParameter(newParameter: ExperimentParameter): Observable<ExperimentParameter> {
    const url = this.experimentParametersUrl;
    const item = JSON.stringify({
      common_value: newParameter.commonValue,
      visible_name: newParameter.visibleName,
      formula_name: newParameter.formulaName,
      experiment_id: newParameter.experimentId,
      package_parameter_id: newParameter.packageParameterId,
    });

    return this.http
      .post<ExperimentParameter>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseExperimentParameter(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeExperimentParameter(parameterId: number): Observable<any> {
    const url = `${this.experimentParametersUrl}/${parameterId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  calculateExperimentAnswer(id: number): Observable<ColleagueCardAnswer[]> {
    const url = this.calculateExperimentAnswerUrl;
    const item = JSON.stringify({
      id: id,
    });

    return this.http
      .post<ColleagueCardAnswer>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagueCardAnswers(response.data)),
        catchError(this.handleError)
      );
  }

  calculateDemoModeParameters(questionnaireId: number, demoAnswers: ColleagueCardAnswer[],
    postActionsIdsList: number[]): Observable<DemoParameter[]> {
    const url = this.calculateDemoModeParametersUrl;
    const apiDemoAnswers = [];
    for (const singleAnswer of demoAnswers) {
      const apiAnswer = {
        anonymous: true,
        chosen_option_id: singleAnswer.chosenOptionId,
        order_position: singleAnswer.orderPosition,
        questionnaire_card_id: singleAnswer.questionnaireCardId,
        user_input: singleAnswer.userInput,
        relative_colleague_id: singleAnswer.relativeColleagueId,
      };
      apiDemoAnswers.push(apiAnswer);
    }

    const item = JSON.stringify({
      id: questionnaireId,
      demo_answers: apiDemoAnswers,
      post_actions_ids: postActionsIdsList,
    });

    return this.http
      .post<DemoParameter[]>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseDemoParameters(response)),
        catchError(this.handleError)
      );
  }

  getExperimentLink(experimentAnswerId: number): Observable<ShortUrl> {
    const url = `${this.experimentLinksUrl}/experiment_link`;
    const data = JSON.stringify({
      experiment_answer_id: experimentAnswerId.toString()
    });

    return this.http
      .post<any>(url, data, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseExperimentLink(response.data[0].attributes)),
        catchError(this.handleError)
      );
  }

  getExperimentLinks(experimentId: number): Observable<ShortUrl[]> {
    const url = `${this.experimentLinksUrl}/experiment_links/${experimentId}`;

    return this.http
      .get<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleExperimentLink(response)),
        catchError(this.handleError)
      );
  }

  private parseExperimentLink(apiResponse: any): ShortUrl {
    return parseApiShortUrl(apiResponse);
  }

  private parseMultipleExperimentLink(apiResponse: any): ShortUrl[] {
    const mappedData = apiResponse.data.map(singleLink => parseApiShortUrl(singleLink.attributes));
    return mappedData;
  }

  private parseExperiment(apiResponse: any, included: any): Experiment {
    return parseApiExperiment(apiResponse, true, included);
  }

  private parseExperimentVerbose(apiResponse: any, included: any): Experiment {
    return parseApiExperimentVerbose(apiResponse, true, included);
  }

  private parseMultipleExperimentVerbose(apiResponse: any, included: any): ColleagueCardAnswer[] {
    console.log(apiResponse);
    const mappedData = apiResponse.map(singleAnswer => parseApiExperimentVerbose(singleAnswer.attributes, true, included));

    console.log(mappedData);
    console.log()
    return mappedData;
  }

  private parseExperimentAnswer(apiResponse: any, included: any): ExperimentAnswer {
    return parseApiExperimentAnswer(apiResponse, true, included);
  }

  private parseRelativeExperimentAnswer(apiResponse: any, included: any): RelativeExperimentAnswer {
    return parseApiRelativeExperimentAnswer(apiResponse, true, included);
  }

  private parseMultipleColleagueCardAnswers(apiResponse: any): ColleagueCardAnswer[] {
    const mappedData = apiResponse.map(singleAnswer => this.parseColleagueCardAnswer(singleAnswer.attributes) as ColleagueCardAnswer);
    return mappedData;
  }

  private parseColleagueCardAnswer(apiResponse: any): ColleagueCardAnswer {
    return parseApiColleagueCardAnswer(apiResponse);
  }

  private parseColleagueParameter(apiResponse: any): ColleagueParameter {
    return parseApiColleagueParameter(apiResponse);
  }

  private parseMultipleColleagueParameter(apiResponse: any): ColleagueParameter[] {
    let allParameters = [];
    apiResponse.forEach((parameters) => {
      allParameters = allParameters.concat(parameters);
    });

    const mappedData = allParameters.map(parameter => parseApiColleagueParameter(parameter));
    return mappedData;
  }

  private parseExperimentParameter(apiResponse: any): ExperimentParameter {
    return parseApiExperimentParameter(apiResponse);
  }

  private parseDemoParameters(apiResponse: any): DemoParameter[] {
    return parseApiDemoView(apiResponse);
  }

  private handleError(error: any): Observable<any> {
    return throwError(error);
  }

  private stringifyCardAnswers(cardAnswers: ColleagueCardAnswer[]): any[] {
    const cards = [];

    cardAnswers.forEach((card) => {
      const newCard = {
        experiment_answer_id: card.experimentAnswerId,
        questionnaire_card_id: card.questionnaireCardId,
        colleague_id: card.colleagueId,
        chosen_option_id: card.chosenOptionId,
        order_position: card.orderPosition,
        user_input: card.userInput,
        anonymous: card.anonymous,
        relative_colleague_id: card.relativeColleagueId,
      };
      cards.push(newCard);
    });

    return cards;
  }

  private getColleaguesIdsArray(array: any[]): any[] {
    const colleaguesIds = [];
    array.forEach((parameter) => {
      const param = {
        id: parameter.id
      };
      colleaguesIds.push(param);
    });

    return colleaguesIds;
  }
}
