import {Component, OnInit, OnDestroy, Input,
  EventEmitter, Output, OnChanges, SimpleChanges
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { PackageParameter } from 'app/_models/package-parameter';

@Component({
  selector: 'app-edit-options-question',
  templateUrl: './edit-options-question.component.html',
  styleUrls: [
    './edit-options-question.component.scss',
    '../../../package-details.component.scss'
  ]
})
export class EditOptionsQuestionComponent implements OnInit, OnChanges, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() editedCardOptions: CardOption[];
  @Input() questionText: string;
  @Input() isRelative: boolean;
  @Output() onSaveQuestion: EventEmitter<any> = new EventEmitter<any>();

  @Input() individualParameters: PackageParameter[] = [];
  @Input() relativeParameters: PackageParameter[] = [];
  @Input() commonParameters: PackageParameter[] = [];
  localCardOptions: CardOption[] = [];
  parametersList: PackageParameter[];
  tooltipActionText: string = `Введите данные в формате 'Параметр1 -> Параметр1 + N(значение для вычисления)'
    ('Параметр1 ->' задается в левом поле)`;
  tooltipGotoText: string = `Введите номер вопроса, к которому нужно перейти в формате '#N', где N - номер вопроса`;

  constructor(
    private sharedPackageService: SharedPackageService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setLocalCardOptions(this.editedCardOptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.editedCardOptions) {
      this.setLocalCardOptions(changes.editedCardOptions.currentValue);
    }
  }

  setLocalCardOptions(inputCardOptions: CardOption[]): void {
    const localOptions: CardOption[] = [];
    for (const option of inputCardOptions) {
      const newOption = Object.assign({}, option);
      const localActions = [];
      for (const action of option.cardActions) {
        const newAction = Object.assign({}, action);
        localActions.push(newAction);
      }
      newOption.cardActions = localActions;
      localOptions.push(newOption);
    }

    this.localCardOptions = localOptions;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dropCardOption(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.localCardOptions, event.previousIndex, event.currentIndex);
    const movedCardOption = this.localCardOptions[event.previousIndex];
    this.updateCardOptionPosition(movedCardOption, event.previousIndex + 1);
    this.renumerateCardOptions();
  }

  dropCardAction(event: CdkDragDrop<string[]>, cardActions: CardAction[]): void {
    moveItemInArray(cardActions, event.previousIndex, event.currentIndex);
    const movedCardActions = cardActions[event.previousIndex];
    this.updateCardActionPosition(movedCardActions, event.previousIndex + 1);
    this.renumerateCardActions(cardActions);
  }

  renumerateCardOptions(): void {
    let nextPosition = 1;
    for (const option of this.localCardOptions) {
      option.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  renumerateCardActions(cardActions: CardAction[]): void {
    let nextPosition = 1;
    for (const action of cardActions) {
      action.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  updateCardOptionPosition(targetOption: CardOption, newOptionIndex: number): void {
    if (targetOption && newOptionIndex) {
      targetOption.orderPosition = newOptionIndex;
    }
  }

  updateCardActionPosition(targetAction: CardAction, newActionIndex: number): void {
    if (targetAction && newActionIndex) {
      targetAction.orderPosition = newActionIndex;
    }
  }

  setActionValue(event: any, cardAction: CardAction): void {
    // console.log('SET ACTION VALUE: ', event);
  }

  addCardOption(): void {
    const orderPosition = this.localCardOptions.length + 1;

    const newOption: CardOption = {
      id: 0,
      text: '',
      orderPosition: orderPosition,
      cardActions: [],
    };

    this.localCardOptions.push(newOption);
  }

  addCardOptionAction(createdOption: CardOption, action: CardAction): void {
    const orderPosition = createdOption.cardActions.length + 1;

    const newAction: CardAction = {
      id: 0,
      text: action.text,
      parameter: action.parameter,
      orderPosition: orderPosition,
    };
    createdOption.cardActions.push(newAction);
  }

  deleteOption(option: CardOption, optionIndex: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.localCardOptions.splice(optionIndex, 1);
        this.renumerateCardOptions();
      }
    });
  }

  deleteOptionAction(activeOption: CardOption, cardActionIndex: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        activeOption.cardActions.splice(cardActionIndex, 1);
      }
    });
  }

  getCurrentOptions(): CardOption[] {
    return this.localCardOptions;
  }

  saveLocalQuestion(event: any): void {
    this.onSaveQuestion.emit(event);
  }

}
