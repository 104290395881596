import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
// import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CopyLocalExperimentHelper {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
  ) { }

  copyExperiment(inputExperiment: Experiment): Experiment {
    const localExperimentAnswers: ExperimentAnswer[] = [];
    for (const singleAnswer of inputExperiment.experimentAnswers) {
      const newAnswer = Object.assign({}, singleAnswer);
      localExperimentAnswers.push(newAnswer);
    }

    const localExperimentParameters: ExperimentParameter[] = [];
    for (const singleParameter of inputExperiment.experimentParameters) {
      const newParameter = Object.assign({}, singleParameter);
      localExperimentParameters.push(newParameter);
    }

    const localRelativeAnswers: RelativeExperimentAnswer[] = []
    for (const singleAnswer of inputExperiment.relativeExperimentAnswers) {
      const newAnswer = Object.assign({}, singleAnswer);
      localRelativeAnswers.push(newAnswer);
    }

    const newExperiment: Experiment = {
      id: inputExperiment.id,
      packageId: inputExperiment.packageId,
      questionnaireId: inputExperiment.questionnaireId,
      startDate: inputExperiment.startDate,
      finishDate: inputExperiment.finishDate,
      completed: inputExperiment.completed,
      calculated: inputExperiment.calculated,
      anonymous: inputExperiment.anonymous,
      experimentAnswers: localExperimentAnswers,
      experimentParameters: localExperimentParameters,
      relativeExperimentAnswers: localRelativeAnswers
    };

    return newExperiment;
  }

}
