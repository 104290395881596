import { SharedDashboard } from 'app/_models/shared-dashboard';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { ApiSharedDashboard } from 'app/_api_models/api-shared-dashboard';
import { parseApiSharedDashboardWidget } from './parse-api-shared-dashboard-widget';
import { environment } from '../../environments/environment';
import { Package } from 'app/_models/package';
import { parseApiPackage } from './parse-api-package';
import { SharedDashboardPackage } from 'app/_models/shared-dashboard-package';
import { parseApiSharedDashboardPackage } from './parse-api-shared-dashboard-package';

export function parseApiSharedDashboard(apiResponse: any, nestedModels: boolean, included: any): SharedDashboard {
  let sharedDashboardObject;
  if (environment.production) {
    sharedDashboardObject = apiResponse;
  } else {
    sharedDashboardObject = ApiSharedDashboard.check(apiResponse);
  }

  const sharedDashboardWidgets: SharedDashboardWidget[] = [];
  if (nestedModels && included) {
    const apiDashboardWidgets = included.filter(item => item.type === 'shared_dashboard_widget');
    if (apiDashboardWidgets) {
      for (const singleWidget of apiDashboardWidgets) {
        const widgetObject = parseApiSharedDashboardWidget(singleWidget.attributes);
        sharedDashboardWidgets.push(widgetObject);
      }
    }
  }

  const sharedPackages: Package[] = [];
  if (nestedModels && included) {
    const apiSharedPackages = included.filter(item => item.type === 'package');
    if (apiSharedPackages) {
      for (const singlePackage of apiSharedPackages) {
        const packObject = parseApiPackage(singlePackage.attributes, false, []);
        sharedPackages.push(packObject);
      }
    }
  }

  const sharedDashboardPackages: SharedDashboardPackage[] = [];
  if (nestedModels && included) {
    const apiSharedPackages = included.filter(item => item.type === 'shared_dashboard_package');
    if (apiSharedPackages) {
      for (const singlePackage of apiSharedPackages) {
        const packObject = parseApiSharedDashboardPackage(singlePackage.attributes);
        sharedDashboardPackages.push(packObject);
      }
    }

  }

  const newSharedDashboard: SharedDashboard = {
    id: sharedDashboardObject.id,
    companyId: sharedDashboardObject.company_id,
    dashboardName: sharedDashboardObject.dashboard_name,
    isPublic: sharedDashboardObject.is_public,
    publicLink: sharedDashboardObject.public_link,
    sharedWidgets: sharedDashboardWidgets,
    packages: sharedPackages,
    sharedDashboardPackages: sharedDashboardPackages
  };

  return newSharedDashboard;
}
