import { SharedDashboardPackage } from 'app/_models/shared-dashboard-package';
import { ApiSharedDashboardPackage } from 'app/_api_models/api-shared-dashboard-package';
import { environment } from '../../environments/environment';

export function parseApiSharedDashboardPackage(apiResponse: any): SharedDashboardPackage {
  let sharedPackageObject;
  // const apiResponse = apiResponseInput.attributes;
  if (environment.production) {
    sharedPackageObject = apiResponse;
  } else {
    sharedPackageObject = ApiSharedDashboardPackage.check(apiResponse);
  }

  const newSharedDashboardPackage: SharedDashboardPackage = {
    id: sharedPackageObject.id,
    packageId: sharedPackageObject.package_id,
    sharedDashboardId: sharedPackageObject.shared_dashboard_id
  };

  return newSharedDashboardPackage;
}
