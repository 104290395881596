<ng-container *ngIf="localCardOption">
<div>
  <div cdkDropList (cdkDropListDropped)="dropCardAction($event, localCardOption.cardActions)">
    <div cdkDrag [cdkDragData]="singleAction" *ngFor="let singleAction of localCardOption.cardActions; let i = index"
      class="answer-action">
      <div class="set-action-text"><span i18n="@@setActionLabel">Set action</span> #{{i + 1}}</div>
      <div class="set-action-group">
        <mat-form-field>
          <mat-placeholder class="placeholder" i18n="@@placeholder-parameter">Parameter</mat-placeholder>
          <mat-select
              style="margin-right: 10px;"
              [(value)]="singleAction.parameter"
              (selectionChange)="setActionValue($event, singleAction)"
          >
            <mat-option *ngFor="let singleParam of individualParameters"
                        [value]="singleParam.visibleName">
              {{singleParam.visibleName}}
            </mat-option>
            <ng-container *ngIf="cardType === 'relative'">
              <mat-option *ngFor="let singleParam of relativeParameters"
                          [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
            </ng-container>
            <mat-option value="goto" i18n="@@goTo">GOTO</mat-option>
            <!--<mat-option value="notify" i18n="@@notify">NOTIFY</mat-option>-->
          </mat-select>
        </mat-form-field>

        <i class="material-icons arrow-rotated">
          keyboard_backspace
        </i>
        <mat-form-field>
          <mat-placeholder class="placeholder" i18n="@@optionAction">Option action</mat-placeholder>
          <input matInput [(ngModel)]="singleAction.text"/>
        </mat-form-field>

        <i *ngIf="singleAction.parameter != 'goto'" class="material-icons help-tooltip"
          matTooltip="{{tooltipActionText}}">
          help_outline
        </i>
        <i *ngIf="singleAction.parameter == 'goto'" class="material-icons help-tooltip"
          matTooltip="{{tooltipGotoText}}">
          help_outline
        </i>

        <!-- <div class="delete-label">
          <label
              (click)="deleteOptionAction(localCardOption, i); $event.stopPropagation()" i18n="@@deleteButton">
            Delete
          </label>
        </div> -->

        <button class="delete-item-button" 
          (click)="deleteOptionAction(localCardOption, i); $event.stopPropagation()">
        </button>

        <span class="move-handle" cdkDragHandle>
          <svg width="12px" fill="#61728d" viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </span>

      </div>
    </div>
  </div>

  <button
      mat-raised-button
      class="adding-button mat-raised-button"
      (click)="addCardOptionAction(localCardOption)"
      i18n="@@addActionButton">
    Add Action
  </button>

  <div class="action-buttons">
    <button
      [disabled]="!questionText"
      mat-raised-button
      color="accent"
      (click)="saveLocalQuestion($event)"
      i18n="@@saveButton">
        SAVE
    </button>
  </div>
</div>
</ng-container>

<ng-container *ngIf="!localCardOption">
  <div style="color:red">
    Error with option
  </div>
</ng-container>
