import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SnackBarComponent } from 'app/snack-bar';
import { PackageService } from '../_services/package.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { Package } from 'app/_models/package';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { Experiment } from 'app/_models/experiment';
import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';

@Component({
  selector: 'app-python-calculation-helper',
  templateUrl: './python-calculation-helper.component.html',
  styleUrls: ['./python-calculation-helper.component.scss'],
  providers: [SnackBarComponent]
})
export class PythonCalculationHelperComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() currentPackageId: number;
  @Input() postQuestionnaireActionId: number;
  @Input() customCalculationId: number;
  @Input() editMode: boolean;
  @Input() isSimpleCalculation: boolean;

  editorOptions = { theme: 'vs-dark', language: 'python', fontSize: 16 };
  // code: string = 'def main():\n    return 10';
  defaultCode: string = `import math\n\ndef sin_calculation():
    return math.sin(45)\n\ndef main():
    sinus_value = sin_calculation()
    return sinus_value`;
  code: string = '';
  editorTheme: string = 'vs-dark';
  fontSize: number = 16;
  // experimentsContext: any;
  pythonContext: any;
  jsonContext: any;
  questionnaire: Questionnaire;
  lastExperiment: Experiment;

  constructor(
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private experimentService: ExperimentService,
    private sharedPackageService: SharedPackageService,
    private snackBar: SnackBarComponent,
  ) { }

  ngOnInit(): void {
    this.getCustomCalculation();
    const packageId = this.currentPackageId;
    if (packageId && packageId > 0) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultPackage: Package) => {
          this.getQuestionnaire(resultPackage.questionnaire.id);
        }, error => {
          console.log(error);
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCurrentCode(): string {
    return this.code;
  }

  getCustomCalculation(): void {
    if (this.customCalculationId) {
      this.packageService.getCustomCalculation(this.customCalculationId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((calculationObject: PythonCustomCalculation) => {
          this.code = calculationObject.text_value;
        }, error => console.log(error));
    } else {
      this.code = this.defaultCode;
    }
  }

  getQuestionnaire(questionnaireId: number): void {
    if (questionnaireId && questionnaireId > 0) {
      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          this.questionnaire = resultQuestionnaire;
          // this.experimentsContext = JSON.stringify(resultQuestionnaire.experiments, null, 4);
          // console.log(this.experimentsContext);
        }, error => {
          console.log(error);
        })
    }
  }

  onSelectionChange(event: any): void {
    const newTheme = event.value;
    this.editorTheme = newTheme;
    this.editorOptions = { ...this.editorOptions, theme: newTheme };
  }

  onBlurMethod(): void {
    this.editorOptions = { ...this.editorOptions, fontSize: this.fontSize };
  }

  replaceTabs(inputCode: string): string {
    let formattedCode = inputCode.replace(/\t/g, '    ');
    return formattedCode;
  }

  onSaveAction(): void {
    if (this.editMode) {
      if (this.customCalculationId) {
        this.packageService.updateCustomCalculation(this.customCalculationId, this.code)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: PythonCustomCalculation) => {
            // console.log('SAVE', result);
          }, error => console.log(error));
      } else {
        this.packageService.addCustomCalculation(this.postQuestionnaireActionId, this.code)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: PythonCustomCalculation) => {
            // console.log('SAVE', result);
          }, error => console.log(error));
      }
    }
  }

  onButtonCalculate(): void {
    if (this.isSimpleCalculation) {
      this.onCalculateSimple();
    } else {
      this.onCalculateVerbose();
    }
  }

  onCalculateSimple(): void {
    this.code = this.replaceTabs(this.code);
    this.experimentService.calculatePythonTest(this.code, this.currentPackageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((calculationResult: any) => {
        if (calculationResult) {
          const value = calculationResult.result;
          this.openSnackBar("The result is: " + value);
        } else {
          this.openSnackBar("No result");
        }
      }, error => {
        this.openSnackBar("Something went wrong");
        console.log(error);
      })
  }

  onCalculateVerbose(): void {
    this.code = this.replaceTabs(this.code);
    this.experimentService.calculatePythonVerbose(this.code, this.currentPackageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((calculationResult: any) => {
        if (calculationResult) {
          const value = calculationResult.result;
          this.openSnackBar("The result is: " + value);
        } else {
          this.openSnackBar("No result");
        }
      }, error => {
        this.openSnackBar("Something went wrong");
        console.log(error);
      })
  }

  onButtonLoadContext(): void {
    this.code = this.replaceTabs(this.code);
    this.experimentService.showPythonContext(this.code, this.currentPackageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((calculationResult: any) => {
        if (calculationResult) {
          const jsonObject = JSON.parse(calculationResult.context);
          const jsonPretty = JSON.stringify(jsonObject, null, 2);
          this.jsonContext = jsonObject;
          this.pythonContext = jsonPretty;
          this.openSnackBar("See context at the bottom of the screen");
        } else {
          // this.openSnackBar("Something went wrong");
        }
      }, error => {
        this.openSnackBar("Something went wrong");
        console.log(error);
      })
  }

  onCopyContext(): void {
    this.openSnackBar("Контекст был скопирован");
  }

  // getExperimentTestValue(): void {
  //   const packageExperiments = this.questionnaire.experiments;
  //   const lastExperiment = this.getLatestPackageExperiment(packageExperiments);
  //   if (lastExperiment) {
  //     this.experimentService.getExperimentVerbose(lastExperiment.id)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe((resultExperiment: Experiment) => {
  //         console.log('Last experiment ', resultExperiment);
  //         this.lastExperiment = resultExperiment;
  //       });
  //   }
  //   // console.log('last experiment ', lastExperiment);
  // }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  getLatestPackageExperiment(packageExperiments: Experiment[]): Experiment {
    if (packageExperiments) {
      const sortedExperimens = packageExperiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  syntaxHighlight(json: any): any {
    if (typeof json != 'string') {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

}
