<div layout="row" layout-padding>
<div flex="30"></div>

<div flex>
	<mat-card class="mat-title">
		<p class="title" i18n="@@sendFeedback-title">SEND FEEDBACK</p>

    <mat-form-field class="card-item">
      <input matInput i18n-placeholder="@@sendFeedback-theme" placeholder="Theme" min="4" required [(ngModel)]="mailTheme" />
    </mat-form-field>

    <mat-form-field class="card-item feedback-field">
	  	<textarea matInput mat-maxlength="150" rows="4" min="4" required
	    	[(ngModel)]="mailDescription" type="text" i18n-placeholder="@@sendFeedback-description" placeholder="Description">
	  	</textarea>
    </mat-form-field>
    
    <br />
    <div align="center">
			<button mat-raised-button color="accent" class="button-send" (click)="sendMail()" i18n="@@sendButton">Send</button>
			<button mat-raised-button color="accent" (click)="onClose()" i18n="@@cancelButton">Dismiss</button>
		</div>
	</mat-card>
</div>

<div flex="30"></div>
</div>