import { Boolean, Number, String, Record, Partial } from 'runtypes';

export const ApiColleagueCardAnswer = Record({
  id: Number,
  experiment_answer_id: Number,
  questionnaire_card_id: Number,
  chosen_option_id: Number,
  order_position: Number,
  user_input: String,
  anonymous: Boolean,
  relative_colleague_id: Number,
}).And(Partial({
  colleague_id: Number,
}));
