import { Component, OnInit } from '@angular/core';
import { Colleague } from 'app/_models/colleague';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';
import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ParameterData } from 'app/_models/overmind_ds/parameter-data';
import { User } from 'app/_models/user';
import { ParameterDataService } from 'app/_services/overmind_ds/parameter-data.service';
import { ParameterService } from 'app/_services/overmind_ds/parameter.service';
import { SharedColleagueService } from 'app/_services/shared-colleague.service';
import { SharedUserService } from 'app/_services/shared-user.service';
import { Subject, pipe } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-colleague-parameters',
  templateUrl: './colleague-parameters.component.html',
  styleUrls: ['./colleague-parameters.component.scss']
})
export class ColleagueParametersComponent implements OnInit {
  public colleague: Colleague = null;
  public parameters: ExtendedParameter[] = [];
  public currentUser: User = null;
  public isLoading: boolean = true;

  public authorUuid: string = '';
  public sourceUuid: string = '';
  public entityUuid: string = '';

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private sharedUserService: SharedUserService,
    private parameterService: ParameterService,
    private parameterDataService: ParameterDataService,
    private sharedColleagueService: SharedColleagueService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getColleague();
  }

  getCurrentUser(): void {
    this.sharedUserService.currentUser$
      .pipe(
        filter(user => user !== null),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((user: User) => {
        this.currentUser = user;
        this.authorUuid = user.uuid;
        this.sourceUuid = user.uuid;
      })
  }

  getColleague(): void {
    this.sharedColleagueService.colleague$
      .pipe(
        filter(colleague => colleague != null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleague: Colleague) => {
        this.colleague = colleague;
        this.entityUuid = colleague.uuid;
        this.getColleagueParameters();
      });
  }

  getColleagueParameters(): void {
    this.parameterDataService.getEntityParametersData(this.colleague.uuid)
      .subscribe((parameters: ExtendedParameter[]) => {
        this.parameters = parameters;

        this.parameters.forEach((parameter: ExtendedParameter) => {
          parameter.parameterData.forEach((parameterData) => {
            parameterData.data = JSON.parse(parameterData.data);
          });
        });

        this.isLoading = false;
      }, error => console.log(error));
  }

  addNewParameter(newParameter: ExtendedParameter): void {
    this.parameters.push(newParameter);
  }
}
