import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { DashboardService } from 'app/_services/dashboard.service';
import { environment } from 'app/../environments/environment';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss']
})
export class DashboardSettingsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  baseUrl: string = environment.BASE_ANGULAR_URL;
  @Input() sharedDashboard: SharedDashboard;
  isPublic: boolean = false;
  publicLink: string = '';

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.isPublic = this.sharedDashboard.isPublic;
    this.publicLink = this.sharedDashboard.publicLink;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onCheckboxChange(): void {
    this.updateSharedDashboard();
  }

  generateNewLink(): void {
    // this.updateSharedDashboard();
  }

  updateSharedDashboard(): void {
    if (this.sharedDashboard) {
      const newDashboardFields = {
        id: this.sharedDashboard.id,
        dashboardName: this.sharedDashboard.dashboardName,
        isPublic: this.isPublic,
        publicLink: this.publicLink
      };
      this.dashboardService.updateSharedDashboard(newDashboardFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDashboard: SharedDashboard) => {
          this.publicLink = resultDashboard.publicLink;
        },
        error => {
          console.log('Error ', error);
        });
    }
  }

}
