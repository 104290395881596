import { Number, String, Array, Record, Partial, Null } from 'runtypes';
import { ApiCardAction } from './api-card-action';

export const ApiCardOption = Record({
  id: Number,
  text: String,
  order_position: Number
}).And(Partial({
  card_actions: Array(ApiCardAction)
}));
