import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Package } from 'app/_models/package';
import { Company } from 'app/_models/company';
import { Experiment } from 'app/_models/experiment';
import { Department } from 'app/_models/department';
import { PackageParameter } from 'app/_models/package-parameter';
import { ExtendedColleague } from 'app/colleagues-page/local-models';
import { ParameterVisibility } from 'app/_models/parameter-visibility';
import { ColleagueTableParameter, ExtendedParameter, PackageData } from 'app/colleague-profile/local-models';

@Injectable({
  providedIn: 'root'
})
export class SharedCompanyDataService {
  public packages$: BehaviorSubject<Package[]>;
  public experiments$: BehaviorSubject<Experiment[]>;
  public packagesData$: BehaviorSubject<PackageData[]>;
  public packageParameters$: BehaviorSubject<PackageParameter[]>;
  public extendedParameters$: BehaviorSubject<ExtendedParameter[]>;
  public parameterVisibilities$: BehaviorSubject<ParameterVisibility[]>;
  public departmentsList$: BehaviorSubject<Department[]>;
  public colleaguesList$: BehaviorSubject<ExtendedColleague[]>;
  public archivedColleaguesList$: BehaviorSubject<ExtendedColleague[]>;
  public colleagueParameters$: BehaviorSubject<ColleagueTableParameter[]>;

  public companyInfo$: BehaviorSubject<Company>;
  public isCompanyEmpty$: BehaviorSubject<boolean>;

  public onColleaguesRefresh$: EventEmitter<any>;

  constructor() {
    this.packages$ = new BehaviorSubject(null);
    this.experiments$ = new BehaviorSubject(null);
    this.packagesData$ = new BehaviorSubject(null);
    this.packageParameters$ = new BehaviorSubject(null);
    this.extendedParameters$ = new BehaviorSubject(null);
    this.parameterVisibilities$ = new BehaviorSubject(null);
    this.departmentsList$ = new BehaviorSubject(null);
    this.colleaguesList$ = new BehaviorSubject(null);
    this.archivedColleaguesList$ = new BehaviorSubject(null);
    this.colleagueParameters$ = new BehaviorSubject(null);

    this.companyInfo$ = new BehaviorSubject(null);
    this.isCompanyEmpty$ = new BehaviorSubject(true);

    this.onColleaguesRefresh$ = new EventEmitter();
  }

  public clearSharedCompanyData() {
    this.packages$.next(null);
    this.experiments$.next(null);
    this.packagesData$.next(null);
    this.packageParameters$.next(null);
    this.extendedParameters$.next(null);
    this.parameterVisibilities$.next(null);
    this.departmentsList$.next(null);
    this.colleaguesList$.next(null);
    this.archivedColleaguesList$.next(null);
    this.colleagueParameters$.next(null);

    this.companyInfo$.next(null);
    this.isCompanyEmpty$.next(true);
  }

  public updatePackages(packages: Package[]): void {
    this.packages$.next(packages);
  }

  public updateExperiments(experiments: Experiment[]): void {
    this.experiments$.next(experiments);
  }

  public updatePackagesData(packagesData: PackageData[]): void {
    this.packagesData$.next(packagesData);
  }

  public updatePackageParameters(packagesParameters: PackageParameter[]): void {
    this.packageParameters$.next(packagesParameters);
  }

  public addExtendedParameters(parameters: ExtendedParameter[]): void {
    this.extendedParameters$.next(parameters);
  }

  public addParameterVisibilities(parameters: ParameterVisibility[]): void {
    this.parameterVisibilities$.next(parameters);
  }

  public updateDepartmentsList(departmentsList: Department[]): void {
    this.departmentsList$.next(departmentsList);
  }

  public updateColleaguesList(colleaguesList: ExtendedColleague[]): void {
    this.colleaguesList$.next(colleaguesList);
  }

  public updateArchivedColleaguesList(colleaguesList: ExtendedColleague[]): void {
    this.archivedColleaguesList$.next(colleaguesList);
  }

  public updateColleagueParameters(colleagueParameters: ColleagueTableParameter[]): void {
    this.colleagueParameters$.next(colleagueParameters);
  }

  public updateCompanyInfo(companyInfo: Company): void {
    this.companyInfo$.next(companyInfo);
  }

  public updateCompanyEmpty(isCompanyEmpty: boolean) {
    this.isCompanyEmpty$.next(isCompanyEmpty);
  }
}
