<div class="wrapper-style">
    <div class="buttons-style">
        <div class="call-to-action">
            <ng-container *ngIf="isRussianLocale">
                <span>Выберите шаблон</span>
            </ng-container>
            <ng-container *ngIf="!isRussianLocale">
                <span>Choose template</span>
            </ng-container>
        </div>

        <ng-container *ngIf="isRussianLocale">
            <div class="button-how-to-style">
            <button (click)="onHowToDialog()" class="how-to-style">
                <div>
                    <mat-icon style="font-size: 18px;">help_outline</mat-icon>
                </div>
                <div>Как этим пользоваться?</div>
            </button>
            </div>
        </ng-container>
    </div>

    <div class="cards-list-style">
        <div *ngFor="let categoryItem of categoryPackages">
            <span class="category-name-style">{{categoryItem.categoryName}}</span>
            <mat-card *ngFor="let publicPack of categoryItem.publicPackages;" class="mat-card-class package-item standard-package-item" >
                <div *ngIf="isGottenPack" class="isGottenPack">
                    <mat-card-header>
                        <mat-card-title class="standard-set">{{publicPack.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="button-block two-buttons">
                    <div class="package-description ql-editor" [innerHtml]="getFirstParagraphDescription(publicPack.description) | safeHtml"></div>
                        <button
                            mat-button
                            color="primary"
                            (click)="openPackage(publicPack)"
                            i18n="@@open">
                            Open
                        </button>
                        <button
                            mat-button
                            color="primary"
                            (click)="copyPublicPackage(publicPack)"
                            i18n="@@get-it">
                            Get it
                        </button>
                    </mat-card-content>
                </div>
                <div *ngIf="!isGottenPack">
                    <mat-card-header>
                        <mat-card-title>{{publicPack.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="button-block two-buttons">
                        <div class="package-description ql-editor" [innerHtml]="getFirstParagraphDescription(publicPack.description) | safeHtml"></div>
                        <!-- <button i18n="@@open" (click)="openPackage(publicPack)">Open</button> -->
                        <button
                            mat-button
                            color="primary"
                            (click)="openPackage(publicPack)"
                            i18n="@@open">
                            Open
                        </button>
                        <button
                            mat-button
                            color="primary"
                            (click)="copyPublicPackage(publicPack)"
                            i18n="@@get-it">
                            Get it
                        </button>
                    </mat-card-content>
                </div>
            </mat-card>
    </div>
    </div>
</div>