import { Record, Number, String } from 'runtypes';

export const ApiRelativeExperimentParameter = Record({
  id: Number,
  value: Number,
  text_value: String,
  relative_experiment_answer_id: Number,
  package_parameter_id: Number,
  colleague_id: Number
});
