import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Package } from 'app/_models/package';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

import * as moment from 'moment-timezone';

class MyQuestionnaireDetails {
  packageId: number = 0;
  packageName: string = '';
  isActive: boolean = false;
  lastRun: string = '';
  nextRun: string = '';
}

@Component({
  selector: 'app-my-questionnaires',
  templateUrl: './my-questionnaires.component.html',
  styleUrls: ['./my-questionnaires.component.scss', './../packages.component.scss'],
})

export class PackagesMyQuestionnairesComponent implements OnInit, OnDestroy {
  myQuestionnaires: MyQuestionnaireDetails[] = [];
  owner: boolean = false;
  activeCompanyId: number = 0;
  statusQuestionnaire: boolean = false;
  isLoading: boolean = true;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private sharedCompanyDataService: SharedCompanyDataService,
    public  dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.checkLoggedIn();
    this.getCachedPackages();
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkLoggedIn(): void {
    const user = localStorage.getItem('currentUser');
    if (user) {
      const email = JSON.parse(user).email;
      const currentUser = {
        'email': email,
        'canCreate': false,
        'canRead': true,
        'canUpdate': false,
        'canDelete': false
      };
      this.sharedService.currentUser = currentUser;

      if (!this.sharedService.onLoginEmitted) {
        this.sharedService.onLogin$.emit();
        this.sharedService.onLoginEmitted = true;
      }
    }
  }

  getCachedPackages(): void {
    this.myQuestionnaires = [];
    this.sharedCompanyDataService.packages$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((packages: Package[]) => {
        const localDetails = [];
        if (packages) {
          for (const pack of packages) {
            const newDetails = this.formQuestionnaireDetails(pack);
            localDetails.push(newDetails);
          }
          this.myQuestionnaires = this.sortQuestionnaires(localDetails);
        }
        this.isLoading = false;
      }, error => {
        console.log('Error with package', error);
        this.sharedService.checkSignatureExpired(error);
      });
  }

  sortQuestionnaires(questionnaireDetails: MyQuestionnaireDetails[]): MyQuestionnaireDetails[] {
    const dateFormat = 'YYYY-M-D H:m';
    const sortedQuestionnaires = [];
    const validQuestionnaires = [];
    for (const singleQuestionnaire of questionnaireDetails) {
      const localDate = moment(singleQuestionnaire.lastRun, dateFormat);
      if (!localDate.isValid()) {
        sortedQuestionnaires.push(singleQuestionnaire);
      } else {
        validQuestionnaires.push(singleQuestionnaire);
      }
    }

    validQuestionnaires.sort(function (a: any, b: any): number {
      const moment_a = moment.utc(a.lastRun);
      const moment_b = moment.utc(b.lastRun);
      const value = moment_a.diff(moment_b);
      return value;
    });

    for (const singleQuestionnaire of validQuestionnaires) {
      sortedQuestionnaires.push(singleQuestionnaire);
    }

    return sortedQuestionnaires.reverse();
  }

  formQuestionnaireDetails(privatePackage: Package): MyQuestionnaireDetails {
    const newQuestionnaireDetails: MyQuestionnaireDetails = {
      packageId: privatePackage.id,
      packageName: privatePackage.name,
      isActive: false,
      lastRun: '',
      nextRun: '',
    };

    const questionnaire = privatePackage.questionnaire;

    const timeZone = moment.tz.guess();
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';

    if (questionnaire && questionnaire.activeStatus) {
      if (questionnaire.activeStatus === 'active') {
        newQuestionnaireDetails.isActive = true;
      }
      const lastRun = questionnaire.lastRun || '';
      const nextRun = questionnaire.nextRun || '';
      newQuestionnaireDetails.lastRun = moment(lastRun).tz(timeZone).format(timeFormat);
      newQuestionnaireDetails.nextRun = moment(nextRun).tz(timeZone).format(timeFormat);
    }

    return newQuestionnaireDetails;
  }

  openQuestionnaire(questionnaireDetails: MyQuestionnaireDetails): void {
    if (questionnaireDetails) {
      const packageId = questionnaireDetails.packageId;
      const packageName = questionnaireDetails.packageName;

      this.router.navigateByUrl(`/`)
        .then(() => {
          this.sharedPackageService.currentPackageId$.next(packageId);
          this.sharedPackageService.currentPackageName$.next(packageName);
          this.sharedPackageService.onPackageSelect$.emit();
          this.router.navigateByUrl(`/questionnaires/${packageId}/dashboard`);
        });
    }
  }
}
