<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header subheader">
    <div class="card-header-group">
      <h2 class="title">
        <span i18n="@@question-label">Question</span> #{{nextId}}
      </h2>
      <div class="float-left">
        <label (click)="goBack()"
               i18n="@@cancelButton">
          Cancel
        </label>
        <ng-container *ngIf="isScoreType">
          <button 
            [disabled]="!cardQuestionText || !selectedScoreDivisions" 
            mat-raised-button
            color="accent" 
            class="header-button" 
            (click)="saveQuestion()" 
            i18n="@@createButton">
              <i class="material-icons">
                check
              </i>
              Create
          </button>
        </ng-container>
        <ng-container *ngIf="!isScoreType">
          <button 
            [disabled]="!cardQuestionText" 
            mat-raised-button 
            color="accent"
            class="header-button" 
            (click)="saveQuestion()" 
            i18n="@@createButton">
              <i class="material-icons">
                check
              </i>
              Create
          </button>
        </ng-container>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="scrolled-block">
  <div class="left-content-part">
    <mat-card class="new-question-card">

      <h2 class="title" i18n="@@createNewQuestion-label">
        Create new question
      </h2>

      <app-questionnaire-card-description 
        [cardId]="0"
        [editMode]="false"
        [cardDescription]="cardQuestionText"
        (onSaveDescription)="onSaveDescription($event)">
      </app-questionnaire-card-description>

      <section class="required-section">
        <mat-checkbox labelPosition="after" [(ngModel)]="isRequired">
          Required question
        </mat-checkbox>
      </section>

      <section class="active-section">
        <mat-checkbox labelPosition="after" [(ngModel)]="isActive">
          Active question
        </mat-checkbox>
      </section>

      <div>
        <mat-form-field>
          <mat-label i18n="@@questionType-label">Question Type</mat-label>
          <mat-select [(ngModel)]="cardType" (selectionChange)="setOptionValue($event)">
            <mat-option value="options" i18n="@@questionType-options">Question with options</mat-option>
            <mat-option value="checkboxes" i18n="@@questionType-checkbox">Checkboxes question</mat-option>
            <mat-option value="text-input" i18n="@@questionType-text-input">Open-ended question</mat-option>
            <mat-option value="score" i18n="@@questionType-score">Score question</mat-option>
            <mat-option value="relative" i18n="@@questionType-relative">Relative question</mat-option>
            <mat-option value="relative-score" i18n="@@questionType-relative-score">Relative score question</mat-option>
            <mat-option value="relative-options" i18n="@@questionType-relative-options">Relative options question</mat-option>
            <mat-option value="relative-checkboxes" i18n="@@questionType-relative-checkboxes">Relative checkboxes question</mat-option>
            <mat-option value="info-card" i18n="@@questionType-info-card">Info card</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="cardType === 'score' || cardType === 'relative-score'">
          <mat-label i18n="@@select-divisions-color-scheme">Select color scheme</mat-label>
          <mat-select [(ngModel)]="this.divisionsColorScheme">
            <mat-option value="default" i18n="@@select-default-color">
              Default
            </mat-option>
            <mat-option value="multicolor" i18n="@@select-multicolor-color">
              Multicolor
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="cardType === 'score' || cardType === 'relative-score'">
          <mat-label i18n="@@select-divisions">Select score divisions</mat-label>
          <mat-select [(ngModel)]="this.selectedScoreDivisions" (selectionChange)="setDivisionsLabelFields()">
            <mat-option value="{{number}}" *ngFor="let number of scoreSteps">{{number}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="cardType === 'options' || cardType === 'checkboxes' || cardType === 'relative-options' || cardType === 'relative-checkboxes'">
        <div cdkDropList (cdkDropListDropped)="dropCardOption($event)">
          <div cdkDrag [cdkDragData]="option" *ngFor="let option of cardOptions; let i = index" 
            class="answer-option" flex>
            <div class="answer-option-header">
              <span class="set-option-text"><span i18n="@@answerOptionLabel">Answer Option</span> {{i + 1}}</span>

              <div class="button-group">
                <button
                    class="delete-item-button"
                    (click)="deleteOption(i)">
                </button>
                
                <span class="move-handle" cdkDragHandle>
                  <svg width="18px" fill="#61728d" viewBox="0 0 24 24">
                    <path
                      d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                    </path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </span>
              </div>
            </div>

            <mat-form-field class="input-white">
              <mat-label i18n="@@optionLabel">Option #{{i + 1}}</mat-label>
              <input matInput [(ngModel)]="option.text"/>
            </mat-form-field>

            <div cdkDropList (cdkDropListDropped)="dropCardAction($event, option.cardActions)">
              <div cdkDrag [cdkDragData]="singleAction" *ngFor="let singleAction of option.cardActions; let i = index"
                class="answer-action">
                <div class="set-action-text"><span i18n="@@setActionLabel">Set action</span> #{{i + 1}}</div>
                <div class="set-action-group">
                  <mat-form-field>
                    <mat-label i18n="@@placeholder-parameter">Parameter</mat-label>
                    <mat-select
                        style="margin-right: 10px;"
                        [(value)]="singleAction.parameter"
                        (selectionChange)="setActionValue($event, singleAction)"
                    >
                      <ng-container *ngIf="cardType === 'options' || cardType === 'checkboxes'">
                      <mat-option *ngFor="let singleParam of individualParameters"
                                  [value]="singleParam.visibleName">
                        {{singleParam.visibleName}}
                      </mat-option>
                      </ng-container>
                      <ng-container *ngIf="cardType === 'relative-options' || cardType === 'relative-checkboxes'">
                        <mat-option *ngFor="let singleParam of relativeParameters"
                          [value]="singleParam.visibleName">
                          {{singleParam.visibleName}}
                        </mat-option>
                      </ng-container>
                      <mat-option value="goto" i18n="@@goTo">GOTO</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <i class="material-icons arrow-rotated">
                    keyboard_backspace
                  </i>

                  <mat-form-field>
                    <mat-label i18n="@@optionAction">Option action</mat-label>
                    <input matInput [(ngModel)]="singleAction.text"/>
                  </mat-form-field>
                  
                  <i *ngIf="singleAction.parameter != 'goto'" class="material-icons help-tooltip" matTooltip="tooltipText">
                    help_outline
                  </i>
                  <i *ngIf="singleAction.parameter == 'goto'" class="material-icons help-tooltip"
                    matTooltip="tooltipGotoText">
                    help_outline
                  </i>

                  <button 
                    class="delete-item-button" 
                    (click)="deleteOptionAction(option, i); $event.stopPropagation()">
                  </button>

                  <span class="move-handle" cdkDragHandle>
                    <svg width="12px" fill="#61728d" viewBox="0 0 24 24">
                      <path
                        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                      </path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </span>

                </div>
              </div>
            </div>

            <button
                mat-raised-button
                class="adding-button mat-raised-button"
                (click)="addOptionAction(option, option.cardActions.length)"
                i18n="@@addActionButton">
              Add Action
            </button>

          </div>
        </div>
        <p style="color: red">
          {{errorList[orderPosition - 1]}}
        </p>

      </div>

      <div *ngIf="cardType === 'text-input' || cardType === 'relative'">
        <div cdkDropList (cdkDropListDropped)="dropCardAction($event, textInputOption.cardActions)">
          <div cdkDrag [cdkDragData]="singleAction"
            *ngFor="let singleAction of textInputOption.cardActions; let i = index" class="answer-action">
            <div class="set-action-text"><span i18n="@@setActionLabel">Set action</span> #{{i + 1}}</div>
            <div class="set-action-group">
              <mat-form-field>
                <!-- <mat-placeholder class="placeholder" i18n="@@placeholder-parameter">Parameter</mat-placeholder> -->
                <mat-label i18n="@@placeholder-parameter">Parameter</mat-label>
                <mat-select
                    style="margin-right: 10px;"
                    [(value)]="singleAction.parameter"
                    (selectionChange)="setActionValue($event, singleAction)"
                >
                  <mat-option *ngFor="let singleParam of individualParameters"
                              [value]="singleParam.visibleName">
                    {{singleParam.visibleName}}
                  </mat-option>
                  <ng-container *ngIf="cardType === 'relative'">
                    <mat-option *ngFor="let singleParam of relativeParameters"
                                [value]="singleParam.visibleName">
                      {{singleParam.visibleName}}
                    </mat-option>
                  </ng-container>
                  <mat-option value="goto" i18n="@@goTo">GOTO</mat-option>
                  <!-- <mat-option value="notify" i18n="@@notify">NOTIFY</mat-option> -->
                </mat-select>
              </mat-form-field>

              <i class="material-icons arrow-rotated">
                keyboard_backspace
              </i>

              <mat-form-field>
                <!-- <mat-placeholder class="placeholder" i18n="@@optionAction">Option action</mat-placeholder> -->
                <mat-label i18n="@@optionAction">Option action</mat-label>
                <input matInput [(ngModel)]="singleAction.text"/>
              </mat-form-field>

               <button class="delete-item-button" 
                (click)="deleteOptionAction(textInputOption, i); $event.stopPropagation()">
               </button>

               <span class="move-handle" cdkDragHandle>
                 <svg width="12px" fill="#61728d" viewBox="0 0 24 24">
                   <path
                     d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                   </path>
                   <path d="M0 0h24v24H0z" fill="none"></path>
                 </svg>
               </span>

            </div>
          </div>
        </div>

        <button
            mat-raised-button
            class="adding-button mat-raised-button"
            (click)="addOptionAction(textInputOption, textInputOption.cardActions.length)"
            i18n="@@addActionButton">
          Add Action
        </button>

      </div>

      <div *ngIf="cardType === 'score' || cardType === 'relative-score'">
        <label *ngIf="divisionsLabelFields.length > 0" i18n="@@score-label">
          You can set the label for any division
        </label>

        <mat-form-field *ngFor="let label of divisionsLabelFields; let i = index">
          <!-- <mat-placeholder class="placeholder"><span i18n="@@score-division-label">Division's label for value</span> {{i + 1}}</mat-placeholder> -->
          <mat-label i18n="@@score-division-label">Division's label for value {{i + 1}}</mat-label>
          <input class="score-division" matInput [(ngModel)]="label.label"/>
        </mat-form-field>

        <div cdkDropList (cdkDropListDropped)="dropCardAction($event, textInputOption.cardActions)">
          <div cdkDrag [cdkDragData]="singleAction"
            *ngFor="let singleAction of textInputOption.cardActions; let i = index" class="answer-action">
            <div class="set-action-text"><span i18n="@@setActionLabel">Set action</span> #{{i + 1}}</div>
            <div class="set-action-group">
              <mat-form-field>
                <!-- <mat-placeholder class="placeholder" i18n="@@placeholder-parameter">Parameter</mat-placeholder> -->
                <mat-label i18n="@@placeholder-parameter">Parameter</mat-label>
                <mat-select
                    style="margin-right: 10px;"
                    [(value)]="singleAction.parameter"
                    (selectionChange)="setActionValue($event, singleAction)"
                >
                  <mat-option *ngFor="let singleParam of individualParameters"
                              [value]="singleParam.visibleName">
                    {{singleParam.visibleName}}
                  </mat-option>
                  <ng-container *ngIf="cardType === 'relative-score'">
                    <mat-option *ngFor="let singleParam of relativeParameters" [value]="singleParam.visibleName">
                      {{singleParam.visibleName}}
                    </mat-option>
                  </ng-container>
                  <mat-option value="goto" i18n="@@goTo">GOTO</mat-option>
                  <!-- <mat-option value="notify" i18n="@@notify">NOTIFY</mat-option> -->
                </mat-select>
              </mat-form-field>

              <i class="material-icons arrow-rotated">
                keyboard_backspace
              </i>

              <mat-form-field>
                <!-- <mat-placeholder class="placeholder" i18n="@@optionAction">Option action</mat-placeholder> -->
                <mat-label i18n="@@optionAction">Option action</mat-label>
                <input matInput [(ngModel)]="singleAction.text"/>
              </mat-form-field>

              <button 
                class="delete-item-button" 
                (click)="deleteOptionAction(textInputOption, i); $event.stopPropagation()">
              </button>

              <span class="move-handle" cdkDragHandle>
                <svg width="12px" fill="#61728d" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </span>
              
            </div>
          </div>
        </div>

        <button
            mat-raised-button
            class="adding-button mat-raised-button"
            (click)="addOptionAction(textInputOption, textInputOption.cardActions.length)"
            i18n="@@addActionButton">
          Add Action
        </button>
      </div>

      <div class="action-buttons">
        <button *ngIf="cardType === 'options' || cardType === 'checkboxes' || cardType === 'relative-options' || cardType === 'relative-checkboxes'"
          mat-raised-button
          color="accent"
          class="add-option"
          (click)="addOption(cardOptions.length)"
          i18n="@@addOptionButton"
        >
          ADD OPTION
        </button>
        <ng-container *ngIf="isScoreType">
          <button
            [disabled]="!cardQuestionText || !selectedScoreDivisions"
            mat-raised-button
            color="accent"
            (click)="saveQuestion()"
            i18n="@@createButton">
              CREATE
          </button>  
        </ng-container>
        <ng-container *ngIf="!isScoreType">
          <button
            [disabled]="!cardQuestionText"
            mat-raised-button
            color="accent"
            (click)="saveQuestion()"
            i18n="@@createButton">
              CREATE
          </button>
        </ng-container>

      </div>

    </mat-card>
  </div>

  <div class="right-content-part">
    <app-hints-page 
      [currentPackageId]="currentPackageId" 
      [isQuestionHints]="true" 
      [isPQActionHints]="false">
    </app-hints-page>
  </div>
</div>
