import {
  Component, OnInit,
  Output, EventEmitter,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { Router} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Company } from 'app/_models/company';
import { CompanyService } from 'app/_services/company.service';
import { UserService } from 'app/_services/user.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { User } from 'app/_models/user';

@Component({
  selector: 'app-create-company',
  templateUrl: './company-create-company.component.html',
  styleUrls: ['./../company-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CompanyCreateCompanyComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  newCompanyName: string = '';
  ownerEmail: string = '';
  userCompanies: Company[] = [];
  currentCompany: Company;

  @Output() addNewDepartment: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private sharedPackageService: SharedPackageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const userInfo = JSON.parse(localStorage.getItem('currentUser'));
    if (userInfo) {
      this.getUserInfo(userInfo.user_id);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserInfo(userId: number): void {
    if (userId && userId > 0) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((userInfo: User) => {
            if (userInfo && userInfo.activeCompanyId) {
              const apiUserId = userInfo.id;
              const activeId = userInfo.activeCompanyId;
              this.getUserCompanies(apiUserId, activeId);
            }
          },
          error => {
            console.log('User info', error);
          });
    }
  }

  getUserCompanies(userId: number, activeId: number): void {
    if (userId && userId > 0) {

      this.companyService.getUserCompanies(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((companies: Company[]) => {
            if (companies && companies.length > 0) {
              this.userCompanies = companies;
              this.sharedPackageService.onRefreshPackage$.emit();

              if (activeId) {
                this.currentCompany = this.getCompanyFromList(activeId);
              } else {
                this.currentCompany = companies[0];
              }
            }
          },
          error => {
            console.log('User Company Error', error);
          });
    }
  }

  getCompanyFromList(companyId: number): any {
    if (companyId && companyId > 0) {
      for (const company of this.userCompanies) {
        if (companyId === company.id) {
          return company;
        }
      }
    }
  }

  addCompany(newCompanyName: string): void {
    if (newCompanyName) {
      const companyInfo = {
        name: newCompanyName,
        packages: [],
        logo: '',
      };

      this.companyService.addNewCompany(companyInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultCompany: Company) => {
            if (resultCompany) {
              this.userService.setActiveCompany(resultCompany.id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    location.reload();
                  },
                  error => {
                    console.log('ERRRR ', error);
                  });
            }
          },
          error => {
            console.log('ERRRR ', error);
          });
    }
    this.newCompanyName = '';
    this.onChangeMode();
  }

  onChangeMode(): void {
    this.router.navigate(['/company']);
  }


}
