<!-- COLLEAGUE-CARD -->
<a class="colleague-card" [routerLink]="['/colleagues/', colleague.id]">
  <div class="colleague-card__header">
    <div class="colleague-card__avatar">
      {{colleague.firstName | slice : 0 : 1}}
    </div>
    <div class="colleague-card__info">
      <div class="colleague-card__name">
        {{colleague.firstName}} {{colleague.lastName}}
      </div>
      <div class="colleague-card__position">
        <span class="colleague-card__department">{{colleague.departmentName}}</span>
      </div>
    </div>
    <button *ngIf="isArchive" class="colleague-card-button" (click)="onRestoreColleague(colleague, $event)" i18n="@@restoreTitle">Restore</button>
  </div>
  <div class="colleague-card__parameters">
    <div class="parameter" *ngFor="let parameter of colleague.parameters">
      <div class="parameter__name">{{parameter.parameterName}}:</div>
      <div class="parameter__value">{{parameter.parameterValue}}</div>
    </div>
  </div>
</a>
<!-- COLLEAGUE-CARD END -->
