import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';

// import Quill from 'quill';
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

import * as QuillNamespace from 'quill';
import ImageResize from 'quill-image-resize-module';
const Quill: any = QuillNamespace;
Quill.register('modules/imageResize', ImageResize);

// import BlotFormatter from 'quill-blot-formatter';
// import BlotFormatter, { AlignAction, DeleteAction, ImageSpec, ResizeAction } from 'quill-blot-formatter';
// Quill.register('modules/blotFormatter', BlotFormatter);

// class CustomImageSpec extends ImageSpec {
//   getActions(): any {
//     return [AlignAction, DeleteAction, ResizeAction];
//   }
// }

@Component({
  selector: 'app-questionnaire-card-description',
  templateUrl: './questionnaire-card-description.component.html',
  styleUrls: ['./questionnaire-card-description.component.scss']
})
export class QuestionnaireCardDescriptionComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() cardId: number;
  @Input() editMode: boolean;
  @Input() cardDescription: string;
  @Output() onSaveDescription: EventEmitter<any> = new EventEmitter<any>();
  // currentPackageId: number;

  editorForm: FormGroup;
  editorContent: string;
  editorEnabled: boolean = true;
  editor: any;
  quill: any;

  modules: any = {};
  content: string = '';

  container: any = [
      ['bold', 'italic', 'underline', 'strike'],
      ['code-block'],
      ['blockquote'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': ['', 'center', 'right'] }],
      ['link', 'image', 'video'],
      ['clean']
  ];

  constructor(
    private formBuilder: FormBuilder,
    private sharedPackageService: SharedPackageService,
    // private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
  ) {
    this.modules = {
      imageResize: {},
      toolbar: {
        container: this.container,
        // handlers: {
        //   image: this.imageHandler
        // }
      }
    };

    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
  }

  ngOnInit(): void {
    // this.editorForm = this.formBuilder.group({
    //   editor: [this.cardDescription, Validators.required]
    // });

    this.content = this.cardDescription;

    // const toolbar = this.editorContent.getModule('toolbar');
    // toolbar.addHandler('image', this.showImageUI);
  }

  contentChanged(editor: any): void {
    // console.log('e')
    // let delta = e.getContents();
    // console.log('delta? ', delta);
    if (this.editMode) {
      this.onRenameDescription(this.cardId, this.content);
    } else {
      this.onCreateDescription(this.content);
    }
  }

  editorCreated(editor: any): void {
    // console.log('Editor create ', editor);
    this.editor = editor;
    this.quill = editor;
    editor.focus();

    // editor.on('selection-change', (range, oldRange, source) => {
    //   console.log('contents??? ', editor.getContents());
    //   if (range === null && oldRange !== null) {
    //     console.log('contents ', editor.getContents());
    //   }
    // });
  }

  // imageHandler(): any {
  //   const getBase64 = function(file: any): any {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = function (): any {
  //       console.log(reader.result);
  //       return reader.result;
  //     };
  //     reader.onerror = function (error: any): any {
  //       console.log('Error: ', error);
  //     };
  //   };

  //   const rez = this.quill.theme.modules.imageResize;
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.click();
  //   input.onchange = () => {
  //     const file = input.files[0];
  //     // file type is only image.
  //     if (/^image\//.test(file.type)) {
  //       // saveToServer(file);
  //       const range = this.quill.getSelection();
  //       console.log('file? ', file);
  //       const fileData = getBase64(file);
  //       this.quill.insertEmbed(range.index, 'image', fileData);
  //     } else {
  //       console.warn('You could only upload images.');
  //     }
  //   };
  // }

  onSubmit(): void {
    // this.editorContent = this.editorForm.get('editor').value;
    // this.cardDescription = this.editorForm.get('editor').value;
    this.editorEnabled = false;
    if (this.editMode) {
      this.onRenameDescription(this.cardId, this.cardDescription);
    } else {
      this.onCreateDescription(this.cardDescription);
    }
  }

  onDiscard(): void {
    // this.editorForm = this.formBuilder.group({
    //   editor: [this.cardDescription, Validators.required]
    // });
    this.editorEnabled = false;
  }

  setEditorEnabled(): void {
    this.editorEnabled = true;
  }

  onFocus(event: any): void {
  }

  onBlur(event: any): void {
    // if (this.editMode) {
    //   this.onRenameDescription(this.cardId, this.cardDescription);
    // } else {
    //   this.onCreateDescription(this.cardDescription);
    // }
  }

  onRenameDescription(cardId: number, newDescription: string): void {
    if (cardId > 0 && newDescription) {
      // console.log('on save description ', newDescription);
      this.onSaveDescription.emit(newDescription);
    } else {
      console.log('Can\'t rename the card');
    }
  }

  onCreateDescription(newDescription: string): void {
    if (newDescription) {
      this.onSaveDescription.emit(newDescription);
    } else {
      console.log('Can\'t create the description');
    }
  }

}
