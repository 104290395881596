import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import {
  FormBuilder, FormGroup,
  FormControl, FormGroupDirective,
  NgForm, Validators
} from '@angular/forms';
import { Location } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { validate } from 'class-validator';

import { CompanyService } from 'app/_services/company.service';
import { SharedService } from 'app/_services/shared.service';
import { UserService } from 'app/_services/user.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { ColleagueValidation } from '../colleague-validation';
import { SnackbarErrorInfoComponent } from '../snackbar-error-info';

import { SnackBarComponent } from 'app/snack-bar';
import { User } from 'app/_models/user';
import { Department } from 'app/_models/department';
import { Colleague } from 'app/_models/colleague';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';
import { PackageService } from 'app/_services/package.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-colleagues-create-colleague',
  templateUrl: './colleagues-create-colleague.component.html',
  styleUrls: ['./../colleagues-page.component.scss'],
  providers: [SnackBarComponent]
})

export class ColleaguesCreateColleagueComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  personForm: FormGroup;
  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();

  @Input() person: any;

  companyId: number = 0;
  departments: Department[] = [];
  nonDepartmentColleagues: Colleague[] = [];
  hasActiveCompany: boolean = true;
  currentLang: string = '';
  infoBar: string[] = [];
  isBusy: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private snackBar: SnackBarComponent,
    private amplitudeService: AmplitudeEventsService,
    private packageService: PackageService,
    private sharedCompanyDataService: SharedCompanyDataService,
    private _location: Location) {
      this.personForm = this.formBuilder.group({
        id: [''],
        firstName: [''],
        lastName: [''],
        // email: ['', [Validators.required]],
        email: [''],
        slackUserId: [''],
        departmentId: [0]
      }, {validator: this.checkEmail});
  }

  ngOnInit(): void {
    const userInfo = JSON.parse(localStorage.getItem('currentUser'));
    if (userInfo) {
      this.getUserInfo(userInfo.user_id);
    }

    this.sharedService.currentLang
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultLang: string) => {
        this.currentLang = resultLang;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: any): void {
    if (changes.person) {
      this.personForm.setValue({
        id: this.person.id || -1,
        firstName: this.person.firstName || '',
        lastName: this.person.lastName || '',
        slackUserId: this.person.slackUserId || '',
        email: this.person.email || '',
        departmentId: this.person.departmentId || 0
      });
    }
  }

  checkPasswords(group: FormGroup): any {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  checkEmail(group: FormGroup): any {
    const emailGroup = group.controls.email;
    if (emailGroup) {
      if (Validators.required(emailGroup)) {
        return {required: true};
      } else if (Validators.email(emailGroup)) {
        return {invalidEmail: true};
      }
    }

    return null;
  }

  getUserInfo(userId: number): void {
    if (userId && userId > 0) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
            if (resultUser && resultUser.activeCompanyId) {
              this.hasActiveCompany = true;
              this.companyId = resultUser.activeCompanyId;
              this.nonDepartmentColleagues = [];
              this.getCompanyDepartments();
            }
          },
          error => {
            console.log('User info', error);
          });
    }
  }

  getCompanyDepartments(): void {
    this.companyService.getCompanyDepartments()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((departments: Department[]) => {
          this.departments = departments;
        },
        error => {
          console.log('departments error ', error);
        });
  }

  onCreateNewPerson(dataModel: Colleague, addMore: boolean): void {
    const newColleagueValidation = new ColleagueValidation();
    newColleagueValidation.firstName = dataModel.firstName;
    newColleagueValidation.lastName = dataModel.lastName;
    newColleagueValidation.email = dataModel.email;

    this.infoBar = [];
    this.isBusy = true;
    validate(newColleagueValidation).then(errors => {
      if (errors.length > 0) {
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
        this.snackBar.openFromComponent(SnackbarErrorInfoComponent, this.infoBar);
        this.isBusy = false;
      } else {
        this.onCreatePerson(dataModel, addMore);
      }
    });

  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  onResetForm(): void {
    this.personForm.reset();
    this.personForm = this.formBuilder.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      slackUserId: [''],
      departmentId: [0]
    }, { validator: this.checkEmail });
  }

  onCreatePerson(dataModel: Colleague, addMore: boolean): void {
    if (this.companyId) {
      const apiColleague = {
        name: dataModel.firstName,
        last_name: dataModel.lastName,
        email: dataModel.email.replace(/[\s\n\r]+/g, ''),
        slack_user_id: dataModel.slackUserId,
        department_id: dataModel.departmentId,
        bio: '',
        company_id: this.companyId,
      };

      this.companyService.addColleague(apiColleague)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultColleague: Colleague) => {
          if (resultColleague.id) {
            this.amplitudeService.addEvent('add single colleague');
            dataModel.id = resultColleague.id;
            this.generatePublicLink(resultColleague.id);
            this.addColleagueToPackages();

            this.sharedCompanyDataService.onColleaguesRefresh$.emit();
            let messageColleagueAdded = 'New colleague was added';

            if (this.currentLang === 'ru') {
              messageColleagueAdded = 'Сотрудник добавлен';
            }

            this.openSnackBar(messageColleagueAdded);

            if (addMore) {
              this.onResetForm();
              this.isBusy = false;
            } else {
              this.goBack();
            }
          }
        },
        error => {
          console.log('on errror ', error);
          this.handleCreateError(error);
          this.isBusy = false;
        });
    }
  }

  addColleagueToPackages(): void {
    const packages = this.sharedCompanyDataService.packages$.getValue();

    forkJoin(
      packages.map((pack) => this.packageService.tryToAddParticipants(pack.id))
    ).subscribe();
  }

  generatePublicLink(colleagueId: number): void {
    this.companyService.updatePublicLink(colleagueId).subscribe();
  }

  handleCreateError(error: any): void {
    let errorText = 'Invalid credentials';

    if (error.error) {
      const errorObject = error.error;

      if (errorObject.email) {
        errorText = 'Email ' + errorObject.email[0];
      } else if (errorObject.firstName) {
        errorText = 'First name ' + errorObject.firstName[0];
      } else if (errorObject.lastName) {
        errorText = 'Last name ' + errorObject.lastName[0];
      }

    }

    this.openSnackBar(errorText);
    // this.openSnackBar("Fields 'Name' or 'Email' can't be blank");
  }

  goBack(): void {
    this._location.back();
  }

  setOptionValue(event: any): void {
    if (event.value) {
      this.personForm.controls['departmentId'].setValue(event.value);
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
