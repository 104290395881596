import {
  Component, OnInit,
  Output, EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Company } from 'app/_models/company';
import { CompanyService } from 'app/_services/company.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { Department } from 'app/_models/department';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';
import { SharedUserService } from 'app/_services/shared-user.service';

@Component({
  selector: 'app-create-department',
  templateUrl: './company-create-department.component.html',
  styleUrls: ['./../company-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CompanyCreateDepartmentComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  newDepartmentName: string = '';
  departments: any = [];
  userCompanies: Company[] = [];
  currentCompany: Company;
  isAdmin: boolean = false;
  activeCompanyId: number = 0;

  @Output() addNewDepartment: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private sharedUserService: SharedUserService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getCompany();
  }

  getUser(): void {
    this.sharedUserService.currentUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        if (user && user.activeCompanyId) {
          if (user.admin) {
            this.isAdmin = user.admin;
          }
          this.activeCompanyId = user.activeCompanyId;
        }
      }, error => {
        console.log('User info', error);
      });
  }

  getCompany(): void {
    this.sharedCompanyDataService.companyInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((company: Company) => {
        this.currentCompany = company;
      }, error => {
        console.log('Company error: ', error);
      });
  }

  onSaveDepartment(): void {
    this.onDepartmentAdd(this.newDepartmentName);
    this.newDepartmentName = '';
  }

  onDepartmentAdd(departmentName: string): void {
    const company = this.currentCompany;

    if (departmentName && company) {
      const companyId = company.id;
      const departmentInfo = {
        name: departmentName.trim(),
        colleagues: [],
        company_id: companyId,
      };

      this.companyService.addNewDepartment(departmentInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDepartment: Department) => {
          if (resultDepartment) {
            this.onChangeMode();
            this.sharedCompanyDataService.onColleaguesRefresh$.emit();
          }
        }, error => {
          console.log(error);
        });
    }
  }

  onChangeMode(): void {
    this.router.navigate(['/colleagues']);
  }
}
