<div [ngClass]="{'widget': !isPublicLink}">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="widget__container">
    <div *ngIf="userView||(!editorEnabled)" [innerHtml]="widgetTitle | safeHtml" class="description-form"></div>

    <div *ngIf="!userView&&editorEnabled">
      <form [formGroup]="editorForm" class="description-form">
        <quill-editor
          [modules]="config"
          (onContentChanged)="onContentChange($event)"
          (onSelectionChanged)="onSelectionChange($event)"
          formControlName="editor">
        </quill-editor>
      </form>
    </div>
  </div>
</div>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="editText()">
    <mat-icon>edit</mat-icon>
    <span i18n="@@editText">Edit text</span>
  </button>
  <button mat-menu-item (click)="showText()">
    <mat-icon>visibility</mat-icon>
    <span i18n="@@showText">Show text</span>
  </button>
  <button mat-menu-item (click)="onDiscard()">
    <mat-icon>visibility</mat-icon>
    <span i18n="@@reset">Reset</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
