import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Colleague } from 'app/_models/colleague';
import { ColleagueInformation } from 'app/_models/colleague-information';
import { ColleagueTable, PackageData } from 'app/colleague-profile/local-models';

@Injectable({
  providedIn: 'root'
})
export class SharedColleagueService {
  public colleague$: BehaviorSubject<Colleague>;
  public colleagueInfo$: BehaviorSubject<ColleagueInformation>;
  public colleaguePackagesData$: BehaviorSubject<PackageData[]>;
  public tables$: BehaviorSubject<ColleagueTable[]>;

  public onColleagueUpdate$: EventEmitter<any>;

  constructor() {
    this.colleague$ = new BehaviorSubject(null);
    this.colleagueInfo$ = new BehaviorSubject(null);
    this.colleaguePackagesData$ = new BehaviorSubject(null);
    this.tables$ = new BehaviorSubject(null);

    this.onColleagueUpdate$ = new EventEmitter();
  }

  public clearSharedService(): void {
    this.colleague$.next(null);
    this.colleagueInfo$.next(null);
    this.tables$.next(null);
  }

  public addColleague(colleague: Colleague): void {
    this.colleague$.next(colleague);
  }

  public addColleagueInfo(colleague: ColleagueInformation): void {
    this.colleagueInfo$.next(colleague);
  }

  public updateColleaguePackagesData(packagesData: PackageData[]): void {
    this.colleaguePackagesData$.next(packagesData);
  }

  public addTables(tables: ColleagueTable[]): void {
    this.tables$.next(tables);
  }

  public clearTables(): void {
    this.tables$.next(null);
  }
}
