import { Record, Number, String, Boolean } from 'runtypes';

export const ApiColleagueParameter = Record({
  id: Number,
  value: Number.Or(String),
  text_value: String,
  experiment_answer_id: Number,
  package_parameter_id: Number,
  colleague_id: Number,
  is_null_value: Boolean,
});
