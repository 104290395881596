import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-info-card',
  templateUrl: './edit-info-card.component.html',
  styleUrls: ['./edit-info-card.component.scss', '../../../package-details.component.scss']
})
export class EditInfoCardComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() questionText: string;
  @Output() onSaveQuestion: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  saveLocalQuestion(event: any): void {
    this.onSaveQuestion.emit(event);
  }

}
