<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2><span i18n="@@parametersList-label"> List of Parameters</span></h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="this.individualParameters.length > 0">
      <h2>
        <i class="material-icons" style="font-size: 30px; vertical-align: bottom;">
          person_outline
        </i>&nbsp;<span i18n="@@individual-parameter-title">Individual Parameters</span>
      </h2>
      <ul [ngClass]="{overThreeItems:this.individualParameters.length > 3}">
        <li *ngFor="let parameter of this.individualParameters" class="parameter-line">
          <button mat-icon-button class="copy-button"
                  (click)="copyParameterName(parameter)">
            <i class="material-icons" i18n-matTooltip="@@copyParameterName-label" matTooltip="Copy to clipboard" [matTooltipPosition]="'before'">
              content_copy
            </i>
          </button>{{parameter.formulaName}}<span class="formula-name-label">{{parameter.visibleName}}</span>
          
        </li>
      </ul>
    </div>
    <div *ngIf="this.commonParameters.length > 0">
      <h2>
        <i class="material-icons" style="font-size: 30px; vertical-align: bottom;">
          people_outline
        </i>&nbsp;<span i18n="@@common-parameter-title">Common Parameters</span>
      </h2>
      <ul [ngClass]="{overThreeItems:this.commonParameters.length > 3}">
        <li *ngFor="let parameter of this.commonParameters " class="parameter-line">
          <button mat-icon-button class="copy-button"
                  (click)="copyParameterName(parameter)">
            <i class="material-icons" i18n-matTooltip="@@copyParameterName-label" matTooltip="Copy to clipboard" [matTooltipPosition]="'before'">
              content_copy
            </i>
          </button>{{parameter.formulaName}}<span class="formula-name-label">{{parameter.visibleName}}</span>
        </li>
      </ul>
    </div>
    <div *ngIf="this.relativeParameters.length > 0">
      <h2>
        <i class="material-icons" style="font-size: 30px; vertical-align: bottom;">
          people_outline
        </i>&nbsp;<span i18n="@@relative-parameter-title">Relative Parameters</span>
      </h2>
      <ul [ngClass]="{overThreeItems:this.relativeParameters.length > 3}">
        <li *ngFor="let parameter of this.relativeParameters " class="parameter-line">
          <button mat-icon-button class="copy-button"
                  (click)="copyParameterName(parameter)">
            <i class="material-icons" i18n-matTooltip="@@copyParameterName-label" matTooltip="Copy to clipboard" [matTooltipPosition]="'before'">
              content_copy
            </i>
          </button>{{parameter.formulaName}}<span class="formula-name-label">{{parameter.visibleName}}</span>
        </li>
      </ul>
    </div>
    <button class="adding-button mat-raised-button" i18n="@@addOneParameterButton" (click)="toParametersTab()">Add
      parameter
    </button>
  </mat-expansion-panel>
  <mat-expansion-panel class="syntax-hints" *ngIf="isQuestionHints">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2><i class="material-icons">
          live_help
        </i> <span i18n="@@HINT-actionTitle"> Action syntax</span></h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li i18n="@@HINT-action1">You don't need an equals sign ("=")</li>
      <li i18n="@@HINT-action2">Use basic math symbols: +, -, *, /</li>
      <li i18n="@@HINT-action3">Use the parentheses () for more complicated formulas</li>
      <li i18n="@@HINT-action4">Parameters may depend on each others: Happiness -> Kindness + Love * 3</li>
      <li i18n="@@HINT-action5">GoTo action use the expression #N (where N is the number of needed question): GoTo -> #3.</li>
      <li i18n="@@HINT-action6">Key-word INPUT can be used as a value for actions in open-ended questions and score questions: Happiness -> Happiness + INPUT.</li>
      <li i18n="@@HINT-action7">Use expressions like A1.INPUT to access answer to a specific question.</li>
    </ul>
  </mat-expansion-panel>
  <mat-expansion-panel class="syntax-hints" *ngIf="isPQActionHints">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2><i class="material-icons">
          live_help
        </i> <span i18n="@@pqa-HINT-actionTitle"> Post-Calculation Syntax</span></h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul>
      <li i18n="@@pqa-HINT-action1">You can use standard operations as for simple Actions.</li>
      <li i18n="@@pqa-HINT-action2">Expressions of type A1.INPUT are available for individual parameters calculation.</li>
      <li i18n="@@pqa-HINT-action3">Special functions can be used to calculate parameters. 
        Available functions: SUM, AVG, MIN, MAX, SIN, COS, TAN, COUNT, ROUND, ROUNDDOWN, ROUNDUP, SQRT, POW and some others.</li>
      <li i18n="@@pqa-HINT-action4">Note that some functions can only be used for common parameters calculation. For these parameters you should specify '.ALL' postfix.
        Example: MAX(Happiness.ALL) finds the maximum value of the Happiness parameter among all respondents.</li>
      <li i18n="@@pqa-HINT-action5">Use AVG function to calculate an average of a parameter between each respondent of
        research (colleagues who did not pass a questionnaire does not count).</li>
      <li i18n="@@pqa-HINT-action6">You can also use special function ENPS.</li>
      <li i18n="@@pqa-HINT-action7">Correlation function is available. Example: CORRELATION(dataset1.ALL, dataset2.ALL). 
        Please note that if the dataset size is too small, the results may be incorrect. We recommend using datasets of 5 or more items.</li>
    </ul>
  </mat-expansion-panel>
</mat-accordion>