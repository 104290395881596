<div class=" experiment-list">
  <div class="list-header">
    <span class="title" i18n="@@listResearches-title">List of researches</span>
    <button mat-icon-button class="sorting-btn" (click)="sortResearch()" [ngClass]="{reverse:isListReverse}" *ngIf="localResearchesList.length > 0" >
      <i class="material-icons" i18n-matTooltip="@@sortResearch-label" matTooltip="Sort the researches" [matTooltipPosition]="'after'">
        sort
      </i>
    </button>
  </div>

  <mat-accordion flex>
    <ng-container *ngIf="localResearchesList.length > 0">
      <div *ngFor="let research of localResearchesList" class="pane mat-elevation-z1" (click)="onPanelClick(research)">
        <mat-expansion-panel [expanded]="false" hideToggle>

          <mat-expansion-panel-header>
            <mat-panel-title>
              <span><span i18n="@@research-label">Research</span> #{{research.orderPosition}}</span>
              <span *ngIf="research.anonymous" class="anonymous"><i class="material-icons"> mic_off </i></span>
              <div class="research-date-info">
                <span>{{research.startDate | slice : 0 : -14}} - {{research.finishDate | slice : 0 : -14}}</span>
              </div>
            </mat-panel-title>
            <mat-panel-description>
                <span class="item-status">
                  <span *ngIf="research.completed" class="completed research-status" i18n="@@status-completed">
                    Completed
                  </span>
                  <span *ngIf="!research.completed" class="research-status" i18n="@@status-inProgress">
                    In progress
                  </span>
                </span>
              <ng-container *ngIf="!research.completed">
                  <span class="item-status">
                    <span *ngIf="checkIsActive(research)" class="active" i18n="@@active">Active</span>
                    <span *ngIf="!checkIsActive(research)" class="inactive" i18n="@@status-scheduled">Scheduled</span>
                  </span>
              </ng-container>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-container *ngIf="research">
            <div class="parameters-calculation-block">

              <div class="parameters-calculation-common">
                <ng-container *ngIf="!research.calculated">
                  <span class="big-text-researches" i18n="@@calculationIsNotComplete">Calculation is not complete</span>
                </ng-container>

                <ng-container *ngIf="research.calculated">
                  <ng-container *ngIf="research.experimentParameters.length > 0">
                    <span class="big-text-researches" i18n="@@common-parameter-title">Common Parameters:</span>
                    <mat-action-list *ngFor="let param of research.experimentParameters">
                      <div mat-list-item class="raisedbox">
                        <span *ngIf = "(param.commonValue.value | typeof) === 'number'">
                          {{param.visibleName || ''}}:
                          {{param.commonValue.value | number : '1.2'}}
                        </span>
                        <span *ngIf="(param.commonValue.value | typeof) !== 'number'">
                          {{param.visibleName || ''}}:
                          {{param.commonValue.value || ''}}
                        </span>
                      </div>
                    </mat-action-list>
                  </ng-container>

                  <ng-container *ngIf="research.experimentParameters.length == 0">
                    <span class="big-text-researches">No common parameters</span>
                  </ng-container>
                </ng-container>

              </div>

              <div class="parameters-calculation-participants" style="margin-top: 20px;">
                <span class="big-text-researches" i18n="@@participants-tab">Participants:</span>

                <mat-action-list *ngFor="let singleAnswer of research.experimentAnswers; let i = index;" [ngClass]="{anonimous:research.anonymous}">
                  <div *ngIf="!research.anonymous" mat-list-item (click)="onAnswerDetails(singleAnswer)" class="raisedbox">
                    <span class="participant-email">{{singleAnswer.email}}</span>
                    <div class="change-button">
                      <span i18n="@@status">Status: </span>
                      <ng-container *ngIf="research.completed">
                      <span class="participant-status" *ngIf="singleAnswer.calculated" style="color: #5ecb6a;" i18n="@@status-calculated">
                        calculated
                      </span>
                      <span class="participant-status" *ngIf="!singleAnswer.calculated&&singleAnswer.completed" style="color: #ffda4d;" i18n="@@status-awaitCalculation">
                        await calculation
                      </span>
                      <span class="participant-status" *ngIf="!singleAnswer.completed" style="color: red;" i18n="@@status-expired">
                        expired
                      </span>
                      </ng-container>
                      <ng-container *ngIf="!research.completed">
                      <span class="participant-status" *ngIf="singleAnswer.completed" style="color: #5ecb6a;" i18n="@@status-finished">
                        finished
                      </span>
                      <span class="participant-status" *ngIf="!singleAnswer.completed" style="color: #ffda4d;" i18n="@@status-inProgress">
                        in progress
                      </span>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="research.anonymous" mat-list-item (click)="onAnswerDetails(singleAnswer)" class="raisedbox">
                    <span class="participant-email">Colleague #{{i+1}}</span>
                    <div class="change-button">
                      <span i18n="@@status">Status: </span>
                      <ng-container *ngIf="research.completed">
                    <span class="participant-status" *ngIf="singleAnswer.calculated" style="color: #5ecb6a;" i18n="@@status-calculated">
                      calculated
                    </span>
                        <span class="participant-status" *ngIf="!singleAnswer.calculated&&singleAnswer.completed" style="color: #ffda4d;" i18n="@@status-awaitCalculation">
                      await calculation
                    </span>
                        <span class="participant-status" *ngIf="!singleAnswer.completed" style="color: red;" i18n="@@status-expired">
                      expired
                    </span>
                      </ng-container>
                      <ng-container *ngIf="!research.completed">
                    <span class="participant-status" *ngIf="singleAnswer.completed" style="color: #5ecb6a;" i18n="@@status-finished">
                      finished
                    </span>
                        <span class="participant-status" *ngIf="!singleAnswer.completed" style="color: #ffda4d;" i18n="@@status-inProgress">
                      in progress
                    </span>
                      </ng-container>
                    </div>
                  </div>
                </mat-action-list>

                <mat-action-list *ngFor="let singleAnswer of research.relativeExperimentAnswers; let i = index;">
                  <div mat-list-item (click)="onRelativeAnswerDetails(singleAnswer)" class="raisedbox">
                    <span class="participant-email">{{singleAnswer.email}}</span>
                    <div class="change-button">
                      <span i18n="@@status">Status: </span>
                      <span class="participant-status" style="color: #808080;">
                        relative
                      </span>
                    </div>
                  </div>
                </mat-action-list>

              </div>

            </div>
          </ng-container>

          <mat-card-actions>
            <div class="action-buttons">
              <ng-container *ngIf="!research.completed">
                <button
                    mat-raised-button
                    color="primary"
                    (click)="finishResearch(research)"
                    i18n="@@completeImmediately">Complete immediately
                </button>
              </ng-container>
              <ng-container *ngIf="research.calculated&&isAdmin">
                <button mat-raised-button color="accent" (click)="resetResearchCalculation(research)"
                  i18n="@@resetCalculation">Reset calculation
                </button>
              </ng-container>
              <ng-container *ngIf="(!research.calculated)&&isAdmin">
                <button mat-raised-button color="accent" (click)="resetResearchAnswers(research)" i18n="@@resetAnswers">
                  Reset answers
                </button>
              </ng-container>
              <button
                  mat-raised-button
                  color="warn"
                  (click)="removeResearch(research)"
                  i18n="@@removeResearch">Remove research
              </button>
            </div>
          </mat-card-actions>

        </mat-expansion-panel>
      </div>
    </ng-container>
    <ng-container *ngIf="isBootstrapped">
      <div *ngIf="localResearchesList.length == 0" class="warning-message">
        <h3 i18n="@@warningMessage-ResearchesTitle">You have no researches :(</h3>
        <p i18n="@@warningMessage-ResearchesBody">Start your first research by the clicking on the "Settings" tab in the
          header</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isBootstrapped">
      <div class="warning-message">
        <h3 i18n="@@warningMessage-Loading">Loading...</h3>
      </div>
    </ng-container>
  </mat-accordion>

</div>

