import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-alert-dialog',
  templateUrl: './custom-alert-dialog.component.html',
  styleUrls: ['./custom-alert-dialog.component.scss']
})
export class CustomAlertDialogComponent implements OnInit {
  dialogTitle: string = 'Delete';
  dialogSubtitle: string = 'Are you sure you want to delete this item?';
  dialogConfirmText: string = 'Delete';
  isConfirmDelete: boolean = true;
  isConfirmPause: boolean = false;
  isConfirmArchive: boolean = false;
  isConfirmReminder: boolean = false;
  isConfirmRestore: boolean = false;
  isConfirmResearch: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomAlertDialogComponent>,
  ) {}

  ngOnInit(): void {
     if (this.data && this.data.dialogTitle && this.data.dialogSubtitle) {
       this.dialogTitle = this.data.dialogTitle;
       this.dialogSubtitle = this.data.dialogSubtitle;
       this.dialogConfirmText = this.data.dialogConfirmText;
     }

     if (this.data && this.data.dialogConfirmType) {
       this.isConfirmArchive = false;
       this.isConfirmDelete = false;
       this.isConfirmPause = false;
       this.isConfirmReminder = false;
       this.isConfirmRestore = false;
       this.isConfirmResearch = false;
       const confirmType = this.data.dialogConfirmType;
       if (confirmType === 'pause') {
        this.isConfirmPause = true;
       } else if (confirmType === 'archive') {
        this.isConfirmArchive = true;
       } else if (confirmType === 'reminder') {
        this.isConfirmReminder = true;
       } else if (confirmType === 'restore') {
        this.isConfirmRestore= true;
      } else if (confirmType === 'research') {
        this.isConfirmResearch = true;
       } else {
        this.isConfirmDelete = true;
       }
     }
  }
}
