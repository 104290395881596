import { Boolean, Number, String, Record, Partial, Array } from 'runtypes';
import { ApiRelativeExperimentParameter } from './api-relative-experiment-parameter';

export const ApiRelativeExperimentAnswer = Record({
  id: Number,
  experiment_id: Number,
  questionnaire_id: Number,
  calculated: Boolean,
  is_relative_answer: Boolean,
}).And(Partial({
  email: String,
  colleague_id: Number,
  relative_experiment_parameters: Array(ApiRelativeExperimentParameter),
}));
