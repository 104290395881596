<!-- COLLEAGUES -->
<section class="colleagues">
  <ng-container *ngIf="!isArchivedColleaguesListEmpty; else emptyArchivedList">
    <div class="colleagues-header">
      <mat-form-field class="colleagues-search">
        <mat-label i18n="@@search">Search</mat-label>
        <input
          matInput type="text"
          [(ngModel)]="colleagueSearch"
          (input)="onColleagueSearch()"
          class="colleagues-search__input">
      </mat-form-field>
    </div> <!-- /.colleagues-header -->

    <div class="colleagues-content">
      <div class="colleagues-content__list">
        <ng-container *ngIf="archivedColleaguesList.length > 0; else noColleagues">
          <ng-container *ngFor="let colleague of archivedColleaguesList">
            <app-colleague-card
              [colleague]="colleague"
              [isArchive]="true"
              (onRestore)="onRestoreColleague($event)">
            </app-colleague-card>
          </ng-container>
        </ng-container>
      </div> <!-- /.colleagues-content__list -->
    </div> <!-- /.colleagues-content -->
  </ng-container>
</section>
<!-- COLLEAGUES END -->

<!-- If company has no archived employees -->
<ng-template #emptyArchivedList>
  <div class="warning-message" i18n="@@noArchivedColleagues">
    Your company has no archived colleagues
  </div>
</ng-template>

<!-- If the colleague search returned no results -->
<ng-template #noColleagues>
  <div class="warning-message" i18n="@@colleaguesNotFound">
    Colleagues not found
  </div>
</ng-template>
