import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { SharedPackageService } from 'app/_services/shared-package.service';
import { CompanyService } from 'app/_services/company.service';
import { PackageParameter } from 'app/_models/package-parameter';
import { takeUntil } from 'rxjs/operators';
import { Experiment } from 'app/_models/experiment';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { ExtendedParameter, ExtendedColleague } from './local-models';
import { SharedService } from 'app/_services/shared.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { Package } from 'app/_models/package';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { PackageParticipant } from 'app/_models/package-participant';

// class SharedExperiments {
//   packageId: number;
//   packageExperiments: Experiment[];
// }

@Injectable()
export class SharedDashboardHelper implements OnDestroy {
  public isLoaded: BehaviorSubject<boolean>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  sharedQuestionnaires: Questionnaire[] = [];
  sharedPackageParameters: PackageParameter[] = [];
  sharedPackageParticipants: PackageParticipant[] = [];
  sharedPackagesList: Package[] = [];
  sharedDashboardWidgets: SharedDashboardWidget[] = [];
  sharedCompanyId: number = 0;

  departments: Department[] = [];
  packageParameters: PackageParameter[] = [];
  colleagues: Colleague[] = [];
  sharedExperiments: Experiment[] = [];

  constructor(
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private companyService: CompanyService,
    private packageService: PackageService,
    private experimentService: ExperimentService,
    private questionnaireService: QuestionnaireService,
  ) {
    this.isLoaded = new BehaviorSubject(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  emptyCachedData(): void {
    this.isLoaded.next(false);
    this.departments = [];
    this.packageParameters = [];
    this.colleagues = [];
    this.sharedExperiments = [];
    this.sharedQuestionnaires = [];
    this.sharedDashboardWidgets = [];
    this.sharedPackageParameters = [];
  }

  setSharedQuestionnaires(inputQuestionnaires: Questionnaire[]): void {
    if (inputQuestionnaires) {
      this.sharedQuestionnaires = inputQuestionnaires;
    } else {
      this.sharedQuestionnaires = [];
    }
  }

  setSharedDashboardWidgets(inputWidgets: SharedDashboardWidget[]): void {
    if (inputWidgets) {
      this.sharedDashboardWidgets = inputWidgets.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });
    } else {
      this.sharedDashboardWidgets = [];
    }
  }

  updateSharedDashboardWidgets(inputWidgets: SharedDashboardWidget[]): void {
    if (inputWidgets) {
      const newSharedDashboardWidgets = [];
      for (const singleWidget of this.sharedDashboardWidgets) {
        const newWidget = inputWidgets.find(widget => widget.id === singleWidget.id);
        if (newWidget) {
          newSharedDashboardWidgets.push(newWidget);
        } else {
          newSharedDashboardWidgets.push(singleWidget);
        }
      }
      this.sharedDashboardWidgets = newSharedDashboardWidgets;
    }
  }

  setSharedPackageParameters(packageParameters: PackageParameter[]): void {
    if (packageParameters) {
      // console.log('SET PARAMETERS SHARED ', packageParameters);
      const notUniqueParameters = packageParameters.filter(this.onlyUnique);
      const uniqueParameters = this.onlyUniqueObjects(packageParameters);
      this.sharedPackageParameters = uniqueParameters;
    } else {
      this.sharedPackageParameters = [];
    }
  }

  setSharedPackages(packList: Package[]): void {
    if (packList) {
      this.sharedPackagesList = packList;
    } else {
      this.sharedPackagesList = [];
    }
  }

  setSharedCompanyId(companyId: number): void {
    if (companyId) {
      this.sharedCompanyId = companyId;
    } else {
      this.sharedCompanyId = 0;
    }
  }

  setSharedParticipants(participants: PackageParticipant[]): void {
    if (participants) {
      this.sharedPackageParticipants = participants;
    } else {
      this.sharedPackageParticipants = participants;
    }
  }

  preloadSharedExperiments(includeAnonymous: boolean): Promise<any> {
    const sharedExperimentsPromises = [];
    for (const singleQuestionnaire of this.sharedQuestionnaires) {
      for (const singleExperiment of singleQuestionnaire.experiments) {
        const isAnonymous = singleExperiment.anonymous;
        if (isAnonymous && includeAnonymous) {
          const singlePromise = this.loadSingleExperiment(singleExperiment.id);
          sharedExperimentsPromises.push(singlePromise);
        } else if (!isAnonymous) {
          const singlePromise = this.loadSingleExperiment(singleExperiment.id);
          sharedExperimentsPromises.push(singlePromise);
        }
      }
    }

    return new Promise((resolve, reject) => {
      Promise.all(sharedExperimentsPromises).then(resultExperiments => {
        resolve(resultExperiments);
      }).catch(error => reject(error));
    });
  }

  setSharedExperiments(experiments: Experiment[]): void {
    const sortedExperimens = experiments.sort(function (a: any, b: any): number {
      return b.id - a.id;
    });
    this.sharedExperiments = sortedExperimens;
  }

  getSharedExperiments(): Experiment[] {
    return this.sharedExperiments;
  }

  loadSingleExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperimentVerbose(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultExp: Experiment) => {
          // console.log('Load single experiment verbose ', resultExp);
          resolve(resultExp);
        }, error => {
          reject(error);
        });
    });
  }

  loadSingleAnswer(experimentAnswerId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperimentAnswer(experimentAnswerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultAnswer: ExperimentAnswer) => {
          resolve(resultAnswer);
        }, error => {
          reject(error);
        });
    });
  }

  getSharedPackageParameters(): PackageParameter[] {
    return this.sharedPackageParameters;
  }

  // getSharedPackageParameters(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (this.sharedPackageParameters) {
  //       resolve(this.sharedPackageParameters);
  //     } else {
  //       reject();
  //     }
  //   });
  // }

  getSharedPackageParticipants(): PackageParticipant[] {
    return this.sharedPackageParticipants;
  }

  // getSharedPackageParticipants(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (this.sharedPackageParticipants) {
  //       resolve(this.sharedPackageParticipants);
  //     } else {
  //       reject();
  //     }
  //   });
  // }

  getSharedDashboardWidget(widgetId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const findWidget = this.sharedDashboardWidgets.find(widg => widg.id === widgetId);
      if (findWidget) {
        resolve(findWidget);
      } else {
        // reject();
        resolve([]);
      }
    });
  }

  getSharedPackages(): Package[] {
    return this.sharedPackagesList;
  }

  preloadColleagues(isSharedMode: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let companyId = this.sharedService.currentCompanyId.getValue();
      if (isSharedMode && (companyId === 0)) {
        companyId = this.sharedCompanyId;
      }
      if (companyId && companyId > 0) {
        this.companyService.getCompanyColleagues(companyId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((colleagues: Colleague[]) => {
            resolve(colleagues);
          }, error => reject(error));
      } else {
        reject();
      }
    });
  }

  setSharedColleagues(colleagues: Colleague[]): void {
    this.colleagues = colleagues;
  }

  getColleagues(): Colleague[] {
    return this.colleagues;
  }

  preloadDepartments(companyId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (companyId > 0) {
        this.companyService.getCompanyAllDepartments(companyId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((departments: Department[]) => {
          console.log(departments);
          resolve(departments);
        }, error => reject(error));
      }
    });
  }

  setSharedDepartments(departments: Department[]): void {
    this.departments = departments;
  }

  getDepartments(): Department[] {
    return this.departments;
  }

  preloadPackageParameters(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (packageId && packageId > 0) {
        this.packageService.getPackageParameters(packageId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((parameters: PackageParameter[]) => {
            resolve(parameters);
          }, error => reject(error));
      } else {
        this.sharedPackageService.packageParameters$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((parameters: PackageParameter[]) => {
            resolve(parameters);
          }, error => reject(error));
      }
    });
  }

  getPackageParameters(packageId: number): PackageParameter[] {
    return this.packageParameters;
  }

  // getDashboardWidget(widgetId: number): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     if (widgetId > 0) {
  //       this.dashboardService.getSharedDashboardWidget(widgetId)
  //         .pipe(takeUntil(this.ngUnsubscribe))
  //         .subscribe((result: any) => {
  //           resolve(result);
  //         }, error => reject(error));
  //     } else {
  //       resolve({});
  //     }
  //   });
  // }

  convertExperimentsDates(experiments: Experiment[]): Experiment[] {
    for (const singleExperiment of experiments) {
      const labelStart = singleExperiment.startDate.split('T')[0];
      const labelFinish = singleExperiment.finishDate.split('T')[0];
      singleExperiment.startDate = labelStart;
      singleExperiment.finishDate = labelFinish;
    }

    return experiments;
  }

  // onlyUnique(value: any, index: number, self: any): boolean {
  //   return self.indexOf(value) === index;
  // }

  onlyUnique(value: any, index: number, self: any): boolean {
    return self.indexOf(value) === index;
  }

  onlyUniqueObjects(objectsArray: any): any {
    const newObjects = [];
    for (const singleObject of objectsArray) {
      const findObject = newObjects.find(obj => obj.id === singleObject.id);
      if (!findObject) {
        newObjects.push(singleObject);
      }
    }

    return newObjects;
  }

  findActiveColleagues(experiments: Experiment[], colleaguesList: Colleague[]): ExtendedColleague[] {
    const activeColleagues: ExtendedColleague[] = [];

    for (const singleColleague of colleaguesList) {
      activeColleagues.push({ ...singleColleague, isActive: true });
    }

    return activeColleagues;
  }

}
