import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

import * as Highcharts from 'highcharts';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { Colleague } from 'app/_models/colleague';
import { ExtendedColleague, ExtendedParameter, WidgetFilters, PlotLine } from '../_helpers/local-models';
import { PackageParameter } from 'app/_models/package-parameter';
import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { PackageParticipant } from 'app/_models/package-participant';
import { Department } from 'app/_models/department';
import { ChartHelper } from '../_helpers/chart-helper';

@Component({
  selector: 'app-individual-parameter-widget',
  templateUrl: './individual-parameter-widget.component.html',
  styleUrls: ['./individual-parameter-widget.component.scss', '../widgets.scss']
})
export class IndividualParameterWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  chartObject: any;
  updateFlag: boolean = false;
  showChartMode: boolean = true;
  linesEditMode: boolean = false;
  isSwitchEnabled: boolean = true;
  showParticipants: boolean = false;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;

  activeExperiment: Experiment;
  inputWidgetFilters: WidgetFilters;
  activeCommonParameter: ExperimentParameter;

  widgetFilters: WidgetFilters = {};
  plotLines: PlotLine[] = [];
  experiments: Experiment[] = [];
  colleaguesList: Colleague[] = [];
  participants: PackageParticipant[] = [];
  localParameters: ExtendedParameter[] = [];
  activeColleagues: ExtendedColleague[] = [];
  commonParameters: ExperimentParameter[] = [];

  tabs: any = [];
  activeExperimentOption: any;
  selectedTabIndex: number = 0;
  activeDepartmentId: number = 0;
  isAllParticipantsSelected: boolean = false;
  isAllParametersSelected: boolean = true;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      marginTop: 40,
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: ''
      },
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      y: 10,
      maxHeight: 100
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}'
        }
      },
      column: {
        stacking: 'normal'
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>:<b>{point.y:.2f}</b><br/>'
    },
    series: null,
  };

  constructor(
    private localDashboardHelper: LocalDashboardHelper,
    private cdRef: ChangeDetectorRef,
    private chartHelper: ChartHelper
  ) { }

  ngOnInit(): void {
    const promisesList = [];

    const promiseColleagues = this.localDashboardHelper.getColleagues(false);
    promiseColleagues.then((colleagues: Colleague[]) => {
      this.colleaguesList = colleagues;
    }).catch(error => console.log(error));

    const promiseParameters = this.localDashboardHelper.getCachedPackageParameters();
    promiseParameters.then((parameters: PackageParameter[]) => {
      const filterParams = parameters.filter(param => param.groupName === 'individual');
      for (const singleParameter of filterParams) {
        this.localParameters.push({
          ...singleParameter,
          isActive: false
        });
      }
    }).catch(error => console.log(error));

    const promiseExperiments = this.localDashboardHelper.getCachedExperiments(true);
    promiseExperiments.then((experiments: Experiment[]) => {
      if (experiments) {
        const sortedExperimens = experiments.sort(function (a: any, b: any): number {
          return b.id - a.id;
        });
        this.experiments = sortedExperimens;
        this.activeExperiment = sortedExperimens[0];
      }
    }).catch(error => console.log(error));

    const promiseWidget = this.localDashboardHelper.getDashboardWidget(this.inputWidgetId);
    promiseWidget.then((widget: DashboardWidget) => {
      this.inputWidgetFilters = widget.widgetFilters;
    }).catch(error => console.log(error));

    const promiseParticipants = this.localDashboardHelper.getCachedParticipants();
    promiseParticipants.then((participants: PackageParticipant[]) => {
      this.participants = participants;
    }).catch((error) => console.log(error));

    const promiseDepartments = this.localDashboardHelper.getDepartments();
    promiseDepartments.then((departments: Department[]) => {
      this.formDepartmentTabs(departments);
    }).catch(error => console.log(error));

    promisesList.push(promiseColleagues);
    promisesList.push(promiseParameters);
    promisesList.push(promiseExperiments);
    promisesList.push(promiseWidget);
    promisesList.push(promiseParticipants);
    promisesList.push(promiseDepartments);

    Promise.all(promisesList).then((result) => {
      this.activeColleagues = this.localDashboardHelper.findActiveColleagues(this.experiments, this.colleaguesList);
      this.checkInputWidgets();
      if (this.showParticipants) {
        this.setActiveParticipants();
      }
      // this.onColleaguesStateChange();
      this.onParametersStateChange();
      const nonEmptyTab = this.findNonEmptyTab();
      if (nonEmptyTab) {
        this.changeTab(nonEmptyTab);
      }

      this.formNewChart();
    }).catch(error => console.log(error));

  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setActiveParticipants(): void {
    this.activeColleagues = this.chartHelper.setActiveParticipants(this.activeColleagues, this.participants);
    this.isAllParticipantsSelected = !this.isAllParticipantsSelected;
    this.onColleaguesStateChange();
  }

  setActiveParameters(): void {
    this.localParameters = this.chartHelper.setActiveParameters(this.localParameters, this.isAllParametersSelected);
    this.isAllParametersSelected = !this.isAllParametersSelected;
    this.onParametersStateChange();
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.showParticipants = true;
    this.widgetFilters['showParticipants'] = true;
    this.widgetFilters['showLatestResearch'] = true;
    this.activeExperimentOption = 'last_research';
  }

  restoreInputWidgetsFilters(): void {
    if (this.inputWidgetFilters) {
      const showLatestResearch = this.inputWidgetFilters.showLatestResearch;
      if (showLatestResearch) {
        const activeResearch = this.getLatestExperiment();
        if (activeResearch) {
          this.activeExperiment = activeResearch;
          this.activeExperimentOption = 'last_research';
        }
      } else {
        const activeResearchId = this.inputWidgetFilters.activeResearchId;
        if (activeResearchId) {
          const activeResearch = this.experiments.find(experiment => experiment.id === activeResearchId);
          if (activeResearch) {
            this.activeExperiment = activeResearch;
            this.activeExperimentOption = activeResearch;
          }
        }
      }

      const colleaguesIds = this.inputWidgetFilters.activeColleaguesIds;
      if (colleaguesIds) {
        for (const singleColleague of this.activeColleagues) {
          const findColleague = colleaguesIds.find(id => id === singleColleague.id);
          if (findColleague) {
            singleColleague.isActive = true;
          } else {
            singleColleague.isActive = false;
          }
        }
      }

      const paramsIds = this.inputWidgetFilters.activeParametersIds;
      if (paramsIds) {
        for (const singleParam of this.localParameters) {
          const findParam = paramsIds.find(id => id === singleParam.id);
          if (findParam) {
            singleParam.isActive = true;
          } else {
            singleParam.isActive = false;
          }
        }
      }

      const plotLines = this.inputWidgetFilters.plotLines;
      if (plotLines) {
        for (const singleLine of plotLines) {
          if (singleLine.packageParameterId > 0) {
            const newValue = this.updateCommonLineValue(singleLine);
            singleLine.value = newValue;
          }
          this.plotLines.push(singleLine);
        }
      }

      const showParticipants = this.inputWidgetFilters.showParticipants;
      if (typeof (showParticipants) === 'undefined' ) {
        this.showParticipants = true;
      } else {
        if (showParticipants) {
          this.showParticipants = true;
        } else {
          this.showParticipants = false;
        }
      }

    }
  }

  widgetFiltersUpdate(): void {
    const colleaguesFilter = this.activeColleagues.filter(colleague => colleague.isActive === true);
    const activeColleaguesIds = [];
    for (const singleColleague of colleaguesFilter) {
      activeColleaguesIds.push(singleColleague.id);
    }
    this.widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

    const parametersFilter = this.localParameters.filter(param => param.isActive === true);
    const activeParamsIds = [];
    for (const singleParam of parametersFilter) {
      activeParamsIds.push(singleParam.id);
    }

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    const plotLines = this.plotLines;
    if (plotLines) {
      this.widgetFilters['plotLines'] = plotLines;
    }

    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
        this.widgetFilters['showLatestResearch'] = true;
      } else {
        this.activeExperiment = experimentOption;
        this.widgetFilters['showLatestResearch'] = false;
      }
    }

    if (this.showParticipants) {
      this.widgetFilters['showParticipants'] = true;
    } else {
      this.widgetFilters['showParticipants'] = false;
    }

    if (this.activeExperiment) {
      this.widgetFilters['activeResearchId'] = this.activeExperiment.id;
    }

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  formDepartmentTabs(departments: Department[]): void {
    this.tabs = [];
    let i = 0;
    for (const singleDepartment of departments) {
      const tabItem = { id: i, name: singleDepartment.name, departmentId: singleDepartment.id };
      this.tabs.push(tabItem);
      i = i + 1;
    }
    if (this.tabs.length > 0) {
      this.activeDepartmentId = this.tabs[0].departmentId;
      this.selectedTabIndex = this.tabs[0].id;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }

  onExperimentChange(event: any): void {
    // console.log('Active experiment ', this.activeExperiment);
    this.widgetFiltersUpdate();
    if (this.plotLines) {
      for (const singleLine of this.plotLines) {
        if (singleLine.packageParameterId > 0) {
          const newValue = this.updateCommonLineValue(singleLine);
          singleLine.value = newValue;
        }
      }
    }
  }

  updateCommonLineValue(plotLine: PlotLine): number {
    if (this.activeExperiment) {
      for (const singleParam of this.activeExperiment.experimentParameters) {
        if (singleParam.packageParameterId === plotLine.packageParameterId) {
          return Number(singleParam.commonValue.value) || 0;
        }
      }
      return plotLine.value;
    }
  }

  onAddNewLine(): void {
    const nextPosition = this.plotLines.length + 1;
    const newLine: PlotLine = {
      color: 'red',
      label: 'label',
      value: 1,
      width: 1,
      zIndex: 1,
      position: nextPosition,
      packageParameterId: 0,
    };
    this.plotLines.push(newLine);
  }

  onAddCommonLineAction(): void {
    const commonParameters: ExperimentParameter[] = []
    if (this.activeExperiment) {
      for (const singleParam of this.activeExperiment.experimentParameters) {
        commonParameters.push(singleParam);
      }
    }

    if (commonParameters.length > 0) {
      this.activeCommonParameter = commonParameters[0];
      this.commonParameters = commonParameters;
    }
  }

  onAddNewCommonLine(): void {
    if (this.activeCommonParameter) {
      const activeParam = this.activeCommonParameter;
      const nextPosition = this.plotLines.length + 1;
      const newLine: PlotLine = {
        color: 'red',
        label: activeParam.visibleName,
        value: Number(activeParam.commonValue.value) || 0,
        width: 1,
        zIndex: 1,
        position: nextPosition,
        packageParameterId: activeParam.packageParameterId,
      };
      this.plotLines.push(newLine);
      this.activeCommonParameter = null;
    }
  }

  onResetCommonLine(): void {
    this.activeCommonParameter = null;
  }

  onRemoveLine(line: PlotLine): void {
    const newPlotLines: PlotLine[] = [];
    let nextPosition = 1;
    for (const singleLine of this.plotLines) {
      if (singleLine.position !== line.position) {
        singleLine.position = nextPosition;
        newPlotLines.push(singleLine);
        nextPosition = nextPosition + 1;
      }
    }
    this.plotLines = newPlotLines;
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
  }

  formNewChart(): void {
    if (this.activeExperiment) {
      const isAnonymous = this.activeExperiment.anonymous;
      if (isAnonymous) {
        this.formNewChartAnonymous(this.activeExperiment);
      } else {
        this.formNewChartRegular(this.activeExperiment);
      }
    } else {
      this.formTestChart();
    }
  }

  findMaxValue(): number {
    let maxValue = 10;
    for (const singleLine of this.plotLines) {
      if (Number(singleLine.value) > maxValue) {
        maxValue = Number(singleLine.value);
      }
    }
    return maxValue + 2;
  }

  formTestChart(): void {
    const newSeries = [];
    const itemsData = [];
    const maxValue = this.findMaxValue();
    for (let i = 1; i < 10; i++) {
      itemsData.push({
        name: 'Colleague #' + i,
        y: this.precise(Math.random() * maxValue),
      });
    }

    const colleaguesSeries = {
      name: '',
      showInLegend: false,
      data: itemsData,
    };
    newSeries.push(colleaguesSeries);

    let maximumDataValue = 0;
    for (const singleData of itemsData) {
      if (singleData.y && (singleData.y > maximumDataValue)) {
        maximumDataValue = singleData.y;
      }
    }

    if (this.plotLines.length > 0) {
      const infoLines = this.formInfoLines();
      for (const singleLine of infoLines) {
        newSeries.push(singleLine);
      }
      this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
    } else {
      newSeries.push({
        type: 'area',
        name: 'Test level',
        color: 'red'
      });
      this.chartOptions.yAxis = this.formTestYAxis();
    }

    this.chartOptions.series = newSeries;
    this.chartOptions.title = {
      text: 'Test data',
      verticalAlign: 'middle',
      style: {
        fontSize: '40',
        color: 'gray'
      }
    };
    this.chartOptions.chart = {
      marginTop: 20,
      marginBottom: 80
    },
    setTimeout(() => {
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  formTestYAxis(): any {
    const plotLines = [
      {
        color: 'red',
        value: 5,
        width: 2,
        zIndex: 10,
      }
    ];
    return {
      title: {
        text: ''
      },
      plotLines: plotLines
    };
  }

  formNewChartAnonymous(activeExperiment: Experiment): void {
    const newSeries = [];
    let maximumDataValue = 0;
    let colorsCounter = 0;
    const activeParameters = this.localParameters.filter(param => param.isActive === true);
    for (const singleParameter of activeParameters) {
      const itemsData = [];
      let colleagueCounter = 1;
      for (const singleAnswer of activeExperiment.experimentAnswers) {
        const colleagueName = 'Colleague #' + colleagueCounter;
        const item = this.formAnonymousColleagueSeries(colleagueName, singleAnswer, singleParameter);
        itemsData.push(item);
        colleagueCounter = colleagueCounter + 1;
      }

      const colleaguesSeries = {
        name: singleParameter.visibleName,
        showInLegend: true,
        data: itemsData,
        stack: singleParameter.visibleName,
        color: this.getNextColor(colorsCounter),
      };
      newSeries.push(colleaguesSeries);

      for (const singleData of itemsData) {
        if (singleData.y && (singleData.y > maximumDataValue)) {
          maximumDataValue = singleData.y;
        }
      }

      colorsCounter = colorsCounter + 1;

    }

    const infoLines = this.formInfoLines();
    for (const singleLine of infoLines) {
      newSeries.push(singleLine);
    }

    this.chartOptions.series = newSeries;
    this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
    this.chartOptions.title = {
      text: '',
      margin: 10,
      // verticalAlign: 'bottom',
    };
    setTimeout(() => {
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  formAnonymousColleagueSeries(colleagueName: string, singleAnswer: ExperimentAnswer, activeParameter: ExtendedParameter): any {
    let resultValue = 0;
    if (activeParameter) {
      const colleagueParameters = singleAnswer.colleagueParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  findNonEmptyTab(): any {
    if (this.activeExperiment && this.tabs && this.tabs.length > 0) {
      for (const singleTab of this.tabs) {
        let filteredColleagues = [];
        if (this.showParticipants) {
          filteredColleagues = this.activeColleagues;
        } else {
          filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
        }

        if (singleTab.departmentId > 0) {
          filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === singleTab.departmentId);
        }

        if (filteredColleagues) {
          const activeParameters = this.localParameters.filter(param => param.isActive === true);
          if (activeParameters.length === 0) {
            return null;
          }

          for (const singleColleague of filteredColleagues) {
            const answers = this.activeExperiment.experimentAnswers;
            // const resultAnswer = answers.find(answer => answer.colleagueId === singleColleague.id);
            const resultAnswers = answers.filter(answer => answer.colleagueId === singleColleague.id);
            for (const resultAnswer of resultAnswers) {
              const nonEmpty = this.findNonEmptyItem(resultAnswer);
              if (this.showParticipants) {
                if (resultAnswer && resultAnswer.completed && nonEmpty) {
                  return singleTab;
                }
              } else {
                if (resultAnswer && nonEmpty) {
                  return singleTab;
                }
              }
            }

            const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
            if (relativeAnswers) {
              const resultAnswer = relativeAnswers.find(answer => answer.colleagueId === singleColleague.id);
              const nonEmpty = this.findNonEmptyItem(resultAnswer);
              if (resultAnswer && nonEmpty) {
                return singleTab;
              }
            }
          }

        }

      }
    }
  }

  findNonEmptyItem(resultAnswer: any): boolean {
    if (resultAnswer) {
      const colleagueParams = resultAnswer["colleagueParameters"];
      if (colleagueParams) {
        for (const singleParam of colleagueParams) {
          if (singleParam.value > 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  formNewChartRegular(activeExperiment: Experiment): void {
    let filteredColleagues = [];
    if (this.showParticipants) {
      filteredColleagues = this.activeColleagues;
    } else {
      filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
    }

    if (this.activeDepartmentId > 0) {
      filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
    }

    if (filteredColleagues) {
      const newSeries = [];

      let maximumDataValue = 0;
      // const filteredColleagues = filterColleagues.filter(colleague => colleague.isActive === true);
      const activeParameters = this.localParameters.filter(param => param.isActive === true);
      let colorsCounter = 0;
      for (const singleParameter of activeParameters) {
        const itemsData = [];
        for (const singleColleague of filteredColleagues) {
          const answers = activeExperiment.experimentAnswers;
          // const resultAnswer = answers.find(answer => answer.colleagueId === singleColleague.id);
          const resultAnswers = answers.filter(answer => answer.colleagueId === singleColleague.id);
          resultAnswers.sort((a, b) => a.id - b.id);
          let colleagueCounter = 0;
          if (resultAnswers.length > 1) {
            colleagueCounter = 1;
          }

          const colleagueItemsData = [];
          for (const resultAnswer of resultAnswers) {
            if (this.showParticipants) {
              if (resultAnswer && resultAnswer.completed) {
                const item = this.formColleagueSeries(singleColleague, resultAnswer, singleParameter, colleagueCounter);
                // itemsData.push(item);
                colleagueItemsData.push(item);
                colleagueCounter = colleagueCounter + 1;
              }
            } else {
              if (resultAnswer) {
                const item = this.formColleagueSeries(singleColleague, resultAnswer, singleParameter, colleagueCounter);
                // itemsData.push(item);
                colleagueItemsData.push(item);
                colleagueCounter = colleagueCounter + 1;
              }
            }
          }

          // console.log('Items data: colleague ', colleagueItemsData);
          const filteredItems = this.filterZeroValues(colleagueItemsData);
          for (const singleItem of filteredItems) {
            itemsData.push(singleItem);
          }

          const relativeAnswers = activeExperiment.relativeExperimentAnswers;
          if (relativeAnswers) {
            const resultAnswer = relativeAnswers.find(answer => answer.colleagueId === singleColleague.id);
            if (resultAnswer) {
              const item = this.formColleagueSeriesRelative(singleColleague, resultAnswer, singleParameter);
              itemsData.push(item);
            }
          }
        }
        const colleaguesSeries = {
          name: singleParameter.visibleName,
          showInLegend: true,
          data: itemsData,
          stack: singleParameter.visibleName,
          color: this.getNextColor(colorsCounter),
        };
        newSeries.push(colleaguesSeries);
        colorsCounter = colorsCounter + 1;

        for (const singleData of itemsData) {
          if (singleData.y && (singleData.y > maximumDataValue)) {
            maximumDataValue = singleData.y;
          }
        }
      }

      const infoLines = this.formInfoLines();
      for (const singleLine of infoLines) {
        newSeries.push(singleLine);
      }

      this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
      this.chartOptions.title = {
        // text: this.activeParameter.visibleName,
        text: '',
        margin: 10,
        // verticalAlign: 'bottom',
      };

      this.chartOptions.series = newSeries;
      setTimeout(() => {
        this.updateFlag = true;
        this.isSwitchEnabled = true;
      }, 30);
    }
  }

  filterZeroValues(colleagueItemsData: any): any {
    if (colleagueItemsData && colleagueItemsData.length > 0) {
      // console.log('Filter zero values ', colleagueItemsData);
      const filteredList = [];
      for (const singleItem of colleagueItemsData) {
        if (singleItem.y > 0) {
          filteredList.push(singleItem);
        }
      }

      if (filteredList.length === 0) {
        filteredList.push(colleagueItemsData[0]);
      }

      if (filteredList.length === 1) {
        const colleagueName = filteredList[0].name;
        filteredList[0].name = colleagueName.split("#")[0].trim();
      }

      return filteredList;
    } else {
      return colleagueItemsData;
    }
  }

  getNextColor(count: number): string {
    const defaultColor = "rgb(124, 181, 236)";
    const colorsArray = ["rgb(124, 181, 236)", "rgb(144, 237, 125)", "rgb(247, 163, 92)", "rgb(100, 10, 100)"];
    if (count && colorsArray[count]) {
      return colorsArray[count];
    } else {
      return defaultColor;
    }
  }

  formColleagueSeries(colleague: Colleague, singleAnswer: ExperimentAnswer,
    activeParameter: ExtendedParameter, colleagueCounter: number): any {
    let colleagueName = colleague.firstName + ' ' + colleague.lastName;
    if (colleagueCounter && colleagueCounter > 0) {
      colleagueName = colleagueName + ' #' + colleagueCounter;
    }
    let resultValue = 0;
    if (activeParameter && singleAnswer) {
      const colleagueParameters = singleAnswer.colleagueParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  formColleagueSeriesRelative(colleague: Colleague, singleAnswer: RelativeExperimentAnswer, activeParameter: ExtendedParameter): any {
    const colleagueName = colleague.firstName + ' ' + colleague.lastName;
    let resultValue = 0;
    if (activeParameter && singleAnswer) {
      const colleagueParameters = singleAnswer.relativeExperimentParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  formInfoLines(): any {
    const infoLines = [];
    for (const singleLine of this.plotLines) {
      const infoLine = {
        type: 'area',
        name: singleLine.label,
        color: singleLine.color
      };
      infoLines.push(infoLine);
    }
    return infoLines;
  }

  formInfoYAxis(maximumDataValue: number): any {
    const plotLines = [];
    let maxValue = maximumDataValue;
    for (const singleLine of this.plotLines) {
      const info = {
        color: singleLine.color,
        value: singleLine.value,
        width: singleLine.width,
        zIndex: singleLine.zIndex,
      };
      if (Number(singleLine.value) > maxValue) {
        maxValue = Number(singleLine.value);
      }
      plotLines.push(info);
    }
    return {
      title: {
        text: ''
      },
      max: maxValue,
      plotLines: plotLines
    };
  }

  precise(inputValue: number): number {
    if (inputValue) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  chartCallback = (chart: any) => {
    this.chartObject = chart;
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  onParametersStateChange(): void {
    this.widgetFiltersUpdate();
  }

  onParticipantsStateChange(): void {
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    this.widgetFiltersUpdate();
    this.formNewChart();
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    this.widgetFiltersUpdate();
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.parametersEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.researchesEditMode = true;
  }

  onLinesEditMode(): void {
    this.setAllModesFalse();
    this.linesEditMode = true;
  }

  onShowChartMode(): void {
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
    this.setAllModesFalse();
    this.showChartMode = true;
    this.formNewChart();
  }

  setAllModesFalse(): void {
    this.showChartMode = false;
    this.colleaguesEditMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
    this.linesEditMode = false;
  }

  changeTab(tab: any): void {
    if (this.isSwitchEnabled) {
      this.isSwitchEnabled = false;
      this.activeDepartmentId = tab.departmentId;
      this.selectedTabIndex = tab.id;
      this.formNewChart();
    }
  }

  onBlurMethod(): void {
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
  }

}
