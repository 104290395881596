import {Component, OnInit, OnDestroy, Input, EventEmitter,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageParameter } from 'app/_models/package-parameter';

export class ExtendedParameter extends PackageParameter {
  formulaMode: boolean;
}

@Component({
  selector: 'app-hints-page',
  templateUrl: './hints-page.component.html',
  styleUrls: [
    './hints-page.component.scss',
  ],
})
export class PackageHintsPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() currentPackageId: number;
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  individualParameters: ExtendedParameter[] = [];
  commonParameters: ExtendedParameter[] = [];
  relativeParameters: ExtendedParameter[] = [];

  @Input() isQuestionHints: boolean;
  @Input() isPQActionHints: boolean;

  constructor(
    private router: Router,
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.sharedPackageService.packageParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameters: PackageParameter[]) => {
        // console.log(parameters);  // ToDo empty if reload page
        this.separateParameters(parameters);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  switchParameterMode(groupName: string): void {
    if (groupName === 'individual') {
      for (const singleParameter of this.individualParameters) {
        const currentMode = singleParameter.formulaMode;
        singleParameter.formulaMode = !currentMode;
      }
    } else if (groupName === 'common') {
      for (const singleParameter of this.commonParameters) {
        const currentMode = singleParameter.formulaMode;
        singleParameter.formulaMode = !currentMode;
      }
    } else if (groupName === 'relative') {
      for (const singleParameter of this.relativeParameters) {
        const currentMode = singleParameter.formulaMode;
        singleParameter.formulaMode = !currentMode;
      }
    }
  }

  separateParameters(parametersList: PackageParameter[]): void {
    this.individualParameters = [];
    this.commonParameters = [];
    this.relativeParameters = [];
    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        const newParameter = this.getExtendedParameter(singleParameter);
        this.individualParameters.push(newParameter);
      } else if (groupName === 'common') {
        const newParameter = this.getExtendedParameter(singleParameter);
        this.commonParameters.push(newParameter);
      } else if (groupName === 'relative') {
        const newParameter = this.getExtendedParameter(singleParameter);
        this.relativeParameters.push(newParameter);
      }
    }
  }

  getExtendedParameter(parameter: PackageParameter): ExtendedParameter {
    const newParameter: ExtendedParameter = {
      formulaMode: false,
      ...parameter
    };
    return newParameter;
  }

  toParametersTab(): void {
    const packId = this.currentPackageId;
    this.sharedService.editQuestionMode$.emit(false);
    this.changeTab.emit(1);
    this.router.navigateByUrl(`/questionnaires/${packId}/parameters`);
  }

  copyParameterName(parameter: ExtendedParameter): void {
    const selBox = document.createElement('textarea');
    const parameterName = parameter.formulaName;

    selBox.value = parameterName;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
