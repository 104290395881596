<div class="answer-header card-wrapper">
  <mat-card-subtitle>
    <label class="left-content-part"><span i18n="@@questionnaire">Questionnaire</span> №{{packageId}}</label>
    <span class="right-content-part">
      <button mat-raised-button color="primary" (click)="goBack()" i18n="@@prev-label">
        BACK
      </button>
    </span>
  </mat-card-subtitle>
  <h2 class="title"><span>Relative experiment parameters for </span> {{colleagueName}}</h2>
  <h2><span>({{experimentPeriod}})</span></h2>
</div>
<div class="card-wrapper" flex>
  <div class="left-content-part" *ngIf="isCalculated">
    <mat-card>
      <h2>Individual Parameters:</h2>
      <ul>
        <li *ngFor="let parameterResult of resultStateIndividual">
          {{parameterResult.name}}: {{parameterResult.value}}
        </li>
      </ul>
    </mat-card>
  </div>
</div>