import { ShortUrl } from 'app/_models/short-url';
import { ApiShortUrl } from 'app/_api_models/api-short-url';
import { environment } from '../../environments/environment';

export function parseApiShortUrl(apiResponse: any): ShortUrl {
  let ShortUrlObject;

  if (environment.production) {
    ShortUrlObject = apiResponse;
  } else {
    ShortUrlObject = ApiShortUrl.check(apiResponse);
  }

  const data = {
    colleagueId: parseInt(ShortUrlObject.data.colleagueId),
    evaluatorId: parseInt(ShortUrlObject.data.evaluatorId),
    experimentId: parseInt(ShortUrlObject.data.experimentId),
    experimentAnswerId: parseInt(ShortUrlObject.data.experimentAnswerId),
    evaluatorEmail: ShortUrlObject.data.email,
    companyToken: ShortUrlObject.data.companyToken
  }

  const newShortUrl: ShortUrl = {
    id: ShortUrlObject.id,
    urlHash: ShortUrlObject.url_hash,
    data: data
  };

  return newShortUrl;
}
