<mat-card class="mat-card-class">
  <h2 i18n="@@settings-change-description">You can edit questionnaire description</h2>
  <p i18n="@@settings-description-preview-label">Description preview</p>
  <div *ngIf="!editorEnabled" class="custom-description">
    <div class="description-preview ql-editor">
      <h2 class="description-preview-title">{{packageName}}</h2>
      <div *ngIf="packageDescription" [innerHtml]="packageDescription | safeHtml"></div>
      <div i18n="@@settings-missed-description-warn" *ngIf="!packageDescription">Questionnaire description is missed. Click to "Edit description" add it.</div>
    </div>
    <div class="buttons-box">
      <button 
        mat-raised-button 
        i18n="@@settings-description-editButton" 
        color="accent" 
        class="description-button"
        (click)="setEditorEnabled()">
        Edit description
      </button>
    </div>
  </div>
  <div *ngIf="editorEnabled" class="custom-description">
    <form [formGroup]="editorForm">
      <div class="form-group">
        <quill-editor [modules]="config" (onContentChanged)="checkMaxLength($event)" formControlName="editor"></quill-editor>
        <!--<span *ngIf="editorForm.get('editor').value != null">{{1000 - editorForm.get('editor').value.length}}</span>-->
      </div>
      <div class="buttons-box two-buttons">
        <button mat-raised-button type="button" i18n="@@settings-description-saveButton" color="accent" (click)="onSubmit()">
          Save changes
        </button>
        <button mat-raised-button type="button" i18n="@@settings-description-discardButton" color="warn" (click)="onDiscard()">
          Discard changes
        </button>
      </div>
    </form>
  </div>

</mat-card>