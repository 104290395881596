import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedService } from 'app/_services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogHowToComponent } from 'app/_dialogs/dialog-how-to/dialog-how-to.component';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-user-view',
  templateUrl: './dashboard-user-view.component.html',
  styleUrls: ['./dashboard-user-view.component.scss']
})
export class DashboardUserViewComponent implements OnInit, OnDestroy {
  public isAdmin: boolean = false;
  public isBootstrapped: boolean = false;
  public hasDashboardConfig: boolean = false;
  public isWidgets: boolean = false;

  private isTestData: boolean = false;
  private isRussianLocale: boolean = false;
  private dashboardConfigWidgets: DashboardWidget[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService
  ) { }

  ngOnInit(): void {
    this.setLocale();
    this.checkPackageBootstrap();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setLocale(): void {
    const lang = this.sharedService.currentLang.getValue();
    if (lang === 'ru') {
      this.isRussianLocale = true;
    } else {
      this.isRussianLocale = false;
    }
  }

  checkPackageBootstrap(): void {
    this.sharedPackageService.packageBootstrapped$
      .pipe(
        filter(packageBootstrapped => packageBootstrapped),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.setStandartSet();
        this.getExperiments();
      });
  }

  setStandartSet(): void {
    const isStandartSet = this.sharedPackageService.isStandardSet$.getValue();

    if (isStandartSet) {
      this.sharedService.currentStandardSetTabId.next(2);
      this.isTestData = true;
    } else {
      this.sharedService.currentTabId.next(0);
    }
  }

  getExperiments(): void {
    this.sharedPackageService.experiments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getDashboardConfig();
      });
  }

  getDashboardConfig(): void {
    const dashboardConfig = this.sharedPackageService.dashboardConfig$.value;

    this.dashboardService.getDashboardConfig(dashboardConfig.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((config: DashboardConfig) => {
        this.hasDashboardConfig = true;
        this.sharedPackageService.dashboardConfig$.next(config);
        this.checkWidgets(config);
        this.reorderWidgetsArray(config);
      }, error => {
        console.log('Error ', error);
      });
  }

  checkWidgets(config: DashboardConfig): void {
    if (config.dashboardWidgets.length > 0) {
      this.isWidgets = true;
    }
  }

  onAddWidgets(): void {
    const packageId = this.sharedPackageService.currentPackageId$.value;
    this.router.navigateByUrl(`/questionnaires/${packageId}/dashboard-editor`);
  }

  reorderWidgetsArray(config: DashboardConfig): void {
    const newConfig = [];
    for (let i = 1; i < config.dashboardWidgets.length + 1; i++) {
      const findWidget = config.dashboardWidgets.find(widget => widget.orderPosition === i);
      if (findWidget) {
        newConfig.push(findWidget);
      }
    }

    this.dashboardConfigWidgets = newConfig;
    this.isBootstrapped = true;
  }

  onHowToDialog(): void {
    this.dialog.open(DialogHowToComponent, {
      data: {
        videoType: 'editStandardPackage'
      }
    });
  }
}
