import { Number, String, Record, Null } from 'runtypes';

export const ApiParameter = Record({
  id: Number,
  uuid: String,
  entity_uuid: String,
  formula_name: String,
  name: String,
  type: String,
  formula: String.Or(Null)
});
