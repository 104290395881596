<div class="package-questionnaire">
  <div class="questions-list" flex>

    <div class="question-box"
      *ngFor="let card of cards; let idCard = index; let odd=odd; let even=even;"
      [ngClass]="{ odd: odd, even: even, isGottenPack:true}">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <span i18n="@@question-label">Question</span> #{{idCard + 1}}
          </mat-card-title>
        </mat-card-header>

        <app-set-single-card-view [card]="card">
        </app-set-single-card-view>
        
      </mat-card>
    </div>

    <div class="pqa-box" *ngFor="let pqAction of postQuestionnaireActions; let idAction = index;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <span i18n="@@PCA-title">Post Calculation Action</span> #{{idAction + 1}}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="actions">
            <span>{{pqAction.parameter}}</span>
            <i class="material-icons arrow-rotated">
              keyboard_backspace
            </i>
            <span>{{pqAction.text}}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

  </div>

  <div class="right-sidebar">
    <div class="button-view">
      <button
          [disabled]="cards.length < 1"
          mat-raised-button
          (click)="viewDemo()">
        <img src="/assets/overmind_icons/demo-eye.svg">
        <span i18n="@@demoMode-title"> Preview</span>
      </button>
    </div>
  </div>

<!-- 
<div *ngIf="this.viewMode" class="demo-mode">
  <div class="demo-header-group">
    <div class="demo-title">
      <img src="/assets/overmind_icons/demo-eye-hover.svg" />
      <h2 i18n="@@demoMode-title">
        Preview
      </h2>
    </div>

    <div class="modal-close" (click)="goBack()">
      <img src="/assets/overmind_icons/demo-close.svg" />
    </div>
  </div>

  <app-questionnaire-user-view
      [inputPackageId]="currentSetId"
  >
  </app-questionnaire-user-view>
</div> -->

</div>