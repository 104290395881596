<div class="packages-list-style">
  <ng-container *ngFor="let pack of sharedPackagesList">
    <div class="package-single-card">
      <span style="margin: 5px;">{{pack.name}}</span>
      <ng-container *ngIf="!pack.isAvailable">
        <button (click)="onAddSharedPackage(pack)">
          Add
        </button>
      </ng-container>
        <ng-container *ngIf="pack.isAvailable">
        <button (click)="onRemoveSharedPackage(pack)">
          Remove
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>