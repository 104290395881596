<mat-card class="settings-card">
  <h2 i18n="@@settings-manageResearch-title">You can manage your research</h2>
  <mat-card-header>
    <mat-card-title>
      <h2 class="title" *ngIf="isResearchActive">
        <span class="title-icon" [ngClass]="{'isStarted':isResearchActive}">
          <i class="material-icons">play_circle_filled</i>
        </span>
        <span i18n="@@settings-statusResearch-on"> Research is on</span>
        <i class="material-icons help-tooltip" matTooltip="Your timezone is: {{currentTimezoneString}}">
          help_outline
        </i>
      </h2>
      <h2 class="title" *ngIf="!isResearchActive">
        <span class="title-icon" [ngClass]="{'isStopped':!isResearchActive}">
          <i class="material-icons">pause_circle_filled</i>
        </span>
        <span i18n="@@settings-statusResearch-off"> Research is off</span>
        <i class="material-icons help-tooltip" matTooltip="Your timezone is: {{currentTimezoneString}}">
          help_outline
        </i>
      </h2>
    </mat-card-title>
  </mat-card-header>

  <!-- [disabled]="isResearchActive" -->
  <form [formGroup]="periodFormGroup" autocomplete="off">
    <mat-form-field>
      <mat-placeholder class="placeholder" i18n="@@settings-interval">Repetition interval</mat-placeholder>
      <mat-select id="interval" formControlName="interval" (selectionChange)="onIntervalUpdate($event)" required>
        <mat-option value="daily" i18n="@@settings-daily" [disabled]="isResearchActive">Daily</mat-option>
        <mat-option value="weekly" i18n="@@settings-weekly" [disabled]="isResearchActive">Weekly</mat-option>
        <mat-option value="monthly" i18n="@@settings-monthly" [disabled]="isResearchActive">Monthly</mat-option>
        <mat-option value="quarterly" i18n="@@settings-quarterly" [disabled]="isResearchActive">Quarterly</mat-option>
        <!--             <mat-option value="yearly" i18n="@@settings-yearly">Yearly</mat-option> -->
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="showFormDays">
      <div class="choose-day-of-repetition">
        <label i18n="@@weeksDay">Days of repetition <span style="font-size: 12px;" *ngIf="allDaysSelected"> (All days are selected)</span></label>
        <form [formGroup]="formDays">
          <!-- <label class="day-button" formArrayName="days"
            *ngFor="let day of formDays['controls']['days']['controls']; let i = index" (click)="onCheckAllSelectedDays()">
            <input type="checkbox" [formControlName]="i">
            <span *ngIf="daysEnabled[i]" i18n="@@dayKey">
              {{daysEnabled[i].name}} {daysEnabled[i].name, select, name {name}}
            </span>
          </label> -->
          <label class="day-button" formArrayName="days"
            *ngFor="let day of formDays['controls']['days']['controls']; let i = index" (click)="onCheckAllSelectedDays()">
            <input type="checkbox" [formControlName]="i">
            <ng-container *ngIf="daysEnabled[i]">
              <span *ngIf="i === 0" i18n="@@day-key-monday">
                Mn
              </span>
              <span *ngIf="i === 1" i18n="@@day-key-tuesday">
                Tu
              </span>
              <span *ngIf="i === 2" i18n="@@day-key-wednesday">
                We
              </span>
              <span *ngIf="i === 3" i18n="@@day-key-thursday">
                Th
              </span>
              <span *ngIf="i === 4" i18n="@@day-key-friday">
                Fr
              </span>
              <span *ngIf="i === 5" i18n="@@day-key-saturday">
                Sa
              </span>
              <span *ngIf="i === 6" i18n="@@day-key-sunday">
                Su
              </span>
            </ng-container>
          </label>
        </form>
      </div>
    </ng-container>

    <div class="two-fields">
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@settings-dateStart">Date of start</mat-placeholder>
        <input matInput type="date" id="startDay" name="startDay" formControlName="startDay" min="{{currentDate}}"
          max="{{maxDate}}" required (blur)="onCheckStartDay(periodFormGroup.value.startDay)"
          (keyup.enter)="onCheckStartDay(periodFormGroup.value.startDay)" i18n-matTooltip="@@hint-Start"
          matTooltip="Can't be set in the past" [matTooltipPosition]="'above'" />
      </mat-form-field>

      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@settings-timeStart">Time of Start</mat-placeholder>
        <input matInput type="time" id="startTime" name="startTime" formControlName="startTime" required
          (blur)="onCheckStartTime(periodFormGroup.value.startTime)" i18n-matTooltip="@@hint-Start"
          matTooltip="Can't be set in the past" [matTooltipPosition]="'above'" />
      </mat-form-field>
    </div>

    <div class="two-fields">
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@settings-dateFinish">Date of end</mat-placeholder>
        <input matInput type="date" id="finishDay" name="finishDay" formControlName="finishDay" min="{{nextDate}}"
          max="{{maxDate}}" required (focus)="updateNextDate()"
          (blur)="onCheckFinishDay(periodFormGroup.value.finishDay)"
          (keyup.enter)="onCheckFinishDay(periodFormGroup.value.finishDay)" i18n-matTooltip="@@hint-Finish"
          matTooltip="Can't be set before the start and more than one year" [matTooltipPosition]="'above'" />
      </mat-form-field>

      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@settings-timeFinish">Time of End</mat-placeholder>
        <input matInput type="time" id="finishTime" name="finishTime" formControlName="finishTime" required
          (blur)="onCheckFinishTime(periodFormGroup.value.finishTime)"
          (keyup.enter)="onCheckFinishTime(periodFormGroup.value.finishTime)" />
      </mat-form-field>
    </div>

    <p i18n="@@hint-start-research" *ngIf="isHintVisible" style="font-size: 12px;">Please, check the data in the form
      and don't forget to start your research!</p>
    <p i18n="@@hint-emptyForm" *ngIf="isWarningVisible" style="font-size: 12px;">The form has empty fields!</p>
    <div class="buttons-box two-buttons">
      <button [disabled]="isResearchActive" mat-raised-button color="accent" type="submit" (click)="onConfirm()"
        i18n="@@settings-confirm">
        Confirm
      </button>
      <button [disabled]="!researchResetEnabled" color="primary" mat-raised-button (click)="resetPeriodForm()"
        i18n="@@settings-reset">
        Reset
      </button>
    </div>

  </form>

</mat-card>