<!-- PARTICIPANTS -->
<section *ngIf="!isLoading" class="participants">
  <ng-container *ngIf="isColleaguesExist; else noColleagues">
    <div class="participants-content">
      <div class="participants-list">
        <h1 class="participants-list__title" i18n="@@ParticipantsPageTitle">Select participants</h1>
        <mat-accordion flex>
          <ng-container *ngIf="departments.length > 0">
            <mat-expansion-panel *ngFor="let department of localDepartments">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{department.name}}
                  <div class="selection-info">
                    <span *ngIf="department.colleagues.length !== 0">
                      {{department.selectedCount}}/{{department.colleagues.length}}
                      <span i18n="@@selection-info">selected</span>
                    </span>

                    <button
                        *ngIf="department.colleagues.length === 0"
                        class="dep-button select-dep-button noColleagues"
                        (click)="$event.stopPropagation()"
                        i18n="@@button-nothingToSelect">
                      Nothing to Select
                    </button>

                    <button
                        *ngIf="department.colleagues.length !== 0 && department.isAllSelected"
                        class="dep-button select-dep-button allSelected"
                        (click)="$event.stopPropagation(); onSelectAllParticipants(department)"
                        i18n="@@button-selected">
                      Selected
                    </button>

                    <button
                        *ngIf="department.colleagues.length !== 0 && !department.isAllSelected"
                        class="dep-button select-dep-button"
                        (click)="$event.stopPropagation(); onSelectAllParticipants(department)"
                        i18n="@@button-selectAll">
                      Select all
                    </button>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="colleagues">
                <ng-container *ngFor="let colleague of department.colleagues">
                  <app-package-participant
                    [colleagues]="colleagues"
                    [colleague]="colleague"
                    [department]="department"
                    [packageId]="packageId"
                    [evaluators]="selectedEvaluators"
                    (newColleague)="onUpdateParticipant($event)"
                    (newDepartment)="onUpdateDepartment($event)"
                  ></app-package-participant>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </ng-container>
          <ng-container *ngIf="localNonDepartment !== null && localNonDepartment.colleagues.length > 0">
            <mat-expansion-panel class="departments-style">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span i18n="@@nonDepartmentsColleagues">Non-department colleagues</span>
                  <div class="selection-info">
                    <span>{{localNonDepartment.selectedCount}}/{{localNonDepartment.colleagues.length}} <span i18n="@@selection-info">selected</span></span>
                    <button
                        *ngIf="localNonDepartment.colleagues.length !== 0 && localNonDepartment.isAllSelected"
                        class="dep-button select-dep-button allSelected"
                        (click)="$event.stopPropagation(); onSelectAllParticipants(localNonDepartment)"
                        i18n="@@button-selected">
                      Selected
                    </button>

                    <button
                        *ngIf="localNonDepartment.colleagues.length !== 0 && !localNonDepartment.isAllSelected"
                        class="dep-button select-dep-button"
                        (click)="$event.stopPropagation(); onSelectAllParticipants(localNonDepartment)"
                        i18n="@@button-selectAll">
                      Select all
                  </button>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <ng-container *ngFor="let colleague of localNonDepartment.colleagues">
                  <app-package-participant
                    [colleagues]="colleagues"
                    [colleague]="colleague"
                    [department]="localNonDepartment"
                    [packageId]="packageId"
                    [evaluators]="selectedEvaluators"
                    (newColleague)="onUpdateParticipant($event)"
                    (newDepartment)="onUpdateDepartment($event)"
                  ></app-package-participant>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </ng-container> <!-- Non department participants -->
        </mat-accordion> <!-- /mat-accordion -->
      </div> <!-- /.participants-list -->

      <div class="participants-tools">
        <div class="participants-tools__buttons">
          <button
            mat-raised-button
            color="accent"
            class="participants-tools__button participants-tools__button--reset"
            [disabled]="isButtonsDisabled"
            (click)="onResetSettings()"
            i18n="@@reset">
            Reset
          </button>

          <button
            mat-raised-button
            color="accent"
            class="participants-tools__button participants-tools__button--save"
            [disabled]="isButtonsDisabled"
            (click)="onSaveSettings()"
            i18n="@@saveButton">
            Save
          </button>
        </div> <!-- /.participants-tools__buttons-->
      </div> <!-- /.participants-tools -->
    </div> <!-- /.participants-content -->
    <div class="package-settings">
      <div class="package-submissions">
        <div class="package-submissions__item">
          <mat-checkbox [(ngModel)]="isMultipleSubmissionsAllowed" (change)="onChangeMultipleSubmission()" i18n="@@multiple-submissions">
            Allow multiple submissions
          </mat-checkbox>
        </div>
        <div class="package-submissions__item">
          <mat-form-field class="example-form-field" appearance="fill">
            <mat-label i18n="@@maximumNumberOfSubmissions">Maximum number of submissions</mat-label>
            <input matInput type="number" min="1" [(ngModel)]="maxSubmissions" (change)="onChangeMaxSubmissionNumber()" [disabled]="!isMultipleSubmissionsAllowed">
          </mat-form-field>
        </div>
      </div> <!-- /.package-submissions-->
      <div class="package-relative-question">
        <div class="package-relative-question__item">
          <mat-form-field class="example-form-field" appearance="fill">
            <mat-label i18n="@@employeesAssessmentQuestion">Question number to start the assessment of employees with</mat-label>
            <input matInput type="number" min="1" [(ngModel)]="relativeQuestion" (change)="onChangeRelativeQuestion()">
          </mat-form-field>
        </div>
      </div> <!-- /.package-relative-question -->
    </div> <!-- /.package-settings -->
  </ng-container> <!-- If colleagues list is not empty -->
</section>
<!-- PARTICIPANTS END -->

<!-- If a company have no colleague-->
<ng-template #noColleagues>
  <div class="error-container">
    <p class="error-container__title" i18n="@@noColleaguesToSelect">You have no colleagues to select</p>
  </div>
</ng-template>

<!-- While data is being loaded -->
<div *ngIf="isLoading" class="loading-container">
  <app-loading-spinner></app-loading-spinner>
</div>
