import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Colleague } from 'app/_models/colleague';
import { ExtendedColleague } from '../local-models';

@Component({
  selector: 'app-colleague-card',
  templateUrl: './colleague-card.component.html',
  styleUrls: ['./colleague-card.component.scss']
})
export class ColleagueCardComponent {

  @Input() isArchive: boolean;
  @Input() colleague: ExtendedColleague;
  @Output() onRestore = new EventEmitter<Colleague>();

  constructor(
  ) { }

  onRestoreColleague(colleague: Colleague, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.onRestore.emit(colleague);
  }
}
