import { ApiScoreDivision } from 'app/_api_models/api-score-division';
import { ApiCardOption } from 'app/_api_models/api-card-option';
import { Number, String, Array, Record, Partial, Boolean } from 'runtypes';

export const ApiQuestionnaireCard = Record({
  id: Number,
  questionText: String,
  cardType: String,
  questionnaire_id: Number,
  order_position: Number,
  is_required: Boolean,
  is_active: Boolean,
  divisions_color_scheme: String,
}).And(Partial({
  cardOptions: Array(ApiCardOption),
  scoreDivisions: Array(ApiScoreDivision)
}));
