import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { CardAction } from 'app/_models/card-action';
import { Package } from 'app/_models/package';
import { ScoreDivision } from 'app/_models/score-division';
import { Questionnaire } from 'app/_models/questionnaire';
import { PackageParameter } from 'app/_models/package-parameter';

@Component({
  selector: 'app-create-question',
  templateUrl: './create-question.component.html',
  styleUrls: [
    './create-question.component.scss',
    './../../package-details.component.scss'
  ]
})
export class PackageCreateQuestionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  errorList: string[] = [];
  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];

  cardOptions: CardOption[] = [];
  textInputOption: CardOption;
  divisionsLabelFields: ScoreDivision[] = [];

  isRequired: boolean = true;
  isActive: boolean = true;
  currentPackageId: number = 0;
  questionnaireId: number = 0;
  nextId: number;
  cardType: string = 'options';
  divisionsColorScheme: string = 'default';
  cardQuestionText: string = '';
  cardQuestionValid: boolean = false;
  isScoreType: boolean = false;
  tooltipText: string = `Введите данные в формате 'Параметр1 -> Параметр1 + N(значение для вычисления)'
    ('Параметр1 ->' задается в левом поле)`;
  tooltipGotoText: string = `Введите номер вопроса, к которому нужно перейти в формате '#N', где N - номер вопроса`;

  scoreSteps: number[] = Array.from(Array(8), (x , i) => i + 3);
  selectedScoreDivisions: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((routeParams: any) => {
        const packageId = routeParams['package_id'];
        if (packageId) {
          this.currentPackageId = packageId;
        }
      });

    this.getPackageInfo();
    this.setEmptyTextInput();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSaveDescription(newDescription: string): void {
    if (newDescription) {
      this.cardQuestionText = newDescription;
    }
    this.checkQuestionIsValid();
  }

  checkQuestionIsValid(): void {
    if (this.cardQuestionText && this.cardQuestionText.length > 4) {
      this.cardQuestionValid = true;
    } else {
      this.cardQuestionValid = false;
    }
  }

  dropCardOption(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.cardOptions, event.previousIndex, event.currentIndex);
    const movedCardOption = this.cardOptions[event.previousIndex];
    this.updateCardOptionPosition(movedCardOption, event.previousIndex + 1);
    this.renumerateCardOptions();
  }

  dropCardAction(event: CdkDragDrop<string[]>, cardActions: CardAction[]): void {
    moveItemInArray(cardActions, event.previousIndex, event.currentIndex);
    const movedCardActions = cardActions[event.previousIndex];
    this.updateCardActionPosition(movedCardActions, event.previousIndex + 1);
    this.renumerateCardActions(cardActions);
  }

  renumerateCardOptions(): void {
    let nextPosition = 1;
    for (const option of this.cardOptions) {
      option.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  renumerateCardActions(cardActions: CardAction[]): void {
    let nextPosition = 1;
    for (const action of cardActions) {
      action.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  updateCardOptionPosition(targetOption: CardOption, newOptionIndex: number): void {
    if (targetOption && newOptionIndex) {
      targetOption.orderPosition = newOptionIndex;
    }
  }

  updateCardActionPosition(targetAction: CardAction, newActionIndex: number): void {
    if (targetAction && newActionIndex) {
      targetAction.orderPosition = newActionIndex;
    }
  }

  getPackageInfo(): void {
    const packageId = this.currentPackageId;

    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
          if (packageInfo && packageInfo.questionnaire) {
            const questionnaire = packageInfo.questionnaire;
            this.questionnaireId = questionnaire.id;

            this.separateParameters(packageInfo.packageParameters);
            this.sharedPackageService.packageParameters$.next(packageInfo.packageParameters);

            if (this.questionnaireId) {
              this.getQuestionnaire(this.questionnaireId);
            }

          }
        },
        error => {
          console.log('Package info', error);
        });
    }
  }

  getQuestionnaire(questionnaireId: number): void {
    if (questionnaireId) {
      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((questionnaireInfo: Questionnaire) => {
          if (questionnaireInfo) {
            const cards = questionnaireInfo.questionnaireCards;
            // this.cards = cards;
            this.nextId = cards.length + 1;
          }
        },
        error => {
          console.log('Questionnaire error ', error);
        });
    }
  }

  separateParameters(parametersList: PackageParameter[]): void {
    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  addOption(optionsLength: number): void {
    const newOption: CardOption = {
      id: 0,
      orderPosition: optionsLength + 1,
      cardActions: [],
      text: '',
    };

    this.cardOptions.push(newOption);
  }

  deleteOption(optionIndex: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.cardOptions.splice(optionIndex, 1);
        this.renumerateCardOptions();
      }
    });
  }

  addOptionAction(activeOption: CardOption): void {
    const orderPosition = activeOption.cardActions.length + 1;
    const newAction: CardAction = {
      id: 0,
      text: '',
      parameter: '',
      orderPosition: orderPosition,
    };

    activeOption.cardActions.push(newAction);
  }

  deleteOptionAction(activeOption: CardOption, cardActionIndex: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        activeOption.cardActions.splice(cardActionIndex, 1);
      }
    });
  }

  setDivisionsLabelFields(): void {
    this.divisionsLabelFields = [];
    for (let num = 1; num <= this.selectedScoreDivisions; num++) {
      const newDivision: ScoreDivision = {
        id: 0,
        label: '',
        value: num,
      };
      this.divisionsLabelFields.push(newDivision);
    }
  }

  async saveQuestion(): Promise<any> {
    return new Promise((resolve, reject) => {
      const newCard: QuestionnaireCard = {
        id: 0,
        questionText: this.cardQuestionText,
        questionnaireId: this.questionnaireId,
        cardType: this.cardType,
        orderPosition: this.nextId,
        isRequired: this.isRequired,
        isActive: this.isActive,
        divisionsColorScheme: this.divisionsColorScheme,
        cardOptions: [],
        scoreDivisions: [],
      };

      const savePromise = this.saveCardFunction(newCard);
      savePromise.then((result) => {
        this.goBack();
        resolve(result);
      }).catch((error) => {
        reject(error);
      });
    });

  }

  async saveCardFunction(newCard: QuestionnaireCard): Promise<any> {
    let cardOptions: CardOption[] = [];
    if ((this.cardType === 'score') || (this.cardType === 'relative-score')) {
      cardOptions.push(this.textInputOption);
      newCard.scoreDivisions = this.divisionsLabelFields;
    } else if (this.cardType === 'text-input') {
      cardOptions.push(this.textInputOption);
    } else if (this.cardType === 'relative') {
      cardOptions.push(this.textInputOption);
    } else {
      cardOptions = this.cardOptions;
    }

    return new Promise((resolve, reject) => {
      this.questionnaireService.addQuestionCard(newCard)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((addedCard: QuestionnaireCard) => {
          const promisesList = [];
          const promisesOptions = cardOptions.map((option) => this.addCardOption(addedCard.id, option));
          for (const singlePromise of promisesOptions) {
            promisesList.push(singlePromise);
          }

          if ((this.cardType === 'score') || (this.cardType === 'relative-score')) {
            const promisesDivisions = this.divisionsLabelFields.map((division) => this.addDivisions(addedCard.id, division));
            for (const singlePromise of promisesDivisions) {
              promisesList.push(singlePromise);
            }
          }

          Promise.all(promisesList)
            .then((result: any) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }, error => {
          reject(error);
        });
    });
  }

  addDivisions(cardId: number, division: ScoreDivision): Promise<any> {
    const newDivision: ScoreDivision = {
      id: 0,
      label: division.label,
      value: division.value,
    };
    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardScoreDivision(cardId, newDivision)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDivision: ScoreDivision) => {
            resolve(resultDivision);
          },
          error => {
            console.log('Option error ', error);
            reject(error);
          });
    });
  }

  addCardOption(cardId: number, option: CardOption): Promise<any> {
    const newOption: CardOption = {
      id: 0,
      text: option.text,
      cardActions: option.cardActions,
      orderPosition: option.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardOption(cardId, newOption)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdOption: CardOption) => {
            const promises = newOption.cardActions.map((a) => this.addCardOptionAction(createdOption, a));
            resolve(Promise.all(promises));
          },
          error => {
            console.log('Option error ', error);
            reject(error);
          });
    });
  }

  addCardOptionAction(createdOption: CardOption, action: CardAction): Promise<any> {
    // console.log('add action ', action);
    const createdOptionId = createdOption.id;
    // const orderPosition = createdOption.cardActions.length + 1;
    const newAction: CardAction = {
      id: 0,
      text: action.text,
      parameter: action.parameter,
      orderPosition: action.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardAction(createdOptionId, newAction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdAction: CardAction) => {
          if (createdAction) {
            createdOption.cardActions.push(createdAction);
            resolve(createdOption.cardActions);
          } else {
            reject('no action');
          }
        },
        error => {
          console.log('Option error ', error);
          reject(error);
        });
    });
  }

  setOptionValue(event: any): void {
    // if (event.value === 'text-input' || event.value === 'score') {
    // }
    if ((event.value === 'score') || (event.value === 'relative-score')) {
      this.isScoreType = true;
    } else {
      this.isScoreType = false;
    }
  }

  setEmptyTextInput(): void {
    const cardActions: CardAction[] = [];
    const newTextInputOption: CardOption = {
      id: 0,
      text: 'INPUT',
      cardActions: cardActions,
      orderPosition: 0,
    };
    this.textInputOption = newTextInputOption;
  }

  setActionValue(event: any, cardAction: CardAction): void {
    // console.log('event ', event);
  }

  goBack(): void {
    this.sharedService.questionState$ = true;
    const packId = this.currentPackageId;
    this.router.navigateByUrl(`/questionnaires/${packId}/questions`);
  }

}
