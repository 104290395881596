import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Company } from 'app/_models/company';
import { User } from 'app/_models/user';
import { AuthenticationService } from 'app/_services/authentication.service';
import { CompanyService } from 'app/_services/company.service';
import { SharedService } from 'app/_services/shared.service';
import { UserService } from 'app/_services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogResultComponent } from 'app/_dialogs/dialog-result/dialog-result.component';
import { Router } from '@angular/router';
import { AdvancedAdminConfig } from 'app/_models/advanced-admin-config';

@Component({
  selector: 'app-admin-settings-page',
  templateUrl: './admin-settings-page.component.html',
  styleUrls: ['./admin-settings-page.component.scss']
})
export class AdminSettingsPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  currentUser: User;
  isAdmin: boolean = false;
  companiesListMode: boolean = false;
  companyLogoUrl: any;
  currentCompany: Company;
  adminCompaniesList: Company[] = [];
  templatesOrderRu: string;
  templatesOrderEn: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private companyService: CompanyService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          this.currentUser = resultUser;
          // this.checkUserLanguage(resultUser.currentLocale);

          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
            this.getTemplatesOrder();
          } else {
            this.router.navigate(['/home/']);
          }
          this.isAdmin = isAdmin;

          const activeCompanyId = resultUser.activeCompanyId;
          if (isAdmin) {
            this.getAdminCompanies(activeCompanyId);
          }

          if (activeCompanyId && activeCompanyId > 0) {
            this.sharedService.currentCompanyId.next(activeCompanyId);
            // this.hasActiveCompany = true;
          }

        },error => {
          this.sharedService.checkSignatureExpired(error);
        });
    }
  }

  getTemplatesOrder(): void {
    this.userService.getAdvancedAdminConfig(1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultConfig: AdvancedAdminConfig) => {
        this.templatesOrderRu = resultConfig.templatesOrderRu;
        this.templatesOrderEn = resultConfig.templatesOrderEn;
      }, error => console.log(error));
  }

  onUpdateTemplatesOrder(locale: string): void {
    const updatedFields = {
      templates_order_ru: this.templatesOrderRu,
      templates_order_en: this.templatesOrderEn,
    };

    this.userService.updateAdvancedAdminConfig(1, updatedFields)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultConfig: AdvancedAdminConfig) => {
        this.templatesOrderRu = resultConfig.templatesOrderRu;
        this.templatesOrderEn = resultConfig.templatesOrderEn;
      }, error => console.log(error));
  }

  showAllAdminCompanies(): void {
    if (this.companiesListMode) {
      this.companiesListMode = false;
    } else {
      this.companiesListMode = true;
    }
  }

  onChooseCompany(company: Company): void {
    console.log('Company ', company);
    if (company) {
      const newCompanyId = company.id;
      this.sharedService.currentCompanyId.next(newCompanyId);
      this.sharedService.onChangeCurrentCompany$.emit();
      this.setAdminActiveCompany(company);
    }
  }

  setAdminActiveCompany(newCompany: Company): void {
    const newCompanyId = newCompany.id;
    this.userService.setActiveCompany(newCompanyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        location.reload();
        // this.updateCurrentCompanyInfo(newCompany);
      }, error => {
        console.log('Error with active company ', error);
      });
  }

  // checkAdminCompanies(): void {
  //   this.companyService.getAdminCompanies()
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((adminList: Company[]) => {
  //       if (adminList) {
  //         this.showAdminCompanies(adminList);
  //       }
  //     }, error => {
  //       console.log(error);
  //     });
  // }

  // showAdminCompanies(adminCompanies: Company[]): void {
  //   const dialogRef = this.dialog.open(DialogResultComponent, {
  //     data: {
  //       dialogType: 'ChangeCompany',
  //       companyList: adminCompanies,
  //     },
  //   });

  //   dialogRef.afterClosed()
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((chosenCompanyId: number) => {
  //       if (chosenCompanyId && chosenCompanyId > 0) {
  //         const newCompany = adminCompanies.find(company => company.id === chosenCompanyId);
  //         if (newCompany) {
  //           const lastCompanyId = this.currentCompany.id;
  //           const newCompanyId = newCompany.id;
  //           if (newCompanyId !== lastCompanyId) {
  //             // this.setAdminActiveCompany(newCompany);
  //           }
  //           this.sharedService.currentCompanyId.next(newCompanyId);
  //           this.sharedService.onChangeCurrentCompany$.emit();
  //           // this.router.navigate(['/company']);
  //         }
  //       }
  //     },
  //       error => console.log('Error change company ', error));

  // }

  getAdminCompanies(activeId: number): void {
    this.companyService.getAdminCompanies()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((adminCompanies: Company[]) => {
        if (adminCompanies && adminCompanies.length > 0) {
          this.adminCompaniesList = adminCompanies;
          // console.log('Admin Companies ', adminCompanies);
          // this.sharedPackageService.onRefreshPackage$.emit();
          const findCompany = adminCompanies.find(company => company.id === activeId);
          if (findCompany) {
            this.updateCurrentCompanyInfo(findCompany);
          } else {
            this.updateCurrentCompanyInfo(adminCompanies[0]);
          }
        }
      }, error => {
        console.log(error);
      });
  }

  updateCurrentCompanyInfo(newCompany: Company): void {
    if (newCompany) {
      // const companyLogo = newCompany.logo;
      // if (companyLogo && companyLogo.url) {
      //   this.companyLogoUrl = this.attachmentsUrl + companyLogo.url;
      // } else {
      //   this.companyLogoUrl = null;
      // }

      const currentCompany: Company = {
        id: newCompany.id,
        uuid: newCompany.uuid,
        name: newCompany.name,
        slackIntegrationToken: newCompany.slackIntegrationToken,
        departments: [],
        ownerEmail: newCompany.ownerEmail,
        ownerId: newCompany.ownerId,
        colleagueSharedLink: newCompany.colleagueSharedLink,
      };

      this.currentCompany = currentCompany;
    }
  }

}
