import { Injectable } from '@angular/core';
import { User } from 'app/_models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedUserService {
  public currentUser$: BehaviorSubject<User>;

  constructor() {
    this.currentUser$ = new BehaviorSubject(null);
  }

  public clearSharedUserService(): void {
    this.currentUser$.next(null);
  }

  public updateUserInfo(user: User): void {
    this.currentUser$.next(user);
  }
}
