import { Number, String, Record, Null } from 'runtypes';

const CommonValue = Record({
  type: String,
  value: Number.Or(String).Or(Null),
});

export const ApiExperimentParameter = Record({
  id: Number,
  visible_name: String.Or(Null),
  formula_name: String,
  common_value: CommonValue,
  experiment_id: Number,
  package_parameter_id: Number,
});
