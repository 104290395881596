import { Number, String, Record, Boolean, Partial, Null } from 'runtypes';

export const ApiUser = Record({
  id: Number,
  uuid: String,
  name: String,
  last_name: String,
  active_company_id: Number.Or(Null),
  email: String,
  has_calculator: Boolean,
  show_notifications: Boolean,
  show_thumbnails: Boolean,
  current_locale: String,
}).And(Partial({
  activated: Boolean,
  admin: Boolean
}));
