import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { filter, takeUntil } from 'rxjs/operators';

import { ColleagueService } from 'app/_services/colleague.service';
import { SharedColleagueService } from 'app/_services/shared-colleague.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

import { ColleagueTable, ExtendedParameter } from '../local-models';
import { Package } from 'app/_models/package';
import { ParameterVisibility } from 'app/_models/parameter-visibility';

@Component({
  selector: 'app-colleague-info-page',
  templateUrl: './colleague-info-page.component.html',
  styleUrls: ['./colleague-info-page.component.scss']
})
export class ColleagueInfoPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  packages: Package[] = [];
  colleagueTables: ColleagueTable[] = [];
  changedColleagueTables: ColleagueTable[] = [];
  activeParameters: ExtendedParameter[] = [];
  changedParameters: ExtendedParameter[] = [];
  parameterVisibilities: ParameterVisibility[] = [];
  displayedColumns: string[] = ['parameterName', 'parameterValue'];

  isLoading: boolean = true;
  isViewMode: boolean = true;
  isEditMode: boolean = false;
  isPublicProfile: boolean = false;
  isEnabledTablesExist: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private colleagueService: ColleagueService,
    private sharedColleagueService: SharedColleagueService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.getData();
    this.checkProfilePublicity();
  }

  ngAfterContentChecked(): void {
    if (this.isViewMode) {
      this.scrollToTop();
    }

    this.setStylesToParameterAuthors();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getData() {
    combineLatest([
      this.sharedColleagueService.tables$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.packages$.pipe(filter(result => result !== null && result.length > 0)),
      this.sharedCompanyDataService.extendedParameters$.pipe(filter(result => result !== null && result.length > 0)),
      this.sharedCompanyDataService.parameterVisibilities$.pipe(filter(result => result !== null && result.length > 0))
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.setColleagueTables(result[0]);
        this.packages = result[1];
        this.activeParameters = result[2];
        this.parameterVisibilities = result[3];
      });
  }

  setColleagueTables(tables: ColleagueTable[]): void {
    const enabledTables = tables.filter(table => table.isEnabled);
    if (enabledTables.length > 0) {
      this.isEnabledTablesExist = true;
    }
    this.colleagueTables = tables;
    this.colleagueTables.sort(function(a, b){return a.orderPosition-b.orderPosition});
    this.isLoading = false;
    this.setDefaultOrderPosition();
  }

  isPackageHasParameters(packageId: number): boolean {
    if (this.activeParameters.find(param => param.packageId === packageId)) {
      return true;
    } else {
      return false;
    }
  }

  updateChangedParameters(parameter: ExtendedParameter): void {
    const isParameterExists = this.changedParameters.find(param => param.id === parameter.id);

    if (!isParameterExists) {
      this.changedParameters.push(parameter);
    } else {
      this.changedParameters.find(param => param.id === parameter.id).onColleagueCard = parameter.onColleagueCard;
      this.changedParameters.find(param => param.id === parameter.id).onColleaguePage = parameter.onColleaguePage;
    }
  }

  changeTableVisibility(table: ColleagueTable): void {
    table.isVisible = !table.isVisible;
    this.updateChangedTables(table);
  }

  onTableUp(table: ColleagueTable): void {
    if (table.orderPosition === 1) {
      return;
    }

    const aboveTable = this.colleagueTables.find(param => param.orderPosition === table.orderPosition - 1);
    aboveTable.orderPosition++;

    const currentTable = table;
    currentTable.orderPosition--;

    this.updateChangedTables(aboveTable);
    this.updateChangedTables(currentTable);

    this.sortTables();
  }

  onTableDown(table: ColleagueTable): void {
    const maxPosition = this.colleagueTables.length;

    if (table.orderPosition === maxPosition) {
      return;
    }

    const belowTable = this.colleagueTables.find(param => param.orderPosition === table.orderPosition + 1);
    belowTable.orderPosition--;

    const currentTable = table;
    currentTable.orderPosition++;

    this.updateChangedTables(belowTable);
    this.updateChangedTables(currentTable);

    this.sortTables();
  }

  updateTables(): void {
    this.changedColleagueTables.forEach((table) => {
      this.colleagueService.updateTableConfig(table)
        .subscribe(() => {
          this.updatePackagesOrder();
      });
    })

    if (this.changedParameters.length > 0) {
      this.sharedCompanyDataService.addParameterVisibilities(this.parameterVisibilities);
      this.changedParameters.forEach((parameter) => {
        this.colleagueService.updateParameter(parameter.parameterVisibilitiesId, parameter.onColleagueCard, parameter.onColleaguePage)
          .subscribe(() => {})
          ,error => console.log(error);
      })
    }

    this.changedColleagueTables = [];
  }

  updateChangedTables(table: ColleagueTable): void {
    const newUpdatedTables = this.changedColleagueTables.filter(param => param.tableConfigId !== table.tableConfigId);
    newUpdatedTables.push(table);
    this.changedColleagueTables = newUpdatedTables;
  }

  setDefaultOrderPosition(): void {
    const zeroOrderTables = this.colleagueTables.filter(table => table.orderPosition === 0);

    if (zeroOrderTables.length > 0) {
      let lastOrderPosition = this.getLastOrderPosition() + 1;

      zeroOrderTables.forEach((table) => {
        table.orderPosition = lastOrderPosition;
        lastOrderPosition++;
        this.changedColleagueTables.push(table);
      })

      this.updateTables();
    }
  }

  getLastOrderPosition(): number {
    let lastOrderPosition = 0;

    this.colleagueTables.forEach((table) => {
      if (table.orderPosition > lastOrderPosition) {
        lastOrderPosition = table.orderPosition;
      }
    })

    return lastOrderPosition;
  }

  updatePackagesOrder():void {
    this.packages.forEach((pack) => {
      const colleagueTable = this.colleagueTables.find(table => table.packageId === pack.id);
      pack.colleagueTableConfig.orderPosition = colleagueTable.orderPosition;
      pack.colleagueTableConfig.isVisible = colleagueTable.isVisible;
    })

    this.sharedCompanyDataService .updatePackages(this.packages);
  }

  sortTables(): void {
    this.colleagueTables.sort(function(a, b){return a.orderPosition-b.orderPosition});
  }

  onSelectParameter(parameter: ExtendedParameter): void {
    this.parameterVisibilities.find(param => param.id === parameter.parameterVisibilitiesId).onColleagueCard = parameter.onColleagueCard;
    this.parameterVisibilities.find(param => param.id === parameter.parameterVisibilitiesId).onColleaguePage = parameter.onColleaguePage;
    this.updateChangedParameters(parameter);
  }

  onSelectAllParameters(packageId: number, parameterType: string): void {
    this.activeParameters.filter(param => param.packageId === packageId).forEach((parameter) => {
      if (parameterType === 'card') {
        this.parameterVisibilities.find(param => param.id === parameter.parameterVisibilitiesId).onColleagueCard = !parameter.onColleagueCard;
        parameter.onColleagueCard = !parameter.onColleagueCard;
      } else if (parameterType === 'page') {
        this.parameterVisibilities.find(param => param.id === parameter.parameterVisibilitiesId).onColleaguePage = !parameter.onColleaguePage;
        parameter.onColleaguePage = !parameter.onColleaguePage;
      }

      this.updateChangedParameters(parameter);
    })
  }

  checkProfilePublicity(): void {
    const colleaguePublicLink = this.route.parent.snapshot.params.public_link;
    if (colleaguePublicLink !== undefined) {
      this.isPublicProfile = true;
    }
  }

  onSaveClick(): void {
    this.updateTables();
    this.isViewMode = true;
    this.isEditMode = false;
  }

  onEditClick(): void {
    this.changedParameters = [];
    this.isEditMode = true;
    this.isViewMode = false;
  }

  onBackClick():void {
    this.isViewMode = true;
    this.isEditMode = false;
  }

  onRedirectToQuestionnaire(questionnaireId: number): void {
    this.router.navigateByUrl(`/questionnaires/${questionnaireId}/parameters`);
  }

  setStylesToParameterAuthors() {
    var parameterAuthors = document.querySelectorAll('.parameter-author');
    Array.from(parameterAuthors).forEach((element) => {
      element['style'].fontFamily = 'raleway-semibold';
    })
  }

  scrollToTop(): void {
    let top = document.getElementById('top');
    if (top) {
      top.scrollIntoView();
      setTimeout(() => {
        top.remove();
      }, 100);
    }
  }
}
