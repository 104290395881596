import { CardOption } from 'app/_models/card-option';
import { ApiCardOption } from 'app/_api_models/api-card-option';
import { CardAction } from 'app/_models/card-action';
import { parseApiCardAction } from 'app/_api_validation/parse-api-card-action';
import { environment } from '../../environments/environment';

export function parseApiCardOption(apiResponse: any, nestedModels: boolean, included: any): CardOption {
  let cardOptionObject;
  if (environment.production) {
    cardOptionObject = apiResponse;
  } else {
    cardOptionObject = ApiCardOption.check(apiResponse);
  }

  const cardActions: CardAction[] = [];
  if (nestedModels && included) {
    const filterIncluded = included.filter(item => item.type === 'card_action');
    const apiCardActions = filterIncluded.filter(item => item.attributes.card_option_id === cardOptionObject.id);
    if (apiCardActions) {
      for (const singleAction of apiCardActions) {
        const actionObject = parseApiCardAction(singleAction.attributes);
        cardActions.push(actionObject);
      }
    }
  }

  const newCardOption: CardOption = {
    id: cardOptionObject.id,
    text: cardOptionObject.text,
    orderPosition: cardOptionObject.order_position,
    cardActions: cardActions,
  };

  return newCardOption;
}
