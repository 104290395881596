import { Component, Input, OnInit } from '@angular/core';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';
import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ParameterData } from 'app/_models/overmind_ds/parameter-data';
import { ParameterDataService } from 'app/_services/overmind_ds/parameter-data.service';

@Component({
  selector: 'app-create-parameter-data',
  templateUrl: './create-parameter-data.component.html',
  styleUrls: ['./create-parameter-data.component.scss']
})
export class CreateParameterDataComponent implements OnInit {
  @Input('parameter') parameter: ExtendedParameter = null;
  @Input('authorUuid') authorUuid: string = '';
  @Input('sourceUuid') sourceUuid: string = '';
  @Input('entityUuid') entityUuid: string = '';

  public parameterValue: string = '';
  public parameterDescription: string = '';

  constructor(
    private parameterDataService: ParameterDataService,
  ) { }

  ngOnInit(): void {
  }

  createParameterData(): void {
    const newParameterData: ParameterData = {
      id: 0,
      parameterUuid: this.parameter.uuid,
      authorUuid: this.authorUuid,
      entityUuid: this.entityUuid,
      sourceUuid: this.sourceUuid,
      value: this.parameterValue,
      data: this.parameterDescription
    }

    this.parameterDataService.createNewParameterData(newParameterData)
      .subscribe((parameterData: ParameterData) => {
        this.parameter.parameterData.push(parameterData);
      });
  }
}
