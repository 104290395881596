import { ScoreDivision } from 'app/_models/score-division';
import { ApiScoreDivision } from 'app/_api_models/api-score-division';
import { environment } from '../../environments/environment';

export function parseApiScoreDivision(apiResponse: any): ScoreDivision {
  let scoreObject;
  if (environment.production) {
    scoreObject = apiResponse;
  } else {
    scoreObject = ApiScoreDivision.check(apiResponse);
  }

  const newScoreDivision: ScoreDivision = {
    id: scoreObject.id,
    label: scoreObject.label,
    value: scoreObject.value
  };

  return newScoreDivision;
}
