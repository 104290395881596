import { DemoParameter } from 'app/_models/demo-parameter';

export function parseApiDemoView(apiResponse: any): DemoParameter[] {
  // const parameterObject = ApiExperimentParameter.check(apiResponse);
  const newDemoParameters: DemoParameter[] = [];

  for (const singleApiParameter of apiResponse) {
    const demoParameter: DemoParameter = {
      packageParameterId: singleApiParameter.package_parameter_id,
      visibleName: singleApiParameter.visible_name,
      formulaName: singleApiParameter.formula_name,
      colleagueId: singleApiParameter.colleague_id,
      existingId: singleApiParameter.existing_id,
      experimentAnswerId: singleApiParameter.experiment_answer_id,
      value: singleApiParameter.value,
      textValue: singleApiParameter.text_value,
      parameterType: singleApiParameter.parameter_type,
      relativeColleagueId: singleApiParameter.relative_colleague_id
    };

    newDemoParameters.push(demoParameter);
  }

  return newDemoParameters;
}
