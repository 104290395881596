<div *ngIf="hasDashboardConfig && isWidgets">
  <div class="buttons-up-style">
    <ng-container *ngIf="isRussianLocale">
      <button (click)="onHowToDialog()" class="how-to-style">
        <div>
          <mat-icon style="font-size: 18px;">help_outline</mat-icon>
        </div>
        <div>Как этим пользоваться?</div>
      </button>
    </ng-container>
  </div>

  <div *ngFor="let widget of dashboardConfigWidgets">
    <div *ngIf="widget.widgetName === 'card-text-widget'">
      <app-text-card-widget
        [userView]="true"
        [testData]="isTestData"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-text-card-widget>
    </div>

    <div *ngIf="widget.widgetName === 'heatmap-individual-widget'">
      <app-heatmap-individual-widget
        [userView]="true" 
        [testData]="isTestData"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-heatmap-individual-widget>
    </div>

    <div *ngIf="widget.widgetName === 'line-common-widget'">
      <app-line-common-widget
        [userView]="true"
        [testData]="isTestData"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-line-common-widget>
    </div>

    <div *ngIf="widget.widgetName === 'individual-chart-widget'">
      <app-individual-chart-widget
        [userView]="true"
        [testData]="isTestData"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-individual-chart-widget>
    </div>

    <div *ngIf="widget.widgetName === 'common-text-card-widget'">
      <app-common-text-card-widget 
        [userView]="true" 
        [testData]="isTestData" 
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)" 
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)" 
        (onWidgetDown)="onWidgetDown(widget)">
      </app-common-text-card-widget>
    </div>

    <div *ngIf="widget.widgetName === 'brief-statistics-widget'">
      <app-passage-brief-statistics
        [userView]="true"
        [testData]="isTestData"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-passage-brief-statistics>
    </div>
    <div *ngIf="widget.widgetName === 'separator-widget'">
      <app-separator-widget
        [userView]="true"
        [testData]="isTestData"
        [widgetTitle]="widget.widgetTitle"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-separator-widget>
    </div>
    <div *ngIf="widget.widgetName === 'description-card-widget'">
      <app-description-card-widget
        [userView]="true"
        [testData]="isTestData"
        [widgetTitle]="widget.widgetTitle"
        [isPublicLink]="false"
        [sharedDashboardMode]="false"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-description-card-widget>
    </div>
    <div *ngIf="widget.widgetName === 'individual-parameter-widget'">
      <app-individual-parameter-widget
        [userView]="true"
        [testData]="isTestData"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-individual-parameter-widget>
    </div>
  </div>
</div>

<ng-container *ngIf="isBootstrapped && !isWidgets">
  <div class="warning-message">
    <h1 i18n="@@warningMessage-NoDashboard">You have no widgets for this dashboard</h1>
    <button mat-raised-button color="accent" (click)="onAddWidgets()" i18n="@@addWidgets">Add widgets</button>
  </div>
</ng-container>

<ng-container *ngIf="!isBootstrapped">
  <div class="loading-container">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
