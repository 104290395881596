import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { SharedModule } from './_shared/modules/shared.module';
import { DashboardsModule } from './_shared/modules/dashboards.module';
import { MaterialModule } from './_shared/modules/material.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { OverlayModule } from '@angular/cdk/overlay';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { PipesModule } from './_pipes/pipes.module';
import { AuthGuard } from 'app/_guards/auth.guard';
import { LoginGuard } from 'app/_guards/login.guard';
import { AuthenticationService } from 'app/_services/authentication.service';
import { MembershipService } from 'app/_services/membership.service';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedService } from 'app/_services/shared.service';
import { UserService } from 'app/_services/user.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { TokenInterceptorService } from 'app/_services/token-interceptor.service';

import { MakePublicPackageHelper } from 'app/_helpers/make-public-package-helper';
import { CreateFromStandardHelper } from 'app/_helpers/get-from-standard-helper';
import { UpdateLocalCardHelper } from 'app/_helpers/update-local-card-helper';
import { PackageDescriptionComponent } from 'app/_shared/package-description';
import { CopyLocalCardHelper } from 'app/_helpers/copy-local-card';
import { CopyLocalExperimentHelper } from 'app/_helpers/copy-local-experiment';
import { SortQuestionnaireHelper } from 'app/_helpers/sort-questionnaire-helper';
import { LocalDashboardHelper } from 'app/_dashboards/single_widgets/_helpers/local-dashboard-helper';
import { SharedDashboardHelper } from 'app/_dashboards/shared_widgets/_helpers/shared-dashboard-helper';

import { LoginComponent } from 'app/authorization/login/';
import { SignUpComponent } from 'app/authorization/sign-up/';
import { PasswordResetComponent } from 'app/authorization/password-reset/';

import { DialogResultComponent } from 'app/_dialogs/dialog-result';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { DialogAddComponent } from 'app/_dialogs/dialog-add';
import { DialogHowToComponent } from './_dialogs/dialog-how-to/dialog-how-to.component';
import { DialogSwitchCardTypeComponent } from './_dialogs/dialog-switch-card-type';
import { DialogRenameComponent } from 'app/_dialogs/dialog-rename';

import { AppComponent } from 'app/app.component';
import { ConfirmationsComponent } from 'app/confirmations/';
import { SnackBarComponent } from 'app/snack-bar/';
import { PackagesComponent } from 'app/packages/';
import { PackagesMyQuestionnairesComponent } from 'app/packages/my-questionnaires/';
import { PackagesStandardSetsComponent } from 'app/packages/standard-sets/';
import { PackagesCreatePackageComponent } from 'app/packages/packages-create-package/';
import { UserSettingsComponent } from 'app/user-settings';
import { SendFeedbackComponent } from 'app/send-feedback/';
import { PackagePermissionsComponent } from 'app/package-permissions/';
import { HomePageComponent } from 'app/home-page/';
import { PublicPackagesComponent } from 'app/public-packages/';
import { HelpPageComponent } from 'app/help-page/';
import { CompanyPageComponent } from 'app/company-page/';
import { CompanyCreateDepartmentComponent } from 'app/company-page/company-create-department/';
import { CompanyCreateCompanyComponent } from 'app/company-page/company-create-company/';

import { ColleaguesEditColleagueComponent } from 'app/colleague-profile/edit-colleague';
import { ColleaguesCreateColleagueComponent } from 'app/colleagues-page/create-colleague/';
import { DialogUploadFileComponent } from 'app/colleagues-page/dialog-upload-file/';
import { ColleagueInfoPageComponent } from 'app/colleague-profile/colleague-info-page';

import { PackageDetailsComponent } from 'app/package-details/';
import { PackageResearchesComponent } from 'app/package-details/researches/';
import { PackageQuestionnaireComponent } from 'app/package-details/questionnaire/';

import { PackageCreateQuestionComponent } from 'app/package-details/questionnaire/create-question/';
import { PackageEditQuestionComponent } from 'app/package-details/questionnaire/edit-question/';
import { EditOptionsQuestionComponent } from 'app/package-details/questionnaire/edit-question/edit-options-question/';
import { EditScoreQuestionComponent } from 'app/package-details/questionnaire/edit-question/edit-score-question/';
import { EditTextInputQuestionComponent } from 'app/package-details/questionnaire/edit-question/edit-text-input-question/';
import { EditCheckboxesQuestionComponent } from 'app/package-details/questionnaire/edit-question/edit-checkboxes-question/';
import { EditInfoCardComponent } from './package-details/questionnaire/edit-question/edit-info-card/';
import { PackageHintsPageComponent } from 'app/package-details/questionnaire/hints-page';
import { PackageCreatePQActionComponent } from 'app/package-details/questionnaire/create-pq-action/';
import { PackageEditPQActionComponent } from 'app/package-details/questionnaire/edit-pq-action/';

import { PackageParametersComponent } from 'app/package-details/parameters/';
import { PackageStatisticsComponent } from 'app/package-details/statistics/';
import { PackageParticipantsComponent } from 'app/package-details/participants/';
import { PackageSettingsComponent } from 'app/package-details/settings/';

import { QuestionnaireColleagueComponent } from 'app/questionnaire-colleague/';
import { QuestionnaireUserViewComponent } from 'app/package-details/user-view/';
import { ExperimentDetailsComponent } from 'app/experiment-details/';
import { RelativeExperimentDetailsComponent } from './relative-experiment-details/';

import { ColleaguesPageComponent } from 'app/colleagues-page';
import { SnackbarErrorInfoComponent } from 'app/colleagues-page/snackbar-error-info';
import { ColleaguesArchiveComponent } from './colleagues-page/colleagues-archive/';

import { StardardSetDetailsComponent } from 'app/stardard-set-details/';
import { StandardSetDescriptionComponent } from 'app/stardard-set-details/description/';
import { StandardSetParametersComponent } from 'app/stardard-set-details/parameters/';
import { StandardSetQuestionsComponent } from 'app/stardard-set-details/questions/';
import { StandardSetSettingsComponent } from 'app/stardard-set-details/settings/';
import { QuickstartComponent } from 'app/quickstart/';
import { NotFoundComponent } from 'app/not-found/';
import { PeriodFormComponent } from './package-details/settings/period-form/';

import { MaterialExcelTableComponent } from 'app/_tables/material-excel-table';
import { SingleCardViewComponent } from 'app/package-details/questionnaire/single-card-view/';
import { SetSingleCardViewComponent } from 'app/stardard-set-details/questions/set-single-card-view/';
import { QuestionnaireCardDescriptionComponent } from 'app/_shared/questionnaire-card-description/';
import { PopoverComponent } from 'app/_shared/popover/popover.component';
import { ExcelViewComponent } from './package-details/excel-view/';
import { ResizeColumnDirective } from 'app/_directives/resize-column.directive';
import { ResizeWidgetDirective } from 'app/_directives/resize-widget.directive';

import { PackageConfigComponent } from 'app/package-config/package-config.component';
import { DashboardEditorComponent } from 'app/_dashboards/dashboard-editor/';
import { TextCardWidgetComponent } from 'app/_dashboards/single_widgets/text-card-widget';
import { HeatmapIndividualWidgetComponent } from './_dashboards/single_widgets/heatmap-individual-widget';
import { LineCommonWidgetComponent } from './_dashboards/single_widgets/line-common-widget';
import { IndividualChartWidgetComponent } from './_dashboards/single_widgets/individual-chart-widget';
import { PassageBriefStatisticsComponent } from './_dashboards/single_widgets/passage-brief-statistics';
import { DescriptionCardWidgetComponent } from './_dashboards/single_widgets/description-card-widget';
import { IndividualParameterWidgetComponent } from './_dashboards/single_widgets/individual-parameter-widget';
import { DashboardUserViewComponent } from './_dashboards/dashboard-user-view/';
import { SeparatorWidgetComponent } from './_dashboards/single_widgets/separator-widget';
import { DashboardsListComponent } from './dashboards-list/dashboards-list.component';
import { CreateNewDashboardComponent } from './dashboards-list/create-new-dashboard';
import { SharedDashboardUserViewComponent } from './_dashboards/shared-dashboard-user-view/';
import { SharedDashboardEditorComponent } from './_dashboards/shared-dashboard-editor/';
import { DashboardManageQuestionnairesComponent } from './dashboards-list/dashboard-manage-questionnaires/';
import { DashboardSettingsComponent } from './dashboards-list/dashboard-settings/';
import { DashboardColleagueComponent } from './dashboard-colleague/';
import { PublicAddColleagueComponent } from './public-add-colleague/';
import { SharedHeatmapWidgetComponent } from './_dashboards/shared_widgets/shared-heatmap-widget/';
import { SharedTextCardWidgetComponent } from './_dashboards/shared_widgets/shared-text-card-widget/';
import { CommonTextCardWidgetComponent } from './_dashboards/single_widgets/common-text-card-widget/';
import { SharedBarWidgetComponent } from './_dashboards/shared_widgets/shared-bar-widget/';
import { PythonCalculationHelperComponent } from './python-calculation-helper/';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AdminSettingsPageComponent } from './admin-settings-page/';
import { TypeofPipe } from './package-details/researches/pipe-helper';
import { ColleagueProfileComponent } from './colleague-profile/colleague-profile.component';
import { ColleagueNotesComponent } from './colleague-profile/colleague-notes/colleague-notes.component';
import { ColleaguesComponent } from './colleagues-page/colleagues/colleagues.component';
import { ColleagueCardComponent } from './colleagues-page/colleague-card/colleague-card.component';
import { ColleaguesDepartmentsComponent } from './colleagues-page/colleagues-departments/colleagues-departments.component';
import { LoadingSpinnerComponent } from './_shared/loading-spinner/loading-spinner.component';
import { DialogNotesComponent } from './_dialogs/dialog-notes/dialog-notes.component';
import { PackageParticipantComponent } from './package-details/participants/package-participant/package-participant.component';
import { ColleagueParametersComponent } from './colleague-profile/colleague-parameters/colleague-parameters.component';
import { ParameterComponent } from './overmindds_api/parameter/parameter.component';
import { ParameterDataComponent } from './overmindds_api/parameter-data/parameter-data.component';
import { CreateParameterComponent } from './overmindds_api/parameter/create-parameter/create-parameter.component';
import { CreateParameterDataComponent } from './overmindds_api/parameter-data/create-parameter-data/create-parameter-data.component';
import { ExtendedParameterComponent } from './overmindds_api/extended-parameter/extended-parameter.component'

registerLocaleData(localeRu, 'ru');

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    CdkTableModule,
    SharedModule,
    MaterialModule,
    DashboardsModule,
    DragDropModule,
    QuillModule,
    QuillModule.forRoot(),
    MonacoEditorModule.forRoot(),
    PipesModule,
    OverlayModule,
    NgxPaginationModule,
    NgxJsonViewerModule
  ],
  declarations: [
    AppComponent,
    SnackBarComponent,
    DialogResultComponent,
    PackagesComponent,
    PackagesMyQuestionnairesComponent,
    PackagesStandardSetsComponent,
    PackagesCreatePackageComponent,
    LoginComponent,
    ConfirmationsComponent,
    UserSettingsComponent,
    SendFeedbackComponent,
    PackagePermissionsComponent,
    HomePageComponent,
    PublicPackagesComponent,
    HelpPageComponent,
    CustomAlertDialogComponent,
    CompanyPageComponent,
    CompanyCreateDepartmentComponent,
    CompanyCreateCompanyComponent,
    PackageDetailsComponent,
    PackageResearchesComponent,
    PackageQuestionnaireComponent,
    PackageCreateQuestionComponent,
    PackageEditQuestionComponent,
    PackageCreatePQActionComponent,
    PackageEditPQActionComponent,
    PackageParametersComponent,
    PackageStatisticsComponent,
    PackageSettingsComponent,
    QuestionnaireColleagueComponent,
    QuestionnaireUserViewComponent,
    ExperimentDetailsComponent,
    DialogAddComponent,
    ColleaguesPageComponent,
    ColleaguesEditColleagueComponent,
    ColleaguesCreateColleagueComponent,
    DialogUploadFileComponent,
    DialogRenameComponent,
    StardardSetDetailsComponent,
    StandardSetDescriptionComponent,
    StandardSetParametersComponent,
    StandardSetQuestionsComponent,
    StandardSetSettingsComponent,
    PackageParticipantsComponent,
    QuickstartComponent,
    NotFoundComponent,
    SignUpComponent,
    PasswordResetComponent,
    DialogSwitchCardTypeComponent,
    EditOptionsQuestionComponent,
    EditScoreQuestionComponent,
    EditTextInputQuestionComponent,
    EditCheckboxesQuestionComponent,
    PackageHintsPageComponent,
    PackageDescriptionComponent,
    PeriodFormComponent,
    SnackbarErrorInfoComponent,
    ColleaguesArchiveComponent,
    SingleCardViewComponent,
    SetSingleCardViewComponent,
    QuestionnaireCardDescriptionComponent,
    PopoverComponent,
    ExcelViewComponent,
    DialogHowToComponent,
    MaterialExcelTableComponent,
    ResizeColumnDirective,
    ResizeWidgetDirective,
    PackageConfigComponent,
    DashboardEditorComponent,
    TextCardWidgetComponent,
    HeatmapIndividualWidgetComponent,
    LineCommonWidgetComponent,
    IndividualChartWidgetComponent,
    PassageBriefStatisticsComponent,
    DashboardUserViewComponent,
    SeparatorWidgetComponent,
    DashboardsListComponent,
    CreateNewDashboardComponent,
    SharedDashboardUserViewComponent,
    SharedDashboardEditorComponent,
    DashboardManageQuestionnairesComponent,
    DashboardSettingsComponent,
    DashboardColleagueComponent,
    DescriptionCardWidgetComponent,
    IndividualParameterWidgetComponent,
    EditInfoCardComponent,
    PublicAddColleagueComponent,
    SharedHeatmapWidgetComponent,
    SharedTextCardWidgetComponent,
    SharedBarWidgetComponent,
    RelativeExperimentDetailsComponent,
    PythonCalculationHelperComponent,
    AdminSettingsPageComponent,
    TypeofPipe,
    CommonTextCardWidgetComponent,
    ColleagueInfoPageComponent,
    ColleagueProfileComponent,
    ColleagueNotesComponent,
    ColleaguesComponent,
    ColleagueCardComponent,
    ColleaguesDepartmentsComponent,
    LoadingSpinnerComponent,
    DialogNotesComponent,
    PackageParticipantComponent,
    ColleagueParametersComponent,
    ParameterComponent,
    ParameterDataComponent,
    CreateParameterComponent,
    CreateParameterDataComponent,
    ExtendedParameterComponent,
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    AuthenticationService,
    MembershipService,
    Title,
    SharedService,
    PackageService,
    DashboardService,
    UserService,
    SharedPackageService,
    QuestionnaireService,
    MakePublicPackageHelper,
    CreateFromStandardHelper,
    UpdateLocalCardHelper,
    CopyLocalCardHelper,
    CopyLocalExperimentHelper,
    SortQuestionnaireHelper,
    SnackbarErrorInfoComponent,
    LocalDashboardHelper,
    SharedDashboardHelper,
    {
        provide: LOCALE_ID,
        useValue: 'ru'
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptorService,
        multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  entryComponents: [
    DialogResultComponent,
    SnackBarComponent,
    CustomAlertDialogComponent,
    DialogAddComponent,
    DialogUploadFileComponent,
    DialogRenameComponent,
    DialogSwitchCardTypeComponent,
    SnackbarErrorInfoComponent,
    PopoverComponent,
    DialogHowToComponent,
  ],
  // bootstrap: [ AppComponent, [SharedService] ]
  bootstrap: [ AppComponent ]

})

export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}
