import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { ScoreDivision } from 'app/_models/score-division';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class UpdateLocalCardHelper {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private questionnaireService: QuestionnaireService,
  ) { }

  async updateActiveCard(activeCard: QuestionnaireCard, newCard: QuestionnaireCard): Promise<any> {
    return new Promise((resolve, reject) => {
      const cardId = activeCard.id;
      const promisesList = [];

      const currentDivisions = activeCard.scoreDivisions;
      const newDivisions = newCard.scoreDivisions;
      const scorePromise = this.changeScoreDivisions(cardId, newDivisions, currentDivisions);
      promisesList.push(scorePromise);

      const currentOptions = activeCard.cardOptions;
      const newOptions = newCard.cardOptions;
      const optionsPromise = this.changeCardOptions(cardId, newOptions, currentOptions);
      promisesList.push(optionsPromise);

      Promise.all(promisesList)
        .then((result: any) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async changeScoreDivisions(cardId: number, newDivisions: ScoreDivision[], currentDivisions: ScoreDivision[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];

      for (const newDivision of newDivisions) {
        const changedDivision = currentDivisions.find(oldDivision => oldDivision.id === newDivision.id);
        if (changedDivision) {
          const scorePromise = this.updateScoreDivision(newDivision);
          promisesList.push(scorePromise);
        } else {
          const scorePromise = this.addScoreDivision(cardId, newDivision);
          promisesList.push(scorePromise);
        }
      }

      const promisesRemovedList = [];
      for (const oldDivision of currentDivisions) {

        const existingDivision = newDivisions.find(newDivision => newDivision.id === oldDivision.id);
        if (!existingDivision) {
          const scorePromise = this.removeScoreDivision(oldDivision.id);
          promisesRemovedList.push(scorePromise);
        }
      }

      Promise.all(promisesList)
        .then((resultDivisions: ScoreDivision[]) => {
          resolve(resultDivisions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateScoreDivision(updatedDivision: ScoreDivision): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.updateCardScoreDivision(updatedDivision.id, updatedDivision)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((responseDivision: ScoreDivision) => {
          resolve(responseDivision);
        },
          error => {
            reject(error);
          });
    });
  }

  async addScoreDivision(cardId: number, newDivision: ScoreDivision): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardScoreDivision(cardId, newDivision)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((responseDivision: ScoreDivision) => {
          resolve(responseDivision);
        },
          error => {
            reject(error);
          });
    });
  }

  async removeScoreDivision(divisionId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.removeCardScoreDivision(divisionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((responseDivision: ScoreDivision) => {
          resolve(responseDivision);
        },
          error => {
            reject(error);
          });
    });
  }

  async changeCardOptions(cardId: number, newOptions: CardOption[], currentOptions: CardOption[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];

      for (const newOption of newOptions) {
        const changedOption = currentOptions.find(oldOption => oldOption.id === newOption.id);
        if (changedOption) {
          const optionPromise = this.updateCardOption(newOption, changedOption);
          promisesList.push(optionPromise);
        } else {
          const optionPromise = this.addCardOption(cardId, newOption);
          promisesList.push(optionPromise);
        }
      }

      const promisesRemovedList = [];
      for (const oldOption of currentOptions) {
        const existingOptions = newOptions.find(newOption => newOption.id === oldOption.id);
        if (!existingOptions) {
          const optionPromise = this.removeCardOption(oldOption.id);
          promisesRemovedList.push(optionPromise);
        }
      }

      Promise.all(promisesList)
        .then((resultOptions: CardOption[]) => {
          resolve(resultOptions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async updateCardOption(updatedOption: CardOption, oldOption: CardOption): Promise<any> {
    const cardOptionId = updatedOption.id;
    const updatedFields = {
      text: updatedOption.text,
      order_position: updatedOption.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.updateCardOption(cardOptionId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((responseOption: CardOption) => {
          const newActions = updatedOption.cardActions;
          const oldActions = oldOption.cardActions;
          const promiseActions = this.changeCardActions(cardOptionId, newActions, oldActions);
          promiseActions.then((resultActions: CardAction[]) => {
            const localOption: CardOption = {
              id: responseOption.id,
              text: responseOption.text,
              orderPosition: responseOption.orderPosition,
              cardActions: resultActions,
            };

            resolve(localOption);
          })
            .catch((error) => {
              reject(error);
            });
        },
          error => {
            console.log('Question card error ', error);
            reject(error);
          });
    });
  }

  async addCardOption(cardId: number, newOption: CardOption): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardOption(cardId, newOption)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdOption: CardOption) => {
          const cardOptionId = createdOption.id;
          const newActions = newOption.cardActions;
          const promiseActions = this.addCardActions(cardOptionId, newActions);
          promiseActions.then((resultActions: CardAction[]) => {
            const localOption: CardOption = {
              id: createdOption.id,
              text: createdOption.text,
              orderPosition: createdOption.orderPosition,
              cardActions: resultActions,
            };

            resolve(localOption);
          })
            .catch((error) => {
              reject(error);
            });
        },
          error => {
            console.log('Option error ', error);
            reject(error);
          });
    });
  }


  async removeCardOption(oldOptionId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.removeCardOption(oldOptionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((deletedOption: CardOption) => {
          resolve(deletedOption);
        },
          error => {
            console.log('Option error ', error);
            reject(error);
          });
    });
  }

  async changeCardActions(cardOptionId: number, newActions: CardAction[], currentActions: CardAction[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];

      for (const newAction of newActions) {
        const changedAction = currentActions.find(oldAction => oldAction.id === newAction.id);
        if (changedAction) {
          const cardActionId = changedAction.id;
          const actionPromise = this.updateCardAction(cardActionId, newAction);
          promisesList.push(actionPromise);
        } else {
          const actionPromise = this.createCardAction(cardOptionId, newAction);
          promisesList.push(actionPromise);
        }
      }

      const promisesRemovedList = [];
      for (const oldAction of currentActions) {
        const existingActions = newActions.find(newAction => newAction.id === oldAction.id);
        if (!existingActions) {
          const optionPromise = this.removeCardAction(oldAction.id);
          promisesRemovedList.push(optionPromise);
        }
      }

      Promise.all(promisesList)
        .then((resultActions: CardAction[]) => {
          resolve(resultActions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async addCardActions(cardOptionId: number, newActions: CardAction[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      for (const singleAction of newActions) {
        const singlePromise = this.createCardAction(cardOptionId, singleAction);
        promisesList.push(singlePromise);
      }

      Promise.all(promisesList)
        .then((resultActions: CardAction[]) => {
          resolve(resultActions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async createCardAction(cardOptionId: number, newAction: CardAction): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardAction(cardOptionId, newAction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(apiCardAction => {
          const localAction: CardAction = {
            id: apiCardAction.id,
            text: apiCardAction.text,
            parameter: apiCardAction.parameter,
            orderPosition: apiCardAction.orderPosition,
          };
          resolve(localAction);
        },
          error => {
            reject(error);
          });
    });
  }

  async updateCardAction(cardActionId: number, newAction: CardAction): Promise<any> {
    const updatedFields = {
      text: newAction.text,
      parameter: newAction.parameter,
      order_position: newAction.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.updateCardAction(cardActionId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((cardAction: CardAction) => {
          const localAction: CardAction = {
            id: cardAction.id,
            text: cardAction.text,
            parameter: cardAction.parameter,
            orderPosition: cardAction.orderPosition,
          };
          resolve(localAction);
        },
          error => {
            reject(error);
          });
    });
  }

  async removeCardAction(deletedActionId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.removeCardAction(deletedActionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((deletedAction: CardAction) => {
          resolve(deletedAction);
        },
          error => {
            reject(error);
          });
    });
  }

}
