import { Component, OnDestroy,
  OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SharedPackageService } from 'app/_services/shared-package.service';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { SharedService } from 'app/_services/shared.service';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CompanyService } from 'app/_services/company.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { Colleague } from 'app/_models/colleague';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { PackageParameter } from 'app/_models/package-parameter';
import { Router } from '@angular/router';
// import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { Experiment } from 'app/_models/experiment';

type ArrayOfArrays = any[][];

@Component({
  selector: 'app-excel-view',
  templateUrl: './excel-view.component.html',
  styleUrls: ['./excel-view.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExcelViewComponent implements OnInit, OnDestroy {
  // @ViewChild('myTable') table: any;
  // @ViewChild('dataTable') private dataTable: ElementRef;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  // columnMode: any = ColumnMode;
  // selectionType: any = SelectionType;
  packageId: number;
  data: ArrayOfArrays = [];
  dataHeaders: ArrayOfArrays = [];
  wopts: XLSX.WritingOptions = {bookType: 'xlsx', type: 'array'};
  fileName: string = 'TeamHeat.xlsx';
  colleagues: Colleague[] = [];
  questionsList: QuestionnaireCard[] = [];
  questionsNumber: number = 0;
  pagenderageId: number = 0;
  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  resultColumns: any = [];
  tableRows: any = [];
  tableColumns: any = [];
  isVerboseMode: boolean = false;
  isBootstrapped: boolean = false;

  constructor(
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private amplitudeService: AmplitudeEventsService,
    private questionnaireService: QuestionnaireService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.sharedService.currentTabId.next(3);
    this.formHeaders();
    this.amplitudeService.addEvent('enter excel results page');

    this.sharedPackageService.currentPackageName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageName: string) => {
        this.fileName = packageName + '.xlsx';
      });

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.packageId = packageId;
      });

    this.sharedPackageService.packageBootstrapped$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isBootstrapped: boolean) => {
        this.isBootstrapped = isBootstrapped;
      });

    this.sharedPackageService.onLoadAllExperiments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.tableRows = [];
        this.isVerboseMode = true;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showResearchDetails(): void {
    this.router.navigateByUrl(`/questionnaires/${this.packageId}/researches-details`);
  }

  formHeaders(): void {
    const headersArray: string[] = ['Имя Фамилия'];
    const promiseList = [];

    const promiseQuestions = this.formHeadersQuestions();
    const promiseParams = this.formHeadersParameters();
    promiseList.push(promiseQuestions);
    promiseList.push(promiseParams);

    Promise.all(promiseList)
      .then(result => {
        const questionsHeaders = result[0];
        let counterQuestions = 0;
        for (const singleHeader of questionsHeaders) {
          headersArray.push(singleHeader);
          const nextId = counterQuestions + 1;
          this.resultColumns.push({
            id: counterQuestions + 1,
            name: singleHeader,
            headerValue: singleHeader,
            prop: 'question' + nextId,
            minWidth: 50,
            color: 'red',
          });
          counterQuestions = counterQuestions + 1;
        }

        this.resultColumns.unshift({
          id: 0,
          name: 'Имя Фамилия',
          prop: 'name',
          minWidth: 50,
          color: 'blue',
        });
        let counterInd = 0;
        for (const singleParameter of this.individualParameters) {
          headersArray.push(singleParameter.visibleName);
          const nextId = counterInd + 1;
          this.resultColumns.push({
            id: counterQuestions + counterInd + 1,
            name: singleParameter.visibleName,
            prop: 'param' + nextId,
            minWidth: 20,
            color: 'white',
          });
          counterInd = counterInd + 1;
        }
        let counterCommon = 0;
        for (const singleParameter of this.commonParameters) {
          headersArray.push(singleParameter.visibleName);
          const nextId = counterCommon + 1;
          this.resultColumns.push({
            id: counterQuestions + counterInd + counterCommon + 1,
            name: singleParameter.visibleName,
            prop: 'commonParam' + nextId,
            minWidth: 20
          });
          counterCommon = counterCommon + 1;
        }
        this.data.push(headersArray);
        this.dataHeaders.push(headersArray);
        this.formExperimentData();
      }).catch(error => console.log(error));
  }

  formHeadersQuestions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.questionnaireCards$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((cards: QuestionnaireCard[]) => {
          const headersArray = [];
          if (cards.length > 0) {
            for (const card of cards) {
              const questionOrder = card.orderPosition;
              const questionText = this.formQuestionText(card.questionText, questionOrder);
              headersArray.push(questionText);
              this.questionsList.push(card);
            }
            this.questionsNumber = this.questionsList.length;
            resolve(headersArray);
          }
        });
    });
  }

  formHeadersParameters(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.packageParameters$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((parameters: PackageParameter[]) => {
          if (parameters) {
            for (const singleParameter of parameters) {
              if (singleParameter.groupName === 'individual') {
                this.individualParameters.push(singleParameter);
              } else if (singleParameter.groupName === 'common') {
                this.commonParameters.push(singleParameter);
              }
            }
            resolve(parameters);
          }
        });
    });
  }

  formExperimentData(): void {
    this.companyService.getColleaguesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colleagues: Colleague[]) => {
        this.colleagues = colleagues;
        this.sharedPackageService.packageTabExperiments$
          .pipe(
            filter(lastExperiments => lastExperiments && lastExperiments.length > 0),
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe((lastExperiments: any) => {
            for (const singleExperiment of lastExperiments) {
              if (singleExperiment.anonymous) {
                this.formAnonymousTable(singleExperiment);
              } else {
                this.formExperimentTable(singleExperiment);
              }
            }
          });
      });
  }

  getTableRow(infoArray: any, isSummary: boolean, rowId: number): any {
    const colleagueRow = {};
    for (const singleColumn of this.resultColumns) {
      const value = singleColumn['prop'];
      const id = singleColumn['id'];
      colleagueRow[value] = infoArray[id];
    }
    colleagueRow['isSummary'] = isSummary;
    if (rowId % 2 === 0) {
      colleagueRow['isEven'] = true;
    }

    // colleagueRow['width'] = { min: 200, max: 400 };
    return colleagueRow;
  }

  formAnonymousTable(experiment: Experiment): void {
    const expAnswers = experiment.experimentAnswers;
    const commonParams = experiment.experimentParameters;
    const experimentTable = [];
    let counter = 1;

    for (const singleAnswer of expAnswers) {
      const colleagueName = 'Colleague #' + counter;
      const rowArray = this.formColleagueRow(colleagueName, singleAnswer);
      experimentTable.push(rowArray);
      counter = counter + 1;
    }

    const startDate = experiment.startDate;
    const newRows = this.tableRows;

    let rowId = 0;
    for (const item of experimentTable) {
      const tableRow = this.getTableRow(item, false, rowId);
      newRows.push(tableRow);
      rowId = rowId + 1;
    }

    const resultRowArray = this.formCommonParametersRow(commonParams, startDate);
    const resultTableRow = this.getTableRow(resultRowArray, true, 0);
    newRows.push(resultTableRow);

    this.tableRows = [...newRows];
    this.tableColumns = [...this.resultColumns];
  }

  formExperimentTable(experiment: Experiment): void {
    const expAnswers = experiment.experimentAnswers;
    const commonParams = experiment.experimentParameters;
    const relativeAnswers = experiment.relativeExperimentAnswers;
    const experimentTable = [];

    for (const colleague of this.colleagues) {
      const colleagueId = colleague.id;
      const filteredAnswers = expAnswers.filter(answer => answer.colleagueId === colleagueId);
      if (filteredAnswers.length > 0) {
        let counter = 1;
        if (filteredAnswers.length > 1) {
          const sortedAnswers = filteredAnswers.sort(function (a: any, b: any): number {
            return a.id - b.id;
          });
          for (const singleAnswer of sortedAnswers) {
            const colleagueName = colleague.firstName + ' ' + colleague.lastName + ' #' + counter;
            const filteredRowArray = this.formColleagueRow(colleagueName, singleAnswer);
            experimentTable.push(filteredRowArray);
            counter = counter + 1;
          }
        } else {
          const colleagueName = colleague.firstName + ' ' + colleague.lastName;
          const noFilterRowArray = this.formColleagueRow(colleagueName, filteredAnswers[0]);
          experimentTable.push(noFilterRowArray);
        }
      }
    }

    for (const singleAnswer of relativeAnswers) {
      const findColleague = this.colleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
      if (findColleague) {
        const colleagueName = findColleague.firstName + ' ' + findColleague.lastName + " (relative)";
        const rowArray = this.formColleagueRowRelative(colleagueName, singleAnswer);
        experimentTable.push(rowArray);
      }
    }

    const startDate = experiment.startDate.split('T')[0];
    const newRows = this.tableRows;

    let rowId = 0;
    for (const item of experimentTable) {
      const expTableRow = this.getTableRow(item, false, rowId);
      newRows.push(expTableRow);
      rowId = rowId + 1;
    }

    const rowArray = this.formCommonParametersRow(commonParams, startDate);
    const tableRow = this.getTableRow(rowArray, true, 0);
    newRows.push(tableRow);

    this.tableRows = [...newRows];
    this.tableColumns = [...this.resultColumns];
  }


  formCommonParametersRow(commonParams: any, startDate: string): any {
    const dataRow = [];
    const experimentName = 'Эксперимент';
    dataRow.push(experimentName);

    dataRow.push(startDate);

    for (let i = 0; i < this.questionsNumber - 1; i++) {
      dataRow.push('');
    }

    for (const singleParameter of this.individualParameters) {
      dataRow.push('');
    }

    for (const singleParameter of this.commonParameters) {
      const findParam = commonParams.find(param => param.packageParameterId === singleParameter.id);
      if (findParam) {
        const value = findParam.commonValue.value;
        if (value) {
          if (typeof(value) === 'number') {
            dataRow.push(value.toFixed(2));
          } else {
            dataRow.push(value);
          }
        } else {
          dataRow.push('-');
        }
      } else {
        dataRow.push('-');
      }
    }

    this.data.push(dataRow);
    return dataRow;
  }

  formColleagueRow(name: string, experimentAnswer: ExperimentAnswer): any {
    const infoArray = [];
    infoArray.push(name);
    let counter = 1;
    for (const singleQuestion of this.questionsList) {
      const questionId = singleQuestion.id;
      const questionType = singleQuestion.cardType;
      const options = singleQuestion.cardOptions;
      let isOptions = false;
      if (questionType === 'checkboxes' || questionType === 'relative-checkboxes'
      || questionType === 'options' || questionType === 'relative-options') {
        isOptions = true;
      }
      const colleagueInput = this.getColleagueInput(experimentAnswer, questionId, isOptions);
      if (colleagueInput) {
        if (isOptions && Array.isArray(colleagueInput)) {
          const itemsTextArray = [];
          for (const singleItem of colleagueInput) {
            const resultOption = options.find(singleOption => singleOption.id === singleItem);
            if (resultOption) {
              const convertedText = this.convertTextParameter(resultOption.text);
              itemsTextArray.push(convertedText);
            }
          }

          const itemText = itemsTextArray.join("; ");

          if (itemText.length > 0) {
            infoArray.push(itemText);
          } else {
            infoArray.push('-');
          }
        } else {
          const convertedText = this.convertTextParameter(colleagueInput);
          infoArray.push(convertedText);
        }
      } else {
        infoArray.push('-');
      }
      counter = counter + 1;
    }

    for (const singleParameter of this.individualParameters) {
      const paramType = singleParameter.commonValue.type;
      const paramId = singleParameter.id;
      const param = this.getColleagueParameter(experimentAnswer, paramId, paramType);
      infoArray.push(param);
    }

    for (const singleParameter of this.commonParameters) {
      infoArray.push('');
    }

    this.data.push(infoArray);
    return infoArray;
  }

  formColleagueRowRelative(name: string, relativeAnswer: RelativeExperimentAnswer): any {
    const infoArray = [];
    infoArray.push(name);
    let counter = 1;
    for (const singleQuestion of this.questionsList) {
      infoArray.push('-');
      counter = counter + 1;
    }

    for (const singleParameter of this.individualParameters) {
      const paramType = singleParameter.commonValue.type;
      const paramId = singleParameter.id;
      const param = this.getColleagueParameterRelative(relativeAnswer, paramId, paramType);
      infoArray.push(param);
    }

    for (const singleParameter of this.commonParameters) {
      infoArray.push('');
    }

    this.data.push(infoArray);
    return infoArray;
  }

  getColleagueInput(experimentAnswer: ExperimentAnswer, cardId: number, isOptions: boolean): any {
    const cardAnswers = experimentAnswer.colleagueCardAnswers;
    if (!cardAnswers) {
      return '-';
    }

    const resultAnswer = cardAnswers.filter(answer => answer.questionnaireCardId === cardId);
    if (resultAnswer && resultAnswer.length > 0) {
      if (isOptions) {
        const optionsArray = [];
        for (const singleResult of resultAnswer) {
          optionsArray.push(singleResult.chosenOptionId);
        }
        optionsArray.sort();
        return optionsArray;
      } else {
        return resultAnswer[0].userInput;
      }
    } else {
      return '-';
    }
  }

  getColleagueParameter(experimentAnswer: ExperimentAnswer, paramId: number, paramType: string): any {
    const cardParameters = experimentAnswer.colleagueParameters;
    if (!cardParameters) {
      return '-';
    }

    const resultParameter = cardParameters.find(param => param.packageParameterId === paramId);
    if (resultParameter) {
      if (paramType === 'text') {
        return resultParameter.textValue;
      } else if (paramType === 'number') {
        return this.precise(resultParameter.value);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  getColleagueParameterRelative(relativeAnswer: RelativeExperimentAnswer, paramId: number, paramType: string): any {
    const cardParameters = relativeAnswer.relativeExperimentParameters;
    if (!cardParameters) {
      return '-';
    }

    const resultParameter = cardParameters.find(param => param.packageParameterId === paramId);
    if (resultParameter) {
      if (paramType === 'text') {
        return resultParameter.textValue;
      } else if (paramType === 'number') {
        return this.precise(resultParameter.value);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  convertTextParameter(inputText: string): string {
    if (inputText) {
      const newValue = String(inputText).replace(/(\r\n|\n|\r)/gm, '<br />');
      return newValue;
    } else {
      return '';
    }
  }

  formQuestionText(inputString: string, orderNumber: number): string {
    let rawText = inputString.replace(/<[^>]*>?/gm, '');
    if (rawText.length > 150) {
      rawText = rawText.slice(0, 150);
      rawText = rawText + '...'
    }
    const questionText = `Вопрос #${orderNumber}: ${rawText}`;
    return questionText;
  }

  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = <ArrayOfArrays>(XLSX.utils.sheet_to_json(ws, {header: 1}));
    };

    reader.readAsBinaryString(target.files[0]);
  }

  export(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);
  }

  precise(inputValue: number): number {
    if (inputValue) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    } else {
      return 0;
    }
  }

}
