import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-separator-widget',
  templateUrl: './separator-widget.component.html',
  styleUrls: ['./separator-widget.component.scss']
})
export class SeparatorWidgetComponent implements OnInit, OnDestroy {
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() widgetTitle: string;

  @Output() onTitleUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();

  public showTextMode: boolean = true;
  public editTextMode: boolean = false;

  private separatorText: string = '';
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.separatorText = this.widgetTitle;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  editText(): void {
    this.editTextMode = true;
    this.showTextMode = false;
  }

  showText(): void {
    this.editTextMode = false;
    this.showTextMode = true;
  }

  onBlurTitle(): void {
    this.onTitleUpdate.emit(this.separatorText);
  }
}
