import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { Router } from '@angular/router';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { User } from 'app/_models/user';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Questionnaire } from 'app/_models/questionnaire';
import { PackageParameter } from 'app/_models/package-parameter';
import { DashboardService } from 'app/_services/dashboard.service';
import { PackageParticipant } from 'app/_models/package-participant';
import { SharedDashboardHelper } from '../shared_widgets/_helpers/shared-dashboard-helper';
import { Experiment } from 'app/_models/experiment';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { SharedService } from 'app/_services/shared.service';

@Component({
  selector: 'app-shared-dashboard-editor',
  templateUrl: './shared-dashboard-editor.component.html',
  styleUrls: ['./shared-dashboard-editor.component.scss']
})
export class SharedDashboardEditorComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() inputDashboard: SharedDashboard;
  @Input() sharedQuestionnaires: Questionnaire[] = [];
  @Input() sharedPackageParameters: PackageParameter[] = [];
  @Input() sharedPackageParticipants: PackageParticipant[] = [];
  @Output() onReloadSharedDashboard: EventEmitter<any> = new EventEmitter<any>();
  isAdmin: boolean = true;
  // isLoaded: boolean = false;
  sharedWidgets: SharedDashboardWidget[];
  sharedDashboardId: number;
  companyId: number = 0;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private sharedDashboardHelper: SharedDashboardHelper,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.sharedDashboardHelper.emptyCachedData();
    this.sharedDashboardHelper.setSharedQuestionnaires(this.sharedQuestionnaires);
    this.sharedDashboardHelper.setSharedPackageParameters(this.sharedPackageParameters);
    this.sharedDashboardHelper.setSharedPackages(this.inputDashboard.packages);
    this.sharedDashboardHelper.setSharedDashboardWidgets(this.inputDashboard.sharedWidgets);
    this.sharedDashboardHelper.setSharedParticipants(this.sharedPackageParticipants);
    this.getUserInfo();
    this.companyId = this.sharedService.currentCompanyId.getValue();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
          if (isAdmin) {
            // this.getDashboardConfig();
            this.getSharedDashboard();
            this.preloadData();
          } else {
            this.redirectNotAdmin();
          }
        },
        error => {
          this.redirectNotAdmin();
        });
    }
  }

  preloadData(): void {
    const promisesList = [];
    let promiseColleagues: Promise<any>;
    let promiseExperiments: Promise<any>;
    // let promiseParticipants: Promise<any>;
    let promiseDepartments: Promise<any>;

    promiseColleagues = this.sharedDashboardHelper.preloadColleagues(true);
    promiseColleagues.then((colleagues: Colleague[]) => {
      if (colleagues) {
        this.sharedDashboardHelper.setSharedColleagues(colleagues);
      }
    }).catch((error) => console.log(error));
    promiseDepartments = this.sharedDashboardHelper.preloadDepartments(this.companyId);
    promiseDepartments.then((departments: Department[]) => {
      if (departments) {
        this.sharedDashboardHelper.setSharedDepartments(departments);
      }
    }).catch((error) => console.log(error));
    promiseExperiments = this.sharedDashboardHelper.preloadSharedExperiments(false);
    promiseExperiments.then((experiments: Experiment[]) => {
      if (experiments) {
        this.sharedDashboardHelper.setSharedExperiments(experiments);
      }
    }).catch((error) => console.log(error));
    // promiseParticipants = this.sharedDashboardHelper.getSharedPackageParticipants();

    promisesList.push(promiseDepartments);
    promisesList.push(promiseColleagues);
    promisesList.push(promiseExperiments);
    // promisesList.push(promiseParticipants);

    Promise.all(promisesList).then((result) => {
      this.sharedDashboardHelper.isLoaded.next(true);
    }).catch((error) => console.log(error));
  }

  redirectNotAdmin(): void {
    this.router.navigateByUrl(`/company/`);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSaveDashboard(): void {
    const promisesList = [];
    const newWidgets = this.sharedWidgets.filter(widget => widget.id === 0);
    for (const singleWidget of newWidgets) {
      const widgetPromise = this.createNewWidget(singleWidget);
      promisesList.push(widgetPromise);
    }

    const existingWidgets = this.sharedWidgets.filter(widget => widget.id !== 0);
    for (const singleWidget of existingWidgets) {
      const widgetPromise = this.updateWidget(singleWidget);
      promisesList.push(widgetPromise);
    }

    const inputWidgets = this.inputDashboard.sharedWidgets;
    for (const singleWidget of inputWidgets) {
      const stillExists = this.sharedWidgets.find(widget => widget.id === singleWidget.id);
      if (!stillExists) {
        const widgetPromise = this.removeWidget(singleWidget);
        promisesList.push(widgetPromise);
      }
    }

    Promise.all(promisesList)
      .then((resultWdigets: SharedDashboardWidget[]) => {
        this.sharedDashboardHelper.updateSharedDashboardWidgets(resultWdigets);
        // this.getDashboardConfig();
        this.onReloadSharedDashboard.emit();
      })
      .catch((error) => {
        // reject(error);
      });
  }

  createNewWidget(newWidget: SharedDashboardWidget): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dashboardService.addSharedDashboardWidget(newWidget)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((dashboardWidget: SharedDashboardWidget) => {
          resolve(dashboardWidget);
        }, error => {
          reject(error);
        });
    });
  }

  updateWidget(widget: SharedDashboardWidget): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dashboardService.updateSharedDashboardWidget(widget)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((dashboardWidget: SharedDashboardWidget) => {
          resolve(dashboardWidget);
        }, error => {
          reject(error);
        });
    });
  }

  removeWidget(widget: SharedDashboardWidget): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dashboardService.removeSharedDashboardWidget(widget)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: any) => {
          resolve(result);
        }, error => {
          reject(error);
        });
    });
  }

  getSharedDashboard(): void {
    const inputWidgets = this.inputDashboard.sharedWidgets;
    this.sharedDashboardId = this.inputDashboard.id;
    this.sharedWidgets = [];
    for (const singleWidget of inputWidgets) {
      this.sharedWidgets.push(singleWidget);
    }
  }

  onAddTextWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const textWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'card-text-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(textWidget);
  }

  onAddHeatmapWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const heatmapWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'heatmap-individual-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(heatmapWidget);
  }

  onAddSharedBarWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const barWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'shared-bar-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(barWidget);
  }

  onAddLineCommonWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const linepWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'line-common-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(linepWidget);
  }

  onAddBriefStatistics(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const statisticsWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'brief-statistics-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(statisticsWidget);
  }

  onAddSeparatorWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const separatorWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'separator-widget',
      widgetTitle: 'This is the default text. You can change it in the settings.',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(separatorWidget);
  }

  onAddDescriptionCardWidget(): void {
    const nextPosition = this.sharedWidgets.length + 1;
    const descriptionWidget: SharedDashboardWidget = {
      id: 0,
      widgetName: 'description-card-widget',
      widgetTitle: '',
      orderPosition: nextPosition,
      sharedDashboardId: this.sharedDashboardId,
      widgetFilters: {}
    };
    this.sharedWidgets.push(descriptionWidget);
  }

  onDeleteWidget(widget: any): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        const filterWidget = this.sharedWidgets.filter(dashboardWidget => dashboardWidget.orderPosition !== widget.orderPosition);
        this.sharedWidgets = filterWidget;
        this.reorderWidgetsArray();
      }
    });
  }

  onWidgetUp(widget: any): void {
    const findWidget = this.sharedWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widget.orderPosition);
    if (findWidget) {
      const widgetPosition = findWidget.orderPosition;
      const widgetAbove = this.sharedWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widgetPosition - 1);
      if (widgetAbove) {
        const abovePosition = widgetAbove.orderPosition;
        widgetAbove.orderPosition = widgetPosition;
        findWidget.orderPosition = abovePosition;
        this.reorderWidgetsArray();
      }
    }
  }

  onWidgetDown(widget: any): void {
    const findWidget = this.sharedWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widget.orderPosition);
    if (findWidget) {
      const widgetPosition = findWidget.orderPosition;
      const widgetBelow = this.sharedWidgets.find(dashboardWidget => dashboardWidget.orderPosition === widgetPosition + 1);
      if (widgetBelow) {
        const belowPosition = widgetBelow.orderPosition;
        widgetBelow.orderPosition = widgetPosition;
        findWidget.orderPosition = belowPosition;
        this.reorderWidgetsArray();
      }
    }
  }

  onFiltersUpdate(widgetFilters: any, widget: any): void {
    widget.widgetFilters = widgetFilters;
  }

  onTitleUpdate(newTitle: string, widget: any): void {
    widget.widgetTitle = newTitle;
  }

  reorderWidgetsArray(): void {
    const newSharedWidgets = [];
    const sortedWidgets = this.sharedWidgets.sort(function (a: any, b: any): number {
      return a.orderPosition - b.orderPosition;
    });

    let orderCount = 1;
    for (const singleWidget of sortedWidgets) {
      singleWidget.orderPosition = orderCount;
      newSharedWidgets.push(singleWidget);
      orderCount = orderCount + 1;
    }

    this.sharedWidgets = newSharedWidgets;
  }

}
