import {
  Component, OnInit, OnDestroy, Inject
} from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-error-info',
  templateUrl: './snackbar-error-info.component.html',
  styleUrls: ['./snackbar-error-info.component.scss']
})
export class SnackbarErrorInfoComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  // infoBar: string[] = [];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log('Data? ', this.data);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
