<div class="widget line-common-widget">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div *ngIf="showChartMode" class="chart-box">
    <highcharts-chart
      style="display: flex;"
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      [(update)]="updateFlag"
      [callbackFunction]="chartCallback"
      [oneToOne]="true"
    ></highcharts-chart>
  </div>

  <mat-card *ngIf="parametersEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <button mat-raised-button color="accent" i18n="@@selectAllParameters" (click)="setActiveParameters()">
        Select all parameters
      </button>
    </div>
    <div *ngFor="let parameter of commonParameters">
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="parameter.isActive" (change)="widgetFiltersUpdate()">
          {{parameter.visibleName}}
        </mat-checkbox>
      </div>
    </div>
  </mat-card> <!-- /.common-parameters -->
</div> <!-- /.widget -->

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showCommonParametersList">Show common parameters list</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu> <!-- /.mat-menu -->
