import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { throwError, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
  private authLoginUrl: string = environment.API_ENDPOINT + '/auth/login';
  private authLogoutUrl: string = environment.API_ENDPOINT + '/auth/logout';
  private refreshUrl: string = environment.API_ENDPOINT + '/auth/refresh';

  private loggedUserEmail: string = '';
  private loggedUserId: number = 0;
  private userAccessToken: string = '';
  private userRefreshToken: string = '';

  constructor(private router: Router, private http: HttpClient) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userAccessToken = currentUser && currentUser.access_token;
    this.userRefreshToken = currentUser && currentUser.refresh_token;
    this.loggedUserEmail = currentUser && currentUser.email;
    this.loggedUserId = currentUser && currentUser.user_id;
  }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    return httpOptions;
  }

  login(email: string, password: string): Observable<boolean> {
    const user = JSON.stringify({ email: email.toLowerCase(), password: password });

    return this.http.post<any>(this.authLoginUrl, user, this.getHttpOptions())
      .pipe(
        tap(response => {
          this.doLoginUser(email, response);
        }),
        mapTo(true),
        catchError(error => {
          return of(false);
        }));
  }

  logout(): Observable<boolean> {
    const item = JSON.stringify({refresh_token: this.getRefreshToken(), email: this.loggedUserEmail});
    this.removeUser();

    return this.http.post<any>(this.authLogoutUrl, item, this.getHttpOptions())
      .pipe(
        tap(response => this.afterUserLogout(response)),
        mapTo(true),
        // catchError(error => {
        //   console.log('Error with logout ', error);
        //   return of(false);
        // })
        catchError(this.handleError)
      );
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();

    const item = JSON.stringify({refresh_token: refreshToken});

    return this.http.post<any>(this.refreshUrl, item, this.getHttpOptions())
      .pipe(tap((token_object: any) => {
        // console.log('REFRESH ACCESS TOKEN ', token_object);
        const token = token_object['access_token'];
        this.updateAccessToken(token);
      }));
  }

  isLoggedIn(): boolean {
    if (localStorage.getItem('currentUser')) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUserId(): number {
    if (this.isLoggedIn() && this.loggedUserId) {
      return this.loggedUserId;
    }
  }

  getCurrentUserEmail(): string {
    if (this.isLoggedIn() && this.loggedUserEmail) {
      return this.loggedUserEmail;
    }
  }

  getAccessToken(): any {
    const token = this.userAccessToken;
    return token || null;
  }

  private doLoginUser(email: string, response: any): void {
    if (response) {
      this.loggedUserEmail = email;
      this.loggedUserId = response['user_id'];
      this.userAccessToken = response['access_token'];
      this.userRefreshToken = response['refresh_token'];

      const currentUser = {
        email: email,
        refresh_token: response['refresh_token'],
        access_token: response['access_token'],
        user_id: response['user_id']
      };

      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
  }

  private afterUserLogout(response: any): void {
    // console.log('After logout', response);
    this.userAccessToken = null;
    this.userRefreshToken = null;
    this.loggedUserEmail = null;
    this.loggedUserId = null;
  }

  private getRefreshToken(): string {
    return this.userRefreshToken;
  }

  private updateAccessToken(token: string): void {
    if (token) {
      this.userAccessToken = token;
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      currentUser['access_token'] = token;
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
  }

  private removeUser(): void {
    localStorage.removeItem('currentUser');
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error);
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }
}
