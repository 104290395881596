import { Record, Number, String, Boolean, Null, Array } from 'runtypes';

const WidgetFilters = Record({
  active_packages_ids: Array(Number).Or(Null),
  active_parameters_ids: Array(Number).Or(Null),
});

export const ApiColleagueInformation = Record({
  id: Number,
  colleague_id: Number,
  has_shared_link: Boolean,
  shared_link: String,
  colleague_filters: WidgetFilters.Or(Record({}))
});
