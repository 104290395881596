import { Injectable, OnInit } from '@angular/core';
import { PackageParticipant } from 'app/_models/package-participant';
import { resolve } from 'path/posix';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { ExtendedColleague, WidgetFilters } from './local-models';

@Injectable({
  providedIn: 'root'
})
export class ChartHelper {

  constructor( ){}


  formTestData(): any {
    const lineData = [1, 4, 3, 4, 5, 6, 7];
    const newLine = {
      name: 'Test parameter',
      type: 'spline',
      data: lineData,
    };

    return newLine;
  }

  formExperimentsLabels(experiments): string[] {
    const categories = [];

    for (const experiment of experiments) {
      const label = experiment.startDate.split('T')[0];
      categories.push(label);
    }

    return categories;
  }

  precise(inputValue: number): number {
    if (inputValue && (typeof(inputValue) === 'number')) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    } else {
      return 0;
    }
  }

  setActiveParticipants(colleagues: ExtendedColleague[], participants: PackageParticipant[]): ExtendedColleague[] {
    for (const colleague of colleagues) {
      const findParticipant = participants.find(participant => participant.colleagueId === colleague.id);

      colleague.isActive = findParticipant.isSelected;
    }

    return colleagues;
  }

  setActiveParameters(parameters: any[], value: boolean): any[] {
    for (const parameter of parameters) {
      parameter.isActive = !value;
    }

    return parameters;
  }

  restoreInputWidgetsFilters(widgetParameters: any[], parameters: any[]): any[] {
    for (const parameter of parameters) {
      const findParam = widgetParameters.find(id => id === parameter.id);
      if (findParam) {
        parameter.isActive = true;
      } else {
        parameter.isActive = false;
      }
    }

    return parameters;
  }

  widgetFiltersUpdate(parameters: any[]): any[] {
    const result = [];

    const filteredParameters = parameters.filter(param => param.isActive === true);
    for (const parameter of filteredParameters) {
      result.push(parameter.id);
    }

    return result;
  }
}
