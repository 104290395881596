import { Number, String, Record, Partial, Array, Null, Union } from 'runtypes';
import { ApiDepartment } from './api-department';

const ApiLogo = Record({
  url: String
});

export const ApiCompany = Record({
  id: Number,
  uuid: String,
  name: String,
  owner_email: String,
  owner_id: Number,
  slack_integration_token: String,
  colleague_shared_link: String
}).And(Partial({
  departments: Array(ApiDepartment),
  logo: ApiLogo.Or(Null)
}));
