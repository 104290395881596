<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header">
    <div class="card-header-group">
      <div class="package-name">
        <a class="package-back" routerLink="/questionnaires/standard-sets" title="Назад к списку шаблонов">
          <mat-icon role="img" aria-hidden="true">arrow_back</mat-icon>
        </a>
        {{currentSetName}}
      </div>

      <mat-tab-group [selectedIndex]="selectedTabId">

        <!-- Can't use ngFor because of translations for tabs -->
        <mat-tab>
          <ng-template mat-tab-label>
            <span (click)="changeUrl(0)" class="tab-group">
              <i class="material-icons">
                description
              </i>
              <span i18n="@@description-tab">Description</span>
            </span>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span i18n="@@questions-tab" (click)="changeUrl(1)">Questions</span>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span (click)="changeUrl(2)" class="tab-group">
                          <i class="material-icons">
              equalizer
            </i>
            <span i18n="@@dashboard-tab">Dashboard</span>
            </span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content-wrap" *ngIf="currentSetName">
  <router-outlet></router-outlet>
</div>