<div class="dashboard-editor">
  <div class="dashboard-editor__widgets">
    <div *ngFor="let widget of dashboardConfigWidgets">
      <div *ngIf="widget.widgetName === 'card-text-widget'">
        <app-text-card-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-text-card-widget>
      </div>

      <div *ngIf="widget.widgetName === 'heatmap-individual-widget'">
        <app-heatmap-individual-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-heatmap-individual-widget>
      </div>

      <div *ngIf="widget.widgetName === 'line-common-widget'">
        <app-line-common-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-line-common-widget>
      </div>

      <div *ngIf="widget.widgetName === 'individual-chart-widget'">
        <app-individual-chart-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-individual-chart-widget>
      </div>

      <div *ngIf="widget.widgetName === 'common-text-card-widget'">
        <app-common-text-card-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-common-text-card-widget>
      </div>

      <div *ngIf="widget.widgetName === 'brief-statistics-widget'">
        <app-passage-brief-statistics
          [userView]="false"
          [testData]="false"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-passage-brief-statistics>
      </div>
      <div *ngIf="widget.widgetName === 'separator-widget'">
        <app-separator-widget
          [userView]="false"
          [testData]="false"
          [widgetTitle]="widget.widgetTitle"
          (onTitleUpdate)="onTitleUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-separator-widget>
      </div>
      <div *ngIf="widget.widgetName === 'description-card-widget'">
        <app-description-card-widget
          [userView]="false"
          [testData]="false"
          [widgetTitle]="widget.widgetTitle"
          [isPublicLink]="false"
          [sharedDashboardMode]="false"
          (onTitleUpdate)="onTitleUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-description-card-widget>
      </div>
      <div *ngIf="widget.widgetName === 'individual-parameter-widget'">
        <app-individual-parameter-widget
          [userView]="false"
          [testData]="false"
          [inputWidgetId]="widget.id"
          (onFiltersUpdate)="onFiltersUpdate($event, widget)"
          (onDeleteWidget)="onDeleteWidget(widget)"
          (onWidgetUp)="onWidgetUp(widget)"
          (onWidgetDown)="onWidgetDown(widget)">
        </app-individual-parameter-widget>
      </div>
    </div>
  </div> <!-- /.widgets -->

  <div class="dashboard-editor__tools-wrapper">
    <div class="dashboard-editor__tools">
      <div class="dashboard-editor__tools-inner">
        <div class="dashboard-editor__icons">
          <div class="button-container">
            <button mat-icon-button matTooltip="Add card view" (click)="onAddTextWidget()" class="dashboard-editor__icon">
              <mat-icon>assignment</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add heat map" (click)="onAddHeatmapWidget()" class="dashboard-editor__icon">
              <mat-icon>map</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add common parameters chart" (click)="onAddLineCommonWidget()" class="dashboard-editor__icon">
              <mat-icon>show_chart</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add individual parameters chart" (click)="onAddIndividualChartWidget()" class="dashboard-editor__icon">
              <mat-icon>multiline_chart</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add common text card" (click)="onAddCommonTextCardWidget()"class="dashboard-editor__icon">
              <mat-icon>wysiwyg</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add brief statistics" (click)="onAddBriefStatistics()" class="dashboard-editor__icon">
              <mat-icon>functions</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add separator" (click)="onAddSeparatorWidget()" class="dashboard-editor__icon">
              <mat-icon>title</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add description card" (click)="onAddDescriptionCardWidget()" class="dashboard-editor__icon">
              <mat-icon>text_format</mat-icon>
            </button>
          </div>
          <div class="button-container">
            <button mat-icon-button matTooltip="Add bar chart" (click)="onAddIndividualParameterWidget()" class="dashboard-editor__icon">
              <mat-icon>bar_chart</mat-icon>
            </button>
          </div>
        </div> <!-- /.dashboard-editor__icons -->

        <div class="dashboard-editor__buttons">
          <button
            mat-raised-button
            color="accent"
            class="dashboard-editor__button"
            (click)="onBack()"
            i18n="@@backButton">
            Back
          </button>

          <button
            mat-raised-button
            color="accent"
            class="dashboard-editor__button"
            (click)="onSaveDashboard()"
            i18n="@@saveDashboard">
            Save dashboard
          </button>
        </div> <!-- /.dashboard-editor__buttons-->
      </div> <!-- /.dashboard-editor__tools-inner-->
    </div> <!-- /.dashboard-editor__tools -->
  </div> <!-- /.dashboard-editor__tools-wrapper -->
</div> <!-- /.dashboard-editor -->
