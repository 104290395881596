import { AdvancedAdminConfig } from 'app/_models/advanced-admin-config';
import { ApiAdvancedAdminConfig } from 'app/_api_models/api-advanced-admin-config';
import { environment } from '../../environments/environment';

export function parseApiAdvancedAdminConfig(apiResponse: any): AdvancedAdminConfig {
  let configObject;
  if (environment.production) {
    configObject = apiResponse;
  } else {
    configObject = ApiAdvancedAdminConfig.check(apiResponse);
  }

  const newConfig: AdvancedAdminConfig = {
    id: configObject.id,
    templatesOrderRu: configObject.templates_order_ru,
    templatesOrderEn: configObject.templates_order_en,
  };

  return newConfig;
}
