import { Questionnaire } from 'app/_models/questionnaire';
import { ApiQuestionnaire } from 'app/_api_models/api-questionnaire';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { Experiment } from 'app/_models/experiment';

import { parseApiQuestionnaireCardVerbose } from './parse-api-questionnaire-card-verbose';
import { parseApiExperiment } from 'app/_api_validation/parse-api-experiment';

import { environment } from '../../environments/environment';

function checkIncludedType(itemType: string): boolean {
  if (itemType === 'card_option') {
    return true;
  } else if (itemType === 'card_action') {
    return true;
  } else if (itemType === 'score_division') {
    return true;
  } else {
    return false;
  }
}

export function parseApiQuestionnaireVerbose(apiResponse: any, nestedModels: boolean, included: any): Questionnaire {
  let questionnaireObject;
  if (environment.production) {
    questionnaireObject = apiResponse;
  } else {
    questionnaireObject = ApiQuestionnaire.check(apiResponse);
  }

  const questionnaireCards: QuestionnaireCard[] = [];
  const experiments: Experiment[] = [];
  if (nestedModels && included) {
    const apiQuestionnaireCards = included.filter(item => item.type === 'questionnaire_card');
    if (apiQuestionnaireCards) {
      const filterIncluded = included.filter(item => checkIncludedType(item.type));
      for (const singleCard of apiQuestionnaireCards) {
        const localCard = parseApiQuestionnaireCardVerbose(singleCard.attributes, filterIncluded);
        questionnaireCards.push(localCard);
      }
    }

    const apiExperiments = included.filter(item => item.type === 'experiment');
    if (apiExperiments) {
      for (const singleExperiment of apiExperiments) {
        const localExperiment = parseApiExperiment(singleExperiment.attributes, false, []);
        experiments.push(localExperiment);
      }
    }
  }

  const questionnaire: Questionnaire = {
    id: questionnaireObject.id,
    packageId: questionnaireObject.package_id,
    startDate: questionnaireObject.start_date,
    finishDate: questionnaireObject.finish_date,
    lastRun: questionnaireObject.last_run,
    nextRun: questionnaireObject.next_run,
    interval: questionnaireObject.interval,
    activeStatus: questionnaireObject.active_status,
    daysEnabled: questionnaireObject.days_enabled,
    startOffset: questionnaireObject.start_offset,
    launchOptions: questionnaireObject.launch_options,
    needCalculation: questionnaireObject.need_calculation,
    anonymous: questionnaireObject.anonymous || false,
    questionnaireCards: questionnaireCards,
    experiments: experiments,
  };

  return questionnaire;
}
