import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar';
import { LocalColleague, LocalDepartment } from '../local-models';
import { ExtendedColleague } from 'app/colleagues-page/local-models';
import { ColleagueEvaluator } from 'app/_models/colleague-evaluator';

import { PackageService } from 'app/_services/package.service';
import { UtilsService } from 'app/_services/utils.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';

@Component({
  selector: 'app-package-participant',
  templateUrl: './package-participant.component.html',
  styleUrls: ['./package-participant.component.scss']
})
export class PackageParticipantComponent implements OnInit {
  @ViewChild('colleagueInput') colleagueInput: ElementRef<HTMLInputElement>;
  @Input('packageId') packageId: number;
  @Input('colleague') colleague: LocalColleague;
  @Input('department') department: LocalDepartment;
  @Input('colleagues') colleagues: ExtendedColleague[];
  @Input('evaluators') evaluators: ColleagueEvaluator[];
  @Output() newColleague = new EventEmitter<LocalColleague>();
  @Output() newDepartment = new EventEmitter<LocalDepartment>();

  colleagueCtrl = new FormControl('');
  selectedEvaluators: ExtendedColleague[] = [];
  filteredColleagues: Observable<ExtendedColleague[]>;

  currentLanguage: string = 'en';
  msgAddColleague: string = 'Employee has been successfully added';
  msgDeleteColleague: string = 'Employee has been successfully deleted';

  msgSentEmail: string = 'Email successfully sent';

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private snackBar: SnackBarComponent,
    private utilsService: UtilsService,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit(): void {
    this.currentLanguage = this.utilsService.getCurrentLanguage();
    this.filterEvaluatorsList();
    this.updateColleaguesList();

    this.filteredColleagues = this.colleagueCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this.filter(fruit) : this.colleagues.slice())
    );
  }

  translateSnackBarMessages(): void {
    if (this.currentLanguage === 'ru') {
      this.msgAddColleague = 'Сотрудник был успешно добавлен';
      this.msgDeleteColleague = 'Сотрудник был успешно удалён';
      this.msgSentEmail = 'Письмо было успешно отправлено';
    }
  }

  filter(name: string): ExtendedColleague[] {
    if (typeof name === 'object') {
      return this.colleagues;
    }
    return this.colleagues.filter(param =>
      param.firstName.toLowerCase().includes(name.toLowerCase()) ||
      param.lastName.toLowerCase().includes(name.toLowerCase())
    );
  }

  filterEvaluatorsList(): void {
    const filteredEvaluators = this.evaluators.filter(evaluator => evaluator.colleagueId === this.colleague.colleagueId);
    filteredEvaluators.forEach((evaluator) => {
      const newSelectedEvaluator = this.colleagues.find(colleague => colleague.id === evaluator.evaluatorId);
      if (newSelectedEvaluator) {
        this.selectedEvaluators.push(newSelectedEvaluator);
      }
    });
  }

  updateColleaguesList(): void {
    this.colleagues.forEach((colleague) => {
      const isColleagueSelected = this.selectedEvaluators.find(param => param.id === colleague.id);
      if (isColleagueSelected) {
        this.colleagues = this.colleagues.filter(param => param.id !== colleague.id);
      }
    });

    this.colleagues = this.colleagues.filter(colleague => colleague.id !== this.colleague.colleagueId);
    this.sortColleaguesList();
  }

  onSelectEvaluator(event): void {
    const evaluator = event.option.value;
    this.addColleagueEvaluator(evaluator);
  }

  addColleagueEvaluator(evaluator: ExtendedColleague): void {
    this.packageService.addColleagueEvaluator(this.packageId, this.colleague.colleagueId, evaluator.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.colleagueInput.nativeElement.value = '';
        this.selectedEvaluators.push(evaluator);
        this.updateColleaguesList();
        this.colleagueCtrl.setValue(null);
        this.openSnackBar(this.msgAddColleague);
      }, error => console.log(error));
  }

  onRemoveEvaluator(colleague: ExtendedColleague): void {
    const index = this.selectedEvaluators.indexOf(colleague);

    if (index >= 0) {
      this.selectedEvaluators.splice(index, 1);
      this.colleagues.push(colleague);
      this.sortColleaguesList();
      this.removeColleagueEvaluator(colleague.id);
      this.colleagueCtrl.setValue(null);
    }
  }

  removeColleagueEvaluator(evaluatorId: number): void {
    this.packageService.removeColleagueEvaluator(this.packageId, this.colleague.colleagueId, evaluatorId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.openSnackBar(this.msgDeleteColleague);
      });
  }

  sortColleaguesList(): void {
    this.colleagues.sort((a, b) => a.firstName.localeCompare(b.firstName));
  }

  onSelectParticipant(): void {
    const newState = !this.colleague.isSelected;
    this.colleague.isSelected = newState;

    if (newState) {
      this.department.selectedCount++;
    } else {
      this.department.selectedCount--;
    }

    let allSelected = false;
    if (this.department.selectedCount === this.department.colleagues.length) {
      allSelected = true;
    }
    this.department.isAllSelected = allSelected;

    this.updateDepartmentColleague();

    this.newColleague.emit(this.colleague);
    this.newDepartment.emit(this.department);
  }

  onSendEmail(): void {
    this.questionnaireService.sendIndividualReminder(this.packageId, this.colleague.colleagueId)
      .subscribe(() => {
        this.openSnackBar(this.msgSentEmail);
      });
  }

  updateDepartmentColleague(): void {
    this.department.colleagues.forEach((colleague) => {
      if (colleague.participantId === this.colleague.participantId) {
        colleague = this.colleague;
      }
    });
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
