import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

import { switchMap, filter, take, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // private cachedRequests: Array<HttpRequest<any>> = [];

  constructor(
    private sharedService: SharedService,
    public authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, nextHandler: HttpHandler): Observable<HttpEvent<any>> {
    return nextHandler.handle(request).pipe(catchError(error => {
      let skipHandling = false;

      if (
        request.url.includes('refresh') ||
        request.url.includes('login')
      ) {
        skipHandling = true;
      }

      // console.log('INTER? ', request);

      if (error instanceof HttpErrorResponse && error.status === 401) {
        const signatureExpired = this.checkSignatureExpired(error);
        if (signatureExpired && !skipHandling) {
          // this.collectFailedRequest(request)
          return this.handle401Error(request, nextHandler);
        } else if (signatureExpired && skipHandling) {
          this.logOutAction();
          return throwError(error);
        } else {
          return throwError(error);
        }
      } else {
        // console.log('ERROR WITH INTERCEPT ', error);
        return throwError(error);
      }

    }));
  }

  private handle401Error(request: HttpRequest<any>, nextHandler: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          if (response && response.access_token) {
            const access_token = response.access_token;
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(access_token);
            const newRequest = this.updateAuthorizationHeader(request, access_token);

            return nextHandler.handle(newRequest);
          } else {
            this.refreshTokenInProgress = false;
          }

          return nextHandler.handle(request);
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(access_token => {
          const newRequest = this.updateAuthorizationHeader(request, access_token);
          return nextHandler.handle(newRequest);
        }));
    }
  }

  private logOutAction(): void {
    this.sharedService.onLogout$.emit();
  }

  private checkSignatureExpired(errorResponse: any): boolean {
    const error = errorResponse['error'];
    if (error && error.message) {
      const error_message = error.message;
      const token1 = error_message.match(/Invalid token/g);
      const token2 = error_message.match(/Signature has expired/g);
      if (token1 || token2) {
        return true;
      }
    }

    return false;
  }

  private updateAuthorizationHeader(request: any, access_token: any): any {
    const newRequest = request.clone({
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${access_token}`
      })
    });

    // console.log('new Request ', newRequest);

    return newRequest;
  }

  // private collectFailedRequest(request): void {
  //   this.cachedRequests.push(request);
  // }

  // private retryFailedRequests(): void {
  //   for (let request of this.cachedRequests) {

  //   }
  // }

}
