import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-how-to',
  templateUrl: './dialog-how-to.component.html',
  styleUrls: ['./dialog-how-to.component.scss']
})
export class DialogHowToComponent implements OnInit {
  safeURL: any;
  videoAddStandardURL: any = 'https://www.youtube.com/embed/cir1i2erHDY';
  videoEditStandardURL: any = 'https://www.youtube.com/embed/ah-7ykYj8M4';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogHowToComponent>,
    private router: Router,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.videoType) {
      const videoType = this.data.videoType;
      if (videoType === 'addStandardPackage') {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoAddStandardURL);
      } else if (videoType === 'editStandardPackage') {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoEditStandardURL);
      }
    }
  }

  onCreateQuestionnaire(): void {
    this.router.navigate(['/questionnaires/create-questionnaire']);
    this.dialogRef.close();
  }

  onAddMember(): void {
    this.router.navigate(['/colleagues/create-colleague']);
    this.dialogRef.close();
  }

  onCreateDepartment(): void {
    this.router.navigate(['/company/create-department']);
    this.dialogRef.close();
  }

  onCreateCompany(): void {
    this.router.navigate(['/company/create-company']);
    this.dialogRef.close();
  }

}
