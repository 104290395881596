<div layout="row" layout-padding>
<div flex="20"></div>

<div flex>
  <mat-card class="mat-title">
    <p class="title" i18n="@@HP-title">Welcome to TeamHeat!</p>
    <p class="title">Your company will be created in a second</p>
  </mat-card>
</div>

<div flex="20"></div>
</div>