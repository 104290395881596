import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/_services/shared.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.sharedService.notFoundMode$.emit(true);
  }

}
