import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Colleague } from 'app/_models/colleague';
import { Experiment } from 'app/_models/experiment';
import { Subject } from 'rxjs';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import {
  ExtendedParameter, ExtendedColleague, InfoParameter,
  InfoCard, CategoryInfoCard, RelativeInfoCard, WidgetFilters
} from '../_helpers/local-models';
import { Package } from 'app/_models/package';
import { SharedDashboardHelper } from '../_helpers/shared-dashboard-helper';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { Department } from 'app/_models/department';

@Component({
  selector: 'app-shared-text-card-widget',
  templateUrl: './shared-text-card-widget.component.html',
  styleUrls: ['./shared-text-card-widget.component.scss']
})
export class SharedTextCardWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;
  @Input() isPublicLink: boolean;
  @Input() isLoaded: boolean;
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();

  colleaguesCardMode: boolean = true;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;
  parameterSortMode: boolean = false;
  packagesEditMode: boolean = false;
  useRelativeMode: boolean = false;
  showParametersNames: boolean = true;
  hideEmptyCards: boolean = true;

  infoCards: InfoCard[] = [];
  filteredInfoCards: CategoryInfoCard[] = [];
  filteredRelativeCards: RelativeInfoCard[] = [];
  colleaguesList: Colleague[] = [];
  inputWidgetFilters: WidgetFilters;
  widgetFilters: WidgetFilters = {};
  experiments: Experiment[] = [];
  activeExperiment: Experiment;
  activeMultipleExperiments: Experiment[] = [];
  activeExperimentOption: any;
  sharedPackagesList: Package[] = [];
  activeColleagues: ExtendedColleague[] = [];
  activeParameters: ExtendedParameter[] = [];
  relativeParameters: ExtendedParameter[] = [];
  localDepartments: Department[] = [];
  activeDepartmentId: number = 0;
  // participants: PackageParticipant[] = [];
  activePackageOption: any;
  parameterFilter: any = null;
  parameterRelativeFilter: any = null;
  hasFilter: boolean = false;
  hasRelativeFilter: boolean = false;
  showParticipants: boolean = false;
  multiplePackagesMode: boolean = false;

  constructor(private sharedDashboardHelper: SharedDashboardHelper) { }

  ngOnInit(): void {
    this.loadSharedDashboardData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadSharedDashboardData(): void {
    this.activePackageOption = null;

    this.sharedDashboardHelper.isLoaded
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        if (result) {
          const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
          this.sharedPackagesList = sharedPackagesList;
          let promiseWidget = this.sharedDashboardHelper.getSharedDashboardWidget(this.inputWidgetId);
          // promiseParticipants = this.localDashboardHelper.getCachedParticipants();
          promiseWidget.then((widget: any) => {
            this.isLoaded = true;
            this.inputWidgetFilters = widget.widgetFilters;
            this.formInitialParameters();
            this.formInitialColleagues();
            this.formInitialExperiments();
            this.checkInputWidgets();
            this.updatePackageOption();
          }).catch((error) => {
            console.log('Widget error ', error);
          });
        }
      }, error => {
        console.log(error);
      });
  }

  formInitialExperiments(): void {
    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    if (this.activePackageOption && this.activePackageOption.id) {
      this.experiments = sharedExperiments.filter(exp => exp.packageId === this.activePackageOption.id);
    } else {
      this.experiments = sharedExperiments;
    }
  }

  formInitialParameters(): void {
    const sharedParameters = this.sharedDashboardHelper.getSharedPackageParameters();
    let filteredParameters = sharedParameters.filter(param => param.groupName === 'individual');
    // filteredParameters = filteredParameters.filter(param => param.commonValue.type === 'number');
    this.activeParameters = [];
    for (const singleParameter of filteredParameters) {
      const newParam: ExtendedParameter = {
        ...singleParameter,
        isActive: true
      }
      this.activeParameters.push(newParam);
    }
  }

  formInitialColleagues(): void {
    const sharedColleagues = this.sharedDashboardHelper.getColleagues();
    this.activeColleagues = [];
    for (const singleColleague of sharedColleagues) {
      const newColleague: ExtendedColleague = {
        ...singleColleague,
        isActive: true
      }
      this.activeColleagues.push(newColleague);
    }
  }

  setActiveParticipants(): void {
    const newActiveColleagues = [];
    const sharedParticipants = this.sharedDashboardHelper.getSharedPackageParticipants();
    for (const singleColleague of this.activeColleagues) {
      const findParticipant = sharedParticipants.find(participant => participant.colleagueId === singleColleague.id);
      if (findParticipant) {
        singleColleague.isActive = findParticipant.isSelected;
        newActiveColleagues.push(singleColleague);
      } else {
        singleColleague.isActive = false;
        newActiveColleagues.push(singleColleague);
      }
    }

    this.activeColleagues = newActiveColleagues;
  }

  updatePackageOption(): void {
    const localDepartments = this.sharedDashboardHelper.getDepartments();
    this.localDepartments = localDepartments;

    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
    if (this.activePackageOption) {
      this.multiplePackagesMode = false;
      this.activeMultipleExperiments = null;
      const packageId = this.activePackageOption.id;
      const packageExperiments = sharedExperiments.filter(exp => exp.packageId === packageId);
      this.experiments = packageExperiments;
      this.activeParameters = this.activeParameters.filter(param => param.packageId === packageId);
      if (!this.activeExperimentOption) {
        this.activeExperimentOption = 'last_research';
      }

      if (this.activeExperimentOption === 'last_research') {
        this.activeExperiment = this.getLatestPackageExperiment(packageExperiments);
      }
    } else {
      this.multiplePackagesMode = true;
      this.activeMultipleExperiments = [];
      for (const singlePackage of sharedPackagesList) {
        const packageExperiments = sharedExperiments.filter(exp => exp.packageId === singlePackage.id);
        this.experiments = [];
        this.activeExperimentOption = 'last_research';
        const lastExperiment = this.getLatestPackageExperiment(packageExperiments);
        if (lastExperiment) {
          this.activeMultipleExperiments.push(lastExperiment);
        }
      }
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    this.formColleagueCards();
  }

  setDepartmentValue(): void {
    this.formColleagueCards();
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.widgetFilters['showLatestResearch'] = true;
    this.widgetFilters['showParticipants'] = true;
    this.showParticipants = true;
    this.activeExperimentOption = 'last_research';
  }

  restoreInputWidgetFilters(): void {
    if (this.inputWidgetFilters) {
      const colleaguesIds = this.inputWidgetFilters.activeColleaguesIds;
      if (colleaguesIds) {
        for (const singleColleague of this.activeColleagues) {
          const findColleague = colleaguesIds.find(id => id === singleColleague.id);
          if (findColleague) {
            singleColleague.isActive = true;
          } else {
            singleColleague.isActive = false;
          }
        }
      }
      if (this.inputWidgetFilters.showParticipants) {
        this.showParticipants = true;
        this.formInitialColleagues();
      }

      const activeList = this.activeColleagues.filter(colleague => colleague.isActive === true);
      if (activeList.length === 0) {
        this.formInitialColleagues();
      }

      const paramsIds = this.inputWidgetFilters.activeParametersIds;
      if (paramsIds) {
        for (const singleParam of this.activeParameters) {
          const findParam = paramsIds.find(id => id === singleParam.id);
          if (findParam) {
            singleParam.isActive = true;
          } else {
            singleParam.isActive = false;
          }
        }
      }
      const activeParams = this.activeParameters.filter(param => param.isActive === true);
      if (activeParams.length === 0) {
        this.formInitialParameters();
      }

      const activePackageId = this.inputWidgetFilters.activePackageId;
      if (activePackageId && activePackageId > 0) {
        const findPackage = this.sharedPackagesList.find(pack => pack.id === activePackageId);
        if (findPackage) {
          this.activePackageOption = findPackage;
        }
      }

      const showLatestResearch = this.inputWidgetFilters.showLatestResearch;
      if (showLatestResearch) {
        const activeResearch = this.getLatestExperiment();
        if (activeResearch) {
          this.activeExperiment = activeResearch;
          this.activeExperimentOption = 'last_research';
        } else {
          this.activeExperimentOption = 'last_research';
        }
      } else {
        const activeResearchId = this.inputWidgetFilters.activeResearchId;
        if (activeResearchId) {
          const activeResearch = this.experiments.find(experiment => experiment.id === activeResearchId);
          if (activeResearch) {
            this.activeExperiment = activeResearch;
            this.activeExperimentOption = activeResearch;
          }
        }
      }

    }
  }

  widgetFiltersUpdate(): void {
    const colleaguesFilter = this.activeColleagues.filter(colleague => colleague.isActive === true);
    const activeColleaguesIds = [];
    for (const singleColleague of colleaguesFilter) {
      activeColleaguesIds.push(singleColleague.id);
    }
    this.widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

    const parametersFilter = this.activeParameters.filter(param => param.isActive === true);
    const activeParamsIds = [];
    for (const singleParam of parametersFilter) {
      activeParamsIds.push(singleParam.id);
    }

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
        this.widgetFilters['showLatestResearch'] = true;
      } else {
        this.activeExperiment = experimentOption;
        this.widgetFilters['showLatestResearch'] = false;
      }
    }

    if (this.activeExperiment) {
      this.widgetFilters['activeResearchId'] = this.activeExperiment.id;
    }

    if (this.parameterRelativeFilter) {
      this.widgetFilters['sortParameterId'] = this.parameterRelativeFilter.id;
    } else if (this.parameterFilter) {
      this.widgetFilters['sortParameterId'] = this.parameterFilter.id;
    } else {
      this.widgetFilters['sortParameterId'] = 0;
    }

    if (this.activePackageOption && this.activePackageOption.id > 0) {
      this.widgetFilters['activePackageId'] = this.activePackageOption.id;
    } else {
      this.widgetFilters['activePackageId'] = 0;
    }

    if (this.showParametersNames) {
      this.widgetFilters['showParametersNames'] = true;
    } else {
      this.widgetFilters['showParametersNames'] = false;
    }

    if (this.showParticipants) {
      this.widgetFilters['showParticipants'] = true;
    } else {
      this.widgetFilters['showParticipants'] = false;
    }

    if (this.hideEmptyCards) {
      this.widgetFilters['hideEmptyCards'] = true;
    } else {
      this.widgetFilters['hideEmptyCards'] = false;
    }

    if (!this.userView) {
      // console.log('New widget filters ', this.widgetFilters);
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  onColleaguesCardMode(): void {
    this.setAllModesFalse();
    this.colleaguesCardMode = true;
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.parametersEditMode = true;
  }

  onPackagesEditMode(): void {
    this.setAllModesFalse();
    this.packagesEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.researchesEditMode = true;
  }

  onParameterSortMode(): void {
    this.setAllModesFalse();
    this.parameterSortMode = true;
  }

  onRelativeUseMode(): void {
    this.setAllModesFalse();
    this.useRelativeMode = true;
  }

  setAllModesFalse(): void {
    this.colleaguesEditMode = false;
    this.colleaguesCardMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
    this.parameterSortMode = false;
    this.packagesEditMode = false;
    this.useRelativeMode = false;
  }

  onlyUnique(value: any, index: number, self: any): boolean {
    return self.indexOf(value) === index;
  }

  onExperimentChange(event: any): void {
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onParametersStateChange(): void {
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onParticipantsStateChange(): void {
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onParameterFilterChange(event: any): void {
    if (this.parameterFilter) {
      this.hasRelativeFilter = false;
      this.formFilteredCards();
    } else {
      this.hasFilter = false;
      this.formColleagueCards();
    }
    this.widgetFiltersUpdate();
  }

  onRelativeFilterChange(): void {
    if (this.parameterRelativeFilter) {
      this.hasFilter = false;
      this.formRelativeCards();
    } else {
      this.hasRelativeFilter = false;
      this.formColleagueCards();
    }
    this.widgetFiltersUpdate();
  }

  onPackagesFilterChange(event: any): void {
    this.widgetFiltersUpdate();
    this.activeParameters = [];
    this.hasRelativeFilter = false;
    this.hasFilter = false;
    this.formInitialColleagues();
    this.formInitialParameters();
    // this.loadExperimentInfo();
    this.updatePackageOption();
  }

  formRelativeCards(): void {
    const activeExperiments = [];
    if (this.multiplePackagesMode) {
      for (const singleExperiment of this.activeMultipleExperiments) {
        activeExperiments.push(singleExperiment);
      }
    } else if (this.activeExperiment) {
      activeExperiments.push(this.activeExperiment);
    }

    if ((activeExperiments.length > 0) && this.parameterRelativeFilter) {
      this.filteredRelativeCards = [];
      this.hasRelativeFilter = true;
      for (const singleExperiment of activeExperiments) {
        const relativeColleaguesIds = this.getRelativeColleaguesIds();
        const relativeParameterName = this.parameterRelativeFilter.visibleName;
        for (const singleId of relativeColleaguesIds) {
          const relativeColleague = this.colleaguesList.find(colleague => colleague.id === singleId);
          if (relativeColleague) {
            const infoCards = this.formRelativeInfoCards(singleExperiment, singleId);
            const relativeInfoCard: RelativeInfoCard = {
              relativeColleagueName: relativeColleague.firstName + ' ' + relativeColleague.lastName,
              relativeParameterName: relativeParameterName,
              infoCards: infoCards
            };
            this.filteredRelativeCards.push(relativeInfoCard);
          }
        }
      }

    } else {
      this.formRelativeTestCards();
    }
  }

  formRelativeInfoCards(activeExperiment: Experiment, relativeColleagueId: number): InfoCard[] {
    let infoCards: InfoCard[] = [];
    if (activeExperiment) {
      const experimentAnswers = activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        if (singleAnswer.completed) {
          // const currentColleague = this.activeColleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formRelativeInfoCardParameter(singleAnswer, relativeColleagueId);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName,
              parameters: cardParameters,
            };
            infoCards.push(infoCard);
          }
        }
      }

      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(infoCards);
        infoCards = newCards;
      }
    }

    return infoCards;
  }

  formRelativeInfoCardParameter(experimentAnswer: ExperimentAnswer, relativeColleagueId: number): InfoParameter[] {
    const filterParameter = this.parameterRelativeFilter;
    const cardParameters = [];
    const localParameters = experimentAnswer.relativeParameters.filter(param => param.relativeColleagueId === relativeColleagueId);
    if (localParameters && filterParameter && filterParameter.isActive) {
      const resultParameter = localParameters.find(param => param.packageParameterId === filterParameter.id);
      if (resultParameter) {
        if (filterParameter.commonValue.type === 'text') {
          const convertedText = this.convertTextParameter(resultParameter.textValue);
          const newParameter: InfoParameter = {
            name: filterParameter.visibleName,
            value: convertedText
          };
          cardParameters.push(newParameter);
        } else {
          const newParameter: InfoParameter = {
            name: filterParameter.visibleName,
            value: resultParameter.value
          };
          cardParameters.push(newParameter);
        }
      }
    }

    return cardParameters;
  }

  getRelativeColleaguesIds(): number[] {
    const relativeColleaguesIds = [];
    const relativeId = this.parameterRelativeFilter.id;
    const experimentAnswers = this.activeExperiment.experimentAnswers;
    for (const singleAnswer of experimentAnswers) {
      const relativeParam = singleAnswer.relativeParameters.find(param => param.packageParameterId === relativeId);
      if (relativeParam) {
        relativeColleaguesIds.push(relativeParam.relativeColleagueId);
      }
    }
    const uniqueIds = relativeColleaguesIds.filter(this.onlyUnique);
    return uniqueIds;
  }

  formRelativeTestCards(): void {
    // TODO
  }

  formFilteredCards(): void {
    const activeExperiments = [];
    if (this.multiplePackagesMode) {
      for (const singleExperiment of this.activeMultipleExperiments) {
        activeExperiments.push(singleExperiment);
      }
    } else if (this.activeExperiment) {
      activeExperiments.push(this.activeExperiment);
    }

    if (activeExperiments.length > 0) {
      const paramVariants = this.getParameterVariants();
      const filteredParams = paramVariants.filter(this.onlyUnique);
      // this.infoCards = [];
      this.filteredInfoCards = [];
      this.hasFilter = true;

      for (const singleExperiment of activeExperiments) {
        for (const singleParameterValue of filteredParams) {
          const infoCards = this.formFilteredInfoCards(singleExperiment, singleParameterValue);
          const categoryInfoCard: CategoryInfoCard = {
            filteredParameterValue: singleParameterValue,
            infoCards: infoCards
          };

          this.filteredInfoCards.push(categoryInfoCard);
        }
      }
    } else {
      this.formFilteredTestCards();
    }
  }

  formFilteredTestCards(): void {
    const filteredParams = ['Test data #1', 'Test data #2'];
    this.filteredInfoCards = [];
    this.hasFilter = true;

    for (const singleParameterValue of filteredParams) {
      const infoCards = [];
      for (let i = 1; i < 4; i++) {
        const localParameters = [];
        for (const singleParam of this.activeParameters) {
          const newParameter: InfoParameter = {
            name: singleParam.visibleName,
            value: 'Test data'
          };
          localParameters.push(newParameter);
        }
        const infoCard: InfoCard = {
          fullName: 'Colleague #' + i,
          parameters: localParameters,
        };
        infoCards.push(infoCard);
      }
      const categoryInfoCard: CategoryInfoCard = {
        filteredParameterValue: singleParameterValue,
        infoCards: infoCards
      };

      this.filteredInfoCards.push(categoryInfoCard);
    }
  }

  formFilteredInfoCards(activeExperiment: Experiment, parameterValue: any): InfoCard[] {
    let infoCards: InfoCard[] = [];
    if (activeExperiment) {
      const experimentAnswers = activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        const isFiltered = this.checkAnswerForFiltered(singleAnswer, parameterValue);
        if (isFiltered && singleAnswer.completed) {
          // const currentColleague = this.activeColleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParameters(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName,
              parameters: cardParameters,
            };
            infoCards.push(infoCard);
          }
        }
      }

      const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
      for (const singleAnswer of relativeAnswers) {
        const isFiltered = this.checkAnswerForFilteredRelative(singleAnswer, parameterValue);
        if (isFiltered && singleAnswer.calculated) {
          const currentColleague = this.activeColleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParametersRelative(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName + ' (relative)',
              parameters: cardParameters,
            };
            infoCards.push(infoCard);
          }
        }
      }

      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(infoCards);
        infoCards = newCards;
      }
    }

    return infoCards;
  }

  checkAnswerForFiltered(experimentAnswer: ExperimentAnswer, parameterValue: any): boolean {
    const colleagueParams = experimentAnswer.colleagueParameters;
    const filterParamId = this.parameterFilter.id;
    const findParam = colleagueParams.find(param => param.packageParameterId === filterParamId);
    if (findParam) {
      if (findParam.value === parameterValue) {
        return true;
      } else if (findParam.textValue === parameterValue) {
        return true;
      }
    }

    return false;
  }

  checkAnswerForFilteredRelative(relativeAnswer: RelativeExperimentAnswer, parameterValue: any): boolean {
    const colleagueParams = relativeAnswer.relativeExperimentParameters;
    const filterParamId = this.parameterFilter.id;
    const findParam = colleagueParams.find(param => param.packageParameterId === filterParamId);
    if (findParam) {
      if (findParam.value === parameterValue) {
        return true;
      } else if (findParam.textValue === parameterValue) {
        return true;
      }
    }

    return false;
  }

  formColleagueCards(): void {
    if (this.hasFilter) {
      this.formFilteredCards();
    } else if (this.hasRelativeFilter) {
      this.formRelativeCards();
    } else {
      this.formColleagueSimpleCards();
    }
  }

  formColleagueSimpleCards(): void {
    const activeExperiments = [];
    if (this.multiplePackagesMode) {
      for (const singleExperiment of this.activeMultipleExperiments) {
        activeExperiments.push(singleExperiment);
      }
    } else if (this.activeExperiment) {
      activeExperiments.push(this.activeExperiment);
    }

    if (activeExperiments.length > 0) {
      this.infoCards = [];
      for (const singleExperiment of activeExperiments) {
        const experimentAnswers = singleExperiment.experimentAnswers;
        for (const singleAnswer of experimentAnswers) {
          if (singleAnswer.completed) {
            const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
            if (currentColleague) {
              const cardParameters = this.formInfoCardParameters(singleAnswer);
              const infoCard: InfoCard = {
                fullName: currentColleague.firstName + ' ' + currentColleague.lastName,
                parameters: cardParameters,
              };
              this.infoCards.push(infoCard);
            }
          }
        }

        if (this.activeExperiment) {
          const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
          for (const singleAnswer of relativeAnswers) {
            if (singleAnswer.calculated) {
              const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
              if (currentColleague) {
                const cardParameters = this.formInfoCardParametersRelative(singleAnswer);
                const infoCard: InfoCard = {
                  fullName: currentColleague.firstName + ' ' + currentColleague.lastName + ' (relative)',
                  parameters: cardParameters,
                };
                this.infoCards.push(infoCard);
              }
            }
          }
        }

      }
      // console.log('info cards ', this.infoCards);
      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(this.infoCards);
        this.infoCards = newCards;
      }

    } else {
      this.formTestColleagueSimpleCards();
    }

  }

  formTestColleagueSimpleCards(): void {
    this.infoCards = [];
    for (let i = 1; i < 4; i++) {
      const localParameters = [];
      for (const singleParam of this.activeParameters) {
        const newParameter: InfoParameter = {
          name: singleParam.visibleName,
          value: 'Test data'
        };
        localParameters.push(newParameter);
      }
      const infoCard: InfoCard = {
        fullName: 'Colleague #' + i,
        parameters: localParameters,
      };
      this.infoCards.push(infoCard);
    }
  }

  getActiveColleague(colleagueId: number): ExtendedColleague {
    let currentColleague = null;
    if (colleagueId && colleagueId > 0) {
      if (this.activeDepartmentId && this.activeDepartmentId > 0) {
        const departmentId = Number(this.activeDepartmentId);
        const filteredColleagues = this.activeColleagues.filter(colleague => colleague.departmentId === departmentId);
        currentColleague = filteredColleagues.find(colleague => colleague.id === colleagueId);
      } else {
        currentColleague = this.activeColleagues.find(colleague => colleague.id === colleagueId);
      }
    }

    if (this.showParticipants) {
      return currentColleague;
    } else {
      if (currentColleague && currentColleague.isActive) {
        return currentColleague;
      }
    }
  }

  removeEmptyCards(inputCards: InfoCard[]): InfoCard[] {
    const newCards = [];
    for (const singleCard of inputCards) {
      let isCardEmpty = true;
      for (const singleParameter of singleCard.parameters) {
        const isParamEmpty = this.checkIfParameterEmpty(singleParameter);
        if (!isParamEmpty) {
          isCardEmpty = false;
        }
      }

      if (!isCardEmpty) {
        newCards.push(singleCard);
      }
    }
    // this.infoCards = newCards;
    return newCards;
  }

  checkIfParameterEmpty(parameter: any): boolean {
    // console.log('Parameter value ', parameter);
    const parameterValue = parameter.value;
    if ((parameterValue === 0) || (parameterValue === '')) {
      return true;
    } else {
      return false;
    }
  }

  formInfoCardParameters(experimentAnswer: ExperimentAnswer): InfoParameter[] {
    const cardParameters = [];
    const localParameters = experimentAnswer.colleagueParameters;
    for (const singleParameter of localParameters) {
      const packageParameter = this.activeParameters.find(param => param.id === singleParameter.packageParameterId);
      if (packageParameter && packageParameter.isActive) {
        if (packageParameter.commonValue.type === 'text') {
          const convertedText = this.convertTextParameter(singleParameter.textValue);
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: convertedText
          };
          cardParameters.push(newParameter);
        } else {
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: singleParameter.value
          };
          cardParameters.push(newParameter);
        }
      }
    }

    return cardParameters;
  }

  formInfoCardParametersRelative(relativeAnswer: RelativeExperimentAnswer): InfoParameter[] {
    const cardParameters = [];
    const localParameters = relativeAnswer.relativeExperimentParameters;
    for (const singleParameter of localParameters) {
      const packageParameter = this.activeParameters.find(param => param.id === singleParameter.packageParameterId);
      if (packageParameter && packageParameter.isActive) {
        if (packageParameter.commonValue.type === 'text') {
          const convertedText = this.convertTextParameter(singleParameter.textValue);
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: convertedText
          };
          cardParameters.push(newParameter);
        } else {
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: singleParameter.value
          };
          cardParameters.push(newParameter);
        }
      }
    }

    return cardParameters;
  }

  getParameterVariants(): any {
    const parameterVariants = [];
    if (this.activeExperiment && this.parameterFilter) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        if (singleAnswer.completed) {
          const filterId = this.parameterFilter.id;
          const filteredParameters = singleAnswer.colleagueParameters.filter(param => param.packageParameterId === filterId);
          for (const singleParameter of filteredParameters) {
            const packageParameter = this.activeParameters.find(param => param.id === singleParameter.packageParameterId);
            if (packageParameter.commonValue.type === 'text') {
              const convertedText = this.convertTextParameter(singleParameter.textValue);
              parameterVariants.push(convertedText);
            } else {
              parameterVariants.push(singleParameter.value);
            }
          }
        }
      }
    }
    return parameterVariants;
  }

  convertTextParameter(inputText: string): string {
    if (inputText) {
      const newValue = String(inputText).replace(/(\r\n|\n|\r)/gm, '<br />');
      return newValue;
    } else {
      return '';
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  getLatestPackageExperiment(packageExperiments: Experiment[]): Experiment {
    if (packageExperiments) {
      const sortedExperimens = packageExperiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

}
