import { ParameterData } from 'app/_models/overmind_ds/parameter-data';
import { ApiParameterData } from 'app/_api_models/overmind_ds/api-parameter-data';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

export function parseApiParameterData(apiResponse: any): ParameterData {
  let parameterData;

  if (environment.production) {
    parameterData = apiResponse;
  } else {
    parameterData = ApiParameterData.check(apiResponse);
  }

  const newParameterData: ParameterData = {
    id: parameterData.id,
    parameterUuid: parameterData.parameter_uuid,
    authorUuid: parameterData.author_uuid,
    entityUuid: parameterData.entity_uuid,
    sourceUuid: parameterData.source_uuid,
    value: parameterData.value,
    data: parameterData.data,
    createdAt: (moment(parameterData.created_at)).format('YYYY-MM-DD'),
    updatedAt: parameterData.updatedAt
  };

  return newParameterData;
}
