import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

import { User } from 'app/_models/user';
import { Package } from 'app/_models/package';
import { Experiment } from 'app/_models/experiment';
import { Questionnaire } from 'app/_models/questionnaire';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';

import { UserService } from 'app/_services/user.service';
import { PackageService } from 'app/_services/package.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedUserService } from 'app/_services/shared-user.service';
import { SharedPackageService } from 'app/_services/shared-package.service';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss'],
})
export class PackageDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  currentCompanyId: number = 0;
  currentPackageId: number = 0;
  currentPackageName: string = '';
  selectedTabId: number = 0;
  isAdmin: boolean = false;
  isDirectLink: boolean = false;

  tabs: any = [
    {id: 1, name: 'Dashboard'},
    {id: 2, name: 'Questions'},
    {id: 3, name: 'Participants'},
    {id: 4, name: 'Researches'},
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
    private packageService: PackageService,
    private authService: AuthenticationService,
    private experimentService: ExperimentService,
    private questionnaireService: QuestionnaireService,
    private sharedService: SharedService,
    private sharedUserService: SharedUserService,
    private sharedPackageService: SharedPackageService,
  ) { }

  ngOnInit(): void {
    this.sharedPackageService.clearSharedService();
    this.sharedPackageService.isStandardSet$.next(false);

    this.onSettingsUpdate();
    this.getUserInfo();
  }

  ngAfterViewChecked(): void {
    this.setActiveTab();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.sharedPackageService.clearSharedService();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserInfo(): void {
    this.sharedUserService.currentUser$
      .pipe(
        filter(user => user !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((user: User) => {
        this.isAdmin = user.admin;
        this.currentCompanyId = user.activeCompanyId;
        this.getCurrentPackageId();
      }, error => {
        console.log(error);
      });
  }

  getPackageInfo(packageId: number): void {
    this.packageService.getPackage(packageId)
      .pipe(
        filter(packageInfo => packageInfo && packageInfo.questionnaire !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((packageInfo: Package) => {
        const isPublic = packageInfo.isPublic;
        const companyId = packageInfo.companyId;

        if (!companyId && isPublic) {
          this.router.navigate(['/questionnaires']);
          return;
        }

        this.currentPackageName = packageInfo.name;
        this.sharedPackageService.updateSharedPackageInfo(packageInfo);
        const packageCompanyId = this.sharedPackageService.packageCompanyId$.getValue();
        const currentCompanyId = this.currentCompanyId;

        if (currentCompanyId !== packageCompanyId && this.isAdmin) {
          this.isDirectLink = true;
          return;
        } else if (currentCompanyId !== packageCompanyId && !this.isAdmin) {
          this.router.navigate(['/not-found']);
          return;
        }

        const questionnaire = packageInfo.questionnaire;
        this.getQuestionnaire(questionnaire.id);
        this.addLocalPQActions(packageInfo.postQuestionnaireActions);
      }, error => {
        if (error['status'] === 403) {
          this.router.navigate(['/questionnaires']);
        } else if (error['status'] === 404) {
          this.sharedService.notFoundMode$.emit(true);
          this.router.navigate(['/not-found']);
        }
        console.log('Package info', error);
        this.sharedService.checkSignatureExpired(error);
      });
  }

  getQuestionnaire(questionnaireId: number): void {
    this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((questionnaire: Questionnaire) => {
        questionnaire.questionnaireCards.sort(function (a: any, b: any): number {
          return a.orderPosition - b.orderPosition;
        });

        this.sharedPackageService.currentQuestionnaireId$.next(questionnaire.id);
        this.sharedPackageService.questionnaireDetails$.next(questionnaire);
        this.sharedPackageService.questionnaireCards$.next(questionnaire.questionnaireCards);

        if (questionnaire.experiments.length === 0) {
          this.sharedPackageService.packageBootstrapped$.next(true);
        } else {
          this.getVerboseExperiments(questionnaire.experiments);
        }
      }, error => {
        console.log('Questionnaire error ', error);
      });
  }

  getVerboseExperiments(experiments: any[]): void {
    this.experimentService.getVerboseExperiments(experiments)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: Experiment[])=> {
        this.sharedPackageService.updateExperiments(result);
        this.sharedPackageService.packageBootstrapped$.next(true);
        this.formTabExperiments(result);
      }, error => console.log(error));
  }


  formTabExperiments(experiments: Experiment[]): void {
    const publicExperiments: any[] = [];
    const tempPublicExperiments = [];

    for (const experiment of experiments) {
      tempPublicExperiments.push({
        ...experiment,
        dateForSorting: new Date(experiment.startDate)
      });
    }

    tempPublicExperiments.sort(function (a: any, b: any): number {
      return b.dateForSorting.valueOf() - a.dateForSorting.valueOf();
    });

    let counter = tempPublicExperiments.length - 1;
    for (const experiment of tempPublicExperiments) {
      publicExperiments.push({
        ...experiment,
        splittedStartDate: experiment.startDate.split('T')[0],
        counter: counter
      });
      counter = counter - 1;
    }

    this.sharedPackageService.packageTabExperiments$.next(publicExperiments);
  }

  addLocalPQActions(pqActions: PostQuestionnaireAction[]): void {
    const localPQActions: PostQuestionnaireAction[] = [];

    for (const singleAction of pqActions) {
      localPQActions.push(singleAction);
    }

    this.sharedPackageService.postQuestionnaireActions$.next(localPQActions);
  }

  backToQuestionnairesList(): void {
    this.router.navigateByUrl('/questionnaires');
  }

  editDashboardConfig(): void {
    const packageId = this.currentPackageId;
    this.router.navigateByUrl(`/questionnaires/${packageId}/dashboard-editor`);
  }

  setActiveTab(): void {
    const packId = this.currentPackageId;
    if (this.router.url.indexOf(`/questionnaires/${packId}/dashboard`) > -1) {
      this.selectedTabId = 0;
    } else if (this.router.url.indexOf(`/questionnaires/${packId}/questions`) > -1) {
      this.selectedTabId = 1;
    } else if (this.router.url.indexOf(`/questionnaires/${packId}/participants`) > -1) {
      this.selectedTabId = 2;
    } else if (this.router.url.indexOf(`/questionnaires/${packId}/researches`) > -1) {
      this.selectedTabId = 3;
    } else if (this.router.url.indexOf(`/questionnaires/${packId}/settings`) > -1) {
      this.selectedTabId = 4;
    }

    this.sharedService.currentTabId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tabId) => {
        this.selectedTabId = tabId;
      });
  }

  changeUrl(tabValue: any): void {
    const packId = this.currentPackageId;
    if (!this.isDirectLink) {
      if (tabValue === 0) {
        this.router.navigateByUrl(`/questionnaires/${packId}/dashboard`);
      } else if (tabValue === 1) {
        this.router.navigateByUrl(`/questionnaires/${packId}/questions`);
      } else if (tabValue === 2) {
        this.router.navigateByUrl(`/questionnaires/${packId}/participants`);
      } else if (tabValue === 3) {
        this.router.navigateByUrl(`/questionnaires/${packId}/researches`);
      } else if (tabValue === 4) {
        this.router.navigateByUrl(`/questionnaires/${packId}/settings`);
      }
    }
  }

  getCurrentPackageId(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((routeParams: any) => {
        const packageId = routeParams['package_id'];
        if (packageId) {
          this.currentPackageId = packageId;
          this.getPackageInfo(packageId);
        }
      });
  }

  onSettingsUpdate(): void {
    this.sharedPackageService.onSettingsUpdate$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getPackageInfo(this.currentPackageId);
      });
  }

  makeCompanyActive(): void {
    const companyId = this.sharedPackageService.packageCompanyId$.getValue();
    this.userService.setActiveCompany(companyId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        location.reload();
      }, error => {
        console.log(error);
      });
  }
}
