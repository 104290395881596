import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'app/_services/user.service';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss']
})
export class SendFeedbackComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  mailTheme: string = '';
  mailDescription: string = '';

  constructor(
    private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClose(): void {
    this.router.navigate(['/company']);
  }

  sendMail(): void {
    const theme = this.mailTheme;
    const description = this.mailDescription;

    if (theme.length > 4 && description.length > 4) {
      this.userService.sendFeedback(theme, description)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.router.navigate(['/company']);
      },
      error => {
        console.log(error);
      });
    }
  }

}
