// NOTE: TypeScript cannot statically verify that the fetched data is of the right type

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { parseApiDashboardConfig } from 'app/_api_validation/parse-api-dashboard-config';
import { parseApiDashboardWidget } from 'app/_api_validation/parse-api-dashboard-widget';
import { parseApiSharedDashboard } from 'app/_api_validation/parse-api-shared-dashboard';
import { parseApiSharedDashboardWidget } from 'app/_api_validation/parse-api-shared-dashboard-widget';

import { DashboardConfig } from 'app/_models/dashboard-config';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { AuthenticationService } from './authentication.service';

import { environment } from '../../environments/environment';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private dashboardConfigsUrl: string = environment.API_ENDPOINT + '/v1/dashboard_configs';
  private dashboardWidgetsUrl: string = environment.API_ENDPOINT + '/v1/dashboard_widgets';
  private addDasboardConfigByPackage: string = environment.API_ENDPOINT + '/v1/add_dashboard_config/';
  private sharedDashboardsUrl: string = environment.API_ENDPOINT + '/v1/shared_dashboards';
  private sharedDashboardPackagesUrl: string = environment.API_ENDPOINT + '/v1/shared_dashboard_packages';
  private sharedDashboardWidgetsUrl: string = environment.API_ENDPOINT + '/v1/shared_dashboard_widgets';
  private publicSharedDashboard: string = environment.API_ENDPOINT + '/v1/public_shared_dashboard';

  constructor(private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  getSharedDashboards(): Observable<SharedDashboard[]> {
    const url = `${this.sharedDashboardsUrl}`;
    return this.http
      .get<SharedDashboard[]>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseMultipleSharedDashboards(response.data);
        }),
        catchError(this.handleError)
      );
  }

  getPublicDashboard(token: string): Observable<SharedDashboard> {
    const url = `${this.publicSharedDashboard}/${token}`;
    return this.http
      .get<SharedDashboard>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (response) {
            const attributes = response.data.attributes;
            const included = response.included;
            return this.parseSharedDashboard(attributes, included);
          } else {
            return response;
          }
        }),
        catchError(this.handleError)
      );
  }

  getSharedDashboard(id: number): Observable<SharedDashboard> {
    const url = `${this.sharedDashboardsUrl}/${id}`;
    return this.http
      .get<SharedDashboard>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseSharedDashboard(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addSharedDashboardPackage(packageId: number, sharedDashboardId: number): Observable<any> {
    const url = `${this.sharedDashboardPackagesUrl}`;
    const item = JSON.stringify({
      package_id: packageId,
      shared_dashboard_id: sharedDashboardId
    });

    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  removeSharedDashboardPackage(sharedDashboardPackageId: number): Observable<any> {
    const url = `${this.sharedDashboardPackagesUrl}/${sharedDashboardPackageId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  addSharedDashboard(companyId: number, dashboardName: string): Observable<SharedDashboard> {
    const url = `${this.sharedDashboardsUrl}`;
    const item = JSON.stringify({
      company_id: companyId,
      dashboard_name: dashboardName,
      is_public: false,
    });

    return this.http
      .post<SharedDashboard>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseSharedDashboard(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  updateSharedDashboard(dashboardInfo: any): Observable<SharedDashboard> {
    const url = `${this.sharedDashboardsUrl}/${dashboardInfo.id}`;
    const newFields = JSON.stringify({
      dashboard_name: dashboardInfo.dashboardName,
      is_public: dashboardInfo.isPublic,
      public_link: dashboardInfo.publicLink
    });

    return this.http
      .put<SharedDashboard>(url, newFields, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseSharedDashboard(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  removeSharedDashboard(dashboardId: number): Observable<any> {
    const url = `${this.sharedDashboardsUrl}/${dashboardId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getDashboardConfig(id: number): Observable<DashboardConfig> {
    const url = `${this.dashboardConfigsUrl}/${id}`;
    return this.http
      .get<DashboardConfig>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseDashboardConfig(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addDashboardConfig(packageId: number): Observable<DashboardConfig> {
    const url = `${this.addDasboardConfigByPackage}/${packageId}`;
    const item = JSON.stringify({
      package_id: packageId
    });

    return this.http
      .post<DashboardConfig>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parseDashboardConfig(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getDashboardWidget(id: number): Observable<DashboardWidget> {
    const url = `${this.dashboardWidgetsUrl}/${id}`;
    return this.http
      .get<DashboardWidget>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseDashboardWidget(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  formFilterItem(widgetInfo: DashboardWidget | SharedDashboardWidget, isSharedDashboard: boolean): any {
    const plotLines = [];
    if (widgetInfo.widgetFilters.plotLines) {
      for (const singleLine of widgetInfo.widgetFilters.plotLines) {
        plotLines.push(JSON.stringify(singleLine));
      }
    }
    const heatmapParameters = [];
    if (widgetInfo.widgetFilters.heatmapParameters) {
      for (const singleParameter of widgetInfo.widgetFilters.heatmapParameters) {
        heatmapParameters.push(JSON.stringify(singleParameter));
      }
    }
    const filterItem = {
      active_colleagues_ids: widgetInfo.widgetFilters.activeColleaguesIds,
      active_parameters_ids: widgetInfo.widgetFilters.activeParametersIds,
      active_research_id: widgetInfo.widgetFilters.activeResearchId,
      sort_parameter_id: widgetInfo.widgetFilters.sortParameterId,
      show_parameters_names: widgetInfo.widgetFilters.showParametersNames,
      show_latest_research: widgetInfo.widgetFilters.showLatestResearch,
      hide_empty_cards: widgetInfo.widgetFilters.hideEmptyCards,
      show_participants: widgetInfo.widgetFilters.showParticipants,
      plot_lines: plotLines,
      heatmap_parameters: heatmapParameters
    };
    if (isSharedDashboard) {
      filterItem['active_package_id'] = widgetInfo.widgetFilters.activePackageId;
    }
    return filterItem;
  }

  formApiWidgetModel(widgetInfo: any, filterItem: any, isSharedDashboard: boolean): any {
    const item = {
      order_position: widgetInfo.orderPosition,
      widget_name: widgetInfo.widgetName,
      widget_title: widgetInfo.widgetTitle,
      widget_filters: filterItem
    };

    if (isSharedDashboard) {
      item['shared_dashboard_id'] = widgetInfo.sharedDashboardId;
    } else {
      item['dashboard_config_id'] = widgetInfo.dashboardConfigId;
    }

    const modelItem = JSON.stringify(item);

    // console.log('Api model ', modelItem);

    return modelItem;
  }

  addDashboardWidget(widgetInfo: DashboardWidget): Observable<DashboardWidget> {
    const url = this.dashboardWidgetsUrl;
    const filterItem = this.formFilterItem(widgetInfo, false);
    const item = this.formApiWidgetModel(widgetInfo, filterItem, false);

    return this.http
      .post<DashboardWidget>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseDashboardWidget(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  addSharedDashboardWidget(widgetInfo: SharedDashboardWidget): Observable<SharedDashboardWidget> {
    const url = this.sharedDashboardWidgetsUrl;
    const filterItem = this.formFilterItem(widgetInfo, true);
    const item = this.formApiWidgetModel(widgetInfo, filterItem, true);

    return this.http
      .post<SharedDashboardWidget>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseSharedDashboardWidget(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateDashboardWidget(widgetInfo: DashboardWidget): Observable<DashboardWidget> {
    const url = `${this.dashboardWidgetsUrl}/${widgetInfo.id}`;
    const filterItem = this.formFilterItem(widgetInfo, false);
    const newFields = this.formApiWidgetModel(widgetInfo, filterItem, false);

    return this.http
      .put<DashboardWidget>(url, newFields, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseDashboardWidget(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateSharedDashboardWidget(widgetInfo: SharedDashboardWidget): Observable<SharedDashboardWidget> {
    const url = `${this.sharedDashboardWidgetsUrl}/${widgetInfo.id}`;
    const filterItem = this.formFilterItem(widgetInfo, true);
    const newFields = this.formApiWidgetModel(widgetInfo, filterItem, true);

    return this.http
      .put<SharedDashboardWidget>(url, newFields, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseSharedDashboardWidget(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeDashboardWidget(widgetInfo: DashboardWidget): Observable<any> {
    const url = `${this.dashboardWidgetsUrl}/${widgetInfo.id}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  removeSharedDashboardWidget(widgetInfo: SharedDashboardWidget): Observable<any> {
    const url = `${this.sharedDashboardWidgetsUrl}/${widgetInfo.id}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  private parseDashboardConfig(apiResponse: any, included: any): DashboardConfig {
    return parseApiDashboardConfig(apiResponse, true, included);
  }

  private parseDashboardWidget(apiResponse: any): DashboardWidget {
    return parseApiDashboardWidget(apiResponse);
  }

  private parseSharedDashboardWidget(apiResponse: any): SharedDashboardWidget {
    return parseApiSharedDashboardWidget(apiResponse);
  }

  private parseSharedDashboard(apiResponse: any, included: any): SharedDashboard {
    return parseApiSharedDashboard(apiResponse, true, included);
  }

  private parseMultipleSharedDashboards(apiResponse: any): SharedDashboard[] {
    const mappedData = apiResponse.map(x => this.parseSharedDashboard(x.attributes, []) as SharedDashboard);
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error); // for demo purposes only
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }
}
