export class EnvTypeConfig {
  readonly type: EnvType.TYPE;

  constructor(type: EnvType.TYPE) {
    this.type = type;
  }

  isStaging(): boolean {
    return this.type === EnvType.TYPE.STAGING;
  }

  isProduction(): boolean {
    return this.type === EnvType.TYPE.PRODUCTION;
  }

  isDevelopment(): boolean {
    return this.type === EnvType.TYPE.DEV;
  }
}

export namespace EnvType {
  export enum TYPE {
    DEV = 'dev',
    STAGING = 'staging',
    PRODUCTION = 'production'
  }
}
