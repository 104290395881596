<mat-dialog-content>
  <ng-container *ngIf="isConfirmDelete">
    <h2 matDialogTitle class="title-class" i18n="@@deleteTitle">{{dialogTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isConfirmPause">
    <h2 matDialogTitle class="title-class" i18n="@@pauseTitle">{{dialogTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isConfirmArchive">
    <h2 matDialogTitle class="title-class" i18n="@@archiveTitle">{{dialogTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isConfirmReminder">
    <h2 matDialogTitle class="title-class" i18n="@@reminderTitle">{{dialogTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isConfirmRestore">
    <h2 matDialogTitle class="title-class" i18n="@@restoreTitle">{{dialogTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isConfirmResearch">
    <h2 matDialogTitle class="title-class" i18n="@@researchTitle">{{dialogTitle}}</h2>
  </ng-container>
</mat-dialog-content>

<mat-dialog-content>
  <ng-container *ngIf="isConfirmDelete">
    <p i18n="@@delete-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmPause">
    <p i18n="@@pause-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmArchive">
    <p i18n="@@archive-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmReminder">
    <p i18n="@@reminder-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmRestore">
    <p i18n="@@restore-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isConfirmResearch">
    <p i18n="@@research-confirmationMessage">{{dialogSubtitle}}</p>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="isConfirmDelete">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@deleteButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmPause">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@pauseButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmArchive">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@archiveButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmReminder">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@reminderButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmRestore">
    <button mat-raised-button matDialogClose="confirm" color="accent" i18n="@@restoreButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <ng-container *ngIf="isConfirmResearch">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@removeButton">
      {{dialogConfirmText}}
    </button>
  </ng-container>
  <button mat-raised-button matDialogClose="cancel" color="primary" i18n="@@cancelButton">Cancel</button>
</mat-dialog-actions>
