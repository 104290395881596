import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { takeUntil } from 'rxjs/operators';
import { Experiment } from 'app/_models/experiment';

@Component({
  selector: 'app-material-excel-table',
  templateUrl: './material-excel-table.component.html',
  styleUrls: ['./material-excel-table.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class MaterialExcelTableComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  displayColumnsNames: any = [];
  displayColumnsLabels: any = {};
  dataSource: MatTableDataSource<any>;
  expandedRow: any | null;
  @Input() inputRows: any;
  @Input() inputColumns: any;
  @Input() isVerboseMode: boolean;
  tableColumns: any[] = [];
  _inputColumns: any;
  rowsData: any;
  verboseMode: boolean = false;

  constructor(private sharedPackageService: SharedPackageService) {
  }

  onCellClick(cell: any): void {
    // console.log('On cell click ', cell);
  }

  ngOnInit(): void {
    // this.sharedPackageService.packageExperimentsVerbose
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((resultExperiments: Experiment[]) => {
    //     console.log('Result experiments ', resultExperiments);
    //   });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputColumns']) {
      const inputColumns = changes['inputColumns']['currentValue'];
      this._inputColumns = inputColumns;
      this.formDatatableColumns(inputColumns);
    }

    if (changes['inputRows']) {
      const rowsData = changes['inputRows']['currentValue'];
      this.rowsData = rowsData;
      const rows = this.formRowsData();
      this.dataSource = rows;
    }
  }

  formRowsData(): any {
    if (this.isVerboseMode) {
      return this.rowsData;
    } else {
      if (this.rowsData.length > 20) {
        return this.rowsData.slice(0, 20);
      } else {
        return this.rowsData;
      }
    }
  }

  loadMore(): void {
    this.sharedPackageService.onLoadAllExperiments$.emit();
    // this.verboseMode = true;
    // const rows = this.formRowsData();
    // this.dataSource = rows;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  formDatatableColumns(inputColumns: any): void {
    const newColumns = [];
    const newDisplayedNames = [];
    for (const singleColumn of inputColumns) {
      const column = { name: singleColumn.prop, label: singleColumn.name };
      newColumns.push(column);
      newDisplayedNames.push(singleColumn.prop);
      this.displayColumnsLabels[singleColumn.prop] = singleColumn.name;
    }
    this.tableColumns = [...newColumns];
    this.displayColumnsNames = [...newDisplayedNames];
  }

  getRowClass(row: any): any {
    if (row.isSummary) {
      return 'row-color-summary';
    } else if (row.isEven) {
      return 'row-color-even';
    }
  }

}
