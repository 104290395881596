<div class="warning-message not-found">
  <h1 class="block-number">
    404
  </h1>
  <div class="block-text">
    <h1 class="text-1-level" i18n="@@404-title">
      This page is not found
    </h1>
    <p class="text-2-level">
      <span i18n="@@404-body-1part">You can go to the </span>
      <a routerLink="/company" i18n="@@404-body-2part">Home </a>
      <span i18n="@@404-body-3part">page or to the </span>
      <a routerLink="/questionnaires" i18n="@@404-body-4part">Questionnaire </a>
      <span i18n="@@404-body-5part">page to continue your investigations.</span>
    </p>
  </div>

</div>