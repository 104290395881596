<div *ngIf="activeDashboard&&isBootstrapped" class="style-dashboard-colleague">
  <app-shared-dashboard-user-view 
    [inputDashboard]="activeDashboard"
    [isPublicLink]="true"
    [sharedQuestionnaires]="sharedQuestionnaires"
    [sharedPackageParameters]="sharedPackageParameters"
    [sharedPackageParticipants]="sharedPackageParticipants"
    >
  </app-shared-dashboard-user-view>
</div>
<div *ngIf="validationFailed" class="style-dashboard-colleague">
  VALIDATION FAILED
</div>