import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { SharedPackageService } from 'app/_services/shared-package.service';
import { CompanyService } from 'app/_services/company.service';
import { PackageParameter } from 'app/_models/package-parameter';
import { takeUntil } from 'rxjs/operators';
import { Experiment } from 'app/_models/experiment';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { ExtendedParameter, ExtendedColleague } from './local-models';
import { SharedService } from 'app/_services/shared.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { Package } from 'app/_models/package';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { PackageParticipant } from 'app/_models/package-participant';
import { CopyLocalExperimentHelper } from 'app/_helpers/copy-local-experiment';

@Injectable()
export class LocalDashboardHelper implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  sharedQuestionnaires: Questionnaire[] = [];
  sharedPackageParameters: PackageParameter[] = [];
  sharedPackagesList: Package[] = [];
  sharedDashboardWidgets: SharedDashboardWidget[] = [];
  sharedCompanyId: number = 0;

  constructor(
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
    private dashboardService: DashboardService,
    private companyService: CompanyService,
    private packageService: PackageService,
    private experimentService: ExperimentService,
    private questionnaireService: QuestionnaireService,
    private copyExperimentHelper: CopyLocalExperimentHelper
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  loadSingleExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperimentVerbose(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultExp: Experiment) => {
          // console.log('Load single experiment verbose ', resultExp);
          resolve(resultExp);
        }, error => {
          reject(error);
        });
    });
  }

  getColleagues(isSharedMode: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      let companyId = this.sharedService.currentCompanyId.getValue();
      if (isSharedMode && (companyId === 0)) {
        companyId = this.sharedCompanyId;
      }
      if (companyId && companyId > 0) {
        this.companyService.getCompanyColleagues(companyId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((colleagues: Colleague[]) => {
            resolve(colleagues);
          }, error => reject(error));
      }
    });
  }

  getDepartments(): Promise<any> {
    return new Promise((resolve, reject) => {
      // const companyId = this.sharedPackageService.packageCompanyId.getValue();
      const companyId = this.sharedService.currentCompanyId.getValue();
      if (companyId && companyId > 0) {
        this.companyService.getCompanyDepartments()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((departments: Department[]) => {
            resolve(departments);
          });
      }
    });
  }

  getCachedPackageParameters(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.packageParameters$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((parameters: PackageParameter[]) => {
          resolve(parameters);
        }, error => reject(error));
    });
  }

  getCachedParticipants(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.packageParticipants$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((participants: PackageParticipant[]) => {
          resolve(participants);
        }, error => reject(error));
    });
  }

  getDashboardWidget(widgetId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (widgetId > 0) {
        this.dashboardService.getDashboardWidget(widgetId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: any) => {
            resolve(result);
          });
      } else {
        resolve({});
      }
    });
  }

  getCachedExperimentsBrief(includeAnonymous: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.experiments$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((experiments: Experiment[]) => {
          if (includeAnonymous) {
            const newExperiments = this.convertExperimentsDates(experiments);
            resolve(newExperiments);
          } else {
            const filteredExperiments = experiments.filter(experiment => experiment.anonymous === false);
            if (filteredExperiments) {
              const newExperiments = this.convertExperimentsDates(filteredExperiments);
              resolve(newExperiments);
            } else {
              resolve([]);
            }
          }
        });
    });
  }

  getCachedExperiments(includeAnonymous: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedPackageService.experiments$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((experiments: Experiment[]) => {
          if (includeAnonymous) {
            const newExperiments = this.convertExperimentsDates(experiments);
            resolve(newExperiments);
          } else {
            const filteredExperiments = experiments.filter(experiment => experiment.anonymous === false);
            if (filteredExperiments) {
              const newExperiments = this.convertExperimentsDates(filteredExperiments);
              resolve(newExperiments);
            } else {
              resolve([]);
            }
          }
        });
    });
  }

  convertExperimentsDates(experiments: Experiment[]): Experiment[] {
    const newExperiments: Experiment[] = [];
    for (const singleExperiment of experiments) {
      const newExperiment = this.copyExperimentHelper.copyExperiment(singleExperiment);
      const labelStart = newExperiment.startDate.split('T')[0];
      const labelFinish = newExperiment.finishDate.split('T')[0];
      newExperiment.startDate = labelStart;
      newExperiment.finishDate = labelFinish;
      newExperiments.push(newExperiment);
    }

    return newExperiments;
  }

  onlyUnique(value: any, index: number, self: any): boolean {
    return self.indexOf(value) === index;
  }

  findActiveColleagues(experiments: Experiment[], colleaguesList: Colleague[]): ExtendedColleague[] {
    const activeColleagues: ExtendedColleague[] = [];

    for (const singleColleague of colleaguesList) {
      activeColleagues.push({ ...singleColleague, isActive: true });
    }

    return activeColleagues;
  }
}
