import { Boolean, Number, String, Array, Record, Partial } from 'runtypes';
import { ApiExperimentAnswer } from 'app/_api_models/api-experiment-answer';
import { ApiExperimentParameter } from 'app/_api_models/api-experiment-parameter';

export const ApiExperiment = Record({
  id: Number,
  package_id: Number,
  questionnaire_id: Number,
  // start_date: InstanceOf(Date),
  // finish_date: InstanceOf(Date),
  start_date: String,
  finish_date: String,
  completed: Boolean,
  calculated: Boolean,
  anonymous: Boolean,
}).And(Partial({
  experiment_answers: Array(ApiExperimentAnswer),
  experiment_parameters: Array(ApiExperimentParameter),
}));
