import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';

@Component({
  selector: 'app-create-new-dashboard',
  templateUrl: './create-new-dashboard.component.html',
  styleUrls: ['./create-new-dashboard.component.scss']
})
export class CreateNewDashboardComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  newDashboardName: string;
  companyId: number = 0;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();
    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          if (isAdmin) {
            this.loadCompanyId();
          } else {
            this.redirectNotAdmin();
          }
        },
          error => {
            this.redirectNotAdmin();
          });
    }
  }

  loadCompanyId(): void {
    this.sharedService.currentCompanyId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyId: number) => {
        if (companyId > 0) {
          this.companyId = companyId;
        }
      });
  }

  redirectNotAdmin(): void {
    this.router.navigateByUrl('/company');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addDashboard(newDashboardName: string): void {
    if (this.companyId && this.companyId > 0) {
      this.dashboardService.addSharedDashboard(this.companyId, newDashboardName)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdDashboard: SharedDashboard) => {
          console.log('New dashboard ', createdDashboard);
          this.onChangeMode();
        },
        error => {
          console.log('Error: shared dashboard', error);
        });
    }
  }

  onChangeMode(): void {
    this.router.navigate(['/dashboards']);
  }

}
