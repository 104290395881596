<div class="colleague-item">
  <div class="colleague-content">
    <div class="colleague-info-block" flex>
      <div class="colleague-avatar small">{{colleague.firstName | slice : 0 : 1}}</div>
      <div class="colleague-text-data">
        <span class="colleague-name">{{colleague.firstName}}</span>
        <span class="colleague-lastname">{{colleague.lastName}}</span>
      </div>
    </div>
    <div class="colleague-actions-block">
      <button
        class="button button--select"
        [ngClass]="{isSelected:colleague.isSelected}"
        (click)="onSelectParticipant()">
      </button>
      <button
        class="button button--send"
        (click)="onSendEmail()">
        <i class="material-icons button__icon" style="font-size: 30px; vertical-align: bottom;">
          email
        </i>
    </button>
    </div>
  </div>
  <mat-form-field appearance="fill" style="padding: 0; margin: 0;">
    <mat-chip-list #chipList aria-label="Evaluator selection">
      <mat-chip
        *ngFor="let evaluator of selectedEvaluators"
        (removed)="onRemoveEvaluator(evaluator)"
        style="padding: 20px; margin: 5px; border: 1px solid #3f51b5;">
        {{evaluator.firstName}} {{evaluator.lastName}}
        <button matChipRemove style="opacity: 1;">
          <mat-icon style="font-size: 20px; color: #3f51b5; width: 20px; height: 20px;">cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        placeholder="Choose colleagues who will give feedback to current employee"
        i18n-placeholder="colleaguesFeedbackToEmployee"
        #colleagueInput
        [formControl]="colleagueCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectEvaluator($event)">
      <mat-option *ngFor="let colleague of filteredColleagues | async" [value]="colleague">
        {{colleague.firstName}} {{colleague.lastName}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
