import {
  Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input,
  ChangeDetectorRef, OnDestroy, AfterViewChecked
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtendedColleague, ExtendedParameter, WidgetFilters, HeatmapParameter } from '../_helpers/local-models';

import * as Highcharts from 'highcharts';
import HeatmapModule from 'highcharts/modules/heatmap';
import { Colleague } from 'app/_models/colleague';
import { Experiment } from 'app/_models/experiment';
import { Department } from 'app/_models/department';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { SharedDashboardHelper } from '../_helpers/shared-dashboard-helper';
import { Package } from 'app/_models/package';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
HeatmapModule(Highcharts);

@Component({
  selector: 'app-shared-heatmap-widget',
  templateUrl: './shared-heatmap-widget.component.html',
  styleUrls: ['./shared-heatmap-widget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedHeatmapWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;
  @Input() isLoaded: boolean;
  @Input() isPublicLink: boolean;
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();

  showChartMode: boolean = true;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;
  packagesEditMode: boolean = false;
  isSwitchEnabled: boolean = true;
  multiplePackagesMode: boolean = false;
  showParticipants: boolean = false;

  inputWidgetFilters: WidgetFilters;
  widgetFilters: WidgetFilters = {};
  colleaguesList: Colleague[] = [];
  departmentsList: Department[] = [];
  activeColleagues: ExtendedColleague[] = [];
  activeParameters: ExtendedParameter[] = [];
  experiments: Experiment[] = [];
  activeExperiment: Experiment;
  activeMultipleExperiments: Experiment[] = [];
  activeExperimentOption: any;
  activeDepartmentId: number = 0;
  activePackageOption: any;
  sharedPackagesList: Package[] = [];
  loadMoreCount: number = 0;

  data: any = [];
  colleaguesAxisNames: string[] = [];
  parametersAxisNames: string[] = [];
  updateFlag: boolean = false;
  chartObject: any;
  selectedTabIndex: number = 0;
  tabs: any = [];
  heatmapParameters: HeatmapParameter[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      // marginTop: 40,
      marginTop: 0,
      marginBottom: 80
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      categories: [],
      title: null
    },
    colorAxis: [
      {
        minColor: '#FFFFFF',
        maxColor: Highcharts.getOptions().colors[0]
      },
    ],
    legend: {
      enabled: false
    },
    // tooltip: {
    //   formatter: function (): any {
    //     return '<b>' + this.series.yAxis.categories[this.point.y] +
    //       '</b> has <br><b>' +
    //       this.point.value +
    //       '</b> on <br><b>' +
    //       this.series.xAxis.categories[this.point.x] + '</b>';
    //   }
    // },
    tooltip: {
      enabled: false,
    },
    series: [],
  };

  constructor(
    private sharedDashboardHelper: SharedDashboardHelper,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadSharedDashboardData();
  }

  loadSharedDashboardData(): void {
    this.activePackageOption = null;

    this.sharedDashboardHelper.isLoaded
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        if (result) {
          const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
          this.sharedPackagesList = sharedPackagesList;
          let promiseWidget = this.sharedDashboardHelper.getSharedDashboardWidget(this.inputWidgetId);
          // promiseParticipants = this.localDashboardHelper.getCachedParticipants();
          promiseWidget.then((widget: any) => {
            this.isLoaded = true;
            this.inputWidgetFilters = widget.widgetFilters;
            this.formInitialParameters();
            this.formInitialColleagues();
            this.formInitialExperiments();
            this.checkInputWidgets();
            this.updatePackageOption();
          }).catch((error) => {
            console.log('Widget error ', error);
          });
        }
      }, error => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  formInitialExperiments(): void {
    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    if (this.activePackageOption && this.activePackageOption.id) {
      this.experiments = sharedExperiments.filter(exp => exp.packageId === this.activePackageOption.id);
    } else {
      this.experiments = sharedExperiments;
    }
  }

  formInitialParameters(): void {
    const sharedParameters = this.sharedDashboardHelper.getSharedPackageParameters();
    let filteredParameters = sharedParameters.filter(param => param.groupName === 'individual');
    filteredParameters = filteredParameters.filter(param => param.commonValue.type === 'number');
    this.activeParameters = [];
    for (const singleParameter of filteredParameters) {
      const newParam: ExtendedParameter = {
        ...singleParameter,
        isActive: true
      }
      this.activeParameters.push(newParam);
    }
    this.generateNewHeatmapParams();
    // console.log('Shared parameters ', sharedParameters);
  }

  formInitialColleagues(): void {
    const sharedColleagues = this.sharedDashboardHelper.getColleagues();
    this.activeColleagues = [];
    for (const singleColleague of sharedColleagues) {
      const newColleague: ExtendedColleague = {
        ...singleColleague,
        isActive: true
      }
      this.activeColleagues.push(newColleague);
    }
  }

  setActiveParticipants(): void {
    const newActiveColleagues = [];
    const sharedParticipants = this.sharedDashboardHelper.getSharedPackageParticipants();
    for (const singleColleague of this.activeColleagues) {
      const findParticipant = sharedParticipants.find(participant => participant.colleagueId === singleColleague.id);
      if (findParticipant) {
        singleColleague.isActive = findParticipant.isSelected;
        newActiveColleagues.push(singleColleague);
      } else {
        singleColleague.isActive = false;
        newActiveColleagues.push(singleColleague);
      }
    }

    this.activeColleagues = newActiveColleagues;
  }

  updatePackageOption(): void {
    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
    const departments = this.sharedDashboardHelper.getDepartments();
    this.formDepartmentTabs(departments);
    if (this.activePackageOption) {
      this.multiplePackagesMode = false;
      this.activeMultipleExperiments = null;
      const packageId = this.activePackageOption.id;
      const packageExperiments = sharedExperiments.filter(exp => exp.packageId === packageId);
      this.experiments = packageExperiments;
      this.activeParameters = this.activeParameters.filter(param => param.packageId === packageId);
      if (!this.activeExperimentOption) {
        this.activeExperimentOption = 'last_research';
      }

      if (this.activeExperimentOption === 'last_research') {
        this.activeExperiment = this.getLatestPackageExperiment(packageExperiments, 0);
      }
      this.generateNewHeatmapParams();
    } else {
      this.multiplePackagesMode = true;
      this.activeMultipleExperiments = [];
      for (const singlePackage of sharedPackagesList) {
        const packageExperiments = sharedExperiments.filter(exp => exp.packageId === singlePackage.id);
        // this.experiments = packageExperiments;
        this.experiments = [];
        this.activeExperimentOption = 'last_research';
        const lastExperiment = this.getLatestPackageExperiment(packageExperiments, this.loadMoreCount);
        if (lastExperiment) {
          this.activeMultipleExperiments.push(lastExperiment);
        }
      }
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
    if (this.showParticipants) {
      this.setActiveParticipants();
    }

    const nonEmptyTab = this.findNonEmptyTab();
    if (nonEmptyTab) {
      this.changeTab(nonEmptyTab);
    }

    this.formNewAxis();
    this.formNewMap();
  }

  formNewAxis(): void {
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    const newParametersAxisNames = [];
    for (const singleParameter of this.activeParameters) {
      if (singleParameter.isActive) {
        newParametersAxisNames.push(singleParameter.visibleName);
      }
    }
    this.parametersAxisNames = newParametersAxisNames;
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.widgetFilters['showLatestResearch'] = true;
    this.widgetFilters['showParticipants'] = true;
    this.showParticipants = true;
    this.activeExperimentOption = 'last_research';
    this.generateNewHeatmapParams();
  }

  restoreInputWidgetFilters(): void {
    if (this.inputWidgetFilters) {
      const colleaguesIds = this.inputWidgetFilters.activeColleaguesIds;
      if (colleaguesIds) {
        for (const singleColleague of this.activeColleagues) {
          const findColleague = colleaguesIds.find(id => id === singleColleague.id);
          if (findColleague) {
            singleColleague.isActive = true;
          } else {
            singleColleague.isActive = false;
          }
        }
      }
      if (this.inputWidgetFilters.showParticipants) {
        this.showParticipants = true;
        this.formInitialColleagues();
      }
      const activeList = this.activeColleagues.filter(colleague => colleague.isActive === true);
      if (activeList.length === 0) {
        this.formInitialColleagues();
      }

      const paramsIds = this.inputWidgetFilters.activeParametersIds;
      if (paramsIds) {
        for (const singleParam of this.activeParameters) {
          const findParam = paramsIds.find(id => id === singleParam.id);
          if (findParam) {
            singleParam.isActive = true;
          } else {
            singleParam.isActive = false;
          }
        }
      }
      const activeParams = this.activeParameters.filter(param => param.isActive === true);
      if (activeParams.length === 0) {
        this.formInitialParameters();
      }

      const activePackageId = this.inputWidgetFilters.activePackageId;
      if (activePackageId && activePackageId > 0) {
        const findPackage = this.sharedPackagesList.find(pack => pack.id === activePackageId);
        if (findPackage) {
          this.activePackageOption = findPackage;
          // this.updatePackageOption();
        }
      }

      const heatmapParameters = this.filterValidParameters(this.inputWidgetFilters.heatmapParameters);
      this.heatmapParameters = [];
      if (heatmapParameters.length > 0) {
        for (const singleParameter of heatmapParameters) {
          this.heatmapParameters.push(singleParameter);
        }
      } else {
        this.generateNewHeatmapParams();
      }

      if (this.inputWidgetFilters.loadMoreCount) {
        this.loadMoreCount = this.inputWidgetFilters.loadMoreCount;
      }

      const showLatestResearch = this.inputWidgetFilters.showLatestResearch;
      if (showLatestResearch) {
        const activeResearch = this.getLatestExperiment();
        if (activeResearch) {
          this.activeExperiment = activeResearch;
          this.activeExperimentOption = 'last_research';
        } else {
          this.activeExperimentOption = 'last_research';
        }
      } else {
        const activeResearchId = this.inputWidgetFilters.activeResearchId;
        if (activeResearchId) {
          const activeResearch = this.experiments.find(experiment => experiment.id === activeResearchId);
          if (activeResearch) {
            this.activeExperiment = activeResearch;
            this.activeExperimentOption = activeResearch;
          }
        }
      }

    }
  }

  widgetFiltersUpdate(): void {
    const colleaguesFilter = this.activeColleagues.filter(colleague => colleague.isActive === true);
    const activeColleaguesIds = [];
    for (const singleColleague of colleaguesFilter) {
      activeColleaguesIds.push(singleColleague.id);
    }
    this.widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

    const parametersFilter = this.activeParameters.filter(param => param.isActive === true);
    const activeParamsIds = [];
    for (const singleParam of parametersFilter) {
      activeParamsIds.push(singleParam.id);
    }

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    const heatmapParameters = this.heatmapParameters;
    if (heatmapParameters) {
      const newParams = this.convertHeatmapParameters(heatmapParameters);
      this.widgetFilters['heatmapParameters'] = newParams;
    }

    if (this.activePackageOption && this.activePackageOption.id > 0) {
      this.widgetFilters['activePackageId'] = this.activePackageOption.id;
    } else {
      this.widgetFilters['activePackageId'] = 0;
    }

    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
        this.widgetFilters['showLatestResearch'] = true;
      } else {
        this.activeExperiment = experimentOption;
        this.widgetFilters['showLatestResearch'] = false;
      }
    }

    if (this.showParticipants) {
      this.widgetFilters['showParticipants'] = true;
    } else {
      this.widgetFilters['showParticipants'] = false;
    }

    if (this.activeExperiment) {
      this.widgetFilters['activeResearchId'] = this.activeExperiment.id;
    }

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  setAllParametersActive(): void {
    for (const singleParameter of this.activeParameters) {
      singleParameter.isActive = true;
    }
  }

  generateNewHeatmapParams(): void {
    this.heatmapParameters = [];
    for (const singleParam of this.activeParameters) {
      const heatmapParam: HeatmapParameter = {
        parameterId: singleParam.id,
        minValue: 1,
        maxValue: 10,
        visibleName: singleParam.visibleName,
        isActive: singleParam.isActive
      };
      this.heatmapParameters.push(heatmapParam);
    }
  }

  convertHeatmapParameters(heatParams: HeatmapParameter[]): HeatmapParameter[] {
    const newParams = [];
    for (const singleParam of heatParams) {
      const newParam = singleParam;
      newParam.minValue = Number(singleParam.minValue);
      newParam.maxValue = Number(singleParam.maxValue);
      newParams.push(newParam);
    }
    return newParams;
  }

  filterValidParameters(inputParameters: HeatmapParameter[]): HeatmapParameter[] {
    const validParameters: HeatmapParameter[] = [];

    if (inputParameters) {
      for (const singleHeatmapParam of inputParameters) {
        const isValid = this.activeParameters.find(param => param.id === singleHeatmapParam.parameterId);
        if (isValid) {
          validParameters.push(singleHeatmapParam);
        }
      }
    } else {
      return validParameters;
    }

    return validParameters;
  }

  formDepartmentTabs(departments: Department[]): void {
    this.tabs = [];
    let i = 0;
    for (const singleDepartment of departments) {
      const tabItem = { id: i, name: singleDepartment.name, departmentId: singleDepartment.id };
      this.tabs.push(tabItem);
      i = i + 1;
    }
    if (this.tabs.length > 0) {
      this.activeDepartmentId = this.tabs[0].departmentId;
      this.selectedTabIndex = this.tabs[0].id;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  resizeAction(width: number, height: number): void {
    this.chartObject.setSize(width, height, true);
  }

  chartCallback = (chart: any) => {
    this.chartObject = chart;
  }

  onBlurMethod(): void {
    // console.log('ON blur');
  }

  onParamsMenuBack(): void {
    this.widgetFiltersUpdate();
  }

  onPackagesEditMode(): void {
    this.setAllModesFalse();
    this.packagesEditMode = true;
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.parametersEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.researchesEditMode = true;
  }

  onShowChartMode(): void {
    this.setAllModesFalse();
    this.showChartMode = true;
    this.formNewMap();
  }

  setAllModesFalse(): void {
    this.showChartMode = false;
    this.packagesEditMode = false;
    this.colleaguesEditMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
  }


  formColleaguesAxisNames(): any {
    const newColleaguesAxisNames = [];
    const activeExperiments = [];
    if (this.multiplePackagesMode && this.activeMultipleExperiments) {
      for (const singleExperiment of this.activeMultipleExperiments) {
        activeExperiments.push(singleExperiment);
      }
    } else if (this.activeExperiment) {
      activeExperiments.push(this.activeExperiment);
    }

    for (const activeExperiment of activeExperiments) {
      const isAnonymous = activeExperiment.anonymous;
      if (isAnonymous) {
        for (let i = 1; i <= activeExperiment.experimentAnswers.length; i++) {
          newColleaguesAxisNames.push('Colleague #' + i);
        }
      } else {
        let filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
        // let filteredColleagues;
        // if (this.showParticipants) {
        //   filteredColleagues = this.activeColleagues;
        // } else {
        //   filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
        // }
        if (this.activeDepartmentId > 0) {
          filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
        }
        for (const singleColleague of filteredColleagues) {
          const newEntry = singleColleague.firstName + ' ' + singleColleague.lastName;
          const exists = newColleaguesAxisNames.find(axisName => axisName === newEntry);
          if (!exists) {
            newColleaguesAxisNames.push(newEntry);
          }
        }
      }
    }

    return newColleaguesAxisNames;
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    this.widgetFiltersUpdate();
  }

  onParticipantsStateChange(): void {
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    this.widgetFiltersUpdate();
  }

  onHeatmapParametersChange(heatParam: HeatmapParameter): void {
    const findParam = this.activeParameters.find(param => param.id === heatParam.parameterId);
    if (findParam) {
      findParam.isActive = heatParam.isActive;
    }
    this.onParametersStateChange();
  }

  onParametersStateChange(): void {
    const newParametersAxisNames = [];
    for (const singleParameter of this.activeParameters) {
      if (singleParameter.isActive) {
        newParametersAxisNames.push(singleParameter.visibleName);
      }
    }

    this.parametersAxisNames = newParametersAxisNames;
    this.widgetFiltersUpdate();
  }

  onExperimentChange(event: any): void {
    // console.log('Active experiment ', this.activeExperiment);
    this.widgetFiltersUpdate();
  }

  onPackagesFilterChange(event: any): void {
    this.heatmapParameters = [];
    this.activeParameters = [];
    this.formInitialColleagues();
    this.formInitialParameters();
    this.updatePackageOption();
  }

  formNewMap(): void {
    // console.log('Form new map!');
    if (this.multiplePackagesMode && this.activeMultipleExperiments) {
      let seriesArray = [];
      let xCoordCount = 0;
      for (const singleExperiment of this.activeMultipleExperiments) {
        const isAnonymous = singleExperiment.anonymous;
        if (isAnonymous) {
          const resultSeries = this.formNewMapAnonymous(singleExperiment, xCoordCount);
          xCoordCount = xCoordCount + resultSeries.length;
          seriesArray = seriesArray.concat(resultSeries);
        } else {
          const resultSeries = this.formNewMapRegular(singleExperiment, xCoordCount);
          xCoordCount = xCoordCount + resultSeries.length;
          seriesArray = seriesArray.concat(resultSeries);
        }
      }
      this.drawNewMap(seriesArray, seriesArray.length);
    } else if (this.activeExperiment) {
      const isAnonymous = this.activeExperiment.anonymous;
      if (isAnonymous) {
        const resultSeries = this.formNewMapAnonymous(this.activeExperiment, 0);
        this.drawNewMap(resultSeries, resultSeries.length);
      } else {
        const resultSeries = this.formNewMapRegular(this.activeExperiment, 0);
        this.drawNewMap(resultSeries, resultSeries.length);
      }
    } else {
      this.formTestMap();
    }
  }

  formTestMap(): void {
    const newSeries = [];
    let testCategories = [];
    for (const singleItem of this.activeParameters) {
      if (singleItem.isActive) {
        testCategories.push(singleItem.visibleName);
      }
    }

    if (testCategories.length === 0) {
      testCategories = ['parameter 1', 'parameter 2'];
    }
    for (let i = 0; i < testCategories.length; i++) {
      const columnData = this.getTestColumnData(i);
      const chartColumn = this.formChartColumnSeries(columnData, i);
      newSeries.push(chartColumn);
    }
    this.chartOptions.series = newSeries;
    this.chartOptions.title = {
      text: 'Test data',
      verticalAlign: 'middle',
      style: {
        fontSize: '40',
        color: 'gray'
      }
    };

    setTimeout(() => {
      this.chartOptions.xAxis = {
        categories: testCategories
      };
      this.chartOptions.yAxis = {
        categories: ['colleague 1', 'colleague 2', 'colleague 3', 'colleague 4', 'colleague 5'],
        title: null
      };
      this.chartOptions.colorAxis = this.generateColorAxisArray(testCategories.length);
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  getTestColumnData(column: number): any {
    const columnData = [
      [column, 0, this.precise(Math.random() * 10)],
      [column, 1, this.precise(Math.random() * 10)],
      [column, 2, this.precise(Math.random() * 10)],
      [column, 3, this.precise(Math.random() * 10)],
      [column, 4, this.precise(Math.random() * 10)],
    ];
    return columnData;
  }

  formNewMapAnonymous(activeExperiment: Experiment, xCoordCount: number): any {
    const newSeries = [];
    const filterParams = this.activeParameters.filter(param => param.isActive === true);
    let xCoord = 0 + xCoordCount;
    const experimentAnswers = activeExperiment.experimentAnswers;
    for (const singleParameter of filterParams) {
      let yCoord = 0;
      const columnData = [];
      for (const singleAnswer of experimentAnswers) {
        const newEntry = this.generateAnonymousEntry(singleAnswer, singleParameter, xCoord, yCoord);
        columnData.push(newEntry);
        yCoord = yCoord + 1;
      }
      const chartColumn = this.formChartColumnSeries(columnData, xCoord);
      newSeries.push(chartColumn);
      xCoord = xCoord + 1;
    }

    return newSeries;
  }

  formNewMapRegular(activeExperiment: Experiment, xCoordCount: number): any {
    const newSeries = [];
    let filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
    // if (this.showParticipants) {
    //   filteredColleagues = this.activeColleagues;
    // } else {
    //   filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
    // }

    if (this.activeDepartmentId > 0) {
      filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
    }

    let filterParams = this.activeParameters.filter(param => param.isActive === true);
    filterParams = filterParams.filter(param => param.packageId === activeExperiment.packageId);
    let xCoord = 0 + xCoordCount;
    for (const singleParameter of filterParams) {
      let yCoord = 0;
      const columnData = [];
      for (const singleColleague of filteredColleagues) {
        const newEntry = this.generateDataEntry(activeExperiment, singleColleague, singleParameter, xCoord, yCoord);
        if (newEntry) {
          columnData.push(newEntry);
          yCoord = yCoord + 1;
        }
      }
      const chartColumn = this.formChartColumnSeries(columnData, xCoord);
      newSeries.push(chartColumn);
      xCoord = xCoord + 1;
    }

    return newSeries;
  }

  drawNewMap(newSeries: any, xCoordCount: number): void {
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    const colorAxisArray = this.generateColorAxisArray(xCoordCount);

    this.chartOptions.title = null;
    this.chartOptions.series = newSeries;
    setTimeout(() => {
      this.chartOptions.xAxis = {
        categories: this.parametersAxisNames
      };
      this.chartOptions.yAxis = {
        categories: this.colleaguesAxisNames,
        title: null
      };
      this.chartOptions.colorAxis = colorAxisArray;
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  findNonEmptyTab(): any {
    if (this.activeMultipleExperiments && this.tabs && this.tabs.length > 0) {
      for (const singleTab of this.tabs) {
        for (const singleExperiment of this.activeMultipleExperiments) {
          let filteredColleagues;
          if (this.showParticipants) {
            filteredColleagues = this.activeColleagues;
          } else {
            filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
          }

          if (singleTab.departmentId > 0) {
            filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === singleTab.departmentId);
          }

          let filterParams = this.activeParameters.filter(param => param.isActive === true);
          filterParams = filterParams.filter(param => param.packageId === singleExperiment.packageId);
          let xCoord = 0;
          for (const singleParameter of filterParams) {
            let yCoord = 0;
            const columnData = [];
            for (const singleColleague of filteredColleagues) {
              const newEntry = this.generateDataEntry(singleExperiment, singleColleague, singleParameter, xCoord, yCoord);
              if (newEntry) {
                columnData.push(newEntry);
                yCoord = yCoord + 1;
              }
            }
            xCoord = xCoord + 1;
          }

        }

      }
    }
  }

  generateColorAxisArray(xCount: number): any {
    const colorArray = [];
    for (let i = 0; i < xCount; i++) {
      const heatmapParam = this.heatmapParameters[i];
      const dataClasses = this.formDataClasses(heatmapParam);
      colorArray.push(dataClasses);
    }
    return colorArray;
  }

  formDataClasses(heatmapParam: HeatmapParameter): any {
    const stepsArray = [];
    if (heatmapParam) {
      const minValue = Number(heatmapParam.minValue);
      const maxValue = Number(heatmapParam.maxValue);
      const step = (maxValue - minValue) / 10;
      for (let i = 0; i < 10; i++) {
        stepsArray.push(this.precise(i * step));
      }
    } else {
      const step = 1;
      for (let i = 0; i < 10; i++) {
        stepsArray.push(i * step);
      }
    }
    // const colorsArray = [
    //   '#ed1717', '#f24d11', '#f6830c', '#fbb806', '#ffee00',
    //   '#fefe69', '#ddf969', '#a9f36a', '#78ec6c', '#57e86b'
    // ];
    const colorsArray = [
      '#c72c2c', '#e65d1d', '#ff8411', '#faa929', '#f4d144',
      '#d1c33c', '#acb334', '#7e9e34', '#689434', '#4c8834'
    ];


    const classesArray = [];
    for (let i = 0; i < 10; i++) {
      const singleItem = {
        from: stepsArray[i],
        to: stepsArray[i + 1],
        color: colorsArray[i]
      };
      classesArray.push(singleItem);
    }

    return { dataClasses: classesArray };
  }

  generateAnonymousEntry(answer: ExperimentAnswer, parameter: ExtendedParameter, x: number, y: number): any {
    if (this.activeParameters) {
      let value = 0;
      const resultParam = answer.colleagueParameters.find(param => param.packageParameterId === parameter.id);
      if (resultParam) {
        value = this.precise(resultParam.value);
      }

      return [x, y, value];
    } else {
      return [x, y, 0];
    }
  }

  generateDataEntry(experiment: Experiment, colleague: ExtendedColleague, parameter: ExtendedParameter, x: number, y: number): any {
    if (this.activeParameters) {
      const experimentAnswers = experiment.experimentAnswers;
      let value = 0;
      const filteredAnswers = experimentAnswers.filter(answer => answer.colleagueId === colleague.id);
      for (const singleAnswer of filteredAnswers) {
        const resultParam = singleAnswer.colleagueParameters.find(param => param.packageParameterId === parameter.id);
        if (resultParam) {
          value = this.precise(resultParam.value);
          if (value > 0) {
            return [x, y, value];
          }
        }
      }

      if (value === 0) {
        const relativeAnswers = experiment.relativeExperimentAnswers;
        if (relativeAnswers) {
          value = this.getRelativeDataValue(relativeAnswers, colleague, parameter);
          if (value) {
            return [x, y, value];
          }
        }
      }

      return null;
    } else {
      if (this.showParticipants) {
        return null;
      } else {
        return [x, y, 0];
      }
    }
  }

  getRelativeDataValue(relativeAnswers: RelativeExperimentAnswer[], colleague: ExtendedColleague, parameter: ExtendedParameter): number {
    let value = 0;
    const findAnswer = relativeAnswers.find(answer => answer.colleagueId === colleague.id);
    if (findAnswer) {
      const resultParam = findAnswer.relativeExperimentParameters.find(param => param.packageParameterId === parameter.id);
      if (resultParam) {
        value = this.precise(resultParam.value);
      }
    }
    return value;
  }

  formChartColumnSeries(newData: any, colorAxisCount: number): any {
    return {
      name: '',
      type: 'heatmap',
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        color: '#000000'
      },
      colorAxis: colorAxisCount,
      data: newData
    };
  }

  changeTab(tab: any): void {
    if (this.isSwitchEnabled) {
      this.isSwitchEnabled = false;
      this.activeDepartmentId = tab.departmentId;
      this.selectedTabIndex = tab.id;
      this.formNewMap();
    }
  }

  precise(inputValue: number): number {
    if (inputValue) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    } else {
      return 0;
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  getLatestPackageExperiment(packageExperiments: Experiment[], diff: number): Experiment {
    if (packageExperiments) {
      const sortedExperimens = packageExperiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      if (diff) {
        return sortedExperimens[diff];
      } else {
        return sortedExperimens[0];
      }
    }
  }

}
