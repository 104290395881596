import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { AuthenticationService } from '../authentication.service';

import { Parameter } from 'app/_models/overmind_ds/parameter';
import { parseApiParameter } from 'app/_api_validation/overmind-ds/parse-api-parameter';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  private parametersUrl: string = environment.OVERMIND_DS + '/parameters';
  private entityParametersUrl: string = environment.OVERMIND_DS + '/entity_parameters';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    return httpOptions;
  }

  createNewParameter(parameter: Parameter): Observable<Parameter> {
    const data = {
      uuid: parameter.uuid,
      entity_uuid: parameter.entityUuid,
      name: parameter.name,
      formula_name: parameter.formulaName,
      type: parameter.type,
      formula: parameter.formula
    };
    
    return this.http
      .post<Parameter>(this.parametersUrl, data, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseParameter(response)),
        catchError(this.handleError)
      );
  }

  getEntityParameters(uuid: string): Observable<Parameter[]> {
    const url = `${this.entityParametersUrl}/${uuid}`;

    return this.http
      .get<Parameter>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (response !== null) {
            console.log(response)
            return this.parseMultipleParameters(response);
          } else {
            return null;
          }
        }),
        catchError(this.handleError)
      );
  }

  private parseParameter(apiResponse: any): Parameter {
    return parseApiParameter(apiResponse);
  }

  private parseMultipleParameters(apiResponse: any): Parameter[] {
    const mappedData = apiResponse.map(parameter => this.parseParameter(parameter));
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error);
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }
}
