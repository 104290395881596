import { CardAction } from 'app/_models/card-action';
import { ApiCardAction } from 'app/_api_models/api-card-action';
import { environment } from '../../environments/environment';

export function parseApiCardAction(apiResponse: any): CardAction {
  let cardActionObject;
  if (environment.production) {
    cardActionObject = apiResponse;
  } else {
    cardActionObject = ApiCardAction.check(apiResponse);
  }

  const newCardAction: CardAction = {
    id: cardActionObject.id,
    text: cardActionObject.text,
    parameter: cardActionObject.parameter,
    orderPosition: cardActionObject.order_position,
  };

  return newCardAction;
}
