import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder, FormGroup,
  FormControl, FormGroupDirective,
  NgForm, Validators
} from '@angular/forms';
import { CompanyService } from 'app/_services/company.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { Company } from 'app/_models/company';
import { Department } from 'app/_models/department';
import { Colleague } from 'app/_models/colleague';
import { SnackBarComponent } from 'app/snack-bar';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { ColleagueValidation } from './colleague-validation';
import { SnackbarErrorInfoComponent } from 'app/colleagues-page/snackbar-error-info';
import { validate } from 'class-validator';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-public-add-colleague',
  templateUrl: './public-add-colleague.component.html',
  styleUrls: ['./public-add-colleague.component.scss'],
  providers: [SnackBarComponent]
})
export class PublicAddColleagueComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  departments: Department[] = [];
  personForm: FormGroup;
  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  isInvalid: boolean = false;
  companyId: number;
  isBusy: boolean = false;
  currentLang: string = '';
  infoBar: string[] = [];
  token: string;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private snackBar: SnackBarComponent,
    private amplitudeService: AmplitudeEventsService,
  ) {
    this.personForm = this.formBuilder.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      // email: ['', [Validators.required]],
      email: [''],
      slackUserId: [''],
      departmentId: [0]
    }, { validator: this.checkEmail });
  }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.validateColleaguePage(params['token']);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  validateColleaguePage(token: string): void {
    this.companyService.getPublicLinkCompany(token)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultCompany: Company) => {
        console.log('Result public link company ', resultCompany);
        if (resultCompany) {
          this.departments = resultCompany.departments;
          this.companyId = resultCompany.id;
          this.token = resultCompany.colleagueSharedLink;
        }
      }, error => {
        this.isInvalid = true;
        console.log('Error ', error);
      });
  }

  checkPasswords(group: FormGroup): any {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  checkEmail(group: FormGroup): any {
    const emailGroup = group.controls.email;
    if (emailGroup) {
      if (Validators.required(emailGroup)) {
        return { required: true };
      } else if (Validators.email(emailGroup)) {
        return { invalidEmail: true };
      }
    }

    return null;
  }

  onResetForm(): void {
    this.personForm.reset();
    this.personForm = this.formBuilder.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      slackUserId: [''],
      departmentId: [0]
    }, { validator: this.checkEmail });
  }

  onCreateNewPerson(dataModel: Colleague, addMore: boolean): void {
    const newColleagueValidation = new ColleagueValidation();
    newColleagueValidation.firstName = dataModel.firstName;
    newColleagueValidation.lastName = dataModel.lastName;
    newColleagueValidation.email = dataModel.email;

    this.infoBar = [];
    this.isBusy = true;
    validate(newColleagueValidation).then(errors => {
      if (errors.length > 0) {
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
        this.snackBar.openFromComponent(SnackbarErrorInfoComponent, this.infoBar);
        this.isBusy = false;
      } else {
        this.onCreatePerson(dataModel, addMore);
      }
    });

  }

  onCreatePerson(dataModel: Colleague, addMore: boolean): void {
    if (this.companyId) {
      const apiColleague = {
        name: dataModel.firstName,
        last_name: dataModel.lastName,
        email: dataModel.email.replace(/[\s\n\r]+/g, ''),
        slack_user_id: dataModel.slackUserId,
        department_id: dataModel.departmentId,
        bio: '',
        company_id: this.companyId,
      };

      this.companyService.addPublicLinkColleague(apiColleague, this.token)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultColleague: Colleague) => {
          if (resultColleague.id) {
            this.amplitudeService.addEvent('add single colleague');
            dataModel.id = resultColleague.id;

            let messageColleagueAdded = 'New colleague was added';
            if (this.currentLang === 'ru') {
              messageColleagueAdded = 'Сотрудник добавлен';
            }

            this.openSnackBar(messageColleagueAdded);
          }
        }, error => {
          console.log('on errror ', error);
          this.handleCreateError(error);
          this.isBusy = false;
        });
    }
  }

  handleCreateError(error: any): void {
    let errorText = 'Invalid credentials';

    if (error.error) {
      const errorObject = error.error;

      if (errorObject.email) {
        errorText = 'Email ' + errorObject.email[0];
      } else if (errorObject.firstName) {
        errorText = 'First name ' + errorObject.firstName[0];
      } else if (errorObject.lastName) {
        errorText = 'Last name ' + errorObject.lastName[0];
      }

    }

    this.openSnackBar(errorText);
  }

  setOptionValue(event: any): void {
    if (event.value) {
      this.personForm.controls['departmentId'].setValue(event.value);
    }
  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
