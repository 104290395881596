import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { SharedPackageService } from 'app/_services/shared-package.service';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog-result',
  templateUrl: './dialog-result.component.html',
  styleUrls: ['./dialog-result.component.scss']
})
export class DialogResultComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public name: string;
  public dialogType: string = '';
  public defaultMode: boolean = false;
  public withDescription: boolean = false;
  public addDepartment: boolean = false;
  public copyItemMode: boolean = false;
  public copyPublicPackageMode: boolean = false;
  public changeCompanyMode: boolean = false;
  public description: any = ['', ''];
  public copyItem: any = ['', true, true];

  private copyWithTags: boolean = true;
  private copyWithAttachments: boolean = true;
  private stateCtrl: FormControl;
  private chosenCompanyId: number = 0;

  private copyItemName: string = '';
  private tabNames: any = [];
  private tabIds: any = [];
  packageName: string;
  chosenCompanyExist: boolean;
  availableCompanies: any;
  filteredTabs: any;

  constructor(
    public dialogRef: MatDialogRef<DialogResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedPackageService: SharedPackageService) {
      this.stateCtrl = new FormControl();
      this.filteredTabs = this.stateCtrl.valueChanges
        .startWith(null)
        .map(name => this.filterTabs(name));
  }

  ngOnInit(): void {
    if (this.data) {
      if (this.data.dialogType === 'Description') {
        this.withDescription = true;
      } else if (this.data.dialogType === 'AddDepartment') {
        this.addDepartment = true;
      } else if (this.data.dialogType === 'CopyItem') {
        this.formTabsList();
        this.copyItemMode = true;
      } else if (this.data.dialogType === 'ChangeCompany') {
        this.formCompanyList();
        this.changeCompanyMode = true;
      } else if (this.data.dialogType === 'CopyPublicPackage') {
        this.copyPublicPackageMode = true;
        this.copyPackageSetup();
      } else {
        this.defaultMode = true;
      }
    } else {
      this.defaultMode = true;
    }
  }

  copyPackageSetup(): void {
    if (this.data && this.data.defaultName) {
      const name = this.data.defaultName;
      this.packageName = name;
    }
  }

  formCompanyList(): void {
    if (this.data && this.data.companyList) {
      const companies = this.data.companyList;
      this.availableCompanies = companies;
    }
  }

  chooseCompany(company: any): void {
    this.chosenCompanyExist = true;
    if (company) {
      this.chosenCompanyId = company.id;
    }
  }

  isCompanyChosen(company: any): boolean {
    if (company && company.id === this.chosenCompanyId) {
      return true;
    } else {
      return false;
    }
  }

  formTabsList(): void {
    const packId = this.sharedPackageService.currentPackageId$.getValue();
    const tabs = [];
    const tabIds = [];
    const tabNames = [];

    for (const tab of tabs) {
      if (tab.package_id === packId) {
        tabNames.push(tab.name);
        tabIds.push({'name': tab.name, 'id': tab.id});
      }
    }

    this.tabNames = tabNames;
    this.tabIds = tabIds;
  }

  filterTabs(value: string): any {
    return value ? this.tabNames.filter(s => s.toLowerCase().indexOf(value.toLowerCase()) === 0)
                 : this.tabNames;
  }

  tryCopyItem(): any {
    this.copyItem[0] = this.findItemId(this.copyItemName);
    this.dialogRef.close(this.copyItem);
  }

  findItemId(name: string): number {
    for (const elem of this.tabIds) {
      if (elem.name === name) {
        return elem.id;
      }
    }

    return 0;
  }

  toggleCopyTags(): void {
    this.copyWithTags = !this.copyWithTags;
    this.copyItem[1] = this.copyWithTags;
  }

  toggleCopyAttachments(): void {
    this.copyWithAttachments = !this.copyWithAttachments;
    this.copyItem[2] = this.copyWithAttachments;
  }

}
