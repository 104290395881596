import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { CovalentModule } from './covalent.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  imports: [
    // CovalentModule,
    FlexLayoutModule,
    ClipboardModule
  ],
  exports: [
    // CovalentModule,
    FlexLayoutModule,
    ClipboardModule
  ]
})

export class SharedModule {}
