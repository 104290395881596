<div class="standard-set-description">
  <mat-card *ngIf="!isAdmin" class="custom-description">
    <mat-card-header>
      <p><span i18n="@@missing" *ngIf="!currentPackageName">Questionnaire name is missing</span></p>
      <mat-card-title>{{currentPackageName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p><span i18n="@@missing" *ngIf="!currentPackageDescription">Questionnaire description is missing</span></p>
      <div class="package-description" [innerHtml]="currentPackageDescription | safeHtml"></div>
    </mat-card-content>
  </mat-card>
  
  <app-package-description *ngIf="isAdmin"
    [packageDescription]=currentPackageDescription
  >
  </app-package-description>

  <div class="right-sidebar">
    <button mat-raised-button color="accent" (click)="copyPublicPackage()" i18n="@@get-it">
      Get it
    </button>
    <ng-container *ngIf="isAdmin">
      <button mat-raised-button color="warn" (click)="toSettingsPage()" i18n="@@settings-tab">
        Settings
      </button>
    </ng-container>
  </div>
</div>