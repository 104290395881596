<!-- PACKAGE -->
<section class="package">
  <mat-toolbar>
    <mat-toolbar-row class="header">
      <div class="card-header-group">
        <div class="package-name">
          <button class="package-name__button button-back" (click)="backToQuestionnairesList()">
            <i class="material-icons">arrow_back</i>
          </button>
          {{currentPackageName}}
          <button class="package-name__button button--edit" (click)="editDashboardConfig()">
            <i class="material-icons">create</i>
          </button>
        </div>

        <mat-tab-group [selectedIndex]="selectedTabId">
            <mat-tab>
              <ng-template mat-tab-label>
                <span (click)="changeUrl(0)" i18n="@@dashboard-tab">
                  Dashboard
                </span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span (click)="changeUrl(1)" i18n="@@questions-tab">
                  Questions
                </span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span (click)="changeUrl(2)" i18n="@@participants-tab">
                  Participants
                </span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span (click)="changeUrl(3)" i18n="@@researches-tab">
                  Researches
                </span>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <span (click)="changeUrl(tabs.length)" class="tab-group">
                            <i class="material-icons">
                  settings
                  </i>
                  <span i18n="@@settings-tab">Settings</span>
                </span>
              </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="content-wrap" *ngIf="currentPackageName">
    <ng-container *ngIf="!isDirectLink; else activeCompany">
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</section>
<!-- PACKAGE END -->

<!-- If at the moment you have another active company -->
<ng-template #activeCompany>
  <div class="error-container">
    <p class="error-container__title" i18n="@@makeCompanyActive-Text">
      You can make this company active to perform additional actions
    </p>
    <div class="error-container__content">
      <button mat-raised-button color="accent" (click)="makeCompanyActive()" i18n="@@makeCompanyActive-Button">
        Make company active
      </button>
    </div>
  </div>
</ng-template>
