import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './pipes';

@NgModule({
  declarations: [
    SafeHtmlPipe,
  ],
  exports: [
    SafeHtmlPipe,
  ]
})
export class PipesModule {}
