import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PackageService } from 'app/_services/package.service';
import { Package } from 'app/_models/package';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PackageParameter } from 'app/_models/package-parameter';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { SharedService } from 'app/_services/shared.service';
import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';
import { PythonCalculationHelperComponent } from 'app/python-calculation-helper';

@Component({
  selector: 'app-create-pq-action',
  templateUrl: './create-pq-action.component.html',
  styleUrls: [
    '../../package-details.component.scss',
    './create-pq-action.component.scss'
  ]
})
export class PackageCreatePQActionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;
  @ViewChild(PythonCalculationHelperComponent, { static: false }) pythonComponent: PythonCalculationHelperComponent;

  packages: Package[] = [];

  errorList: string[] = [];
  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];

  currentPackageId: number = 0;
  nextPosition: number = 0;
  isActionValid: boolean = false;
  isActionSaving: boolean = false;
  isCustomCalculation: boolean = false;
  isSimpleCalculation: boolean = false;
  isAdmin: boolean = false;

  nextId: number;

  pqAction: PostQuestionnaireAction = {
    id: 0,
    actionType: '',
    parameter: '',
    text: '',
    orderPosition: 0,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private sharedPackageService: SharedPackageService,
    private authService: AuthenticationService,
    private userService: UserService,
    private sharedService: SharedService,
    private packageService: PackageService) {
  }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        const packageId = params['package_id'];
        if (packageId) {
          this.currentPackageId = packageId;
        }
      });
    this.getPackageInfo();
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        },error => {
          this.sharedService.checkSignatureExpired(error);
        });
    }
  }

  handleInput(inputValue: any): void {
    if (inputValue && inputValue.length > 0) {
      this.validateAction();
    } else {
      this.isActionValid = false;
    }
  }

  validateAction(): void {
    const actionType = this.pqAction.actionType;
    const parameter = this.pqAction.parameter;
    const text = this.pqAction.text;

    if (!actionType || !parameter || !text) {
      this.isActionValid = false;
    } else if (this.isActionSaving) {
      this.isActionValid = false;
    } else {
      this.isActionValid = true;
    }
  }

  getPackageInfo(): void {
    const packageId = this.currentPackageId;

    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
            if (packageInfo && packageInfo.questionnaire) {
              const postQuestionnaireActions: PostQuestionnaireAction[] = packageInfo.postQuestionnaireActions;
              this.nextId = postQuestionnaireActions.length + 1;
              this.nextPosition = this.nextId;

              this.sharedPackageService.packageParameters$.next(packageInfo.packageParameters);
              this.separateParameters(packageInfo.packageParameters);
            }
          },
          error => {
            console.log('Package info', error);
          });
    }
  }

  separateParameters(parametersList: PackageParameter[]): void {
    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  onSelectionChange(event: any): void {
    const newCalculationType = event.value;
    if (newCalculationType === 'custom-simple') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = true;
      this.pqAction.text = 'CUSTOM_CALCULATION_SIMPLE';
      this.validateAction();
    } else if (newCalculationType === 'custom') {
      this.isCustomCalculation = true;
      this.isSimpleCalculation = false;
      this.pqAction.text = 'CUSTOM_CALCULATION';
      this.pqAction.parameter = '';
      this.isActionValid = true;
      // this.validateAction();
    } else {
      this.isCustomCalculation = false;
      this.validateAction();
    }
  }

  onParameterChange(event: any): void {
    this.validateAction();
  }

  saveAction(): void {
    const packageId = this.currentPackageId;
    const actionInfo: PostQuestionnaireAction = {
      id: 0,
      actionType: this.pqAction.actionType,
      parameter: this.pqAction.parameter,
      text: this.pqAction.text,
      orderPosition: this.nextPosition,
    };

    this.isActionSaving = true;
    this.validateAction();

    this.packageService.addPostQuestionnaireAction(packageId, actionInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultAction: PostQuestionnaireAction) => {
        // console.log('Result action ', resultAction);
        if (this.isCustomCalculation) {
          this.addCustomCalculation(resultAction.id);
        } else {
          this.goBack();
        }
      }, error => {
        console.log('Post Questionnaire error ', error);
      });
  }

  addCustomCalculation(pqActionId: number): void {
    const code = this.pythonComponent.getCurrentCode();
    this.packageService.addCustomCalculation(pqActionId, code)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: PythonCustomCalculation) => {
        this.goBack();
      }, error => console.log(error));
  }

  goBack(): void {
    this._location.back();
  }
}
