<div class="widget">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="package-statistics">
    <mat-card class="package-statistics__item left-card">
      <h2 i18n="@@passagePercentage" class="package-statistics__title">Last research passage percentage</h2>
      <span class="package-statistics__results">{{passagePercentage}}</span>
      <div *ngIf="!testData">
        <button (click)="onSendReminder()" class="send-reminder" i18n="@@reminder-buttonText">
          Send reminder
        </button>
      </div>
    </mat-card>
    <mat-card class="package-statistics__item launches">
      <h2 i18n="@@numberLaunches" class="package-statistics__title">Number of launches</h2>
      <span class="package-statistics__results">{{experiments.length}}</span>
    </mat-card>
    <mat-card class="package-statistics__item next-run">
      <h2 i18n="@@next-run" class="package-statistics__title">Next run</h2>
      <span *ngIf="nextRun" class="package-statistics__results">{{nextRun | slice : 0 : -7}}</span>
      <span class="warning-message" *ngIf="!nextRun && started" i18n="@@warningMessage-NextRunIsLast">Current run is the last</span>
      <span class="warning-message" *ngIf="!nextRun && !started" i18n="@@warningMessage-notStarted">Don't have the run yet</span>
    </mat-card>
    <mat-card class="package-statistics__item">
      <h2 i18n="@@last-run" class="package-statistics__title">Last run</h2>
      <span *ngIf="lastRun" class="package-statistics__results">{{lastRun | slice : 0 : -7}}</span>
      <span class="warning-message" *ngIf="!lastRun && started"> - </span>
      <span class="warning-message" *ngIf="!lastRun && !started" i18n="@@warningMessage-notStarted">Don't have the run yet</span>
    </mat-card>
  </div> <!-- /.package-statistics -->
</div> <!-- /.widget -->

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
