import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'app/_services/dashboard.service';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { Questionnaire } from 'app/_models/questionnaire';
import { PackageParameter } from 'app/_models/package-parameter';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { PackageParticipant } from 'app/_models/package-participant';
import { SharedDashboardHelper } from '../shared_widgets/_helpers/shared-dashboard-helper';
import { Experiment } from 'app/_models/experiment';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { SharedService } from 'app/_services/shared.service';

@Component({
  selector: 'app-shared-dashboard-user-view',
  templateUrl: './shared-dashboard-user-view.component.html',
  styleUrls: ['./shared-dashboard-user-view.component.scss']
})
export class SharedDashboardUserViewComponent implements OnInit, OnDestroy, OnChanges {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() inputDashboard: SharedDashboard;
  @Input() sharedQuestionnaires: Questionnaire[] = [];
  @Input() sharedPackageParameters: PackageParameter[] = [];
  @Input() sharedPackageParticipants: PackageParticipant[] = [];
  @Input() isPublicLink: boolean = false;
  @Output() toEditMode: EventEmitter<any> = new EventEmitter<any>();
  @Output() toManageQuestionnaires: EventEmitter<any> = new EventEmitter<any>();
  localDashboardWidgets: SharedDashboardWidget[] = [];
  activeResearchIds: any = [];
  loadMoreCount: number = 0;
  companyId: number = 0;

  constructor(
    private dashboardService: DashboardService,
    private sharedDashboardHelper: SharedDashboardHelper,
    private sharedService: SharedService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.sharedDashboardHelper.emptyCachedData();
    this.sharedDashboardHelper.setSharedQuestionnaires(this.sharedQuestionnaires);
    this.sharedDashboardHelper.setSharedPackageParameters(this.sharedPackageParameters);
    this.sharedDashboardHelper.setSharedPackages(this.inputDashboard.packages);
    this.sharedDashboardHelper.setSharedDashboardWidgets(this.inputDashboard.sharedWidgets);
    this.sharedDashboardHelper.setSharedParticipants(this.sharedPackageParticipants);
    this.sharedDashboardHelper.setSharedCompanyId(this.inputDashboard.companyId);
    this.localDashboardWidgets = this.formLocalDashboardWidgets(this.inputDashboard);
    this.formActiveResearchIds();
    this.preloadData();
    this.companyId = this.sharedService.currentCompanyId.getValue();
  }

  ngOnChanges(): void {
    // console.log('On changes? ', this.inputDashboard);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  formActiveResearchIds(): void {
    for (const singleWidget of this.localDashboardWidgets) {
      const currentId = singleWidget.widgetFilters.activeResearchId;
      if (currentId) {
        this.activeResearchIds.push({ widgetId: singleWidget.id, activeResearchId: currentId });
      }
    }
  }

  getNextExperimentId(packageId: number, widgetId: number): any {
    const findQuestionnaire = this.sharedQuestionnaires.find(questionnaire => questionnaire.packageId === packageId);
    const currentExperimentSettings = this.activeResearchIds.find(research => research.widgetId === widgetId);
    // console.log('Current exp id ', currentExperimentSettings);
    if (findQuestionnaire && currentExperimentSettings) {
      const sortedExperiments = findQuestionnaire.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      for (let i = 0; i < sortedExperiments.length; i++) {
        if (sortedExperiments[i].id === currentExperimentSettings.activeResearchId) {
          if (sortedExperiments[i + 1]) {
            currentExperimentSettings.activeResearchId = sortedExperiments[i + 1].id;
            // console.log('Return ', sortedExperiments[i + 1].id);
            return sortedExperiments[i + 1].id;
          }
        }
      }
    }
  }

  formLocalDashboardWidgets(inputDashboard: SharedDashboard): SharedDashboardWidget[] {
    const localDashboardWidgets: SharedDashboardWidget[] = [];
    for (const singleWidget of inputDashboard.sharedWidgets) {
      const localWidget = this.copySingleWidget(singleWidget);
      // console.log('Local widget ', localWidget);
      localDashboardWidgets.push(localWidget);
    }

    return localDashboardWidgets;
  }

  copySingleWidget(inputWidget: SharedDashboardWidget): SharedDashboardWidget {
    // console.log('New filters ', this.copyWidgetFilters(inputWidget.widgetFilters));
    const newFilters = this.copyWidgetFilters(inputWidget.widgetFilters);
    const newWidget: SharedDashboardWidget = {
      id: inputWidget.id,
      widgetName: inputWidget.widgetName,
      widgetTitle: inputWidget.widgetTitle,
      orderPosition: inputWidget.orderPosition,
      sharedDashboardId: inputWidget.sharedDashboardId,
      widgetFilters: inputWidget.widgetFilters
      // widgetFilters: newFilters,
    };
    newWidget.widgetFilters.loadMoreCount = this.loadMoreCount;
    return newWidget;
  }

  copyWidgetFilters(inputFilters: any): any {
    const newFilters = {};
    for (const singleKey of Object.keys(inputFilters)) {
      newFilters[singleKey] = inputFilters[singleKey];
    }
    return newFilters;
  }

  onDeleteDashboard(): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.removeSharedDashboard();
      }
    });
  }

  removeSharedDashboard(): void {
    if (this.inputDashboard) {
      this.dashboardService.removeSharedDashboard(this.inputDashboard.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          location.reload();
        }, error => {
          console.log('Error ', error);
        });
    }
  }

  onEditDashboard(): void {
    this.toEditMode.emit();
  }

  onManageQuestionnaires(): void {
    this.toManageQuestionnaires.emit();
  }

  preloadData(): void {
    const promisesList = [];
    let promiseColleagues: Promise<any>;
    let promiseExperiments: Promise<any>;
    // let promiseParticipants: Promise<any>;
    let promiseDepartments: Promise<any>;

    promiseColleagues = this.sharedDashboardHelper.preloadColleagues(true);
    promiseColleagues.then((colleagues: Colleague[]) => {
      if (colleagues) {
        this.sharedDashboardHelper.setSharedColleagues(colleagues);
      }
    }).catch((error) => console.log(error));
    promiseDepartments = this.sharedDashboardHelper.preloadDepartments(70);
    promiseDepartments.then((departments: Department[]) => {
      if (departments) {
        this.sharedDashboardHelper.setSharedDepartments(departments);
      }
    }).catch((error) => console.log(error));
    promiseExperiments = this.sharedDashboardHelper.preloadSharedExperiments(false);
    promiseExperiments.then((experiments: Experiment[]) => {
      if (experiments) {
        this.sharedDashboardHelper.setSharedExperiments(experiments);
      }
    }).catch((error) => console.log(error));
    // promiseParticipants = this.sharedDashboardHelper.getSharedPackageParticipants();

    promisesList.push(promiseDepartments);
    promisesList.push(promiseColleagues);
    promisesList.push(promiseExperiments);
    // promisesList.push(promiseParticipants);

    Promise.all(promisesList).then((result) => {
      this.sharedDashboardHelper.isLoaded.next(true);
    }).catch((error) => console.log(error));
  }

  loadMore(): void {
    let nextOrderPosition = this.localDashboardWidgets.length + 1;
    this.loadMoreCount = this.loadMoreCount + 1;
    const newLocalWidgets = this.formLocalDashboardWidgets(this.inputDashboard);
    for (const singleWidget of newLocalWidgets) {
      if (singleWidget.widgetName === 'card-text-widget') {
        const updatedWidget = this.addNextTextCard(singleWidget);
        if (updatedWidget) {
          updatedWidget.orderPosition = nextOrderPosition;
          this.localDashboardWidgets.push(updatedWidget);
          nextOrderPosition = nextOrderPosition + 1;
        }
      } else if (singleWidget.widgetName === 'shared-bar-widget') {
        const updatedWidget = this.addNextBarChart(singleWidget);
        if (updatedWidget) {
          updatedWidget.orderPosition = nextOrderPosition;
          this.localDashboardWidgets.push(updatedWidget);
          nextOrderPosition = nextOrderPosition + 1;
        }
      } else if (singleWidget.widgetName === 'heatmap-individual-widget') {
        const updatedWidget = this.addNextHeatmap(singleWidget);
        if (updatedWidget) {
          updatedWidget.orderPosition = nextOrderPosition;
          this.localDashboardWidgets.push(updatedWidget);
          nextOrderPosition = nextOrderPosition + 1;
        }
      } else if (singleWidget.widgetName === 'separator-widget') {
        const updatedWidget = this.addNextSeparator(singleWidget);
        if (updatedWidget) {
          updatedWidget.orderPosition = nextOrderPosition;
          this.localDashboardWidgets.push(updatedWidget);
          nextOrderPosition = nextOrderPosition + 1;
        }
      }
    }

    this.removeEmptySeparators();
    // console.log('tttt ', this.localDashboardWidgets);
  }

  addNextTextCard(singleWidget: SharedDashboardWidget): any {
    const packageId = singleWidget.widgetFilters.activePackageId;
    // const currentExperimentId = singleWidget.widgetFilters.activeResearchId;
    const nextExperimentId = this.getNextExperimentId(packageId, singleWidget.id);
    const updatedWidget = this.copySingleWidget(singleWidget);
    if (nextExperimentId && nextExperimentId > 0) {
      // updatedWidget.id = this.genUniqueId();
      updatedWidget.widgetFilters.activeResearchId = nextExperimentId;
      updatedWidget.widgetFilters.showLatestResearch = false;
      return updatedWidget;
    }
  }

  addNextBarChart(singleWidget: SharedDashboardWidget): any {
    const packageId = singleWidget.widgetFilters.activePackageId;
    const nextExperimentId = this.getNextExperimentId(packageId, singleWidget.id);
    const updatedWidget = this.copySingleWidget(singleWidget);
    // console.log('Copied widget ', updatedWidget);
    if (nextExperimentId && nextExperimentId > 0) {
      updatedWidget.widgetFilters.activeResearchId = nextExperimentId;
      updatedWidget.widgetFilters.showLatestResearch = false;
      return updatedWidget;
    }
  }

  addNextHeatmap(singleWidget: SharedDashboardWidget): any {
    const packageId = singleWidget.widgetFilters.activePackageId;
    if (packageId) {
      const nextExperimentId = this.getNextExperimentId(packageId, singleWidget.id);
      const updatedWidget = this.copySingleWidget(singleWidget);
      if (nextExperimentId && nextExperimentId > 0) {
        updatedWidget.widgetFilters.activeResearchId = nextExperimentId;
        updatedWidget.widgetFilters.showLatestResearch = false;
        return updatedWidget;
      }
    } else {
      const updatedWidget = this.copySingleWidget(singleWidget);
      return updatedWidget;
    }
  }

  addNextSeparator(singleWidget: SharedDashboardWidget): any {
    const updatedWidget: SharedDashboardWidget = {
      ...singleWidget
    };
    return updatedWidget;
  }

  removeEmptySeparators(): void {
    const newWidgets: SharedDashboardWidget[] = [];
    for (let i = 0; i < this.localDashboardWidgets.length; i++) {
      const isSeparator = (this.localDashboardWidgets[i].widgetName === 'separator-widget');
      const notEmpty = (this.localDashboardWidgets[i + 1]);
      if (!isSeparator || notEmpty) {
        newWidgets.push(this.localDashboardWidgets[i]);
      }
    }

    this.localDashboardWidgets = newWidgets;
  }

}
