import {EnvType, EnvTypeConfig} from './envtypeconfig';

export const environment = {
  production: true,
  envType: new EnvTypeConfig(EnvType.TYPE.STAGING),
  API_ENDPOINT: 'https://staging-overmind.dotwrk.com/api',
  ATTACHMENTS_URL: 'https://staging-overmind.dotwrk.com',
  BASE_ANGULAR_URL: 'https://staging-overmind.dotwrk.com',
  OVERMIND_DS: 'https://staging-overmind.dotwrk.com:4300/api/v1'
};
