import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-rename',
  templateUrl: './dialog-rename.component.html',
  styleUrls: ['./dialog-rename.component.scss']
})
export class DialogRenameComponent implements OnInit {
  dialogTitle: string = '';
  dialogSubtitle: string = '';
  public packageName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogRenameComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
     if (this.data && this.data.dialogTitle && this.data.dialogSubtitle) {
       this.dialogTitle = this.data.dialogTitle;
       this.dialogSubtitle = this.data.dialogSubtitle;
     }
  }

}
