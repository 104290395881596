import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SharedService } from 'app/_services/shared.service';
import { SnackBarComponent } from 'app/snack-bar/snack-bar.component';
import { SharedPackageService } from 'app/_services/shared-package.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  providers: [SnackBarComponent],
  // encapsulation: ViewEncapsulation.None
})
export class PackagesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  selectedTabId: number = 0;
  isBootstrapped: boolean = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private snackBar: SnackBarComponent,
    public  dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.sharedService.currentTabId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tabId: number) => {
        if (this.isBootstrapped) {
          this.selectedTabId = tabId;
        }
      });

    if (this.router.url.indexOf('/questionnaires/standard-sets') > -1) {
      this.selectedTabId = 1;
      this.isBootstrapped = true;
    } else {
      const currentTabId = this.sharedService.currentTabId.getValue();
      this.selectedTabId = currentTabId;
      this.isBootstrapped = true;
    }

    this.sharedPackageService.onCreateFromStandard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.openSnackBar('New package was created');
        } else {
          this.openSnackBar('Error while copying the package');
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeUrl(tabValue: any): void {
    this.selectedTabId = tabValue;
    if (tabValue === 0) {
      this.router.navigateByUrl('/questionnaires');
    } else if (tabValue === 1) {
      this.router.navigateByUrl('/questionnaires/standard-sets');
   }
  }

  // here could be situation when we access packages
  // directly without login page
  // therefore we should emit onLogin manually the first time
  checkLoggedIn(): void {
    const user = localStorage.getItem('currentUser');

    if (user) {
      const email = JSON.parse(user)['email'];

      this.sharedService.currentUser = {
        'email': email, 'canCreate': false,
        'canRead': true, 'canUpdate': false, 'canDelete': false
      };

      if (!this.sharedService.onLoginEmitted) {
        this.sharedService.onLogin$.emit();
        this.sharedService.onLoginEmitted = true;
      }
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
