import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedDashboard } from 'app/_models/shared-dashboard';
import { DashboardService } from 'app/_services/dashboard.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { PackageParameter } from 'app/_models/package-parameter';
import { PackageService } from 'app/_services/package.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { PackageParticipant } from 'app/_models/package-participant';

@Component({
  selector: 'app-dashboards-list',
  templateUrl: './dashboards-list.component.html',
  styleUrls: ['./dashboards-list.component.scss']
})
export class DashboardsListComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;
  selectedTabId: number = 0;
  currentDashboardId: number = 0;
  dashboards: SharedDashboard[] = [];
  isBootstrapped: boolean = false;
  isReadyDashboard: boolean = false;
  activeDashboardId: number;
  activeDashboard: SharedDashboard;
  isEditorMode: boolean = false;
  isSettingsMode: boolean = false;
  isViewMode: boolean = true;
  isQuestionnairesChoice: boolean = false;
  canClickTab: boolean = true;
  sharedQuestionnaires: Questionnaire[] = [];
  sharedPackageParameters: PackageParameter[] = [];
  sharedPackageParticipants: PackageParticipant[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private questionnaireService: QuestionnaireService,
    private packageService: PackageService,
    private authService: AuthenticationService,
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isBootstrapped = false;
    this.getUserInfo();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();
    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          if (isAdmin) {
            this.loadDashboards();
          } else {
            this.redirectNotAdmin();
          }
        },
        error => {
          this.redirectNotAdmin();
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadDashboards(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((routeParams: any) => {
        const dashboardId = Number(routeParams['dashboard_id']);
        if (dashboardId) {
          this.currentDashboardId = dashboardId;
          this.getSharedDashboards(dashboardId);
        } else {
          this.currentDashboardId = 0;
          this.getSharedDashboards(0);
        }
      });
  }

  redirectNotAdmin(): void {
    this.router.navigateByUrl('/company');
  }

  onReloadSharedDashboard(event: any): void {
    // console.log('On reload shared dashboard ', this.currentDashboardId);
    if (this.currentDashboardId > 0) {
      // this.getSharedDashboards(this.currentDashboardId);
      location.reload();
    }
  }

  onTabClick(dashboardId: number): void {
    if (this.canClickTab) {
      // console.log('Dashboard id ', dashboardId);
      this.isBootstrapped = false;
      if (dashboardId && dashboardId > 0) {
        this.canClickTab = false;
        this.setAllModesFalse();
        this.isViewMode = true;
        this.getSharedDashboards(dashboardId);
      }
    }
  }

  getSharedDashboards(defaultId: number): void {
    // console.log('get shared ', defaultId);
    this.dashboardService.getSharedDashboards()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sharedDashboardsList: SharedDashboard[]) => {
        this.dashboards = sharedDashboardsList;
        if (sharedDashboardsList.length > 0) {
          const findDashboard = sharedDashboardsList.find(listItem => listItem.id === defaultId);
          if ((defaultId > 0) && findDashboard) {
            this.activeDashboardId = findDashboard.id;
            // this.activeDashboard = findDashboard;
            const tabId = this.getTabId(this.activeDashboardId);
            this.selectedTabId = tabId;
            this.router.navigate(['/dashboards/' + this.activeDashboardId]);
          } else {
            this.activeDashboardId = sharedDashboardsList[0].id;
            // this.activeDashboard = sharedDashboardsList[0];
            this.router.navigate(['/dashboards/' + this.activeDashboardId]);
          }
        } else {
          this.isReadyDashboard = true;
        }

        this.loadSingleDashboard();
      },
      error => {
        console.log('Error: shared dashboard', error);
      });
  }

  loadSingleDashboard(): void {
    if (this.activeDashboardId) {
      this.dashboardService.getSharedDashboard(this.activeDashboardId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDashboard: SharedDashboard) => {
          this.activeDashboard = resultDashboard;
          const promisesList = [];
          promisesList.push(this.getSharedQuestionnaires());
          promisesList.push(this.getSharedPackageParameters());
          promisesList.push(this.getSharedPackageParticipants());
          this.sharedPackageParameters = [];
          this.sharedQuestionnaires = [];
          this.sharedPackageParticipants = [];
          Promise.all(promisesList).then((result) => {
            this.sharedQuestionnaires = result[0];
            for (const singlePackageParams of result[1]) {
              for (const singleParam of singlePackageParams) {
                this.sharedPackageParameters.push(singleParam);
              }
            }
            for (const participantsArray of result[2]) {
              for (const singleParticipant of participantsArray) {
                this.sharedPackageParticipants.push(singleParticipant);
              }
            }
            this.isBootstrapped = true;
            this.isReadyDashboard = true;
            this.canClickTab = true;
          }).catch((error) => {
            console.log('Error ', error);
          });
        },
        error => {
          console.log(error);
        });
    }
  }

  getTabId(dashboardId: number): number {
    const dashboardsLength = this.dashboards.length;
    if (dashboardsLength > 0) {
      for (let i = 0; i < dashboardsLength; i++) {
        if (this.dashboards[i].id === dashboardId) {
          return i;
        }
      }
    }
    return 0;
  }

  getSharedQuestionnaires(): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.sharedQuestionnaires = [];
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const questionnaireId = singlePackage.questionnaire.id;
        promisesList.push(this.loadSingleQuestionnaire(questionnaireId));
      }
      Promise.all(promisesList).then((result) => {
        // this.sharedQuestionnaires = result;
        resolve(result);
      }).catch ((error) => {
        reject(error);
      });
    });
  }

  loadSingleQuestionnaire(questionnaireId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.getQuestionnaireBrief(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          resolve(resultQuestionnaire);
        }, error => reject(error));
    });
  }

  getSharedPackageParameters(): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.sharedPackageParameters = [];
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const packageId = singlePackage.id;
        promisesList.push(this.loadPackageParameters(packageId));
      }
      Promise.all(promisesList).then((resultParams) => {
        resolve(resultParams);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadPackageParameters(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getPackageParameters(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameters: PackageParameter[]) => {
          resolve(resultParameters);
        }, error => reject(error));
    });
  }

  getSharedPackageParticipants(): Promise<any> {
    return new Promise((resolve, reject) => {
      const promisesList = [];
      const packages = this.activeDashboard.packages;
      for (const singlePackage of packages) {
        const packageId = singlePackage.id;
        promisesList.push(this.loadPackageParticipants(packageId));
      }
      Promise.all(promisesList).then((resultParticipants) => {
        resolve(resultParticipants);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  loadPackageParticipants(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getPackageParticipants(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParticipants: PackageParticipant[]) => {
          resolve(resultParticipants);
        }, error => reject(error));
    });
  }

  toCreateDashboardPage(): void {
    this.router.navigate(['/dashboards/create-dashboard']);
  }

  setAllModesFalse(): void {
    this.isEditorMode = false;
    this.isQuestionnairesChoice = false;
    this.isViewMode = false;
    this.isSettingsMode = false;
  }

  onEditorMode(): void {
    this.setAllModesFalse();
    this.isEditorMode = true;
  }

  onManageQuestionnaires(): void {
    this.setAllModesFalse();
    this.isQuestionnairesChoice = true;
  }

  onDashboardSettings(): void {
    this.setAllModesFalse();
    this.isSettingsMode = true;
  }

  onDeleteDashboard(): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.removeSharedDashboard();
      }
    });
  }

  removeSharedDashboard(): void {
    if (this.activeDashboard) {
      this.dashboardService.removeSharedDashboard(this.activeDashboard.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          location.reload();
        }, error => {
          console.log('Error ', error);
        });
    }
  }

}
