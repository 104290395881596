<mat-tab-group class="packages-tabs-style" *ngIf="isReadyDashboard" [selectedIndex]="selectedTabId">
  <mat-tab *ngFor="let singleDashboard of dashboards" [disabled]="!canClickTab">
    <ng-template mat-tab-label>
      <span (click)="onTabClick(singleDashboard.id)">{{singleDashboard.dashboardName}}</span>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon (click)="toCreateDashboardPage()">add</mat-icon>
      <span (click)="toCreateDashboardPage()" i18n="@@createDashboard">Create dashboard</span>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<div *ngIf="isBootstrapped&&activeDashboardId">
  <ng-container *ngIf="isViewMode">
    <div class="dashboard-edit-buttons-style">
      <button
        mat-raised-button
        color="accent"
        class="single-edit-button-style"
        (click)="onEditorMode()"
        i18n="@@editDashboard">
          Edit dashboard
      </button>
      <button
        mat-raised-button
        color="primary"
        class="single-edit-button-style"
        (click)="onManageQuestionnaires()"
        i18n="@@sharedQuestionnaires">
          Shared Questionnaires
      </button>
      <button
        mat-raised-button
        color="primary"
        class="single-edit-button-style"
        (click)="onDashboardSettings()"
        i18n="@@settings">
          Settings
      </button>
      <button
        mat-raised-button
        color="warn"
        class="single-edit-button-style"
        (click)="onDeleteDashboard()"
        i18n="@@deleteDashboard">
          Delete dashboard
      </button>
    </div>
    <app-shared-dashboard-user-view 
      [inputDashboard]="activeDashboard"
      [isPublicLink]="false"
      [sharedQuestionnaires]="sharedQuestionnaires"
      [sharedPackageParameters]="sharedPackageParameters"
      [sharedPackageParticipants]="sharedPackageParticipants"
      (toEditMode)="onEditorMode($event)"
      (toManageQuestionnaires)="onManageQuestionnaires($event)"
      >
    </app-shared-dashboard-user-view>
  </ng-container>
  <ng-container *ngIf="isEditorMode">
    <app-shared-dashboard-editor
      [inputDashboard]="activeDashboard"
      [sharedQuestionnaires]="sharedQuestionnaires"
      [sharedPackageParameters]="sharedPackageParameters"
      [sharedPackageParticipants]="sharedPackageParticipants"
      (onReloadSharedDashboard)="onReloadSharedDashboard($event)"
    >
    </app-shared-dashboard-editor>
  </ng-container> 
  <ng-container *ngIf="isQuestionnairesChoice">
    <app-dashboard-manage-questionnaires
      [sharedDashboard]="activeDashboard"
    >
    </app-dashboard-manage-questionnaires>
  </ng-container>
  <ng-container *ngIf="isSettingsMode">
    <app-dashboard-settings
      [sharedDashboard]="activeDashboard"
    >
    </app-dashboard-settings>
  </ng-container>
</div>