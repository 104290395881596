import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { Questionnaire } from 'app/_models/questionnaire';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';

class PeriodModel {
  interval: string;
  startDay: string;
  startTime: string;
  finishDay: string;
  finishTime: string;
}

@Component({
  selector: 'app-period-form',
  templateUrl: './period-form.component.html',
  styleUrls: ['./period-form.component.scss']
})
export class PeriodFormComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() onUpdateResearchActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onUpdateModelConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  daysEnabled: any = [];
  daysTemplate: any = [
    { id: 1, name: 'Mo', code: 'mon', enabled: false },
    { id: 2, name: 'Tu', code: 'tue', enabled: false },
    { id: 3, name: 'We', code: 'wed', enabled: false },
    { id: 4, name: 'Th', code: 'thu', enabled: false },
    { id: 5, name: 'Fr', code: 'fri', enabled: false },
    { id: 6, name: 'Sa', code: 'sat', enabled: false },
    { id: 7, name: 'Su', code: 'sun', enabled: false }
  ];

  periodFormGroup: FormGroup;
  formDays: FormGroup;
  isFormBootstraped: boolean = false;
  showFormDays: boolean = true;
  allDaysSelected: boolean = false;

  isResearchActive: boolean = false;
  isModelConfirmed: boolean = false;
  isHintVisible: boolean = false;
  isWarningVisible: boolean = false;
  researchConfirmEnabled: boolean = false;
  researchResetEnabled: boolean = false;
  researchModelValid: boolean = false;

  currentTimezoneString: string;
  currentQuestionnaireId: number;

  currentDate: string = new Date().toJSON().split('T')[0];
  maxDate: string;
  nextDate: string;

  constructor(
    private formBuilder: FormBuilder,
    private sharedPackageService: SharedPackageService,
    private questionnaireService: QuestionnaireService,
    public dialog: MatDialog
  ) {
    this.periodFormGroup = this.formBuilder.group({
      interval: ['', Validators.required],
      startDay: ['', Validators.required],
      startTime: ['', Validators.required],
      finishDay: ['', Validators.required],
      finishTime: ['', Validators.required],
    });

    // console.log('Constructor!');

    this.formDays = this.formBuilder.group({
      // days: new FormArray([], minSelectedCheckboxes(1))
      days: new FormArray([])
    });
  }

  ngOnInit(): void {
    this.setFormDisabled();
    this.sharedPackageService.questionnaireDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((questionnaire: Questionnaire) => {
        if (questionnaire) {
          if (!this.isFormBootstraped) {
            this.setInitialFormState(questionnaire);
          }

          const questionnaireId = questionnaire.id;
          this.currentQuestionnaireId = questionnaireId;
          const activeStatus = questionnaire.activeStatus;
          if (activeStatus) {
            this.checkActiveStatus(activeStatus);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private addCheckboxes(): any {
    this.daysEnabled.forEach((dayObject, index) => {
      const control = new FormControl(dayObject.enabled);
      (this.formDays.controls.days as FormArray).push(control);
    });
  }

  setInitialFormState(questionnaire: Questionnaire): void {
    if (questionnaire && (questionnaire.id > 0)) {
      this.isFormBootstraped = true;
      this.formEnabledDays(questionnaire.daysEnabled);
      const timeZone = moment.tz.guess();
      this.currentTimezoneString = timeZone;
      this.checkInitInterval();

      const currentInterval = questionnaire.interval;
      const currentStartDate = questionnaire.startDate;
      const currentFinishDate = questionnaire.finishDate;
      if (currentInterval && currentStartDate && currentFinishDate) {
        const timeFormat = 'YYYY-MM-DD HH:mm:ss';

        const startArray = moment(currentStartDate).tz(timeZone).format(timeFormat).split(' ');
        const finishArray = moment(currentFinishDate).tz(timeZone).format(timeFormat).split(' ');

        this.periodFormGroup = this.formBuilder.group({
          interval: [currentInterval, Validators.required],
          startDay: [startArray[0], Validators.required],
          startTime: [startArray[1], Validators.required],
          finishDay: [finishArray[0], Validators.required],
          finishTime: [finishArray[1], Validators.required],
        });
        this.researchResetEnabled = true;
      }

      setTimeout(() => {
        const activeStatus = questionnaire.activeStatus;
        if (activeStatus) {
          this.checkActiveStatus(activeStatus);
          this.checkInitInterval();
        }
       }, 10);
    }
  }

  checkInitInterval(): void {
    const interval = this.periodFormGroup.value.interval;
    if (interval && (interval === 'daily')) {
      this.showFormDays = true;
    } else {
      this.showFormDays = false;
    }
  }

  onCheckAllSelectedDays(): void {
    setTimeout(() => {
      const unselected = this.formDays['controls']['days']['value'].some(day => day === false);
      this.allDaysSelected = !unselected;
      if (unselected) {
        this.allDaysSelected = !unselected;
      }
    }, 100);
  }

  setFormDisabled(): void {
    // console.log('SET FORM DISABLED');
    this.periodFormGroup.disable();
    this.formDays.disable();
  }

  setFormEnabled(): void {
    // console.log('SET FORM ENABLED');
    this.periodFormGroup.enable();
    this.formDays.enable();
  }

  resetPeriodForm(): void {
    this.periodFormGroup = this.formBuilder.group({
      interval: ['', Validators.required],
      startDay: ['', Validators.required],
      startTime: ['', Validators.required],
      finishDay: ['', Validators.required],
      finishTime: ['', Validators.required],
    });

    this.checkConfirmEnabled();
    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
    this.isWarningVisible = false;
    this.isHintVisible = false;
  }

  onCheckStartDay(startDay: string): void {
    if (startDay < this.currentDate || startDay > this.maxDate || startDay === '') {
      this.periodFormGroup.get('startDay').setValue(this.currentDate);
    } else if (this.periodFormGroup.value.startTime) {
      this.onCheckStartTime(this.periodFormGroup.value.startTime);
    }

    this.checkConfirmEnabled();
    this.updateNextDate();
    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
  }

  updateNextDate(): void {
    const interval = this.periodFormGroup.value.interval;
    const timeFormat = 'YYYY-MM-DD';
    if (interval) {
      this.maxDate = moment().add(1, 'year').format(timeFormat);
      this.nextDate = moment().add(2, 'days').format(timeFormat);

      const nextDayDate = moment().add(1, 'day').format(timeFormat);
      const nextWeekDate = moment().add(8, 'days').format(timeFormat);
      const nextMonthDate = moment().add(1, 'month').format(timeFormat);
      const nextQuarterDate = moment().add(3, 'months').format(timeFormat);
      const nextYearDate = moment().add(1, 'year').format(timeFormat);

      if (interval === 'daily') {
        this.nextDate = nextDayDate;
        this.showFormDays = true;
      } else if (interval === 'weekly') {
        this.nextDate = nextWeekDate;
        this.enableAllDays();
      } else if (interval === 'monthly') {
        this.nextDate = nextMonthDate;
        this.enableAllDays();
      } else if (interval === 'quarterly') {
        this.nextDate = nextQuarterDate;
        this.enableAllDays();
      } else if (interval === 'yearly') {
        this.nextDate = nextYearDate;
        this.enableAllDays();
      }
    }
  }

  onIntervalUpdate(event: any): void {
    this.updateNextDate();
    this.researchConfirmEnabled = false;
  }

  checkAll(): void {
    this.onCheckStartDay(this.periodFormGroup.value.startDay);
    this.onCheckStartTime(this.periodFormGroup.value.startTime);
    this.onCheckFinishDay(this.periodFormGroup.value.finishDay);
    this.onCheckFinishTime(this.periodFormGroup.value.finishTime);
  }

  formEnabledDays(daysEnabledString: string): any {
    const days = this.parseEnabledString(daysEnabledString);
    this.daysEnabled = days;
    this.onCheckAllSelectedDays();
    this.addCheckboxes();
  }

  onCheckStartTime(startTime: string): void {
    const currentTime = new Date().toTimeString().split('G')[0];
    const currentMoment = new Date();
    currentMoment.setMinutes(currentMoment.getMinutes() + 1);
    const newTime = new Date(currentMoment).toTimeString().split('G')[0];
    if (this.periodFormGroup.value.startDay === this.currentDate && (startTime <= currentTime) || startTime === '') {
      this.periodFormGroup.get('startTime').setValue(newTime.slice(0, -4));
    }
    this.checkConfirmEnabled();
    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
  }

  onCheckFinishDay(finishDay: string): void {
    const timeFormat = 'YYYY-MM-DD';
    let finishDate;

    if (this.periodFormGroup.value.interval === 'daily') {
      finishDate = moment().add(1, 'day').format(timeFormat);
    } else if (this.periodFormGroup.value.interval === 'weekly') {
      finishDate = moment().add(8, 'days').format(timeFormat);
    } else if (this.periodFormGroup.value.interval === 'monthly') {
      finishDate = moment().add(1, 'month').format(timeFormat);
    } else if (this.periodFormGroup.value.interval === 'quarterly') {
      finishDate = moment().add(3, 'months').format(timeFormat);
    } else if (this.periodFormGroup.value.interval === 'yearly') {
      finishDate = moment().add(1, 'year').format(timeFormat);
    } else if (finishDate > this.maxDate) {
      finishDate = this.maxDate;
    }

    if (finishDay < this.periodFormGroup.value.startDay || finishDay > this.maxDate || finishDay === '' || finishDay < finishDate) {
      this.periodFormGroup.get('finishDay').setValue(finishDate);
    }
    this.checkConfirmEnabled();
    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
  }

  onCheckFinishTime(finishTime: string): void {
    const currentTime = new Date().toTimeString().split('G')[0];
    const currentMoment = new Date();
    currentMoment.setMinutes(currentMoment.getMinutes() + 1);
    const newTime = new Date(currentMoment).toTimeString().split('G')[0];

    if (this.periodFormGroup.value.startDay === this.currentDate && (finishTime <= currentTime) || finishTime === '') {
      this.periodFormGroup.get('finishTime').setValue(newTime.slice(0, -4));
    }

    this.checkConfirmEnabled();
    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
  }

  checkConfirmEnabled(): void {
    this.isModelConfirmed = false;
    this.onUpdateModelConfirmed.emit(false);

    const isModelValid = this.checkModelValid();
    if (isModelValid && !this.isResearchActive) {
      this.researchConfirmEnabled = true;
    } else {
      this.researchConfirmEnabled = false;
    }
  }

  checkModelValid(): boolean {
    let isValid = false;

    const interval = this.periodFormGroup.value.interval;
    const startDay = this.periodFormGroup.value.startDay;
    const startTime = this.periodFormGroup.value.startTime;
    const finishDay = this.periodFormGroup.value.finishDay;
    const finishTime = this.periodFormGroup.value.finishTime;

    if (interval && startDay && startTime && finishDay && finishTime) {
      isValid = true;
      this.isWarningVisible = false;

      if (finishDay <= startDay) {
        const fullStartDate = moment(startDay + 'T' + startTime);
        const fullFinishDate = moment(finishDay + 'T' + finishTime);

        const isBefore = fullStartDate.isBefore(fullFinishDate);
        if (!isBefore) {
          isValid = false;
        }
      } else {
        const fullStartDate = moment(startDay + 'T' + startTime);
        const nowDate = moment();

        const isPast = fullStartDate.isBefore(nowDate);
        if (isPast) {
          isValid = false;
        }
      }

    } else {
      isValid = false;
      this.isWarningVisible = true;
    }

    return isValid;
  }

  parseEnabledString(daysEnabled: any): any {
    const days = JSON.parse(JSON.stringify(this.daysTemplate));
    if (daysEnabled === '') {
      daysEnabled = 'mon,tue,wed,thu,fri,sat,sun';
    }

    const parsedDays = daysEnabled.split(',');
    let enabledCount = 0;

    for (const singleValue of parsedDays) {
      for (const day of days) {
        if (singleValue === day.code) {
          day.enabled = true;
          enabledCount = enabledCount + 1;
        }
      }
    }

    return days;
  }

  enableAllDays(): void {
    this.showFormDays = false;
  }

  onConfirm(): void {
    this.checkAll();

    const isValid = this.checkModelValid();
    this.researchModelValid = isValid;
    const questionnaireId = this.currentQuestionnaireId;

    if (questionnaireId && isValid) {
      this.isModelConfirmed = true;
      this.onUpdateModelConfirmed.emit(true);
      this.isHintVisible = true;
    }
  }

  checkActiveStatus(activeStatus: string): void {
    switch (activeStatus) {
      case 'active':
        this.setActiveLocalState();
        break;
      case 'paused':
        this.setPausedLocalState();
        break;
      case 'finished':
        this.setFinishedLocalState();
        break;
      default:
        this.setIdleLocalState();
        break;
    }

    this.onUpdateResearchActive.emit(this.isResearchActive);
    // this.onUpdateConfirmEnabled.emit(this.researchConfirmEnabled);
  }

  setActiveLocalState(): void {
    this.isResearchActive = true;
    this.researchConfirmEnabled = false;
    this.researchResetEnabled = false;
    this.setFormDisabled();
  }

  setPausedLocalState(): void {
    this.isResearchActive = false;
    this.researchConfirmEnabled = false;
    this.researchResetEnabled = true;
    this.setFormEnabled();
  }

  setFinishedLocalState(): void {
    this.isResearchActive = false;
    this.setFormEnabled();
  }

  setIdleLocalState(): void {
    this.researchConfirmEnabled = false;
    this.researchResetEnabled = true;
    this.setFormEnabled();
  }

  getCurrentForm(): PeriodModel {
    const periodModel = this.periodFormGroup.value;
    return periodModel;
  }

  getTimezoneString(): string {
    return this.currentTimezoneString;
  }

  getEnabledDaysString(): string {
    let resultString = '';

    if (!this.showFormDays) {
      return 'mon,tue,wed,thu,fri,sat,sun';
    }

    const enabledDays = this.formDays.value.days;
    let counter = 1;
    for (const singleDayEnabled of enabledDays) {
      if (singleDayEnabled === true) {
        const day = this.daysTemplate.find(tempDay => tempDay.id === counter);
        if (day) {
          resultString = resultString + day.code + ',';
        }
      }
      counter = counter + 1;
    }

    return resultString;
  }

}
