<mat-spinner *ngIf="!isLoaded" style="margin: 20px;"></mat-spinner>

<div [ngClass]="{'widget': !isPublicLink}" class="text-card-widget">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div [class.widget__container]="!editMode" [class.widget__edit]="editMode">
    <div class="widget-header">
      <mat-form-field *ngIf="!editMode" class="widget__select">
        <mat-label i18n="@@dashboard-select-department">Select department</mat-label>
        <mat-select [(ngModel)]="activeDepartmentId" (selectionChange)="setDepartmentValue()">
          <ng-container *ngFor="let singleDepartment of localDepartments">
            <mat-option value="{{singleDepartment.id}}">{{singleDepartment.name}}</mat-option>
          </ng-container>
          <mat-option value="0" i18n="@@dashboard-department-no-filter">No filter</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!editMode" class="widget__select">
        <mat-label i18n="@@dashboard-select-colleague">Select colleague</mat-label>
        <mat-select [(ngModel)]="activeColleagueId" (selectionChange)="setActiveColleagueName()">
          <ng-container *ngFor="let colleague of selectedColleaguesList">
            <mat-option value="{{colleague.id}}">{{colleague.firstName}} {{colleague.lastName}}</mat-option>
          </ng-container>
          <mat-option value="0" i18n="@@dashboard-colleague-no-filter">No filter</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="colleaguesCardMode">
      <ng-container *ngIf="hasFilter">
        <ng-container *ngFor="let filteredCard of filteredInfoCards">
          <ng-container *ngIf="filteredCard.infoCards.length > 0">
            <div class="card">
              <div class="card__title">
                {{filteredCard.filteredParameterValue}}
              </div>
              <div *ngFor="let infoCard of filteredCard.infoCards; let lastCard = last;">
                <mat-card class="info-card">
                  <mat-card-header class="info-card__header">
                    <div mat-card-avatar class="info-card__avatar colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
                    <mat-card-title class="info-card__name">{{infoCard.fullName}}</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <ng-container *ngFor="let singleParameter of infoCard.parameters">
                      <p>
                        <span *ngIf="showParametersNames" class="info-card__parameter">{{singleParameter.name}}: </span>
                        <span [innerHtml]="singleParameter.value | safeHtml"></span>
                      </p>
                    </ng-container>
                  </mat-card-content>
                </mat-card>
                <ng-container *ngIf="!lastCard">
                  <mat-divider class="divider-card-style"></mat-divider>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="hasRelativeFilter">
        <div *ngFor="let relativeCard of filteredRelativeCards;" class="relative-card">
          <ng-container *ngIf="relativeCard.infoCards.length > 0">
            <div class="card__title">
              {{relativeCard.relativeColleagueName}}
            </div>
          </ng-container>
          <div *ngFor="let infoCard of relativeCard.infoCards; let lastCard = last;">
            <mat-card class="info-card">
              <mat-card-header class="info-card__header">
                <div mat-card-avatar class="info-card__avatar colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
                <mat-card-title class="info-card__name">{{infoCard.fullName}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <ng-container *ngFor="let singleParameter of infoCard.parameters">
                  <p>
                    <span *ngIf="showParametersNames" class="info-card__parameter">{{singleParameter.name}}: </span>
                    <span [innerHtml]="singleParameter.value | safeHtml"></span>
                  </p>
                </ng-container>
              </mat-card-content>
            </mat-card>
            <ng-container *ngIf="!lastCard">
              <mat-divider class="divider-card-style"></mat-divider>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!hasFilter&&!hasRelativeFilter">
        <div *ngFor="let infoCard of infoCards; let last = last;" class="card">
          <mat-card class="info-card">
            <mat-card-header class="info-card__header">
              <div mat-card-avatar class="info-card__avatar colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
              <mat-card-title class="info-card__name">{{infoCard.fullName}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <ng-container *ngFor="let singleParameter of infoCard.parameters">
                <p>
                  <span *ngIf="showParametersNames" class="info-card__parameter">{{singleParameter.name}}: </span>
                  <span [innerHtml]="singleParameter.value | safeHtml"></span>
                </p>
              </ng-container>
            </mat-card-content>
          </mat-card>
          <ng-container *ngIf="!last">
            <mat-divider class="divider-card-style"></mat-divider>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <mat-card *ngIf="colleaguesEditMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <button mat-raised-button color="accent" i18n="@@selectAllParticipants" (click)="setActiveParticipants()">
          Select all participants
        </button>
      </div>
      <div *ngFor="let colleague of colleagues">
        <div class="parameters-list__item">
          <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()">
            {{colleague.firstName}} {{colleague.lastName}}
          </mat-checkbox>
        </div>
      </div>
    </mat-card> <!-- /.colleagues-list -->

    <mat-card *ngIf="parametersEditMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <button mat-raised-button color="accent" i18n="@@selectAllParameters" (click)="setActiveIndividualParameters()">
          Select all parameters
        </button>
      </div>
      <div *ngFor="let parameter of individualParameters">
        <div class="parameters-list__item">
          <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onParametersStateChange()">
            {{parameter.visibleName}}
          </mat-checkbox>
        </div>
      </div>
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="showParametersNames" (change)="onParametersStateChange()">
          <b i18n="@@showIndividualParametersList">Show individual parameters names </b>
        </mat-checkbox>
      </div>
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="hideEmptyCards" (change)="onParametersStateChange()">
          <b i18n="@@hideEmptyCards">Hide empty cards </b>
        </mat-checkbox>
      </div>
    </mat-card> <!-- /.individual-parameters -->

    <mat-card *ngIf="useRelativeMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <button mat-raised-button color="accent" i18n="@@selectAllParameters" (click)="setActiveRelativeParameters()">
          Select all parameters
        </button>
      </div>
      <div *ngFor="let parameter of relativeParameters">
        <div class="parameters-list__item">
          <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onRelativeParametersChange()">
            {{parameter.visibleName}}
          </mat-checkbox>
        </div>
      </div>
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="hideEmptyCards" (change)="onRelativeParametersChange()">
          <b i18n="@@hideEmptyCards">Hide empty cards </b>
        </mat-checkbox>
      </div>
    </mat-card> <!-- /.relative-parameters -->

    <mat-card *ngIf="researchesEditMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
          <mat-radio-button class="parameters-list__radio" value="last_research" i18n="@@lastResearch">
            Last research
          </mat-radio-button>
          <mat-radio-button class="parameters-list__radio"
            *ngFor="let research of experiments" [value]="research">
            {{research.startDate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card> <!-- /.researches-parameters -->

    <mat-card *ngIf="parameterGroupMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <mat-radio-group [(ngModel)]="parameterFilter" (change)="onParameterFilterChange($event)">
          <mat-radio-button class="parameters-list__radio"
            *ngFor="let parameter of individualParameters" [value]="parameter">
            {{parameter.visibleName}}
          </mat-radio-button>
          <mat-radio-button class="parameters-list__radio" [value]="null" i18n="@@noFilter">
            No filter
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card> <!-- /.group-mode -->
  </div> <!-- /.widget__container -->
</div> <!-- /.widget -->


<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showIndividualParametersList">Show individual parameters list</span>
  </button>
  <button mat-menu-item (click)="onRelativeUseMode()">
    <mat-icon>record_voice_over</mat-icon>
    <span i18n="@@showRelativeParametersList">Show relative parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="onParameterGroupMode()">
    <mat-icon>sort_by_alpha</mat-icon>
    <span i18n="@@sortByParameter">Group by parameter</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
