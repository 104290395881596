import { environment } from '../../environments/environment';
import { ColleagueTableConfig } from 'app/_models/colleague-table-config';
import { ApiColleagueTableConfig } from 'app/_api_models/api-colleague-table-config';

export function parseApiColleagueTableConfig(apiResponse: any): ColleagueTableConfig {
  let colleagueTableConfig;

  if (environment.production) {
    colleagueTableConfig = apiResponse;
  } else {
    colleagueTableConfig = ApiColleagueTableConfig.check(apiResponse);
  }

  const newColleagueTableConfig: ColleagueTableConfig = {
    id: colleagueTableConfig.id,
    packageId: colleagueTableConfig.package_id,
    isVisible: colleagueTableConfig.is_visible,
    orderPosition: colleagueTableConfig.order_position
  };

  return newColleagueTableConfig;
}
