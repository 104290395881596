<div layout="row" layout-padding>
<div flex="30"></div>

<div flex>
<mat-card class="mat-subhead">

	<div *ngIf="token" i18n="@@confirmation-title">
	  Thank you for confirmation!
	</div>

	<div *ngIf="resetToken">
		<div *ngIf="!hideForm">
			<h2 class="login" i18n="@@confirmation-newPassword">New password</h2>
		  <form name="form" (ngSubmit)="f.form.valid && performAction()" #f="ngForm" novalidate>
		  	<mat-form-field class="form-group"
		      [ngClass]="{ 'has-error': f.submitted && !password.valid }">
					<mat-placeholder class="placeholder" i18n="@@confirmation-password">Password</mat-placeholder>
		        <input matInput type="password" class="form-control" name="password"
		          [(ngModel)]="model.password" #password="ngModel" required />
		        <div *ngIf="f.submitted && !password.valid" class="help-block" i18n="@@confirmation-passwordRequired">Password is required</div>
		    </mat-form-field>
		    
		    <mat-form-field class="form-group" 
		      [ngClass]="{ 'has-error': f.submitted && !password_confirmation.valid }">
					<mat-placeholder class="placeholder" i18n="@@confirmation-passwordConfirmation">Password confirmation</mat-placeholder>
		        <input matInput type="password" class="form-control" name="password_confirmation"
		          [(ngModel)]="model.password_confirmation" #password_confirmation="ngModel" required />
		        <div *ngIf="f.submitted && !password_confirmation.valid" class="help-block" i18n="@@confirmation-required">Confirmation is required</div>
		    </mat-form-field>

		    <button [disabled]="loading" mat-raised-button color="primary" i18n="@@confirmation-sendNewPassword">Send New Password</button>
		    <p></p>
		    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
		  </form>
		</div>
	</div>

	<div *ngIf="hideForm" class="col-mat-4 col-mat-offset-4">
	  <h2 class="login"  i18n="@@confirmation-passwordUpdated">The password has been successfully updated</h2>
	</div>

</mat-card>
</div>


<div flex="30"></div>
</div>