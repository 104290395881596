import { ApiPackageParameter } from 'app/_api_models/api-package-parameter';
import { PackageParameter } from 'app/_models/package-parameter';
import { environment } from '../../environments/environment';

export function parseApiPackageParameter(apiResponse: any): PackageParameter {
  let parameterObject;
  if (environment.production) {
    parameterObject = apiResponse;
  } else {
    parameterObject = ApiPackageParameter.check(apiResponse);
  }

  const newParameter: PackageParameter = {
    id: parameterObject.id,
    formulaName: parameterObject.formula_name,
    visibleName: parameterObject.visible_name,
    groupName: parameterObject.group_name,
    commonValue: parameterObject.common_value,
    packageId: parameterObject.package_id,
  };

  return newParameter;
}
