<div *ngIf="true" class="dialog-style">
  <div mat-dialog-content>
    <h2 align="center" i18n="@@dialogUpload-title">Uploading colleagues list from the file</h2>
    <p i18n="@@dialogUpload-hint">To upload colleagues list please attach the CSV file (the "comma-separated values"), where the column titles should be "Lastname", "FirstName", "Email", "Department"</p>
    <div class="buttons-box two-buttons">
      <button
        i18n="@@loadFromFileButton"
        mat-raised-button
        color="accent"
        (click)="fileUpload.click($event)"
        [disabled]="uploadDisabled"
      >
        Load from file
      </button>
      <!-- (click)="onLoadClick($event)" -->

      <input type="file" class="file-input" 
        [accept]="acceptedFileTypes" (change)="onFileSelected($event)" [disabled]="uploadDisabled" #fileUpload>
      
      <!-- <td-file-input
        #fileInput
        (select)="selectEvent($event)"
        accept=".csv"
        [style.display]="'none'"
      >
      </td-file-input> -->

      <button
          i18n="@@cancelButton"
          mat-dialog-close
          type="button"
          mat-raised-button
          color="primary"
          alt="close"
          [disabled]="uploadDisabled">
        Cancel
      </button>
    </div>
  </div>
</div>