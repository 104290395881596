import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { parseApiUser } from 'app/_api_validation/parse-api-user';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { User } from 'app/_models/user';
import { HomePageInfo } from 'app/_models/home-page-info';
import { parseApiHomePageInfo } from 'app/_api_validation/parse-api-home-page-info';
import { AdvancedAdminConfig } from 'app/_models/advanced-admin-config';
import { parseApiAdvancedAdminConfig } from 'app/_api_validation/parse-api-advanced-admin-config';

@Injectable()
export class UserService {
  private usersUrl: string = environment.API_ENDPOINT + '/signup';
  private quickStartUrl: string = environment.API_ENDPOINT + '/quickstart';
  private resetUrl: string = environment.API_ENDPOINT + '/reset';
  private newPasswordUrl: string = environment.API_ENDPOINT + '/new_password';
  private confirmUrl: string = environment.API_ENDPOINT + '/confirm';
  private userInfoUrl: string = environment.API_ENDPOINT + '/user_info';
  private userLocaleUrl: string = environment.API_ENDPOINT + '/user_locale';
  private feedbackUrl: string = environment.API_ENDPOINT + '/feedback';
  private sendInBlueTestUrl: string = environment.API_ENDPOINT + '/send_in_blue_test';
  private setActiveCompanyUrl: string = environment.API_ENDPOINT + '/set_active_company';
  private homePageInfosUrl: string = environment.API_ENDPOINT + '/v1/home_page_infos';
  private advancedAdminConfigsUrl: string = environment.API_ENDPOINT + '/v1/advanced_admin_configs';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  sendInBlueTest(): Observable<any> {
    const item = JSON.stringify({});
    return this.http
      .post<any>(this.sendInBlueTestUrl, item, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  sendFeedback(theme: string, body: string): Observable<any> {
    const item = JSON.stringify({ theme: theme, body: body });
    return this.http
      .post<any>(this.feedbackUrl, item, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  addNewUser( name: string, lastName: string, email: string,
    password: string): Observable<any> {
      const item = JSON.stringify({ name: name, last_name: lastName,  email: email,
        password: password });

      return this.http
        .post<any>(this.usersUrl, item, this.getHttpOptions())
        .pipe(
          map((response: any) => response),
          catchError(this.handleError)
        );
  }

  addQuickstartUser( name: string, lastName: string, email: string,
      password: string): Observable<User> {
    const item = JSON.stringify({ name: name, last_name: lastName,  email: email,
      password: password });

    return this.http
      .post<User>(this.quickStartUrl, item, this.getHttpOptions())
      .pipe(
        // map((response: any) => this.parseUser(response)),
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getHomePageInfos(): Observable<HomePageInfo[]> {
    const url = `${this.homePageInfosUrl}`;

    return this.http
      .get<HomePageInfo[]>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseHomePageInfo(response.data)),
        catchError(this.handleError)
      );
  }

  updateHomePageInfo(id: number, updatedFields: any): Observable<HomePageInfo> {
    const url = `${this.homePageInfosUrl}/${id}`;
    const newInfo = JSON.stringify(updatedFields);

    return this.http
      .put<HomePageInfo>(url, newInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseSingleHomePageInfo(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }

  getUserInfo(id: number): Observable<User> {
    const url = `${this.userInfoUrl}/${id}`;

    return this.http
      .get<User>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseUser(response)),
        catchError(this.handleError)
      );
  }

  updateUserInfo(id: number, params: any): Observable<User> {
    const url = `${this.userInfoUrl}/${id}`;
    const userInfo = JSON.stringify(params);

    return this.http
      .put<User>(url, userInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseUser(response)),
        catchError(this.handleError)
      );
  }

  getAdvancedAdminConfig(id: number): Observable<AdvancedAdminConfig> {
    const url = `${this.advancedAdminConfigsUrl}/${id}`;

    return this.http
      .get<AdvancedAdminConfig>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseAdvancedAdminConfig(response)),
        catchError(this.handleError)
      );
  }

  updateAdvancedAdminConfig(id: number, params: any): Observable<AdvancedAdminConfig> {
    const url = `${this.advancedAdminConfigsUrl}/${id}`;
    const configInfo = JSON.stringify(params);

    return this.http
      .put<AdvancedAdminConfig>(url, configInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseAdvancedAdminConfig(response)),
        catchError(this.handleError)
      );
  }

  updateUserLocale(id: number, newLocale: string): Observable<User> {
    const url = `${this.userLocaleUrl}/${id}`;
    const userInfo = JSON.stringify({ current_locale: newLocale });

    return this.http
      .post<User>(url, userInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseUser(response)),
        catchError(this.handleError)
      );
  }

  setActiveCompany(companyId: number): Observable<any> {
    const item = JSON.stringify({ active_company_id: companyId });

    return this.http
      .post<any>(this.setActiveCompanyUrl, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  resetPassword(email: string): Observable<any> {
    const item = JSON.stringify({ email: email });

    return this.http
      .post<any>(this.resetUrl, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  sendNewPassword(token: string, password: string): Observable<any> {
    const item = JSON.stringify({ token: token, password: password });

    return this.http
      .post<any>(this.newPasswordUrl, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  sendConfirmToken(token: string): Observable<any> {
    const item = JSON.stringify({ token: token });

    return this.http
      .post<any>(this.confirmUrl, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  private parseUser(apiResponse: any): User {
    return parseApiUser(apiResponse);
  }

  private parseAdvancedAdminConfig(apiResponse: any): AdvancedAdminConfig {
    return parseApiAdvancedAdminConfig(apiResponse.data.attributes);
  }

  private parseSingleHomePageInfo(apiResponse: any): HomePageInfo {
    return parseApiHomePageInfo(apiResponse);
  }

  private parseHomePageInfo(apiResponse: any): HomePageInfo[] {
    const mappedData = apiResponse.map(x => this.parseSingleHomePageInfo(x.attributes) as HomePageInfo);
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error); // for demo purposes only
    return throwError(error);
    // return Observable.throw('Some error information');
  }

}
