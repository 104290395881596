import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'app/_services/user.service';

@Component({
  selector: 'app-confirmations',
  templateUrl: './confirmations.component.html',
  styleUrls: ['./confirmations.component.scss']
  // styles: [String(require('./confirmations.component.scss'))]
})
export class ConfirmationsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  model: any = {};
  hideForm: boolean = false;
  loading: boolean = false;
  token: string = '';
  resetToken: string = '';
  error: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService) { }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.token = params['token'];
        this.resetToken = params['resetToken'];
        if (this.token) {
          this.sendConfirmToken(this.token);
        } else if (this.resetToken) {
          // this.sendNewPassword(this.resetToken, '999111');
        }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  performAction(): void {
    this.loading = true;
    const item = this.model;
    if (item.password !== item.password_confirmation) {
      this.loading = false;
      this.error = 'Passwords don\'t match';
    } else {
      this.loading = false;
      this.sendNewPassword(this.resetToken, item.password);
    }
  }

  sendNewPassword(resetToken: string, password: string): void {
    if (resetToken && password) {
      this.userService.sendNewPassword(resetToken, password)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          this.hideForm = true;
          this.router.navigate(['/']);
        },
        error => {
          console.log(error);
          this.error = 'Invalid password(min: 6 symbols)';
        });
    }
  }

  sendConfirmToken(token: string): void {
    if (!token) {
      return;
    }

    this.userService.sendConfirmToken(token)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        // console.log('RESULT IS CONFIRM')
        this.router.navigate(['/']);
      },
      error => {
        // console.log(error);
        // console.log('ERROR IS HERE');
        this.error = 'Invalid token';
      });
  }

}
