import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ApiParameter } from 'app/_api_models/overmind_ds/api-parameter';
import { environment } from '../../../environments/environment';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';
import { ApiExtendedParameter } from 'app/_api_models/overmind_ds/api-extended-parameter';
import { ParameterData } from 'app/_models/overmind_ds/parameter-data';
import { parseApiParameterData } from './parse-api-parameter-data';

export function parseApiExtendedParameter(apiResponse: any): ExtendedParameter {
  let extendedParameter;

  if (environment.production) {
    extendedParameter = apiResponse;
  } else {
    extendedParameter = ApiExtendedParameter.check(apiResponse);
  }

  let parameterDataArray: ParameterData[] = [];

  for (const singleParameterData of extendedParameter.parameter_data) {
    let parameterData = parseApiParameterData(singleParameterData);
    parameterDataArray.push(parameterData);
  }

  const newExtendedParameter: ExtendedParameter = {
    id: extendedParameter.id,
    uuid: extendedParameter.uuid,
    entityUuid: extendedParameter.entity_uuid,
    formulaName: extendedParameter.formula_name,
    name: extendedParameter.name,
    type: extendedParameter.type,
    formula: extendedParameter.formula,
    parameterData: parameterDataArray
  };

  return newExtendedParameter;
}
