<div *ngIf="isAdmin">
  <div class="toolbar-style">
    <button mat-icon-button matTooltip="Add card view" (click)="onAddTextWidget()">
      <mat-icon>assignment</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Add heat map" (click)="onAddHeatmapWidget()">
      <mat-icon>map</mat-icon>
    </button>
    <!-- <button mat-icon-button matTooltip="Add common parameters chart" (click)="onAddLineCommonWidget()">
      <mat-icon>show_chart</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Add brief statistics" (click)="onAddBriefStatistics()">
      <mat-icon>functions</mat-icon>
    </button> -->
    <button mat-icon-button matTooltip="Add separator" (click)="onAddSeparatorWidget()">
      <mat-icon>title</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Add description card" (click)="onAddDescriptionCardWidget()">
      <mat-icon>text_format</mat-icon>
    </button>
    <button mat-icon-button matTooltip="Add bar chart" (click)="onAddSharedBarWidget()">
      <mat-icon>bar_chart</mat-icon>
    </button>

    <button
        mat-raised-button
        color="accent"
        class="create-dashboard-button"
        (click)="onSaveDashboard()">
      Save dashboard
    </button>
  </div>

  <div *ngFor="let widget of sharedWidgets">
    <div *ngIf="widget.widgetName === 'card-text-widget'">
      <app-shared-text-card-widget
        [userView]="false"
        [isLoaded]="isLoaded"
        [isPublicLink]="false"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-text-card-widget>
    </div>

    <div *ngIf="widget.widgetName === 'heatmap-individual-widget'">
      <app-shared-heatmap-widget
        [userView]="false"
        [isLoaded]="isLoaded"
        [isPublicLink]="false"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-heatmap-widget>
    </div>

    <div *ngIf="widget.widgetName === 'shared-bar-widget'">
      <app-shared-bar-widget 
        [userView]="false"
        [isLoaded]="isLoaded" 
        [isPublicLink]="false" 
        [testData]="false"
        [inputWidgetId]="widget.id" 
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)" 
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-bar-widget>
    </div>

    <!-- <div *ngIf="widget.widgetName === 'line-common-widget'">
      <app-line-common-widget
        [userView]="false"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-line-common-widget>
    </div> -->

    <div *ngIf="widget.widgetName === 'brief-statistics-widget'">
      <app-passage-brief-statistics
        [userView]="false"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-passage-brief-statistics>
    </div>
    <div *ngIf="widget.widgetName === 'separator-widget'">
      <app-separator-widget
        [userView]="false"
        [widgetTitle]="widget.widgetTitle"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-separator-widget>
    </div>
    <div *ngIf="widget.widgetName === 'description-card-widget'">
      <app-description-card-widget
        [userView]="false"
        [widgetTitle]="widget.widgetTitle"
        [isPublicLink]="false"
        [sharedDashboardMode]="true"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-description-card-widget>
    </div>
  </div>

</div>