<mat-card class="mat-card-class">
  <p>Card description preview</p>
  <div *ngIf="!editorEnabled" class="custom-description">
    <div class="description-preview ql-editor">
      <!-- <h2 class="description-preview-title">{{cardId}}</h2> -->
      <div *ngIf="cardDescription" [innerHtml]="cardDescription | safeHtml"></div>
      <div *ngIf="!cardDescription">Card description is missed.
        Click to "Edit description" add it.</div>
    </div>
    <div class="buttons-box">
      <button mat-raised-button color="accent" class="description-button" (click)="setEditorEnabled()">
        Edit description
      </button>
    </div>
  </div>
  <!-- [styles]="{ 'max-width': '400px' }" -->
  <div *ngIf="editorEnabled">
    <quill-editor
      [(ngModel)]="content" 
      [modules]="modules"
      (onContentChanged)="contentChanged($event)"
      (onEditorCreated)="editorCreated($event)"
      (onFocus)="onFocus($event)"
      (onBlur)="onBlur($event)"
      trackChanges='all'
    >
    </quill-editor>
  </div>

</mat-card>