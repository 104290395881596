<div *ngIf="!isLoading" id="wrap-people-list">
  <h2 class="title" i18n="@@ColleaguePageTitle">Manage my team</h2>
  <div class="people-list">

    <div layout="row" class="left-content-part">
      <mat-accordion flex>
        <ng-container *ngIf="departmentsList !== null">
          <mat-expansion-panel class="departments-style" *ngFor="let department of departmentsList">
            <mat-expansion-panel-header>
              <mat-panel-title style="align-items: center;">
                {{department.name}}
              </mat-panel-title>
              <mat-panel-description>
                <button
                  color="primary"
                  class="item-button delete-item-button"
                  mat-raised-button
                  (click)="onRemoveDepartment(department); $event.stopPropagation()">
                  DELETE
                </button>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngFor="let colleague of colleaguesList">
              <ng-container *ngIf="colleague.departmentId === department.id">
                <div class="colleague-item">
                  <div class="colleague-info-block" flex>
                    <span class="colleague-avatar">{{colleague.firstName | slice : 0 : 1}}</span>
                    <span class="colleague-text-data">
                      <span class="colleague-name">{{colleague.firstName}}<span
                          class="colleague-lastname">{{colleague.lastName}}</span></span>
                      <span class="colleague-email">{{colleague.email}}</span>
                    </span>
                  </div>
                  <div class="colleague-actions-block">
                    <button class="item-button info-item-button" (click)="onInfoPage(colleague.id)"></button>
                    <button class="item-button edit-item-button" (click)="onEditPerson(colleague.id)"></button>
                    <button class="item-button colleague-delete" (click)="onArchiveColleague(colleague)"></button>
                  </div>

                </div>
              </ng-container>
            </div>

            <div *ngIf="department.colleagues.length == 0" class="warning-message">
              <h3 i18n="@@warningMessage-ColleaguesTitle">You have no colleagues in this department yet :(</h3>
              <p i18n="@@warningMessage-ColleaguesBody2">Add your first colleague by the clicking on the "Add team
                member"
                button in the right sidebar</p>
            </div>

          </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="nonDepartmentColleaguesList.length > 0">
          <mat-expansion-panel class="departments-style">
            <mat-expansion-panel-header>
              <mat-panel-title i18n="@@nonDepartmentsColleagues">
                Non-department colleagues
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngFor="let nonDepartmentColleague of nonDepartmentColleaguesList">
              <div class="colleague-item">
                <div class="colleague-info-block" flex>
                  <span class="colleague-avatar">{{nonDepartmentColleague.firstName | slice : 0 : 1}}</span>
                  <span class="colleague-text-data">
                    <span class="colleague-name">{{nonDepartmentColleague.firstName}}
                      <span class="colleague-lastname">{{nonDepartmentColleague.lastName}}</span>
                    </span>
                    <span class="colleague-email">{{nonDepartmentColleague.email}}</span>
                  </span>
                </div>
                <div class="colleague-actions-block">
                  <button class="item-button edit-item-button" (click)="onEditPerson(nonDepartmentColleague.id)"></button>
                  <button class="item-button colleague-delete"
                    (click)="onArchiveColleague(nonDepartmentColleague)"></button>
                </div>

              </div>

              <div *ngIf="nonDepartmentColleaguesList.length == 0">
                <div class="warning-message">
                  <h3 i18n="@@warningMessage-ColleaguesTitle">You have no colleagues in this department yet :(</h3>
                  <p i18n="@@warningMessage-ColleaguesBody2">Add your first colleague by the clicking on the "Add team
                    member"
                    button in the right sidebar</p>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>

      <div *ngIf="departmentsList === null && nonDepartmentColleaguesList.length == 0">
        <div class="warning-message">
          <h3 i18n="@@warningMessage-neitherColleaguesNorDepartmentsTitle">You have neither colleagues nor departments
            yet :(
          </h3>
          <p i18n="@@warningMessage-neitherColleaguesNorDepartmentsBody">Please add your first colleague by the clicking
            on
            the "Add team member" button in the right sidebar or first department by the clicking on the "New
            department"
            button.</p>
        </div>
      </div>
    </div>

    <div class="right-content-part">

      <mat-card>
        <button class="add-person" (click)="goToAddNewDepartmentPage()" i18n="@@addNewDepartment">
          Add department
        </button>
        <button class="add-person" (click)="goToAddNewColleaguePage()" i18n="@@addTeamMember">
          Add team member
        </button>
        <button class="load-from-file" (click)="addFromFile()" i18n="@@loadFromFileButton">
          Load from file
        </button>
        <ng-container *ngIf="colleagueShareUrl">
          <button class="share-colleague" [cdkCopyToClipboard]="resultColleagueShareUrl" (click)="afterLinkCopied()" i18n="@@colleagueShareLink">
            Share link
          </button>
        </ng-container>
        <ng-container *ngIf="!colleagueShareUrl&&!isLoading">
          <button class="share-colleague" (click)="createSharedLink()" i18n="@@colleagueCreateSharedLink">
            Create shared link
          </button>
        </ng-container>

      </mat-card>

      <div *ngIf="isRussianLocale" style="margin-top:10px;">
        <iframe width="280" height="200" [src]='safeURL'
          frameborder="0" allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</div>


<ng-container *ngIf="isLoading">
  <div class="loading-container">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
