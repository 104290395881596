<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header">
    <div class="card-header-group">
      <h2 class="title" i18n="@@newCompany">
        New company
      </h2>
      <div class="float-left">
        <label (click)="onChangeMode()" i18n="@@cancelButton">
          Cancel
        </label>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div layout="row" layout-padding>
  <div flex class="left-content-part">
    <mat-card class="mat-title company-info-block">
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@placeholder-companyName">Company Name</mat-placeholder>
        <input matInput [(ngModel)]="newCompanyName" required/>
      </mat-form-field>

      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@placeholder-ownerEmail">Owner email</mat-placeholder>
        <input matInput [(ngModel)]="ownerEmail" required/>
      </mat-form-field>

      <div class="button-block" style="text-align: center;">
        <button [disabled]="!newCompanyName || !ownerEmail"
                class="create-button full-width-card-button"
                mat-raised-button
                color="accent"
                (click)="addCompany(newCompanyName); $event.stopPropagation()"
                i18n="@@createButton">
          Create
        </button>
      </div>

    </mat-card>
  </div>
</div>