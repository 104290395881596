<div>
    <div layout="row" layout-padding>
        <div flex class="left-content-part">
            <div class="card-header-group">
                <h2 class="title  float-left" i18n="@@companyPageTitle">
                    My company
                </h2>
                <ng-container *ngIf="actionsEnabled">
                    <div class="adding-right-block float-left">
                        <button class="adding-button"
                                mat-raised-button
                                color="primary"
                                (click)="toCreateCompanyPage()" i18n="@@createCompany">
                            NEW COMPANY
                        </button>
                    </div>
                </ng-container>
            </div>

            <input 
                type="file" 
                class="file-input" 
                (change)="onFileSelected($event)"
                [disabled]="uploadDisabled"
                #fileUpload
            >

            <mat-card class="mat-title company-info-block">
                <div *ngIf="companyLogoUrl">
                    <img [src]='companyLogoUrl' class="company-logo-block"/>
                    <div class="photo-button-block" *ngIf="actionsEnabled">
                        <!-- <td-file-upload #singleFileUpload
                                        (select)="selectEvent($event)"
                                        [disabled]="uploadDisabled"
                                        accept="image/gif, image/png, image/jpeg,
                        image/bmp, image/webp"
                                        defaultColor="accent"
                                        activeColor="primary"
                                        cancelColor="warn">
                            <ng-template td-file-input-label>
                                <label class="account-button avatar-manage-btn" i18n="@@uploadNEWLogoLabel">
                                    Upload new logo
                                </label>
                            </ng-template>
                        </td-file-upload> -->
                        <label class="account-button avatar-manage-btn" i18n="@@uploadNEWLogoLabel" (click)="fileUpload.click()">
                            Upload new logo
                        </label>

                        <label mat-button class="account-button avatar-manage-btn"
                               (click)="deleteCompanyLogo()" i18n="@@deleteLogoLabel">
                            Delete logo
                        </label>
                    </div>
                </div>

                <div *ngIf="!companyLogoUrl">
                    <span class="company-logo-block initials-block">{{currentCompany.name | slice : 0 : 1}}</span>
                    <div class="photo-button-block" *ngIf="actionsEnabled">
                        <!-- <td-file-upload #singleFileUpload
                                        (select)="selectEvent($event)"
                                        [disabled]="uploadDisabled"
                                        accept="image/gif, image/png, image/jpeg,
                        image/bmp, image/webp"
                                        defaultColor="accent"
                                        activeColor="primary"
                                        cancelColor="warn">
                            <ng-template td-file-input-label>
                                <label mat-button class="account-button avatar-manage-btn" i18n="@@uploadLogoLabel">
                                    Upload logo
                                </label>
                            </ng-template>
                        </td-file-upload> -->
                        <label class="account-button avatar-manage-btn" i18n="@@uploadNEWLogoLabel" (click)="fileUpload.click($event)">
                            Upload new logo
                        </label>
                    </div>
                </div>

                <mat-form-field class="card-item">
                    <mat-placeholder class="placeholder" i18n="@@placeholder-companyName">Company Name</mat-placeholder>
                    <input matInput [(ngModel)]="currentCompany.name"/>
                </mat-form-field>

                <mat-form-field class="card-item">
                    <mat-placeholder class="placeholder" i18n="@@placeholder-ownerEmail">Owner email</mat-placeholder>
                    <input matInput [(ngModel)]="currentCompany.ownerEmail"/>
                </mat-form-field>

                <mat-form-field class="card-item">
                    <mat-placeholder class="placeholder">Slack integration token</mat-placeholder>
                    <input matInput [(ngModel)]="currentCompany.slackIntegrationToken"/>
                </mat-form-field>

                <button mat-raised-button color="accent" 
                        class="default-card-button full-width-card-button"
                        [disabled]="!actionsEnabled"
                        (click)="updateCompanyInfo()" 
                        i18n="@@saveButton">
                    Save
                </button>

            </mat-card>
        </div>
    </div>
</div>

