import { environment } from '../../environments/environment';
import { ParameterVisibility } from 'app/_models/parameter-visibility';
import { ApiParameterVisibility } from 'app/_api_models/api-parameter-visibility';


export function parseApiParameterVisibility(apiResponse: any): ParameterVisibility {
  let parameterVisibility;

  if (environment.production) {
    parameterVisibility = apiResponse;
  } else {
    parameterVisibility = ApiParameterVisibility.check(apiResponse);
  }

  const newParameterVisibility: ParameterVisibility = {
    id: parameterVisibility.id,
    packageParameterId: parameterVisibility.package_parameter_id,
    onColleagueCard: parameterVisibility.on_colleague_card,
    onColleaguePage: parameterVisibility.on_colleague_page
  };

  return newParameterVisibility;
}
