import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { ApiQuestionnaireCard } from 'app/_api_models/api-questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { ScoreDivision } from 'app/_models/score-division';
import { parseApiCardOption } from 'app/_api_validation/parse-api-card-option';
import { parseApiScoreDivision } from 'app/_api_validation/parse-api-score-division';
import { environment } from '../../environments/environment';

export function parseApiQuestionnaireCard(apiResponse: any, nestedModels: boolean): QuestionnaireCard {
  let cardObject;
  if (environment.production) {
    cardObject = apiResponse;
  } else {
    cardObject = ApiQuestionnaireCard.check(apiResponse);
  }

  const cardOptions: CardOption[] = [];
  const scoreDivisions: ScoreDivision[] = [];
  if (nestedModels) {
    const apiCardOptions = apiResponse.card_options;
    if (apiCardOptions) {
      for (const singleOption of apiCardOptions) {
        const optionObject = parseApiCardOption(singleOption, false, []);
        cardOptions.push(optionObject);
      }
    }

    const apiScoreDivisions = apiResponse.score_divisions;
    if (apiScoreDivisions) {
      for (const singleDivision of apiScoreDivisions) {
        const divisionObject = parseApiScoreDivision(singleDivision);
        scoreDivisions.push(divisionObject);
      }
    }
  }

  const newCard: QuestionnaireCard = {
    id: cardObject.id,
    questionText: cardObject.questionText,
    cardType: cardObject.cardType,
    questionnaireId: cardObject.questionnaire_id,
    orderPosition: cardObject.order_position,
    cardOptions: cardOptions,
    scoreDivisions: scoreDivisions,
    isRequired: cardObject.is_required,
    isActive: cardObject.is_active,
    divisionsColorScheme: cardObject.divisions_color_scheme,
  };

  return newCard;
}
