<div *ngIf="!isLoading && myQuestionnaires.length > 0" class='wrapper-style'>
  <mat-card *ngFor="let questionnaireDetails of myQuestionnaires"
            [ngClass]="{ isActive:questionnaireDetails.isActive }" class="mat-card-class package-item">
      <mat-card-header>
          <mat-card-subtitle>
            <span class="item-status">
                <span class="active" i18n="@@active">Active</span>
                <span *ngIf="questionnaireDetails.nextRun.length > 0" class="inactive" i18n="@@inactive">Paused</span>
                <span *ngIf="questionnaireDetails.nextRun.length == 0" class="inactive" i18n="@@inactive">Waiting for start</span>
            </span>
          </mat-card-subtitle>

          <mat-card-title>{{questionnaireDetails.packageName}}</mat-card-title>

      </mat-card-header>
      <mat-card-content class="button-block">
          <button
            mat-raised-button
            color="primary"
            (click)="openQuestionnaire(questionnaireDetails)"
            i18n="@@open">
              Open
          </button>

      </mat-card-content>
      <mat-card-subtitle>
        <span *ngIf="questionnaireDetails.lastRun.length > 0"><span i18n="@@last-run">Last run</span>: {{questionnaireDetails.lastRun}}</span>
        <span *ngIf="questionnaireDetails.nextRun.length > 0"><span i18n="@@next-run">Next run</span>: {{questionnaireDetails.nextRun}}</span>
      </mat-card-subtitle>
  </mat-card>
</div>

<ng-container *ngIf="!isLoading && myQuestionnaires.length === 0">
  <div class="warning-message">
    <h3 i18n="@@warningMessage-noQuestionnnaire-title">You have no questionnaires yet :(</h3>
    <p i18n="@@warningMessage-noQuestionnnaire-body">Add your first questionnaire by copying one from the Standard Sets or create a new one by the clicking on the "+" button on the top</p>
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <div class="loading-container">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
