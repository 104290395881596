// NOTE: TypeScript cannot statically verify that the fetched data is of the right type

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { parseApiPackage } from 'app/_api_validation/parse-api-package';
import { parseApiPackageParameter } from 'app/_api_validation/parse-api-package-parameter';
import { parseApiPostQuestionnaireAction } from 'app/_api_validation/parse-api-pq-action';
import { parseApiPackageParticipant } from 'app/_api_validation/parse-api-package-participant';
import { parseApiPythonCustomCalculation } from 'app/_api_validation/parse-api-python-custom-calculation';

import { PackageParameter } from 'app/_models/package-parameter';
import { Package } from 'app/_models/package';
import { PackageParticipant } from 'app/_models/package-participant';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { ColleagueEvaluator } from 'app/_models/colleague-evaluator';
import { parseApiColleagueEvaluator } from 'app/_api_validation/parse-api-colleague-evaluator';

@Injectable()
export class PackageService {
  private packagesUrl: string = environment.API_ENDPOINT + '/v1/packages';
  private companyPackagesUrl: string = environment.API_ENDPOINT + '/v1/company_packages';
  private publicPackagesUrl: string = environment.API_ENDPOINT + '/v1/public_packages';
  private publicPackagesListUrl: string = environment.API_ENDPOINT + '/v1/public_list';
  private packageParametersUrl: string = environment.API_ENDPOINT + '/v1/package_parameters';
  private listPackageParametersUrl: string = environment.API_ENDPOINT + '/v1/list_package_parameters';
  private listPackageParticipantsUrl: string = environment.API_ENDPOINT + '/v1/list_package_participants';
  private postQuestionnaireActionsUrl: string = environment.API_ENDPOINT + '/v1/post_questionnaire_actions';
  private tryToAddParticipantsUrl: string = environment.API_ENDPOINT + '/v1/try_to_add_participants';
  private participantsUrl: string = environment.API_ENDPOINT + '/v1/participants';
  private packageMultipleSublissionsUrl: string = environment.API_ENDPOINT + '/v1/check_multiple_submissions';
  private packageOwnerIdUrl: string = environment.API_ENDPOINT + '/v1/package_owner_id';
  private customCalculationsUrl: string = environment.API_ENDPOINT + '/v1/custom_calculations';
  private colleagueTableConfigs: string = environment.API_ENDPOINT + '/v1/colleague_table_configs';
  private parameterVisibilities: string = environment.API_ENDPOINT + '/v1/parameter_visibilities';
  private colleagueEvaluatorsUrl: string = environment.API_ENDPOINT + '/v1/colleague_evaluators';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  startPackage(packageId: number): Observable<any> {
    const data = JSON.stringify({ package_id: packageId });
    return this.http
      .post<PackageParticipant[]>(`${this.packagesUrl}/start_package`, data, this.getHttpOptions())
      .pipe(
        map((response: any) => console.log(response)),
        catchError(this.handleError)
      );
  }

  tryToAddParticipants(packageId: number): Observable<PackageParticipant[]> {
    const newInfo = JSON.stringify({id: packageId});
    return this.http
      .post<PackageParticipant[]>(this.tryToAddParticipantsUrl, newInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleParticipants(response.data)),
        catchError(this.handleError)
      );
  }

  updateParticipant(participantId: number, isSelected: boolean): Observable<PackageParticipant> {
    const url = `${this.participantsUrl}/${participantId}`;
    const newParticipant = JSON.stringify({is_selected: isSelected});

    return this.http
      .put<PackageParticipant>(url, newParticipant, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePackageParticipant(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeParticipant(participantId: number): Observable<any> {
    const url = `${this.participantsUrl}/${participantId}`;

    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('remove participant ', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getCompanyPackages(companyId: number): Observable<Package[]> {
    return this.http.get<Package[]>(this.companyPackagesUrl + `/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseMultiplePackages(response.data);
        }),
        catchError(this.handleError)
      );
  }

  getPrivatePackages(): Observable<Package[]> {
    return this.http.get<Package[]>(this.packagesUrl, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseMultiplePackages(response.data);
        }),
        catchError(this.handleError)
      );
  }

  getPublicPackagesList(): Observable<Package[]> {
    return this.http
      .get<Package[]>(this.publicPackagesListUrl, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('PUBL ', response);
          return this.parseMultiplePackages(response.data);
        }),
        catchError(this.handleError)
      );
  }

  getPackage(id: number): Observable<Package> {
    const url = `${this.packagesUrl}/${id}`;
    return this.http
      .get<Package>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getPublicPackage(id: number): Observable<Package> {
    const url = `${this.publicPackagesUrl}/${id}`;
    return this.http
      .get<Package>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  checkPackageMultipleSubmissions(packageId: number): Observable<boolean> {
    const url = `${this.packageMultipleSublissionsUrl}/${packageId}`;
    return this.http
      .get<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response.multiple_submissions;
        }),
        catchError(this.handleError)
      );
  }

  getPackageOwnerId(packageId: number): Observable<number> {
    const url = `${this.packageOwnerIdUrl}/${packageId}`;
    // console.log('url ', url);
    return this.http
      .get<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response.owner_id;
        }),
        catchError(this.handleError)
      );
  }

  createFromStandardSet(name: string, description: string, language: string): Observable<Package> {
    const url = this.packagesUrl;
    const item = JSON.stringify({
      name: name,
      description: description,
      is_public: false,
      created_from_public: true,
      language: language,
    });

    return this.http
      .post<Package>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  createSetFromPrivatePackage(name: string, description: string, language: string): Observable<Package> {
    const url = this.packagesUrl;
    const item = JSON.stringify({
      name: name,
      description: description,
      is_public: true,
      language: language,
    });

    return this.http
      .post<Package>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  addPackage(name: string, description: string, companyId: number, language: string): Observable<Package> {
    const url = this.packagesUrl;
    const item = JSON.stringify({
      name: name,
      description: description,
      company_id: companyId,
      language: language
    });

    return this.http
      .post<Package>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  removePackage(packageId: number): Observable<any> {
    const url = `${this.packagesUrl}/${packageId}`;

    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  updatePackage(id: number, updatedFields: any): Observable<Package> {
    const url = `${this.packagesUrl}/${id}`;
    const updatedValues = JSON.stringify(updatedFields);

    return this.http
      .put<Package>(url, updatedValues, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          const included = response.included;
          return this.parsePackage(attributes, included);
        }),
        catchError(this.handleError)
      );
  }

  getPackageParticipants(packageId: number): Observable<PackageParticipant[]> {
    const url = `${this.listPackageParticipantsUrl}/${packageId}`;
    const httpOptionsNoToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.get<PackageParticipant>(url, httpOptionsNoToken)
      .pipe(
        map((response: any) => this.parseMultipleParticipants(response.data)),
        catchError(this.handleError)
      );
  }

  getPackageParameters(packageId: number): Observable<PackageParameter[]> {
    const url = `${this.listPackageParametersUrl}/${packageId}`;
    const httpOptionsNoToken = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // 'Authorization': 'Bearer ' + this.authService.token,
      })
    };

    return this.http.get<PackageParameter>(url, httpOptionsNoToken)
      .pipe(
        map((response: any) => this.parseMultiplePackageParameters(response.data)),
        catchError(this.handleError)
      );
  }

  getSinglePackageParameter(id: number): Observable<PackageParameter> {
    const url = `${this.packageParametersUrl}/${id}`;
    return this.http
      .get<PackageParameter>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          const attributes = response.data.attributes;
          return this.parsePackageParameter(attributes);
        }),
        catchError(this.handleError)
      );
  }

  addNewParameter(parameterInfo: PackageParameter): Observable<PackageParameter> {
    const url = this.packageParametersUrl;
    const item = JSON.stringify({
      formula_name: parameterInfo.formulaName,
      visible_name: parameterInfo.visibleName,
      group_name: parameterInfo.groupName,
      package_id: parameterInfo.packageId,
      common_value: parameterInfo.commonValue,
    });

    return this.http
      .post<PackageParameter>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePackageParameter(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateParameter(packageParameterId: number, parameterInfo: any): Observable<PackageParameter> {
    const url = `${this.packageParametersUrl}/${packageParameterId}`;
    const item = JSON.stringify({
      formula_name: parameterInfo.formulaName,
      visible_name: parameterInfo.visibleName,
      group_name: parameterInfo.groupName,
      package_id: parameterInfo.packageId,
      common_value: parameterInfo.commonValue,
    });

    return this.http
      .put<PackageParameter>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePackageParameter(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removeParameter(packageParameterId: number): Observable<any> {
    const url = `${this.packageParametersUrl}/${packageParameterId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  createParameterVisibility(parameterId: number): Observable<any> {
    const url = this.parameterVisibilities;
    const item = JSON.stringify({
      package_parameter_id: parameterId,
      on_colleague_card: true,
      on_colleague_page: true
    });

    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return 'OK';
        }),
        catchError(this.handleError)
      );
  }

  addPostQuestionnaireAction(packageId: number, actionInfo: PostQuestionnaireAction): Observable<PostQuestionnaireAction> {
    const url = this.postQuestionnaireActionsUrl;
    const item = JSON.stringify({
      text: actionInfo.text,
      parameter: actionInfo.parameter,
      package_id: packageId,
      order_position: actionInfo.orderPosition,
      action_type: actionInfo.actionType,
    });

    return this.http
      .post<PostQuestionnaireAction>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePostQuestionnaireAction(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updatePostQuestionnaireAction(actionId: number, updatedFields: any): Observable<PostQuestionnaireAction> {
    const url = `${this.postQuestionnaireActionsUrl}/${actionId}`;
    const newActionFields = JSON.stringify(updatedFields);
    return this.http
      .put<PostQuestionnaireAction>(url, newActionFields, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePostQuestionnaireAction(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  removePostQuestionnaireAction(actionId: number): Observable<any> {
    const url = `${this.postQuestionnaireActionsUrl}/${actionId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getCustomCalculation(calculationId: number): Observable<PythonCustomCalculation> {
    const url = `${this.customCalculationsUrl}/${calculationId}`;
    return this.http
      .get<PythonCustomCalculation>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parsePythonCustomCalculation(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }

  addCustomCalculation(postQuestionnaireId: number, textValue: string): Observable<PythonCustomCalculation> {
    const url = this.customCalculationsUrl;
    const item = JSON.stringify({
      text_value: textValue,
      post_questionnaire_action_id: postQuestionnaireId,
    });

    return this.http
      .post<PythonCustomCalculation>(url, item, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePythonCustomCalculation(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateCustomCalculation(calculationId: number, newTextValue: string): Observable<PythonCustomCalculation> {
    const url = `${this.customCalculationsUrl}/${calculationId}`;
    const updatedFields = JSON.stringify({text_value: newTextValue});
    return this.http
      .put<PythonCustomCalculation>(url, updatedFields, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parsePythonCustomCalculation(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  addColleagueTableConfig(packageId: number): Observable<any> {
    const url = this.colleagueTableConfigs;
    const config = JSON.stringify({
      package_id: packageId,
      is_visible: true,
      order_position: 0
    });

    return this.http
      .post<any>(url, config, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return 'OK';
        }),
        catchError(this.handleError)
      );
  }

  addColleagueEvaluator(packageId: number, colleagueId: number, evaluatorId: number): Observable<ColleagueEvaluator> {
    const url = this.colleagueEvaluatorsUrl;
    const data = JSON.stringify({
      package_id: packageId,
      colleague_id: colleagueId,
      evaluator_id: evaluatorId
    });

    return this.http
      .post<any>(url, data, this.getHttpOptions())
      .pipe(
        map((response: any) => 'OK'),
        catchError(this.handleError)
      );
  }

  removeColleagueEvaluator(packageId: number, colleagueId: number, evaluatorId: number): Observable<ColleagueEvaluator[]> {
    const url = `${this.colleagueEvaluatorsUrl}/remove_colleague_evaluator`;

    const data = JSON.stringify({
      package_id: packageId,
      colleague_id: colleagueId,
      evaluator_id: evaluatorId
    });

    return this.http
      .post<any>(url, data, this.getHttpOptions())
      .pipe(
        map((response: any) => 'OK'),
        catchError(this.handleError)
      );
  }

  getColleagueEvaluators(packageId: number): Observable<ColleagueEvaluator[]> {
    const url = `${this.colleagueEvaluatorsUrl}/show_colleague_evaluators`;
    const data = JSON.stringify({ package_id: packageId });

    return this.http
      .post<any>(url, data, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagueEvaluators(response.data)),
        catchError(this.handleError)
      );
  }

  private parseMultiplePackages(apiResponse: any): Package {
    const mappedData = apiResponse.map(x => this.parsePackage(x.attributes, []) as Package);
    return mappedData;
  }

  private parsePackage(apiResponse: any, included: any): Package {
    return parseApiPackage(apiResponse, true, included);
  }

  private parseMultiplePackageParameters(apiResponse: any): PackageParameter[] {
    const mappedData = apiResponse.map(x => this.parsePackageParameter(x.attributes) as PackageParameter);
    return mappedData;
  }

  private parsePackageParameter(apiResponse: any): PackageParameter {
    return parseApiPackageParameter(apiResponse);
  }

  private parsePostQuestionnaireAction(apiResponse: any): PostQuestionnaireAction {
    return parseApiPostQuestionnaireAction(apiResponse);
  }

  private parsePythonCustomCalculation(apiResponse: any): PythonCustomCalculation {
    return parseApiPythonCustomCalculation(apiResponse);
  }

  private parseMultipleParticipants(apiResponse: any): PackageParticipant[] {
    const mappedData = apiResponse.map(x => this.parsePackageParticipant(x.attributes) as PackageParticipant);
    return mappedData;
  }

  private parsePackageParticipant(apiResponse: any): PackageParticipant {
    return parseApiPackageParticipant(apiResponse);
  }

  private parseMultipleColleagueEvaluators(apiResponse: any): Package {
    const mappedData = apiResponse.map(x => this.parseColleagueEvaluator(x.attributes));
    return mappedData;
  }

  private parseColleagueEvaluator(apiResponse: any): ColleagueEvaluator {
    return parseApiColleagueEvaluator(apiResponse);
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error); // for demo purposes only
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }

}
