import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import * as lodash from 'lodash';

import { SnackBarComponent } from 'app/snack-bar';

import { SharedService } from 'app/_services/shared.service';
import { PackageService } from 'app/_services/package.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

import { Department } from 'app/_models/department';
import { PackageParticipant } from 'app/_models/package-participant';
import { ExtendedColleague } from 'app/colleagues-page/local-models';
import { ColleagueEvaluator } from 'app/_models/colleague-evaluator';
import { LocalColleague, LocalDepartment } from './local-models';
import { Colleague } from 'app/_models/colleague';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-package-participants',
  templateUrl: './package-participants.component.html',
  styleUrls: ['./package-participants.component.scss'],
  providers: [SnackBarComponent]
})
export class PackageParticipantsComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  isColleaguesExist: boolean = true;
  isButtonsDisabled: boolean = true;
  isMultipleSubmissionsAllowed: boolean = false;

  packageId: number = 0;
  maxSubmissions: number = 0;
  relativeQuestion: number = 0;

  departments: Department[] = [];
  colleagues: ExtendedColleague[] = [];
  nonDepartmentColleagues: ExtendedColleague[] = [];
  participants: PackageParticipant[] = [];
  changedParticipants: LocalColleague[] = [];
  selectedEvaluators: ColleagueEvaluator[] = [];

  localDepartments: LocalDepartment[] = [];
  localNonDepartment: LocalDepartment = null;
  localDefaultDepartments: LocalDepartment[] = [];
  localDefaultNonDepartment: LocalDepartment = null;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private snackBar: SnackBarComponent,
    private packageService: PackageService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.formPackageSettings();
    this.sharedService.currentTabId.next(2);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getData(): void {
    combineLatest([
      this.sharedCompanyDataService.colleaguesList$.pipe(filter(colleagues => colleagues !== null), first()),
      this.sharedCompanyDataService.departmentsList$.pipe(filter(departments => departments !== null), first()),
      this.sharedPackageService.packageParticipants$.pipe(filter(participants => participants !== null), first()),
      this.sharedPackageService.currentPackageId$.pipe(filter(packageId => packageId !== 0), first())
    ]).subscribe((result) => {
      this.nonDepartmentColleagues = result[0].filter(colleague => colleague.departmentId === 0);
      this.colleagues = result[0];
      this.departments = result[1];
      this.participants = result[2];
      this.packageId = result[3];

      this.getColleagueEvaluators();

      if (this.participants.length === 0) {
        this.isColleaguesExist = false;
      } else {
        this.formDepartmentParticipants();
        this.formNonDepartmentParticipants();
      }
    });
  }

  formPackageSettings(): void {
    combineLatest([
      this.sharedPackageService.maxSubmissions$.pipe(filter(value => value !== null), first()),
      this.sharedPackageService.relativeQuestion$.pipe(filter(colleagues => colleagues !== null), first()),
      this.sharedPackageService.multipleSubmissionsAllowed$.pipe(filter(colleagues => colleagues !== null), first()),
    ]).subscribe((result) => {
      this.maxSubmissions = result[0];
      this.relativeQuestion = result[1];
      this.isMultipleSubmissionsAllowed = result[2];
    }, error => console.log(error));
  }

  getColleagueEvaluators(): void {
    this.packageService.getColleagueEvaluators(this.packageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colleagueEvaluators: ColleagueEvaluator[]) => {
        this.selectedEvaluators = colleagueEvaluators;
        this.isLoading = false;
      }, error => console.log(error));
  }

  formDepartmentParticipants(): void {
    const departmentParticipants = [];

    for (const department of this.departments) {
      const departmentColleagues: LocalColleague[] = [];
      let selectedCount = 0;

      const colleagues: Colleague[] = department.colleagues.filter(param => !param.archived);
      for (const colleague of colleagues) {
        const participant = this.participants.find(param => param.colleagueId === colleague.id);

        if (!participant) {
          continue;
        }

        const newColleague: LocalColleague = {
          participantId: participant.id,
          colleagueId: colleague.id,
          firstName: colleague.firstName,
          lastName: colleague.lastName,
          departmentId: colleague.departmentId,
          isSelected: participant.isSelected
        };

        if (participant.isSelected) {
          selectedCount++;
        }

        departmentColleagues.push(newColleague);
      }

      let isAllSelected = false;
      if (selectedCount === departmentColleagues.length) {
        isAllSelected = true;
      }

      const newDepartment: LocalDepartment = {
        id: department.id,
        name: department.name,
        colleagues: departmentColleagues,
        selectedCount: selectedCount,
        isAllSelected: isAllSelected,
      };

      departmentParticipants.push(newDepartment);
    }

    this.localDepartments = departmentParticipants;
    this.localDefaultDepartments = lodash.cloneDeep(departmentParticipants);
  }

  formNonDepartmentParticipants(): void {
    const nonDepartmentColleagues = [];
    let selectedCount = 0;

    for (const colleague of this.nonDepartmentColleagues) {
      const participant = this.participants.find(person => person.colleagueId === colleague.id);

      if (!participant) {
        continue;
      }

      const newColleague: LocalColleague = {
        participantId: participant.id,
        colleagueId: colleague.id,
        firstName: colleague.firstName,
        lastName: colleague.lastName,
        departmentId: colleague.departmentId,
        isSelected: participant.isSelected
      };

      if (participant.isSelected) {
        selectedCount++;
      }

      nonDepartmentColleagues.push(newColleague);
    }

    let isAllSelected = false;
    if (selectedCount === nonDepartmentColleagues.length) {
      isAllSelected = true;
    }

    const newDepartment: LocalDepartment = {
      id: 0,
      name: '',
      colleagues: nonDepartmentColleagues,
      selectedCount: selectedCount,
      isAllSelected: isAllSelected,
    };

    this.localNonDepartment = newDepartment;
    this.localDefaultNonDepartment = lodash.cloneDeep(newDepartment);
  }

  onUpdateDepartment($event): void {
    const department = $event;
    this.departments.forEach((dep) => {
      if (dep.id === department.id) {
        dep = department;
      }
    });

    this.isButtonsDisabled = false;
  }

  onUpdateParticipant($event): void {
    const participant = $event;
    this.updateChangedParticipantsList(participant);
    this.isButtonsDisabled = false;
  }

  onSelectAllParticipants(department: LocalDepartment): void {
    const newState = !department.isAllSelected;
    department.isAllSelected = newState;

    for (const colleague of department.colleagues) {
      colleague.isSelected = newState;
      this.updateChangedParticipantsList(colleague);
    }

    if (newState) {
      department.selectedCount = department.colleagues.length;
    } else {
      department.selectedCount = 0;
    }

    this.isButtonsDisabled = false;
  }

  onChangeMultipleSubmission(): void {
    this.isButtonsDisabled = false;

    if (!this.isMultipleSubmissionsAllowed) {
      this.maxSubmissions = 1;
    }
  }

  onChangeMaxSubmissionNumber(): void {
    if (this.maxSubmissions < 1) {
      this.maxSubmissions = 1;
    }

    if (this.maxSubmissions >= 1) {
      this.isButtonsDisabled = false;
    }
  }

  onChangeRelativeQuestion(): void {
    if (this.relativeQuestion < 0) {
      this.relativeQuestion = 0;
    }

    if (this.relativeQuestion >= 0) {
      this.isButtonsDisabled = false;
    }
  }

  updateChangedParticipantsList(participant: LocalColleague): void {
    const isChangedParticipantExists = this.changedParticipants.find(person => person.participantId === participant.participantId);

    if (isChangedParticipantExists) {
      this.changedParticipants = this.changedParticipants.filter(person => person.participantId !== participant.participantId);
    } else {
      this.changedParticipants.push(participant);
    }
  }

  onResetSettings(): void {
    const cloneLocalDepartments = lodash.cloneDeep(this.localDefaultDepartments);
    const cloneLocalNonDepartment = lodash.cloneDeep(this.localDefaultNonDepartment);

    this.localDefaultDepartments = cloneLocalDepartments;
    this.localDefaultNonDepartment = cloneLocalNonDepartment;

    this.changedParticipants = [];
    this.isMultipleSubmissionsAllowed = this.sharedPackageService.multipleSubmissionsAllowed$.value;
    this.maxSubmissions = this.sharedPackageService.maxSubmissions$.value;
    this.isButtonsDisabled = true;
  }

  updatePackageSettings(): void {
    const updatedFields = {
      multiple_submissions: this.isMultipleSubmissionsAllowed,
      max_submissions: this.maxSubmissions,
      relative_question: this.relativeQuestion
    };

    this.packageService.updatePackage(this.packageId, updatedFields)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.sharedPackageService.multipleSubmissionsAllowed$.next(this.isMultipleSubmissionsAllowed);
        this.sharedPackageService.maxSubmissions$.next(this.maxSubmissions);
        this.sharedPackageService.relativeQuestion$.next(this.relativeQuestion);
        this.isButtonsDisabled = true;
        this.openSnackBar();
      }, error => console.log(error));
  }

  onSaveSettings(): void {
    this.updatePackageSettings();

    forkJoin(
      this.changedParticipants.map(
        participant => this.onParticipantUpdate(participant)
      )
    ).subscribe(()=> {
      this.localDefaultDepartments = lodash.cloneDeep(this.localDepartments);
      this.localDefaultNonDepartment = lodash.cloneDeep(this.localNonDepartment);
      this.isButtonsDisabled = true;
      this.updateSharedParticipants();
      this.openSnackBar();
    }, error => console.log(error));
  }

  onParticipantUpdate(participant: LocalColleague): Observable<any> {
    return this.packageService.updateParticipant(participant.participantId, participant.isSelected);
  }

  updateSharedParticipants(): void {
    this.changedParticipants.forEach((participant) => {
      this.participants.find(person => person.id === participant.participantId).isSelected = participant.isSelected;
    });

    this.sharedPackageService.updatePackageParticipants(this.participants);
    this.changedParticipants = [];
  }

  openSnackBar(): void {
    const currentLanguage = this.sharedService.currentLang.value;
    let message = 'Changes have been saved';

    if (currentLanguage === 'ru') {
      message = 'Изменения были сохранены';
    }

    this.snackBar.open(message);
  }
}
