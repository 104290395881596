import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { Package } from 'app/_models/package';
import { SharedDashboard } from 'app/_models/shared-dashboard';

class LocalSharedPackage {
  id: number;
  name: string;
  isAvailable: boolean;
}

@Component({
  selector: 'app-dashboard-manage-questionnaires',
  templateUrl: './dashboard-manage-questionnaires.component.html',
  styleUrls: ['./dashboard-manage-questionnaires.component.scss']
})
export class DashboardManageQuestionnairesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  // @Input() sharedDashboardId: number;
  @Input() sharedDashboard: SharedDashboard;
  // myQuestionnaires: Package[];
  sharedPackagesList: LocalSharedPackage[] = [];

  constructor(
    private packageService: PackageService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.getPrivatePackages();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPrivatePackages(): void {
    // console.log('Shared dashboard ', this.sharedDashboard);
    const user = localStorage.getItem('currentUser');
    if (user) {
      // this.myQuestionnaires = [];
      const sharedPackages = this.sharedDashboard.packages;
      this.packageService.getPrivatePackages()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packList: Package[]) => {
          for (const singlePack of packList) {
            let isAvailable = false;
            const findShared = sharedPackages.find(pack => pack.id === singlePack.id);
            if (findShared) {
              isAvailable = true;
            }

            const newLocalShared: LocalSharedPackage = {
              id: singlePack.id,
              name: singlePack.name,
              isAvailable: isAvailable
            };
            this.sharedPackagesList.push(newLocalShared);
          }
        },
        error => {
          console.log('Error with package', error);
        });
    }
  }

  onAddSharedPackage(pack: Package): void {
    if ((this.sharedDashboard.id > 0) && pack) {
      this.dashboardService.addSharedDashboardPackage(pack.id, this.sharedDashboard.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: any) => {
          location.reload();
        },
        error => {
          console.group('Error with package ', error);
        });
    }
  }

  onRemoveSharedPackage(pack: Package): void {
    if ((this.sharedDashboard.id > 0) && pack) {
      const dashboardPackages = this.sharedDashboard.sharedDashboardPackages;
      const findPackage = dashboardPackages.find(sharedPack => sharedPack.packageId === pack.id);
      if (findPackage) {
        this.dashboardService.removeSharedDashboardPackage(findPackage.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: any) => {
            location.reload();
          },
          error => {
            console.group('Error with package ', error);
          });
      }
    }
  }

  // getSharedDashboardPackages(): void {
  //   this.dashboardService.getSharedDashboardPackages()
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((result: any) => {
  //       console.log('Shared packages? ', result);
  //     },
  //     error => {
  //       console.group('Error with package ', error);
  //     });
  // }

}
