<div class="dialog-main">
  <div mat-dialog-content class="dialog-wrap">
    <button mat-dialog-close class="modal-close">
      <!-- <img src="../../assets/overmind_icons/close.svg" alt="close" > -->
      <img src="/assets/overmind_icons/close.svg" alt="close" >
    </button>
    <div class="dialog-buttons-one">
      <button (click)="onCreateQuestionnaire()" i18n="@@createQuestionnaire">
        CREATE QUESTIONNAIRE
      </button>

      <button (click)="onAddMember()" i18n="@@createColleague">
        ADD TEAM MEMBER
      </button>

      <button (click)="onCreateDepartment()" i18n="@@createDepartment">
        CREATE DEPARTMENT
      </button>
    </div>

    <div class="dialog-buttons-two">
      <button (click)="onCreateCompany()" i18n="@@createCompany">
        NEW COMPANY
      </button>
    </div>
  </div>
</div>