<div [ngClass]="{'widget': !isPublicLink}">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="widget__container">
    <div *ngIf="userView||(showTextMode)" [innerHtml]="widgetText | safeHtml" class="description-form"></div>

    <mat-card *ngIf="parametersEditMode" class="parameters-list">
      <div class="parameters-list__header">
        <button mat-menu-item (click)="onShowTextMode()" class="parameters-list__button">
          <mat-icon class="parameters-list__icon">close</mat-icon>
        </button>
      </div>
      <div class="parameters-list__item">
        <mat-radio-group [(ngModel)]="activeParameter" (change)="onParametersStateChange($event)">
          <mat-radio-button class="researches-radio-button"
            *ngFor="let parameter of localParameters" [value]="parameter">
            {{parameter.visibleName}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-card>
  </div>
</div>


<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showParametersList">Show parameters list</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
