import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { validate } from 'class-validator';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'app/_services/user.service';
import { EmailValidation } from './email-validation';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss', '../authorization.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  model: any = {};
  loading: boolean = false;
  hideForm: boolean = false;
  infoBar: string[] = [];

  constructor(
    private router: Router,
    private userService: UserService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toLoginPage(): void {
    this.router.navigate(['/login']);
  }

  onValidate(): void {
    this.infoBar = [];
    const newUserValidation = new EmailValidation();
    newUserValidation.email = this.model.email;

    validate(newUserValidation).then(errors => {
      if (errors.length > 0) {
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
      } else {
        this.resetPassword();
      }
    });
  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  resetPassword(): void {
    this.loading = true;
    this.userService.resetPassword(this.model.email)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.hideForm = true;
        this.loading = false;
        // location.reload();
      },
      error => {
        console.log(error);
        this.hideForm = true;
        this.loading = false;
      });
  }

}
