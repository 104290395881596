import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { Questionnaire } from 'app/_models/questionnaire';
import { Package } from 'app/_models/package';
import { Experiment } from 'app/_models/experiment';

import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { CompanyService } from 'app/_services/company.service';
import { SharedService } from 'app/_services/shared.service';
import { PackageParameter } from 'app/_models/package-parameter';
import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { Colleague } from 'app/_models/colleague';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { RelativeExperimentParameter } from 'app/_models/relative-experiment-parameter';

@Component({
  selector: 'app-relative-experiment-details',
  templateUrl: './relative-experiment-details.component.html',
  styleUrls: ['./relative-experiment-details.component.scss']
})
export class RelativeExperimentDetailsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  colleaguesList: Colleague[] = [];
  questionnaireCards: QuestionnaireCard[] = [];
  // localCards: QuestionnaireCard[] = [];
  cardOptions: CardOption[] = [];
  chosenAnswers: ColleagueCardAnswer[] = [];
  colleagueId: number = 0;
  experimentAnswerId: number = 0;
  resultStateIndividual: any = [];
  resultStateRelative: any = [];
  packageParameters: PackageParameter[] = [];
  packageId: number = 0;
  colleagueParams: RelativeExperimentParameter[] = [];
  colleagueName: string = '';
  // relativeParams: RelativeParameter[] = [];
  experimentPeriod: string = '';
  // isAnswered: boolean = false;
  isCalculated: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private experimentService: ExperimentService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((routeParams: any) => {
        if (routeParams) {
          const relativeAnswerId = parseInt(routeParams['relative_answer_id'], 10);
          this.getRelativeExperimentAnswer(relativeAnswerId);
        }
      });
  }

  getRelativeExperimentAnswer(id: number): void {
    if (id && id > 0) {
      this.experimentService.getRelativeExperimentAnswer(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((answer: RelativeExperimentAnswer) => {
          const colleagueParams = answer.relativeExperimentParameters;
          this.colleagueParams = colleagueParams;
          this.isCalculated = answer.calculated;

          this.getExperiment(answer.experimentId);
          this.getColleagues(answer.colleagueId);
        },
          error => {
            console.log('Answer error ', error);
          });
    }
  }

  getExperiment(experimentId: number): void {
    if (experimentId) {
      this.experimentService.getExperiment(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((experiment: Experiment) => {
          if (experiment) {
            // this.isCalculated = experiment.calculated;
            // this.getExperimentAnswerId(experiment.experimentAnswers);
            const questionnaireId = experiment.questionnaireId;
            this.getQuestionnaire(questionnaireId);
            const startDate = experiment.startDate.toString().slice(0, -14);
            const finishDate = experiment.finishDate.toString().slice(0, -14);
            this.experimentPeriod = startDate + ' - ' + finishDate;
          }
        },
          error => {
            console.log('Experiment error ', error);
          });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getQuestionnaire(questionnaireId: number): void {
    if (questionnaireId) {
      this.questionnaireService.getQuestionnaireBrief(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          if (resultQuestionnaire) {
            const packageId = resultQuestionnaire.packageId;
            this.packageId = packageId;
            this.getPackageInfo(packageId);
          }
        },
          error => {
            console.log('Questionnaire error ', error);
          });
    }
  }

  getColleagues(colleagueId: number): void {
    this.companyService.getColleaguesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colleaguesList: Colleague[]) => {
        if (colleaguesList) {
          this.colleaguesList = colleaguesList;
          // const currentColleague = colleaguesList.find(colleague => colleague.id === this.colleagueId);
          const currentColleague = colleaguesList.find(colleague => colleague.id === colleagueId);
          if (currentColleague) {
            const currentColleagueName = currentColleague.firstName + ' ' + currentColleague.lastName;
            this.colleagueName = currentColleagueName;
          }
        }
      });
  }

  getPackageInfo(packageId: number): void {
    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
          if (packageInfo) {
            const params = packageInfo.packageParameters;
            this.packageParameters = params;
            this.formResultState();
          }
        },
          error => {
            console.log('Package info', error);
          });
    }
  }

  formResultState(): void {
    const resultStateIndividual = [];
    const resultStateRelative = [];
    const packageParameters: PackageParameter[] = this.packageParameters;
    for (const singleParameter of packageParameters) {
      if (singleParameter.groupName === 'individual') {
        const paramType = singleParameter.commonValue.type;
        const packageParameterId = singleParameter.id;
        const paramValue = this.getColleagueParameterValue(packageParameterId, paramType);

        const item = {
          name: singleParameter.visibleName,
          value: paramValue,
        };

        resultStateIndividual.push(item);
      } 
    }

    this.resultStateIndividual = resultStateIndividual;
    this.resultStateRelative = resultStateRelative;
  }

  getColleagueParameterValue(packageParameterId: number, paramType: string): number | string {
    const param = this.colleagueParams.find(colleagueParam => colleagueParam.packageParameterId === packageParameterId);
    if (param) {
      if (paramType === 'text') {
        return param.textValue;
      } else {
        return this.precise(param.value);
      }
    } else {
      return '-';
    }
  }

  // getRelativeParameterValue(packageParameterId: number, paramType: string): number | string {
  //   const param = this.relativeParams.find(relativeParam => relativeParam.packageParameterId === packageParameterId);
  //   if (param) {
  //     if (paramType === 'text') {
  //       return param.textValue;
  //     } else {
  //       return this.precise(param.value);
  //     }
  //   } else {
  //     return '-';
  //   }
  // }

  // getRelativeColleagueName(packageParameterId: number): string {
  //   const param = this.relativeParams.find(colleagueParam => colleagueParam.packageParameterId === packageParameterId);
  //   if (param && (param.relativeColleagueId > 0)) {
  //     const findColleague = this.colleaguesList.find(colleague => colleague.id === param.relativeColleagueId);
  //     if (findColleague) {
  //       return findColleague.firstName + ' ' + findColleague.lastName;
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  precise(inputValue: number): number {
    if (inputValue) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    } else {
      return 0;
    }
  }

  goBack(): void {
    this.sharedService.questionState$ = true;
    const packId = this.packageId;
    this.router.navigateByUrl(`/questionnaires/${packId}/researches`);
  }

}
