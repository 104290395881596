import { Number, String, Record } from 'runtypes';

export const ApiParameterData = Record({
  id: Number,
  parameter_uuid: String,
  author_uuid: String,
  entity_uuid: String,
  source_uuid: String,
  value: String,
  data: String,
  created_at: String,
  updated_at: String
});
