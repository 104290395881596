<ng-container *ngIf="inputDashboard">
  <div *ngFor="let widget of localDashboardWidgets">
    <div *ngIf="widget.widgetName === 'card-text-widget'">
      <app-shared-text-card-widget
        [userView]="true"
        [isLoaded]="isLoaded"
        [isPublicLink]="isPublicLink"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-text-card-widget>
    </div>

    <div *ngIf="widget.widgetName === 'heatmap-individual-widget'">
      <app-shared-heatmap-widget
        [userView]="true"
        [isLoaded]="isLoaded"
        [isPublicLink]="isPublicLink"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-heatmap-widget>
    </div>

    <div *ngIf="widget.widgetName === 'shared-bar-widget'">
      <app-shared-bar-widget 
        [userView]="true" 
        [isLoaded]="isLoaded"
        [isPublicLink]="isPublicLink" 
        [testData]="false"
        [inputWidgetId]="widget.id" 
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)" 
        (onWidgetUp)="onWidgetUp(widget)" 
        (onWidgetDown)="onWidgetDown(widget)">
      </app-shared-bar-widget>
    </div>

    <!-- <div *ngIf="widget.widgetName === 'line-common-widget'">
      <app-line-common-widget
        [userView]="true"
        [inputWidgetId]="widget.id"
        (onFiltersUpdate)="onFiltersUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-line-common-widget>
    </div> -->

    <div *ngIf="widget.widgetName === 'brief-statistics-widget'">
      <app-passage-brief-statistics
        [userView]="true" 
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-passage-brief-statistics>
    </div>
    
    <div *ngIf="widget.widgetName === 'separator-widget'">
      <app-separator-widget
        [userView]="true"
        [widgetTitle]="widget.widgetTitle"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-separator-widget>
    </div>

    <div *ngIf="widget.widgetName === 'description-card-widget'">
      <app-description-card-widget
        [userView]="true"
        [widgetTitle]="widget.widgetTitle"
        [isPublicLink]="isPublicLink"
        [sharedDashboardMode]="true"
        (onTitleUpdate)="onTitleUpdate($event, widget)"
        (onDeleteWidget)="onDeleteWidget(widget)"
        (onWidgetUp)="onWidgetUp(widget)"
        (onWidgetDown)="onWidgetDown(widget)">
      </app-description-card-widget>
    </div>
  </div>
  <div *ngIf="localDashboardWidgets.length > 0">
    <button
      mat-raised-button
      color="accent"
      class="button-load-more"
      (click)="loadMore()"
      i18n="@@loadMore">
        Load more
    </button>
  </div>
</ng-container>
