import { Number, Record, String, Null } from 'runtypes';

const Data = Record({
  colleague_id: Number.Or(Null),
  evaluator_id: Number,
  experiment_id: Number,
  experiment_answer_id: Number,
  user_email: String,
  company_token: String
});

export const ApiShortUrl = Record({
  id: Number,
  url_hash: String,
  data: Data.Or(Record({}))
});

