<div class="authorization">
  <div *ngIf="!hideForm" class="authorization__container">

    <div class="logo-block">
      <img src="/assets/overmind_icons/logo.svg" alt="Logotype"/>
      <span class="logo-block__title">Teamheat</span>
    </div>

    <h2 class="login title" i18n="@@loginPage-newUser-title">New user</h2>

    <mat-card class="authorization__wrapper">
      <form class="form form--signup" name="form" (ngSubmit)="f.form.valid && signup()" #f="ngForm" autocomplete="off" novalidate>

        <div class="form__fields">
          <mat-form-field class="card-item">
            <mat-placeholder class="placeholder" i18n="@@placeholder-firstName">First Name</mat-placeholder>
            <input matInput min="2" class="form-control" [(ngModel)]="model.firstName"
              (ngModelChange)="checkCreateEnabled($event)" name="firstName" required />
          </mat-form-field>

          <mat-form-field class="card-item">
            <mat-placeholder class="placeholder" i18n="@@placeholder-lastName">Last name</mat-placeholder>
            <input matInput class="form-control" [(ngModel)]="model.lastName"
              (ngModelChange)="checkCreateEnabled($event)" name="lastName" required />
          </mat-form-field>

          <mat-form-field class="card-item">
            <input matInput placeholder="Email" class="form-control" [(ngModel)]="model.email"
              (ngModelChange)="checkCreateEnabled($event)" name="email" required />
          </mat-form-field>

          <mat-form-field class="card-item">
            <mat-placeholder class="placeholder" i18n="@@placeholder-password">Password</mat-placeholder>
            <input matInput type="password" class="form-control" [(ngModel)]="model.password"
              (ngModelChange)="checkCreateEnabled($event)" name="password" required />
          </mat-form-field>

          <mat-form-field class="card-item">
            <mat-placeholder class="placeholder" i18n="@@placeholder-passwordConfirmation">Password Confirmation</mat-placeholder>
            <input matInput type="password" class="form-control" [(ngModel)]="model.passwordConfirmation"
              (ngModelChange)="checkCreateEnabled($event)" name="passwordConfirmation" required />
          </mat-form-field>
        </div>

        <div class="policy" *ngIf="localeId === 'en'">
          <mat-checkbox [(ngModel)]="policyChecked" name="policy">
            I confirm that I have read, understand and agree
            <br />
            <a href="https://teamheat.tech/policy" target="_blank">
              to the Policy
            </a>
            and
            <a href="https://teamheat.tech/agreement" target="_blank">User agreement</a>
          </mat-checkbox>
        </div>

        <div class="policy" *ngIf="localeId === 'ru'">
          <mat-checkbox [(ngModel)]="policyChecked" name="policy">
            Я подтверждаю, что ознакомлен(а) и согласен(а) с
            <br />
            <a href="https://teamheat.tech/agreement" target="_blank">Пользовательским соглашением</a> и
            <br />
            <a href="https://teamheat.tech/policy" target="_blank">
              Политикой в отношении обработки персональных данных
            </a>
          </mat-checkbox>
        </div>

        <div class="form-group buttons-box two-buttons">
          <div class="buttons-box__item">
            <button 
              [disabled]="!isCreateEnabled || !policyChecked"
              mat-raised-button
              color="accent"
              class="control-element" 
              i18n="@@loginPage-createAccount"
              type="submit">
                Create account
            </button>
            <button 
              [disabled]="loading" 
              mat-raised-button 
              color="primary" 
              class="control-element"
              (click)="toLoginPage()" 
              i18n="@@loginPage-title"
              type="button">
                Login page
            </button>
          </div>
        </div>

        <!-- <div *ngIf="error" class="alert alert-danger">{{error}}</div> -->
        <div *ngIf="infoBar.length > 0" class="alert alert-danger">
          <div *ngFor="let error of infoBar; let i = index;">
            {{error}}
          </div>
        </div>

      </form> <!-- /.form -->
    </mat-card> <!-- /.authorization__wrapper -->
  </div> <!-- /.authorization__container -->

  <div *ngIf="hideForm" class="message">
    <!-- <mat-card>
      <p i18n="@@loginPage-registrationTitle">Thank you for registration!</p>
      <p i18n="@@loginPage-registrationMessage">Please check your email for the activation link.</p>
    </mat-card> -->
    <mat-card class="mat-title">
      <p class="title" i18n="@@HP-title">Welcome to TeamHeat!</p>
      <p class="title">Your company will be created in a second</p>
    </mat-card>
  </div>
</div>