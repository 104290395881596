import { DashboardWidget } from 'app/_models/dashboard-widget';
import { ApiDashboardWidget } from 'app/_api_models/api-dashboard-widget';
import { environment } from '../../environments/environment';

export function parseApiDashboardWidget(apiResponse: any): DashboardWidget {
  let dashboardWidgetObject;
  if (environment.production) {
    dashboardWidgetObject = apiResponse;
  } else {
    dashboardWidgetObject = ApiDashboardWidget.check(apiResponse);
  }

  const widgetFilters = {};
  if (dashboardWidgetObject.widget_filters.active_colleagues_ids) {
    widgetFilters['activeColleaguesIds'] = dashboardWidgetObject.widget_filters.active_colleagues_ids;
  }
  if (dashboardWidgetObject.widget_filters.active_parameters_ids) {
    widgetFilters['activeParametersIds'] = dashboardWidgetObject.widget_filters.active_parameters_ids;
  }
  if (dashboardWidgetObject.widget_filters.active_research_id) {
    widgetFilters['activeResearchId'] = dashboardWidgetObject.widget_filters.active_research_id;
  }
  if (dashboardWidgetObject.widget_filters.sort_parameter_id) {
    widgetFilters['sortParameterId'] = dashboardWidgetObject.widget_filters.sort_parameter_id;
  }
  if (typeof(dashboardWidgetObject.widget_filters.show_parameters_names) !== 'undefined') {
    widgetFilters['showParametersNames'] = dashboardWidgetObject.widget_filters.show_parameters_names;
  }
  if (typeof (dashboardWidgetObject.widget_filters.hide_empty_cards) !== 'undefined') {
    widgetFilters['hideEmptyCards'] = dashboardWidgetObject.widget_filters.hide_empty_cards;
  }
  if (typeof (dashboardWidgetObject.widget_filters.show_latest_research) !== 'undefined') {
    widgetFilters['showLatestResearch'] = dashboardWidgetObject.widget_filters.show_latest_research;
  }
  if (typeof (dashboardWidgetObject.widget_filters.show_participants) !== 'undefined') {
    widgetFilters['showParticipants'] = dashboardWidgetObject.widget_filters.show_participants;
  }

  if (dashboardWidgetObject.widget_filters.plot_lines) {
    const resultLines = [];
    for (const singleLine of dashboardWidgetObject.widget_filters.plot_lines) {
      const serialized = JSON.parse(singleLine);
      resultLines.push(serialized);
    }
    widgetFilters['plotLines'] = resultLines;
  }

  if (dashboardWidgetObject.widget_filters.heatmap_parameters) {
    const resultParameters = [];
    for (const singleParameter of dashboardWidgetObject.widget_filters.heatmap_parameters) {
      const serialized = JSON.parse(singleParameter);
      resultParameters.push(serialized);
    }
    widgetFilters['heatmapParameters'] = resultParameters;
  }

  const newDashboardWidget: DashboardWidget = {
    id: dashboardWidgetObject.id,
    widgetName: dashboardWidgetObject.widget_name,
    orderPosition: dashboardWidgetObject.order_position,
    dashboardConfigId: dashboardWidgetObject.dashboard_config_id,
    widgetTitle: dashboardWidgetObject.widget_title,
    widgetFilters: widgetFilters
  };

  return newDashboardWidget;
}
