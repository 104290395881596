<mat-dialog-content>
  <ng-container *ngIf="isCreateMode">
    <h2 matDialogTitle class="title-class" i18n="@@createButton">{{createNoteTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isUpdateMode">
    <h2 matDialogTitle class="title-class" i18n="@@updateButton">{{updateNoteTitle}}</h2>
  </ng-container>
  <ng-container *ngIf="isDeleteMode">
    <h2 matDialogTitle class="title-class" i18n="@@deleteButton">{{deleteNoteTitle}}</h2>
  </ng-container>
</mat-dialog-content>

<mat-dialog-content>
  <ng-container *ngIf="isCreateMode">
    <p i18n="@@confirm-createNote">{{createNoteSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isUpdateMode">
    <p i18n="@@confirm-updateNote">{{updateNoteSubtitle}}</p>
  </ng-container>
  <ng-container *ngIf="isDeleteMode">
    <p i18n="@@confirm-deleteNote">{{deleteNoteSubtitle}}</p>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngIf="isCreateMode">
    <button mat-raised-button matDialogClose="confirm" color="accent" i18n="@@createButton">
      {{createNoteConfirm}}
    </button>
  </ng-container>
  <ng-container *ngIf="isUpdateMode">
    <button mat-raised-button matDialogClose="confirm" color="accent" i18n="@@updateButton">
      {{updateNoteConfirm}}
    </button>
  </ng-container>
  <ng-container *ngIf="isDeleteMode">
    <button mat-raised-button matDialogClose="confirm" color="warn" i18n="@@deleteButton">
      {{deleteNoteConfirm}}
    </button>
  </ng-container>
  <button mat-raised-button matDialogClose="cancel" color="primary" i18n="@@cancelButton">Cancel</button>
</mat-dialog-actions>
