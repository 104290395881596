<div class="widget">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div *ngIf="showChartMode" class="chart-box">
    <nav mat-tab-nav-bar class="tabs-list">
      <a mat-tab-link *ngFor="let tab of tabs" (click)="changeTab(tab)" [active]="selectedTabIndex == tab.id"
        class="tabs-list__item">
        {{tab.name}}
      </a>
    </nav>
    <highcharts-chart
      style="display: flex;"
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      [(update)]="updateFlag"
      [callbackFunction]="chartCallback"
      [oneToOne]="true"
    ></highcharts-chart>
  </div>

  <mat-card *ngIf="colleaguesEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <button mat-raised-button color="accent" i18n="@@selectAllParticipants" (click)="setActiveParticipants()">
        Select all participants
      </button>
    </div>
    <div *ngFor="let colleague of activeColleagues">
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()">
          {{colleague.firstName}} {{colleague.lastName}}
        </mat-checkbox>
      </div>
    </div>
  </mat-card> <!-- /.colleagues-list -->

  <mat-card *ngIf="parametersEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <button mat-raised-button color="accent" i18n="@@selectAllParameters" (click)="setActiveParameters()">
        Select all parameters
      </button>
    </div>
    <div *ngFor="let parameter of localParameters">
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onParametersStateChange()">
          {{parameter.visibleName}}
        </mat-checkbox>
      </div>
    </div>
  </mat-card> <!-- /.parameters-list -->

  <mat-card *ngIf="researchesEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
        <mat-radio-button class="parameters-list__radio" value="last_research" i18n="@@lastResearch">
          Last research
        </mat-radio-button>
        <mat-radio-button class="parameters-list__radio"
          *ngFor="let research of experiments" [value]="research">
          {{research.startDate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card> <!-- /.researches-mode -->

  <mat-card *ngIf="linesEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div *ngFor="let line of plotLines" class="line">
      <mat-form-field style="width: 80px;" class="line__item">
        <mat-placeholder class="placeholder">Line color</mat-placeholder>
        <input matInput [(ngModel)]="line.color" (blur)="onBlurMethod()">
      </mat-form-field>
      <mat-form-field style="width: 250px;" class="line__item">
        <mat-placeholder class="placeholder">Line label</mat-placeholder>
        <input matInput [(ngModel)]="line.label" (blur)="onBlurMethod()">
      </mat-form-field>
      <ng-container *ngIf="line.packageParameterId > 0">
        <mat-form-field style="width: 80px;" class="line__item">
          <mat-placeholder class="placeholder">Line value</mat-placeholder>
          <input matInput [disabled]="true" [(ngModel)]="line.value" (blur)="onBlurMethod()">
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!(line.packageParameterId > 0)">
        <mat-form-field style="width: 80px;" class="line__item">
          <mat-placeholder class="placeholder">Line value</mat-placeholder>
          <input matInput [(ngModel)]="line.value" (blur)="onBlurMethod()">
        </mat-form-field>
      </ng-container>
      <mat-form-field style="width: 80px;" class="line__item">
        <mat-placeholder class="placeholder">Line width</mat-placeholder>
        <input matInput [(ngModel)]="line.width" (blur)="onBlurMethod()">
      </mat-form-field>
      <mat-form-field style="width: 80px;" class="line__item">
        <mat-placeholder class="placeholder">Line zIndex</mat-placeholder>
        <input matInput [(ngModel)]="line.zIndex" (blur)="onBlurMethod()">
      </mat-form-field>
      <span style="width: 50px;">
        <button class="delete-button" (click)="onRemoveLine(line)" i18n="@@deleteButton">
          Delete
        </button>
      </span>
    </div>
    <ng-container *ngIf="activeCommonParameter">
      <div *ngIf="commonParameters.length > 0">
        <mat-radio-group [(ngModel)]="activeCommonParameter" (change)="onChangeActiveCommonParameter($event)">
          <mat-radio-button class="researches-radio-button" *ngFor="let param of commonParameters" [value]="param">
            {{param.visibleName}}
          </mat-radio-button>
        </mat-radio-group>
        <button (click)="onAddNewCommonLine()" i18n="@@addCommonLine">
          Add common line
        </button>
        &nbsp;
        <button (click)="onResetCommonLine()" i18n="@@reset">
          Reset
        </button>
      </div>
      <div *ngIf="commonParameters.length == 0">
        <button (click)="onResetCommonLine()" i18n="@@reset">
          Reset
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="!activeCommonParameter">
      <button (click)="onAddNewLine()" i18n="@@addLine">
        Add line
      </button>
      &nbsp;
      <button (click)="onAddCommonLineAction()" i18n="@@addCommonLine">
        Add common line
      </button>
    </ng-container>
  </mat-card> <!-- /.lines-edit-mode -->
</div>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showIndividualParametersList">Show individual parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="onLinesEditMode()">
    <mat-icon>edit</mat-icon>
    <span i18n="@@editLines">Edit lines</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
