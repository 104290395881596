import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { Package } from 'app/_models/package';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { PackageParameter } from 'app/_models/package-parameter';
import { PackageParticipant } from 'app/_models/package-participant';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';

import { ChartHelper } from '../_helpers/chart-helper';
import { ExtendedParameter, ExtendedColleague, InfoParameter,
  InfoCard, CategoryInfoCard, RelativeInfoCard, WidgetFilters } from '../_helpers/local-models';
import { widgetFiltersUpdateHelper, restoreInputWidgetsFiltersHelper } from './local-helper';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-text-card-widget',
  templateUrl: './text-card-widget.component.html',
  styleUrls: ['./text-card-widget.component.scss', '../widgets.scss']
})
export class TextCardWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;
  @Input() isPublicLink: boolean;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  activeExperiment: Experiment;
  inputWidgetFilters: WidgetFilters;

  widgetFilters: WidgetFilters = {};

  infoCards: InfoCard[] = [];
  experiments: Experiment[] = [];
  colleaguesList: ExtendedColleague[] = [];
  sharedPackagesList: Package[] = [];
  localDepartments: Department[] = [];
  colleagues: ExtendedColleague[] = [];
  participants: PackageParticipant[] = [];
  selectedColleaguesList: Colleague[] = [];
  filteredInfoCards: CategoryInfoCard[] = [];
  relativeParameters: ExtendedParameter[] = [];
  filteredRelativeCards: RelativeInfoCard[] = [];
  individualParameters: ExtendedParameter[] = [];


  isLoaded: boolean = false;
  editMode: boolean = false;
  hasFilter: boolean = false;
  hideEmptyCards: boolean = true;
  useRelativeMode: boolean = false;
  showParticipants: boolean = false;
  hasRelativeFilter: boolean = false;
  colleaguesCardMode: boolean = true;
  showParametersNames: boolean = true;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;
  parameterGroupMode: boolean = false;
  isAllParticipantsSelected: boolean = true;
  isAllRelativeParametersSelected: boolean = true;
  isAllIndividualParametersSelected: boolean = false;

  activePackageOption: any;
  activeExperimentOption: any;
  activeColleagueId: number = 0;
  activeDepartmentId: number = 0;
  parameterFilter: any = null;

  // Packages
  SELF_REVIEW_ID = 1747;
  GENERAL_NOTES_ID = 1746;

  constructor(
    private localDashboardHelper: LocalDashboardHelper,
    private chartHelper: ChartHelper,
    private sharedPackageService: SharedPackageService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.getExperiments();
    this.getColleagues();
    this.getDepartments();

    this.loadExperimentInfo();
  }

  loadExperimentInfo(): void {
    const promisesList = [];

    const promiseWidget = this.localDashboardHelper.getDashboardWidget(this.inputWidgetId);
    promiseWidget.then((widget: any) => {
      this.inputWidgetFilters = widget.widgetFilters;
    }).catch((error) => {
      console.log('Widget error ', error);
    });

    const promiseParameters = this.localDashboardHelper.getCachedPackageParameters();
    promiseParameters.then((parameters: PackageParameter[]) => {
      this.individualParameters = [];
      this.relativeParameters = [];
      if (parameters) {
        const filterParams = parameters.filter(param => param.groupName === 'individual');
        for (const singleParameter of filterParams) {
          this.individualParameters.push({
            ...singleParameter,
            isActive: true
          });
        }
        const filterRelative = parameters.filter(param => param.groupName === 'relative');
        for (const singleRelativeParam of filterRelative) {
          this.relativeParameters.push({
            ...singleRelativeParam,
            isActive: false
          });
        }
        this.updatePackageOption();
      }
    }).catch((error) => {
      console.log('Parameters error ', error);
    });

    promisesList.push(promiseParameters);
    promisesList.push(promiseWidget);


    Promise.all(promisesList).then((result) => {
      this.checkInputWidgets();
      if (this.showParticipants) {
        this.setActiveParticipants();
      }
      this.formColleagueCards();
      this.fillSelectedColleaguesList();
      this.isLoaded = true;
    }).catch((error) => console.log(error));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getExperiments(): void {
    this.sharedPackageService.experiments$
      .pipe(
        filter(experiments => experiments !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((experiments: Experiment[]) => {
        this.experiments = this.localDashboardHelper.convertExperimentsDates(experiments);
      }, error => console.log(error));
  }

  getParticipants(): void {
    this.sharedPackageService.packageParticipants$
      .pipe(
        filter(participants => participants !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((participants: PackageParticipant[]) => {
        this.participants = participants;
        this.setActiveParticipants();
      }, error => console.log(error));
  }

  getColleagues(): void {
    this.sharedCompanyDataService.colleaguesList$
      .pipe(
        filter(colleaguesList => colleaguesList !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleaguesList: ExtendedColleague[]) => {
        this.colleaguesList = colleaguesList;
        this.colleagues = colleaguesList;
        this.getParticipants();
      }, error => console.log(error));
  }

  getDepartments(): void {
    this.sharedCompanyDataService.departmentsList$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((departmentsList: Department[]) => {
        this.localDepartments = departmentsList;
      }, error => console.log(error));
  }

  fillSelectedColleaguesList() {
    this.selectedColleaguesList = [];
    const departmentId = Number(this.activeDepartmentId);

    for (let participant of this.participants) {
      if (participant.isSelected) {
        for (let colleague of this.colleaguesList) {
          if (colleague.id === participant.colleagueId) {
            if (departmentId === colleague.departmentId) {
              this.selectedColleaguesList.push(colleague);
            } else if (departmentId === 0) {
              this.selectedColleaguesList.push(colleague);
            }
          }
        }
      }
    }
  }

  setDepartmentValue(): void {
    this.activeColleagueId = 0;
    this.formColleagueCards();
    this.fillSelectedColleaguesList();
  }

  setActiveColleagueName(): void {
    this.formColleagueCards();
  }

  setActiveIndividualParameters() {
    this.individualParameters = this.chartHelper.setActiveParameters(this.individualParameters, this.isAllIndividualParametersSelected);
    this.isAllIndividualParametersSelected = !this.isAllIndividualParametersSelected;
    this.isAllRelativeParametersSelected = this.isAllIndividualParametersSelected;
    this.onParametersStateChange();
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  setActiveRelativeParameters() {
    this.relativeParameters = this.chartHelper.setActiveParameters(this.relativeParameters, this.isAllRelativeParametersSelected);
    this.isAllRelativeParametersSelected = !this.isAllRelativeParametersSelected;
    this.isAllIndividualParametersSelected = this.isAllRelativeParametersSelected;
    this.onRelativeParametersChange();
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  setActiveParticipants(): void {
    this.colleagues = this.chartHelper.setActiveParticipants(this.colleagues, this.participants);
    this.isAllParticipantsSelected = !this.isAllParticipantsSelected;
    this.showParticipants = false;
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  updatePackageOption(): void {
    if (this.activePackageOption) {
      const packageId = this.activePackageOption.id;
      this.experiments = this.experiments.filter(exp => exp.packageId === packageId);
      this.individualParameters = this.individualParameters.filter(param => param.packageId === packageId);
    }
    if (this.activeExperimentOption === 'last_research') {
      this.activeExperiment = this.getLatestExperiment();
    }
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.showParticipants = true;
    this.activeExperimentOption = 'last_research';
    this.widgetFilters['showParticipants'] = true;
    this.widgetFilters['showLatestResearch'] = true;
  }

  restoreInputWidgetsFilters(): void {
    if (this.inputWidgetFilters) {
      const activePackageId = this.inputWidgetFilters.activePackageId;
      if (activePackageId && activePackageId > 0) {
        const findPackage = this.sharedPackagesList.find(pack => pack.id === activePackageId);
        if (findPackage) {
          this.activePackageOption = findPackage;
          this.updatePackageOption();
        }
      }

      const sortParameterId = this.inputWidgetFilters.sortParameterId;
      if (sortParameterId) {
        const sortParameter = this.individualParameters.find(param => param.id === sortParameterId);
        if (sortParameter) {
          this.parameterFilter = sortParameter;
          this.hasFilter = true;
          this.hasRelativeFilter = false;
          this.relativeParameters.forEach(param => param.isActive = false);
          const individualFind = this.individualParameters.find(param => param.id === this.parameterFilter.id);
          if (individualFind) {
            individualFind.isActive = true;
          }
          this.formFilteredCards();
        }
      }

      const stateObject = {
        activeColleagues: this.colleagues,
        individualParameters: this.individualParameters,
        relativeParameters: this.relativeParameters,
        experiments: this.experiments,
        activeExperiment: this.activeExperiment,
        activeExperimentOption: this.activeExperimentOption,
        showParametersNames: this.showParametersNames,
        hideEmptyCards: this.hideEmptyCards,
        showParticipants: this.showParticipants,
      };
      const updatedStateObject = restoreInputWidgetsFiltersHelper(this.inputWidgetFilters, stateObject);
      if (updatedStateObject) {
        this.colleagues = updatedStateObject.activeColleagues;
        this.individualParameters = updatedStateObject.individualParameters;
        this.relativeParameters = updatedStateObject.relativeParameters;
        this.activeExperiment = updatedStateObject.activeExperiment;
        this.activeExperimentOption = updatedStateObject.activeExperimentOption;
        this.showParametersNames = updatedStateObject.showParametersNames;
        this.hideEmptyCards = updatedStateObject.hideEmptyCards;
        this.showParticipants = updatedStateObject.showParticipants;
      }
    }
  }

  widgetFiltersUpdate(): void {
    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
      } else {
        this.activeExperiment = experimentOption;
      }
    }

    const contextObject = {
      activeColleagues: this.colleagues,
      individualParameters: this.individualParameters,
      relativeParameters: this.relativeParameters,
      experiments: this.experiments,
      activeExperiment: this.activeExperiment,
      activeExperimentOption: this.activeExperimentOption,
      parameterFilter: this.parameterFilter,
      showParametersNames: this.showParametersNames,
      showParticipants: this.showParticipants,
      hideEmptyCards: this.hideEmptyCards
    };

    const widgetFilters = widgetFiltersUpdateHelper(this.widgetFilters, contextObject);
    this.widgetFilters = widgetFilters;

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  onParametersStateChange(): void {
    const hasActive = this.individualParameters.find(param => param.isActive === true);
    if (hasActive) {
      this.relativeParameters.forEach(param => param.isActive = false);
      this.isAllRelativeParametersSelected = false;
    }


    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onRelativeParametersChange(): void {
    const hasActive = this.relativeParameters.find(param => param.isActive === true);
    if (hasActive) {
      this.individualParameters.forEach(param => param.isActive = false);
      this.isAllIndividualParametersSelected = false;
    }
    this.parameterFilter = null;
    this.hasFilter = false;
    this.widgetFiltersUpdate();
    this.formRelativeCards();
  }

  onParticipantsStateChange(): void {
    // this.widgetFiltersUpdate();
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }

  onParameterFilterChange(event: any): void {
    if (this.parameterFilter) {
      this.hasRelativeFilter = false;
      this.relativeParameters.forEach(param => param.isActive = false);
      const individualFind = this.individualParameters.find(param => param.id === this.parameterFilter.id);
      if (individualFind) {
        individualFind.isActive = true;
      }
      this.formFilteredCards();
    } else {
      this.hasFilter = false;
      this.formColleagueCards();
    }
    this.widgetFiltersUpdate();
  }

  onPackagesFilterChange(event: any): void {
    this.widgetFiltersUpdate();
    this.loadExperimentInfo();
  }

  formRelativeCards(): void {
    const relativeParams = this.relativeParameters.filter(param => param.isActive === true);

    if (this.activeExperiment && relativeParams.length > 0) {
      this.filteredRelativeCards = [];
      this.hasRelativeFilter = true;
      const relativeColleaguesIds = this.getRelativeColleaguesIds(relativeParams);

      for (let singleId of relativeColleaguesIds) {
        const relativeColleague = this.getActiveColleague(singleId);

        if (relativeColleague) {
          const infoCards = this.formRelativeInfoCards(singleId);
          const relativeInfoCard: RelativeInfoCard = {
            relativeColleagueName: relativeColleague.firstName + ' ' + relativeColleague.lastName,
            infoCards: infoCards
          };
          this.filteredRelativeCards.push(relativeInfoCard);
        }
      }

    } else {
      this.formRelativeTestCards();
    }
  }

  formRelativeInfoCards(relativeColleagueId: number): InfoCard[] {
    let infoCards: InfoCard[] = [];
    if (this.activeExperiment) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;

      for (const singleAnswer of experimentAnswers) {
        if (singleAnswer.completed) {
            const colleague = this.colleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
            const cardParameters = this.formRelativeInfoCardParameters(singleAnswer, relativeColleagueId);

            if (cardParameters && colleague) {
              const infoCard: InfoCard = {
                fullName: colleague.firstName + ' ' + colleague.lastName,
                parameters: cardParameters,
              };
              if (cardParameters.length > 0) {
                infoCards.push(infoCard);
              }
            }
        }
      }

      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(infoCards);
        infoCards = newCards;
      }
    }

    return infoCards;
  }

  formRelativeInfoCardParameters(experimentAnswer: ExperimentAnswer, relativeColleagueId: number): InfoParameter[] {
    // const filterParameter = this.parameterRelativeFilter;
    const cardParameters = [];
    const relativePackageParams = this.relativeParameters.filter(param => param.isActive === true);

    for (const parameter of relativePackageParams) {
      const filterParameter = parameter;
      const localParameters = experimentAnswer.relativeParameters.filter(param => param.relativeColleagueId === relativeColleagueId);

      if (localParameters && filterParameter && filterParameter.isActive) {
        const resultParameter = localParameters.find(param => param.packageParameterId === filterParameter.id);
        if (resultParameter) {
          if (filterParameter.commonValue.type === 'text') {
            const convertedText = this.convertTextParameter(resultParameter.textValue);
            if (convertedText === '-') {
              break;
            }
            const newParameter: InfoParameter = {
              name: filterParameter.visibleName,
              value: convertedText
            };
            cardParameters.push(newParameter);
          } else {
            const newParameter: InfoParameter = {
              name: filterParameter.visibleName,
              value: resultParameter.value
            };
            cardParameters.push(newParameter);
          }
        }
      }

    }

    return cardParameters;
  }

  getRelativeColleaguesIds(relativePackageParameters: ExtendedParameter[]): number[] {
    if (relativePackageParameters) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      const relativeColleaguesIds = [];
      for (const singleParam of relativePackageParameters) {
        for (const singleAnswer of experimentAnswers) {
          const findParam = singleAnswer.relativeParameters.find(param => param.packageParameterId === singleParam.id);
          if (findParam && findParam.relativeColleagueId > 0) {
            relativeColleaguesIds.push(findParam.relativeColleagueId);
          }
        }
      }
      const uniqueIds = relativeColleaguesIds.filter(this.onlyUnique);
      return uniqueIds;
    }
  }

  formRelativeTestCards(): void {
    // TODO
  }

  formFilteredCards(): void {
    if (this.activeExperiment) {
      const paramVariants = this.getParameterVariants();
      const filteredParams = paramVariants.filter(this.onlyUnique);
      // this.infoCards = [];
      this.filteredInfoCards = [];
      this.hasFilter = true;

      for (const singleParameterValue of filteredParams) {
        const infoCards = this.formFilteredInfoCards(singleParameterValue);
        const categoryInfoCard: CategoryInfoCard = {
          filteredParameterValue: singleParameterValue,
          infoCards: infoCards
        };

        this.filteredInfoCards.push(categoryInfoCard);
      }
    } else {
      this.formFilteredTestCards();
    }
  }

  formFilteredTestCards(): void {
    const filteredParams = ['Test data #1', 'Test data #2'];
    this.filteredInfoCards = [];
    this.hasFilter = true;

    for (const singleParameterValue of filteredParams) {
      const infoCards = [];
      for (let i = 1; i < 4; i++) {
        const localParameters = [];
        for (const singleParam of this.individualParameters) {
          const newParameter: InfoParameter = {
            name: singleParam.visibleName,
            value: 'Test data'
          };
          localParameters.push(newParameter);
        }
        const infoCard: InfoCard = {
          fullName: 'Colleague #' + i,
          parameters: localParameters,
        };
        infoCards.push(infoCard);
      }
      const categoryInfoCard: CategoryInfoCard = {
        filteredParameterValue: singleParameterValue,
        infoCards: infoCards
      };

      this.filteredInfoCards.push(categoryInfoCard);
    }
  }

  formFilteredInfoCards(parameterValue: any): InfoCard[] {
    let infoCards: InfoCard[] = [];
    if (this.activeExperiment) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        const isFiltered = this.checkAnswerForFiltered(singleAnswer, parameterValue);
        if (isFiltered && singleAnswer.completed) {
          // const currentColleague = this.activeColleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParameters(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName,
              parameters: cardParameters,
            };
            infoCards.push(infoCard);
          }
        }
      }

      const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
      for (const singleAnswer of relativeAnswers) {
        const isFiltered = this.checkAnswerForFilteredRelative(singleAnswer, parameterValue);
        if (isFiltered && singleAnswer.calculated) {
          // const currentColleague = this.activeColleagues.find(colleague => colleague.id === singleAnswer.colleagueId);
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParametersRelative(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName + ' (relative)',
              parameters: cardParameters,
            };
            infoCards.push(infoCard);
          }
        }
      }

      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(infoCards);
        infoCards = newCards;
      }
    }

    return infoCards;
  }

  checkAnswerForFiltered(experimentAnswer: ExperimentAnswer, parameterValue: any): boolean {
    const colleagueParams = experimentAnswer.colleagueParameters;
    const filterParamId = this.parameterFilter.id;
    const findParam = colleagueParams.find(param => param.packageParameterId === filterParamId);
    if (findParam) {
      if (findParam.value === parameterValue) {
        return true;
      } else if (findParam.textValue === parameterValue) {
        return true;
      }
    }

    return false;
  }

  checkAnswerForFilteredRelative(relativeAnswer: RelativeExperimentAnswer, parameterValue: any): boolean {
    const colleagueParams = relativeAnswer.relativeExperimentParameters;
    const filterParamId = this.parameterFilter.id;
    const findParam = colleagueParams.find(param => param.packageParameterId === filterParamId);
    if (findParam) {
      if (findParam.value === parameterValue) {
        return true;
      } else if (findParam.textValue === parameterValue) {
        return true;
      }
    }

    return false;
  }

  formColleagueCards(): void {
    if (this.hasFilter) {
      this.hasRelativeFilter = false;
      this.formFilteredCards();
    } else {
      const hasActiveRelative = this.relativeParameters.find(param => param.isActive === true);
      if (hasActiveRelative) {
        this.hasRelativeFilter = true;
        this.formRelativeCards();
      } else {
        this.hasRelativeFilter = false;
        this.formColleagueSimpleCards();
      }
    }
  }

  formColleagueSimpleCards(): void {
    if (this.activeExperiment) {
      this.infoCards = [];
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        if (singleAnswer.completed) {
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParameters(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName,
              parameters: cardParameters,
            };
            this.infoCards.push(infoCard);
          }
        }
      }
      const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
      for (const singleAnswer of relativeAnswers) {
        if (singleAnswer.calculated) {
          const currentColleague = this.getActiveColleague(singleAnswer.colleagueId);
          if (currentColleague) {
            const cardParameters = this.formInfoCardParametersRelative(singleAnswer);
            const infoCard: InfoCard = {
              fullName: currentColleague.firstName + ' ' + currentColleague.lastName + ' (relative)',
              parameters: cardParameters,
            };
            this.infoCards.push(infoCard);
          }
        }
      }

      if (this.hideEmptyCards) {
        const newCards = this.removeEmptyCards(this.infoCards);
        this.infoCards = newCards;
      }

    } else {
      this.formTestColleagueSimpleCards();
    }

  }

  formTestColleagueSimpleCards(): void {
    this.infoCards = [];
    for (let i = 1; i < 4; i++) {
      const localParameters = [];
      for (const singleParam of this.individualParameters) {
        const newParameter: InfoParameter = {
          name: singleParam.visibleName,
          value: 'Test data'
        };
        localParameters.push(newParameter);
      }
      const infoCard: InfoCard = {
        fullName: 'Colleague #' + i,
        parameters: localParameters,
      };
      this.infoCards.push(infoCard);
    }
  }

  getActiveColleague(colleagueId: number): ExtendedColleague {
    const activeDepartmentId = Number(this.activeDepartmentId);
    const activeColleagueId = Number(this.activeColleagueId);
    let currentColleague = null;

    if (activeDepartmentId === 0 && activeColleagueId === 0) {
      currentColleague = this.colleagues.find(colleague => colleague.id === colleagueId);
    } else if (colleagueId && (activeDepartmentId && activeColleagueId) || activeDepartmentId != 0 || activeColleagueId != 0) {
      let filteredColleagues = null;

      if (activeColleagueId != 0) {

        if (activeDepartmentId != 0) {
          filteredColleagues = this.colleagues.filter(colleague => colleague.departmentId === activeDepartmentId && colleague.id === activeColleagueId);
        } else {
          filteredColleagues = this.colleagues.filter(colleague => colleague.id === activeColleagueId);
        }

      } else {
        filteredColleagues = this.colleagues.filter(colleague => colleague.departmentId === activeDepartmentId);
      }

      currentColleague = filteredColleagues.find(colleague => colleague.id === colleagueId);
    } else {
      currentColleague = this.colleagues.find(colleague => colleague.id === colleagueId);
    }

    if (this.showParticipants) {
      return currentColleague;
    } else if (currentColleague && currentColleague.isActive) {
      return currentColleague;
    }
  }

  removeEmptyCards(inputCards: InfoCard[]): InfoCard[] {
    const newCards = [];
    for (const singleCard of inputCards) {
      let isCardEmpty = true;
      for (const singleParameter of singleCard.parameters) {
        const isParamEmpty = this.checkIfParameterEmpty(singleParameter);
        if (!isParamEmpty) {
          isCardEmpty = false;
        }
      }

      if (!isCardEmpty) {
        newCards.push(singleCard);
      }
    }
    // this.infoCards = newCards;
    return newCards;
  }

  checkIfParameterEmpty(parameter: any): boolean {
    // console.log('Parameter value ', parameter);
    const parameterValue = parameter.value;
    if ((parameterValue === 0) || (parameterValue === '')) {
      return true;
    } else {
      return false;
    }
  }

  formInfoCardParameters(experimentAnswer: ExperimentAnswer): InfoParameter[] {
    const cardParameters = [];
    const localParameters = experimentAnswer.colleagueParameters;

    for (const parameter of localParameters) {
      if (parameter.packageParameterId === this.SELF_REVIEW_ID && parameter.value === 0) {
        break;
      }

      const packageParameter = this.individualParameters.find(param => param.id === parameter.packageParameterId);
      if (packageParameter && packageParameter.isActive) {
        if (packageParameter.commonValue.type === 'text') {
          const convertedText = this.convertTextParameter(parameter.textValue);
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: convertedText
          };
          cardParameters.push(newParameter);
        } else {
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: parameter.value
          };
          cardParameters.push(newParameter);
        }
      }
    }

    return cardParameters;
  }

  formInfoCardParametersRelative(relativeAnswer: RelativeExperimentAnswer): InfoParameter[] {
    const cardParameters = [];
    const localParameters = relativeAnswer.relativeExperimentParameters;
    for (const singleParameter of localParameters) {
      const packageParameter = this.individualParameters.find(param => param.id === singleParameter.packageParameterId);
      if (packageParameter && packageParameter.isActive) {
        if (packageParameter.commonValue.type === 'text') {
          const convertedText = this.convertTextParameter(singleParameter.textValue);
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: convertedText
          };
          cardParameters.push(newParameter);
        } else {
          const newParameter: InfoParameter = {
            name: packageParameter.visibleName,
            value: singleParameter.value
          };
          cardParameters.push(newParameter);
        }
      }
    }

    return cardParameters;
  }

  getParameterVariants(): any {
    const parameterVariants = [];
    if (this.activeExperiment && this.parameterFilter) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      for (const singleAnswer of experimentAnswers) {
        if (singleAnswer.completed) {
          const filterId = this.parameterFilter.id;
          const filteredParameters = singleAnswer.colleagueParameters.filter(param => param.packageParameterId === filterId);
          for (const singleParameter of filteredParameters) {
            const packageParameter = this.individualParameters.find(param => param.id === singleParameter.packageParameterId);
            if (packageParameter.commonValue.type === 'text') {
              const convertedText = this.convertTextParameter(singleParameter.textValue);
              parameterVariants.push(convertedText);
            } else {
              parameterVariants.push(singleParameter.value);
            }
          }
        }
      }
    }
    return parameterVariants;
  }

  convertTextParameter(inputText: string): string {
    if (inputText) {
      const newValue = String(inputText).replace(/(\r\n|\n|\r)/gm, '<br />');
      return newValue;
    } else {
      return '';
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  onShowChartMode(): void {
    this.setAllModesFalse();
    this.colleaguesCardMode = true;
  }

  onColleaguesCardMode(): void {
    this.setAllModesFalse();
    this.colleaguesCardMode = true;
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.editMode = true;
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.editMode = true;
    this.parametersEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.editMode = true;
    this.researchesEditMode = true;
  }

  onParameterGroupMode(): void {
    this.setAllModesFalse();
    this.editMode = true;
    this.parameterGroupMode = true;
  }

  onRelativeUseMode(): void {
    this.setAllModesFalse();
    this.editMode = true;
    this.useRelativeMode = true;
  }

  setAllModesFalse(): void {
    this.colleaguesEditMode = false;
    this.colleaguesCardMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
    this.parameterGroupMode = false;
    this.useRelativeMode = false;
    this.editMode = false;
  }

  onlyUnique(value: any, index: number, self: any): boolean {
    return self.indexOf(value) === index;
  }

  onExperimentChange(event: any): void {
    this.widgetFiltersUpdate();
    this.formColleagueCards();
  }
}
