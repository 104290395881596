import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SharedService } from 'app/_services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-standard-set-questions',
  templateUrl: './set-questions.component.html',
  styleUrls: [
    '../stardard-set-details.component.scss',
    './set-questions.component.scss'
  ]
})
export class StandardSetQuestionsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  questionnaireId: number;
  currentSetId: number;
  parametersList: any = [];
  individualParameters: any = [];
  commonParameters: any = [];
  relativeParameters: any = [];
  cards:  QuestionnaireCard[] = [];
  postQuestionnaireActions:  PostQuestionnaireAction[] = [];
  viewMode: boolean = false;

  constructor(
    private router: Router,
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this.sharedService.demoMode$.emit(this.viewMode);
    this.sharedService.currentStandardSetTabId.next(1);

    this.sharedPackageService.questionnaireCards$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.cards = result;
      });

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((setId) => {
        this.currentSetId = setId;
      });

    this.sharedPackageService.postQuestionnaireActions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pqActions) => {
        this.postQuestionnaireActions = pqActions;
        this.sortPostQuestionnaireActions();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sortPostQuestionnaireActions(): void {
    this.postQuestionnaireActions.sort(function (a: any, b: any): number {
      return a.orderPosition - b.orderPosition;
    });
  }

  // viewDemo(): void {
  //   this.viewMode = true;
  //   this.sharedService.demoMode$.emit(this.viewMode);
  // }

  viewDemo(): void {
    this.sharedService.demoMode$.emit(true);
    this.router.navigate([`/standard-sets/${this.currentSetId}/preview`]);
  }

  goBack(): void {
    this.viewMode = false;
    this.sharedService.demoMode$.emit(this.viewMode);
  }

}
