<div class="settings">

  <div class="left-side">
    <app-period-form
      (onUpdateResearchActive)="onUpdateResearchActive($event)"
      (onUpdateModelConfirmed)="onUpdateModelConfirmed($event)"
    >
    </app-period-form>

    <mat-card class="mat-card-class">
      <h2 i18n="@@launch-options">Launch options</h2>
      <mat-form-field>
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-launch">Choose option</mat-placeholder>
        <mat-select [(ngModel)]="launchOptions">
          <mat-option value="email">Email</mat-option>
          <mat-option value="slack">Slack</mat-option>
          <mat-option value="email-slack">Email and Slack</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <button mat-raised-button (click)="onLaunchOptionsUpdate()" color="accent" i18n="@@saveButton">
        Save
      </button>
    </mat-card>

    <mat-card *ngIf="anonymityState" class="choose-anonymous-state">
      <h2 i18n="@@anonymity">Research anonymity</h2>
      <form [formGroup]="anonymityState">
        <mat-radio-group class="anonymity-group" formControlName="anonymity" (change)="onAnonymityChange($event)" [required]>
          <mat-radio-button [value]="false" i18n="@@anonymity-no" [disabled]="isResearchActive">
            Public
          </mat-radio-button>
          <mat-radio-button [value]="true" i18n="@@anonymity-yes" [disabled]="isResearchActive">
            Anonymous
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </mat-card>

    <mat-card *ngIf="sendColleaguesState" class="choose-anonymous-state">
      <h2 i18n="@@send-colleague-results">Send results to colleagues</h2>
      <form [formGroup]="sendColleaguesState">
        <mat-radio-group class="anonymity-group" formControlName="sendColleagues" (change)="onColleaguesSendChange($event)" [required]>
          <mat-radio-button [value]="false" i18n="@@send-colleague-results-no" [disabled]="isResearchActive">
            No
          </mat-radio-button>
          <mat-radio-button [value]="true" i18n="@@send-colleague-results-yes" [disabled]="isResearchActive">
            Yes
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </mat-card>


    <mat-card class="notification-options">
      <h2 i18n="@@send-colleague-results">Notification options</h2>
      <div class="notification-options__content">
        <mat-checkbox [(ngModel)]="oneDayNotifications"  (change)="onChangeOneDayNotifications()" i18n="@@oneDayNotifications">
          If less than 24 hours are left before the end of the questionnaire
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="halfTermNotifications" (change)="onChangeHalfTermNotifications()" i18n="@@halfTermNotifications">
          If half of the entire questionnaire period has passed
        </mat-checkbox>
      </div>
    </mat-card>

    <mat-card *ngIf="sendToColleagues" class="mat-card-class">
      <h2 i18n="@@settings-colleague-link">Colleague link</h2>
      <mat-form-field class="card-full-width">
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-colleague-link">Enter new link</mat-placeholder>
        <input matInput [(ngModel)]="colleagueLinkString" required />
      </mat-form-field>
      <br />
      <button mat-raised-button (click)="onSetColleagueLink(currentPackageId, colleagueLinkString)" color="accent"
        i18n="@@settings-colleague-updateButton">
        Submit
      </button>
    </mat-card>

    <mat-card class="mat-card-class">
      <h2 i18n="@@settings-rename-title">You can rename questionnaire</h2>
      <p i18n="@@rename-hint">Please, pick new name.</p>
      <mat-form-field class="card-full-width">
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-rename">Enter new title</mat-placeholder>
        <input matInput [(ngModel)]="packageName" required />
      </mat-form-field>
      <br />
      <button mat-raised-button (click)="onRenamePackage(currentPackageId, packageName)" color="accent"
        i18n="@@settings-renameButton">
        Submit
      </button>
    </mat-card>

    <mat-card class="mat-card-class">
      <h2 i18n="@@settings-language">Language</h2>
      <mat-form-field>
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-language">Choose Language</mat-placeholder>
        <mat-select [(ngModel)]="packageLanguage">
          <mat-option value="en">English</mat-option>
          <mat-option value="ru">Русский</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <button mat-raised-button (click)="onLanguageUpdate(currentPackageId)" color="accent" i18n="@@saveButton">
        Save
      </button>
    </mat-card>

    <app-package-description [packageDescription]=packageDescription>
    </app-package-description>

    <mat-card *ngIf="isAdmin" class="mat-card-class">
      <h2 i18n="@@settings-create-standard-set">You can create template from this questionnaire.</h2>
      <button
          mat-raised-button
          color="accent"
          (click)="onCreateStandardSet(currentPackageId, packageDescription, packageLanguage)"
          i18n="@@settings-button-create-set">
        Create template
      </button>
    </mat-card>

    <mat-card class="mat-card-class">
      <h2 i18n="@@settings-delete-title">You can delete this questionnaire with all parameters, questions, participants and experiments.</h2>
      <button
          mat-raised-button
          color="warn"
          (click)="onDelete(currentPackageId)"
          i18n="@@settings-delete">
        Delete questionnaire
      </button>
    </mat-card>
  </div>

  <div class="right-sidebar">
    <ng-container *ngIf="isResearchActive&&!isDirectLink">
      <button
        class="button-pause"
        mat-raised-button
        (click)="onPauseResearch()"
      >
        <i class="material-icons">pause_circle_filled</i>
        <span i18n="@@pauseResearch"> Pause research</span>
      </button>
    </ng-container>
    <ng-container *ngIf="!isResearchActive&&!isDirectLink">
      <button
        [disabled]="!isModelConfirmed||!allDataIsReady"
        class="button-start"
        [ngClass]="{'button-start-active':isModelConfirmed&&allDataIsReady}"
        mat-raised-button
        (click)="onStartResearch(anonymityState.value)"
      >
        <i class="material-icons">play_circle_filled</i>
        <span i18n="@@startResearch"> Start research</span>
      </button>
    </ng-container>
    <ng-container *ngIf="isDirectLink">
      <div>You're viewing this package by a direct link</div>
      <button mat-raised-button color="accent" (click)="makeCompanyActive()">
        Make active
      </button>
    </ng-container>

    <ng-container *ngIf="showHint">
      <p class="research-start-hint">
        <span i18n="@@settings-check-data-hint">It seems you forgot to add some data. Please check </span>
        <span *ngIf="parametersList.length == 0">
          <a class="check-data-button" (click)="toParametersTab()" i18n="@@settings-parameters-link">
            parameters
          </a>
        </span>
        <span i18n="@@settings-or" *ngIf="parametersList.length == 0 && (questionnaireCards.length == 0 || packageParticipants.length == 0)"> or </span>
        <span *ngIf="questionnaireCards.length == 0">
          <a class="check-data-button" (click)="toQuestionsTab()" i18n="@@settings-questions-link">
            questions
          </a>
        </span>
        <span i18n="@@settings-or" *ngIf="questionnaireCards.length == 0 && packageParticipants.length == 0"> or </span>
        <span *ngIf="packageParticipants.length == 0">
          <a class="check-data-button" (click)="toParticipantsTab()" i18n="@@settings-participants-link">
            participants
          </a>
        </span>
        <span i18n="@@settings-check-data-hint1"> to start your research.</span></p>
    </ng-container>

    <ng-container *ngIf="isAdmin">
      <button (click)="startPackage()" mat-raised-button color="accent" class="startPackageButton">Запустить опрос</button>
    </ng-container>
  </div>

</div>
