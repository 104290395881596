<mat-expansion-panel class="create-parameter">
  <mat-expansion-panel-header (click)="$event.stopPropagation();">
    <mat-panel-title>
      <span i18n="@@create-parameter-btn">Create parameter</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="create-parameter__field">
    <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
    <input matInput required [(ngModel)]="newParameterName">
  </mat-form-field>
  <mat-form-field class="create-parameter__field">
    <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula name</mat-placeholder>
    <input matInput required [(ngModel)]="newFormulaName">
  </mat-form-field>
  <mat-form-field class="create-parameter__field">
    <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula <span class="create-parameter__optional">(optional)</span></mat-placeholder>
    <input matInput [(ngModel)]="newParameterFormula">
  </mat-form-field>
  <div class="create-parameter__field create-parameter__field--radio">
    <mat-radio-group required class="radio-group" [(ngModel)]="newIndividualParameterType">
      <mat-radio-button class="radio-group__item" *ngFor="let singleType of parameterTypes" [value]="singleType">
        {{singleType}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="create-parameter__buttons">
    <button mat-raised-button
            color="accent"
            (click)="createParameter()">
      Save
    </button>
    <button mat-raised-button
            color="primary"
            i18n="@@cancelButton">
      Cancel
    </button>
  </div>
</mat-expansion-panel>
