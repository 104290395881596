<mat-spinner *ngIf="!isLoaded" style="margin: 20px;"></mat-spinner>

<div *ngIf="isLoaded" class="widget heatmap-individual-widget">
  <div *ngIf="!userView" class="widget-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div *ngIf="showChartMode" class="chart-box">
    <nav mat-tab-nav-bar class="tabs-list">
      <a mat-tab-link *ngFor="let tab of tabs"
        (click)="changeTab(tab)"
        [active]="selectedTabIndex == tab.id"
        class="tabs-list__item">
          {{tab.name}}
        </a>
    </nav>
    <highcharts-chart
      style="display: flex;"
      [Highcharts]="Highcharts"
      [options]="chartOptions"
      [(update)]="updateFlag"
      [callbackFunction]="chartCallback"
      [oneToOne]="true"
    ></highcharts-chart>
  </div>

  <mat-card *ngIf="colleaguesEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <button mat-raised-button color="accent" i18n="@@selectAllParticipants" (click)="setActiveParticipants()">
        Select all participants
      </button>
    </div>
    <div *ngFor="let colleague of activeColleagues">
      <div class="parameters-list__item">
        <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()">
          {{colleague.firstName}} {{colleague.lastName}}
        </mat-checkbox>
      </div>
    </div>
  </mat-card> <!-- /.colleagues-list -->

  <mat-card *ngIf="parametersEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div *ngFor="let parameter of heatmapParameters">
      <div class="parameters-list__item heatmap-parameter">
        <mat-form-field style="width: 80px; margin: 2px;">
          <mat-placeholder class="placeholder">Min value</mat-placeholder>
          <input matInput [(ngModel)]="parameter.minValue" (blur)="onBlurMethod()">
        </mat-form-field>
        <mat-form-field style="width: 80px; margin: 2px">
          <mat-placeholder class="placeholder">Max value</mat-placeholder>
          <input matInput [(ngModel)]="parameter.maxValue" (blur)="onBlurMethod()">
        </mat-form-field>
        <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onHeatmapParametersChange(parameter)" class="heatmap-parameter__checkbox">
          {{parameter.visibleName}}
        </mat-checkbox>
      </div>
    </div>
  </mat-card> <!-- /.individual-parameters -->

  <mat-card *ngIf="researchesEditMode" class="parameters-list">
    <div class="parameters-list__header">
      <button mat-menu-item (click)="onShowChartMode()" class="parameters-list__button">
        <mat-icon class="parameters-list__icon">close</mat-icon>
      </button>
    </div>
    <div class="parameters-list__item">
      <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
        <mat-radio-button class="parameters-list__radio" value="last_research" i18n="@@lastResearch">
          Last research
        </mat-radio-button>
        <mat-radio-button class="parameters-list__radio"
          *ngFor="let research of experiments" [value]="research">
          {{research.startDate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card>
</div> <!-- /.widget -->

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showIndividualParametersList">Show individual parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>
