import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ApiParameter } from 'app/_api_models/overmind_ds/api-parameter';
import { environment } from '../../../environments/environment';

export function parseApiParameter(apiResponse: any): Parameter {
  let parameter;

  if (environment.production) {
    parameter = apiResponse;
  } else {
    parameter = ApiParameter.check(apiResponse);
  }

  const newParameter: Parameter = {
    id: parameter.id,
    uuid: parameter.uuid,
    entityUuid: parameter.entity_uuid,
    formulaName: parameter.formula_name,
    name: parameter.name,
    type: parameter.type,
    formula: parameter.formula
  };

  return newParameter;
}
