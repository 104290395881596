<div class="standard-set-settings">
  <div class="left-side">
    <mat-card class="mat-card-class">
      <h2 i18n="@@settings-copyStandardSet-title">You can copy this set in your questionnaires list</h2>
      <button mat-raised-button color="primary" (click)="copyPublicPackage()" i18n="@@get-it">
        Get it
      </button>
      <ng-container *ngIf="isAdmin">
        &nbsp;
        <button mat-raised-button color="warn" (click)="onDeleteSet()">
          Delete set
        </button>
      </ng-container>
    </mat-card>

    <mat-card *ngIf="isAdmin" class="mat-card-class">
      <h2 i18n="@@settings-rename-title">You can rename questionnaire</h2>
      <p i18n="@@rename-hint">Please, pick new name.</p>
      <mat-form-field class="card-full-width">
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-rename">Enter new title</mat-placeholder>
        <input matInput [(ngModel)]="currentSetName" required />
      </mat-form-field>
      <br />
      <button mat-raised-button (click)="onRenameSet(currentSetId, currentSetName)" color="accent"
        i18n="@@submitButton">
        Submit
      </button>
    </mat-card>

    <mat-card *ngIf="isAdmin" class="mat-card-class">
      <h2 i18n="@@settings-category">You can set category name</h2>
      <mat-form-field class="card-full-width">
        <mat-placeholder class="placeholder" i18n="@@placeholder-settings-category">Enter category name</mat-placeholder>
        <input matInput [(ngModel)]="currentCategoryName" required />
      </mat-form-field>
      <br />
      <button mat-raised-button (click)="onRenameCategorySet(currentSetId, currentCategoryName)" color="accent" i18n="@@submitButton">
        Submit
      </button>
    </mat-card>

    <mat-card *ngIf="isAdmin" class="mat-card-class">
      <h2>Language</h2>
      <mat-form-field>
        <mat-placeholder class="placeholder">Choose Language</mat-placeholder>
        <mat-select [(ngModel)]="packageLanguage">
          <mat-option value="en">English</mat-option>
          <mat-option value="ru">Русский</mat-option>
        </mat-select>
      </mat-form-field>

      <br />
      <button mat-raised-button (click)="onLanguageUpdate(currentSetId, currentPackageId)" color="accent" i18n="@@saveButton">
        Save
      </button>
    </mat-card>

  </div>

</div>
