import { ApiPackageParticipant } from 'app/_api_models/api-package-participant';
import { PackageParticipant } from 'app/_models/package-participant';
import { environment } from '../../environments/environment';

export function parseApiPackageParticipant(apiResponse: any): PackageParticipant {
  let participantObject;
  if (environment.production) {
    participantObject = apiResponse;
  } else {
    participantObject = ApiPackageParticipant.check(apiResponse);
  }

  const newParticipant: PackageParticipant = {
    id: participantObject.id,
    colleagueId: participantObject.colleague_id,
    isSelected: participantObject.is_selected,
    packageId: participantObject.package_id,
  };

  return newParticipant;
}
