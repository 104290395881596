import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { Experiment } from 'app/_models/experiment';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { PackageParameter } from 'app/_models/package-parameter';
import { PackageParticipant } from 'app/_models/package-participant';

import { ChartHelper } from '../_helpers/chart-helper';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { ExtendedColleague, ExtendedParameter, WidgetFilters } from '../_helpers/local-models';

import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-individual-chart-widget',
  templateUrl: './individual-chart-widget.component.html',
  styleUrls: ['./individual-chart-widget.component.scss', '../widgets.scss']
})
export class IndividualChartWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  inputWidgetFilters: WidgetFilters;
  experiments: Experiment[] = [];
  widgetFilters: WidgetFilters = {};
  colleagues: ExtendedColleague[] = [];
  participants: PackageParticipant[] = [];
  individualParameters: ExtendedParameter[] = [];

  updateFlag: boolean = false;
  showChartMode: boolean = true;
  isAllParticipantsSelected: boolean = false;
  isAllIndividualParametersSelected: boolean = true;
  colleaguesListEditMode: boolean = false;
  individualParametersEditMode: boolean = false;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'spline',
      marginTop: 40,
      marginBottom: 180,
      height: 500,
    },
    title: {
      text: ''
    },
    yAxis: {
      title: {
        text: ''
      }
    },

    xAxis: {
      accessibility: {
        rangeDescription: ''
      },
      // tickInterval: 1,
      categories: []
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      y: 20,
      maxHeight: 100
    },
    tooltip: {
      valueSuffix: ''
    },
    series: [],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'vertical',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  };

  constructor(private localDashboardHelper: LocalDashboardHelper, private chartHelper: ChartHelper) { }

  ngOnInit(): void {
    const promisesList = [];

    const promiseParticipants = this.localDashboardHelper.getCachedParticipants();
    promiseParticipants.then((participants: PackageParticipant[]) => {
      this.participants = participants;
    }).catch((error) => console.log(error));

    const promiseIndividualParameters = this.localDashboardHelper.getCachedPackageParameters();
    promiseIndividualParameters.then((parameters: PackageParameter[]) => {
      const filterParams = parameters.filter(param => param.groupName === 'individual' && param.commonValue.type === 'number');
      for (const singleParameter of filterParams) {
        this.individualParameters.push({
          ...singleParameter,
          isActive: true
        });
      }
    }).catch(error => console.log(error));

    const promiseExperiments = this.localDashboardHelper.getCachedExperiments(true);
    promiseExperiments.then((experiments: Experiment[]) => {
      if (experiments) {
        const sortedExperimens = experiments.sort(function (a: any, b: any): number {
          return a.id - b.id;
        });
        this.experiments = sortedExperimens;
      }
    }).catch(error => console.log(error));

    const promiseWidget = this.localDashboardHelper.getDashboardWidget(this.inputWidgetId);
    promiseWidget.then((widget: DashboardWidget) => {
      this.inputWidgetFilters = widget.widgetFilters;
    }).catch(error => console.log(error));

    const promiseColleagues = this.localDashboardHelper.getColleagues(false);
    promiseColleagues.then((colleagues: ExtendedColleague[]) => {
      this.colleagues = colleagues;
    }).catch((error) => console.log(error));

    promisesList.push(promiseColleagues);
    promisesList.push(promiseIndividualParameters);
    promisesList.push(promiseExperiments);
    promisesList.push(promiseParticipants);
    promisesList.push(promiseWidget);

    Promise.all(promisesList).then((result) => {
      this.setActiveParticipants();
      this.checkInputWidgets();
      this.formNewChart();
    }).catch(error => console.log(error));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  restoreInputWidgetsFilters(): void {
    const paramsIds = this.inputWidgetFilters.activeParametersIds;
    const colleaguesId = this.inputWidgetFilters.activeColleaguesIds;

    if (paramsIds) {
      this.individualParameters = this.chartHelper.restoreInputWidgetsFilters(paramsIds, this.individualParameters);
    }

    if (colleaguesId) {
      this.colleagues = this.chartHelper.restoreInputWidgetsFilters(colleaguesId, this.colleagues);
    }
  }

  widgetFiltersUpdate(): void {
    this.widgetFilters['activeParametersIds'] = this.chartHelper.widgetFiltersUpdate(this.individualParameters);
    this.widgetFilters['activeColleaguesIds'] = this.chartHelper.widgetFiltersUpdate(this.colleagues);

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  setActiveParticipants(): void {
    this.colleagues = this.chartHelper.setActiveParticipants(this.colleagues, this.participants);
    this.isAllParticipantsSelected = !this.isAllParticipantsSelected;
    this.widgetFiltersUpdate();
  }

  setActiveParameters(): void {
    this.individualParameters = this.chartHelper.setActiveParameters(this.individualParameters, this.isAllIndividualParametersSelected);
    this.isAllIndividualParametersSelected = !this.isAllIndividualParametersSelected;
    this.widgetFiltersUpdate();
  }

  formNewChart(): void {
    const filteredIndividualParameters = this.individualParameters.filter(param => param.isActive && param.commonValue.type === 'number');
    const selectedColleagues = this.colleagues.filter(param => param.isActive);
    const data = [];

    for (const singleParameter of filteredIndividualParameters) {
      for (const colleague of selectedColleagues) {
        const newData = [];
        let colleagueInfo = this.colleagues.find(param => param.id === colleague.id);
        for (const experiment of this.experiments) {
          const experimentAnswers = experiment.experimentAnswers.find(param => param.colleagueId === colleague.id && param.relativeParameters.length === 0);
          if (experimentAnswers) {
            const colleagueAnswers = experimentAnswers.colleagueParameters.find(param => param.colleagueId === colleague.id && param.packageParameterId === singleParameter.id);
            if (colleagueAnswers) {
              newData.push(colleagueAnswers.value);
            } else {
              newData.push(null);
            }
          } else {
            newData.push(null);
          }
        }
        data.push({
          name: '[' + singleParameter.visibleName + '] ' + colleagueInfo.firstName + ' ' + colleagueInfo.lastName,
          data: newData,
          type: 'spline'
        })
      }
    }

    if (data.length === 0) {
      data.push(this.chartHelper.formTestData());
    }

    this.chartOptions.series = data;
    this.chartOptions.xAxis = {
      categories: this.chartHelper.formExperimentsLabels(this.experiments)
    };
    this.updateFlag = true;
  }

  onIndividualParametersEditMode(): void {
    this.individualParametersEditMode = true;
    this.colleaguesListEditMode = false;
    this.showChartMode = false;
  }

  onColleaguesListEditMode(): void {
    this.colleaguesListEditMode = true;
    this.individualParametersEditMode = false;
    this.showChartMode = false;
  }

  onShowChartMode(): void {
    this.showChartMode = true;
    this.individualParametersEditMode = false;
    this.colleaguesListEditMode = false;
    this.formNewChart();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }
}
