<div class="authorization">
  <div *ngIf="!hideForm" class="authorization__container">

    <div class="logo-block">
      <img src="/assets/overmind_icons/logo.svg" alt="Logotype"/>
      <span class="logo-block__title">Teamheat</span>
    </div>

    <h2 class="login title" i18n="@@loginButton">Login</h2>

    <mat-card class="authorization__wrapper">
      <form class="form form--login" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" autocomplete="off" novalidate>     

        <div class="form__fields">
          <mat-form-field class="card-item">
            <input matInput placeholder="Email" class="form-control" name="email" [(ngModel)]="model.email"
                    #email="ngModel" required/>
          </mat-form-field>

          <mat-form-field class="card-item">
            <mat-placeholder class="placeholder" i18n="@@placeholder-password">Password</mat-placeholder>
            <input matInput type="password" class="form-control" name="password" [(ngModel)]="model.password"
                    #password="ngModel" required/>
          </mat-form-field>
        </div>

        <div class="form-group buttons-box three-buttons">
          <div class="buttons-box__item">
            <button 
              [disabled]="loading"
              mat-raised-button
              color="accent"
              class="control-element button-login" 
              i18n="@@loginButton"
              type="submit">
                Login
            </button>
          </div>

          <div class="buttons-box__item">
            <button 
              [disabled]="loading" 
              mat-raised-button 
              color="primary"
              (click)="toSignUpPage()" 
              class="control-element sign-up button-signup" 
              i18n="@@loginPage-signup"
              type="button">
                Sign up
          </button>
          <button 
              [disabled]="loading" 
              mat-raised-button 
              color="primary"
              (click)="toResetPage()" 
              class="control-element reset button-reset" 
              i18n="@@loginPage-reset-title"
              type="button">
                Reset Password
          </button>
          </div>
          <img *ngIf="loading"/>
        </div>
        
        <div *ngIf="error" class="alert alert-danger">
            <div i18n="@@login-error">
              Email or password is incorrect
          </div>
        </div>
        <!-- <div *ngIf="error" class="alert alert-danger">{{error}}</div> -->

      </form> <!-- /.form -->
    </mat-card> <!-- /.authorization__wrapper -->
  </div> <!-- /.authorization__container -->

  <div *ngIf="hideForm">
    <div *ngIf="resetMode" class="message">
      <mat-card>
        <p i18n="@@loginPage-instructionMessage">Instructions were sent by email</p>
      </mat-card>
    </div>
    <div *ngIf="signupMode" class="message">
      <mat-card>
        <p i18n="@@loginPage-registrationTitle">Thank you for registration!</p>
        <p i18n="@@loginPage-registrationMessage">Please check your email for the activation link.</p>
      </mat-card>
    </div>
  </div>
</div>
