import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { HomePageInfo } from 'app/_models/home-page-info';
import { FormBuilder, FormGroup } from '@angular/forms';

import ImageResize from 'quill-image-resize-module';
import * as QuillNamespace from 'quill';
const Quill: any = QuillNamespace;
// Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  isAdmin: boolean = false;
  editMode: boolean = false;
  currentLocale: string = 'en';
  infoObjectId: number;

  editorForm: FormGroup;
  editorContent: string;
  editorEnabled: boolean = true;
  editor: any;
  quill: any;
  infoText: string = '';

  modules: any = {};
  container: any = [
    ['bold', 'italic', 'underline', 'strike'],
    ['code-block'],
    ['blockquote'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': ['', 'center', 'right'] }],
    ['link', 'image', 'video'],
    ['clean']
  ];

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
  ) {
    this.modules = {
      imageResize: {},
      toolbar: {
        container: this.container,
      }
    };

    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  editorCreated(editor: any): void {
    this.editor = editor;
    this.quill = editor;
    editor.focus();
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          // console.log('Result user ', resultUser);
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.currentLocale = resultUser.currentLocale;
          this.isAdmin = isAdmin;
          this.getHomePageInfo();
        }, error => {
          console.log(error);
        });
    }
  }

  getHomePageInfo(): void {
    this.userService.getHomePageInfos()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: HomePageInfo[]) => {
        const infoItem = result.find(singleItem => singleItem.language === this.currentLocale);
        if (infoItem) {
          this.infoText = infoItem.info;
          // this.editorContent = infoItem.info;
          this.infoObjectId = infoItem.id;
        }
      }, error => {
        console.log(error);
      });
  }

  updateHomePageInfo(): void {
    if (this.infoObjectId && this.infoObjectId > 0) {
      const updatedFields = {
        info: this.infoText
      };
      this.userService.updateHomePageInfo(this.infoObjectId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: any) => {
          // console.log('After update home page ', result);
        }, error => {
          console.log(error);
        });
    }
  }

  setEditMode(): void {
    this.editMode = !this.editMode;
  }

  onFocus(event: any): void {
  }

  onBlur(event: any): void {
  }

  onSelectionChange(e: any): void {
    this.updateHomePageInfo();
  }

  onContentChange(e: any): void {
  }

}

