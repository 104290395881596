import { Number, String, Record, Partial, Null } from 'runtypes';

export const ApiPostQuestionnaireAction = Record({
  id: Number,
  text: String,
  parameter: String,
  order_position: Number
}).And(Partial({
  action_type: String.Or(Null),
  python_custom_calculation_id: Number.Or(Null)
}));
