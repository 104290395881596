<div class="wrapper-admin-style" *ngIf="isAdmin">
  <mat-card class="mat-card-class">
    <h2>You can set templates order (Ru)</h2>
    <mat-form-field class="card-full-width">
      <mat-placeholder class="placeholder">Enter order string</mat-placeholder>
      <input matInput [(ngModel)]="templatesOrderRu" required />
    </mat-form-field>
    <br />
    <button mat-raised-button (click)="onUpdateTemplatesOrder('ru')" color="accent"
      i18n="@@submitButton">
      Submit
    </button>
  </mat-card>
  <span>&nbsp;</span>
  <mat-card class="mat-card-class">
    <h2>You can set templates order (En)</h2>
    <mat-form-field class="card-full-width">
      <mat-placeholder class="placeholder">Enter order string</mat-placeholder>
      <input matInput [(ngModel)]="templatesOrderEn" required />
    </mat-form-field>
    <br />
    <button mat-raised-button (click)="onUpdateTemplatesOrder('en')" color="accent" i18n="@@submitButton">
      Submit
    </button>
  </mat-card>
  <button style="margin-top:20px;" mat-raised-button color="accent" (click)="showAllAdminCompanies()">
    Admin companies
  </button>
</div>

<div class="wrapper-admin-style-companies" *ngIf="isAdmin&&companiesListMode">
  <div *ngFor="let singleCompany of adminCompaniesList" class="single-admin-company-style">
    <b>{{singleCompany.name}}</b>
    {{singleCompany.ownerEmail}}
    <div class="button-choose-style">
      <button mat-raised-button (click)="onChooseCompany(singleCompany)" color="primary">
        Choose
      </button>
    </div>
  </div>
</div>