import {Component, OnInit, OnDestroy, Input, EventEmitter,
  Output, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EditOptionsQuestionComponent } from './edit-options-question/';
import { EditScoreQuestionComponent } from './edit-score-question';
import { EditCheckboxesQuestionComponent } from './edit-checkboxes-question';
import { EditTextInputQuestionComponent } from './edit-text-input-question';

import { PackageService } from 'app/_services/package.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';

import { DialogSwitchCardTypeComponent } from 'app/_dialogs/dialog-switch-card-type';
import { PackageParameter } from 'app/_models/package-parameter';
import { ScoreDivision } from 'app/_models/score-division';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: [
    './edit-question.component.scss',
    '../../package-details.component.scss'
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageEditQuestionComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(EditOptionsQuestionComponent, { static: false }) optionsComponent: EditOptionsQuestionComponent;
  @ViewChild(EditScoreQuestionComponent, { static: false }) scoreComponent: EditScoreQuestionComponent;
  @ViewChild(EditCheckboxesQuestionComponent, { static: false }) checkboxesComponent: EditCheckboxesQuestionComponent;
  @ViewChild(EditTextInputQuestionComponent, { static: false }) textInputComponent: EditTextInputQuestionComponent;

  @Input() editedQuestion: QuestionnaireCard;
  @Input() currentPackageId: number;

  @Output() updateQuestion: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeTab: EventEmitter<any> = new EventEmitter<any>();

  card: QuestionnaireCard;
  initialCard: QuestionnaireCard;

  currentCardType: string;
  cardTypeChanged: boolean = false;
  stickySidebar: boolean = false;
  // isRequired: boolean;

  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];

  constructor(
    private router: Router,
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private sharedService: SharedService,
    public dialog: MatDialog) {
  }

  public handleScroll(event: any): void {
    this.stickySidebar = event.originalEvent.target.scrollTop >= 0;
    // console.log(this.stickySidebar);
  }

  ngOnInit(): void {
    this.initialCard = this.getInitialCard();
    this.card = this.getInitialCard();
    this.currentCardType = this.card.cardType;

    this.sharedPackageService.packageParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameters: PackageParameter[]) => {
        this.separateParameters(parameters);
      });
  }

  separateParameters(parametersList: PackageParameter[]): void {
    this.individualParameters = [];
    this.commonParameters = [];
    this.relativeParameters = [];
    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  copyQuestionnaireCard(inputCard: QuestionnaireCard): QuestionnaireCard {
    const localOptions: CardOption[] = [];
    for (const option of inputCard.cardOptions) {
      const newOption = Object.assign({}, option);
      const localActions = [];
      for (const action of option.cardActions) {
        const newAction = Object.assign({}, action);
        localActions.push(newAction);
      }
      newOption.cardActions = localActions;
      localOptions.push(newOption);
    }

    const localScoreDivisions: ScoreDivision[] = [];
    for (const singleDivision of inputCard.scoreDivisions) {
      const newDivision = Object.assign({}, singleDivision);
      localScoreDivisions.push(newDivision);
    }

    const newCard: QuestionnaireCard = {
      id: inputCard.id,
      questionText: inputCard.questionText,
      cardType: inputCard.cardType,
      questionnaireId: inputCard.questionnaireId,
      cardOptions: localOptions,
      orderPosition: inputCard.orderPosition,
      isRequired: inputCard.isRequired,
      scoreDivisions: localScoreDivisions,
      divisionsColorScheme: inputCard.divisionsColorScheme,
    };

    return newCard;
  }

  getInitialCard(): QuestionnaireCard {
    const localCard = this.copyQuestionnaireCard(this.editedQuestion);
    return localCard;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setOptionValue(event: any): void {
    if (event.value) {
      const previousCardType = this.card.cardType;
      const newCardType = event.value;
      if (previousCardType !== newCardType) {
        this.showDialogChangeType(previousCardType, newCardType);
      }

    }
  }

  showDialogChangeType(previousCardType: string, newCardType: string): void {
    const dialogRef = this.dialog.open(DialogSwitchCardTypeComponent, {
      data: {
        dialogTitle: 'Change card type',
        dialogSubtitle: 'Changing the type could result in losing working data. Are you sure?',
        dialogConfirmText: 'Yes',
        dialogCancelText: 'No',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.card.cardType = newCardType;
        if (this.isChangingFromMultipleToSingle(previousCardType, newCardType)) {
          this.showDialogKeepActions(previousCardType, newCardType);
        } else {
          this.changeCardType(previousCardType, newCardType, true);
        }
      } else {
        this.currentCardType = previousCardType;
      }
    });
  }

  isChangingFromMultipleToSingle(previousCardType: string, newCardType: string): boolean {
    let checkResult = false;
    if ((previousCardType === 'options') || (previousCardType === 'checkboxes'
    || (previousCardType === 'relative-options') || (previousCardType === 'relative-checkboxes'))) {
      if ((newCardType === 'score') || (newCardType === 'text-input')
      || (newCardType === 'relative') || (newCardType === 'relative-score')) {
        checkResult = true;
      }
    }

    return checkResult;
  }

  showDialogKeepActions(previousCardType: string, newCardType: string): void {
    const dialogRef = this.dialog.open(DialogSwitchCardTypeComponent, {
      data: {
        dialogTitle: 'Do you want to keep actions?',
        dialogSubtitle: 'All your actions will be added to the new question',
        dialogConfirmText: 'Keep',
        dialogCancelText: 'Remove',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.changeCardType(previousCardType, newCardType, true);
      } else {
        this.changeCardType(previousCardType, newCardType, false);
      }
    });
  }

  changeCardType(previousCardType: string, newCardType: string, keepActions: boolean): void {
    switch (previousCardType) {
      case 'options': {
        const newCard = this.changeFromOptionsQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'relative-options': {
        const newCard = this.changeFromOptionsQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'checkboxes': {
        const newCard = this.changeFromCheckboxesQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'relative-checkboxes': {
        const newCard = this.changeFromCheckboxesQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'score': {
        const newCard = this.changeFromScoreQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'relative-score': {
        const newCard = this.changeFromScoreQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'text-input': {
        const newCard = this.changeFromTextInputQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'relative': {
        const newCard = this.changeFromTextInputQuestion(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      case 'info-card': {
        const newCard = this.changeFromInfoCard(newCardType, keepActions);
        this.card = newCard;
        break;
      }
      default:
        break;
    }
  }

  changeFromOptionsQuestion(newCardType: string, keepActions: boolean): QuestionnaireCard {
    let newCardOptions;
    if ((newCardType === 'checkboxes') || (newCardType === 'relative-checkboxes')) {
      newCardOptions = this.card.cardOptions;
    } else if ((newCardType === 'score') || (newCardType === 'text-input')
    || (newCardType === 'relative') || (newCardType === 'relative-score')) {
      newCardOptions = this.tranlateMultipleOptionsToSingle(keepActions);
    } else if (newCardType === 'info-card') {
      newCardOptions = [];
    } else {
      newCardOptions = this.card.cardOptions;
      console.log('Wrong card type');
    }

    const newCard: QuestionnaireCard = {
      id: this.card.id,
      questionText: this.card.questionText,
      cardType: newCardType,
      questionnaireId: this.card.questionnaireId,
      orderPosition: this.card.orderPosition,
      cardOptions: newCardOptions,
      scoreDivisions: [],
      isRequired: this.card.isRequired,
      divisionsColorScheme: this.card.divisionsColorScheme,
    };

    return newCard;
  }

  changeFromCheckboxesQuestion(newCardType: string, keepActions: boolean): QuestionnaireCard {
    let newCardOptions;
    if ((newCardType === 'options') || (newCardType === 'relative-options')) {
      newCardOptions = this.card.cardOptions;
    } else if ((newCardType === 'score') || (newCardType === 'text-input')
    || (newCardType === 'relative') || (newCardType === 'relative-score')) {
      newCardOptions = this.tranlateMultipleOptionsToSingle(keepActions);
    } else if (newCardType === 'info-card') {
      newCardOptions = [];
    } else {
      newCardOptions = this.card.cardOptions;
      console.log('Wrong card type');
    }

    const newCard: QuestionnaireCard = {
      id: this.card.id,
      questionText: this.card.questionText,
      cardType: newCardType,
      questionnaireId: this.card.questionnaireId,
      orderPosition: this.card.orderPosition,
      cardOptions: newCardOptions,
      scoreDivisions: [],
      isRequired: this.card.isRequired,
      divisionsColorScheme: this.card.divisionsColorScheme,
    };

    return newCard;
  }

  tranlateMultipleOptionsToSingle(keepActions: boolean): CardOption[] {
    const newCardOptions = [];
    const cardInputOption = this.card.cardOptions[0];
    if (cardInputOption) {
      newCardOptions.push(cardInputOption);

      if (keepActions) {
        const actionsList = this.getAllActionsFromOptions(this.card.cardOptions);
        cardInputOption.cardActions = actionsList;
      }

    } else {
      const newOption: CardOption = {
        id: 0,
        text: '',
        orderPosition: 0,
        cardActions: []
      };

      newCardOptions.push(newOption);
    }

    return newCardOptions;
  }

  changeFromScoreQuestion(newCardType: string, keepActions: boolean): QuestionnaireCard {
    const newCardOptions = this.card.cardOptions;

    const newCard: QuestionnaireCard = {
      id: this.card.id,
      questionText: this.card.questionText,
      cardType: newCardType,
      questionnaireId: this.card.questionnaireId,
      orderPosition: this.card.orderPosition,
      cardOptions: newCardOptions,
      scoreDivisions: [],
      isRequired: this.card.isRequired,
      divisionsColorScheme: this.card.divisionsColorScheme,
    };

    return newCard;
  }

  changeFromTextInputQuestion(newCardType: string, keepActions: boolean): QuestionnaireCard {
    const newCardOptions = this.card.cardOptions;

    const newCard: QuestionnaireCard = {
      id: this.card.id,
      questionText: this.card.questionText,
      cardType: newCardType,
      questionnaireId: this.card.questionnaireId,
      orderPosition: this.card.orderPosition,
      cardOptions: newCardOptions,
      scoreDivisions: [],
      isRequired: this.card.isRequired,
      divisionsColorScheme: this.card.divisionsColorScheme,
    };

    return newCard;
  }

  changeFromInfoCard(newCardType: string, keepActions: boolean): QuestionnaireCard {
    let newCardOptions = [];
    if ((newCardType === 'score') || (newCardType === 'text-input')) {
      newCardOptions = this.tranlateMultipleOptionsToSingle(keepActions);
    }
    const newCard: QuestionnaireCard = {
      id: this.card.id,
      questionText: this.card.questionText,
      cardType: newCardType,
      questionnaireId: this.card.questionnaireId,
      orderPosition: this.card.orderPosition,
      cardOptions: newCardOptions,
      scoreDivisions: [],
      isRequired: this.card.isRequired,
      divisionsColorScheme: this.card.divisionsColorScheme,
    };

    return newCard;
  }

  getAllActionsFromOptions(cardOptions: CardOption[]): CardAction[] {
    const newActionsList = [];
    for (const singleOption of cardOptions) {
      for (const singleAction of singleOption.cardActions) {
        newActionsList.push(singleAction);
      }
    }

    return newActionsList;
  }

  restoreInitialCard(): void {
    this.card = this.getInitialCard();
    this.currentCardType = this.card.cardType;
  }

  onSaveDescription(newDescription: string): void {
    if (newDescription) {
      this.card.questionText = newDescription;
    }
  }

  onSaveQuestion(): void {
    const packId = this.currentPackageId;

    const cardType = this.currentCardType;
    let newCardOptions = [];
    let newScoreDivisions = this.card.scoreDivisions;
    if ((cardType === 'options') || (cardType === 'relative-options')) {
      const localOptions = this.optionsComponent.getCurrentOptions();
      newCardOptions = localOptions;
    } else if ((cardType === 'checkboxes') || (cardType === 'relative-checkboxes')) {
      const localOptions = this.checkboxesComponent.getCurrentOptions();
      newCardOptions = localOptions;
    } else if ((cardType === 'text-input') || (cardType === 'relative')) {
      const textInputOption = this.textInputComponent.getTextInputOption();
      newCardOptions.push(textInputOption);
    } else if ((cardType === 'score') || cardType === 'relative-score') {
      const scoreOption = this.scoreComponent.getScoreInputOption();
      const scoreDivisions = this.scoreComponent.getScoreDivisions();
      const divisionsColorScheme = this.scoreComponent.getDivisionsColorScheme();
      this.card.divisionsColorScheme = divisionsColorScheme;
      newCardOptions.push(scoreOption);
      newScoreDivisions = scoreDivisions;
    }

    const updatedCard: QuestionnaireCard = this.card;
    updatedCard.scoreDivisions = newScoreDivisions;
    updatedCard.cardOptions = newCardOptions;
    this.updateQuestion.emit(updatedCard);
    this.sharedService.editQuestionMode$.emit(false);
    this.router.navigateByUrl(`/questionnaires/${packId}/questions`);
  }

  goBack(): void {
    this.sharedService.editQuestionMode$.emit(false);
  }
}
