import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { Package } from 'app/_models/package';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageService } from 'app/_services/package.service';

// import Quill from 'quill/dist/quill';
import * as QuillNamespace from 'quill';
const Quill: any = QuillNamespace;

@Component({
  selector: 'app-package-description',
  templateUrl: './package-description.component.html',
  styleUrls: ['./package-description.component.scss']
})
export class PackageDescriptionComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() packageDescription: string;
  currentPackageId: number;

  editorForm: FormGroup;
  editorContent: string;
  editorEnabled: boolean = false;
  config: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': ['', 'center', 'right'] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  constructor(
    private formBuilder: FormBuilder,
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
  ) {
    this.editorForm = this.formBuilder.group({
      editor: ['', Validators.required]
    });
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
   }

  ngOnInit(): void {
    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.currentPackageId = packageId;
        this.editorForm = this.formBuilder.group({
          editor: [this.packageDescription, Validators.required]
        });
      });
  }

  checkMaxLength(e: any): void {
    if (e.editor.getLength() > 1000) {
      console.log('max length 1000!');
      // e.editor.deleteText(1000, e.editor.getLength());
    }
  }

  onSubmit(): void {
    this.editorContent = this.editorForm.get('editor').value;
    this.packageDescription = this.editorForm.get('editor').value;
    this.onRenameDescription(this.currentPackageId, this.packageDescription);
    this.editorEnabled = false;
  }

  onDiscard(): void {
    this.editorForm = this.formBuilder.group({
      editor: [this.packageDescription, Validators.required]
    });
    this.editorEnabled = false;
  }

  setEditorEnabled(): void {
    this.editorEnabled = true;
  }

  onRenameDescription(packageId: number, newDescription: string): void {
    if (packageId > 0 && newDescription) {

      const updatedFields: any = {
        description: newDescription,
      };

      this.packageService.updatePackage(packageId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((newPackage: Package) => {
          this.sharedPackageService.onRefreshPackage$.emit();
          this.sharedPackageService.currentPackageDescription$.next(newDescription);
        },
          error => console.log(error));
    } else {
      console.log('Can\'t rename the package');
    }
  }


}
