<div *ngIf="isLoading" class="loading-container">
  <app-loading-spinner></app-loading-spinner>
</div>

<section *ngIf="!isLoading" class="parameters">
  <div class="parameters__content">
    <div *ngFor="let parameter of parameters">
      <app-extended-parameter 
        [authorUuid]="authorUuid"
        [sourceUuid]="sourceUuid"
        [entityUuid]="entityUuid"
        [parameter]="parameter">
      </app-extended-parameter>
    </div>
  </div>

  <div class="parameters__tools">
    <app-create-parameter [entityUuid]='colleague.uuid' (newParameter)='addNewParameter($event)'></app-create-parameter>
  </div>
</section>
