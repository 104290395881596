import { RelativeExperimentParameter } from 'app/_models/relative-experiment-parameter';
import { ApiRelativeExperimentParameter } from 'app/_api_models/api-relative-experiment-parameter';
import { environment } from '../../environments/environment';

export function parseApiRelativeExperimentParameter(apiResponse: any): RelativeExperimentParameter {
  let parameterObject;
  if (environment.production) {
    parameterObject = apiResponse;
  } else {
    parameterObject = ApiRelativeExperimentParameter.check(apiResponse);
  }

  const newParameter: RelativeExperimentParameter = {
    id: parameterObject.id,
    value: parameterObject.value,
    textValue: parameterObject.text_value,
    relativeExperimentAnswerId: parameterObject.relative_experiment_answer_id,
    packageParameterId: parameterObject.package_parameter_id,
    colleagueId: parameterObject.colleague_id
  };

  return newParameter;
}

