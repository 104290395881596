<mat-card class="colleague">
  <div class="colleague-header">
    <a (click)="onBackClick()" class="button-back" title="Назад к списку коллег">
      <mat-icon *ngIf="!isPublicProfile" role="img" aria-hidden="true">arrow_back</mat-icon>
    </a>

    <mat-card class="colleague-header__info colleague-info">
      <mat-card-header class="colleague-info__header">
        <div mat-card-avatar class="colleague-info__avatar">{{fullName | slice : 0 : 1}}</div>
        <mat-card-title class="colleague-info__item">{{fullName}}</mat-card-title>
        <mat-card-subtitle class="colleague-info__item">Department: {{colleagueDepartment}}</mat-card-subtitle>
        <mat-card-subtitle class="colleague-info__item">Email: {{colleagueEmail}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="slackUserId !== ''" class="colleague-info__item">Slack: {{slackUserId}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="colleaguePublicLink && colleaguePublicLink.length > 0 && !isPublicProfile" class="colleague-info__item">
          <button [cdkCopyToClipboard]="fullPublicLink" (click)="afterLinkCopied()" class="generate_link">Copy public link</button>
        </mat-card-subtitle>
        <mat-card-subtitle *ngIf="!colleaguePublicLink && !isPublicProfile" class="colleague-info__item">
          <button (click)="onGeneratePublicLink()" class="generate_link">Generate public link</button>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div> <!-- /.colleague-header -->

  <mat-tab-group [selectedIndex]="selectedTabId" class="colleague-tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <span (click)="changeUrl(0)" i18n="@@colleagueTabData">Data</span>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span (click)="changeUrl(1)" i18n="@@colleagueTabNotes">Notes</span>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!isPublicProfile">
      <ng-template mat-tab-label>
        <span (click)="changeUrl(2)" i18n="@@colleagueTabProfile">Profile</span>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span (click)="changeUrl(3)" i18n="@@colleagueTabProfile">Parameters</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group> <!-- /.colleague-menu -->

  <div class="colleague-content">
    <router-outlet></router-outlet>
  </div>
</mat-card> <!-- /.colleague -->
