<div class="editor-wrapper-style">
  <mat-form-field>
    <mat-placeholder class="placeholder">Theme</mat-placeholder>
    <mat-select [(ngModel)]="editorTheme" (selectionChange)="onSelectionChange($event)">
      <mat-option value="vs">Default</mat-option>
      <mat-option value="vs-dark">Dark</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <mat-form-field style="width: 80px;">
    <mat-placeholder class="placeholder">Font size</mat-placeholder>
    <input matInput [(ngModel)]="fontSize" (blur)="onBlurMethod()">
  </mat-form-field>
  &nbsp;
  <button
    mat-raised-button
    color="accent"
    (click)="onButtonCalculate()"
    i18n="@@calculate">
      Calculate
  </button>
  &nbsp;
  <button
    mat-raised-button color="accent"
    [cdkCopyToClipboard]="pythonContext"
    (click)="onButtonLoadContext()"
    i18n="@@loadContext">
      Load Context
  </button>
  &nbsp;
  <ng-container *ngIf="editMode">
    <button
      mat-raised-button
      color="accent"
      (click)="onSaveAction()"
      i18n="@@saveButton">
        Save
    </button>
  </ng-container>

  <ngx-monaco-editor class="my-code-editor" [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
  <div style="white-space: pre;" *ngIf='pythonContext'>
    <button
      mat-raised-button
      color="accent"
      [cdkCopyToClipboard]="pythonContext"
      (click)="onCopyContext()"
      i18n="@@copyContext">
        Скопировать контекст
    </button>
    <div class="colored-text">
      <ngx-json-viewer [json]="jsonContext" [expanded]="false"></ngx-json-viewer>
    </div>
  </div>
</div>
