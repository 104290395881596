import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';

import { PackageParameter } from 'app/_models/package-parameter';
import { PackageService } from 'app/_services/package.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { SnackBarComponent } from 'app/snack-bar/';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ParameterVisibility } from 'app/_models/parameter-visibility';

export class ExtendedParameter extends PackageParameter {
  formulaMode: boolean;
}

export class ParameterGroup {
  id: number;
  name: string;
  title: string;
  parameters: ExtendedParameter[];
}

@Component({
  selector: 'app-package-parameters',
  templateUrl: './package-parameters.component.html',
  styleUrls: ['./package-parameters.component.scss'],
  providers: [SnackBarComponent]
})
export class PackageParametersComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  currentPackageId: number;
  // parametersList: PackageParameter[] = [];

  individualParameters: ParameterGroup = {
    id: 1,
    name: 'individual',
    title: 'Individual Parameters',
    parameters: [],
  };

  commonParameters: ParameterGroup = {
    id: 2,
    name: 'common',
    title: 'Common Parameters',
    parameters: [],
  };

  relativeParameters: ParameterGroup = {
    id: 3,
    name: 'relative',
    title: 'Relative Parameters',
    parameters: [],
  };

  paramsGroups: ParameterGroup[] = [
    this.individualParameters,
    this.commonParameters,
    this.relativeParameters
  ];

  parameterTypes: any[] = [
    {'name': 'Number', 'code': 'number'},
    {'name': 'Text', 'code': 'text'}
  ];

  newIndividualFormulaName: string = '';
  newIndividualVisibleName: string = '';
  newIndividualParameterType: string = '';
  newCommonFormulaName: string = '';
  newCommonVisibleName: string = '';
  newCommonParameterType: string = '';
  newRelativeFormulaName: string = '';
  newRelativeVisibleName: string = '';
  newRelativeParameterType: string = '';
  // newRelativeParameterType: string = 'text';

  panelOpenState: boolean = false;
  panelCommonOpenState: boolean = false;
  panelRelativeOpenState: boolean = false;

  constructor(
    private router: Router,
    private packageService: PackageService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private snackBar: SnackBarComponent
  ) { }

  ngOnInit(): void {
    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.currentPackageId = packageId;
      });

    this.sharedService.currentTabId.next(1);

    const parameters = this.sharedPackageService.packageParameters$.getValue();
    this.separateParameters(parameters);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toQuestionnairePage(): void {
    this.router.navigateByUrl(`/questionnaires/${this.currentPackageId}/questions`);
  }

  updateCachedPackageParameters(): void {
    const updatedParameters: PackageParameter[] = [];
    for (const paramGroup of this.paramsGroups) {
      for (const singleParameter of paramGroup.parameters) {
        const newParameter: PackageParameter = {
          commonValue: singleParameter.commonValue,
          formulaName: singleParameter.formulaName,
          groupName: singleParameter.groupName,
          id: singleParameter.id,
          packageId: singleParameter.packageId,
          visibleName: singleParameter.visibleName
        };
        // console.log('new parameter ', newParameter);
        updatedParameters.push(newParameter);
      }
    }

    this.sharedPackageService.packageParameters$.next(updatedParameters);
  }

  getPackageParameters(packageId: number): void {
    this.packageService.getPackageParameters(packageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameters: PackageParameter[]) => {
        this.separateParameters(parameters);
      });
  }

  switchParameterMode(parameter: ExtendedParameter): void {
    const currentMode = parameter.formulaMode;
    parameter.formulaMode = !currentMode;
  }

  separateParameters(parametersList: PackageParameter[]): void {
    const individualGroup = this.paramsGroups.find(group => group.name === 'individual');
    const commonGroup = this.paramsGroups.find(group => group.name === 'common');
    const relativeGroup = this.paramsGroups.find(group => group.name === 'relative');

    for (const singleParameter of parametersList) {
      const groupName = singleParameter.groupName;
      const extendedParameter: ExtendedParameter = {
        formulaMode: false,
        ...singleParameter
      };

      if (groupName === 'individual') {
        individualGroup.parameters.push(extendedParameter);
      } else if (groupName === 'common') {
        commonGroup.parameters.push(extendedParameter);
      } else if (groupName === 'relative') {
        relativeGroup.parameters.push(extendedParameter);
      }
    }
  }

  addParameter(groupId: number): void {
    const packageId = this.currentPackageId;
    const activeGroup = this.paramsGroups.find(group => group.id === groupId);

    if (activeGroup && packageId) {
      const groupName = activeGroup.name;
      let newVisibleName;
      let newFormulaName;
      let newParameterType;
      if (groupName === 'common') {
        newVisibleName = this.newCommonVisibleName;
        newFormulaName = this.newCommonFormulaName;
        newParameterType = this.newCommonParameterType;
      } else if (groupName === 'individual') {
        newVisibleName = this.newIndividualVisibleName;
        newFormulaName = this.newIndividualFormulaName;
        newParameterType = this.newIndividualParameterType;
      } else if (groupName === 'relative') {
        newVisibleName = this.newRelativeVisibleName;
        newFormulaName = this.newRelativeFormulaName;
        newParameterType = this.newRelativeParameterType;
      } else {
        this.openSnackBar('Parameter type is invalid');
        return;
      }

      const newParameterInfo: PackageParameter = {
        id: 0,
        visibleName: newVisibleName,
        formulaName: newFormulaName,
        groupName: groupName,
        packageId: packageId,
        commonValue: {
          type: newParameterType,
          value: 0,
        },
      };

      if (newVisibleName && newFormulaName && newParameterType) {
        this.packageService.addNewParameter(newParameterInfo)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultParameter: PackageParameter) => {
              if (resultParameter) {
                this.createParameterVisibilities(resultParameter.id)
                const extendedParameter: ExtendedParameter = {
                  formulaMode: false,
                  ...resultParameter
                };
                activeGroup.parameters.push(extendedParameter);
                this.updateParameter(extendedParameter, groupName);
                this.updateCachedPackageParameters();
                this.closePanel(groupName);
              }
            },
            error => {
              console.log('Parameters error ', error);
            });
      } else {
        if (!newVisibleName) {
          this.openSnackBar('Parameter name is invalid');
        } else if (!newFormulaName) {
          this.openSnackBar('Formula name is invalid');
        } else if (!newParameterType) {
          this.openSnackBar('Parameter type is invalid');
        }
      }
    }
  }

  createParameterVisibilities(parameterId: number): void {
    this.packageService.createParameterVisibility(parameterId)
      .subscribe(() => {})
  }

  closePanel(groupName: string): void {
    if (groupName === 'individual') {
      this.newIndividualFormulaName = '';
      this.newIndividualVisibleName = '';
      this.panelOpenState = false;
    } else if (groupName === 'common') {
      this.newCommonFormulaName = '';
      this.newCommonVisibleName = '';
      this.panelCommonOpenState = false;
    } else if (groupName === 'relative') {
      this.newRelativeFormulaName = '';
      this.newRelativeVisibleName = '';
      this.panelRelativeOpenState = false;
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  togglePanel(parameterGroupName: string): void {
    if (parameterGroupName === 'common') {
      this.panelCommonOpenState = !this.panelCommonOpenState;
    } else if (parameterGroupName === 'individual') {
      this.panelOpenState = !this.panelOpenState;
    } else if (parameterGroupName === 'relative') {
      this.panelRelativeOpenState = !this.panelRelativeOpenState;
    }
  }

  radioChange(event: any, groupId: number, parameterId: number): void {
    const radioValue = event.value;
    if (radioValue) {
      this.updateParameterType(groupId, parameterId, radioValue);
    }
  }

  onBlurParameter(groupId: number, parameterId: number): void {
    const activeGroup = this.paramsGroups.find(group => group.id === groupId);
    if (activeGroup) {
      const activeParameter = activeGroup.parameters.find(param => param.id === parameterId);
      if (activeParameter) {
        this.updateParameter(activeParameter, activeGroup.name);
      }
    }
  }

  onBlurNewVisibleName(groupName: string): void {
    if (groupName === 'individual') {
      if (!this.newIndividualFormulaName) {
        const newFormulaName = this.generateFormulaName(this.newIndividualVisibleName);
        this.newIndividualFormulaName = newFormulaName;
      }
    } else if (groupName === 'common') {
      if (!this.newCommonFormulaName) {
        const newFormulaName = this.generateFormulaName(this.newCommonVisibleName);
        this.newCommonFormulaName = newFormulaName;
      }
    } else if (groupName === 'relative') {
      if (!this.newRelativeFormulaName) {
        const newFormulaName = this.generateFormulaName(this.newRelativeVisibleName);
        this.newRelativeFormulaName = newFormulaName;
      }
    }
  }

  onBlurNewFormulaName(groupName: string): void {
    if (groupName === 'individual') {
      const newFormulaName = this.checkFormulaName(this.newIndividualFormulaName);
      this.newIndividualFormulaName = newFormulaName;
    } else if (groupName === 'common') {
      const newFormulaName = this.checkFormulaName(this.newCommonFormulaName);
      this.newCommonFormulaName = newFormulaName;
    } else if (groupName === 'relative') {
      const newFormulaName = this.checkFormulaName(this.newRelativeFormulaName);
      this.newRelativeFormulaName = newFormulaName;
    }
  }

  checkFormulaName(inputFormulaName: string): string {
    const localFormulaName = inputFormulaName.split(' ').join('_');
    const newFormulaName = localFormulaName.slice(0, 10);
    return newFormulaName;
  }

  generateFormulaName(inputVisibleName: string): string {
    const localVisibleName = inputVisibleName.split(' ').join('_');
    let formulaName = '';
    // console.log('LOCAL VISIBLE NAME ', localVisibleName);
    if (localVisibleName.length > 10) {
      formulaName = localVisibleName.slice(0, 8);
      formulaName = formulaName + '01';
    } else {
      formulaName = localVisibleName;
    }

    // console.log('formula name ', formulaName);

    return formulaName;
  }

  updateParameterType(groupId: number, parameterId: number, type: string): void {
    const activeGroup = this.paramsGroups.find(group => group.id === groupId);
    if (activeGroup) {
      const activeParameter = activeGroup.parameters.find(param => param.id === parameterId);
      if (activeParameter) {
        const commonValue = {
          type: type,
          value: activeParameter.commonValue.value,
        };
        const newParameterInfo = {
          commonValue: commonValue
        };

        this.packageService.updateParameter(activeParameter.id, newParameterInfo)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultParameter: PackageParameter) => {
            if (resultParameter) {
              // console.log('Result parameter ', resultParameter);
              this.afterParameterUpdate(resultParameter);
            }
          },
            error => {
              console.log('Parameters error ', error);
            });
      }
    }
  }

  updateParameter(parameter: PackageParameter, groupName: string): void {
    if (parameter) {
      let formulaName = parameter.formulaName;
      if (formulaName === '') {
        formulaName = this.generateFormulaName(parameter.visibleName);
      } else {
        formulaName = this.checkFormulaName(parameter.formulaName);
      }

      const newParameterInfo: PackageParameter = {
        id: parameter.id,
        visibleName: parameter.visibleName,
        formulaName: formulaName,
        groupName: parameter.groupName,
        packageId: parameter.packageId,
        commonValue: parameter.commonValue,
      };
      const parameterId = parameter.id;

      this.packageService.updateParameter(parameterId, newParameterInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameter: PackageParameter) => {
          if (resultParameter) {
            // console.log('Result parameter ', resultParameter);
            this.afterParameterUpdate(resultParameter);
          }
        },
        error => {
          console.log('Parameters error ', error);
        });

    }
  }

  afterParameterUpdate(parameter: PackageParameter): void {
    const activeGroup = this.paramsGroups.find(group => group.name === parameter.groupName);
    if (activeGroup) {
      const updatedParameters: ExtendedParameter[] = [];
      for (const singleParameter of activeGroup.parameters) {
        if (singleParameter.id === parameter.id) {
          const newParameter: ExtendedParameter = {
            formulaMode: singleParameter.formulaMode,
            ...parameter
          };
          updatedParameters.push(newParameter);
        } else {
          updatedParameters.push(singleParameter);
        }

        // activeGroup.parameters = updatedParameters;
        this.updateCachedPackageParameters();
      }
    }
  }

  deleteParameter(groupId: number, parameterId: number): void {
    const activeGroup = this.paramsGroups.find(group => group.id === groupId);
    if (activeGroup) {
      const parameterToDelete = activeGroup.parameters.find(param => param.id === parameterId);
      if (parameterToDelete) {
        this.packageService.removeParameter(parameterId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: any) => {
            if (result) {
              const newParameters = activeGroup.parameters.filter(param => param.id !== parameterId);
              activeGroup.parameters = newParameters;
              this.updateCachedPackageParameters();
            }
          },
          error => {
            console.log('Parameters error ', error);
          });
      }
    }
  }

}
