import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar/snack-bar.component';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog/custom-alert-dialog.component';

import { CompanyService } from 'app/_services/company.service';

import { Colleague } from 'app/_models/colleague';
import { ExtendedColleague } from '../local-models';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

@Component({
  selector: 'app-colleagues-archive',
  templateUrl: './colleagues-archive.component.html',
  styleUrls: ['./colleagues-archive.component.scss', '../colleagues-page.component.scss']
})
export class ColleaguesArchiveComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  colleagueSearch: string = '';
  archivedColleaguesList: ExtendedColleague[] = [];
  defaultArchivedColleaguesList: ExtendedColleague[] = [];
  isArchivedColleaguesListEmpty: boolean = true;

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarComponent,
    private companyService: CompanyService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.getArchivedColleaguesList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getArchivedColleaguesList(): void {
    this.sharedCompanyDataService.archivedColleaguesList$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((archivedColleaguesList: Colleague[]) => {
        if (archivedColleaguesList && archivedColleaguesList.length > 0) {
          this.archivedColleaguesList = archivedColleaguesList;
          this.defaultArchivedColleaguesList = archivedColleaguesList;
          this.isArchivedColleaguesListEmpty = false;
        } else {
          this.archivedColleaguesList = [];
          this.isArchivedColleaguesListEmpty = true;
        }
      });
  }

  onRestoreColleague(colleague: Colleague): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Restore colleague?',
        dialogSubtitle: 'Are you sure you want to restore colleague?',
        dialogConfirmText: 'Restore',
        dialogConfirmType: 'restore',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.restoreColleague(colleague);
      }
    });
  }

  restoreColleague(colleague: Colleague) {
    const colleagueInfo = {
      archived: false,
    };

    colleague.archived = false;

    this.companyService.updateColleague(colleague.id, colleagueInfo)
      .subscribe(() => {
        this.sharedCompanyDataService.onColleaguesRefresh$.emit();
        this.openSnackBar('Colleague restored');
      },
      error => {
        this.openSnackBar('Can\'t restore');
        console.log('Colleagues info', error);
      });
  }

  onColleagueSearch(): void {
    const colleagueSearch = this.colleagueSearch.toLowerCase();

    if (colleagueSearch !== '') {
      this.archivedColleaguesList = this.defaultArchivedColleaguesList.filter(param => param.firstName.toLowerCase().includes(colleagueSearch) || param.lastName.toLowerCase().includes(colleagueSearch));
    } else {
      this.archivedColleaguesList = this.defaultArchivedColleaguesList;
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
