import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { SharedPackageService } from 'app/_services/shared-package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { PackageService } from 'app/_services/package.service';
import { DashboardService } from 'app/_services/dashboard.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { Package } from 'app/_models/package';
import { PackageParameter } from 'app/_models/package-parameter';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { ScoreDivision } from 'app/_models/score-division';
import { takeUntil } from 'rxjs/operators';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { PythonCustomCalculation } from 'app/_models/python-custom-calculation';

@Injectable()
export class MakePublicPackageHelper implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  newParameters: PackageParameter[] = [];

  constructor(
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private dashboardService: DashboardService,
    private questionnaireService: QuestionnaireService,
    private amplitudeService: AmplitudeEventsService,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  createStandardSet(standardSetName: string, standardSetDescription: string, packageLanguage: string): void {
    // const companyId = this.sharedPackageService.currentCompanyId.getValue();
    this.newParameters = [];
    const companyId = this.sharedPackageService.packageCompanyId$.getValue();
    this.createNewPackage(companyId, standardSetName, standardSetDescription, packageLanguage);
  }

  createNewPackage(companyId: number, newName: string, newDescription: string, newLanguage: string): void {
    if (companyId > 0) {
      if (newName && companyId) {
        this.packageService.createSetFromPrivatePackage(newName, newDescription, newLanguage)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultPackage: Package) => {
            const promisesList = [];
            const id = resultPackage.id;
            const newQuestionnaire = resultPackage.questionnaire;
            const newQuestionnaireId = newQuestionnaire.id;
            const promiseParameters = this.copyPackageParameters(id);
            promisesList.push(promiseParameters);
            const promiseQuestionnaire = this.copyPublicQuestionnaire(id, newQuestionnaireId);
            promisesList.push(promiseQuestionnaire);
            // const promiseDashboard = this.copyDashboardConfig(resultPackage.dashboardConfig.id);
            // promisesList.push(promiseDashboard);
            const promisePQActions = this.copyPQActions(id);
            promisesList.push(promisePQActions);

            Promise.all(promisesList)
              .then((result: any) => {
                const promiseDashboard = this.copyDashboardConfig(resultPackage.dashboardConfig.id);
                promiseDashboard.then(() => {
                  this.sharedPackageService.onStandardSetCreated$.emit(true);
                  this.amplitudeService.addEvent('add standard set');
                }).catch(() => this.sharedPackageService.onStandardSetCreated$.emit(false));
              })
              .catch(() => {
                this.sharedPackageService.onStandardSetCreated$.emit(false);
              });
            }, error => {
              console.log('Error with package copying');
            });
      }
    }
  }

  copyPackageParameters(newPackageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const packageParameters: PackageParameter[] = this.sharedPackageService.packageParameters$.getValue();
      if (newPackageId && packageParameters) {
        const promisesList = [];
        for (const singleParameter of packageParameters) {
          const newPackageParameter: PackageParameter = {
            id: 0,
            visibleName: singleParameter.visibleName,
            formulaName: singleParameter.formulaName,
            groupName: singleParameter.groupName,
            packageId: newPackageId,
            commonValue: singleParameter.commonValue,
          };

          const parameterPromise = this.copySingleParameter(newPackageParameter);
          promisesList.push(parameterPromise);
        }

        Promise.all(promisesList)
          .then((resultParameters: PackageParameter[]) => {
            this.newParameters = resultParameters;
            resolve(resultParameters);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copySingleParameter(newPackageParameter: PackageParameter): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.addNewParameter(newPackageParameter)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameter: PackageParameter) => {
          resolve(resultParameter);
        }, error => {
          reject(error);
        });
    });
  }

  copyDashboardConfig(newConfigId: number): Promise<any> {
    // console.log('Copy dashboard config, new id ', newConfigId);
    return new Promise((resolve, reject) => {
      const packageDashboard = this.sharedPackageService.dashboardConfig$.getValue();
      if (packageDashboard) {
        const promiseConfig = this.loadDashboardConfig(packageDashboard.id);
        promiseConfig.then((resultDashboard: DashboardConfig) => {
          const promiseFilterParams = this.loadFilterParameters(resultDashboard);
          promiseFilterParams.then((resultParameters: PackageParameter[]) => {
            const promisesList = [];
            for (const singleWidget of resultDashboard.dashboardWidgets) {
              const newFilters = this.formDefaultFilters(singleWidget.widgetFilters, resultParameters);
              const newWidget: DashboardWidget = {
              id: 0,
              widgetName: singleWidget.widgetName,
              widgetTitle: singleWidget.widgetTitle,
              orderPosition: singleWidget.orderPosition,
              dashboardConfigId: newConfigId,
              widgetFilters: newFilters
              };

              const promiseWidget = this.copySingleWidget(newWidget);
              promisesList.push(promiseWidget);
            }

            Promise.all(promisesList).then((result: any) => {
              resolve(result);
            }).catch((error) => reject(error));
          }).catch(error => console.log(error));
        }).catch(error => reject(error));
      } else {
        reject();
      }
    });
  }

  formDefaultFilters(widgetFilters: any, filterParameters: PackageParameter[]): any {
    const newFilters = {};
    const activeParametersIds = widgetFilters.activeParametersIds;
    if (activeParametersIds && activeParametersIds.length > 0) {
      const newParamIds = [];
      for (const singleId of activeParametersIds) {
        const findFilterParam = filterParameters.find(filterParam => filterParam.id === singleId);
        if (findFilterParam) {
          const findNewParam = this.newParameters.find(newParam => newParam.formulaName === findFilterParam.formulaName);
          if (findNewParam) {
            newParamIds.push(findNewParam.id);
          }
        }
      }
      newFilters['activeParametersIds'] = newParamIds;
    }
    if (widgetFilters.sortParameterId) {
      const findFilterParam = filterParameters.find(filterParam => filterParam.id === widgetFilters.sortParameterId);
      if (findFilterParam) {
        const findNewParam = this.newParameters.find(newParam => newParam.formulaName === findFilterParam.formulaName);
        if (findNewParam) {
          newFilters['sortParameterId'] = findNewParam.id;
        }
      }
    }
    newFilters['hideEmptyCards'] = true;
    newFilters['showLatestResearch'] = true;
    newFilters['showParametersNames'] = true;
    newFilters['showParticipants'] = true;

    return newFilters;
  }

  loadDashboardConfig(configId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dashboardService.getDashboardConfig(configId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultConfig: DashboardConfig) => {
          resolve(resultConfig);
        }, error => reject(error));
    });
  }

  loadFilterParameters(config: DashboardConfig): Promise<any> {
    return new Promise((resolve, reject) => {
      const promiseList = [];
      for (const singleWidget of config.dashboardWidgets) {
        const activeParametersIds = singleWidget.widgetFilters.activeParametersIds;
        if (activeParametersIds && activeParametersIds.length > 0) {
          for (const singleParamId of activeParametersIds) {
            const singlePromise = this.loadSingleFilterParameter(singleParamId);
            promiseList.push(singlePromise);
          }
        }
      }
      Promise.all(promiseList).then(result => {
        resolve(result);
      }).catch(error => reject(error));
    });
  }

  loadSingleFilterParameter(parameterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.getSinglePackageParameter(parameterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultParameter: PackageParameter) => {
          resolve(resultParameter);
        }, error => reject(error));
    });
  }

  copySingleWidget(widgetInfo: DashboardWidget): Promise<any> {
    // console.log('Copy single widget ', widgetInfo);
    return new Promise((resolve, reject) => {
      this.dashboardService.addDashboardWidget(widgetInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((newWidget: DashboardWidget) => {
          // console.log('Resolve widget ', newWidget);
          resolve(newWidget);
        }, error => {
          reject(error);
        });
    });
  }

  copyPublicQuestionnaire(packageId: number, newQuestionnaireId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (packageId && newQuestionnaireId) {
        const questionnaireCards: QuestionnaireCard[] = this.sharedPackageService.questionnaireCards$.getValue();
        const promisesList = [];
        for (const card of questionnaireCards) {
          const cardPromise = this.copyQuestionnaireCard(card, newQuestionnaireId);
          promisesList.push(cardPromise);
        }

        Promise.all(promisesList)
          .then((result: any) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copyPQActions(packageId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (packageId) {
        let pqActions: PostQuestionnaireAction[] = [];
        pqActions = this.sharedPackageService.postQuestionnaireActions$.getValue();

        const promisesList = [];
        for (const action of pqActions) {
          const newAction: PostQuestionnaireAction = {
            id: 0,
            text: action.text,
            parameter: action.parameter,
            orderPosition: action.orderPosition,
            actionType: action.actionType,
          };

          const customCalculationId = action.customCalculationId;

          const singlePromise = this.copySinglePQAction(packageId, newAction, customCalculationId);
          promisesList.push(singlePromise);
        }

        Promise.all(promisesList)
          .then((result: any) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copySinglePQAction(packageId: number, newAction: PostQuestionnaireAction, customCalculationId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.addPostQuestionnaireAction(packageId, newAction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((addedPQA: PostQuestionnaireAction) => {
          if (customCalculationId) {
            this.packageService.getCustomCalculation(customCalculationId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((calculationObject: PythonCustomCalculation) => {
                const code = calculationObject.text_value;
                this.copyPythonCalculation(addedPQA.id, code);
                resolve(addedPQA);
              }, error => console.log(error));
          } else {
            resolve(addedPQA);
          }
        }, error => {
          console.log('PQA error ', error);
          reject(error);
        });
    });
  }

  copyPythonCalculation(pqActionId: number, code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.packageService.addCustomCalculation(pqActionId, code)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultPython: PythonCustomCalculation) => {
        resolve(resultPython);
      }, error => reject(error));
    });
  }

  copyQuestionnaireCard(card: QuestionnaireCard, newQuestionnaireId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const newCard: QuestionnaireCard = {
        id: 0,
        cardOptions: [],
        scoreDivisions: [],
        questionText: card.questionText,
        questionnaireId: newQuestionnaireId,
        cardType: card.cardType,
        orderPosition: card.orderPosition,
        isRequired: card.isRequired,
        divisionsColorScheme: card.divisionsColorScheme,
      };

      if (newCard) {
        this.questionnaireService.addQuestionCard(newCard)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((addedCard: QuestionnaireCard) => {
            if (addedCard) {
              const promisesList = [];
              const cardOptions: CardOption[] = card.cardOptions;
              for (const singleOption of cardOptions) {
                const optionPromise = this.copyCardOption(singleOption, addedCard.id);
                promisesList.push(optionPromise);
              }
              const scoreDivisions = card.scoreDivisions;
              for (const singleDivision of scoreDivisions) {
                const divisionPromise = this.copyScoreDivision(singleDivision, addedCard.id);
                promisesList.push(divisionPromise);
              }

              Promise.all(promisesList)
                .then((cardResult: any) => {
                  resolve(cardResult);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              reject();
            }
          },
            error => {
              console.log('Question card error ', error);
              reject(error);
            });
      } else {
        reject();
      }
    });
  }

  copyScoreDivision(scoreDivision: ScoreDivision, newCardId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const newDivision: ScoreDivision = {
        id: 0,
        label: scoreDivision.label,
        value: scoreDivision.value,
      };

      if (newDivision) {
        this.questionnaireService.addCardScoreDivision(newCardId, newDivision)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((createdDivision: any) => {
            // console.log('Division created ', createdDivision);
            resolve(createdDivision);
          },
            error => {
              console.log('Division error ', error);
              reject(error);
            });
      } else {
        reject();
      }
    });
  }

  copyCardOption(cardOption: CardOption, newCardId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const newOption: CardOption = {
        id: 0,
        orderPosition: cardOption.orderPosition,
        text: cardOption.text,
        cardActions: [],
      };

      if (newOption) {
        this.questionnaireService.addCardOption(newCardId, newOption)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((createdOption: CardOption) => {
            if (createdOption) {
              const id = createdOption.id;
              const allActionsPromise = this.copyOptionActions(id, cardOption);
              resolve(allActionsPromise);
            } else {
              reject();
            }
          },
            error => {
              console.log('Option error ', error);
              reject(error);
            });
      } else {
        reject();
      }
    });
  }

  copyOptionActions(newOptionId: number, cardOption: CardOption): Promise<any> {
    return new Promise((resolve, reject) => {
      if (cardOption) {
        const cardActions: CardAction[] = cardOption.cardActions;
        const promisesList = [];
        for (const singleAction of cardActions) {
          const actionPromise = this.copySingleAction(newOptionId, singleAction);
          promisesList.push(actionPromise);
        }

        Promise.all(promisesList)
          .then((resultActions: any) => {
            resolve(resultActions);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  copySingleAction(newOptionId: number, cardAction: CardAction): Promise<any> {
    return new Promise((resolve, reject) => {
      if (newOptionId && cardAction) {
        const newAction: CardAction = {
          id: 0,
          text: cardAction.text,
          parameter: cardAction.parameter,
          orderPosition: cardAction.orderPosition,
        };

        if (newAction) {
          this.questionnaireService.addCardAction(newOptionId, newAction)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((createdAction: CardAction) => {
              if (createdAction) {
                // console.log('Action created ', createdAction);
                resolve(createdAction);
              }
            },
              error => {
                console.log('Action error ', error);
                reject(error);
              });
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  }
}
