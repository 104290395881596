import { ColleagueParameter } from 'app/_models/colleague-parameter';
import { ApiColleagueParameter } from 'app/_api_models/api-colleague-parameter';
import { environment } from '../../environments/environment';

export function parseApiColleagueParameter(apiResponse: any): ColleagueParameter {
  // console.log('Api response ', apiResponse);
  let parameterObject;
  if (environment.production) {
    parameterObject = apiResponse;
  } else {
    parameterObject = ApiColleagueParameter.check(apiResponse);
  }

  const newParameter: ColleagueParameter = {
    id: parameterObject.id,
    value: parameterObject.value,
    textValue: parameterObject.text_value,
    experimentAnswerId: parameterObject.experiment_answer_id,
    packageParameterId: parameterObject.package_parameter_id,
    colleagueId: parameterObject.colleague_id,
    isNullValue: parameterObject.is_null_value,
  };

  return newParameter;
}

