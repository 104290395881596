<div *ngIf="isLoading" class="loading-container">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" class="edit-colleague">
  <form [formGroup]="personForm" autocomplete="off">
    <input type="hidden" formControlName="id">

    <div class="colleague-names-block">
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@placeholder-firstName">First Name</mat-placeholder>
        <input matInput [errorStateMatcher]="matcher" min="2" class="form-control" id="firstName" formControlName="firstName" required/>
      </mat-form-field>
      &nbsp;
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@placeholder-lastName">Last name</mat-placeholder>
        <input matInput [errorStateMatcher]="matcher" min="2" class="form-control" id="lastName" formControlName="lastName" required/>
      </mat-form-field>
    </div>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder">Email</mat-placeholder>
      <input matInput [errorStateMatcher]="matcher" class="form-control" id="email" formControlName="email" required/>
    </mat-form-field>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder">Slack User Id</mat-placeholder>
      <input matInput [errorStateMatcher]="matcher" class="form-control" id="slackUserId" formControlName="slackUserId"/>
    </mat-form-field>

    <mat-form-field class="card-item">
      <mat-placeholder i18n="@@placeholder-department" class="placeholder">Department</mat-placeholder>
      <mat-select panelClass="panel-blue" [(value)]="personForm.value.departmentId"
                  (selectionChange)="setOptionValue($event)">
        <mat-option i18n="@@placeholder-none-option" [value]="0">None</mat-option>
        <mat-option *ngFor="let department of departments" [value]="department.id">{{department.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <button
        [disabled]="!personForm.value.firstName || !personForm.value.lastName || !personForm.value.email"
        mat-raised-button
        type="button"
        class="create-button full-width-card-button"
        color="accent"
        (click)="onValidatePerson()"
        i18n="@@saveButton">
      Save
    </button>

  </form>
</div>
