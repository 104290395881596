<div id="top"></div>

<div *ngIf="isLoading" class="loading-container">
  <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading && !isEnabledTablesExist">
  <div class="no-data">
    <div class="no-data__message" i18n="@@didntParticipate">
      This employee did not participate in questionnaires
    </div>
  </div>
</div>

<div *ngIf="!isLoading && isEnabledTablesExist" class="colleague-data">
  <div class="colleague-tables">
    <ng-container *ngFor="let table of colleagueTables">
      <div *ngIf="(table.isEnabled && table.isVisible && isViewMode) || isEditMode" class="package-info mat-elevation-z1">
        <div class="package-info__header">
          <a class="package-info__title" [routerLink]="['/questionnaires', table.packageId]">{{table.tableName}}</a>
          <div *ngIf="table.lastExperimentTime" class="package-info__date">
            {{table.lastExperimentTime}}
          </div>
        </div> <!-- /.package-info__header -->

        <ng-container *ngIf="isEditMode" >
          <ng-container *ngIf="isPackageHasParameters(table.packageId); else noData">
            <div [ngClass]="{'table-visible': table.isVisible}" class="parameters-list table-container">
              <div class="parameters-type">
                <div class="parameters-list__column" i18n="onColleagueCard">
                  В карточке сотрудника
                </div>
                <div class="parameters-list__column" i18n="onColleaguePage">
                  На странице сотрудника
                </div>
                <div class="parameters-list__column parameters-list__column--name"></div>
              </div>

              <ng-container *ngFor="let parameter of activeParameters">
                <ng-container *ngIf="parameter.packageId === table.packageId">
                  <div class="parameters-list__item">
                    <div class="parameters-list__column">
                      <mat-checkbox [(ngModel)]="parameter.onColleagueCard" (change)="onSelectParameter(parameter)" color="3f51b5"></mat-checkbox>
                    </div>
                    <div class="parameters-list__column">
                      <mat-checkbox [(ngModel)]="parameter.onColleaguePage" (change)="onSelectParameter(parameter)" color="3f51b5"></mat-checkbox>
                    </div>
                    <div class="parameters-list__column parameters-list__column--name">
                      {{parameter.visibleName}}
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <div class="select-buttons">
                <button (click)="onSelectAllParameters(table.packageId, 'card')" class="select-buttons__item" i18n="@@selectAll">
                  Select all
                </button>
                <button (click)="onSelectAllParameters(table.packageId, 'page')" class="select-buttons__item" i18n="@@selectAll">
                  Select all
                </button>
              </div>
            </div>
          </ng-container>

          <ng-template #noData>
            <div class="no-package-data">
              <div class="no-package-data__message" i18n="@@questionnaireNoData">
                This questionnaire has no available data.
              </div>
            </div>
          </ng-template>

          <div class="package-info-footer">
            <button (click)="onTableUp(table)" class="package-info-footer__item" i18n="@@upButton">Up</button>
            <button (click)="onTableDown(table)"class="package-info-footer__item" i18n="@@downButton">Down</button>
            <ng-container *ngIf="table.isVisible && isEditMode">
              <button (click)="changeTableVisibility(table)" class="table-visibility__button package-info-footer__item" i18n="@@hideTable">Hide table</button>
            </ng-container>
            <ng-container *ngIf="!table.isVisible && isEditMode">
              <button (click)="changeTableVisibility(table)" class="table-visibility__button package-info-footer__item" i18n="@@showTable">Show table</button>
            </ng-container>
          </div>
        </ng-container> <!-- editMode -->

        <ng-container *ngIf="isViewMode">
          <div *ngFor="let tableAnswer of table.tableAnswers" [ngClass]="{'table-visible': table.isVisible}" class="table-container">
            <table mat-table [dataSource]="tableAnswer.dataSource" class="table">
              <ng-container matColumnDef="parameterName">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element" class="table__parameter-name"> {{element.parameterName}} </td>
              </ng-container>

              <ng-container matColumnDef="parameterValue">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td mat-cell *matCellDef="let element" class="table__parameter-value" [innerHtml]="element.parameterValue"></td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;">
              </tr>
            </table> <!-- /.table -->
          </div> <!-- /.table-container -->
        </ng-container> <!-- viewMode -->
      </div> <!-- /.package-info -->
    </ng-container>
  </div>

  <div class="visibility-control">
    <button *ngIf="isViewMode && !isPublicProfile" (click)="onEditClick()" class="visibility-control__button button button--edit" i18n="@@editDataVisibility">
      Edit data visibility
    </button>
    <button *ngIf="isEditMode" (click)="onBackClick()" class="visibility-control__button button button--back" mat-raised-button i18n="@@backButton">
      Back
    </button>
    <button *ngIf="isEditMode" (click)="onSaveClick()" class="visibility-control__button button button--save" mat-raised-button i18n="@@saveButton">
      Save
    </button>
  </div>
</div>
