import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as Highcharts from 'highcharts';
import { SharedDashboardHelper } from '../_helpers/shared-dashboard-helper';
import { Colleague } from 'app/_models/colleague';
import { ExtendedColleague, ExtendedParameter, WidgetFilters, PlotLine } from '../_helpers/local-models';
import { PackageParameter } from 'app/_models/package-parameter';
import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { SharedDashboardWidget } from 'app/_models/shared-dashboard-widget';
import { Package } from 'app/_models/package';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { PackageParticipant } from 'app/_models/package-participant';
import { Department } from 'app/_models/department';

@Component({
  selector: 'app-shared-bar-widget',
  templateUrl: './shared-bar-widget.component.html',
  styleUrls: ['./shared-bar-widget.component.scss']
})
export class SharedBarWidgetComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() isPublicLink: boolean;
  @Input() inputWidgetId: number;
  @Input() isLoaded: boolean;
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  updateFlag: boolean = false;
  chartObject: any;

  showChartMode: boolean = true;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;
  packagesEditMode: boolean = false;
  linesEditMode: boolean = false;
  showParticipants: boolean = false;
  isSwitchEnabled: boolean = true;

  activePackageOption: any;
  sharedPackagesList: Package[] = [];
  inputWidgetFilters: WidgetFilters;
  widgetFilters: WidgetFilters = {};
  colleaguesList: Colleague[] = [];
  activeColleagues: ExtendedColleague[] = [];
  localParameters: ExtendedParameter[] = [];
  // activeParameter: ExtendedParameter;
  experiments: Experiment[] = [];
  activeExperiment: Experiment;
  activeExperimentOption: any;
  plotLines: PlotLine[] = [];
  commonParameters: ExperimentParameter[] = [];
  activeCommonParameter: ExperimentParameter;
  participants: PackageParticipant[] = [];
  departmentsList: Department[] = [];
  activeDepartmentId: number = 0;
  selectedTabIndex: number = 0;
  tabs: any = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      marginTop: 40,
      marginBottom: 140
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: ''
      },
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}'
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>:<b>{point.y:.2f}</b><br/>'
    },
    series: null,
  };

  constructor(
    private sharedDashboardHelper: SharedDashboardHelper,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadSharedDashboardData();
  }

  loadSharedDashboardData(): void {
    this.activePackageOption = null;

    this.sharedDashboardHelper.isLoaded
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        if (result) {
          const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
          this.sharedPackagesList = sharedPackagesList;
          let promiseWidget = this.sharedDashboardHelper.getSharedDashboardWidget(this.inputWidgetId);
          // promiseParticipants = this.localDashboardHelper.getCachedParticipants();
          promiseWidget.then((widget: any) => {
            this.isLoaded = true;
            this.inputWidgetFilters = widget.widgetFilters;
            this.formInitialParameters();
            this.formInitialColleagues();
            this.formInitialExperiments();
            this.checkInputWidgets();
            this.updatePackageOption();
          }).catch((error) => {
            console.log('Widget error ', error);
          });
        }
      }, error => {
        console.log(error);
      });
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setActiveParticipants(): void {
    const newActiveColleagues = [];
    const sharedParticipants = this.sharedDashboardHelper.getSharedPackageParticipants();
    for (const singleColleague of this.activeColleagues) {
      const findParticipant = sharedParticipants.find(participant => participant.colleagueId === singleColleague.id);
      if (findParticipant) {
        singleColleague.isActive = findParticipant.isSelected;
        newActiveColleagues.push(singleColleague);
      } else {
        singleColleague.isActive = false;
        newActiveColleagues.push(singleColleague);
      }
    }

    this.activeColleagues = newActiveColleagues;
  }

  formInitialExperiments(): void {
    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    if (this.activePackageOption && this.activePackageOption.id) {
      this.experiments = sharedExperiments.filter(exp => exp.packageId === this.activePackageOption.id);
    } else {
      this.experiments = sharedExperiments;
    }
  }

  formInitialParameters(): void {
    this.formParameters(0);
  }
  
  formParameters(packageId: number): void {
    const sharedParameters = this.sharedDashboardHelper.getSharedPackageParameters();
    let filteredParameters = sharedParameters.filter(param => param.groupName === 'individual');
    filteredParameters = filteredParameters.filter(param => param.commonValue.type === 'number');
    this.localParameters = [];
    for (const singleParameter of filteredParameters) {
      const newParam: ExtendedParameter = {
        ...singleParameter,
        isActive: false
      }
      this.localParameters.push(newParam);
    }
    if (packageId && packageId > 0) {
      this.localParameters = this.localParameters.filter(param => param.packageId === packageId);
    }
    if (this.localParameters.length > 0) {
      this.localParameters[0].isActive = true;
    }
    // if (this.localParameters.length > 0) {
    //   this.activeParameter = this.localParameters[0];
    // }
  }

  formInitialColleagues(): void {
    const sharedColleagues = this.sharedDashboardHelper.getColleagues();
    this.activeColleagues = [];
    for (const singleColleague of sharedColleagues) {
      const newColleague: ExtendedColleague = {
        ...singleColleague,
        isActive: true
      }
      this.activeColleagues.push(newColleague);
    }
  }

  updatePackageOption(): void {
    const departments = this.sharedDashboardHelper.getDepartments();
    this.formDepartmentTabs(departments);

    const sharedExperiments = this.sharedDashboardHelper.getSharedExperiments();
    const sharedPackagesList = this.sharedDashboardHelper.getSharedPackages();
    if (sharedPackagesList && sharedPackagesList.length > 0) {
      if (!this.activePackageOption) {
        this.activePackageOption = sharedPackagesList[0];
      }

      const packageId = this.activePackageOption.id;
      const packageExperiments = sharedExperiments.filter(exp => exp.packageId === packageId);
      this.experiments = packageExperiments;
      const activeParameters = this.localParameters.filter(param => param.isActive === true);
      if (activeParameters.length === 0) {
        this.formParameters(packageId);
      }

      if (!this.activeExperimentOption) {
        this.activeExperimentOption = 'last_research';
      }

      if (this.activeExperimentOption === 'last_research') {
        this.activeExperiment = this.getLatestPackageExperiment(packageExperiments);
      }
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }

    if (this.showParticipants) {
      this.setActiveParticipants();
    }

    const nonEmptyTab = this.findNonEmptyTab();
    if (nonEmptyTab) {
      this.changeTab(nonEmptyTab);
    }
    this.formNewChart();
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.widgetFilters['showLatestResearch'] = true;
    this.widgetFilters['showParticipants'] = true;
    this.showParticipants = true;
    this.activeExperimentOption = 'last_research';
    // if (this.localParameters.length > 0) {
    //   this.activeParameter = this.localParameters[0];
    // }
  }

  restoreInputWidgetsFilters(): void {
    if (this.inputWidgetFilters) {
      const showLatestResearch = this.inputWidgetFilters.showLatestResearch;
      if (showLatestResearch) {
        const activeResearch = this.getLatestExperiment();
        if (activeResearch) {
          this.activeExperiment = activeResearch;
          this.activeExperimentOption = 'last_research';
        }
      } else {
        const activeResearchId = this.inputWidgetFilters.activeResearchId;
        if (activeResearchId) {
          const activeResearch = this.experiments.find(experiment => experiment.id === activeResearchId);
          if (activeResearch) {
            this.activeExperiment = activeResearch;
            this.activeExperimentOption = activeResearch;
          }
        }
      }

      const colleaguesIds = this.inputWidgetFilters.activeColleaguesIds;
      if (colleaguesIds) {
        for (const singleColleague of this.activeColleagues) {
          const findColleague = colleaguesIds.find(id => id === singleColleague.id);
          if (findColleague) {
            singleColleague.isActive = true;
          } else {
            singleColleague.isActive = false;
          }
        }
      }

      if (this.inputWidgetFilters.showParticipants) {
        this.showParticipants = true;
        this.formInitialColleagues();
      }

      // const paramsIds = this.inputWidgetFilters.activeParametersIds;
      // if (paramsIds && paramsIds.length > 0) {
      //   const savedParamId = paramsIds[0];
      //   const findActiveParam = this.localParameters.find(param => param.id === savedParamId);
      //   if (findActiveParam) {
      //     this.activeParameter = findActiveParam;
      //   }
      // }

      const paramsIds = this.inputWidgetFilters.activeParametersIds;
      if (paramsIds) {
        for (const singleParam of this.localParameters) {
          const findParam = paramsIds.find(id => id === singleParam.id);
          if (findParam) {
            singleParam.isActive = true;
          } else {
            singleParam.isActive = false;
          }
        }
      }

      const activePackageId = this.inputWidgetFilters.activePackageId;
      if (activePackageId && activePackageId > 0) {
        const findPackage = this.sharedPackagesList.find(pack => pack.id === activePackageId);
        if (findPackage) {
          this.activePackageOption = findPackage;
        }
      }

      const plotLines = this.inputWidgetFilters.plotLines;
      if (plotLines) {
        for (const singleLine of plotLines) {
          if (singleLine.packageParameterId > 0) {
            const newValue = this.updateCommonLineValue(singleLine);
            singleLine.value = newValue;
          }
          this.plotLines.push(singleLine);
        }
      }

    }
  }

  widgetFiltersUpdate(): void {
    const colleaguesFilter = this.activeColleagues.filter(colleague => colleague.isActive === true);
    const activeColleaguesIds = [];
    for (const singleColleague of colleaguesFilter) {
      activeColleaguesIds.push(singleColleague.id);
    }
    this.widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

    if (this.showParticipants) {
      this.widgetFilters['showParticipants'] = true;
    } else {
      this.widgetFilters['showParticipants'] = false;
    }

    // const activeParamsIds = [];
    // if (this.activeParameter) {
    //   activeParamsIds.push(this.activeParameter.id);
    // }

    const parametersFilter = this.localParameters.filter(param => param.isActive === true);
    const activeParamsIds = [];
    for (const singleParam of parametersFilter) {
      activeParamsIds.push(singleParam.id);
    }

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    const plotLines = this.plotLines;
    if (plotLines) {
      this.widgetFilters['plotLines'] = plotLines;
    }

    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
        this.widgetFilters['showLatestResearch'] = true;
      } else {
        this.activeExperiment = experimentOption;
        this.widgetFilters['showLatestResearch'] = false;
      }
    }

    if (this.activePackageOption && this.activePackageOption.id > 0) {
      this.widgetFilters['activePackageId'] = this.activePackageOption.id;
    } else {
      this.widgetFilters['activePackageId'] = 0;
    }

    if (this.activeExperiment) {
      this.widgetFilters['activeResearchId'] = this.activeExperiment.id;
    }

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  formDepartmentTabs(departments: Department[]): void {
    this.tabs = [];
    let i = 0;
    for (const singleDepartment of departments) {
      const tabItem = { id: i, name: singleDepartment.name, departmentId: singleDepartment.id };
      this.tabs.push(tabItem);
      i = i + 1;
    }
    if (this.tabs.length > 0) {
      this.activeDepartmentId = this.tabs[0].departmentId;
      this.selectedTabIndex = this.tabs[0].id;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }

  changeTab(tab: any): void {
    if (this.isSwitchEnabled) {
      this.isSwitchEnabled = false;
      this.activeDepartmentId = tab.departmentId;
      this.selectedTabIndex = tab.id;
      this.formNewChart();
    }
  }

  chartCallback = (chart: any) => {
    this.chartObject = chart;
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  onParametersStateChange(): void {
    // this.activeParameter = newParam;
    this.widgetFiltersUpdate();
  }

  onParticipantsStateChange(): void {
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    this.widgetFiltersUpdate();
    this.formNewChart();
  }

  onExperimentChange(event: any): void {
    // console.log('Active experiment ', this.activeExperiment);
    this.widgetFiltersUpdate();
    if (this.plotLines) {
      for (const singleLine of this.plotLines) {
        if (singleLine.packageParameterId > 0) {
          const newValue = this.updateCommonLineValue(singleLine);
          singleLine.value = newValue;
        }
      }
    }
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    this.widgetFiltersUpdate();
  }

  onPackagesFilterChange(event: any): void {
    this.localParameters = [];
    // this.activeParameter = null;
    this.formInitialColleagues();
    // this.formInitialParameters();
    this.updatePackageOption();
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.parametersEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.researchesEditMode = true;
  }

  onPackagesEditMode(): void {
    this.setAllModesFalse();
    this.packagesEditMode = true;
  }

  onLinesEditMode(): void {
    this.setAllModesFalse();
    this.linesEditMode = true;
  }

  onShowChartMode(): void {
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
    this.setAllModesFalse();
    this.showChartMode = true;
    this.formNewChart();
  }

  setAllModesFalse(): void {
    this.showChartMode = false;
    this.colleaguesEditMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
    this.linesEditMode = false;
    this.packagesEditMode = false;
  }

  onChangeActiveCommonParameter(): void {
    // console.log('Change active common parameter ', this.activeCommonParameter);
  }

  onBlurMethod(): void {
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
  }

  updateCommonLineValue(plotLine: PlotLine): number {
    if (this.activeExperiment) {
      for (const singleParam of this.activeExperiment.experimentParameters) {
        if (singleParam.packageParameterId === plotLine.packageParameterId) {
          return Number(singleParam.commonValue.value) || 0;
        }
      }
      return plotLine.value;
    }
  }

  onAddNewLine(): void {
    const nextPosition = this.plotLines.length + 1;
    const newLine: PlotLine = {
      color: 'red',
      label: 'label',
      value: 1,
      width: 1,
      zIndex: 1,
      position: nextPosition,
      packageParameterId: 0,
    };
    this.plotLines.push(newLine);
  }

  onAddCommonLineAction(): void {
    const commonParameters: ExperimentParameter[] = []
    if (this.activeExperiment) {
      for (const singleParam of this.activeExperiment.experimentParameters) {
        commonParameters.push(singleParam);
      }
    }

    if (commonParameters.length > 0) {
      this.activeCommonParameter = commonParameters[0];
      this.commonParameters = commonParameters;
    }
  }

  onAddNewCommonLine(): void {
    if (this.activeCommonParameter) {
      const activeParam = this.activeCommonParameter;
      const nextPosition = this.plotLines.length + 1;
      const newLine: PlotLine = {
        color: 'red',
        label: activeParam.visibleName,
        value: Number(activeParam.commonValue.value) || 0,
        width: 1,
        zIndex: 1,
        position: nextPosition,
        packageParameterId: activeParam.packageParameterId,
      };
      this.plotLines.push(newLine);
      this.activeCommonParameter = null;
    }
  }

  onResetCommonLine(): void {
    this.activeCommonParameter = null;
  }

  onRemoveLine(line: PlotLine): void {
    const newPlotLines: PlotLine[] = [];
    let nextPosition = 1;
    for (const singleLine of this.plotLines) {
      if (singleLine.position !== line.position) {
        singleLine.position = nextPosition;
        newPlotLines.push(singleLine);
        nextPosition = nextPosition + 1;
      }
    }
    this.plotLines = newPlotLines;
    if (this.linesEditMode) {
      this.widgetFiltersUpdate();
    }
  }

  formNewChart(): void {
    if (this.activeExperiment) {
      const isAnonymous = this.activeExperiment.anonymous;
      if (isAnonymous) {
        this.formNewChartAnonymous(this.activeExperiment);
      } else {
        this.formNewChartRegular(this.activeExperiment);
      }
    } else {
      this.formTestChart();
    }
  }

  findMaxValue(): number {
    let maxValue = 10;
    for (const singleLine of this.plotLines) {
      if (Number(singleLine.value) > maxValue) {
        maxValue = Number(singleLine.value);
      }
    }
    return maxValue + 2;
  }

  formTestChart(): void {
    const newSeries = [];
    const itemsData = [];
    const maxValue = this.findMaxValue();
    for (let i = 1; i < 10; i++) {
      itemsData.push({
        name: 'Colleague #' + i,
        y: this.precise(Math.random() * maxValue),
      });
    }

    const colleaguesSeries = {
      name: '',
      showInLegend: false,
      data: itemsData,
    };
    newSeries.push(colleaguesSeries);

    let maximumDataValue = 0;
    for (const singleData of itemsData) {
      if (singleData.y && (singleData.y > maximumDataValue)) {
        maximumDataValue = singleData.y;
      }
    }

    if (this.plotLines.length > 0) {
      const infoLines = this.formInfoLines();
      for (const singleLine of infoLines) {
        newSeries.push(singleLine);
      }
      this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
    } else {
      newSeries.push({
        type: 'area',
        name: 'Test level',
        color: 'red'
      });
      this.chartOptions.yAxis = this.formTestYAxis();
    }

    this.chartOptions.series = newSeries;
    this.chartOptions.title = {
      text: 'Test data',
      verticalAlign: 'middle',
      style: {
        fontSize: '40',
        color: 'gray'
      }
    };
    this.chartOptions.chart = {
      marginTop: 20,
      marginBottom: 80
    },
      setTimeout(() => {
        this.updateFlag = true;
        this.isSwitchEnabled = true;
      }, 30);
  }

  formTestYAxis(): any {
    const plotLines = [
      {
        color: 'red',
        value: 5,
        width: 2,
        zIndex: 10,
      }
    ];
    return {
      title: {
        text: ''
      },
      plotLines: plotLines
    };
  }

  getNextColor(count: number): string {
    const defaultColor = "rgb(124, 181, 236)";
    const colorsArray = ["rgb(124, 181, 236)", "rgb(144, 237, 125)", "rgb(247, 163, 92)", "rgb(100, 10, 100)"];
    if (count && colorsArray[count]) {
      return colorsArray[count];
    } else {
      return defaultColor;
    }
  }

  formNewChartAnonymous(activeExperiment: Experiment): void {
    const newSeries = [];
    let maximumDataValue = 0;
    let colorsCounter = 0;
    const activeParameters = this.localParameters.filter(param => param.isActive === true);
    for (const singleParameter of activeParameters) {
      const itemsData = [];
      let colleagueCounter = 1;
      for (const singleAnswer of activeExperiment.experimentAnswers) {
        const colleagueName = 'Colleague #' + colleagueCounter;
        const item = this.formAnonymousColleagueSeries(colleagueName, singleAnswer, singleParameter);
        itemsData.push(item);
        colleagueCounter = colleagueCounter + 1;
      }

      const colleaguesSeries = {
        name: singleParameter.visibleName,
        showInLegend: true,
        data: itemsData,
        stack: singleParameter.visibleName,
        color: this.getNextColor(colorsCounter),
      };
      newSeries.push(colleaguesSeries);

      for (const singleData of itemsData) {
        if (singleData.y && (singleData.y > maximumDataValue)) {
          maximumDataValue = singleData.y;
        }
      }

      colorsCounter = colorsCounter + 1;

    }

    const infoLines = this.formInfoLines();
    for (const singleLine of infoLines) {
      newSeries.push(singleLine);
    }

    this.chartOptions.series = newSeries;
    this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
    this.chartOptions.title = {
      text: '',
      margin: 10,
      // verticalAlign: 'bottom',
    };
    setTimeout(() => {
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  formAnonymousColleagueSeries(colleagueName: string, singleAnswer: ExperimentAnswer, activeParameter: ExtendedParameter): any {
    let resultValue = 0;
    if (activeParameter) {
      const colleagueParameters = singleAnswer.colleagueParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  findNonEmptyTab(): any {
    if (this.activeExperiment && this.tabs && this.tabs.length > 0) {
      for (const singleTab of this.tabs) {
        let filteredColleagues = [];
        if (this.showParticipants) {
          filteredColleagues = this.activeColleagues;
        } else {
          filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
        }

        if (singleTab.departmentId > 0) {
          filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === singleTab.departmentId);
        }

        if (filteredColleagues) {
          const activeParameters = this.localParameters.filter(param => param.isActive === true);
          if (activeParameters.length === 0) {
            return null;
          }

          for (const singleColleague of filteredColleagues) {
            const answers = this.activeExperiment.experimentAnswers;
            // const resultAnswer = answers.find(answer => answer.colleagueId === singleColleague.id);
            const resultAnswers = answers.filter(answer => answer.colleagueId === singleColleague.id);
            for (const resultAnswer of resultAnswers) {
              const nonEmpty = this.findNonEmptyItem(resultAnswer);
              if (this.showParticipants) {
                if (resultAnswer && resultAnswer.completed && nonEmpty) {
                  return singleTab;
                }
              } else {
                if (resultAnswer && nonEmpty) {
                  return singleTab;
                }
              }
            }

            const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
            if (relativeAnswers) {
              const resultAnswer = relativeAnswers.find(answer => answer.colleagueId === singleColleague.id);
              const nonEmpty = this.findNonEmptyItem(resultAnswer);
              if (resultAnswer && nonEmpty) {
                return singleTab;
              }
            }
          }

        }

      }
    }
  }

  findNonEmptyItem(resultAnswer: any): boolean {
    if (resultAnswer) {
      const colleagueParams = resultAnswer["colleagueParameters"];
      if (colleagueParams) {
        for (const singleParam of colleagueParams) {
          if (singleParam.value > 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  formNewChartRegular(activeExperiment: Experiment): void {
    let filteredColleagues = [];
    if (this.showParticipants) {
      filteredColleagues = this.activeColleagues;
    } else {
      filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
    }

    if (this.activeDepartmentId > 0) {
      filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
    }
        
    if (filteredColleagues) {
      const newSeries = [];

      let maximumDataValue = 0;
      const activeParameters = this.localParameters.filter(param => param.isActive === true);
      let colorsCounter = 0;
      for (const singleParameter of activeParameters) {
        const itemsData = [];
        for (const singleColleague of filteredColleagues) {
          const answers = activeExperiment.experimentAnswers;
          // const resultAnswer = answers.find(answer => answer.colleagueId === singleColleague.id);
          const resultAnswers = answers.filter(answer => answer.colleagueId === singleColleague.id);
          resultAnswers.sort((a, b) => a.id - b.id);
          let colleagueCounter = 0;
          if (resultAnswers.length > 1) {
            colleagueCounter = 1;
          }

          const colleagueItemsData = [];
          for (const resultAnswer of resultAnswers) {
            if (this.showParticipants) {
              if (resultAnswer && resultAnswer.completed) {
                const item = this.formColleagueSeries(singleColleague, resultAnswer, singleParameter, colleagueCounter);
                // itemsData.push(item);
                colleagueItemsData.push(item);
                colleagueCounter = colleagueCounter + 1;
              }
            } else {
              if (resultAnswer) {
                const item = this.formColleagueSeries(singleColleague, resultAnswer, singleParameter, colleagueCounter);
                // itemsData.push(item);
                colleagueItemsData.push(item);
                colleagueCounter = colleagueCounter + 1;
              }
            }
          }

          // console.log('Items data: colleague ', colleagueItemsData);
          const filteredItems = this.filterZeroValues(colleagueItemsData);
          for (const singleItem of filteredItems) {
            itemsData.push(singleItem);
          }

          const relativeAnswers = activeExperiment.relativeExperimentAnswers;
          if (relativeAnswers) {
            const resultAnswer = relativeAnswers.find(answer => answer.colleagueId === singleColleague.id);
            if (resultAnswer) {
              const item = this.formColleagueSeriesRelative(singleColleague, resultAnswer, singleParameter);
              itemsData.push(item);
            }
          }
        }
        const colleaguesSeries = {
          name: singleParameter.visibleName,
          showInLegend: true,
          data: itemsData,
          stack: singleParameter.visibleName,
          color: this.getNextColor(colorsCounter),
        };
        newSeries.push(colleaguesSeries);
        colorsCounter = colorsCounter + 1;

        for (const singleData of itemsData) {
          if (singleData.y && (singleData.y > maximumDataValue)) {
            maximumDataValue = singleData.y;
          }
        }
      }

      const infoLines = this.formInfoLines();
      for (const singleLine of infoLines) {
        newSeries.push(singleLine);
      }

      this.chartOptions.yAxis = this.formInfoYAxis(maximumDataValue);
      this.chartOptions.title = {
        // text: this.activeParameter.visibleName,
        text: '',
        margin: 10,
        // verticalAlign: 'bottom',
      };

      this.chartOptions.series = newSeries;
      setTimeout(() => {
        this.updateFlag = true;
        this.isSwitchEnabled = true;
      }, 30);
    }
  }

  filterZeroValues(colleagueItemsData: any): any {
    if (colleagueItemsData && colleagueItemsData.length > 0) {
      // console.log('Filter zero values ', colleagueItemsData);
      const filteredList = [];
      for (const singleItem of colleagueItemsData) {
        if (singleItem.y > 0) {
          filteredList.push(singleItem);
        }
      }

      if (filteredList.length === 0) {
        filteredList.push(colleagueItemsData[0]);
      }

      if (filteredList.length === 1) {
        const colleagueName = filteredList[0].name;
        filteredList[0].name = colleagueName.split("#")[0].trim();
      }

      return filteredList;
    } else {
      return colleagueItemsData;
    }
  }

  formColleagueSeries(colleague: Colleague, singleAnswer: ExperimentAnswer, 
    activeParameter: ExtendedParameter, colleagueCounter: number): any {
    let colleagueName = colleague.firstName + ' ' + colleague.lastName;
    if (colleagueCounter && colleagueCounter > 0) {
      colleagueName = colleagueName + ' #' + colleagueCounter;
    }
    let resultValue = 0;
    if (activeParameter && singleAnswer) {
      const colleagueParameters = singleAnswer.colleagueParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  formColleagueSeriesRelative(colleague: Colleague, singleAnswer: RelativeExperimentAnswer, activeParameter: ExtendedParameter): any {
    const colleagueName = colleague.firstName + ' ' + colleague.lastName;
    let resultValue = 0;
    if (activeParameter && singleAnswer) {
      const colleagueParameters = singleAnswer.relativeExperimentParameters;
      const findParameter = colleagueParameters.find(param => param.packageParameterId === activeParameter.id);
      if (findParameter) {
        resultValue = findParameter.value;
      }
    }
    const item = {
      name: colleagueName,
      y: resultValue,
    };
    return item;
  }

  formInfoLines(): any {
    const infoLines = [];
    for (const singleLine of this.plotLines) {
      const infoLine = {
        type: 'area',
        name: singleLine.label,
        color: singleLine.color
      };
      infoLines.push(infoLine);
    }
    return infoLines;
  }

  formInfoYAxis(maximumDataValue: number): any {
    const plotLines = [];
    let maxValue = maximumDataValue;
    for (const singleLine of this.plotLines) {
      const info = {
        color: singleLine.color,
        value: singleLine.value,
        width: singleLine.width,
        zIndex: singleLine.zIndex,
      };
      if (Number(singleLine.value) > maxValue) {
        maxValue = Number(singleLine.value);
      }
      plotLines.push(info);
    }
    return {
      title: {
        text: ''
      },
      max: maxValue,
      plotLines: plotLines
    };
  }

  precise(inputValue: number): number {
    if (inputValue) {
      const stringValue = inputValue.toPrecision(3);
      return parseFloat(stringValue);
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  getLatestPackageExperiment(packageExperiments: Experiment[]): Experiment {
    if (packageExperiments) {
      const sortedExperimens = packageExperiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

}
