<h2 matDialogTitle class="title-class">{{dialogTitle}}</h2>
<mat-dialog-content>
  <p>{{dialogSubtitle}}</p>
  <mat-form-field class="card-full-width">
    <input matInput i18n="@@placeholder-settings-rename" placeholder="Enter new name" [(ngModel)]="packageName" />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button (click)="dialogRef.close(packageName)" color="accent" i18n="@@submitButton">Submit</button>
  <button mat-raised-button (click)="dialogRef.close()" color="primary" i18n="@@cancelButton">Cancel</button>
</mat-dialog-actions>