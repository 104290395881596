import {
  Component, OnInit, OnDestroy, AfterViewInit,
  ViewChildren, QueryList, ElementRef, Renderer2, TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { single, takeUntil } from 'rxjs/operators';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { CardAction } from 'app/_models/card-action';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';

import { UpdateLocalCardHelper } from 'app/_helpers/update-local-card-helper';
import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { PackageParameter } from 'app/_models/package-parameter';
import { AuthenticationService } from 'app/_services/authentication.service';
import { UserService } from 'app/_services/user.service';
import { User } from 'app/_models/user';
import { ScoreDivision } from 'app/_models/score-division';
import { SnackBarComponent } from 'app/snack-bar';
import { CopyLocalCardHelper } from 'app/_helpers/copy-local-card';

@Component({
  selector: 'app-package-questionnaire',
  templateUrl: './package-questionnaire.component.html',
  styleUrls: [
    '../package-details.component.scss',
    './package-questionnaire.component.scss'
  ],
  providers: [SnackBarComponent]
})
export class PackageQuestionnaireComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('cardReference') cardReference: QueryList<ElementRef>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  questionnaireId: number;
  currentPackageId: number;
  parametersList: PackageParameter[] = [];
  individualParameters: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];
  cards: QuestionnaireCard[] = [];
  postQuestionnaireActions: PostQuestionnaireAction[] = [];
  pqaBootstrapped: boolean = false;

  editedQuestion: QuestionnaireCard;
  editedPQAction: PostQuestionnaireAction;

  errorList: string[] = [];
  viewMode: boolean = false;
  editQuestionMode: boolean = false;
  editPQActionMode: boolean = false;
  activeCardId: number;
  isAdmin: boolean;

  constructor(
    private router: Router,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
    private updateCardHelper: UpdateLocalCardHelper,
    private authService: AuthenticationService,
    private userService: UserService,
    private snackBar: SnackBarComponent,
    private copyLocalCardHelper: CopyLocalCardHelper,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.sharedService.demoMode$.emit(this.viewMode);
    this.getUserInfo();

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.currentPackageId = packageId;
        // this.getPackageInfo();
      });

    this.sharedPackageService.questionnaireCards$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cardsList: QuestionnaireCard[]) => {
        if (cardsList) {
          this.cards = cardsList;
          for (const card of this.cards) {
            this.sortCardOptions(card);
          }
        }
      });

    this.sharedPackageService.packageParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params: PackageParameter[]) => {
        this.parametersList = params;
        this.separateParameters();
      });

    this.sharedPackageService.postQuestionnaireActions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pqActions: PostQuestionnaireAction[]) => {
        this.formPostQuestionnaireActions(pqActions);
      });

    // this.getPackageInfo();
    this.sharedService.currentTabId.next(1);

    this.sharedService.editQuestionMode$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        this.editQuestionMode = result;
      });

    this.sharedService.editPQActionMode$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: boolean) => {
        this.editPQActionMode = result;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    if (this.cardReference) {
      this.cardReference.changes.subscribe(result => {
        const activeElement = result.toArray()
          .find(r => r.nativeElement.hasAttribute('isActive'));
        // console.log('Active element ', activeElement);
        if (activeElement && activeElement.nativeElement) {
          // this.renderer.selectRootElement(activeElement).scrollIntoView();
          activeElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        }, error => {
          this.sharedService.checkSignatureExpired(error);
        });
    }
  }

  separateParameters(): void {
    this.individualParameters = [];
    this.commonParameters = [];
    this.relativeParameters = [];
    for (const singleParameter of this.parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  sortCardOptions(questionnaireCard: QuestionnaireCard): void {
    if (questionnaireCard) {
      questionnaireCard.cardOptions.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });

      for (const singleOption of questionnaireCard.cardOptions) {
       this.sortCardActions(singleOption);
      }
    }
  }

  sortCardActions(cardOption: CardOption): void {
    if (cardOption) {
      cardOption.cardActions.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });
    }
  }

  formPostQuestionnaireActions(pqActions: PostQuestionnaireAction[]): void {
    if (!this.pqaBootstrapped) {
      // console.log('form PQA ', pqActions);
      this.postQuestionnaireActions = [];

      for (const singleAction of pqActions) {
        this.postQuestionnaireActions.push(singleAction);
      }

      this.postQuestionnaireActions.sort(function (a: any, b: any): number {
        return a.orderPosition - b.orderPosition;
      });

      this.pqaBootstrapped = true;

      // console.log('after form ', this.postQuestionnaireActions);
    }
  }

  renumerateItemsOrder(inputItems: any[], itemType: string): void {
    let nextPosition = 1;
    const itemsToRenumerate = [];

    for (const item of inputItems) {
      const itemPosition = item.orderPosition;
      if (nextPosition === itemPosition) {
        nextPosition = nextPosition + 1;
      } else {
        const itemId = item.id;
        itemsToRenumerate.push(itemId);
      }
    }

    if (itemsToRenumerate) {
      for (const itemId of itemsToRenumerate) {

        if (itemType === 'cards') {
          const targetCard = this.findCardById(itemId);
          if (targetCard) {
            this.updateCardPosition(targetCard, nextPosition);
            nextPosition = nextPosition + 1;
          }
        } else if (itemType === 'pqActions') {
          const targetAction = this.findPQActionById(itemId);
          if (targetAction) {
            this.updatePQActionPosition(targetAction, nextPosition);
            nextPosition = nextPosition + 1;
          }
        }
      }
    }

  }

  dropQuestion(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.cards, event.previousIndex, event.currentIndex);
    const firstCard = this.cards[event.previousIndex];
    const secondCard = this.cards[event.currentIndex];
    const promisesList = [];
    const promiseFirst = this.updateCardPosition(firstCard, event.previousIndex + 1);
    const promiseSecond = this.updateCardPosition(secondCard, event.currentIndex + 1);
    promisesList.push(promiseFirst);
    promisesList.push(promiseSecond);

    Promise.all(promisesList)
      .then((result: any) => {
        this.renumerateCards();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renumerateCards(): void {
    let iterator = 1;
    const promisesList = [];
    for (const singleCard of this.cards) {
      if (singleCard.isActive === true) {
        if (singleCard.orderPosition !== iterator) {
          const promiseItem = this.updateCardPosition(singleCard, iterator);
          promisesList.push(promiseItem);
        }
        iterator = iterator + 1;
      }
    }
    Promise.all(promisesList)
      .then((result: any) => {
        this.sharedPackageService.questionnaireCards$.next(this.cards);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  dropAction(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.postQuestionnaireActions, event.previousIndex, event.currentIndex);
    const firstAction = this.postQuestionnaireActions[event.previousIndex];
    const secondAction = this.postQuestionnaireActions[event.currentIndex];

    const promisesList = [];
    const actionOne = this.updatePQActionPosition(firstAction, event.previousIndex + 1);
    promisesList.push(actionOne);
    const actionTwo = this.updatePQActionPosition(secondAction, event.currentIndex + 1);
    promisesList.push(actionTwo);

    Promise.all(promisesList)
      .then((resultPQA: any) => {
        this.renumerateItemsOrder(this.postQuestionnaireActions, 'pqActions');
        this.sharedPackageService.postQuestionnaireActions$.next(this.postQuestionnaireActions);
      })
      .catch((error) => {
        console.log('Error with PQA update');
      });
  }

  updateCardPosition(targetCard: QuestionnaireCard, newCardIndex: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (targetCard && newCardIndex) {
        const newCard = {
          questionText: targetCard.questionText,
          cardType: targetCard.cardType,
          questionnaire_id: targetCard.questionnaireId,
          order_position: newCardIndex,
          // divisions_color_scheme: targetCard.divisionsColorScheme,
        };

        const cardId = targetCard.id;
        this.questionnaireService.updateQuestionCard(cardId, newCard)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((card: QuestionnaireCard) => {
            targetCard.orderPosition = newCardIndex;
            resolve(card);
          }, error => {
              reject(error);
            });
      } else {
        reject();
      }
    });
  }

  updatePQActionPosition(targetAction: PostQuestionnaireAction, newActionIndex: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (targetAction && newActionIndex) {
        const updatedFields = {
          order_position: newActionIndex,
        };

        const actionId = targetAction.id;
        this.packageService.updatePostQuestionnaireAction(actionId, updatedFields)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((pqAction: PostQuestionnaireAction) => {
            targetAction.orderPosition = newActionIndex;
            resolve(pqAction);
          },
            error => {
              console.log('PQA error ', error);
              reject(error);
            });

      } else {
        reject();
      }

    // console.log('PQA AFTER ', this.postQuestionnaireActions);
    });
  }

  updatePQAction(pqAction: PostQuestionnaireAction): void {
    const activePQAction = this.postQuestionnaireActions.find(action => action.id === pqAction.id);
    if (activePQAction) {
      const actionId = activePQAction.id;
      const updatedFields = {
        text: pqAction.text,
        parameter: pqAction.parameter,
        action_type: pqAction.actionType,
      };

      this.packageService.updatePostQuestionnaireAction(actionId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((updatedAction: PostQuestionnaireAction) => {
            activePQAction.text = updatedAction.text;
            activePQAction.parameter = updatedAction.parameter;
            activePQAction.actionType = updatedAction.actionType;
          },
          error => {
            console.log('Option error ', error);
          });
    }
  }

  removePQAction(pqAction: PostQuestionnaireAction): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onRemovePQAction(pqAction);
      }
    });
  }

  onRemovePQAction(pqAction: PostQuestionnaireAction): void {
    this.packageService.removePostQuestionnaireAction(pqAction.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultAction: PostQuestionnaireAction) => {
        if (resultAction) {
          const newActions = this.postQuestionnaireActions.filter(action => action.id !== pqAction.id);
          this.postQuestionnaireActions = newActions;
          this.renumerateItemsOrder(this.postQuestionnaireActions, 'pqActions');
          this.sharedPackageService.postQuestionnaireActions$.next(newActions);
        }
      },
        error => {
          console.log('PQA remove ', error);
        });
  }

  removeQuestion(activeCard: QuestionnaireCard): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onRemoveQuestion(activeCard);
      }
    });
  }

  onRemoveQuestion(activeCard: QuestionnaireCard): void {
    const newCards = this.cards.filter(card => card.id !== activeCard.id);
    this.cards = newCards;
    this.questionnaireService.removeQuestionCard(activeCard.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultCard: QuestionnaireCard) => {
        if (resultCard) {
          const updatedCards = this.cards.filter(card => card.id !== activeCard.id);
          this.cards = updatedCards;
          this.sharedPackageService.questionnaireCards$.next(updatedCards);
          this.renumerateQuestionOrder();
        }
      },
        error => {
          console.log('Question card remove ', error);
        });
  }

  copyQuestion(activeCard: QuestionnaireCard): void {
    if (activeCard) {
      const localCard = this.copyLocalCardHelper.copyQuestionnaireCard(activeCard);
      const newOrderPosition = this.cards.length + 1;
      localCard.orderPosition = newOrderPosition;
      this.questionnaireService.addQuestionCard(localCard)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((addedCard: QuestionnaireCard) => {
          const promisesList = [];
          const promisesOptions = localCard.cardOptions.map((option) => this.addCardOption(addedCard.id, option));
          for (const singlePromise of promisesOptions) {
            promisesList.push(singlePromise);
          }

          const promisesDivisions = localCard.scoreDivisions.map((division) => this.addDivisions(addedCard.id, division));
          for (const singlePromise of promisesDivisions) {
            promisesList.push(singlePromise);
          }

          Promise.all(promisesList)
            .then((result: any) => {
              this.openSnackBar('Success! Please, reload page.');
            })
            .catch((error) => {
              console.log('Copy question error ', error);
              this.openSnackBar('Error while copying');
            });
        }, error => {
          console.log('Copy question error ', error);
          this.openSnackBar('Error while copying');
        });
    }
  }

  addCardOption(cardId: number, option: CardOption): Promise<any> {
    const newOption: CardOption = {
      id: 0,
      text: option.text,
      cardActions: option.cardActions,
      orderPosition: option.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardOption(cardId, newOption)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdOption: CardOption) => {
          const promises = newOption.cardActions.map((a) => this.addCardOptionAction(createdOption, a));
          resolve(Promise.all(promises));
        },
          error => {
            console.log('Option error ', error);
            reject(error);
          });
    });
  }

  addCardOptionAction(createdOption: CardOption, action: CardAction): Promise<any> {
    const createdOptionId = createdOption.id;
    const newAction: CardAction = {
      id: 0,
      text: action.text,
      parameter: action.parameter,
      orderPosition: action.orderPosition,
    };

    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardAction(createdOptionId, newAction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((createdAction: CardAction) => {
          if (createdAction) {
            createdOption.cardActions.push(createdAction);
            resolve(createdOption.cardActions);
          } else {
            reject('no action');
          }
        }, error => {
          console.log('Option error ', error);
          reject(error);
        });
    });
  }

  addDivisions(cardId: number, division: ScoreDivision): Promise<any> {
    const newDivision: ScoreDivision = {
      id: 0,
      label: division.label,
      value: division.value,
    };
    return new Promise((resolve, reject) => {
      this.questionnaireService.addCardScoreDivision(cardId, newDivision)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultDivision: ScoreDivision) => {
          resolve(resultDivision);
        }, error => {
          console.log('Option error ', error);
          reject(error);
        });
    });
  }

  renumerateQuestionOrder(): void {
    let nextPosition = 1;
    const idCardsToRenumerate: number[] = [];

    for (const card of this.cards) {
      const cardPosition = card.orderPosition;
      if (nextPosition === cardPosition) {
        nextPosition = nextPosition + 1;
      } else {
        const cardId = card.id;
        idCardsToRenumerate.push(cardId);
      }
    }

    if (idCardsToRenumerate) {
      for (const cardId of idCardsToRenumerate) {
        const targetCard = this.findCardById(cardId);
        if (targetCard) {
          this.updateCardPosition(targetCard, nextPosition);
          nextPosition = nextPosition + 1;
        }
      }
    }
  }

  findCardById(cardId: number): QuestionnaireCard {
    const targetCard = this.cards.find(card => card.id === cardId);
    return targetCard;
  }

  findPQActionById(actionId: number): PostQuestionnaireAction {
    const targetAction = this.postQuestionnaireActions.find(action => action.id === actionId);
    return targetAction;
  }

  updateQuestionCardStatus(card: QuestionnaireCard, isActive: boolean): void {
    this.cards.find(param => param.id === card.id).isActive = isActive;
    const updatedFields = {
      is_active: isActive
    };

    this.questionnaireService.updateQuestionCard(card.id, updatedFields)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((updatedCard: QuestionnaireCard) => {
        this.renumerateCards();
      }, error => {
        console.log('Question card error ', error);
      });
  }

  updateQuestion(newCard: QuestionnaireCard): void {
    const activeCard = this.cards.find(card => card.id === newCard.id);
    if (activeCard) {
      const cardId = activeCard.id;

      const updatedFields = {
        questionText: newCard.questionText,
        cardType: newCard.cardType,
        is_required: newCard.isRequired,
        divisions_color_scheme: newCard.divisionsColorScheme,
      };

      this.questionnaireService.updateQuestionCard(cardId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((updatedCard: QuestionnaireCard) => {
          if (updatedCard) {
            const newCardType = updatedCard.cardType;
            activeCard.cardType = newCardType;
            activeCard.questionText = updatedCard.questionText;
            activeCard.isRequired = updatedCard.isRequired;
            activeCard.divisionsColorScheme = updatedCard.divisionsColorScheme,
            this.updateActiveCard(activeCard, newCard);
          }
        },
        error => {
          console.log('Question card error ', error);
        });
    }
  }

  updateActiveCard(activeCard: QuestionnaireCard, newCard: QuestionnaireCard): void {
    // const result = this.updateCardHelper.updateActiveCard(activeCard, newCard);
    this.updateCardHelper.updateActiveCard(activeCard, newCard)
      .then((result: any) => {
        activeCard.scoreDivisions = result[0];
        activeCard.cardOptions = result[1];
        this.sharedPackageService.reloadLocalCard$.emit(activeCard);
      }).catch(error => console.log(error));
  }

  validateActions(cardId: number): void {
    const activeCard = this.cards.find(card => card.id === cardId);
    if (activeCard) {
      const cardOptions = activeCard.cardOptions;
      const actions: CardAction[] = [];
      for (const option of cardOptions) {
        for (const singleAction of option.cardActions) {
          actions.push(singleAction);
        }
      }

      for (const action of actions) {
        this.validateSingleAction(action);
      }
    }
  }

  validateSingleAction(action: CardAction): void {
    const actionText = action.text;
    const splitted = actionText.split(' ');
    const params = this.individualParameters;
    let newString = '';
    for (const word of splitted) {
      let is_found = false;
      for (const param of params) {
        if (param['text'] === word) {
          is_found = true;
        }
      }

      if (is_found) {
        newString = newString + '<font>' + word + '<font>';
      } else {
        newString = newString + ' ' + word;
      }
    }
  }

  validateCard(cardId: number): void {
    this.updateErrorList();

    const activeCard = this.cards.find(card => card.id === cardId);
    if (activeCard) {
      const strError = 'Should be only one answer for this type of question';
      const len = activeCard.cardOptions.length;
      const orderPosition = activeCard.orderPosition;

      if (activeCard.cardType === 'text-input' && len > 1) {
        this.errorList[orderPosition - 1] = strError;
      } else if (len === 0) {
        this.errorList[orderPosition - 1] = 'Please add an option';
      } else {
        this.errorList[orderPosition - 1] = '';
      }

    }
  }

  updateErrorList(): void {
    const cardsLen = this.cards.length;
    const errorListLen = this.errorList.length;

    if (cardsLen > errorListLen) {
      const diff = cardsLen - errorListLen;
      for (let i = 0; i < diff; i++) {
        this.errorList.push('');
      }
    } else if (errorListLen > cardsLen) {
      // console.log('SLICE!');
    }
  }

  viewDemo(): void {
    this.sharedService.demoMode$.emit(true);
    // this.router.navigateByUrl(`/questionnaires/${this.currentPackageId}/preview`);
    this.router.navigate([`/questionnaires/${this.currentPackageId}/preview`]);
  }

  toCreateQuestionPage(): void {
    this.router.navigateByUrl(`/questionnaires/${this.currentPackageId}/create-question`);
  }

  toParametersPage(): void {
    this.router.navigateByUrl(`/questionnaires/${this.currentPackageId}/parameters`);
  }

  toCreatePQActionPage(): void {
    this.router.navigateByUrl(`/questionnaires/${this.currentPackageId}/create-pq-action`);
  }

  toEditQuestionPage(card: QuestionnaireCard, activeId: number): void {
    this.editQuestionMode = true;
    this.editPQActionMode = false;
    this.editedQuestion = card;
    this.activeCardId = activeId;
  }

  toEditPQActionPage(pqAction: PostQuestionnaireAction): void {
    this.editPQActionMode = true;
    this.editQuestionMode = false;
    this.editedPQAction = pqAction;

    let lastCardId = 0;
    for (const singleCard of this.cards) {
      if (singleCard.id > lastCardId) {
        lastCardId = singleCard.id;
      }
    }

    this.activeCardId = lastCardId;
  }

  goBack(): void {
    this.viewMode = false;
    this.sharedService.demoMode$.emit(this.viewMode);
  }

  goBackEdit(): void {
    this.editQuestionMode = false;
    this.editPQActionMode = false;
  }

  selectTab(value: any): void {
    this.sharedService.currentTabId.next(1);
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}
