import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-notes',
  templateUrl: './dialog-notes.component.html',
  styleUrls: ['./dialog-notes.component.scss']
})
export class DialogNotesComponent implements OnInit {
  isCreateMode: boolean = false;
  isUpdateMode: boolean = false;
  isDeleteMode: boolean  = false;

  createNoteTitle: string = 'Create';
  createNoteSubtitle: string = 'Are you sure you want to create this note?';
  createNoteConfirm: string = 'Create';

  updateNoteTitle: string = 'Update';
  updateNoteSubtitle: string = 'Are you sure you want to update this note?';
  updateNoteConfirm: string = 'Update';

  deleteNoteTitle: string = 'Delete';
  deleteNoteSubtitle: string = 'Are you sure you want to delete this note?';
  deleteNoteConfirm: string = 'Delete';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogNotesComponent>,
  ) {}

  ngOnInit(): void {
    if (this.data.isCreateMode) {
      this.isCreateMode = true;
    } else if (this.data.isUpdateMode) {
      this.isUpdateMode = true;
    } else if (this.data.isDeleteMode) {
      this.isDeleteMode = true;
    }
  }
}
