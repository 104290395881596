import { Number, String, Record, Null, Array, Boolean } from 'runtypes';

const PlotLine = Record({
  color: String,
  label: String,
  value: Number,
  width: Number,
  zIndex: Number,
  position: Number,
});

const WidgetFilters = Record({
  active_colleagues_ids: Array(Number).Or(Null),
  active_parameters_ids: Array(Number).Or(Null),
  active_research_id: Number.Or(Null),
  sort_parameter_id: Number.Or(Null),
  show_parameters_names: Boolean.Or(Null),
  hide_empty_cards: Boolean.Or(Null),
  show_latest_research: Boolean.Or(Null),
  show_participants: Boolean.Or(Null),
  plot_lines: Array(PlotLine).Or(Null)
});

export const ApiSharedDashboardWidget = Record({
  id: Number,
  shared_dashboard_id: Number,
  widget_name: String,
  widget_title: String,
  order_position: Number,
  widget_filters: WidgetFilters.Or(Record({}))
});
