<div class="outer">
<div class="middle">
<div class="inner">
  <mat-card>
    <form [formGroup]="personForm" autocomplete="off">
      <input type="hidden" formControlName="id">

      <div>
        <mat-form-field class="card-item">
          <mat-placeholder class="placeholder" i18n="@@placeholder-firstName">First Name</mat-placeholder>
          <input matInput [errorStateMatcher]="matcher" min="2" class="form-control" id="firstName" formControlName="firstName" required/>
        </mat-form-field>
        <mat-form-field class="card-item">
          <mat-placeholder class="placeholder" i18n="@@placeholder-lastName">Last name</mat-placeholder>
          <input matInput [errorStateMatcher]="matcher" min="2" class="form-control" id="lastName" formControlName="lastName" required/>
        </mat-form-field>
      </div>
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder">Email</mat-placeholder>
        <input matInput [errorStateMatcher]="matcher" class="form-control" id="email" formControlName="email" required/>
      </mat-form-field>
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder">Slack User Id</mat-placeholder>
        <input matInput [errorStateMatcher]="matcher" class="form-control" id="slackUserId" formControlName="slackUserId"/>
      </mat-form-field>

      <mat-form-field class="card-item">
        <mat-placeholder i18n="@@placeholder-department" class="placeholder">Department</mat-placeholder>
        <mat-select panelClass="panel-blue" [(value)]="personForm.value.departmentId"
                    (selectionChange)="setOptionValue($event)">
          <mat-option i18n="@@placeholder-none-option" [value]="0">None</mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.id">{{department.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="button-block">
        <button
            [disabled]="!personForm.value.firstName || !personForm.value.email || isBusy"
            mat-raised-button
            type="button"
            class="create-button"
            color="accent"
            (click)="onCreateNewPerson(personForm.value, false)"
            i18n="@@createButton">
          Create
        </button>
      </div>

    </form>
  </mat-card>
</div>
</div>
</div>