<mat-expansion-panel class="create-parameter-data">
  <mat-expansion-panel-header (click)="$event.stopPropagation();">
    <mat-panel-title>
      <span i18n="@@create-parameter-btn">Create parameter data</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="create-parameter-data__field">
    <mat-placeholder class="create-parameter-data__placeholder" i18n="@@parameterValue">Parameter value:</mat-placeholder>
    <input class="create-parameter-data__input" matInput required [(ngModel)]="parameterValue">
  </mat-form-field>
  <mat-form-field class="create-parameter-data__field">
    <mat-placeholder class="create-parameter-data__placeholder" i18n="@@description">Description:</mat-placeholder>
    <textarea class="create-parameter-data__textarea" matInput [(ngModel)]="parameterDescription"></textarea>
  </mat-form-field>
  <div class="create-parameter-data__buttons">
    <button mat-raised-button
            color="accent"
            i18n="@@create-parameter-btn"
            (click)="createParameterData()">
      Save
    </button>
    <button mat-raised-button
            color="primary"
            i18n="@@cancelButton">
      Cancel
    </button>
  </div>
</mat-expansion-panel>
