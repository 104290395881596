import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Experiment } from 'app/_models/experiment';
import { Questionnaire } from 'app/_models/questionnaire';
import { TabExperiment } from 'app/_models/tab-experiment';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { PackageParameter } from 'app/_models/package-parameter';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { PackageParticipant } from 'app/_models/package-participant';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { Package } from 'app/_models/package';

@Injectable({
  providedIn: 'root'
})
export class SharedPackageService {
  public isStandardSet$: BehaviorSubject<boolean>;
  public multipleSubmissionsAllowed$: BehaviorSubject<boolean>;
  public sendToColleagues$: BehaviorSubject<boolean>;
  public maxSubmissions$: BehaviorSubject<number>;
  public oneDayNotifications$: BehaviorSubject<boolean>;
  public halfTermNotifications$: BehaviorSubject<boolean>;
  public relativeQuestion$: BehaviorSubject<number>;
  public packageCompanyId$: BehaviorSubject<number>;
  public packageBootstrapped$: BehaviorSubject<boolean>;
  public currentPackageId$: BehaviorSubject<number>;
  public copiedStandardPackId$: BehaviorSubject<number>;
  public currentQuestionnaireId$: BehaviorSubject<number>;
  public currentPackageName$: BehaviorSubject<string>;
  public currentPackageCategoryName$: BehaviorSubject<string>;
  public currentPackageDescription$: BehaviorSubject<string>;
  public colleagueLink$: BehaviorSubject<string>;
  public currentPackageLanguage$: BehaviorSubject<string>;
  public questionnaireActiveStatus$: BehaviorSubject<string>;
  public packageParameters$: BehaviorSubject<PackageParameter[]>;
  public questionnaireCards$: BehaviorSubject<QuestionnaireCard[]>;
  public postQuestionnaireActions$: BehaviorSubject<PostQuestionnaireAction[]>;
  public packageParticipants$: BehaviorSubject<PackageParticipant[]>;
  public packageTabExperiments$: BehaviorSubject<TabExperiment[]>;
  public packageExperimentsBrief$: BehaviorSubject<Experiment[]>;
  public experiments$: BehaviorSubject<Experiment[]>;
  public questionnaireDetails$: BehaviorSubject<Questionnaire>;
  public dashboardConfig$: BehaviorSubject<DashboardConfig>;
  public experimentsToLoad$: BehaviorSubject<number[]>;
  public experimentsAlreadyLoaded$: BehaviorSubject<number[]>;

  public reloadLocalCard$: EventEmitter<QuestionnaireCard>;
  public reloadParticipants$: EventEmitter<any>;
  public onSettingsUpdate$: EventEmitter<any>;
  public onChartUpdate$: EventEmitter<any>;
  public onResearchUpdate$: EventEmitter<any>;
  public onPackageSelect$: EventEmitter<any>;
  public onRefreshPackage$: EventEmitter<any>;
  public onLoadAllExperiments$: EventEmitter<any>;
  public onCreateFromStandard$: EventEmitter<any>;
  public onStandardSetCreated$: EventEmitter<boolean>;

  constructor() {
    this.isStandardSet$ = new BehaviorSubject(false);
    this.multipleSubmissionsAllowed$ = new BehaviorSubject(false);
    this.sendToColleagues$ = new BehaviorSubject(false);
    this.packageCompanyId$ = new BehaviorSubject(0);
    this.packageBootstrapped$ = new BehaviorSubject(false);
    this.oneDayNotifications$ = new BehaviorSubject(false);
    this.halfTermNotifications$ = new BehaviorSubject(false);

    this.maxSubmissions$ = new BehaviorSubject(0);
    this.relativeQuestion$ = new BehaviorSubject(0);
    this.currentPackageId$ = new BehaviorSubject(0);
    this.copiedStandardPackId$ = new BehaviorSubject(0);
    this.currentQuestionnaireId$ = new BehaviorSubject(0);
    this.currentPackageName$ = new BehaviorSubject('');
    this.currentPackageCategoryName$ = new BehaviorSubject('');
    this.currentPackageDescription$ = new BehaviorSubject('');
    this.colleagueLink$ = new BehaviorSubject('');
    this.currentPackageLanguage$ = new BehaviorSubject('');
    this.questionnaireActiveStatus$ = new BehaviorSubject('');
    this.packageParameters$ = new BehaviorSubject([]);
    this.questionnaireCards$ = new BehaviorSubject([]);
    this.postQuestionnaireActions$ = new BehaviorSubject([]);
    this.packageParticipants$ = new BehaviorSubject([]);
    this.packageTabExperiments$ = new BehaviorSubject([]);
    this.packageExperimentsBrief$ = new BehaviorSubject([]);
    this.experiments$ = new BehaviorSubject([]);
    this.questionnaireDetails$ = new BehaviorSubject(new Questionnaire);
    this.dashboardConfig$ = new BehaviorSubject(new DashboardConfig);
    this.experimentsToLoad$ = new BehaviorSubject([]);
    this.experimentsAlreadyLoaded$ = new BehaviorSubject([]);

    this.reloadLocalCard$ = new EventEmitter();
    this.reloadParticipants$ = new EventEmitter();
    this.onSettingsUpdate$ = new EventEmitter();
    this.onChartUpdate$ = new EventEmitter();
    this.onResearchUpdate$ = new EventEmitter();
    this.onPackageSelect$ = new EventEmitter();
    this.onRefreshPackage$ = new EventEmitter();
    this.onCreateFromStandard$ = new EventEmitter();
    this.onStandardSetCreated$ = new EventEmitter();
    this.onLoadAllExperiments$ = new EventEmitter();
  }

  public clearSharedService(): void {
    this.isStandardSet$.next(false);
    this.multipleSubmissionsAllowed$.next(false);
    this.sendToColleagues$.next(false);
    this.packageCompanyId$.next(0);
    this.packageBootstrapped$.next(false);
    this.currentPackageId$.next(0);
    this.copiedStandardPackId$.next(0);
    this.currentQuestionnaireId$.next(0);
    this.currentPackageName$.next('');
    this.currentPackageCategoryName$.next('');
    this.currentPackageDescription$.next('');
    this.colleagueLink$.next('');
    this.questionnaireActiveStatus$.next('');
    this.currentPackageLanguage$.next('');
    this.packageParameters$.next([]);
    this.packageExperimentsBrief$.next([]);
    this.experiments$.next([]);
    this.questionnaireCards$.next([]);
    this.postQuestionnaireActions$.next([]);
    this.packageParticipants$.next([]);
    this.packageTabExperiments$.next([]);
    this.questionnaireDetails$.next(new Questionnaire);
    this.dashboardConfig$.next(new DashboardConfig);
    this.experimentsToLoad$.next([]);
    this.experimentsAlreadyLoaded$.next([]);
  }

  // ----------- //
  // GET METHODS //
  // ----------- //
  public getPackageParameters(): Observable<PackageParameter[]> {
    return this.packageParameters$
      .pipe(
        filter(parameters => parameters && parameters.length > 0),
        map((parameters: PackageParameter[]) => parameters)
      );
  }

  public getPackageExperiments(): Observable<Experiment[]> {
    return this.experiments$
      .pipe(
        filter(experiments => experiments && experiments.length > 0),
        map((experiments: Experiment[]) => experiments)
      );
  }

  public getDashboardConfig(): Observable<DashboardConfig> {
    return this.dashboardConfig$
      .pipe(
        filter(dashboardConfig => dashboardConfig !== null),
        map((dashboardConfig: DashboardConfig) => dashboardConfig)
      );
  }

  public getQuestionnaireDetails(): Observable<Questionnaire> {
    return this.questionnaireDetails$
      .pipe(
        filter(questionnaireDetails => questionnaireDetails !== null),
        map((questionnaireDetails: Questionnaire) => questionnaireDetails)
      );
  }

  // -------------- //
  // UPDATE METHODS //
  // -------------- //
  public updateActiveStatus(newStatus: any): void {
    const questionnaire: Questionnaire = this.questionnaireDetails$.getValue();
    questionnaire.activeStatus = newStatus;

    this.questionnaireDetails$.next(questionnaire);
  }

  public updatePackageParticipants(participants: PackageParticipant[]) {
    this.packageParticipants$.next(participants);
  }

  public updateExperiments(experiments: Experiment[]) {
    this.experiments$.next(experiments);
  }

  public updateSharedPackageInfo(packageInfo: Package) {
    this.currentPackageId$.next(packageInfo.id);
    this.currentPackageName$.next(packageInfo.name);
    this.packageCompanyId$.next(packageInfo.companyId);
    this.colleagueLink$.next(packageInfo.colleagueLink);
    this.currentPackageLanguage$.next(packageInfo.language);
    this.dashboardConfig$.next(packageInfo.dashboardConfig);
    this.sendToColleagues$.next(packageInfo.sendToColleagues);
    this.packageParticipants$.next(packageInfo.participants);
    this.packageParameters$.next(packageInfo.packageParameters);
    this.currentPackageDescription$.next(packageInfo.description);
    this.multipleSubmissionsAllowed$.next(packageInfo.multipleSubmissions);
    this.maxSubmissions$.next(packageInfo.maxSubmissions);
    this.relativeQuestion$.next(packageInfo.relativeQuestion);
    this.oneDayNotifications$.next(packageInfo.oneDayNotifications);
    this.halfTermNotifications$.next(packageInfo.halfTermNotifications);
  }
}
