import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CardOption } from 'app/_models/card-option';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { ScoreDivision } from 'app/_models/score-division';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CopyLocalCardHelper {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
  ) { }

  copyQuestionnaireCard(inputCard: QuestionnaireCard): QuestionnaireCard {
    const localOptions: CardOption[] = [];
    for (const option of inputCard.cardOptions) {
      const newOption = Object.assign({}, option);
      const localActions = [];
      for (const action of option.cardActions) {
        const newAction = Object.assign({}, action);
        localActions.push(newAction);
      }
      newOption.cardActions = localActions;
      localOptions.push(newOption);
    }

    const localScoreDivisions: ScoreDivision[] = [];
    for (const singleDivision of inputCard.scoreDivisions) {
      const newDivision = Object.assign({}, singleDivision);
      localScoreDivisions.push(newDivision);
    }

    const newCard: QuestionnaireCard = {
      id: inputCard.id,
      questionText: inputCard.questionText,
      cardType: inputCard.cardType,
      questionnaireId: inputCard.questionnaireId,
      cardOptions: localOptions,
      orderPosition: inputCard.orderPosition,
      scoreDivisions: localScoreDivisions,
      isRequired: inputCard.isRequired,
      divisionsColorScheme: inputCard.divisionsColorScheme,
    };

    return newCard;
  }

}
