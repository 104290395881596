<mat-spinner *ngIf="!isLoaded" style="margin: 20px;"></mat-spinner>

<div class="widget-wrapper-bar-style">
  <div *ngIf="showChartMode" class="chart-box">
    <div *ngIf="!userView" class="menu-bar-style">
      <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button (click)="removeWidget()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <nav mat-tab-nav-bar class="tab-links-style">
      <a mat-tab-link *ngFor="let tab of tabs" (click)="changeTab(tab)" [active]="selectedTabIndex == tab.id"
        class="single-link-style">
        {{tab.name}}
      </a>
    </nav>
    <highcharts-chart style="display: flex;" [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag"
      [callbackFunction]="chartCallback" [oneToOne]="true"></highcharts-chart>
  </div>
</div>

<mat-card *ngIf="colleaguesEditMode" class="colleagues-bar-list">
  <div class="button-bar-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngFor="let colleague of activeColleagues">
    <div class="single-colleague-list">
      {{colleague.firstName}} {{colleague.lastName}}
      <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()"></mat-checkbox>
    </div>
  </div>
  <div class="single-colleague-list">
    <b i18n="@@useParticipants">Use participants </b>
    <mat-checkbox [(ngModel)]="showParticipants" (change)="onParticipantsStateChange()"></mat-checkbox>
  </div>
</mat-card>

<mat-card *ngIf="parametersEditMode" class="colleagues-bar-list">
  <div class="button-bar-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <!-- <mat-radio-group [(ngModel)]="activeParameter" (change)="onParametersStateChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let parameter of localParameters" [value]="parameter">
        {{parameter.visibleName}}
      </mat-radio-button>
    </mat-radio-group> -->
    <div *ngFor="let parameter of localParameters">
      <div class="single-colleague-list">
        {{parameter.visibleName}}
        <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onParametersStateChange()"></mat-checkbox>
      </div>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="researchesEditMode" class="colleagues-bar-list">
  <div class="button-bar-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
      <mat-radio-button class="researches-radio-button" value="last_research" i18n="@@lastResearch">
        Last research
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" *ngFor="let research of experiments" [value]="research">
        {{research.startDate}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-card *ngIf="linesEditMode" class="colleagues-bar-list">
  <div class="button-bar-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngFor="let line of plotLines">
    <mat-form-field style="width: 80px;">
      <mat-placeholder class="placeholder">Line color</mat-placeholder>
      <input matInput [(ngModel)]="line.color" (blur)="onBlurMethod()">
    </mat-form-field>
    <mat-form-field style="width: 250px;">
      <mat-placeholder class="placeholder">Line label</mat-placeholder>
      <input matInput [(ngModel)]="line.label" (blur)="onBlurMethod()">
    </mat-form-field>
    <ng-container *ngIf="line.packageParameterId > 0">
      <mat-form-field style="width: 80px;">
        <mat-placeholder class="placeholder">Line value</mat-placeholder>
        <input matInput [disabled]="true" [(ngModel)]="line.value" (blur)="onBlurMethod()">
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!(line.packageParameterId > 0)">
      <mat-form-field style="width: 80px;">
        <mat-placeholder class="placeholder">Line value</mat-placeholder>
        <input matInput [(ngModel)]="line.value" (blur)="onBlurMethod()">
      </mat-form-field>
    </ng-container>
    <mat-form-field style="width: 80px;">
      <mat-placeholder class="placeholder">Line width</mat-placeholder>
      <input matInput [(ngModel)]="line.width" (blur)="onBlurMethod()">
    </mat-form-field>
    <mat-form-field style="width: 80px;">
      <mat-placeholder class="placeholder">Line zIndex</mat-placeholder>
      <input matInput [(ngModel)]="line.zIndex" (blur)="onBlurMethod()">
    </mat-form-field>
    <span style="width: 50px;">
      <button class="delete-button-style" (click)="onRemoveLine(line)" i18n="@@deleteButton">
        Delete
      </button>
    </span>
  </div>
  <ng-container *ngIf="activeCommonParameter">
    <div *ngIf="commonParameters.length > 0">
      <mat-radio-group [(ngModel)]="activeCommonParameter" (change)="onChangeActiveCommonParameter($event)">
        <mat-radio-button class="researches-radio-button" *ngFor="let param of commonParameters" [value]="param">
          {{param.visibleName}}
        </mat-radio-button>
      </mat-radio-group>
      <button (click)="onAddNewCommonLine()" i18n="@@addCommonLine">
        Add common line
      </button>
      &nbsp;
      <button (click)="onResetCommonLine()" i18n="@@reset">
        Reset
      </button>
    </div>
    <div *ngIf="commonParameters.length == 0">
      <button (click)="onResetCommonLine()" i18n="@@reset">
        Reset
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!activeCommonParameter">
    <button (click)="onAddNewLine()">
      Add new
    </button>
    &nbsp;
    <button (click)="onAddCommonLineAction()" i18n="@@addCommonLine">
      Add common line
    </button>
  </ng-container>
</mat-card>

<mat-card *ngIf="packagesEditMode" class="colleagues-bar-list">
  <div class="button-bar-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activePackageOption" (change)="onPackagesFilterChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let singlePackage of sharedPackagesList"
        [value]="singlePackage">
        {{singlePackage.name}}
      </mat-radio-button>
      <!-- <mat-radio-button class="researches-radio-button" [value]="null">
        No filter
      </mat-radio-button> -->
    </mat-radio-group>
  </div>
</mat-card>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showParametersList">Show parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="onPackagesEditMode()">
    <mat-icon>ballot</mat-icon>
    <span i18n="@@choosePackage">Choose package</span>
  </button>
  <button mat-menu-item (click)="onLinesEditMode()">
    <mat-icon>edit</mat-icon>
    <span i18n="@@editLines">Edit lines</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>

<mat-menu #menuHide="matMenu">
  <button mat-menu-item (click)="onShowChartMode()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span i18n="@@backButton">Back</span>
  </button>
</mat-menu>