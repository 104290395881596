import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input,
  ChangeDetectorRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtendedColleague, ExtendedParameter, WidgetFilters, HeatmapParameter } from '../_helpers/local-models';
import { ChartHelper } from '../_helpers/chart-helper';

import * as Highcharts from 'highcharts';
import HeatmapModule from 'highcharts/modules/heatmap';
import { Colleague } from 'app/_models/colleague';
import { PackageParameter } from 'app/_models/package-parameter';
import { Experiment } from 'app/_models/experiment';
import { Department } from 'app/_models/department';
// import { DashboardWidget } from 'app/_models/dashboard-widget';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { LocalDashboardHelper } from '../_helpers/local-dashboard-helper';
import { Package } from 'app/_models/package';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { PackageParticipant } from 'app/_models/package-participant';
import { ColleagueParameter } from 'app/_models/colleague-parameter';
HeatmapModule(Highcharts);

@Component({
  selector: 'app-heatmap-individual-widget',
  templateUrl: './heatmap-individual-widget.component.html',
  styleUrls: ['./heatmap-individual-widget.component.scss', '../widgets.scss'],
})
export class HeatmapIndividualWidgetComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  isLoaded: boolean = false;
  showChartMode: boolean = true;
  isSwitchEnabled: boolean = true;
  isPackageChanged: boolean = false;
  showParticipants: boolean = false;
  colleaguesEditMode: boolean = false;
  parametersEditMode: boolean = false;
  researchesEditMode: boolean = false;
  isAllParticipantsSelected: boolean = false;

  activeExperiment: Experiment;
  inputWidgetFilters: WidgetFilters;
  widgetFilters: WidgetFilters = {};
  experiments: Experiment[] = [];
  colleaguesList: Colleague[] = [];
  sharedPackagesList: Package[] = [];
  departmentsList: Department[] = [];
  participants: PackageParticipant[] = [];
  heatmapParameters: HeatmapParameter[] = [];
  activeColleagues: ExtendedColleague[] = [];
  activeParameters: ExtendedParameter[] = [];

  data: any = [];
  tabs: any = [];
  chartObject: any;
  activePackageOption: any;
  activeExperimentOption: any;
  updateFlag: boolean = false;
  selectedTabIndex: number = 0;
  activeDepartmentId: number = 0;
  colleaguesAxisNames: string[] = [];
  parametersAxisNames: string[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      // marginTop: 40,
      marginTop: 0,
      marginBottom: 80
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      categories: [],
      title: null
    },
    colorAxis: [
      {
        minColor: '#FFFFFF',
        maxColor: Highcharts.getOptions().colors[0]
      },
    ],
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false,
    },
    series: [],
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private chartHelper: ChartHelper,
    private localDashboardHelper: LocalDashboardHelper
  ) { }

  ngOnInit(): void {
    this.loadSingleDashboardData();
  }

  loadSingleDashboardData(): void {
    const promisesList = [];
    const promiseColleagues: Promise<any> = this.localDashboardHelper.getColleagues(false);

    promiseColleagues.then((colleagues: Colleague[]) => {
      this.colleaguesList = colleagues;
    }).catch((error) => console.log(error));

    promisesList.push(promiseColleagues);

    Promise.all(promisesList).then((result) => {
      this.loadExperimentInfo();
    }).catch((error) => console.log(error));
  }

  loadExperimentInfo(): void {
    this.isLoaded = false;
    const promisesList = [];
    let promiseParameters: Promise<any>;
    let promiseExperiments: Promise<any>;
    let promiseWidget: Promise<any>;
    let promiseParticipants: Promise<any>;
    let promiseDepartments: Promise<any>;
    promiseParameters = this.localDashboardHelper.getCachedPackageParameters();
    promiseDepartments = this.localDashboardHelper.getDepartments();
    promiseExperiments = this.localDashboardHelper.getCachedExperiments(false);
    promiseWidget = this.localDashboardHelper.getDashboardWidget(this.inputWidgetId);
    promiseParticipants = this.localDashboardHelper.getCachedParticipants();
    promiseWidget.then((widget: any) => {
      // console.log('RELOAD WIDGET ', widget);
      this.inputWidgetFilters = widget.widgetFilters;
    }).catch((error) => {
      console.log('Widget error ', error);
    });

    promiseParameters.then((parameters: PackageParameter[]) => {
      this.activeParameters = [];
      if (parameters) {
        const individualParams = parameters.filter(param => param.groupName === 'individual');
        const filterParams = individualParams.filter(param => param.commonValue.type === 'number');
        // const filterParams = parameters.filter(param => param.groupName === 'individual');
        for (const singleParameter of filterParams) {
          this.activeParameters.push({
            ...singleParameter,
            isActive: true
          });
        }
      }
    }).catch((error) => {
      console.log('Parameters error ', error);
    });

    promiseExperiments.then((experiments: Experiment[]) => {
      this.experiments = [];
      if (experiments) {
        this.experiments = experiments;
      }
    }).catch((error) => console.log(error));

    promiseDepartments = this.localDashboardHelper.getDepartments();
    promiseDepartments.then((departments: Department[]) => {
      this.formDepartmentTabs(departments);
      this.departmentsList = departments;
    }).catch(error => console.log(error));

    promiseParticipants.then((participants: PackageParticipant[]) => {
      this.participants = participants;
    }).catch((error) => console.log(error));

    promisesList.push(promiseDepartments);
    promisesList.push(promiseParameters);
    promisesList.push(promiseExperiments);
    promisesList.push(promiseWidget);
    promisesList.push(promiseParticipants);

    Promise.all(promisesList).then((result) => {
      this.activeColleagues = this.localDashboardHelper.findActiveColleagues(this.experiments, this.colleaguesList);
      this.checkInputWidgets();
      if (this.showParticipants) {
        this.setActiveParticipants();
      }
      const nonEmptyTab = this.findNonEmptyTab();
      if (nonEmptyTab) {
        this.changeTab(nonEmptyTab);
      }

      this.formNewMap();
      this.formNewAxis();
      // this.changeEmptyTab();
      this.isLoaded = true;
    }).catch((error) => console.log(error));
  }

  setActiveParticipants(): void {
    const newActiveColleagues = [];

    for (const singleColleague of this.activeColleagues) {
      const findParticipant = this.participants.find(participant => participant.colleagueId === singleColleague.id);
      if (findParticipant && !this.isAllParticipantsSelected) {
        singleColleague.isActive = findParticipant.isSelected;
        newActiveColleagues.push(singleColleague);
      } else {
        singleColleague.isActive = false;
        newActiveColleagues.push(singleColleague);
      }
    }

    this.isAllParticipantsSelected = !this.isAllParticipantsSelected;
    this.activeColleagues = newActiveColleagues;
  }

  reloadExperimentInfo(): void {
    const promisesList = [];
    let promiseExperiments: Promise<any>;
    let promiseDepartments: Promise<any>;
    let promiseParameters: Promise<any>;
    promiseDepartments = this.localDashboardHelper.getDepartments();
    promiseExperiments = this.localDashboardHelper.getCachedExperiments(false);
    promiseParameters = this.localDashboardHelper.getCachedPackageParameters();

    promiseParameters.then((parameters: PackageParameter[]) => {
      this.activeParameters = [];
      // this.relativeParameters = [];
      if (parameters) {
        const individualParams = parameters.filter(param => param.groupName === 'individual');
        const filterParams = individualParams.filter(param => param.commonValue.type === 'number');
        for (const singleParameter of filterParams) {
          this.activeParameters.push({
            ...singleParameter,
            isActive: true
          });
        }
        this.updatePackageOption();
      }
    }).catch((error) => {
      console.log('Parameters error ', error);
    });

    promiseExperiments.then((experiments: Experiment[]) => {
      this.experiments = [];
      if (experiments) {
        this.experiments = experiments;
        this.updatePackageOption();
      }
    }).catch((error) => console.log(error));

    promiseDepartments = this.localDashboardHelper.getDepartments();
    promiseDepartments.then((departments: Department[]) => {
      this.formDepartmentTabs(departments);
    }).catch(error => console.log(error));

    promisesList.push(promiseDepartments);
    promisesList.push(promiseExperiments);
    promisesList.push(promiseParameters);

    Promise.all(promisesList).then((result) => {
      this.activeColleagues = this.localDashboardHelper.findActiveColleagues(this.experiments, this.colleaguesList);
      this.formNewMap();
      this.formNewAxis();
      this.generateNewHeatmapParams();
      this.widgetFiltersUpdate();
      this.isLoaded = true;
    }).catch((error) => console.log(error));
  }

  formNewAxis(): void {
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    const newParametersAxisNames = [];
    for (const singleParameter of this.activeParameters) {
      if (singleParameter.isActive) {
        newParametersAxisNames.push(singleParameter.visibleName);
      }
    }
    this.parametersAxisNames = newParametersAxisNames;
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updatePackageOption(): void {
    if (this.activePackageOption) {
      const packageId = this.activePackageOption.id;
      this.activeParameters = this.activeParameters.filter(param => param.packageId === packageId);
    }
    if (this.activeExperimentOption === 'last_research') {
      this.activeExperiment = this.getLatestExperiment();
    }
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  setDefaultWidgetFilters(): void {
    this.widgetFilters['showLatestResearch'] = true;
    this.widgetFilters['showParticipants'] = true;
    this.showParticipants = true;
    this.activeExperimentOption = 'last_research';
    this.generateNewHeatmapParams();
  }

  restoreInputWidgetFilters(): void {
    // console.log('INPUTW FILTERS ', this.inputWidgetFilters);
    if (this.inputWidgetFilters) {
      const colleaguesIds = this.inputWidgetFilters.activeColleaguesIds;
      if (colleaguesIds) {
        for (const singleColleague of this.activeColleagues) {
          const findColleague = colleaguesIds.find(id => id === singleColleague.id);
          if (findColleague) {
            singleColleague.isActive = true;
          } else {
            singleColleague.isActive = false;
          }
        }
      }

      if (this.isPackageChanged) {
        this.isPackageChanged = false;
        this.setAllParametersActive();
        this.generateNewHeatmapParams();
      } else {
        const paramsIds = this.inputWidgetFilters.activeParametersIds;
        if (paramsIds) {
          for (const singleParam of this.activeParameters) {
            const findParam = paramsIds.find(id => id === singleParam.id);
            if (findParam) {
              singleParam.isActive = true;
            } else {
              singleParam.isActive = false;
            }
          }
        }
      }

      const activePackageId = this.inputWidgetFilters.activePackageId;
      if (activePackageId && activePackageId > 0) {
        const findPackage = this.sharedPackagesList.find(pack => pack.id === activePackageId);
        if (findPackage) {
          this.activePackageOption = findPackage;
          this.updatePackageOption();
        }
      }

      const heatmapParameters = this.filterValidParameters(this.inputWidgetFilters.heatmapParameters);
      this.heatmapParameters = [];
      if (heatmapParameters.length > 0) {
        for (const singleParameter of heatmapParameters) {
          this.heatmapParameters.push(singleParameter);
        }
      } else {
        this.generateNewHeatmapParams();
      }

      const showLatestResearch = this.inputWidgetFilters.showLatestResearch;
      if (showLatestResearch) {
        const activeResearch = this.getLatestExperiment();
        if (activeResearch) {
          this.activeExperiment = activeResearch;
          this.activeExperimentOption = 'last_research';
        }
      } else {
        const activeResearchId = this.inputWidgetFilters.activeResearchId;
        if (activeResearchId) {
          const activeResearch = this.experiments.find(experiment => experiment.id === activeResearchId);
          if (activeResearch) {
            this.activeExperiment = activeResearch;
            this.activeExperimentOption = activeResearch;
          }
        }
      }

      const showParticipants = this.inputWidgetFilters.showParticipants;
      if (typeof (showParticipants) === 'undefined') {
        this.showParticipants = true;
      } else {
        if (showParticipants) {
          this.showParticipants = true;
        } else {
          this.showParticipants = false;
        }
      }

    }
  }

  widgetFiltersUpdate(): void {
    const colleaguesFilter = this.activeColleagues.filter(colleague => colleague.isActive === true);
    const activeColleaguesIds = [];
    for (const singleColleague of colleaguesFilter) {
      activeColleaguesIds.push(singleColleague.id);
    }
    this.widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

    const parametersFilter = this.activeParameters.filter(param => param.isActive === true);
    const activeParamsIds = [];
    for (const singleParam of parametersFilter) {
      activeParamsIds.push(singleParam.id);
    }

    // console.log('Active params ids ', activeParamsIds);

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    const heatmapParameters = this.heatmapParameters;
    // console.log('H PPP ', heatmapParameters);
    if (heatmapParameters) {
      // console.log('WIDGET UPD ', heatmapParameters);
      const newParams = this.convertHeatmapParameters(heatmapParameters);
      this.widgetFilters['heatmapParameters'] = newParams;
    }

    if (this.activePackageOption && this.activePackageOption.id > 0) {
      this.widgetFilters['activePackageId'] = this.activePackageOption.id;
    } else {
      this.widgetFilters['activePackageId'] = 0;
    }

    const experimentOption = this.activeExperimentOption;
    if (experimentOption && this.experiments) {
      if (experimentOption === 'last_research') {
        this.activeExperiment = this.getLatestExperiment();
        this.widgetFilters['showLatestResearch'] = true;
      } else {
        this.activeExperiment = experimentOption;
        this.widgetFilters['showLatestResearch'] = false;
      }
    }

    if (this.activeExperiment) {
      this.widgetFilters['activeResearchId'] = this.activeExperiment.id;
    }

    if (this.showParticipants) {
      this.widgetFilters['showParticipants'] = true;
    } else {
      this.widgetFilters['showParticipants'] = false;
    }

    if (!this.userView) {
      // console.log('On filters UPDATE ', this.widgetFilters);
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  setAllParametersActive(): void {
    for (const singleParameter of this.activeParameters) {
      singleParameter.isActive = true;
    }
  }

  generateNewHeatmapParams(): void {
    this.heatmapParameters = [];
    for (const singleParam of this.activeParameters) {
      const heatmapParam: HeatmapParameter = {
        parameterId: singleParam.id,
        minValue: 1,
        maxValue: 10,
        visibleName: singleParam.visibleName,
        isActive: singleParam.isActive
      };
      this.heatmapParameters.push(heatmapParam);
    }
  }

  convertHeatmapParameters(heatParams: HeatmapParameter[]): HeatmapParameter[] {
    const newParams = [];
    // console.log('HEAT? ', heatParams);
    for (const singleParam of heatParams) {
      const newParam = singleParam;
      newParam.minValue = Number(singleParam.minValue);
      newParam.maxValue = Number(singleParam.maxValue);
      newParams.push(newParam);
    }
    return newParams;
  }

  filterValidParameters(inputParameters: HeatmapParameter[]): HeatmapParameter[] {
    const validParameters: HeatmapParameter[] = [];

    if (inputParameters) {
      for (const singleHeatmapParam of inputParameters) {
        const isValid = this.activeParameters.find(param => param.id === singleHeatmapParam.parameterId);
        if (isValid) {
          validParameters.push(singleHeatmapParam);
        }
      }
    } else {
      return validParameters;
    }

    return validParameters;
  }

  formDepartmentTabs(departments: Department[]): void {
    this.tabs = [];
    let i = 0;
    for (const singleDepartment of departments) {
      const tabItem = {id: i, name: singleDepartment.name, departmentId: singleDepartment.id};
      this.tabs.push(tabItem);
      i = i + 1;
    }
    if (this.tabs.length > 0) {
      this.activeDepartmentId = this.tabs[0].departmentId;
      this.selectedTabIndex = this.tabs[0].id;
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }

  formColleaguesAxisNames(): any {
    const newColleaguesAxisNames = [];
    if (this.activeExperiment) {
      const isAnonymous = this.activeExperiment.anonymous;
      if (isAnonymous) {
        for (let i = 1; i <= this.activeExperiment.experimentAnswers.length; i++) {
          newColleaguesAxisNames.push('Colleague #' + i);
        }
      } else {
        let filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);

        if (this.activeDepartmentId > 0) {
          filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
        }
        for (const singleColleague of filteredColleagues) {
          const newEntry = singleColleague.firstName + ' ' + singleColleague.lastName;
          newColleaguesAxisNames.push(newEntry);
        }
      }
    }

    return newColleaguesAxisNames;
  }

  onColleaguesStateChange(): void {
    this.showParticipants = false;
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    this.widgetFiltersUpdate();
  }

  onParticipantsStateChange(): void {
    if (this.showParticipants) {
      this.setActiveParticipants();
    }
    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    this.widgetFiltersUpdate();
    // this.formColleagueCards();
  }

  onHeatmapParametersChange(heatParam: HeatmapParameter): void {
    const findParam = this.activeParameters.find(param => param.id === heatParam.parameterId);
    if (findParam) {
      findParam.isActive = heatParam.isActive;
    }
    this.onParametersStateChange();
  }

  onParametersStateChange(): void {
    const newParametersAxisNames = [];
    for (const singleParameter of this.activeParameters) {
      if (singleParameter.isActive) {
        newParametersAxisNames.push(singleParameter.visibleName);
      }
    }

    this.parametersAxisNames = newParametersAxisNames;
    this.widgetFiltersUpdate();
  }

  onExperimentChange(event: any): void {
    // console.log('Active experiment ', this.activeExperiment);
    this.widgetFiltersUpdate();
  }

  onPackagesFilterChange(event: any): void {
    this.heatmapParameters = [];
    this.activeParameters = [];
    this.reloadExperimentInfo();
  }

  formNewMap(): void {
    if (this.activeExperiment) {
      const isAnonymous = this.activeExperiment.anonymous;
      if (isAnonymous) {
        this.formNewMapAnonymous();
      } else {
        this.formNewMapRegular();
      }
    } else {
      this.formTestMap();
    }
  }

  formTestMap(): void {
    const newSeries = [];
    let testCategories = [];
    for (const singleItem of this.activeParameters) {
      if (singleItem.isActive) {
        testCategories.push(singleItem.visibleName);
      }
    }

    if (testCategories.length === 0) {
      testCategories = ['parameter 1', 'parameter 2'];
    }
    for (let i = 0; i < testCategories.length; i++) {
      const columnData = this.getTestColumnData(i);
      const chartColumn = this.formChartColumnSeries(columnData, i);
      newSeries.push(chartColumn);
    }
    this.chartOptions.series = newSeries;
    this.chartOptions.title = {
      text: 'Test data',
      verticalAlign: 'middle',
      style: {
        fontSize: '40',
        color: 'gray'
      }
    };

    setTimeout(() => {
      this.chartOptions.xAxis = {
        categories: testCategories
      };
      this.chartOptions.yAxis = {
        categories: ['colleague 1', 'colleague 2', 'colleague 3', 'colleague 4', 'colleague 5'],
        title: null
      };
      this.chartOptions.colorAxis = this.generateColorAxisArray(testCategories.length);
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  getTestColumnData(column: number): any {
    const columnData = [
      [column, 0, this.chartHelper.precise(Math.random() * 10)],
      [column, 1, this.chartHelper.precise(Math.random() * 10)],
      [column, 2, this.chartHelper.precise(Math.random() * 10)],
      [column, 3, this.chartHelper.precise(Math.random() * 10)],
      [column, 4, this.chartHelper.precise(Math.random() * 10)],
    ];
    return columnData;
  }

  formNewMapAnonymous(): void {
    const newSeries = [];
    const filterParams = this.activeParameters.filter(param => param.isActive === true);
    let xCoord = 0;
    const experimentAnswers = this.activeExperiment.experimentAnswers;
    for (const singleParameter of filterParams) {
      let yCoord = 0;
      const columnData = [];
      for (const singleAnswer of experimentAnswers) {
        const newEntry = this.generateAnonymousEntry(singleAnswer, singleParameter, xCoord, yCoord);
        columnData.push(newEntry);
        yCoord = yCoord + 1;
      }
      const chartColumn = this.formChartColumnSeries(columnData, xCoord);
      newSeries.push(chartColumn);
      xCoord = xCoord + 1;
    }

    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    const colorAxisArray = this.generateColorAxisArray(xCoord);

    this.chartOptions.title = null;
    this.chartOptions.series = newSeries;
    setTimeout(() => {
      this.chartOptions.xAxis = {
        categories: this.parametersAxisNames
      };
      this.chartOptions.yAxis = {
        categories: this.colleaguesAxisNames,
        title: null
      };
      this.chartOptions.colorAxis = colorAxisArray;
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  formNewMapRegular(): void {
    const newSeries = [];
    let filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);

    if (this.activeDepartmentId > 0) {
      filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === this.activeDepartmentId);
    }

    const filterParams = this.activeParameters.filter(param => param.isActive === true);
    let xCoord = 0;
    for (const singleParameter of filterParams) {
      let yCoord = 0;
      const columnData = [];
      for (const singleColleague of filteredColleagues) {
        const newEntry = this.generateDataEntry(singleColleague, singleParameter, xCoord, yCoord);
        if (newEntry) {
          columnData.push(newEntry);
          yCoord = yCoord + 1;
        }
      }
      const chartColumn = this.formChartColumnSeries(columnData, xCoord);
      newSeries.push(chartColumn);
      xCoord = xCoord + 1;
    }

    const newColleaguesAxisNames = this.formColleaguesAxisNames();
    this.colleaguesAxisNames = newColleaguesAxisNames;
    const colorAxisArray = this.generateColorAxisArray(xCoord);

    this.chartOptions.title = null;
    this.chartOptions.series = newSeries;
    setTimeout(() => {
      this.chartOptions.xAxis = {
        categories: this.parametersAxisNames
      };
      this.chartOptions.yAxis = {
        categories: this.colleaguesAxisNames,
        title: null
      };
      this.chartOptions.colorAxis = colorAxisArray;
      this.updateFlag = true;
      this.isSwitchEnabled = true;
    }, 30);
  }

  findNonEmptyTab(): any {
    if (this.tabs && this.tabs.length > 0) {
      for (const singleTab of this.tabs) {
        let filteredColleagues;
        if (this.showParticipants) {
          filteredColleagues = this.activeColleagues;
        } else {
          filteredColleagues = this.activeColleagues.filter(colleague => colleague.isActive === true);
        }

        if (singleTab.departmentId > 0) {
          filteredColleagues = filteredColleagues.filter(colleague => colleague.departmentId === singleTab.departmentId);
        }
        const filterParams = this.activeParameters.filter(param => param.isActive === true);
        let xCoord = 0;
        for (const singleParameter of filterParams) {
          let yCoord = 0;
          for (const singleColleague of filteredColleagues) {
            const newEntry = this.generateDataEntry(singleColleague, singleParameter, xCoord, yCoord);
            if (newEntry) {
              yCoord = yCoord + 1;
              return singleTab;
            }
          }

        }
      }
    }
  }

  generateColorAxisArray(xCount: number): any {
    const colorArray = [];
    for (let i = 0; i < xCount; i++) {
      const heatmapParam = this.heatmapParameters[i];
      const dataClasses = this.formDataClasses(heatmapParam);
      colorArray.push(dataClasses);
    }
    return colorArray;
  }

  formDataClasses(heatmapParam: HeatmapParameter): any {
    const stepsArray = [];
    if (heatmapParam) {
      const minValue = Number(heatmapParam.minValue);
      const maxValue = Number(heatmapParam.maxValue);
      const step = (maxValue - minValue) / 10;
      for (let i = 0; i < 10; i++) {
        stepsArray.push(this.chartHelper.precise(i * step));
      }
    } else {
      const step = 1;
      for (let i = 0; i < 10; i++) {
        stepsArray.push(i * step);
      }
    }
    // const colorsArray = [
    //   '#FF9858', '#F49F4C', '#E8A643', '#DAAD3D', '#CAB33A',
    //   '#B9B93C', '#A6BF43', '#91C34D', '#7AC75A', '#5ECB6A'
    // ];
    const colorsArray = [
      '#c72c2c', '#e65d1d', '#ff8411', '#faa929', '#f4d144',
      '#d1c33c', '#acb334', '#7e9e34', '#689434', '#4c8834'
    ];

    const classesArray = [];
    for (let i = 0; i < 10; i++) {
      const singleItem = {
        from: stepsArray[i],
        to: stepsArray[i + 1],
        color: colorsArray[i]
      };
      classesArray.push(singleItem);
    }

    return {dataClasses: classesArray};
  }

  generateAnonymousEntry(answer: ExperimentAnswer, parameter: ExtendedParameter, x: number, y: number): any {
    if (this.activeParameters) {
      let value = 0;
      const resultParam = answer.colleagueParameters.find(param => param.packageParameterId === parameter.id);
      if (resultParam) {
        value = this.chartHelper.precise(resultParam.value);
      }

      return [x, y, value];
    } else {
      return [x, y, 0];
    }
  }

  generateDataEntry(colleague: ExtendedColleague, parameter: ExtendedParameter, x: number, y: number): any {
    if (this.activeParameters && this.activeExperiment) {
      const experimentAnswers = this.activeExperiment.experimentAnswers;
      let value = 0;
      const filteredAnswers = experimentAnswers.filter(answer => answer.colleagueId === colleague.id);
      for (const singleAnswer of filteredAnswers) {
        const resultParam = singleAnswer.colleagueParameters.find(param => param.packageParameterId === parameter.id);
        if (resultParam) {
          value = this.chartHelper.precise(resultParam.value);
          if (value > 0) {
            return [x, y, value];
          }
        }
      }

      if (value === 0) {
        const relativeAnswers = this.activeExperiment.relativeExperimentAnswers;
        if (relativeAnswers) {
          value = this.getRelativeDataValue(relativeAnswers, colleague, parameter);
          if (value) {
            return [x, y, value];
          }
        }
      }

      return null;
    } else {
      if (this.showParticipants) {
        return null;
      } else {
        return [x, y, 0];
      }
    }
  }

  getRelativeDataValue(relativeAnswers: RelativeExperimentAnswer[], colleague: ExtendedColleague, parameter: ExtendedParameter): number {
    // let value = 0;
    const findAnswer = relativeAnswers.find(answer => answer.colleagueId === colleague.id);
    if (findAnswer) {
      const resultParam = findAnswer.relativeExperimentParameters.find(param => param.packageParameterId === parameter.id);
      if (resultParam) {
        const value = this.chartHelper.precise(resultParam.value);
        return value;
      }
    }
    return null;
  }

  formChartColumnSeries(newData: any, colorAxisCount: number): any {
    return {
      name: '',
      type: 'heatmap',
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        color: '#000000'
      },
      colorAxis: colorAxisCount,
      data: newData
    };
  }

  changeTab(tab: any): void {
    if (this.isSwitchEnabled) {
      this.isSwitchEnabled = false;
      this.activeDepartmentId = tab.departmentId;
      this.selectedTabIndex = tab.id;
      this.formNewMap();
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort(function (a: any, b: any): number {
        return b.id - a.id;
      });
      return sortedExperimens[0];
    }
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  resizeAction(width: number, height: number): void {
    this.chartObject.setSize(width, height, true);
  }

  chartCallback = (chart: any) => {
    this.chartObject = chart;
  }

  onParamsMenuBack(): void {
    this.widgetFiltersUpdate();
  }

  onColleaguesEditMode(): void {
    this.setAllModesFalse();
    this.colleaguesEditMode = true;
  }

  onParametersEditMode(): void {
    this.setAllModesFalse();
    this.parametersEditMode = true;
  }

  onResearchesEditMode(): void {
    this.setAllModesFalse();
    this.researchesEditMode = true;
  }

  onShowChartMode(): void {
    this.setAllModesFalse();
    this.showChartMode = true;
    this.formNewMap();
  }

  setAllModesFalse(): void {
    this.showChartMode = false;
    this.colleaguesEditMode = false;
    this.parametersEditMode = false;
    this.researchesEditMode = false;
  }
}
