<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header">
    <div class="card-header-group">
      <h2 class="title" i18n="@@createQuestionnairePage-title">
        New Questionnaire
      </h2>
      <div class="float-left">
        <label (click)="onChangeMode()" i18n="@@cancelButton">
          Cancel
        </label>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div layout="row" layout-padding>
  <div flex class="left-content-part">
    <mat-card class="mat-title company-info-block">
      <mat-form-field class="card-item">
        <mat-placeholder class="placeholder" i18n="@@placeholder-packageName">Questionnaire Title</mat-placeholder>
        <input matInput [(ngModel)]="packageName" required/>
      </mat-form-field>
      <div class="custom-description">
        <form [formGroup]="editorForm">
          <div class="form-group">
            <quill-editor [modules]="config" (onContentChanged)="maxLength($event)"
                          formControlName="editor"></quill-editor>
            <!--<span *ngIf="editorForm.get('editor').value != null">{{1000 - editorForm.get('editor').value.length}}</span>-->
          </div>
        </form>
      </div>

      <div class="button-block">
        <button [disabled]="!packageName"
                class="create-button full-width-card-button"
                mat-raised-button
                color="accent"
                (click)="onSubmit(); $event.stopPropagation()"
        i18n="@@createButton">
          Create
        </button>
      </div>

    </mat-card>
  </div>
</div>