import { ApiExperimentAnswer } from 'app/_api_models/api-experiment-answer';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { ColleagueParameter } from 'app/_models/colleague-parameter';
import { parseApiColleagueCardAnswer } from 'app/_api_validation/parse-api-colleague-card-answer';
import { parseApiColleagueParameter } from 'app/_api_validation/parse-api-colleague-parameter';
import { parseApiRelativeParameter } from 'app/_api_validation/parse-api-relative-parameter';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { RelativeParameter } from 'app/_models/relative-parameter';

function sortColleagueParameters(apiColleagueParameters: any): any[] {
  const sortedParameters = apiColleagueParameters.sort(function (a: any, b: any): number {
    const moment_a = moment.utc(a.attributes.created_at);
    const moment_b = moment.utc(b.attributes.created_at);
    const value = moment_a.diff(moment_b);
    return value;
  });

  return sortedParameters;
}

export function parseApiExperimentAnswer(apiResponse: any, nestedModels: boolean, included: any): ExperimentAnswer {
  let answerObject;
  if (environment.production) {
    answerObject = apiResponse;
  } else {
    answerObject = ApiExperimentAnswer.check(apiResponse);
  }

  const colleagueCardAnswers: ColleagueCardAnswer[] = [];
  const colleagueParameters: ColleagueParameter[] = [];
  const relativeParameters: RelativeParameter[] = [];
  if (nestedModels && included) {
    const apiCardAnswers = included.filter(item => item.type === 'colleague_card_answer');
    if (apiCardAnswers) {
      for (const singleAnswer of apiCardAnswers) {
        const colleagueAnswer = parseApiColleagueCardAnswer(singleAnswer.attributes);
        colleagueCardAnswers.push(colleagueAnswer);
      }
    }
    const apiColleagueParameters = included.filter(item => (item.type === 'colleague_parameter'));
    if (apiColleagueParameters) {
      const sortedParameters = sortColleagueParameters(apiColleagueParameters);
      for (const singleParameter of sortedParameters) {
        const parameterObject = parseApiColleagueParameter(singleParameter.attributes);
        colleagueParameters.push(parameterObject);
      }
    }
    const apiRelativeParameters = included.filter(item => (item.type === 'relative_parameter'));
    if (apiRelativeParameters) {
      const sortedParameters = sortColleagueParameters(apiRelativeParameters);
      for (const singleParameter of sortedParameters) {
        const parameterObject = parseApiRelativeParameter(singleParameter.attributes);
        relativeParameters.push(parameterObject);
      }
    }
  }

  const newExperimentAnswer: ExperimentAnswer = {
    id: answerObject.id,
    experimentId: answerObject.experiment_id,
    questionnaireId: answerObject.questionnaire_id,
    completed: answerObject.completed,
    calculated: answerObject.calculated,
    anonymous: answerObject.anonymous,
    isRelativeAnswer: answerObject.is_relative_answer,
    colleagueCardAnswers: colleagueCardAnswers,
    colleagueParameters: colleagueParameters,
    relativeParameters: relativeParameters
  };

  if (answerObject.colleague_id) {
    newExperimentAnswer.colleagueId = answerObject.colleague_id;
  }
  if (answerObject.email) {
    newExperimentAnswer.email = answerObject.email;
  }

  return newExperimentAnswer;
}
