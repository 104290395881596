import { DashboardConfig } from 'app/_models/dashboard-config';
import { DashboardWidget } from 'app/_models/dashboard-widget';
import { ApiDashboardConfig } from 'app/_api_models/api-dashboard-config';
import { parseApiDashboardWidget } from './parse-api-dashboard-widget';
import { environment } from '../../environments/environment';

export function parseApiDashboardConfig(apiResponse: any, nestedModels: boolean, included: any): DashboardConfig {
  let dashboardConfigObject;
  if (environment.production) {
    dashboardConfigObject = apiResponse;
  } else {
    dashboardConfigObject = ApiDashboardConfig.check(apiResponse);
  }

  const dashboardWidgets: DashboardWidget[] = [];
  if (nestedModels && included) {
    const apiDashboardWidgets = included.filter(item => item.type === 'dashboard_widget');
    if (apiDashboardWidgets) {
      for (const singleWidget of apiDashboardWidgets) {
        const widgetObject = parseApiDashboardWidget(singleWidget.attributes);
        dashboardWidgets.push(widgetObject);
      }
    }
  }

  const newDashboardConfig: DashboardConfig = {
    id: dashboardConfigObject.id,
    packageId: dashboardConfigObject.package_id,
    dashboardWidgets: dashboardWidgets
  };

  return newDashboardConfig;
}
