<div class="widget-wrapper-style">
  <div *ngIf="!userView" class="menu-style">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <h2 *ngIf="showTextMode" class="separator-text">
    {{separatorText}}
  </h2>

  <form *ngIf="editTextMode" class="separator-form">
    <mat-form-field class="separator-full-width">
      <mat-label>Separator text</mat-label>
      <textarea matInput [(ngModel)]="separatorText" [ngModelOptions]="{ standalone: true }" (blur)="onBlurTitle()">
      </textarea>
      <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>
  </form>
</div>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="editText()">
    <mat-icon>edit</mat-icon>
    <span i18n="@@editText">Edit text</span>
  </button>
  <button mat-menu-item (click)="showText()">
    <mat-icon>visibility</mat-icon>
    <span i18n="@@showText">Show text</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>