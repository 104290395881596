<mat-spinner *ngIf="!isLoaded" style="margin: 20px;"></mat-spinner>

<div class="widget-wrapper-style" *ngIf="isLoaded">
  <div *ngIf="!userView" class="menu-style">
    <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button (click)="removeWidget()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div *ngIf="showChartMode" class="chart-box">
    <nav mat-tab-nav-bar class="tab-links-style">
      <a mat-tab-link *ngFor="let tab of tabs" (click)="changeTab(tab)" [active]="selectedTabIndex == tab.id"
        class="single-link-style">
        {{tab.name}}
      </a>
    </nav>
    <highcharts-chart style="display: flex;" [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag"
      [callbackFunction]="chartCallback" [oneToOne]="true"></highcharts-chart>
  </div>
</div>

<mat-card *ngIf="colleaguesEditMode" class="colleagues-heatmap-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngFor="let colleague of activeColleagues">
    <div class="single-colleague-list">
      {{colleague.firstName}} {{colleague.lastName}}
      <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()"></mat-checkbox>
    </div>
  </div>
  <div class="single-colleague-list">
    <b i18n="@@useParticipants">Use participants </b>
    <mat-checkbox [(ngModel)]="showParticipants" (change)="onParticipantsStateChange()"></mat-checkbox>
  </div>
</mat-card>

<mat-card *ngIf="parametersEditMode" class="colleagues-heatmap-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu" (click)="onParamsMenuBack()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div *ngFor="let singleParam of heatmapParameters">
    <div class="single-colleague-list">
      {{singleParam.visibleName}}
      <mat-form-field style="width: 80px; margin: 2px;">
        <mat-placeholder class="placeholder">Min value</mat-placeholder>
        <input matInput [(ngModel)]="singleParam.minValue" (blur)="onBlurMethod()">
      </mat-form-field>
      <mat-form-field style="width: 80px; margin: 2px">
        <mat-placeholder class="placeholder">Max value</mat-placeholder>
        <input matInput [(ngModel)]="singleParam.maxValue" (blur)="onBlurMethod()">
      </mat-form-field>
      <mat-checkbox [(ngModel)]="singleParam.isActive" (change)="onHeatmapParametersChange(singleParam)"></mat-checkbox>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="researchesEditMode" class="colleagues-heatmap-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
      <mat-radio-button class="researches-radio-button" value="last_research" i18n="@@lastResearch">
        Last research
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" *ngFor="let research of experiments" [value]="research">
        {{research.startDate}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-card *ngIf="packagesEditMode" class="colleagues-heatmap-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activePackageOption" (change)="onPackagesFilterChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let singlePackage of sharedPackagesList"
        [value]="singlePackage">
        {{singlePackage.name}}
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" [value]="null" i18n="@@noFilter">
        No filter
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showParametersList">Show parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="onPackagesEditMode()">
    <mat-icon>ballot</mat-icon>
    <span i18n="@@choosePackage">Choose package</span>
  </button>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>

<mat-menu #menuHide="matMenu">
  <button mat-menu-item (click)="onShowChartMode()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span i18n="@@backButton">Back</span>
  </button>
</mat-menu>