import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: 'snack-bar.component.html',
})

export class SnackBarComponent {
  message: string = 'Snack Bar opened.';
  actionButtonLabel: string = 'Hide';
  action: boolean = true;

  constructor(public snackBar: MatSnackBar) {}

  open(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  openWithTimeout(message: string, timeout: number): void {
    const config = new MatSnackBarConfig();
    config.duration = timeout;

    this.snackBar.open(message, this.action ? this.actionButtonLabel : undefined, config);
  }

  openFromComponent(errorComponent: any, errorData: string[]): void {
    this.snackBar.openFromComponent(errorComponent, {
      verticalPosition: 'bottom',
      duration: 5000,
      data: errorData
    });
  }
}
