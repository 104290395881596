import { Component, OnInit, OnDestroy }	from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { validate } from 'class-validator';
import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar/';
import { UserService } from 'app/_services/user.service';
import { SharedService } from 'app/_services/shared.service';
import { User } from 'app/_models/user';
import { UserValidation } from './user-validation';

@Component({
  selector: 'app-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [SnackBarComponent]
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  userId: number = 0;
  userFirstName: string = '';
  userLastName: string = '';
  userInitialLetters: string = '';
  userEmail: string = '';
  userPassword: string = '';
  color: string = 'accent';
  showNotifications: boolean = true;
  showThumbnails: boolean = false;
  isAdmin: boolean = false;
  infoBar: string[] = [];

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private snackBar: SnackBarComponent,
    private router: Router) { }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getUserInfo(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const user_id = currentUser['user_id'];

    this.userService.getUserInfo(user_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultUser: User) => {
        this.userId = resultUser.id;
        // this.userAvatar = result['avatar'];
        this.userFirstName = resultUser.firstName;
        this.userLastName = resultUser.lastName;
        this.userEmail = resultUser.email;
        this.showThumbnails = resultUser.showThumbnails;
        this.showNotifications = resultUser.showNotifications;

        let isAdmin = false;
        if (resultUser.admin) {
          isAdmin = resultUser.admin;
        }
        this.isAdmin = isAdmin;

        this.formInitialLetters();
      },
      error => console.log(error));
  }

  sendinblueTestEmail(): void {
    console.log('sendinblue test');
    this.userService.sendInBlueTest()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        console.log('Result of test: ', result);
      },
        error => {
          console.log(error);
        });
  }

  formInitialLetters(): void {
    let initialLetters = '';
    if (!this.userLastName) {
      initialLetters = this.userFirstName.substr(0, 1);
    } else {
      initialLetters = (this.userFirstName.substr(0, 1)) + ' ' + (this.userLastName.substr(0, 1));
    }

    this.userInitialLetters = initialLetters.toUpperCase();
  }

  requestPassword(): void {
    this.userService.resetPassword(this.userEmail)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.infoBar.push('Check your email');
        this.openSnackBar('Check your email');
      },
      error => {
        console.log(error);
        this.infoBar.push('Unable to send');
        this.openSnackBar('Unable to send');
      });
  }

  onClose(): void {
    this.router.navigate(['/']);
  }

  onUpdate(): void {
    this.infoBar = [];

    const newUserValidation = new UserValidation();
    newUserValidation.firstName = this.userFirstName;
    newUserValidation.lastName = this.userLastName;
    newUserValidation.email = this.userEmail;
    newUserValidation.password = this.userPassword;

    validate(newUserValidation).then(errors => {
      if (errors.length > 0) {
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
        // console.log('validation failed. errors: ', errors);
      } else {
        this.onUpdateAction();
        // console.log('validation succeed');
      }
    });
  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  onUpdateAction(): void {
    const params = {
        // 'avatar': this.userAvatar,
        'email': this.userEmail,
        'name': this.userFirstName,
        'last_name': this.userLastName,
        'password': this.userPassword,
        'show_notifications': this.showNotifications,
        'show_thumbnails': this.showThumbnails
    };
    this.userService.updateUserInfo(this.userId, params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.formInitialLetters();
        this.sharedService.showThumbnails.next(this.showThumbnails);
        this.infoBar.push('Your account is updated!');
        this.openSnackBar('Your account is updated!');
        this.sharedService.onRefreshUserInfo$.emit();
      },

      error => {
        this.infoBar.push('Unable to update');
        this.openSnackBar('Unable to update');
      });
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

}

