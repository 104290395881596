import { Entity } from 'app/_models/overmind_ds/entity';
import { ApiEntity } from 'app/_api_models/overmind_ds/api-entity';
import { environment } from '../../../environments/environment';

export function parseApiEntity(apiResponse: any): Entity {
  let entity;

  if (environment.production) {
    entity = apiResponse;
  } else {
    entity = ApiEntity.check(apiResponse);
  }

  const newEntity: Entity = {
    id: entity.id,
    uuid: entity.uuid,
    entityTypeId: entity.entity_type_id,
    data: entity.data
  };

  return newEntity;
}
