<mat-spinner *ngIf="!isLoaded" style="margin: 20px;"></mat-spinner>

<div *ngIf="hasFilter" [ngClass]="{'widget-wrapper-style': !isPublicLink}">
  <mat-form-field class="department-choice-style">
    <mat-label i18n="@@dashboard-select-department">Select department</mat-label>
    <mat-select [(ngModel)]="activeDepartmentId" (selectionChange)="setDepartmentValue($event)">
      <ng-container *ngFor="let singleDepartment of localDepartments">
        <mat-option value="{{singleDepartment.id}}">{{singleDepartment.name}}</mat-option>
      </ng-container>
      <mat-option value="0" i18n="@@dashboard-department-no-filter" i18n="@@noFilter">No filter</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="colleaguesCardMode" class="widget-wrapper-for-cards">
    <div *ngIf="!userView" class="menu-style">
      <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button (click)="removeWidget()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div *ngFor="let filteredCard of filteredInfoCards; let lastFilter = last;">
      <ng-container *ngIf="filteredCard.infoCards.length > 0">
        <div class="filtered-parameter-value">
          {{filteredCard.filteredParameterValue}}
        </div>
      </ng-container>
      <div *ngFor="let infoCard of filteredCard.infoCards; let lastCard = last;">
        <mat-card class="info-card">
          <mat-card-header class="colleague-name-block">
            <div mat-card-avatar class="colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
            <mat-card-title class="colleague-name">{{infoCard.fullName}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngFor="let singleParameter of infoCard.parameters">
              <p>
                <span *ngIf="showParametersNames" class="parameter-title">{{singleParameter.name}}: </span>
                <span [innerHtml]="singleParameter.value | safeHtml"></span>
              </p>
            </ng-container>
          </mat-card-content>
        </mat-card>
        <ng-container *ngIf="!lastFilter&&!lastCard">
          <mat-divider class="divider-card-style"></mat-divider>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasRelativeFilter" [ngClass]="{'widget-wrapper-style': !isPublicLink}">
  <mat-form-field class="department-choice-style">
    <mat-label i18n="@@dashboard-select-department">Select department</mat-label>
    <mat-select [(ngModel)]="activeDepartmentId" (selectionChange)="setDepartmentValue($event)">
      <ng-container *ngFor="let singleDepartment of localDepartments">
        <mat-option value="{{singleDepartment.id}}">{{singleDepartment.name}}</mat-option>
      </ng-container>
      <mat-option value="0" i18n="@@dashboard-department-no-filter" i18n="@@noFilter">No filter</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="colleaguesCardMode" class="widget-wrapper-for-cards">
    <div *ngIf="!userView" class="menu-style">
      <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button (click)="removeWidget()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div *ngFor="let relativeCard of filteredRelativeCards; let lastFilter = last;">
      <ng-container *ngIf="relativeCard.infoCards.length > 0">
        <div class="filtered-parameter-value">
          {{relativeCard.relativeParameterName}} for {{relativeCard.relativeColleagueName}}
        </div>
      </ng-container>
      <div *ngFor="let infoCard of relativeCard.infoCards; let lastCard = last;">
        <mat-card class="info-card">
          <mat-card-header class="colleague-name-block">
            <div mat-card-avatar class="colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
            <mat-card-title class="colleague-name">{{infoCard.fullName}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngFor="let singleParameter of infoCard.parameters">
              <p>
                <span *ngIf="showParametersNames" class="parameter-title">{{singleParameter.name}}: </span>
                <span [innerHtml]="singleParameter.value | safeHtml"></span>
              </p>
            </ng-container>
          </mat-card-content>
        </mat-card>
        <ng-container *ngIf="!lastFilter&&!lastCard">
          <mat-divider class="divider-card-style"></mat-divider>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hasFilter&&!hasRelativeFilter" [ngClass]="{'widget-wrapper-style': !isPublicLink}">
  <mat-form-field class="department-choice-style">
    <mat-label i18n="@@dashboard-select-department">Select department</mat-label>
    <mat-select [(ngModel)]="activeDepartmentId" (selectionChange)="setDepartmentValue($event)">
      <ng-container *ngFor="let singleDepartment of localDepartments">
        <mat-option value="{{singleDepartment.id}}">{{singleDepartment.name}}</mat-option>
      </ng-container>
      <mat-option value="0" i18n="@@dashboard-department-no-filter">No filter</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="colleaguesCardMode" class="widget-wrapper-for-cards">
    <div *ngIf="!userView" class="menu-style">
      <button mat-icon-button [matMenuTriggerFor]="menuFirst" aria-label="Menu">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button (click)="removeWidget()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <!-- <div *ngIf="infoCards.length == 0">
      There are no completed answers yet
    </div> -->
    <div *ngFor="let infoCard of infoCards; let last = last;" class="widget-wrapper-for-cards">
      <mat-card class="info-card">
        <mat-card-header class="colleague-name-block">
          <div mat-card-avatar class="colleague-avatar">{{infoCard.fullName | slice : 0 : 1}}</div>
          <mat-card-title class="colleague-name">{{infoCard.fullName}}</mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngFor="let singleParameter of infoCard.parameters">
            <p>
              <span *ngIf="showParametersNames" class="parameter-title">{{singleParameter.name}}: </span>
              <span [innerHtml]="singleParameter.value | safeHtml"></span>
            </p>
          </ng-container>
        </mat-card-content>
      </mat-card>
      <ng-container *ngIf="!last">
        <mat-divider class="divider-card-style"></mat-divider>
      </ng-container>
    </div>
  </div>
</div>

<mat-card *ngIf="colleaguesEditMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngFor="let colleague of activeColleagues">
    <div class="single-colleague-list">
      {{colleague.firstName}} {{colleague.lastName}}
      <mat-checkbox [(ngModel)]="colleague.isActive" (change)="onColleaguesStateChange()"></mat-checkbox>
    </div>
  </div>
  <div class="single-colleague-list">
    <b i18n="@@useParticipants">Use participants </b>
    <mat-checkbox [(ngModel)]="showParticipants" (change)="onParticipantsStateChange()"></mat-checkbox>
  </div>
</mat-card>

<mat-card *ngIf="parametersEditMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div *ngFor="let parameter of activeParameters">
    <div class="single-colleague-list">
      {{parameter.visibleName}}
      <mat-checkbox [(ngModel)]="parameter.isActive" (change)="onParametersStateChange()"></mat-checkbox>
    </div>
  </div>
  <div class="single-colleague-list">
    <b i18n="@@showParametersNames">Show parameters names </b>
    <mat-checkbox [(ngModel)]="showParametersNames" (change)="onParametersStateChange()"></mat-checkbox>
  </div>
  <div class="single-colleague-list">
    <b i18n="@@hideEmptyCards">Hide empty cards </b>
    <mat-checkbox [(ngModel)]="hideEmptyCards" (change)="onParametersStateChange()"></mat-checkbox>
  </div>
</mat-card>

<mat-card *ngIf="researchesEditMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activeExperimentOption" (change)="onExperimentChange($event)">
      <mat-radio-button class="researches-radio-button" value="last_research" i18n="@@lastResearch">
        Last research
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" *ngFor="let research of experiments" [value]="research">
        {{research.startDate}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-card *ngIf="useRelativeMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="parameterRelativeFilter" (change)="onRelativeFilterChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let parameter of relativeParameters"
        [value]="parameter">
        {{parameter.visibleName}}
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" [value]="null" i18n="@@noParameter">
        No parameter
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-card *ngIf="parameterSortMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="parameterFilter" (change)="onParameterFilterChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let parameter of activeParameters" [value]="parameter">
        {{parameter.visibleName}}
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" [value]="null" i18n="@@noFilter">
        No filter
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-card *ngIf="packagesEditMode" class="colleagues-list">
  <div class="button-menu">
    <button mat-icon-button [matMenuTriggerFor]="menuHide" aria-label="Menu">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="single-colleague-list">
    <mat-radio-group [(ngModel)]="activePackageOption" (change)="onPackagesFilterChange($event)">
      <mat-radio-button class="researches-radio-button" *ngFor="let singlePackage of sharedPackagesList"
        [value]="singlePackage">
        {{singlePackage.name}}
      </mat-radio-button>
      <mat-radio-button class="researches-radio-button" [value]="null" i18n="@@noFilter">
        No filter
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>

<mat-menu #menuFirst="matMenu">
  <button mat-menu-item (click)="onColleaguesEditMode()">
    <mat-icon>people_alt</mat-icon>
    <span i18n="@@showColleaguesList">Show colleagues list</span>
  </button>
  <button mat-menu-item (click)="onParametersEditMode()">
    <mat-icon>art_track</mat-icon>
    <span i18n="@@showParametersList">Show parameters list</span>
  </button>
  <button mat-menu-item (click)="onResearchesEditMode()">
    <mat-icon>assignment</mat-icon>
    <span i18n="@@chooseResearch">Choose research</span>
  </button>
  <button mat-menu-item (click)="onPackagesEditMode()">
    <mat-icon>ballot</mat-icon>
    <span i18n="@@choosePackage">Choose package</span>
  </button>
  <ng-container *ngIf="!multiplePackagesMode">
    <button mat-menu-item (click)="onRelativeUseMode()">
      <mat-icon>sort_by_alpha</mat-icon>
      <span i18n="@@useRelativeParameters">Use relative parameter</span>
    </button>
    <button mat-menu-item (click)="onParameterSortMode()">
      <mat-icon>sort_by_alpha</mat-icon>
      <span i18n="@@sortByParameter">Sort by parameter</span>
    </button>
  </ng-container>
  <button mat-menu-item (click)="widgetUp()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span i18n="@@moveUp">Move up</span>
  </button>
  <button mat-menu-item (click)="widgetDown()">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <span i18n="@@moveDown">Move down</span>
  </button>
</mat-menu>

<mat-menu #menuHide="matMenu">
  <button mat-menu-item (click)="onColleaguesCardMode()">
    <mat-icon>keyboard_backspace</mat-icon>
    <span i18n="@@backButton">Back</span>
  </button>
</mat-menu>