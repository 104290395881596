import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';

import { AuthenticationService } from '../authentication.service';

import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ParameterData } from 'app/_models/overmind_ds/parameter-data';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';
import { parseApiExtendedParameter } from 'app/_api_validation/overmind-ds/parse-api-extended-parameter';
import { parseApiParameterData } from 'app/_api_validation/overmind-ds/parse-api-parameter-data';

@Injectable({
  providedIn: 'root'
})
export class ParameterDataService {
  private parametersDataUrl: string = environment.OVERMIND_DS + '/parameters_data';
  private entityParametersUrl: string = environment.OVERMIND_DS + '/entity_parameters_data';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    return httpOptions;
  }

  createNewParameterData(parameter: ParameterData): Observable<ParameterData> {
    const data = {
      parameter_uuid: parameter.parameterUuid,
      author_uuid: parameter.authorUuid,
      entity_uuid: parameter.entityUuid,
      source_uuid: parameter.sourceUuid,
      value: parameter.value,
      data: parameter.data
    };
    
    return this.http
      .post<Parameter>(this.parametersDataUrl, data, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseParameterData(response)),
        catchError(this.handleError)
      );
  }

  getEntityParametersData(uuid: string): Observable<ExtendedParameter[]> {
    const url = `${this.entityParametersUrl}/${uuid}`;

    return this.http
      .get<ParameterData>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (response !== null) {
            return this.parseMultipleParameters(response);
          } else {
            return null;
          }
        }),
        catchError(this.handleError)
      );
  }

  private parseParameterData(apiResponse: any): ParameterData {
    return parseApiParameterData(apiResponse);
  }

  private parseExtendedParameter(apiResponse: any): ExtendedParameter {
    return parseApiExtendedParameter(apiResponse);
  }

  private parseMultipleParameters(apiResponse: any): ParameterData[] {
    const mappedData = apiResponse.map(parameter => this.parseExtendedParameter(parameter));
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error);
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }
}
