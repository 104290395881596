import { Experiment } from 'app/_models/experiment';
import { ApiExperiment } from 'app/_api_models/api-experiment';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { parseApiExperimentAnswer } from 'app/_api_validation/parse-api-experiment-answer';
import { parseApiExperimentParameter } from 'app/_api_validation/parse-api-experiment-parameter';
import { parseApiRelativeExperimentAnswer } from 'app/_api_validation/parse-api-relative-experiment-answer';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';

function sortExperimentParameters(apiExperimentParameters: any): any[] {
  const sortedParameters = apiExperimentParameters.sort(function (a: any, b: any): number {
    const moment_a = moment.utc(a.attributes.created_at);
    const moment_b = moment.utc(b.attributes.created_at);
    const value = moment_a.diff(moment_b);
    return value;
  });

  return sortedParameters;
}

function checkIncludedType(itemType: string): boolean {
  if (itemType === 'colleague_parameter') {
    return true;
  } else if (itemType === 'colleague_card_answer') {
    return true;
  } else if (itemType === 'relative_parameter') {
    return true;
  } else if (itemType === 'relative_experiment_parameter') {
    return true;
  } else {
    return false;
  }
}

export function parseApiExperimentVerbose(apiResponse: any, nestedModels: boolean, included: any): Experiment {
  let experimentObject;
  if (environment.production) {
    experimentObject = apiResponse;
  } else {
    experimentObject = ApiExperiment.check(apiResponse);
  }

  const experimentAnswers: ExperimentAnswer[] = [];
  const experimentParameters: ExperimentParameter[] = [];
  const relativeAnswers: RelativeExperimentAnswer[] = [];
  if (nestedModels && included) {
    const apiExperimentAnswers = included.filter(item => item.type === 'experiment_answer');
    if (apiExperimentAnswers) {
      const apiFilteredIncluded = included.filter(item => checkIncludedType(item.type));
      for (const singleAnswer of apiExperimentAnswers) {
        const apiFiltered = apiFilteredIncluded.filter(item => item.attributes.experiment_answer_id === singleAnswer.attributes.id);
        const answerObject = parseApiExperimentAnswer(singleAnswer.attributes, true, apiFiltered);
        experimentAnswers.push(answerObject);
      }
    }
    const apiExperimentParameters = included.filter(item => item.type === 'experiment_parameter');
    if (apiExperimentParameters) {
      const sortedApiParameters = sortExperimentParameters(apiExperimentParameters);
      for (const singleParameter of sortedApiParameters) {
        const parameterObject = parseApiExperimentParameter(singleParameter.attributes);
        experimentParameters.push(parameterObject);
      }
    }
    const apiRelativeExperimentAnswers = included.filter(item => item.type === 'relative_experiment_answer');
    if (apiRelativeExperimentAnswers) {
      const apiFilteredIncluded = included.filter(item => checkIncludedType(item.type));
      for (const singleAnswer of apiRelativeExperimentAnswers) {
        const apiFiltered = apiFilteredIncluded.filter(item => item.attributes.relative_experiment_answer_id === singleAnswer.attributes.id);
        const answerObject = parseApiRelativeExperimentAnswer(singleAnswer.attributes, true, apiFiltered);
        relativeAnswers.push(answerObject);
      }
    }
  }

  const newExperiment: Experiment = {
    id: experimentObject.id,
    packageId: experimentObject.package_id,
    startDate: experimentObject.start_date,
    finishDate: experimentObject.finish_date,
    completed: experimentObject.completed,
    calculated: experimentObject.calculated,
    questionnaireId: experimentObject.questionnaire_id,
    anonymous: experimentObject.anonymous,
    experimentAnswers: experimentAnswers,
    experimentParameters: experimentParameters,
    relativeExperimentAnswers: relativeAnswers
  };

  return newExperiment;
}
