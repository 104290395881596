import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { ApiColleagueCardAnswer } from 'app/_api_models/api-colleague-card-answer';
import { environment } from '../../environments/environment';

export function parseApiColleagueCardAnswer(apiResponse: any): ColleagueCardAnswer {
  let answerObject;
  if (environment.production) {
    answerObject = apiResponse;
  } else {
    answerObject = ApiColleagueCardAnswer.check(apiResponse);
  }

  const newCardAnswer: ColleagueCardAnswer = {
    id: answerObject.id,
    experimentAnswerId: answerObject.experiment_answer_id,
    questionnaireCardId: answerObject.questionnaire_card_id,
    chosenOptionId: answerObject.chosen_option_id,
    orderPosition: answerObject.order_position,
    userInput: answerObject.user_input,
    anonymous: answerObject.anonymous,
    relativeColleagueId: answerObject.relative_colleague_id
  };

  if (answerObject.colleague_id) {
    newCardAnswer.colleagueId = answerObject.colleague_id;
  }

  return newCardAnswer;
}

