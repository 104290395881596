import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { SnackBarComponent } from 'app/snack-bar';
import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';

import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { SharedService } from 'app/_services/shared.service';

import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';

import * as moment from 'moment-timezone';
import { Package } from 'app/_models/package';
import { Questionnaire } from 'app/_models/questionnaire';
import { PackageParameter } from 'app/_models/package-parameter';
import { UserService } from 'app/_services/user.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { User } from 'app/_models/user';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';

const EXPERIMENTS_INITIAL_LIMIT = 3

class Research extends Experiment {
  orderPosition: number = 0;
}

@Component({
  selector: 'app-package-researches',
  templateUrl: './package-researches.component.html',
  styleUrls: ['./package-researches.component.scss'],
  providers: [SnackBarComponent],
})
export class PackageResearchesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  parametersList: PackageParameter[] = [];
  commonParameters: PackageParameter[] = [];
  individualParameters: PackageParameter[] = [];
  relativeParameters: PackageParameter[] = [];
  localResearchesList: Research[] = [];
  questionnaireExperiments: Experiment[] = [];

  alreadyLoadedExperiments: number[] = [];
  currentQuestionnaireStatus: string;
  isListReverse: boolean = false;
  isBootstrapped: boolean = false;
  // componentInitialized: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private experimentService: ExperimentService,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private sharedPackageService: SharedPackageService,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.getPackageInfo(packageId);
      });

    this.sharedPackageService.packageBootstrapped$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isBootstrapped: boolean) => {
        this.isBootstrapped = isBootstrapped;
      });

    this.sharedService.currentTabId.next(3);
  }

  refreshPackages(): void {
    this.sharedPackageService.experiments$.next([]);
    const packageId = this.sharedPackageService.currentPackageId$.getValue();
    this.getPackageInfo(packageId);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPackageInfo(packageId: number): void {
    if (packageId) {
      this.getUserInfo();
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
            if (packageInfo && packageInfo.questionnaire) {
              this.parametersList = packageInfo.packageParameters;
              this.separateParameters();
              this.getQuestionnaire(packageInfo.questionnaire.id);
            }
          },
          error => {
            console.log('Package info', error);
          });
    }
  }

  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
        },
          error => {
            this.sharedService.checkSignatureExpired(error);
          });
    }
  }

  getQuestionnaire(questionnaireId: number): void {
    this.questionnaireService.getQuestionnaireBrief(questionnaireId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultQuestionnaire: Questionnaire) => {
        // console.log('Questionnaire ', resultQuestionnaire);
        this.currentQuestionnaireStatus = resultQuestionnaire.activeStatus;
        this.questionnaireExperiments = resultQuestionnaire.experiments;

        const cachedExperiments = this.sharedPackageService.experiments$.getValue();
        if (cachedExperiments.length > 0) {
          this.updateExperimentsList();
        } else {
          this.loadInitialExperiments(resultQuestionnaire.experiments);
        }
      },
      error => {
        console.log('Package info', error);
      });
  }

  loadInitialExperiments(questionnaireExperiments: Experiment[]): void {
    this.loadMultipleExperiments(questionnaireExperiments, EXPERIMENTS_INITIAL_LIMIT);
  }

  updateExperimentsList(): void {
    const questionnaireExperiments = this.questionnaireExperiments;
    const cachedExperiments = this.sharedPackageService.experiments$.getValue();
    let resultExperimentsList = [];
    for (const singleExperiment of questionnaireExperiments) {
      const findVerboseExperiment = cachedExperiments.find(exp => exp.id === singleExperiment.id);
      if (findVerboseExperiment) {
        resultExperimentsList.push(findVerboseExperiment);
      } else {
        resultExperimentsList.push(singleExperiment);
      }
    }

    // console.log('Cached experiments ', resultExperimentsList);

    resultExperimentsList = resultExperimentsList.sort(function (a: any, b: any): number {
      return b.id - a.id;
    });
    this.getExperimentInfo(resultExperimentsList);
  }

  loadSingleExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperimentVerbose(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultExp: Experiment) => {
          resolve(resultExp);
        }, error => {
          reject(error);
        });
    });
  }

  separateParameters(): void {
    for (const singleParameter of this.parametersList) {
      const groupName = singleParameter.groupName;
      if (groupName === 'individual') {
        this.individualParameters.push(singleParameter);
      } else if (groupName === 'common') {
        this.commonParameters.push(singleParameter);
      } else if (groupName === 'relative') {
        this.relativeParameters.push(singleParameter);
      }
    }
  }

  onPanelClick(research: Research): void {
    const researchId = research.id;
    const findLoadedExperiment = this.alreadyLoadedExperiments.find(expId => expId === researchId);
    if (!findLoadedExperiment) {
      this.alreadyLoadedExperiments.push(researchId);
      const cachedExperiments = this.sharedPackageService.experiments$.getValue();
      const alreadyLoaded = cachedExperiments.find(exp => exp.id === researchId);
      if (!alreadyLoaded) {
        const singlePromise = this.loadSingleExperiment(researchId);
        singlePromise.then((resultExperiment: Experiment) => {
          const newExperiments = cachedExperiments.concat(resultExperiment);
          this.sharedPackageService.experiments$.next(newExperiments);
          this.updateExperimentsList();
        }).catch(error => console.log(error));
      }
    }
  }

  getExperimentInfo(experiments: Experiment[]): void {
    this.localResearchesList = [];

    for (const experiment of experiments) {
      if (experiment.anonymous) {
        const sortedAnswers = this.sortAnswers(experiment.experimentAnswers);
        experiment.experimentAnswers = sortedAnswers;
      } else {
        const newAnswers = this.editExperimentAnswers(experiment);
        experiment.experimentAnswers = newAnswers;
      }
      // console.log('ADD LOCAL RESEARCH ', experiment);
      this.addLocalResearch(experiment);
    }
    this.localResearchesList.sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });
    this.setOrder(this.localResearchesList);
  }

  editExperimentAnswers(experiment: Experiment): ExperimentAnswer[] {
    const newExperimentAnswers = [];
    const answers = experiment.experimentAnswers;
    const uniqueEmails = [];
    for (const singleAnswer of answers) {
      const emailExist = uniqueEmails.find(singleEmail => singleEmail === singleAnswer.email);
      if (!emailExist) {
        uniqueEmails.push(singleAnswer.email);
      }
    }

    for (const singleEmail of uniqueEmails) {
      const filteredAnswers = answers.filter(answer => answer.email === singleEmail);
      let answerCount = 1;
      const sortedAnswers = filteredAnswers.sort(function (a: any, b: any): number {
        return a.id - b.id;
      });
      for (const singleAnswer of sortedAnswers) {
        if (sortedAnswers.length > 1) {
          singleAnswer.email = singleAnswer.email + ' #' + answerCount;
          answerCount = answerCount + 1;
          newExperimentAnswers.push(singleAnswer);
        } else {
          newExperimentAnswers.push(singleAnswer);
        }
      }
    }

    return newExperimentAnswers;
  }

  sortAnswers(experimentAnswers: ExperimentAnswer[]): ExperimentAnswer[] {
    const anonimousAnswers = experimentAnswers.sort(function(a: any, b: any): number {
      return a.completed - b.completed;
    });

    anonimousAnswers.reverse();
    return anonimousAnswers;
  }

  addLocalResearch(experiment: Experiment): void {
    const timeZone = moment.tz.guess();
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';
    const startDate = moment(experiment.startDate).tz(timeZone).format(timeFormat);
    const finishDate = moment(experiment.finishDate).tz(timeZone).format(timeFormat);

    const localResearch: Research = {
      startDate: startDate,
      finishDate: finishDate,
      orderPosition: 0,
      ...experiment,
    };

    // console.log('Local research ', localResearch);

    this.localResearchesList.push(localResearch);
  }

  checkIsActive(reseach: Research): boolean {
    if (reseach && !reseach.completed) {
      const today = new Date();
      const startDate = new Date(reseach.startDate);

      if (startDate > today) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onAnswerDetails(experimentAnswer: ExperimentAnswer): void {
    // const experimentId = experimentAnswer.experimentId;
    // const colleagueId = experimentAnswer.colleagueId;
    const experimentAnswerId = experimentAnswer.id;
    const win = window.open(`/experiment_details/${experimentAnswerId}`, '_blank');
    win.focus();
  }

  onRelativeAnswerDetails(relativeAnswer: RelativeExperimentAnswer): void {
    const relativeAnswerId = relativeAnswer.id;
    const win = window.open(`/relative_experiment_details/${relativeAnswerId}`, '_blank');
    win.focus();
  }

  finishResearch(research: Research): void {
    if (research) {
      const timeZone = moment.tz.guess();
      const timeFormat = 'YYYY-MM-DD HH:mm:ss';

      this.experimentService.finishExperiment(research.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((apiExperiment: Experiment) => {
          if (apiExperiment) {
            research.completed = true;
            const finishDate = moment(apiExperiment.finishDate).tz(timeZone).format(timeFormat);
            research.finishDate = finishDate;
            this.setQuestionnaireForCalculation(apiExperiment.questionnaireId);
          }
        },
        error => {
          console.log('Experiment error ', error);
        });
    }
  }

  setQuestionnaireForCalculation(questionnaireId: number): void {
    if (this.currentQuestionnaireStatus !== 'active') {
      this.questionnaireService.updateQuestionnaireResearchStatus(questionnaireId, 'not_calculated')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: any) => {
          // console.log('Result calculation? ', result);
        },
        error => {
          console.log('Experiment error ', error);
        });
    }
  }

  removeResearch(research: Research): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Remove research?',
        dialogSubtitle: 'Are you sure you want to remove research?',
        dialogConfirmText: 'Remove',
        dialogConfirmType: 'research',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onResearchDelete(research);
      }
    });
  }

  onResearchDelete(research: Research): void {
    if (research) {
      this.experimentService.removeExperiment(research.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((apiExperiment: Experiment) => {
          if (apiExperiment) {
            const newResearches = this.localResearchesList.filter(localResearch => localResearch.id !== apiExperiment.id);
            this.localResearchesList = newResearches;
            this.setOrder(newResearches);
            this.sharedPackageService.experiments$.next(newResearches);
            this.sharedPackageService.onResearchUpdate$.emit();
          }
        },
        error => {
          console.log('Experiment error ', error);
        });
    }
  }

  resetResearchCalculation(research: Research): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Reset calculation?',
        dialogSubtitle: 'Research results will be erased and calculated again',
        dialogConfirmText: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onResetResearchCalculation(research, false);
      }
    });
  }

  resetResearchAnswers(research: Research): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Reset calculation?',
        dialogSubtitle: 'Research results will be erased and calculated again',
        dialogConfirmText: 'Yes',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onResetResearchCalculation(research, true);
      }
    });
  }

  onResetResearchCalculation(research: Research, onlyAnswers: boolean): void {
    research.experimentParameters = [];
    research.relativeExperimentAnswers = [];
    research.calculated = false;
    for (const singleAnswer of research.experimentAnswers) {
      singleAnswer.calculated = false;
    }
    this.experimentService.resetExperiment(research.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((apiExperiment: any) => {
        // console.log('Result api ', apiExperiment);
      }, error => console.log(error));
  }

  // onResetResearchCalculation(research: Research, onlyAnswers: boolean): void {
  //   if (research) {
  //     const experimentParameters = research.experimentParameters;
  //     const promisesList = [];
  //     const colleagueParameters = [];
  //     const relativeParameters = [];
  //     for (const singleAnswer of research.experimentAnswers) {
  //       const promise = this.resetExperimentAnswer(singleAnswer.id);
  //       promisesList.push(promise);
  //       for (const singleParameter of singleAnswer.colleagueParameters) {
  //         colleagueParameters.push(singleParameter);
  //       }
  //       for (const singleParameter of singleAnswer.relativeParameters) {
  //         relativeParameters.push(singleParameter);
  //       }
  //     }

  //     for (const singleParameter of experimentParameters) {
  //       const promise = this.removeExperimentParameter(singleParameter.id);
  //       promisesList.push(promise);
  //     }

  //     for (const singleParameter of colleagueParameters) {
  //       const promise = this.removeColleagueParameter(singleParameter.id);
  //       promisesList.push(promise);
  //     }

  //     for (const singleParameter of relativeParameters) {
  //       const promise = this.removeRelativeParameter(singleParameter.id);
  //       promisesList.push(promise);
  //     }

  //     for (const singleAnswer of research.relativeExperimentAnswers) {
  //       const promise = this.removeRelativeExperimentAnswer(singleAnswer.id);
  //       promisesList.push(promise);
  //     }

  //     Promise.all(promisesList)
  //       .then((result: any) => {
  //         if (onlyAnswers) {
  //           this.refreshPackages();
  //         } else {
  //           this.experimentService.resetExperimentCalculated(research.id)
  //             .pipe(takeUntil(this.ngUnsubscribe))
  //             .subscribe((apiExperiment: Experiment) => {
  //               if (apiExperiment) {
  //                 this.setQuestionnaireForCalculation(apiExperiment.questionnaireId);
  //                 this.refreshPackages();
  //               }
  //             }, error => console.log(error));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log('Catch all', error);
  //       });
  //   }
  // }

  resetExperimentAnswer(answerId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.resetExperimentAnswerCalculated(answerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((answer: ExperimentAnswer) => {
          resolve(answer);
        }, error => {
          reject(error);
        });
    });
  }

  removeExperimentParameter(parameterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.removeExperimentParameter(parameterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  removeColleagueParameter(parameterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.removeColleagueParameter(parameterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  removeRelativeParameter(parameterId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.removeRelativeParameter(parameterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  removeRelativeExperimentAnswer(answerId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.removeRelativeExperimentAnswer(answerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
          resolve(response)
        }, error => {
          reject(error);
        });
    });
  }

  sortResearch(): void {
    this.isListReverse = !this.isListReverse;
    this.localResearchesList.reverse();
  }

  setOrder(researches: Research[]): void {
    this.localResearchesList = [];
    let order = researches.length;
    for (const research of researches) {
      research.orderPosition = order;
      this.localResearchesList.push(research);
      order--;
    }
  }

  loadAllExperiments(): void {
    const experimentsCount = this.questionnaireExperiments.length;
    if (experimentsCount > 0) {
      this.loadMultipleExperiments(this.questionnaireExperiments, experimentsCount);
    }
  }

  loadMultipleExperiments(questionnaireExperiments: Experiment[], limitCount: number): void {
    const sortedExperiments = questionnaireExperiments.sort(function (a: any, b: any): number {
      return b.id - a.id;
    });

    const experimentsToLoad = [];
    const cachedExperiments = this.sharedPackageService.experiments$.getValue();
    for (let i = 0; i < limitCount; i++) {
      if (sortedExperiments[i]) {
        const findExperiment = cachedExperiments.find(exp => exp.id === sortedExperiments[i].id);
        if (!findExperiment) {
          experimentsToLoad.push(sortedExperiments[i].id);
        }
      }
    }
    const promisesList = [];
    for (const singleExperimentId of experimentsToLoad) {
      const singlePromise = this.loadSingleVerboseExperiment(singleExperimentId);
      promisesList.push(singlePromise);
    }
    Promise.all(promisesList).then(resultExperiments => {
      const newExperiments = cachedExperiments.concat(resultExperiments);
      const sortedVerboseExperiments = newExperiments.sort(function (a: any, b: any): number {
        return a.id - b.id;
      });
      this.loadBriefExperiments(sortedExperiments, sortedVerboseExperiments);
      this.sharedPackageService.experiments$.next(sortedVerboseExperiments);
    }).catch(error => console.log(error));
  }

  loadBriefExperiments(allExperiments: Experiment[], verboseExperiments: Experiment[]): void {
    const experimentsToLoad = [];
    for (const singleExperiment of allExperiments) {
      const findExperiment = verboseExperiments.find(exp => exp.id === singleExperiment.id);
      if (!findExperiment) {
        experimentsToLoad.push(singleExperiment.id);
      }
    }

    const promisesList = [];
    for (const singleExperimentId of experimentsToLoad) {
      const singlePromise = this.loadSingleBriefExperiment(singleExperimentId);
      promisesList.push(singlePromise);
    }
    Promise.all(promisesList).then(resultExperiments => {
      const briefExperiments = verboseExperiments.concat(resultExperiments);
      const sortedBriefExperiments = briefExperiments.sort(function (a: any, b: any): number {
        return a.id - b.id;
      });
      this.updateExperimentsList();
      this.sharedPackageService.packageExperimentsBrief$.next(sortedBriefExperiments);
      this.sharedPackageService.packageBootstrapped$.next(true);
    }).catch(error => console.log(error));
  }

  loadSingleBriefExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperiment(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultExp: Experiment) => {
          resolve(resultExp);
        }, error => {
          reject(error);
        });
    });
  }

  loadSingleVerboseExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperimentVerbose(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultExp: Experiment) => {
          resolve(resultExp);
        }, error => {
          reject(error);
        });
    });
  }

}
