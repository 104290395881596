import {Component, OnInit, OnChanges, OnDestroy,
  Input, EventEmitter, Output, SimpleChanges
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { CardAction } from 'app/_models/card-action';
import { CardOption } from 'app/_models/card-option';
import { ScoreDivision } from 'app/_models/score-division';
import { PackageParameter } from 'app/_models/package-parameter';

@Component({
  selector: 'app-edit-score-question',
  templateUrl: './edit-score-question.component.html',
  styleUrls: [
    './edit-score-question.component.scss',
    '../../../package-details.component.scss'
  ]
})
export class EditScoreQuestionComponent implements OnInit, OnChanges, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() questionText: string;
  @Input() cardType: string;
  @Input() editedScoreDivisions: ScoreDivision[];
  @Input() editedInputOption: CardOption;
  @Input() colorScheme: string;
  @Output() onSaveQuestion: EventEmitter<any> = new EventEmitter<any>();

  @Input() individualParameters: PackageParameter[] = [];
  @Input() commonParameters: PackageParameter[] = [];
  @Input() relativeParameters: PackageParameter[] = [];
  localScoreDivisions: ScoreDivision[];
  localCardOption: CardOption;
  tooltipActionText: string = `Введите данные в формате 'Параметр1 -> Параметр1 + N(значение для вычисления)'
    ('Параметр1 ->' задается в левом поле)`;
  tooltipGotoText: string = `Введите номер вопроса, к которому нужно перейти в формате '#N', где N - номер вопроса`;

  divisionsColorScheme: string = 'default';
  currentDivisionsLength: number;
  scoreSteps: number[] = Array.from(Array(8), (x , i) => i + 3);

  constructor(
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.setLocalDivisions(this.editedScoreDivisions);
    this.setLocalOption(this.editedInputOption);
    this.divisionsColorScheme = this.colorScheme;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.editedScoreDivisions && changes.editedInputOption) {
      this.setLocalDivisions(changes.editedScoreDivisions.currentValue);
      this.setLocalOption(changes.editedInputOption.currentValue);
    }
  }

  setLocalDivisions(inputCardDivisions: ScoreDivision[]): void {
    const localScoreDivisions: ScoreDivision[] = [];
    for (const singleDivision of inputCardDivisions) {
      const newDivision = Object.assign({}, singleDivision);
      localScoreDivisions.push(newDivision);
    }

    localScoreDivisions.sort(function (a: any, b: any): number {
      return a.value - b.value;
    });

    this.localScoreDivisions = localScoreDivisions;
    this.currentDivisionsLength = this.localScoreDivisions.length;
  }

  setLocalOption(inputCardOption: CardOption): void {
    this.localCardOption = inputCardOption;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  dropCardAction(event: CdkDragDrop<string[]>, cardActions: CardAction[]): void {
    moveItemInArray(cardActions, event.previousIndex, event.currentIndex);
    const movedCardActions = cardActions[event.previousIndex];
    this.updateCardActionPosition(movedCardActions, event.previousIndex + 1);
    this.renumerateCardActions(cardActions);
  }

  renumerateCardActions(cardActions: CardAction[]): void {
    let nextPosition = 1;
    for (const action of cardActions) {
      action.orderPosition = nextPosition;
      nextPosition = nextPosition + 1;
    }
  }

  updateCardActionPosition(targetAction: CardAction, newActionIndex: number): void {
    if (targetAction && newActionIndex) {
      targetAction.orderPosition = newActionIndex;
    }
  }

  setActionValue(event: any, cardAction: CardAction): void {
    // console.log('SET ACTION VALUE: ', event);
  }

  updateDivisionsLabelCount(): void {
    const existingLength = this.localScoreDivisions.length;

    if (this.currentDivisionsLength > existingLength) {
      for (let i = existingLength + 1; i < this.currentDivisionsLength + 1; i++) {
        const newDivision = {
          id: 0,
          label: '',
          value: i,
        };

        this.localScoreDivisions.push(newDivision);
      }
    } else {
      const newScoreDivisions = this.localScoreDivisions.filter(division =>
        division.value <= this.currentDivisionsLength);
      this.localScoreDivisions = newScoreDivisions;
    }
  }

  addCardOptionAction(createdOption: CardOption): void {
    const orderPosition = createdOption.cardActions.length + 1;

    const newAction: CardAction = {
      id: 0,
      text: '',
      parameter: '',
      orderPosition: orderPosition,
    };
    createdOption.cardActions.push(newAction);
  }

  deleteOptionAction(activeOption: CardOption, cardActionIndex: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        activeOption.cardActions.splice(cardActionIndex, 1);
        this.renumerateCardActions(activeOption.cardActions);
      }
    });
  }

  saveLocalQuestion(event: any): void {
    this.onSaveQuestion.emit(event);
  }

  getScoreInputOption(): CardOption {
    return this.localCardOption;
  }

  getScoreDivisions(): ScoreDivision[] {
    return this.localScoreDivisions;
  }

  getDivisionsColorScheme(): string {
    return this.divisionsColorScheme;
  }

}
