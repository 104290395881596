import {Component, OnInit, OnDestroy} from '@angular/core';
import {
  FormBuilder, FormGroup,
  FormControl, FormGroupDirective,
  NgForm, Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { validate } from 'class-validator';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { ColleagueValidation } from '../../colleagues-page/colleague-validation';
import { SnackbarErrorInfoComponent } from '../../colleagues-page/snackbar-error-info';
import { SnackBarComponent } from 'app/snack-bar';
import { CompanyService } from 'app/_services/company.service';
import { SharedColleagueService } from 'app/_services/shared-colleague.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';
import { UtilsService } from 'app/_services/utils.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-colleagues-edit-colleague',
  templateUrl: './colleagues-edit-colleague.component.html',
  styleUrls: ['./colleagues-edit-colleague.component.scss'],
  providers: [SnackBarComponent],
})

export class ColleaguesEditColleagueComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  personForm: FormGroup;
  matcher: MyErrorStateMatcher = new MyErrorStateMatcher();
  infoBar: string[] = [];
  departments: Department[] = [];

  person: Colleague;
  companyId: number;
  isLoading: boolean = true;

  msgColleagueSaved: string = 'Colleague information has been updated';

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: SnackBarComponent,
    private utilsService: UtilsService,
    private companyService: CompanyService,
    private sharedColleagueService: SharedColleagueService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) {
    this.personForm = this.formBuilder.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      slackUserId: [''],
      email: [''],
      departmentId: [0]
    }, { validator: this.checkEmail });
  }

  ngOnInit(): void {
    this.sharedColleagueService.colleague$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((participant: Colleague) => {
        if (participant) {
          this.person = participant;
          this.companyId = participant.companyId;
          this.personForm.setValue({
            id: this.person.id || -1,
            firstName: this.person.firstName || '',
            lastName: this.person.lastName || '',
            email: this.person.email || '',
            slackUserId: this.person.slackUserId || '',
            departmentId: this.person.departmentId || 0
          });
          this.getCompanyDepartments();
          this.isLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkEmail(group: FormGroup): any {
    const emailGroup = group.controls.email;
    if (emailGroup) {
      if (Validators.required(emailGroup)) {
        return { required: true };
      } else if (Validators.email(emailGroup)) {
        return { invalidEmail: true };
      }
    }

    return null;
  }

  setOptionValue(event: any): void {
    if (event.value) {
      this.personForm.controls['departmentId'].setValue(event.value);
    }
  }

  onValidatePerson(): void {
    const dataModel = this.personForm.value;
    const newColleagueValidation = new ColleagueValidation();
    newColleagueValidation.firstName = dataModel.firstName;
    newColleagueValidation.lastName = dataModel.lastName;
    newColleagueValidation.email = dataModel.email;

    this.infoBar = [];
    validate(newColleagueValidation).then(errors => {
      if (errors.length > 0) {
        console.log('Errors ', errors);
        for (const singleError of errors) {
          this.addErrorToInfobar(singleError);
        }
        this.snackBar.openFromComponent(SnackbarErrorInfoComponent, this.infoBar);
      } else {
        this.onUpdatePersonFunction(dataModel);
      }
    });
  }

  onUpdatePersonFunction(colleague: Colleague): void {
    if (this.companyId) {
      const colleagueInfo = {
        name: colleague.firstName,
        email: colleague.email,
        last_name: colleague.lastName,
        slack_user_id: colleague.slackUserId,
        department_id: colleague.departmentId,
        bio: '',
        company_id: this.companyId,
      };

      this.companyService.updateColleague(colleague.id, colleagueInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultColleague: Colleague) => {
          this.sharedColleagueService.addColleague(resultColleague);
          this.sharedCompanyDataService.onColleaguesRefresh$.emit();
          this.sharedColleagueService.onColleagueUpdate$.emit();
          this.openSnackBar(this.msgColleagueSaved);
        }, error => {
          this.handleUpdateError(error);
        });
    }
  }

  getCompanyDepartments(): void {
    this.sharedCompanyDataService.departmentsList$
      .pipe(
        filter(departments => departments && departments.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((departments: Department[]) => {
        this.departments = departments;
      });
  }

  handleUpdateError(error: any): void {
    let errorText = 'Invalid credentials';

    if (error.error) {
      const errorObject = error.error;
      if (errorObject.email) {
        errorText = 'Email ' + errorObject.email[0];
      } else if (errorObject.firstName) {
        errorText = 'First name ' + errorObject.firstName[0];
      } else if (errorObject.lastName) {
        errorText = 'Last name ' + errorObject.lastName[0];
      }
    }
  }

  addErrorToInfobar(singleError: any): void {
    const data = singleError.constraints;
    for (const key of Object.keys(data)) {
      this.infoBar.push(data[key]);
    }
  }

  openSnackBar(message: string): void {
    this.formMessages();
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  formMessages(): void {
    const language = this.utilsService.getCurrentLanguage();
    if (language === 'ru') {
      this.msgColleagueSaved = 'Информация коллеги была успешно сохранена';
    }
  }
}
