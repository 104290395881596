import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';

import { CompanyService } from 'app/_services/company.service';
import { UserService } from 'app/_services/user.service';
import { SharedService } from 'app/_services/shared.service';

import { Company } from 'app/_models/company';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { User } from 'app/_models/user';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { SnackBarComponent } from 'app/snack-bar';

import { environment } from '../../environments/environment';
import { SharedUserService } from 'app/_services/shared-user.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss'],
  providers: [SnackBarComponent]
})
export class CompanyPageComponent implements OnInit, OnDestroy {
  private attachmentsUrl: string = environment.ATTACHMENTS_URL;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  colleagues: Colleague[] = [];
  nonDepartmentColleagues: Colleague[] = [];
  currentCompany: Company = {
    id: 0,
    uuid: '',
    name: '',
    departments: [],
    ownerEmail: '',
    slackIntegrationToken: '',
    ownerId: 0,
    logo: null,
    colleagueSharedLink: '',
  };
  currentUser: User = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    activeCompanyId: 0,
    showNotifications: false,
    hasCalculator: false,
    showThumbnails: false,
    currentLocale: 'en',
  };

  isAdmin: boolean = false;
  companyLogoUrl: any;
  initialOwnerEmail: string = '';
  actionsEnabled: boolean = false;

  imageFiles: any = [];
  uploadName: string = '';
  uploadDisabled: boolean = false;
  infoBar: string = '';
  departments: Department[] = [];
  people: Colleague[] = [];

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private userService: UserService,
    private snackBar: SnackBarComponent,
    private sharedService: SharedService,
    private sharedUserService: SharedUserService,
    private sharedCompanyDataService: SharedCompanyDataService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toDepartmentPage(): void {
    this.router.navigate(['/company/create-department']);
  }

  toCreateCompanyPage(): void {
    this.router.navigate(['/company/create-company']);
  }

  getUserInfo(): void {
    this.sharedUserService.currentUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.currentUser = user;

        let isAdmin = false;
        if (user.admin) {
          isAdmin = true;
        }
        this.isAdmin = isAdmin;

        const activeId = user.activeCompanyId;
        if (activeId && activeId > 0) {
          this.getCompanyInfo();
        }
      }, error => {
        console.log('User info', error);
        this.sharedService.checkSignatureExpired(error);
      });
  }

  getCompanyInfo(): void {
    this.sharedCompanyDataService.companyInfo$
      .pipe(
        filter(companyInfo => companyInfo !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((companyInfo: Company) => {
        this.initialOwnerEmail = companyInfo.ownerEmail;
        this.currentCompany = companyInfo;
        this.checkActionsEnabled();

        const companyLogo = companyInfo.logo;
        if (companyLogo && companyLogo.url) {
          this.companyLogoUrl = this.attachmentsUrl + companyLogo.url;
        } else {
          this.companyLogoUrl = null;
        }
      },
      error => {
        console.log('Error with active company ', error);
      });
  }

  checkActionsEnabled(): void {
    const email = this.currentUser.email;
    const ownerEmail = this.currentCompany.ownerEmail;

    if (this.isAdmin) {
      this.actionsEnabled = true;
    } else if (email === ownerEmail) {
      this.actionsEnabled = true;
    } else {
      this.actionsEnabled = false;
    }
  }

  updateCompanyInfo(): void {
    const companyId = this.currentCompany.id;

    const updateParams = {
      'name': this.currentCompany.name,
      'logo': this.currentCompany.logo,
      'slack_integration_token': this.currentCompany.slackIntegrationToken,
    };

    this.companyService.updateCompanyInfo(companyId, updateParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((companyInfo: Company) => {
        if (companyInfo.logo) {
          this.companyLogoUrl = this.attachmentsUrl + companyInfo.logo.url;
        }
        this.sharedService.onRefreshUserInfo$.emit();
        this.openSnackBar('Information updated');
      },

      error => {
        console.log(error);
        this.infoBar = 'Unable to update';
        this.openSnackBar('Unable to update');
      });

    const ownerEmail = this.currentCompany.ownerEmail;
    if (this.initialOwnerEmail !== ownerEmail) {
      this.updateCompanyOwner(companyId, ownerEmail);
    }
    // this.setActiveCompany(this.currentCompany.id);
  }

  updateCompanyOwner(companyId: number, newOwnerEmail: string): void {
    this.companyService.updateCompanyOwner(companyId, newOwnerEmail)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultCompany: Company) => {
        const newCompany: Company = {
          id: resultCompany.id,
          uuid: resultCompany.uuid,
          name: resultCompany.name,
          ownerEmail: resultCompany.ownerEmail,
          ownerId: resultCompany.ownerId,
          slackIntegrationToken: resultCompany.slackIntegrationToken,
          departments: [],
          logo: resultCompany.logo,
          colleagueSharedLink: '',
        };
        this.initialOwnerEmail = resultCompany.ownerEmail;
        this.currentCompany = newCompany;
        this.openSnackBar('Owner updated successfully');
      },
      error => {
        console.log(error);
        this.infoBar = 'Unable to update owner';
        this.openSnackBar('Unable to update owner');
      });
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectEvent(file);
    }
  }

  selectEvent(file: File): void {
    this.imageFiles = [];
    this.formUploadName(file);

    this.imageFiles.push(file);

    this.uploadAction();
  }

  formUploadName(file: File): void {
    this.uploadName = '';
    let newName = '';
    newName = file.name;

    if (newName.length > 30) {
      newName = newName.slice(0, 30) + '...';
    }

    this.uploadName = newName;
  }

  uploadAction(): void {
    if (this.imageFiles.length > 0) {
      this.uploadDisabled = true;
      this.uploadFiles(this.imageFiles);
    }
  }

  uploadFiles(files: any): void {
    // console.log('upload files ', files);
    const company = this.currentCompany;

    this.companyService.uploadCompanyLogo(files, company['id'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: Response) => {
          // console.log('UPLOAD DATA ', data);
          this.uploadDisabled = false;
          this.updateCompanyInfo();
        },
        error => console.log(error));
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  deleteCompanyLogo(): void {
    this.companyLogoUrl = '';

    const companyId = this.currentCompany.id;
    const params = {
      'name': this.currentCompany.name,
      'logo': this.companyLogoUrl,
    };

    this.companyService.updateCompanyInfo(companyId, params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
          this.openSnackBar('Company logo was deleted!');
          this.sharedService.onRefreshUserInfo$.emit();
        },

        error => {
          console.log(error);
          this.infoBar = 'Unable to update';
          this.openSnackBar('Unable to update');
        });
  }
}
