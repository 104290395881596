<div *ngIf="!isPreviewMode" flex [ngClass]="{loginMode:loginMode}">
<!-- <div flex [ngClass]="{demo:currentMode, loginMode:loginMode}"> -->
  <mat-toolbar style="background-color: #fff">
    <mat-toolbar-row class="header">
      <div class="logo-block">
          <img src="/assets/overmind_icons/logo.svg" 
            (click)="onLogoClick()" alt="Bookmark" class="main-logo" />
          <span class="logo-block__title" (click)="onLogoClick()">TeamHeat</span>
      </div>

      <ng-container *ngIf="userExists">
        <div style="flex: 1 1 auto; padding-left: 40px;">
          <button class="adding-button" mat-mini-fab color="primary" (click)="onDialogAdd()">
            <img src="/assets/overmind_icons/add.svg" alt="Add" />
          </button>
        </div>

        <div class="account-header-block" (click)="onSettingsPage()">
          <div class="user-block">
            <p>{{currentUser.firstName}} {{currentUser.lastName}}</p>

            <!-- TODO Should be hidden for the first release
                <p class="label">HR Specialist</p>
            -->
          </div>
          <button class="avatar-icon" mat-fab>
            <!--<img *ngIf="userAvatar" [src]='userAvatar' class="user-avatar" />-->
            <span>{{currentUser.firstName | slice : 0 : 1}} {{currentUser.lastName | slice : 0 : 1}}</span>
          </button>
        </div>

      </ng-container>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav mode="side" opened class="sidenav-style">
      <div *ngIf="userExists" class="navigation-box">
        <div class="navigation-top-block">
          <div class="company-info-box">

            <ng-container *ngIf="currentCompany">
              <div>
                <img routerLink="/company" *ngIf="this.companyLogoUrl" [src]='this.companyLogoUrl' class="company-logo-block" />
                <div routerLink="/company" *ngIf="!this.companyLogoUrl">
                  <span class="company-logo-block initials-block">{{currentCompany.name | slice : 0 : 1}}</span>
                </div>
                <span class="company-title" routerLink="/company">{{currentCompany.name}}</span>
                <span class="mat-select-arrow" (click)="changeCompany()"></span>
              </div>
              <button mat-button class="small-text" (click)="changeCompany()" i18n="@@changeCompanyButton">
                Change company
              </button>
              <ng-container *ngIf="isAdmin">
                <!-- <button mat-raised-button color="accent" (click)="checkAdminCompanies()">
                  Admin companies
                </button> -->
                <button mat-raised-button color="accent" (click)="onAdminSettingsPage()" i18n="@@adminSettings">
                  Admin settings
                </button>
              </ng-container>
            </ng-container>
            
          </div>
        </div>
        <div class="navigation-middle-block" *ngIf="currentCompany">
          <div class="navigation-buttons">
            <button mat-menu-item routerLink="/home" routerLinkActive="active-list-item">
              <mat-icon class="home-icon"></mat-icon>
              <span i18n="@@homePage">Home</span>
            </button>
            <ng-container *ngIf="isAdmin">
              <button mat-menu-item routerLink="/dashboards" routerLinkActive="active-list-item">
                <mat-icon class="dashboard-icon"></mat-icon>
                <span i18n="@@dashboardsPage">Dashboards</span>
              </button>
            </ng-container>
            <button mat-menu-item routerLink="/questionnaires" routerLinkActive="active-list-item" (click)="changeTab()">
              <mat-icon class="package-icon"></mat-icon>
              <span i18n="@@packagePage">Questionnaires</span>
            </button>

            <button mat-menu-item routerLink="/colleagues" routerLinkActive="active-list-item">
              <mat-icon class="colleagues-icon"></mat-icon>
              <span i18n="@@colleaguesPage">Colleagues</span>
            </button>

          </div>
        </div>

        <div class="navigation-bottom-block">
          <mat-action-list class="navigation-buttons">
            <ng-container *ngIf="isRussianLocale">
              <button mat-list-item (click)="onFaqPage()">
                <mat-icon>help_outline</mat-icon>
                <span style="color: #0051cc;">Справка</span>
              </button>
            </ng-container>
            <ng-template ngFor let-lang [ngForOf]="languages">
              <button mat-list-item (click)="onLanguageChange(lang)">
                <mat-icon>language</mat-icon>
                <span>{{lang.label}}</span>
              </button>
            </ng-template>
            <button mat-list-item (click)="onFeedback()">
              <mat-icon>&#xE0C9;</mat-icon>
              <span i18n="@@feedbackPage">Send feedback</span>
            </button>
            <button mat-list-item (click)="logOut()">
              <mat-icon>&#xE879;</mat-icon>
              <span i18n="@@logout">Logout</span>
            </button>
          </mat-action-list>
        </div>

      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>

<div *ngIf="isPreviewMode">
  <router-outlet></router-outlet>
</div>
