<!-- COLLEAGUES -->
<section class="colleagues-page">
  <ng-container *ngIf="hasActiveCompany">
    <div class="colleagues-page__header">
      <nav mat-tab-nav-bar>
        <a mat-tab-link routerLink="/colleagues" [active]="activeLink === 'colleagues'" (click)="changeUrl(0)" i18n="@@ColleaguePageColleaguesTitle">Colleagues</a>
        <a mat-tab-link routerLink="/colleagues/department-management" [active]="activeLink === 'department-management'" (click)="changeUrl(1)" i18n="@@ColleaguePageDepartmentManagementTitle">Department management</a>
        <a mat-tab-link routerLink="/colleagues/archive" [active]="activeLink === 'archive'" (click)="changeUrl(2)" i18n="@@ColleaguePageArchiveTitle">Archive</a>
      </nav>
    </div> <!-- /.colleagues-header -->

    <div class="colleagues-page__content">
      <router-outlet></router-outlet>
    </div>
  </ng-container> <!-- *ngIf="hasActiveCompany" -->
</section>
<!-- COLLEAGUES END -->

<ng-container *ngIf="!hasActiveCompany">
  <div class="warning-message">
    <h3 i18n="@@warningMessage-ActiveCompanyMissingTitle">Sorry, but it seems You don't have any active company :(</h3>
    <p i18n="@@warningMessage-ActiveCompanyMissingBody">Please, choose the active company.</p>
  </div>
</ng-container>
