import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { ApiQuestionnaireCard } from 'app/_api_models/api-questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { ScoreDivision } from 'app/_models/score-division';
import { parseApiCardOption } from 'app/_api_validation/parse-api-card-option';
import { parseApiScoreDivision } from 'app/_api_validation/parse-api-score-division';
import { environment } from '../../environments/environment';

function filterScoreDivisions(included: any, cardId: number): any {
  const includedDivisions = included.filter(item => item.type === 'score_division');
  const filteredDivisions = includedDivisions.filter(item => item.attributes.questionnaire_card_id === cardId);
  const resultDivisions = [];
  for (const singleApiDivision of filteredDivisions) {
    const resultApiDivision = singleApiDivision.attributes;
    resultDivisions.push(resultApiDivision);
  }

  return resultDivisions;
}

function filterCardOptions(included: any, cardId: number): any {
  const includedOptions = included.filter(item => item.type === 'card_option');
  const filteredOptions = includedOptions.filter(item => item.attributes.questionnaire_card_id === cardId);
  const resultOptions = [];
  for (const singleApiOption of filteredOptions) {
    const resultApiOption = singleApiOption.attributes;
    resultOptions.push(resultApiOption);
  }

  return resultOptions;
}

export function parseApiQuestionnaireCardVerbose(apiResponse: any, included: any): QuestionnaireCard {
  let cardObject;
  if (environment.production) {
    cardObject = apiResponse;
  } else {
    cardObject = ApiQuestionnaireCard.check(apiResponse);
  }

  const cardOptions: CardOption[] = [];
  const scoreDivisions: ScoreDivision[] = [];
  if (included) {
    const apiCardOptions = filterCardOptions(included, cardObject.id);
    if (apiCardOptions) {
      for (const singleOption of apiCardOptions) {
        const optionObject = parseApiCardOption(singleOption, true, included);
        cardOptions.push(optionObject);
      }
    }

    const apiScoreDivisions = filterScoreDivisions(included, cardObject.id);
    if (apiScoreDivisions) {
      for (const singleDivision of apiScoreDivisions) {
        const divisionObject = parseApiScoreDivision(singleDivision);
        scoreDivisions.push(divisionObject);
      }
    }
  }

  const newCard: QuestionnaireCard = {
    id: cardObject.id,
    questionText: cardObject.questionText,
    cardType: cardObject.cardType,
    questionnaireId: cardObject.questionnaire_id,
    orderPosition: cardObject.order_position,
    cardOptions: cardOptions,
    scoreDivisions: scoreDivisions,
    isRequired: cardObject.is_required,
    isActive: cardObject.is_active,
    divisionsColorScheme: cardObject.divisions_color_scheme,
  };

  return newCard;
}
