<div class="dashboard-settings-style">
  <div class="single-setting-option">
    Dashboard is public
    <mat-checkbox [(ngModel)]="isPublic" (change)="onCheckboxChange()"></mat-checkbox>
  </div>
  <div class="single-setting-option">
    Dashboard public link: {{baseUrl}}/dashboard/{{publicLink}}
  </div>
  <!-- <button
      mat-raised-button
      color="accent"
      (click)="generateNewLink()"
      >
        Generate new link
    </button> -->
</div>