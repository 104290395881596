import { environment } from '../../environments/environment';
import { ColleagueEvaluator } from 'app/_models/colleague-evaluator';
import { ApiColleagueEvaluator } from 'app/_api_models/api-colleague-evaluator';

export function parseApiColleagueEvaluator(apiResponse: any): ColleagueEvaluator {
  let colleagueEvaluator;

  if (environment.production) {
    colleagueEvaluator = apiResponse;
  } else {
    colleagueEvaluator = ApiColleagueEvaluator.check(apiResponse);
  }

  const newColleagueEvaluator: ColleagueEvaluator = {
    id: colleagueEvaluator.id,
    packageId: colleagueEvaluator.package_id,
    colleagueId: colleagueEvaluator.colleague_id,
    evaluatorId: colleagueEvaluator.evaluator_id,
  };

  return newColleagueEvaluator;
}
