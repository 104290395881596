import { Number, String, Record, Null } from 'runtypes';

const CommonValue = Record({
  type: String,
  value: Number.Or(String).Or(Null),
});

export const ApiPackageParameter = Record({
  id: Number,
  formula_name: String,
  visible_name: String,
  group_name: String,
  common_value: CommonValue,
  package_id: Number,
});
