import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-dialog-add',
  templateUrl: './dialog-add.component.html',
  styleUrls: ['./dialog-add.component.scss']
})
export class DialogAddComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogAddComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onCreateQuestionnaire(): void {
    this.router.navigate(['/questionnaires/create-questionnaire']);
    this.dialogRef.close();
  }

  onAddMember(): void {
    this.router.navigate(['/colleagues/create-colleague']);
    this.dialogRef.close();
  }

  onCreateDepartment(): void {
    this.router.navigate(['/company/create-department']);
    this.dialogRef.close();
  }

  onCreateCompany(): void {
    this.router.navigate(['/company/create-company']);
    this.dialogRef.close();
  }

}
