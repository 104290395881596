import {Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { Questionnaire } from 'app/_models/questionnaire';
import { Package } from 'app/_models/package';
import { CardOption } from 'app/_models/card-option';

@Component({
  selector: 'app-stardard-set-details',
  templateUrl: './stardard-set-details.component.html',
  styleUrls: ['./stardard-set-details.component.scss']
})
export class StardardSetDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  currentSetId: number = 0;
  currentSetName: string = '';
  selectedTabId: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    public  dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.sharedPackageService.clearSharedService();

    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        const setId = params['set_id'];
        if (setId) {
          this.currentSetId = setId;
          this.sharedPackageService.isStandardSet$.next(true);
          this.getPackageInfo();
        }
    });

    this.sharedPackageService.currentPackageName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((setName: string) => {
        this.currentSetName = setName;
      });
  }

  ngAfterViewChecked(): void {
    const packId = this.currentSetId;
    if (this.router.url.indexOf(`/standard-sets/${packId}/description`) > -1) {
      this.selectedTabId = 0;
    // } else if (this.router.url.indexOf(`/standard-sets/${packId}/parameters`) > -1) {
    //   this.selectedTabId = 1;
    } else if (this.router.url.indexOf(`/standard-sets/${packId}/questions`) > -1) {
      this.selectedTabId = 1;
    } else if (this.router.url.indexOf(`/standard-sets/${packId}/settings`) > -1) {
      this.selectedTabId = 1;
    } else if (this.router.url.indexOf(`/standard-sets/${packId}/dashboard`) > -1) {
      this.selectedTabId = 2;
    }

    this.sharedService.currentStandardSetTabId
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(tabId => {
        this.selectedTabId = tabId;
      });

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPackageInfo(): void {
    const packageId = this.currentSetId;

    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
          if (packageInfo && packageInfo.questionnaire) {
            this.currentSetName = packageInfo.name;
            this.sharedPackageService.currentPackageId$.next(packageInfo.id);
            this.sharedPackageService.currentPackageName$.next(packageInfo.name);
            this.sharedPackageService.currentPackageCategoryName$.next(packageInfo.categoryName);
            this.sharedPackageService.currentPackageDescription$.next(packageInfo.description);
            this.sharedPackageService.currentPackageLanguage$.next(packageInfo.language);
            this.sharedPackageService.packageParameters$.next(packageInfo.packageParameters);
            this.sharedPackageService.dashboardConfig$.next(packageInfo.dashboardConfig);
            this.sharedPackageService.packageBootstrapped$.next(true);

            this.formPQActions(packageInfo.postQuestionnaireActions);

            const questionnaire = packageInfo.questionnaire;
            this.getQuestionnaireInfo(questionnaire.id);
          }
        },
        error => {
          console.log('Package info', error);
          if (error['status'] === 404) {
            this.router.navigate(['/not-found']);
          }
        });
    }
  }

  getQuestionnaireInfo(questionnaireId: number): void {
    if (questionnaireId) {
      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((questionnaireInfo: Questionnaire) => {
          if (questionnaireInfo) {
            const localCards = [];

            const cards = questionnaireInfo.questionnaireCards;
            for (const singleCard of cards) {
              localCards.push(singleCard);
            }

            localCards.sort(function (a: any, b: any): number {
              return a.orderPosition - b.orderPosition;
            });

            this.loadCardOptions(localCards);
            // this.sharedPackageService.questionnaireCards.next(localCards);
          }
        },
        error => {
          console.log('Questionnaire error ', error);
        });
    }
  }

  loadCardOptions(localCards: QuestionnaireCard[]): void {
    const promisesList = [];
    for (const singleCard of localCards) {
      for (const singleOption of singleCard.cardOptions) {
        const singlePromise = this.loadCardOption(singleOption.id);
        promisesList.push(singlePromise);
        singlePromise.then((resultOption: CardOption) => {
          singleOption.cardActions = resultOption.cardActions;
        }).catch(error => console.log(error));
      }
    }

    Promise.all(promisesList).then((result) => {
      this.sharedPackageService.questionnaireCards$.next(localCards);
    }).catch(error => console.log(error));
  }

  loadCardOption(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.questionnaireService.getCardOption(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultCard: CardOption) => {
          resolve(resultCard);
        }, error => {
          reject(error);
        });
    });
  }

  formPQActions(pqActions: PostQuestionnaireAction[]): void {
    const localPQActions: PostQuestionnaireAction[] = [];
    for (const action of pqActions) {
      // const localAction = this.addLocalPQAction(action);
      localPQActions.push(action);
    }

    this.sharedPackageService.postQuestionnaireActions$.next(localPQActions);
  }

  changeUrl(tabValue: number): void {
    const setId = this.currentSetId;
    if (tabValue === 0) {
      this.router.navigateByUrl(`/standard-sets/${setId}/description`);
    // } else if (tabValue === 1) {
    //   this.router.navigateByUrl(`/standard-sets/${setId}/parameters`);
    } else if (tabValue === 1) {
      this.router.navigateByUrl(`/standard-sets/${setId}/questions`);
    } else if (tabValue === 1) {
      this.router.navigateByUrl(`/standard-sets/${setId}/settings`);
    } else if (tabValue === 2) {
      this.router.navigateByUrl(`/standard-sets/${setId}/dashboard`);
    }
  }

}
