import { ApiPostQuestionnaireAction } from 'app/_api_models/api-post-questionnaire-action';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { environment } from '../../environments/environment';

export function parseApiPostQuestionnaireAction(apiResponse: any): PostQuestionnaireAction {
  let postObject;
  if (environment.production) {
    postObject = apiResponse;
  } else {
    postObject = ApiPostQuestionnaireAction.check(apiResponse);
  }

  const newPQAction: PostQuestionnaireAction = {
    id: postObject.id,
    text: postObject.text,
    parameter: postObject.parameter,
    actionType: postObject.action_type,
    orderPosition: postObject.order_position,
    customCalculationId: postObject.python_custom_calculation_id
  };

  return newPQAction;
}
