<mat-tab-group [selectedIndex]="selectedTabId" class="packages-tabs-style">
  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n="@@myQuestionnaire-tab" (click)="changeUrl(0)">My questionnaires</span>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span i18n="@@standardSets-tab" (click)="changeUrl(1)">Templates</span>
    </ng-template>
  </mat-tab>
<!--   <mat-tab>
    <ng-template mat-tab-label>
      <span i18n="@@standardSets-tab" (click)="changeUrl(2)">Community Sets</span>
    </ng-template>
  </mat-tab> -->
</mat-tab-group>
<div class="content-wrap my-style">
  <router-outlet></router-outlet>
</div>
