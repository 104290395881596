<div class="parameters-wrapper-style">
<div class="card-wrapper parameters-cards">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <i class="material-icons" style="font-size: 30px; vertical-align: bottom;">
          person_outline
        </i>&nbsp;<span i18n="@@individual-parameter-title">Individual Parameters</span>
      </mat-card-title>
      <mat-card-subtitle>
        <span i18n="@@individual-parameter-description">
          Parameters of this type are applicable to each employee of your company individually. For example, each employee may have his own level of involvement in the cultural life of a company.
        </span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let singleParameter of individualParameters.parameters; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="singleParameter.visibleName">{{singleParameter.visibleName}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <ng-container>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.visibleName"
                    (blur)="onBlurParameter(individualParameters.id, singleParameter.id)"
                >
              </mat-form-field>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula name</mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.formulaName"
                    maxlength="10"
                    (blur)="onBlurParameter(individualParameters.id, singleParameter.id)"
                >
              </mat-form-field>
            </ng-container>
            <div>
              <mat-radio-group class="radio-simple" [(ngModel)]="singleParameter.commonValue.type">
                <mat-radio-button *ngFor="let singleType of parameterTypes" 
                  [value]="singleType.code"
                  (change)="radioChange($event, individualParameters.id, singleParameter.id)">
                    {{singleType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <button mat-raised-button
                  color="warn"
                  class="delete mat-raised-button"
                  (click)="deleteParameter(individualParameters.id, singleParameter.id); $event.stopPropagation()" 
                  i18n="@@deleteButton">
                    Delete
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="add-new-parameter" [expanded]="panelOpenState">
          <mat-expansion-panel-header (click)="$event.stopPropagation();togglePanel('individual')">
            <mat-panel-title>
              <span i18n="@@create-parameter-btn">create parameter</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span i18n="@@new-parameter-label" style="color: #b7bac1;">New parameter</span>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
            <input
                matInput
                required
                [(ngModel)]="newIndividualVisibleName"
                (blur)="onBlurNewVisibleName('individual')"
            >
          </mat-form-field>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula name</mat-placeholder>
            <input
                matInput
                maxlength="10"
                [(ngModel)]="newIndividualFormulaName"
                (blur)="onBlurNewFormulaName('individual')"
            >
          </mat-form-field>
          <div>
            <mat-radio-group class="radio-simple" [(ngModel)]="newIndividualParameterType">
              <mat-radio-button *ngFor="let singleType of parameterTypes" [value]="singleType.code">
                {{singleType.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-raised-button
                    style="margin-right: 10px;"
                    color="accent"
                    i18n="@@create-parameter-btn"
                    class="mat-raised-button"
                    (click)="addParameter(individualParameters.id)">
              Save
            </button>
            <button mat-raised-button
                    color="primary"
                    i18n="@@cancelButton"
                    class="mat-raised-button"
                    (click)="closePanel('individual')">
              Cancel
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <i class="material-icons" style="font-size: 30px; vertical-align: bottom;">
          people_outline
        </i>&nbsp;<span i18n="@@common-parameter-title"> Common Parameters</span>
      </mat-card-title>
      <mat-card-subtitle>
        <span i18n="@@common-parameter-description">
          These are the parameters that apply to the team as a whole. For example, the average value of any individual parameter.
        </span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let singleParameter of commonParameters.parameters; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="singleParameter.visibleName">{{singleParameter.visibleName}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <ng-container>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.visibleName"
                    (blur)="onBlurParameter(commonParameters.id, singleParameter.id)"
                >
              </mat-form-field>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">
                  Formula name
                </mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.formulaName"
                    maxlength="10"
                    (blur)="onBlurParameter(commonParameters.id, singleParameter.id)"
                >
              </mat-form-field>
            </ng-container>
            <div>
              <mat-radio-group class="radio-simple" [(ngModel)]="singleParameter.commonValue.type">
                <mat-radio-button *ngFor="let singleType of parameterTypes" 
                  [value]="singleType.code"
                  (change)="radioChange($event, commonParameters.id, singleParameter.id)">
                    {{singleType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- <div>
              <mat-radio-group class="radio-simple" [(ngModel)]="singleParameter.commonValue.type">
                <mat-radio-button *ngFor="let singleType of parameterTypes" [value]="singleType.code">
                  {{singleType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div> -->
            <div>
              <button mat-raised-button
                      color="warn"
                      class="delete mat-raised-button "
                      (click)="deleteParameter(commonParameters.id, singleParameter.id); $event.stopPropagation()"
              i18n="@@deleteButton">Delete
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="add-new-parameter" [expanded]="panelCommonOpenState">
          <mat-expansion-panel-header (click)="$event.stopPropagation();togglePanel('common')">
            <mat-panel-title>
              <span i18n="@@create-parameter-btn">create parameter</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span i18n="@@new-parameter-label" style="color: #b7bac1;">New parameter</span>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
            <input
                matInput
                required
                [(ngModel)]="newCommonVisibleName"
                (blur)="onBlurNewVisibleName('common')"
            >
          </mat-form-field>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula name</mat-placeholder>
            <input
                matInput
                maxlength="10"
                [(ngModel)]="newCommonFormulaName"
                (blur)="onBlurNewFormulaName('common')"
            >
          </mat-form-field>
          <!-- <div>
            <mat-radio-group class="radio-simple" [(ngModel)]="newCommonParameterType">
              <mat-radio-button *ngFor="let singleType of parameterTypes" 
                [value]="singleType.code"
                (change)="radioChange($event, commonParameters.id, singleParameter.id)">
                {{singleType.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div> -->
          <div>
            <mat-radio-group class="radio-simple" [(ngModel)]="newCommonParameterType">
              <mat-radio-button *ngFor="let singleType of parameterTypes" [value]="singleType.code">
                {{singleType.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div>
            <button mat-raised-button
                    style="margin-right: 10px;"
                    color="accent"
                    i18n="@@create-parameter-btn"
                    class="mat-raised-button"
                    (click)="addParameter(commonParameters.id)">
              Save
            </button>
            <button mat-raised-button
                    color="primary"
                    i18n="@@cancelButton"
                    class="mat-raised-button"
                    (click)="closePanel('common')">
              Cancel
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <i class="material-icons" style="font-size: 30px; vertical-align: top;">
          &#xE8D3;
        </i>
        &nbsp;
        <span i18n="@@relative-parameter-title">Relative Parameters</span>
      </mat-card-title>
      <mat-card-subtitle>
        <span i18n="@@relative-parameter-description">
          These are the parameters that can be applied from the respondent to someone from colleagues. For example, you need to interview the intern about who helped him most during the adaptation.
        </span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let singleParameter of relativeParameters.parameters; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span *ngIf="singleParameter.visibleName">{{singleParameter.visibleName}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <ng-container>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.visibleName"
                    (blur)="onBlurParameter(relativeParameters.id, singleParameter.id)"
                >
              </mat-form-field>
              <mat-form-field style="width: 600px;">
                <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">
                  Formula name
                </mat-placeholder>
                <input
                    matInput
                    [(ngModel)]="singleParameter.formulaName"
                    maxlength="10"
                    (blur)="onBlurParameter(relativeParameters.id, singleParameter.id)"
                >
              </mat-form-field>
            </ng-container>
            <div>
              <mat-radio-group class="radio-simple" [(ngModel)]="singleParameter.commonValue.type">
                <mat-radio-button *ngFor="let singleType of parameterTypes" 
                  [value]="singleType.code"
                  (change)="radioChange($event, relativeParameters.id, singleParameter.id)">
                    {{singleType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              <button mat-raised-button
                      color="warn"
                      class="delete mat-raised-button"
                      (click)="deleteParameter(relativeParameters.id, singleParameter.id); $event.stopPropagation()"
              i18n="@@deleteButton">Delete
              </button>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="add-new-parameter" [expanded]="panelRelativeOpenState">
          <mat-expansion-panel-header (click)="$event.stopPropagation();togglePanel('relative')">
            <mat-panel-title>
              <span i18n="@@create-parameter-btn">create parameter</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <span i18n="@@new-parameter-label" style="color: #b7bac1;">New parameter</span>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterName">Parameter name</mat-placeholder>
            <input
                matInput
                required
                [(ngModel)]="newRelativeVisibleName"
                (blur)="onBlurNewVisibleName('relative')"
            >
          </mat-form-field>
          <mat-form-field style="width: 600px;">
            <mat-placeholder class="placeholder" i18n="@@placeholder-parameterFormula">Formula name</mat-placeholder>
            <input
                matInput
                maxlength="10"
                [(ngModel)]="newRelativeFormulaName"
                (blur)="onBlurNewFormulaName('relative')"
            >
          </mat-form-field>
          <div>
            <mat-radio-group class="radio-simple" [(ngModel)]="newRelativeParameterType">
              <mat-radio-button *ngFor="let singleType of parameterTypes" [value]="singleType.code">
                {{singleType.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- <div>
            <mat-radio-group class="radio-simple" [value]="newRelativeParameterType">
              <mat-radio-button [value]="newRelativeParameterType" selected="true">
                Text
              </mat-radio-button>
            </mat-radio-group>
          </div> -->
          <div>
            <button mat-raised-button
                    style="margin-right: 10px;"
                    color="accent"
                    i18n="@@create-parameter-btn"
                    class="mat-raised-button"
                    (click)="addParameter(relativeParameters.id)">
              Save
            </button>
            <button mat-raised-button
                    color="primary"
                    i18n="@@cancelButton"
                    class="mat-raised-button"
                    (click)="closePanel('relative')">
              Cancel
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </mat-card-content>
  </mat-card>
</div>

<div class="right-sidebar">
  <div class="button-back-style">
    <button
        mat-raised-button
        color="primary"
        (click)="toQuestionnairePage()">
      <!-- <img src="assets/overmind_icons/rounded-add-button-1.svg"> -->
      <mat-icon inline="true" class="mat-icon-param-style">arrow_back_ios_new</mat-icon>
      <span i18n="@@prev-label">Back</span>
    </button>
  </div>
</div>

</div>
