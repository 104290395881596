import { HomePageInfo } from 'app/_models/home-page-info';
import { ApiHomePageInfo } from 'app/_api_models/api-home-page-info';
import { environment } from '../../environments/environment';

export function parseApiHomePageInfo(apiResponse: any): HomePageInfo {
  let homePageObject;
  if (environment.production) {
    homePageObject = apiResponse;
  } else {
    homePageObject = ApiHomePageInfo.check(apiResponse);
  }

  const newInfo: HomePageInfo = {
    id: homePageObject.id,
    language: homePageObject.language,
    info: homePageObject.info
  };

  return newInfo;
}
