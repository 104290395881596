import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';
import { Parameter } from 'app/_models/overmind_ds/parameter';
import { ParameterService } from 'app/_services/overmind_ds/parameter.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-create-parameter',
  templateUrl: './create-parameter.component.html',
  styleUrls: ['./create-parameter.component.scss']
})
export class CreateParameterComponent implements OnInit {
  @Input('entityUuid') entityUuid: string = '';
  @Output() newParameter = new EventEmitter<ExtendedParameter>();

  public newParameterValue: string = '';
  public newParameterName: string = '';
  public newFormulaName: string = '';
  public newParameterFormula: string = '';
  public newIndividualParameterType = null;
  public parameterTypes: string[] = ['number', 'text'];

  constructor(
    private parameterService: ParameterService,
  ) { }

  ngOnInit(): void {
  }

  createParameter(): void {
    const newParameter: Parameter = {
      id: 0,
      uuid: uuid(),
      entityUuid: this.entityUuid,
      name: this.newParameterName,
      formulaName: this.newFormulaName,
      formula: this.newParameterFormula,
      type: this.newIndividualParameterType,
    };

    this.parameterService.createNewParameter(newParameter)
      .subscribe((parameter: Parameter) => {
        const newExtendedParameter: ExtendedParameter = {
          id: parameter.id,
          uuid: parameter.uuid,
          entityUuid: parameter.entityUuid,
          formulaName: parameter.formulaName,
          name: parameter.name,
          type: parameter.type,
          formula: parameter.formula,
          parameterData: []
        };

        this.newParameter.emit(newExtendedParameter);
      });
  }
}
