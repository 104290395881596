import { ApiExperimentParameter } from 'app/_api_models/api-experiment-parameter';
import { ExperimentParameter } from 'app/_models/experiment-parameter';
import { environment } from '../../environments/environment';

export function parseApiExperimentParameter(apiResponse: any): ExperimentParameter {
  let parameterObject;
  if (environment.production) {
    parameterObject = apiResponse;
  } else {
    parameterObject = ApiExperimentParameter.check(apiResponse);
  }

  const newExperimentParameter: ExperimentParameter = {
    id: parameterObject.id,
    visibleName: parameterObject.visible_name,
    formulaName: parameterObject.formula_name,
    commonValue: parameterObject.common_value,
    experimentId: parameterObject.experiment_id,
    packageParameterId: parameterObject.package_parameter_id,
  };

  return newExperimentParameter;
}
