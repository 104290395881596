import { Department } from 'app/_models/department';
import { Colleague } from 'app/_models/colleague';
import { ApiDepartment } from 'app/_api_models/api-department';
import { parseApiColleague } from 'app/_api_validation/parse-api-colleague';
import { environment } from '../../environments/environment';

export function parseApiDepartment(apiResponse: any, nestedModels: boolean): Department {
  let departmentObject;
  if (environment.production) {
    departmentObject = apiResponse;
  } else {
    departmentObject = ApiDepartment.check(apiResponse);
  }

  const colleagues: Colleague[] = [];
  if (nestedModels) {
    const apiColleagues = apiResponse.colleagues;
    if (apiColleagues) {
      for (const singleColleague of apiColleagues) {
        const colleagueObject = parseApiColleague(singleColleague, false);
        colleagues.push(colleagueObject);
      }
    }
  }

  const newDepartment: Department = {
    id: departmentObject.id,
    uuid: departmentObject.uuid,
    name: departmentObject.name,
    colleagues: colleagues,
    companyId: departmentObject.company_id
  };

  return newDepartment;
}
