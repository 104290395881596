<!-- <div layout="row" layout-padding>
<div flex="20"></div>

<div flex>
	<mat-card class="mat-title">
		<p class="title" i18n="@@HP-title">Welcome to TeamHeat!</p>
		<p class="title" i18n="@@HP-description">You can create a new company in the menu at the top.</p>
	</mat-card>
</div>

<div flex="20"></div>
</div> -->

<ng-container *ngIf="isAdmin">
	<button (click)="setEditMode()">
		<i class="material-icons">
			create
		</i>
	</button>
</ng-container>

<div class="homepage-general-style">
	<div *ngIf="editMode">
		<quill-editor
      [(ngModel)]="infoText"
			[modules]="modules"
			(onEditorCreated)="editorCreated($event)"
      (onContentChanged)="onContentChange($event)"
			(onSelectionChanged)="onSelectionChange($event)"
			(onFocus)="onFocus($event)"
      (onBlur)="onBlur($event)"
      trackChanges='all'
    >
		</quill-editor>
	</div>

	<div *ngIf="!editMode" [innerHtml]="infoText | safeHtml" class="homepage-form-style">
	</div>
</div>