<mat-toolbar style="background-color: #fff">
  <mat-toolbar-row class="header subheader">
    <div class="card-header-group">
      <h2 class="title">
        <span i18n="@@PCA-title">Post Calculation Action</span> #{{editedPQAction.orderPosition}}
      </h2>
      <div class="float-left">
        <button 
          mat-raised-button
          (click)="goBack()" 
          i18n="@@cancelButton">
            Cancel
        </button>
        <button
          mat-raised-button
          [disabled]="!isActionValid"
          color="accent"
          class="header-button"
          (click)="onSaveAction()"
          i18n="@@saveButton">
          <i class="material-icons">
            check
          </i>
          Save
        </button>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div style="display: flex;align-items: flex-start;">
  <mat-card class="new-pqa-card">

    <h2 class="title" i18n="@@PCA-edit-title">
      Edit Post Calculation Action
    </h2>

    <div>
      <mat-form-field>
        <mat-placeholder class="placeholder" i18n="@@placeholder-actionType">Action Type</mat-placeholder>
        <mat-select [(ngModel)]="pqAction.actionType" (selectionChange)="onSelectionChange($event)">
          <mat-option value="individual" [disabled]="individualParameters.length == 0">Individual</mat-option>
          <mat-option value="common" [disabled]="commonParameters.length == 0">Common</mat-option>
          <mat-option value="relative" [disabled]="relativeParameters.length == 0">Relative</mat-option>
          <mat-option value="custom-simple" [disabled]="!isAdmin">Custom Simple</mat-option>
          <mat-option value="custom" [disabled]="!isAdmin">Custom</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="pq-action">
        <mat-form-field>
          <mat-placeholder class="placeholder" i18n="@@placeholder-parameter">Parameter</mat-placeholder>
          <mat-select
              style="margin-right: 10px;"
              [(value)]="pqAction.parameter"
              (selectionChange)="onParameterChange($event)"
              [disabled]="isCustomCalculation&&(!isSimpleCalculation)"
          >
            <div *ngIf="pqAction.actionType == 'common'">
              <mat-option *ngFor="let singleParam of commonParameters"
                          [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
            </div>
            <div *ngIf="pqAction.actionType == 'individual'">
              <mat-option *ngFor="let singleParam of individualParameters"
                          [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
            </div>
            <div *ngIf="pqAction.actionType == 'relative'">
              <mat-option *ngFor="let singleParam of individualParameters"
                          [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
              <mat-option *ngFor="let singleParam of relativeParameters"
                          [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
            </div>
            <div *ngIf="pqAction.actionType == 'custom-simple'">
              <mat-option *ngFor="let singleParam of individualParameters" [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
              <mat-option *ngFor="let singleParam of commonParameters" [value]="singleParam.visibleName">
                {{singleParam.visibleName}}
              </mat-option>
            </div>
            <!-- <div *ngIf="pqAction.actionType == 'custom'">
              <mat-option [value]="">
              </mat-option>
            </div> -->
          </mat-select>
        </mat-form-field>

        <i class="material-icons arrow-rotated">
          keyboard_backspace
        </i>
        <mat-form-field>
          <mat-placeholder class="placeholder" i18n="@@optionAction">Option action</mat-placeholder>
          <ng-container *ngIf="isCustomCalculation">
            <input matInput class="select-class" [value]="pqAction.text" [disabled]="true" />
          </ng-container>
          <ng-container *ngIf="!isCustomCalculation">
            <input matInput class="select-class" [(ngModel)]="pqAction.text" (input)="handleInput($event.target.value)" />
          </ng-container>
        </mat-form-field>
      </div>
    </div>

  </mat-card>

  <div class="right-content-part">
    <app-hints-page 
      [currentPackageId]="currentPackageId" 
      [isQuestionHints]="false" 
      [isPQActionHints]="true">
    </app-hints-page>
  </div>
</div>

<div *ngIf="isCustomCalculation && isAdmin">
  <app-python-calculation-helper 
    [currentPackageId]="currentPackageId"
    [postQuestionnaireActionId]="pqAction.id"
    [customCalculationId]="pqAction.customCalculationId"
    [editMode]="true"
    [isSimpleCalculation]="isSimpleCalculation"
  >
  </app-python-calculation-helper>
</div>