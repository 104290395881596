import { User } from 'app/_models/user';
import { ApiUser } from 'app/_api_models/api-user';
import { environment } from '../../environments/environment';

export function parseApiUser(apiResponse: any): User {
  let userObject;
  if (environment.production) {
    userObject = apiResponse;
  } else {
    userObject = ApiUser.check(apiResponse);
  }

  const newUser: User = {
    id: userObject.id,
    uuid: userObject.uuid,
    firstName: userObject.name,
    lastName: userObject.last_name,
    email: userObject.email,
    activeCompanyId: userObject.active_company_id,
    showNotifications: userObject.show_notifications,
    hasCalculator: userObject.has_calculator,
    showThumbnails: userObject.show_thumbnails,
    admin: userObject.admin,
    activated: userObject.activated,
    currentLocale: userObject.current_locale
  };

  return newUser;
}
