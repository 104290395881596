import { Experiment } from 'app/_models/experiment';
import {
  ExtendedParameter, ExtendedColleague, InfoParameter,
  InfoCard, CategoryInfoCard, RelativeInfoCard, WidgetFilters
} from '../_helpers/local-models';

export function widgetFiltersUpdateHelper(widgetFilters: WidgetFilters, contextObject: any): any {
  const activeColleagues: ExtendedColleague[] = contextObject.activeColleagues;
  const individualParameters: ExtendedParameter[] = contextObject.individualParameters;
  const relativeParameters: ExtendedParameter[] = contextObject.relativeParameters;
  const experiments: Experiment[] = contextObject.experiments;
  const activeExperiment: Experiment = contextObject.activeExperiment;
  const activeExperimentOption: any = contextObject.activeExperimentOption;
  const parameterFilter: any = contextObject.parameterFilter;
  // const parameterRelativeFilter: any = contextObject.parameterRelativeFilter;
  const showParametersNames: boolean = contextObject.showParametersNames;
  const showParticipants: boolean = contextObject.showParticipants;
  const hideEmptyCards: boolean = contextObject.hideEmptyCards;

  const colleaguesFilter = activeColleagues.filter(colleague => colleague.isActive === true);
  const activeColleaguesIds = [];
  for(const singleColleague of colleaguesFilter) {
    activeColleaguesIds.push(singleColleague.id);
  }
  
  widgetFilters['activeColleaguesIds'] = activeColleaguesIds;

  const individualParamsFilter = individualParameters.filter(param => param.isActive === true);
  const relativeParamsFilter = relativeParameters.filter(param => param.isActive === true);
  const activeParamsIds = [];
  for (const singleParam of individualParamsFilter) {
    activeParamsIds.push(singleParam.id);
  }
  for (const singleParam of relativeParamsFilter) {
    activeParamsIds.push(singleParam.id);
  }

  widgetFilters['activeParametersIds'] = activeParamsIds;

  const experimentOption = activeExperimentOption;
  if (experimentOption && experiments) {
    if (experimentOption === 'last_research') {
      widgetFilters['showLatestResearch'] = true;
    } else {
      widgetFilters['showLatestResearch'] = false;
    }
  }

  if (activeExperiment) {
    widgetFilters['activeResearchId'] = activeExperiment.id;
  }

  // if (parameterRelativeFilter) {
  //   widgetFilters['sortParameterId'] = parameterRelativeFilter.id;
  // } else if (parameterFilter) {
  //   widgetFilters['sortParameterId'] = parameterFilter.id;
  // } else {
  //   widgetFilters['sortParameterId'] = 0;
  // }

  if (relativeParamsFilter.length > 0) {
    widgetFilters['sortParameterId'] = 0;
  } else if (parameterFilter) {
    widgetFilters['sortParameterId'] = parameterFilter.id;
  } else {
    widgetFilters['sortParameterId'] = 0;
  }

  if (showParametersNames) {
    widgetFilters['showParametersNames'] = true;
  } else {
    widgetFilters['showParametersNames'] = false;
  }

  if (showParticipants) {
    widgetFilters['showParticipants'] = true;
  } else {
    widgetFilters['showParticipants'] = false;
  }

  if (hideEmptyCards) {
    widgetFilters['hideEmptyCards'] = true;
  } else {
    widgetFilters['hideEmptyCards'] = false;
  }

  return widgetFilters;
}


function getLatestExperiment(experiments: Experiment[]): Experiment {
  if (experiments) {
    const sortedExperimens = experiments.sort(function (a: any, b: any): number {
      return b.id - a.id;
    });
    return sortedExperimens[0];
  }
}

export function restoreInputWidgetsFiltersHelper(inputWidgetFilters: any, stateObject: any): any {
  const activeColleagues = stateObject.activeColleagues;
  const individualParameters = stateObject.individualParameters;
  const relativeParameters = stateObject.relativeParameters;
  const experiments = stateObject.experiments;
  let activeExperiment = stateObject.activeExperiment;
  let activeExperimentOption = stateObject.activeExperimentOption;
  let showParametersNames: boolean = stateObject.showParametersNames;
  let hideEmptyCards: boolean = stateObject.hideEmptyCards;
  let showParticipants: boolean = stateObject.showParticipants;

  const colleaguesIds = inputWidgetFilters.activeColleaguesIds;
  if (colleaguesIds) {
    for (const singleColleague of activeColleagues) {
      const findColleague = colleaguesIds.find(id => id === singleColleague.id);
      if (findColleague) {
        singleColleague.isActive = true;
      } else {
        singleColleague.isActive = false;
      }
    }
  }

  const paramsIds = inputWidgetFilters.activeParametersIds;
  if (paramsIds) {
    for (const singleParam of individualParameters) {
      const findParam = paramsIds.find(id => id === singleParam.id);
      if (findParam) {
        singleParam.isActive = true;
      } else {
        singleParam.isActive = false;
      }
    }

    for (const singleParam of relativeParameters) {
      const findParam = paramsIds.find(id => id === singleParam.id);
      if (findParam) {
        singleParam.isActive = true;
      } else {
        singleParam.isActive = false;
      }
    }
  }

  const showLatestResearch = inputWidgetFilters.showLatestResearch;
  if (showLatestResearch) {
    const activeResearch = getLatestExperiment(experiments);
    if (activeResearch) {
      activeExperiment = activeResearch;
      activeExperimentOption = 'last_research';
    }
  } else {
    const activeResearchId = inputWidgetFilters.activeResearchId;
    if (activeResearchId) {
      const activeResearch = experiments.find(experiment => experiment.id === activeResearchId);
      if (activeResearch) {
        activeExperiment = activeResearch;
        activeExperimentOption = activeResearch;
      }
    }
  }

  const showParametersNamesValue = inputWidgetFilters.showParametersNames;
  if (typeof (showParametersNamesValue) === 'undefined') {
    showParametersNames = true;
  } else {
    if (showParametersNamesValue) {
      showParametersNames = true;
    } else {
      showParametersNames = false;
    }
  }

  const hideEmptyCardsValue = inputWidgetFilters.hideEmptyCards;
  if (typeof (hideEmptyCardsValue) === 'undefined') {
    hideEmptyCards = true;
  } else {
    if (hideEmptyCardsValue) {
      hideEmptyCards = true;
    } else {
      hideEmptyCards = false;
    }
  }

  const showParticipantsValue = inputWidgetFilters.showParticipants;
  if (typeof (showParticipantsValue) === 'undefined') {
    showParticipants = true;
  } else {
    if (showParticipantsValue) {
      showParticipants = true;
    } else {
      showParticipants = false;
    }
  }

  const updatedStateObject = {
    activeColleagues: activeColleagues,
    individualParameters: individualParameters,
    relativeParameters: relativeParameters,
    activeExperiment: activeExperiment,
    activeExperimentOption: activeExperimentOption,
    showParametersNames: showParametersNames,
    hideEmptyCards: hideEmptyCards,
    showParticipants: showParticipants
  };
  return updatedStateObject;
}