import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { CopyLocalCardHelper } from 'app/_helpers/copy-local-card';
import { SortQuestionnaireHelper } from 'app/_helpers/sort-questionnaire-helper';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { CardAction } from 'app/_models/card-action';
import { Questionnaire } from 'app/_models/questionnaire';
import { DemoParameter } from 'app/_models/demo-parameter';
import { Package } from 'app/_models/package';
import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { Department } from 'app/_models/department';
import { Colleague } from 'app/_models/colleague';
import { CompanyService } from 'app/_services/company.service';

class CheckboxOption extends CardOption {
  selected: boolean = false;
  cardId: number = 0;
}

class Selection {
  cardId: number;
  cardOrder: number;
  cardType: string;
  selectedAnswers: ColleagueCardAnswer[];
}

@Component({
  selector: 'app-questionnaire-user-view',
  templateUrl: './questionnaire-user-view.component.html',
  styleUrls: ['./questionnaire-user-view.component.scss']
})
export class QuestionnaireUserViewComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;
  // @Input() inputPackageId: number;
  inputPackageId: number;
  inputSetId: number;

  isTextQuestion: boolean = false;
  isOptionsQuestion: boolean = false;
  isScoreQuestion: boolean = false;
  isRelativeQuestion: boolean = false;
  isRelativeScoreQuestion: boolean = false;
  isRelativeOptionsQuestion: boolean = false;
  isCheckboxesQuestion: boolean = false;
  isRelativeCheckboxesQuestion: boolean = false;
  isInfoCard: boolean = false;
  isLastQuestion: boolean = false;
  isCompleted: boolean = false;
  shouldNotify: boolean = false;

  isScoreMulticolor: boolean = false;
  multicolorGroupSize: number = 3;
  questionnaireId: number = 0;
  questionNumber: number = 1;
  questionnaireCards: QuestionnaireCard[] = [];
  activeCard: QuestionnaireCard;
  cardError: string = '';
  chosenOption: CardOption;
  textOption: CardOption;
  checkboxOptions: CheckboxOption[] = [];
  textInput: string = '';
  answerState: CardAction[] = [];
  // resultState: DemoParameter[] = [];
  resultStateIndividual: DemoParameter[] = [];
  resultStateCommon: DemoParameter[] = [];
  resultStateRelative: DemoParameter[] = [];
  postActionsList: PostQuestionnaireAction[] = [];
  selectionState: Selection[] = [];
  selectedDepartmentIndex: string = '';
  selectedColleagueIndex: string = '';
  lastRelativeColleagueIndex: string = '';
  inputDepartments: Department[] = [];
  inputColleagues: Colleague[] = [];
  activeColleagues: Colleague[] = [];

  constructor(
    private packageService: PackageService,
    private companyService: CompanyService,
    private questionnaireService: QuestionnaireService,
    private experimentService: ExperimentService,
    private copyLocalCardHelper: CopyLocalCardHelper,
    private sortQuestionnaireHelper: SortQuestionnaireHelper,
    private sharedPackageService: SharedPackageService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    // this.getPackageInfo(this.inputPackageId);
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        const packageId = params['package_id'];
        const setId = params['set_id'];
        if (packageId) {
          this.inputPackageId = packageId;
          this.getPackageInfo(packageId);
        } else if (setId) {
          this.inputSetId = setId;
          this.getPackageInfo(setId);
        }
      });

    this.companyService.getColleaguesList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultColleagues: Colleague[]) => {
        this.inputColleagues = resultColleagues;
      });

    this.companyService.getCompanyDepartments()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultDepartments: Department[]) => {
        this.inputDepartments = resultDepartments;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPackageInfo(packageId: number): void {
    if (packageId) {
      this.packageService.getPackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((packageInfo: Package) => {
          if (packageInfo && packageInfo.questionnaire) {
            const questionnaire = packageInfo.questionnaire;
            this.getQuestionnaire(questionnaire.id);
            this.postActionsList = packageInfo.postQuestionnaireActions;
          }
        },
        error => {
          console.log('Package info', error);
        });
    }
  }

  getQuestionnaire(questionnaireId: number): void {
    if (questionnaireId) {
      this.questionnaireId = questionnaireId;

      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((questionnaireInfo: Questionnaire) => {
          if (questionnaireInfo) {
            const rawCards = questionnaireInfo.questionnaireCards.filter(singleCard => singleCard.isActive);
            if (rawCards) {
              const sortedCards = this.sortQuestionnaireHelper.sortQuestionnaireCards(rawCards);
              this.questionnaireCards = sortedCards;
            }
            const card: QuestionnaireCard = this.findCardByOrder(1);
            this.showCard(card);
          }
        },
        error => {
          console.log('Questionnaire error ', error);
        });
    }
  }

  updateQuestionType(cardType: string): void {
    this.isTextQuestion = false;
    this.isScoreQuestion = false;
    this.isCheckboxesQuestion = false;
    this.isOptionsQuestion = false;
    this.isInfoCard = false;
    this.isRelativeQuestion = false;
    this.isRelativeScoreQuestion = false;
    this.isRelativeOptionsQuestion = false;
    this.isRelativeCheckboxesQuestion = false;

    if (cardType === 'options') {
      this.isOptionsQuestion = true;
    } else if (cardType === 'relative-options') {
      this.isRelativeOptionsQuestion = true;
    } else if (cardType === 'text-input') {
      this.isTextQuestion = true;
    } else if (cardType === 'relative') {
      this.isRelativeQuestion = true;
    } else if (cardType === 'relative-score') {
      this.isRelativeScoreQuestion = true;
    } else if (cardType === 'score') {
      this.isScoreQuestion = true;
    } else if (cardType === 'checkboxes') {
      this.isCheckboxesQuestion = true;
    } else if (cardType === 'relative-checkboxes') {
      this.isRelativeCheckboxesQuestion = true;
    } else if (cardType === 'info-card') {
      this.isInfoCard = true;
    }
  }

  findCardByOrder(orderPosition: number): QuestionnaireCard {
    const targetCard = this.questionnaireCards.find(card => card.orderPosition === orderPosition);
    return targetCard;
  }

  findSelectionByOrder(orderPosition: number): Selection {
    const resultSelection = this.selectionState.find(selection => selection.cardOrder === orderPosition);
    return resultSelection;
  }

  setSelectedDepartment(): void {
    if (this.selectedDepartmentIndex) {
      const departmentId = Number(this.selectedDepartmentIndex);
      // this.selectedDepartmentId = departmentId;
      this.activeColleagues = this.inputColleagues.filter(singleColleague => singleColleague.departmentId === departmentId);
    }
  }

  setSelectedColleague(): void {
    // console.log('Set selected colleague ', this.selectedColleagueId);
  }

  toPreviousQuestion(): void {
    const currentCardPosition = this.activeCard.orderPosition;
    const isAnswered = this.checkIsAnswered();
    if (isAnswered) {
      this.formSelection(currentCardPosition);
    }

    this.selectedColleagueIndex = '';
    this.selectedDepartmentIndex = '';

    const index = this.selectionState.findIndex(selection => selection.cardOrder === currentCardPosition);

    let prevQuestionNumber = currentCardPosition - 1;
    if (index > 1) {
      const prevSelection = this.selectionState[index - 1];
      prevQuestionNumber = prevSelection.cardOrder;
    }
    this.questionNumber = prevQuestionNumber;
    const card: QuestionnaireCard = this.findCardByOrder(prevQuestionNumber);
    if (card) {
      this.showCard(card);
    } else {
      // this.cardError = 'Couldn\'t find a next card. Please, check order positions of your cards.';
      this.cardError = this.addCardMessage('next_card');
    }
  }

  toNextQuestion(): void {
    const isRequired = this.activeCard.isRequired;
    const isAnswered = this.checkIsAnswered();
    if (isAnswered || (!isRequired)) {
      const currentCardPosition = this.activeCard.orderPosition;
      if (isAnswered) {
        this.formSelection(currentCardPosition);
      }
      
      this.lastRelativeColleagueIndex = this.selectedColleagueIndex;
      this.selectedColleagueIndex = '';
      this.selectedDepartmentIndex = '';

      const allActions = this.findAllActions(this.activeCard);
      const gotoQuestion = this.checkGotoSwitch(allActions);

      let nextQuestionNumber = currentCardPosition + 1;
      if (gotoQuestion && gotoQuestion > 0) {
        nextQuestionNumber = gotoQuestion;
        this.removeQuestionsFromSelection(this.questionNumber, nextQuestionNumber);
      }
      this.questionNumber = nextQuestionNumber;
      const card: QuestionnaireCard = this.findCardByOrder(nextQuestionNumber);
      if (card) {
        this.showCard(card);
      } else if (this.isLastQuestion) {
        this.isCompleted = true;
        this.calculateAnswerResult();
      } else {
        // this.cardError = 'Couldn\'t find a next card. Please, check order positions of your cards.';
        this.cardError = this.addCardMessage('next_card');
      }
    } else {
      // this.cardError = 'Did you forget to answer?';
      this.cardError = this.addCardMessage('no_answer');
    }
  }

  checkIsAnswered(): boolean {
    if (this.isOptionsQuestion && this.chosenOption) {
      return true;
    } else if (this.isRelativeOptionsQuestion && this.chosenOption) {
      return true;
    } else if (this.isTextQuestion && this.textOption && this.textInput) {
      return true;
    } else if (this.isRelativeQuestion || this.isRelativeScoreQuestion) {
      if (this.selectedColleagueIndex && this.textOption && this.textInput) {
        return true;
      } else {
        return false;
      }
    } else if ((this.isCheckboxesQuestion) || this.isRelativeCheckboxesQuestion) {
      for (const singleOption of this.checkboxOptions) {
        if (singleOption.selected) { return true; }
      }
    } else if (this.isScoreQuestion && this.textOption && this.textInput) {
      return true;
    } else if (this.isInfoCard) {
      return true;
    }

    return false;
  }

  removeQuestionsFromSelection(firstQuestionNum: number, secondQuestionNum: number): void {
    const newSelectionState = [];
    for (const singleSelection of this.selectionState) {
      const cardOrder = singleSelection.cardOrder;
      if (cardOrder <= firstQuestionNum || cardOrder >= secondQuestionNum) {
        newSelectionState.push(singleSelection);
      }
    }
    this.selectionState = newSelectionState;
  }

  checkGotoSwitch(actions: CardAction[]): number {
    for (const singleAction of actions) {
      if (singleAction.parameter === 'goto') {
        const splitValue = singleAction.text.split('#');
        if (splitValue[1]) {
          const nextCardIndex = parseInt(splitValue[1], 10);
          return nextCardIndex;
        }
      }
    }

    return 0;
  }

  findAllActions(activeCard: QuestionnaireCard): CardAction[] {
    const actions: CardAction[] = [];
    const cardType = activeCard.cardType;

    if ((cardType === 'options') || (cardType === 'relative-options')) {
      for (const singleAction of this.chosenOption.cardActions) {
        actions.push(singleAction);
      }
    } else if ((cardType === 'text-input') || (cardType === 'relative')) {
      const textAction = this.textOption.cardActions[0];
      if (textAction) {
        actions.push(textAction);
      }
    } else if ((cardType === 'checkboxes') || (cardType === 'relative-checkboxes')) {
      for (const singleOption of this.checkboxOptions) {
        if (singleOption.selected) {
          for (const singleAction of singleOption.cardActions) {
            actions.push(singleAction);
          }
        }
      }
    } else if ((cardType === 'score') || (cardType === 'relative-score')) {
      const textAction = this.textOption.cardActions[0];
      if (textAction) {
        actions.push(textAction);
      }
    }

    return actions;
  }

  showCard(inputCard: QuestionnaireCard): void {
    const localCard = this.copyLocalCardHelper.copyQuestionnaireCard(inputCard);
    if (localCard) {
      this.activeCard = localCard;
      this.updateQuestionType(localCard.cardType);
      this.emptyActiveState();

      if (inputCard.orderPosition === this.questionnaireCards.length) {
        this.isLastQuestion = true;
      }

      if ((localCard.cardType === 'checkboxes') || (localCard.cardType === 'relative-checkboxes')) {
        const cardId = localCard.id;
        this.formCheckboxOptions(cardId, localCard.cardOptions);
      } else if (localCard.cardType === 'text-input') {
        this.textOption = this.activeCard.cardOptions[0];
      } else if (localCard.cardType === 'score') {
        this.textOption = this.activeCard.cardOptions[0];
      } else if (localCard.cardType === 'relative') {
        this.textOption = this.activeCard.cardOptions[0];
      } else if (localCard.cardType === 'relative-score') {
        this.textOption = this.activeCard.cardOptions[0];
      }

      const colorScheme = this.activeCard.divisionsColorScheme;
      if (colorScheme === 'multicolor') {
        this.isScoreMulticolor = true;
      } else {
        this.isScoreMulticolor = false;
      }
      const divisionsLength = this.activeCard.scoreDivisions.length;
      this.multicolorGroupSize = divisionsLength;

      this.restoreSelection();
    }
  }

  emptyActiveState(): void {
    this.chosenOption = null;
    this.textOption = null;
    this.checkboxOptions = [];
    this.textInput = '';
    this.cardError = '';
    this.isLastQuestion = false;
  }

  restoreSelection(): void {
    const currentCardPosition = this.activeCard.orderPosition;
    const selection = this.findSelectionByOrder(currentCardPosition);
    if (selection) {
      if (selection.cardType === 'options') {
        const answer = selection.selectedAnswers[0];
        const optionId = answer.chosenOptionId;
        const chosenOption = this.activeCard.cardOptions.find(option => option.id === optionId);
        this.chosenOption = chosenOption;
      } else if (selection.cardType === 'relative-options') {
        const answer = selection.selectedAnswers[0];
        const optionId = answer.chosenOptionId;
        const chosenOption = this.activeCard.cardOptions.find(option => option.id === optionId);
        this.chosenOption = chosenOption;
        this.restoreDepartmentColleague(answer.relativeColleagueId);
      } else if (selection.cardType === 'text-input') {
        this.textOption = this.activeCard.cardOptions[0];
        const selectionAnswer = selection.selectedAnswers[0];
        this.textInput = selectionAnswer.userInput;
      } else if (selection.cardType === 'checkboxes') {
        this.restoreCheckboxes(selection);
      } else if (selection.cardType === 'relative-checkboxes') {
        this.restoreCheckboxes(selection);
        const answer = selection.selectedAnswers[0];
        this.restoreDepartmentColleague(answer.relativeColleagueId);
      } else if (selection.cardType === 'score') {
        this.textOption = this.activeCard.cardOptions[0];
        const selectionAnswer = selection.selectedAnswers[0];
        this.textInput = selectionAnswer.userInput;
      } else if (selection.cardType === 'relative') {
        this.textOption = this.activeCard.cardOptions[0];
        const selectionAnswer = selection.selectedAnswers[0];
        this.textInput = selectionAnswer.userInput;
        this.restoreDepartmentColleague(selectionAnswer.relativeColleagueId);
      } else if (selection.cardType === 'relative-score') {
        this.textOption = this.activeCard.cardOptions[0];
        const selectionAnswer = selection.selectedAnswers[0];
        this.textInput = selectionAnswer.userInput;
        this.restoreDepartmentColleague(selectionAnswer.relativeColleagueId);
      }
    } else if (this.activeCard) {
      const cardType = this.activeCard.cardType;
      if ((cardType === 'relative-score') || (cardType === 'relative') 
      || (cardType === 'relative-options') || (cardType === 'relative-checkboxes')) {
        const lastId = Number(this.lastRelativeColleagueIndex);
        if (lastId) {
          this.restoreDepartmentColleague(lastId);
        }
      }
    }
  }

  restoreDepartmentColleague(relativeColleagueId: number): void {
    const findColleague = this.inputColleagues.find(colleague => colleague.id === relativeColleagueId);
    if (findColleague) {
      const findDepartment = this.inputDepartments.find(dep => dep.id === findColleague.departmentId);
      if (findDepartment) {
        this.selectedDepartmentIndex = String(findDepartment.id);
      } else {
        this.selectedDepartmentIndex = '0';
      }
    }
    this.setSelectedDepartment();
    this.selectedColleagueIndex = String(relativeColleagueId);
  }

  restoreCheckboxes(selection: Selection): void {
    for (const singleAnswer of selection.selectedAnswers) {
      const optionId = singleAnswer.chosenOptionId;
      for (const singleOption of this.checkboxOptions) {
        if (singleOption.id === optionId) {
          singleOption.selected = true;
        }
      }
    }
  }

  formCheckboxOptions(cardId: number, cardOptions: CardOption[]): void {
    this.checkboxOptions = [];
    for (const singleOption of cardOptions) {
      const localCheckboxOption: CheckboxOption = {
        ...singleOption,
        selected: false,
        cardId: cardId
      };
      this.checkboxOptions.push(localCheckboxOption);
    }
  }

  radioChange(event: any): void {
    // console.log('event ', event);
  }

  formSelection(currentCardPosition: number): void {
    const selectedAnswers: ColleagueCardAnswer[] = [];
    if ((this.isOptionsQuestion) || this.isRelativeOptionsQuestion) {
      const answer = this.formSingleSelectionAnswer(this.chosenOption.id);
      selectedAnswers.push(answer);
    } else if ((this.isCheckboxesQuestion) || (this.isRelativeCheckboxesQuestion)) {
      for (const singleOption of this.checkboxOptions) {
        if (singleOption.selected) {
          const answer = this.formSingleSelectionAnswer(singleOption.id);
          selectedAnswers.push(answer);
        }
      }
    } else if ((this.isTextQuestion) || (this.isRelativeQuestion)) {
      const answer = this.formSingleSelectionAnswer(this.textOption.id);
      selectedAnswers.push(answer);
    } else if (this.isScoreQuestion || this.isRelativeScoreQuestion) {
      const answer = this.formSingleSelectionAnswer(this.textOption.id);
      selectedAnswers.push(answer);
    } else {
      // this.cardError = 'Did you forget to answer?';
      this.cardError = this.addCardMessage('no_answer');
    }

    const newSelection: Selection = {
      cardId: this.activeCard.id,
      cardOrder: this.activeCard.orderPosition,
      cardType: this.activeCard.cardType,
      selectedAnswers: selectedAnswers
    };

    const selectionLength = this.selectionState.length;
    const index = this.selectionState.findIndex(selection => selection.cardId === newSelection.cardId);
    if (index > 0) {
      this.selectionState[index] = newSelection;
    } else {
      if (selectionLength < currentCardPosition) {
        this.selectionState.push(newSelection);
      } else {
        this.insertInSelection(currentCardPosition, newSelection);
      }
    }
  }

  insertInSelection(currentCardPosition: number, newSelection: Selection): void {
    if (currentCardPosition > 1) {
      const newSelectionState = [];
      for (const singleSelection of this.selectionState) {
        if (singleSelection.cardOrder < currentCardPosition) {
          newSelectionState.push(singleSelection);
        }
      }
      newSelectionState.push(newSelection);
      for (const singleSelection of this.selectionState) {
        if (singleSelection.cardOrder > currentCardPosition) {
          newSelectionState.push(singleSelection);
        }
      }
      this.selectionState = newSelectionState;
    }
  }

  updateSelection(currentCardPosition: number, newSelection: Selection): void {
    const newSelectionState: Selection[] = [];
    for (const singleSelection of this.selectionState) {
      if (singleSelection.cardOrder === currentCardPosition) {
        newSelectionState.push(newSelection);
      } else {
        newSelectionState.push(singleSelection);
      }
    }
    this.selectionState = newSelectionState;
  }

  formSingleSelectionAnswer(optionId: number): ColleagueCardAnswer {
    const selectionAnswer: ColleagueCardAnswer = {
      id: 0,
      experimentAnswerId: 0,
      questionnaireCardId: this.activeCard.id,
      chosenOptionId: optionId,
      orderPosition: this.activeCard.orderPosition,
      userInput: this.textInput,
      anonymous: true,
      relativeColleagueId: Number(this.selectedColleagueIndex) || 0,
    };

    // console.log('Form selection ', selectionAnswer);
    return selectionAnswer;
  }

  calculateAnswerResult(): void {
    const id = this.questionnaireId;

    // console.log('Selection state ', this.selectionState);
    const demoAnswers: ColleagueCardAnswer[] = [];
    for (const singleSelection of this.selectionState) {
      for (const singleAnswer of singleSelection.selectedAnswers) {
        demoAnswers.push(singleAnswer);
      }
    }

    const postActionsIdsList = [];
    for (const postAction of this.postActionsList) {
      postActionsIdsList.push(postAction.id);
    }

    this.experimentService.calculateDemoModeParameters(id, demoAnswers, postActionsIdsList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((demoParameters: DemoParameter[]) => {
        // console.log('Demo parameters ', demoParameters);
        // this.resultState = demoParameters;
        this.separateResultState(demoParameters);
      },
      error => {
        console.log('Answer error ', error);
      });
  }

  separateResultState(demoParameters: DemoParameter[]): void {
    for (const singleParameter of demoParameters) {
      if (singleParameter.relativeColleagueId > 0) {
        this.resultStateRelative.push(singleParameter);
      } else {
        if (singleParameter.colleagueId === 0) {
          this.resultStateIndividual.push(singleParameter);
        } else {
          this.resultStateCommon.push(singleParameter);
        }
      }
    }
  }

  getColleagueName(param: DemoParameter): string {
    const findColleague = this.inputColleagues.find(coll => coll.id === param.relativeColleagueId);
    if (findColleague) {
      return findColleague.firstName + ' ' + findColleague.lastName;
    }
    return '';
  }

  goBack(): void {
    if (this.inputPackageId) {
      const newUrl = `/questionnaires/${this.inputPackageId}/questions`;
      location.replace(newUrl);
    } else if (this.inputSetId) {
      const newUrl = `/standard-sets/${this.inputSetId}/questions`;
      location.replace(newUrl);
    }
  }

  addCardMessage(messageType: string): string {
    if (messageType === 'next_card') {
      const enMessage = 'Couldn\'t find a next card. Please, check order positions of your cards.';
      const ruMessage = 'Невозможно найти следующий вопрос. Пожалуйста, проверьте порядок вопросов.';
      if (this.isRussianLocale()) {
        return ruMessage;
      } else {
        return enMessage;
      }
    } else if (messageType === 'no_answer') {
      const enMessage = 'This question is required. Please give an answer';
      const ruMessage = 'Это обязательный вопрос. Пожалуйста, дайте ответ.';
      if (this.isRussianLocale()) {
        return ruMessage;
      } else {
        return enMessage;
      }
    } else {
      console.log('Error: Wrong message type');
      return '';
    }
  }

  // isRussianLocale(): boolean {
  //   const lang = this.sharedPackageService.currentPackageLanguage.getValue();
  //   if (lang === 'ru') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  isRussianLocale(): boolean {
    const isRu = this.checkUrlForSubstring('/ru/');
    if (isRu) {
      return true;
    } else {
      return false;
    }
  }

  checkUrlForSubstring(substring: string): boolean {
    const link = window.location.href;
    if (substring && link.indexOf(substring) > -1) {
      return true;
    } else {
      return false;
    }
  }

}
