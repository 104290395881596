import { Number, String, Record, Null, Array } from 'runtypes';
import { ApiParameterData } from './api-parameter-data';


export const ApiExtendedParameter = Record({
  id: Number,
  uuid: String,
  formula_name: String,
  name: String,
  type: String,
  formula: String.Or(Null),
  parameter_data: Array(ApiParameterData).Or(Null)
});
