<div layout="row" layout-padding>

<div flex>
  <h2 class="title" i18n="@@profile">Profile</h2>
	<mat-card class="mat-title">

    <ng-container *ngIf="isAdmin">
      <button mat-raised-button color="accent" (click)="sendinblueTestEmail()" i18n="@@sendTestEmail">
        Send test mail
      </button>
    </ng-container>

    <div class="user-photo-block">
      
      <button  mat-fab class="account-button avatar-icon" (click)="onUpdate()">  <!-- TODO function update photo-->
        <!--<img *ngIf="userAvatar" [src]='avatar'  #avatar="ngModel" [(ngModel)]="userAvatar"  />-->
        <!--<span *ngIf="!userAvatar">{{userInitialLetters}}</span>-->
        <span>{{userInitialLetters}}</span>
      </button>

      <!--<div>    TODO Should be hidden for the fires release -->
      <!--<label mat-button class="account-button avatar-manage-btn" (click)="onUpdate()" i18n="@@uploadNewPhoto">  -->
      <!--Upload new photo-->
      <!--</label>-->
      <!--<label mat-button class="account-button avatar-manage-btn" (click)="onUpdate()" i18n="@@deletePhoto">  &lt;!&ndash; TODO function delete photo and hide if avatar doesn't exist&ndash;&gt;-->
      <!--Delete photo-->
      <!--</label>-->
      <!--</div>-->
    </div>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder" i18n="@@placeholder-firstName">First Name</mat-placeholder>
      <input matInput [(ngModel)]="userFirstName" />
    </mat-form-field>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder" i18n="@@placeholder-lastName">Last name</mat-placeholder>
      <input matInput [(ngModel)]="userLastName" />
    </mat-form-field>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder">Email</mat-placeholder>
      <input matInput [(ngModel)]="userEmail" />
    </mat-form-field>

    <mat-form-field class="card-item">
      <mat-placeholder class="placeholder" i18n="@@placeholder-password">Password</mat-placeholder>
      <input matInput type="password" [(ngModel)]="userPassword" />
    </mat-form-field>

		<!--<section class="slider-section">  !TODO Should be hidden for the first release -->
    	<!--<mat-slide-toggle-->
        <!--[color]="color"-->
        <!--[(ngModel)]="showNotifications">-->
          <!--Show notifications-->
      <!--</mat-slide-toggle>-->
    <!--</section>-->

    <!--<section class="slider-section">-->
      <!--<mat-slide-toggle-->
        <!--[color]="color"-->
        <!--[(ngModel)]="showThumbnails">-->
          <!--Show thumbnails-->
      <!--</mat-slide-toggle>-->
    <!--</section>-->

		<button mat-raised-button color="accent" class="default-card-button" (click)="requestPassword()" i18n="@@requestNewPassword">
      Request new password
    </button>

		<button mat-raised-button color="accent" class="default-card-button" (click)="onUpdate()" i18n="@@updateAccount">
      Update account
    </button>
		<button mat-raised-button color="primary" class="default-card-button" (click)="onClose()" i18n="@@cancelButton">
      Cancel
    </button>

    <div class="info-bar mat-body-1" *ngFor="let error of infoBar; let i = index;">
      {{error}}
    </div>
		<!-- <p class="mat-body-1">
      <div class="info-bar" *ngFor="let error of infoBar; let i = index;">
        {{error}}
      </div>
		</p> -->
	</mat-card>
</div>

<div flex="30"></div>
</div>