import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'app/snack-bar/';

import { UserService } from 'app/_services/user.service';
import { UtilsService } from 'app/_services/utils.service';
import { SharedService } from 'app/_services/shared.service';

import { ExtendedColleague } from './local-models';
import { User } from 'app/_models/user';
import { Package } from 'app/_models/package';
import { Department } from 'app/_models/department';
import { PackageParameter } from 'app/_models/package-parameter';
import { ExtendedParameter, PackageData } from 'app/colleague-profile/local-models';
import { SharedUserService } from 'app/_services/shared-user.service';

@Component({
  selector: 'app-colleagues-page',
  templateUrl: './colleagues-page.component.html',
  styleUrls: ['./colleagues-page.component.scss'],
  providers: [SnackBarComponent]
})
export class ColleaguesPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  departments: Department[] = [];
  colleaguesList: ExtendedColleague[] = [];
  archivedColleaguesList: ExtendedColleague[] = [];
  packages: Package[] = [];
  verbosePackages: Package[] = [];
  packageData: PackageData[] = [];
  packageParameters: PackageParameter[] = [];
  extendedParameters: ExtendedParameter[] = [];
  companyId: number = 0;
  loadingQueue: number = 0;
  hasActiveCompany: boolean = true;
  activeLink: string = 'colleagues';

  constructor(
    private snackBar: SnackBarComponent,
    private utilsService: UtilsService,
    private sharedService: SharedService,
    private sharedUserService: SharedUserService
  ) {}

  ngOnInit(): void {
    this.getCurrentTab();
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCurrentTab(): void {
    const isArchiveTab = this.utilsService.checkUrlForSubstring('archive');
    const isDepartmentManagementTab = this.utilsService.checkUrlForSubstring('department-management');

    if (isArchiveTab) {
      this.activeLink = 'archive';
    } else if (isDepartmentManagementTab) {
      this.activeLink = 'department-management';
    } else {
      this.activeLink = 'colleagues';
    }
  }

  changeUrl(tabValue: number): void {
    if (tabValue === 0) {
      this.activeLink = 'colleagues';
    } else if (tabValue === 1) {
      this.activeLink = 'department-management';
    } else if (tabValue === 2) {
      this.activeLink = 'archive';
    }
  }

  getUserInfo(): void {
    this.sharedUserService.currentUser$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultUser: User) => {
        if (resultUser && resultUser.activeCompanyId) {
          this.hasActiveCompany = true;
          this.companyId = resultUser.activeCompanyId;
        }
      },
      error => {
        console.log('User info', error);
        this.sharedService.checkSignatureExpired(error);
      });
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }
}
