<div class="extended-parameter">
  <div class="extended-parameter__name">{{parameter.name}}</div>
  <div class="extended-parameter__content">
    <ng-container *ngIf="parameter.parameterData.length > 0">
      <div class="data" *ngFor="let parameterData of parameter.parameterData">
        <div class="data__item data__item--date">Date: {{parameterData.createdAt}}</div>
        <div class="data__item data__item--value">Value: {{parameterData.value}}</div>
        <div class="data__item data__item--data">Data: {{parameterData.data}}</div>
      </div>
    </ng-container>
    <app-create-parameter-data 
      [parameter]="parameter"
      [authorUuid]="authorUuid"
      [sourceUuid]="sourceUuid"
      [entityUuid]="entityUuid">
  </app-create-parameter-data>
  </div>
</div>
