import { ApiColleague } from './api-colleague';
import { Number, String, Record, Array, Partial } from 'runtypes';

export const ApiDepartment = Record({
  id: Number,
  uuid: String,
  name: String,
  company_id: Number
}).And(Partial({
  colleagues: Array(ApiColleague),
}));
