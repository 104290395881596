import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

// store api key here for convenience
// since it isn't really sensitive information
const amplitude = require('amplitude-js');
amplitude.getInstance().init('0aa19dea9ca6fad58fa21e32f5854dff');

@Injectable({
  providedIn: 'root'
})
export class AmplitudeEventsService {

  constructor() { }

  addEvent(eventName: string): void {
    if (environment.envType.isProduction()) {
      amplitude.getInstance().logEvent('production ' + eventName);
    } else if (environment.envType.isStaging()) {
      amplitude.getInstance().logEvent('staging ' + eventName);
    } else if (environment.envType.isDevelopment()) {
      // amplitude.getInstance().logEvent('test ' + eventName);
    }
  }

  addVerboseEvent(eventName: string, eventInfo: any): void {
    if (environment.envType.isProduction()) {
      amplitude.getInstance().logEvent('production ' + eventName, eventInfo);
    } else if (environment.envType.isStaging()) {
      amplitude.getInstance().logEvent('staging ' + eventName, eventInfo);
    } else if (environment.envType.isDevelopment()) {
      // amplitude.getInstance().logEvent('test ' + eventName, eventInfo);
    }
  }

  addDevelopmentEvent(eventInfo: any): void {
    if (environment.envType.isDevelopment()) {
      amplitude.getInstance().logEvent(eventInfo);
    }
  }
}
