import { environment } from '../../environments/environment';
import { ColleagueNote } from 'app/_models/colleague-note';
import { ApiColleagueNote } from 'app/_api_models/api-colleague-note';

export function parseApiColleagueNote(apiResponse: any): ColleagueNote {
  let colleagueNote;

  if (environment.production) {
    colleagueNote = apiResponse;
  } else {
    colleagueNote = ApiColleagueNote.check(apiResponse);
  }

  const newUser = {
    firstName: colleagueNote.user.name,
    lastName: colleagueNote.user.last_name,
    email: colleagueNote.user.email
  }

  const newColleagueNote: ColleagueNote = {
    id: colleagueNote.id,
    title: colleagueNote.title,
    content: colleagueNote.content,
    userId: colleagueNote.user_id,
    colleagueId: colleagueNote.colleague_id,
    updatedAt: colleagueNote.updated_at,
    user: newUser
  };

  return newColleagueNote;
}
