<div class="card-wrapper" flex>
  <div *ngIf="isOptions" class="question-card">
    <div 
      class="remove-all-styles" 
      *ngIf="card.questionText" 
      [innerHtml]="card.questionText | safeHtml">
    </div>
    <mat-radio-group class="radio-group" [(ngModel)]="chosenRadioOption">
      <mat-radio-button *ngFor="let option of card.cardOptions" [value]="option" (change)="radioChange($event)">
        {{option.text}}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="isTextQuestion||isRelativeQuestion" class="question-card">
    <div 
      class="remove-all-styles" 
      *ngIf="card.questionText" 
      [innerHtml]="card.questionText | safeHtml">
    </div>
    <mat-form-field>
      <textarea 
        class="forautosize" 
        [(ngModel)]="textInputValue" 
        matInput 
        autocomplete="off" 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" 
        cdkAutosizeMinRows="3" 
        cdkAutosizeMaxRows="50">
      </textarea>
    </mat-form-field>
  </div>

  <div *ngIf="isInfoCard" class="question-card">
    <div 
      class="remove-all-styles" 
      *ngIf="card.questionText" 
      [innerHtml]="card.questionText | safeHtml">
    </div>
  </div>

  <div *ngIf="isScoreQuestion" class="question-card score-group-wrap">
    <div
      class="remove-all-styles" 
      *ngIf="card.questionText" 
      [innerHtml]="card.questionText | safeHtml">
    </div>
    <ng-container *ngIf="isScoreMulticolor">
      <mat-radio-group class="score-group-multicolor" [(ngModel)]="textOption">
        <mat-radio-button *ngFor="let option of scoreDivisions" class="radio-group-{{multicolorGroupSize}}" [value]="option.value">
          {{option.label}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <ng-container *ngIf="!isScoreMulticolor">
      <mat-radio-group class="score-group" [(ngModel)]="textOption">
        <mat-radio-button *ngFor="let option of scoreDivisions" [value]="option.value">
          {{option.label}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>

  <div *ngIf="isCheckboxesQuestion" class="question-card">
    <div 
      class="remove-all-styles" 
      *ngIf="card.questionText" 
      [innerHtml]="card.questionText | safeHtml">
    </div>
    <div class="checkboxes-group">
      <mat-checkbox *ngFor="let checkboxOption of checkboxOptions" [value]="checkboxOption"
        [(ngModel)]="checkboxOption.selected" (change)="changeCheckboxOption($event)">
        {{checkboxOption.text}}
      </mat-checkbox>
    </div>
  </div>

  <p style="color: red">
    {{errorList[card.orderPosition - 1]}}
  </p>

  <div class="question-card actions" *ngIf="adminMode">
    <span *ngIf="cardActionsCount < 1" i18n="@@noActions">you have no actions for this option</span>
    <ng-container *ngIf="resultActions && cardActionsCount > 0">
      <span *ngFor="let singleAction of resultActions">
        <span>{{singleAction.parameter}}</span>
        <i class="material-icons arrow-rotated">
          keyboard_backspace
        </i>
        <span>{{singleAction.text}}</span>
      </span>
    </ng-container>
  </div>

</div>