import { Company } from 'app/_models/company';
import { ApiCompany } from 'app/_api_models/api-company';
import { Department } from 'app/_models/department';
import { parseApiDepartment } from 'app/_api_validation/parse-api-department';
import { environment } from '../../environments/environment';

export function parseApiCompany(apiResponse: any, nestedModels: boolean): Company {
  let companyObject;
  if (environment.production) {
    companyObject = apiResponse;
  } else {
    companyObject = ApiCompany.check(apiResponse);
  }

  // let packages: Package[] = [];
  const departments: Department[] = [];
  if (nestedModels) {
    const apiDepartments = apiResponse.departments;
    if (apiDepartments) {
      for (const singleDepartment of apiDepartments) {
        const newDepartment = parseApiDepartment(singleDepartment, false);
        departments.push(newDepartment);
      }
    }
  }

  const newCompany: Company = {
    id: companyObject.id,
    uuid: companyObject.uuid,
    name: companyObject.name,
    ownerEmail: companyObject.owner_email,
    ownerId: companyObject.owner_id,
    slackIntegrationToken: companyObject.slack_integration_token,
    departments: departments,
    logo: companyObject.logo,
    colleagueSharedLink: companyObject.colleague_shared_link
  };

  return newCompany;
}
