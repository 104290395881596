import { Component, Input, OnInit } from '@angular/core';
import { ExtendedParameter } from 'app/_models/overmind_ds/extended-parameter';

@Component({
  selector: 'app-extended-parameter',
  templateUrl: './extended-parameter.component.html',
  styleUrls: ['./extended-parameter.component.scss']
})
export class ExtendedParameterComponent implements OnInit {
  @Input('parameter') parameter: ExtendedParameter = null;
  @Input('authorUuid') authorUuid: string = '';
  @Input('sourceUuid') sourceUuid: string = '';
  @Input('entityUuid') entityUuid: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
