import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/_guards/auth.guard';
import { LoginGuard } from 'app/_guards/login.guard';
import { DashboardEditorComponent } from 'app/_dashboards/dashboard-editor/';
import { DashboardUserViewComponent } from 'app/_dashboards/dashboard-user-view/';
import { DashboardsListComponent } from 'app/dashboards-list';
import { CreateNewDashboardComponent } from 'app/dashboards-list/create-new-dashboard';
import { DashboardColleagueComponent } from 'app/dashboard-colleague';
import { AdminSettingsPageComponent } from 'app/admin-settings-page';

import { PackageDetailsComponent } from 'app/package-details/';
import { PackageParametersComponent } from 'app/package-details/parameters/';
import { PackageSettingsComponent } from 'app/package-details/settings/';
import { PackageQuestionnaireComponent } from 'app/package-details/questionnaire/';
import { PackageResearchesComponent } from 'app/package-details/researches/';
import { PackageParticipantsComponent } from 'app/package-details/participants/';
import { ExcelViewComponent } from 'app/package-details/excel-view';
import { PublicPackagesComponent} from 'app/public-packages/';
import { QuestionnaireUserViewComponent } from 'app/package-details/user-view/';
import { PackageConfigComponent } from 'app/package-config/package-config.component';
import { PublicAddColleagueComponent } from 'app/public-add-colleague';

import { LoginComponent } from 'app/authorization/login/';
import { SignUpComponent } from 'app/authorization/sign-up/';
import { PasswordResetComponent } from 'app/authorization/password-reset/';
import { ConfirmationsComponent } from 'app/confirmations/';

import { UserSettingsComponent } from 'app/user-settings';
import { SendFeedbackComponent } from 'app/send-feedback/';
import { HomePageComponent } from 'app/home-page/';
import { QuickstartComponent } from 'app/quickstart/';
import { NotFoundComponent } from 'app/not-found/';
import { ColleagueInfoPageComponent } from './colleague-profile/colleague-info-page';

import { CompanyPageComponent } from 'app/company-page/';
import { CompanyCreateDepartmentComponent } from 'app/company-page/company-create-department/';
import { CompanyCreateCompanyComponent } from 'app/company-page/company-create-company/';
import { ColleaguesPageComponent } from 'app/colleagues-page';
import { ColleaguesCreateColleagueComponent } from 'app/colleagues-page/create-colleague/';
import { QuestionnaireColleagueComponent } from 'app/questionnaire-colleague/';
import { ExperimentDetailsComponent } from 'app/experiment-details/';
import { RelativeExperimentDetailsComponent } from 'app/relative-experiment-details';

import { PackagesComponent } from 'app/packages';
import { PackagesMyQuestionnairesComponent } from 'app/packages/my-questionnaires/';
import { PackagesStandardSetsComponent } from 'app/packages/standard-sets/';
import { PackagesCreatePackageComponent } from 'app/packages/packages-create-package/';
import { PackageCreateQuestionComponent } from 'app/package-details/questionnaire/create-question/';
import { PackageEditQuestionComponent } from 'app/package-details/questionnaire/edit-question/';
import { PackageCreatePQActionComponent } from 'app/package-details/questionnaire/create-pq-action/';
import { PackageEditPQActionComponent } from 'app/package-details/questionnaire/edit-pq-action/';

import { StardardSetDetailsComponent } from 'app/stardard-set-details/';
import { StandardSetDescriptionComponent } from 'app/stardard-set-details/description/';
import { StandardSetQuestionsComponent } from 'app/stardard-set-details/questions/';
import { StandardSetSettingsComponent } from 'app/stardard-set-details/settings/';
import { ColleagueProfileComponent } from './colleague-profile/colleague-profile.component';
import { ColleaguesEditColleagueComponent } from './colleague-profile/edit-colleague';
import { ColleagueNotesComponent } from './colleague-profile/colleague-notes/colleague-notes.component';
import { ColleaguesComponent } from './colleagues-page/colleagues/colleagues.component';
import { ColleaguesArchiveComponent } from './colleagues-page/colleagues-archive';
import { ColleaguesDepartmentsComponent } from './colleagues-page/colleagues-departments/colleagues-departments.component';
import { ColleagueParametersComponent } from './colleague-profile/colleague-parameters/colleague-parameters.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'sign-up', component: SignUpComponent, canActivate: [LoginGuard]},
  { path: 'password-reset', component: PasswordResetComponent, canActivate: [LoginGuard] },
  { path: 'quickstart/:email', component: QuickstartComponent, canActivate: [LoginGuard] },
  { path: 'dashboard/:token', component: DashboardColleagueComponent },
  { path: 'dashboards/create-dashboard', component: CreateNewDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboards/:dashboard_id', component: DashboardsListComponent, canActivate: [AuthGuard] },
  { path: 'dashboards', component: DashboardsListComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'company', component: CompanyPageComponent, canActivate: [AuthGuard] },
  { path: 'company/create-company', component: CompanyCreateCompanyComponent, canActivate: [AuthGuard] },
  { path: 'company/create-department', component: CompanyCreateDepartmentComponent, canActivate: [AuthGuard] },
  {
    path: 'colleagues',
    component: ColleaguesPageComponent,
    children: [
      {
        path: '', component: ColleaguesComponent, canActivate: [AuthGuard]
      },
      {
        path: 'archive', component: ColleaguesArchiveComponent, canActivate: [AuthGuard]
      },
      {
        path: 'department-management', component: ColleaguesDepartmentsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'create-colleague', component: ColleaguesCreateColleagueComponent,canActivate: [AuthGuard]
      }
    ],
    canActivate: [AuthGuard]
  },

  { path: 'colleagues/:id',
    component: ColleagueProfileComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', redirectTo: 'data', pathMatch: 'prefix'
      },
      {
        path: 'data', component: ColleagueInfoPageComponent
      },
      {
        path: 'notes', component: ColleagueNotesComponent
      },
      {
        path: 'edit', component: ColleaguesEditColleagueComponent
      },
      {
        path: 'parameters', component: ColleagueParametersComponent
      }
    ]
  },
  { path: 'colleague/:public_link',
    component: ColleagueProfileComponent,
    children: [
      {
        path: '', redirectTo: 'data', pathMatch: 'prefix'
      },
      {
        path: 'data', component: ColleagueInfoPageComponent
      },
      {
        path: 'notes', component: ColleagueNotesComponent
      }
    ]
  },
  {
    path: 'questionnaires',
    component: PackagesComponent,
    children: [
      {
        path: 'standard-sets', component: PackagesStandardSetsComponent, canActivate: [AuthGuard]
      },
      {
        path: '', component: PackagesMyQuestionnairesComponent, canActivate: [AuthGuard]
      }
    ],
    canActivate: [AuthGuard]
  },

  { path: 'questionnaires/create-questionnaire', component: PackagesCreatePackageComponent, canActivate: [AuthGuard] },
  {
    path: 'questionnaires/:package_id',
    component: PackageDetailsComponent,
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'prefix'
      },
      {
        path: 'dashboard', component: DashboardUserViewComponent, canActivate: [AuthGuard]
      },
      {
        path: 'parameters', component: PackageParametersComponent, canActivate: [AuthGuard]
      },
      {
        path: 'questions', component: PackageQuestionnaireComponent, canActivate: [AuthGuard]
      },
      {
        path: 'participants', component: PackageParticipantsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'researches', component: ExcelViewComponent, canActivate: [AuthGuard]
      },
      {
        path: 'researches-details', component: PackageResearchesComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings', component: PackageSettingsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'dashboard-editor', component: DashboardEditorComponent, canActivate: [AuthGuard]
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'standard-sets/:set_id',
    component: StardardSetDetailsComponent,
    children: [
      {
        path: '', component: StandardSetDescriptionComponent, canActivate: [AuthGuard]
      },
      {
        path: 'description', component: StandardSetDescriptionComponent, canActivate: [AuthGuard]
      },
      {
        path: 'questions', component: StandardSetQuestionsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'settings', component: StandardSetSettingsComponent, canActivate: [AuthGuard]
      },
      {
        path: 'dashboard', component: DashboardUserViewComponent, canActivate: [AuthGuard]
      },
    ],
    canActivate: [AuthGuard]
  },
  { path: 'questionnaires/:package_id/create-question', component: PackageCreateQuestionComponent, canActivate: [AuthGuard] },
  { path: 'questionnaires/:package_id/edit-question/:card_id', component: PackageEditQuestionComponent, canActivate: [AuthGuard] },
  { path: 'questionnaires/:package_id/create-pq-action', component: PackageCreatePQActionComponent, canActivate: [AuthGuard] },
  { path: 'questionnaires/:package_id/edit-pq-action/:pq_action_id', component: PackageEditPQActionComponent, canActivate: [AuthGuard] },
  { path: 'questionnaires/:package_id/preview', component: QuestionnaireUserViewComponent, canActivate: [AuthGuard] },
  { path: 'standard-sets/:set_id/preview', component: QuestionnaireUserViewComponent, canActivate: [AuthGuard] },
  { path: 'package-config', component: PackageConfigComponent, canActivate: [AuthGuard] },
  { path: 'public_packages', component: PublicPackagesComponent, canActivate: [AuthGuard] },
  { path: 'experiment_details/:experiment_answer_id', component: ExperimentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'relative_experiment_details/:relative_answer_id', component: RelativeExperimentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'reset/:resetToken', component: ConfirmationsComponent },
  { path: 'confirm/:token', component: ConfirmationsComponent },
  { path: 'settings', component: UserSettingsComponent, canActivate: [AuthGuard] },
  { path: 'feedback', component: SendFeedbackComponent, canActivate: [AuthGuard] },
  { path: 'questionnaire/:token', component: QuestionnaireColleagueComponent },
  { path: 'add-colleague/:token', component: PublicAddColleagueComponent },
  { path: 'not-found', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: 'admin-settings-page', component: AdminSettingsPageComponent, canActivate: [AuthGuard] },


  { path: '404', component: NotFoundComponent },
  // otherwise redirect to default page
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
