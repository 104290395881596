import { ColleagueInformation } from 'app/_models/colleague-information';
import { ApiColleagueInformation } from 'app/_api_models/api-colleague-information';
import { environment } from '../../environments/environment';

export function parseApiColleagueInformation(apiResponse: any): ColleagueInformation {
  // console.log('Api response ', apiResponse);
  let informationObject;
  if (environment.production) {
    informationObject = apiResponse;
  } else {
    informationObject = ApiColleagueInformation.check(apiResponse);
  }

  const colleagueFilters = {};

  if (informationObject.colleague_filters.active_packages_ids) {
    colleagueFilters['activePackagesIds'] = informationObject.colleague_filters.active_packages_ids;
  }

  if (informationObject.colleague_filters.active_parameters_ids) {
    colleagueFilters['activeParametersIds'] = informationObject.colleague_filters.active_parameters_ids;
  }

  const newColleagueInfo: ColleagueInformation = {
    id: informationObject.id,
    colleagueId: informationObject.colleague_id,
    hasSharedLink: informationObject.has_shared_link,
    sharedLink: informationObject.shared_link,
    colleagueFilters: colleagueFilters,
  };

  return newColleagueInfo;
}

