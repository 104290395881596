import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from 'app/_services/authentication.service';
import { SharedService } from 'app/_services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../authorization.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  model: any = {};
  loading: boolean = false;
  hideForm: boolean = false;
  error: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.authService.logout();
    this.sharedService.onLoginEmitted = false;
    // this.loginMode = true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toSignUpPage(): void {
    this.router.navigate(['/sign-up']);
  }

  toResetPage(): void {
    this.router.navigate(['/password-reset']);
  }

  login(): void {
    this.loading = true;
    this.error = false;

    this.authService.login(this.model.email, this.model.password)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result === true) {
          this.sharedService.onLogin(this.model.email);
          this.sharedService.loginMode$.emit(false);
          // this.sharedService.currentTabId.next(1);
          // this.router.navigate(['/questionnaires/standard-sets']);
          this.router.navigate(['/home/']);
        } else {
          // this.error = 'Username or password is incorrect';
          this.error = true; // Username or password is incorrect';
          this.loading = false;
        }
      },
      error => {
        // console.log('ERROR ', error);
        // this.error = 'Username or password is incorrect';
        this.error = true; // Username or password is incorrect';
        this.loading = false;
      });
  }

}
