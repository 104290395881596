import { Component, OnInit, OnDestroy, Input, NgZone, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { CompanyService } from 'app/_services/company.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { PackageService } from 'app/_services/package.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';

import { QuestionnaireCard } from 'app/_models/questionnaire-card';
import { CardOption } from 'app/_models/card-option';
import { ColleagueCardAnswer } from 'app/_models/colleague-card-answer';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { CardAction } from 'app/_models/card-action';
import { ScoreDivision } from 'app/_models/score-division';
import { Questionnaire } from 'app/_models/questionnaire';
import { Experiment } from 'app/_models/experiment';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { Company } from 'app/_models/company';
import { Package } from 'app/_models/package';

class CheckboxOption {
  id: number = 0;
  text: string = '';
  orderPosition: number = 0;
  cardActions: CardAction[] = [];
  cardId: number = 0;
  isSelected: boolean = false;
}

class Selection {
  cardId: number;
  optionId: number;
  selectedAnswer: CardOption | CheckboxOption | string;
  orderPosition: number;
  userInput: string;
  relativeColleagueId: number;
}

@Component({
  selector: 'app-questionnaire-colleague',
  templateUrl: './questionnaire-colleague.component.html',
  styleUrls: ['./questionnaire-colleague.component.scss']
})
export class QuestionnaireColleagueComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private sub: any;

  @Input() inputPackageId: number = 0;
  @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

  currentPackageId: number = 0;
  ownerId: number = 0;
  cardOptions: CardOption[] = [];
  questionnaireCards: QuestionnaireCard[] = [];
  cardError: string = '';
  questionText: string = '';
  questionNumber: number = 1;
  isTextQuestion: boolean = false;
  isRelativeQuestion: boolean = false;
  isRelativeScoreQuestion: boolean = false;
  isOptionsQuestion: boolean = false;
  isRelativeOptionsQuestion: boolean = false;
  isScoreQuestion: boolean = false;
  isCheckboxesQuestion: boolean = false;
  isRelativeCheckboxesQuestion: boolean = false;
  isInfoCard: boolean = false;
  isRequired: boolean = false;
  isInvalid: boolean = false;
  isLocked: boolean = false;
  multipleSubmissions: boolean = false;
  questionTitleCounter: number = 1;
  chosenOption: any = null;
  isCompleted: boolean = false;
  isActive: boolean = true;
  textOption: string = '';
  scoreDivisions: ScoreDivision[] = [];
  resultState: any = [];
  packageParameters: any = [];
  shouldNotify: boolean = false;
  colleagueId: number = 0;
  // experimentAnswerId: number = 0;
  experimentAnswer: ExperimentAnswer;
  selectedOptions: any = [];
  checkboxOptions:  any = [];
  cardCheckboxOptions:  any = [];
  previewTextAnswer: string = '';
  isLastQuestion: boolean = false;
  isAnonymous: boolean = false;
  isLoaded: boolean = false;
  previousQuestionNumber: number = 1;
  selectedDepartmentIndex: string = '';
  selectedColleagueIndex: string = '';
  lastRelativeColleagueIndex: string = '';
  inputDepartments: Department[] = [];
  inputColleagues: Colleague[] = [];
  activeColleagues: Colleague[] = [];
  token: string;
  userEmail: string = '';
  isScoreMulticolor: boolean = false;
  multicolorGroupSize: number = 3;
  resultCardAnswers: ColleagueCardAnswer[] = [];
  packageId: number = 0;
  relativeQuestionNumber = 0;
  isRelativeSubmission: boolean = false;
  evaluatorId = 0;
  currentQuestionNumber: number = 1;
  isExperimentCompleted: boolean = false;
  maxSubmissionsNumber: number = 1;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private experimentService: ExperimentService,
    private amplitudeService: AmplitudeEventsService,
    private sharedPackageService: SharedPackageService,
    private _ngZone: NgZone,
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  onPageHide($event: any): void {
    // console.log('hello');
    if (!this.isCompleted) {
      if (!this.isLocked) {
        this.setExperimentAnswerLocked(this.token, false);
      }
      $event.preventDefault();
      $event.returnValue = 'Unsaved modifications';
      return $event;
    }
  }

  ngOnInit(): void {
    this.sub = this.route.params
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        this.validateQuestionnaire(params['token']);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  validateQuestionnaire(token: string): void {
    this.token = token;
    this.companyService.validateQuestionnaire(token)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        console.log(result);
        if (result && result['data']) {
          // this.setExperimentAnswerLocked(token, true);
          // setInterval(() => {
          //   this.setExperimentAnswerLocked(token, true);
          // }, 30000);
          if (result['isLocked']) {
            this.isLocked = true;
          } else {
            this.parseValidateResponse(result['data']);
            this.setExperimentAnswerLocked(token, true);
            setInterval(() => {
              this.setExperimentAnswerLocked(token, true);
            }, 30000);
          }
        }
      }, error => {
        this.isInvalid = true;
        console.log('Error ', error);
      });
  }

  setExperimentAnswerLocked(token: string, isLocked: boolean): void {
    this.companyService.lockQuestionnaireStatus(token, isLocked)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        //
      }), error => {
        this.isInvalid = true;
        console.log(error);
      };
  }

  parseValidateResponse(data: any): void {
    const email = data['email'];
    const experimentAnswerId = data['experimentAnswerId'];
    const companyToken = data['companyToken'];
    const evaluatorId = data['evaluatorId']; // Сотрудник, который оценивает
    const colleagueId = data['colleagueId']; // Сотрудник, которого нужно оценить

    if (colleagueId) {
      this.evaluatorId = parseInt(colleagueId, 10);
      this.isRelativeSubmission = true;
    }

    if (email) {
      this.userEmail = email;
    }

    if (companyToken) {
      this.getColleaguesList(companyToken);
    }

    if (experimentAnswerId) {
      const answerId = parseInt(experimentAnswerId, 10);
      this.getExperimentAnswer(answerId);
    }
  }

  getExperimentAnswer(id: number): void {
    this.experimentService.logExperimentAnswer('get_answer', this.userEmail, 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        //
      }, error => console.log(error));

    this.experimentService.getExperimentAnswer(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((answer: ExperimentAnswer) => {
        const isAnonymous = answer.anonymous;
        if (isAnonymous) {
          this.isAnonymous = true;
        } else {
          this.isAnonymous = false;
          this.colleagueId = answer.colleagueId;
        }
        this.experimentAnswer = answer;
        this.getExperimentInfo(answer.experimentId);
      },
      error => {
        this.isInvalid = true;
        console.log('Answer error ', error);
      });
  }

  getExperimentInfo(experimentId: number): void {
    this.experimentService.getExperiment(experimentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((experiment: Experiment) => {
        this.packageId = experiment.packageId;
        this.getPackage(this.packageId);
      });
  }

  getPackage(packageId: number): void {
    this.packageService.getPublicPackage(packageId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pack: Package) => {
        this.relativeQuestionNumber = pack.relativeQuestion;
        this.maxSubmissionsNumber = pack.maxSubmissions;

        const isCompleted = this.experimentAnswer.completed;
        if (isCompleted && this.isRelativeSubmission) {
          this.isExperimentCompleted = true;
        } if (isCompleted && !this.isRelativeSubmission) {
          this.checkMultipleSubmissions(this.experimentAnswer);
        } else {
          this.getExperimentStatus(this.experimentAnswer);
        }
      });
  }

  getColleaguesList(companyToken: string): void {
    this.companyService.getPublicLinkCompany(companyToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultCompany: Company) => {
        this.inputDepartments = resultCompany.departments;
      }, error => {
        console.log('Error ', error);
      });
    this.companyService.getPublicLinkColleagues(companyToken)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultColleagues: Colleague[]) => {
        this.inputColleagues = resultColleagues;
        if (this.evaluatorId > 0) {
          this.getEvaluatorInfo(this.evaluatorId);
        }
      }, error => {
        console.log('Error ', error);
      });
  }

  getEvaluatorInfo(evalutorId: number): void {
    this.companyService.getColleague(evalutorId)
      .subscribe((evaluatorInfo: Colleague) => {
        this.selectedDepartmentIndex = evaluatorInfo.departmentId.toString();
        this.selectedColleagueIndex = this.evaluatorId.toString();
        this.setSelectedDepartment();
      });
  }

  setSelectedDepartment(): void {
    if (this.selectedDepartmentIndex) {
      const departmentId = Number(this.selectedDepartmentIndex);
      this.activeColleagues = this.inputColleagues.filter(singleColleague => singleColleague.departmentId === departmentId);
      this.activeColleagues = this.activeColleagues.filter(singleColleague => singleColleague.id !== this.colleagueId);
    }
  }

  setSelectedColleague(): void {
    // console.log('Set selected colleague ', this.selectedColleagueId);
  }

  checkMultipleSubmissions(completedAnswer: ExperimentAnswer): void {
    const experimentId = completedAnswer.experimentId;
    const colleagueId = completedAnswer.colleagueId;
    const promiseExperiment = this.getExperiment(experimentId);
    promiseExperiment.then((resultExperiment: Experiment) => {
      const isExpired = resultExperiment.completed;
      if (isExpired) {
        this.isActive = false;
      } else {
        const packId = resultExperiment.packageId;
        this.packageId = packId;

        this.packageService.checkPackageMultipleSubmissions(packId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((multipleSubmissions: boolean) => {
            if (multipleSubmissions && colleagueId) {
              const experimentAnswers = resultExperiment.experimentAnswers;
              this.findActualExperimentAnswer(experimentAnswers, colleagueId);
            } else if (multipleSubmissions && this.isAnonymous) {
              // this.addNewExperimentAnswer(completedAnswer);
              this.isCompleted = true;
            } else {
              this.isCompleted = true;
            }
          });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  findActualExperimentAnswer(experimentAnswers: ExperimentAnswer[], colleagueId: number): void {
    const colleagueAnswers = experimentAnswers.filter(answer => answer.colleagueId === colleagueId);
    if (colleagueAnswers) {
      const completedAnswer = colleagueAnswers.find(answer => answer.completed === true);
      const activeAnswer = colleagueAnswers.find(answer => answer.completed === false && !answer.isRelativeAnswer);
      if (activeAnswer) {
        this.experimentAnswer = activeAnswer;
        this.getExperimentStatus(activeAnswer);
      } else {
        const count = colleagueAnswers.filter(answer => !answer.isRelativeAnswer);
        if (count.length < this.maxSubmissionsNumber) {
          this.addNewExperimentAnswer(completedAnswer);
        } else {
          this.isExperimentCompleted = true;
        }
      }
    }
  }

  addNewExperimentAnswer(completedAnswer: ExperimentAnswer): void {
    const newAnswer: ExperimentAnswer = {
      anonymous: completedAnswer.anonymous,
      calculated: false,
      colleagueCardAnswers: [],
      colleagueParameters: [],
      relativeParameters: [],
      completed: false,
      email: completedAnswer.email,
      colleagueId: completedAnswer.colleagueId,
      experimentId: completedAnswer.experimentId,
      id: 0,
      questionnaireId: completedAnswer.questionnaireId
    };
    this.experimentAnswer = newAnswer;
    this.getExperimentStatus(newAnswer);
  }

  getExperiment(experimentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.getExperiment(experimentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((experimentInfo: Experiment) => {
          resolve(experimentInfo);
        },
          error => {
            reject(error);
          });
    });
  }

  getExperimentStatus(answer: ExperimentAnswer): void {
    const questionnaireId = answer.questionnaireId;
    const cardAnswers = answer.colleagueCardAnswers;
    const experimentId = answer.experimentId;

    console.log(answer)
    this.experimentService.logExperimentAnswer('status_check', this.userEmail, answer.id || 0)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        // console.log('Log experiment start ', result);
      }, error => console.log(error));

    this.experimentService.getExperiment(experimentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((experimentInfo: Experiment) => {
        if (experimentInfo && !experimentInfo.completed) {
          this.isActive = true;
          const localCardAnswers: ColleagueCardAnswer[] = [];
          for (const singleAnswer of cardAnswers) {
            // const localAnswer = this.addLocalCardAnswer(singleAnswer);
            localCardAnswers.push(singleAnswer);
          }

          localCardAnswers.sort(function (a: any, b: any): number {
            return a.orderPosition - b.orderPosition;
          });

          this.getQuestionnaire(experimentInfo.questionnaireId, localCardAnswers);

        } else {
          this.isActive = false;
        }
      },
      error => {
        console.log('Questionnaire colleague error ', error);
      });
  }

  getQuestionnaire(questionnaireId: number, cardAnswers: ColleagueCardAnswer[]): void {
    this.experimentService.logExperimentAnswer('start', this.userEmail, this.experimentAnswer.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        // console.log('Log experiment start ', result);
      }, error => console.log(error));

      console.log(questionnaireId);
    if (questionnaireId) {
      this.questionnaireService.getQuestionnaireVerbose(questionnaireId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((questionnaireInfo: Questionnaire) => {
          if (questionnaireInfo) {
            this.getPackageOwnerId(questionnaireInfo.packageId);
            const cards = questionnaireInfo.questionnaireCards.filter(card => card.isActive);

            const localCards = [];
            for (const card of cards) {
              this.sortLocalCard(card);
              localCards.push(card);
            }

            localCards.sort(function (a: any, b: any): number {
              return a.orderPosition - b.orderPosition;
            });

            this.questionnaireCards = localCards;

            for (const card of this.questionnaireCards) {
              if ((card.cardType === 'score') || (card.cardType === 'relative-score')) {
                this.sortScoreDivisions(card.scoreDivisions);
              } else if ((card.cardType === 'checkboxes') || (card.cardType === 'relative-checkboxes')) {
                for (const singleOption of card.cardOptions) {
                  const localCheckboxOption: CheckboxOption = {
                    id: singleOption.id,
                    cardId: card.id,
                    text: singleOption.text,
                    cardActions: singleOption.cardActions,
                    orderPosition: singleOption.orderPosition,
                    isSelected: false,
                  };
                  this.checkboxOptions.push(localCheckboxOption);
                }
              }
            }

            this.isLoaded = true;
            this.currentPackageId = questionnaireInfo.packageId;
            this.getPackageParameters();

            if (this.relativeQuestionNumber > 0 && this.isRelativeSubmission) {
              this.setRelativeSubmissionType();
              this.setDefinedQuestion(this.relativeQuestionNumber);
            } else {
              this.setDefinedQuestion(1);
            }
          }
        },
        error => {
          console.log('Questionnaire error ', error);
        });
    }
  }

  setDefinedQuestion(questionNumber: number): void {
    const card = this.findCardByOrder(questionNumber);
    if (card) {
      this.cardOptions = card.cardOptions;
      this.questionText = card.questionText;
      this.questionTitleCounter = card.orderPosition;
      this.questionNumber = card.orderPosition;
      this.updateQuestionType(card.cardType, card.isRequired);
      this.scoreDivisions = card.scoreDivisions;
      this.cardCheckboxOptions = this.checkboxOptions.filter(checkbox => checkbox.cardId === card.id);
    }
  }

  getPackageOwnerId(packId: number): void {
    if (packId && packId > 0) {
      this.packageService.getPackageOwnerId(packId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((ownerId: any) => {
          if (ownerId) {
            this.ownerId = ownerId;
          }
        });
    }
  }

  sortLocalCard(card: QuestionnaireCard): void {
    card.cardOptions.sort(function (a: any, b: any): number {
      return a.orderPosition - b.orderPosition;
    });

    card.scoreDivisions.sort(function (a: any, b: any): number {
      return a.orderPosition - b.orderPosition;
    });
  }

  sortScoreDivisions(scoreDivisions: ScoreDivision[]): void {
    scoreDivisions.sort(function (a: any, b: any): number {
      return b.value - a.value;
    });
  }

  addCheckboxOption(chosenOption: CheckboxOption): void {
    chosenOption.isSelected = !chosenOption.isSelected;
  }

  getPackageParameters(): void {
    const packageId = this.currentPackageId;

    if (packageId) {
      this.packageService.getPackageParameters(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(packageParameters => {
          if (packageParameters) {
            const params = packageParameters;
            this.packageParameters = params;
          }
        },
        error => {
          console.log('Package info', error);
        });
    }
  }

  setActualQuestion(cards: QuestionnaireCard[], cardAnswers: ColleagueCardAnswer[]): void {
    if (cards) {
      const notAnswered = [];

      for (let i = 1; i < cards.length + 1; i++) {
        const findedCard = this.findCardByOrder(i);
        if (findedCard) {
          const isAnswer = cardAnswers.find(answer => answer.questionnaireCardId === findedCard.id);
          if (!isAnswer) {
            notAnswered.push(i);
          }
        }
      }

      const actualId = Math.min(...notAnswered);
      const card = this.findCardByOrder(actualId);
      if (card) {
        this.cardOptions = card.cardOptions;
        this.questionText = card.questionText;
        this.questionTitleCounter = card.orderPosition;
        this.questionNumber = card.orderPosition;
        this.updateQuestionType(card.cardType, card.isRequired);
        this.scoreDivisions = card.scoreDivisions;
        this.cardCheckboxOptions = this.checkboxOptions.filter(checkbox => checkbox.cardId === card.id);
      } else {
        this.isCompleted = true;
        this.checkForCompletion();
      }
    }
  }

  updateQuestionType(cardType: string, isRequired: boolean): void {
    this.isTextQuestion = false;
    this.isRelativeQuestion = false;
    this.isRelativeScoreQuestion = false;
    this.isScoreQuestion = false;
    this.isCheckboxesQuestion = false;
    this.isOptionsQuestion = false;
    this.isInfoCard = false;
    this.isRelativeOptionsQuestion = false;
    this.isRelativeCheckboxesQuestion = false;

    if (cardType === 'options') {
      this.isOptionsQuestion = true;
    } else if (cardType === 'relative-options') {
      this.isRelativeOptionsQuestion = true;
    } else if (cardType === 'text-input') {
      this.isTextQuestion = true;
    } else if (cardType === 'relative') {
      this.isRelativeQuestion = true;
    } else if (cardType === 'relative-score') {
      this.isRelativeScoreQuestion = true;
    } else if (cardType === 'score') {
      this.isScoreQuestion = true;
    } else if (cardType === 'checkboxes') {
      this.isCheckboxesQuestion = true;
    } else if (cardType === 'relative-checkboxes') {
      this.isRelativeCheckboxesQuestion = true;
    } else if (cardType === 'info-card') {
      this.isInfoCard = true;
    }

    this.isRequired = isRequired;
  }

  findCardByOrder(orderId: number): QuestionnaireCard {
    const targetCard = this.questionnaireCards.find(card => card.orderPosition === orderId);
    return targetCard;
  }

  onClickNextQuestion(): void {
    this.cardError = '';

    if ((this.isOptionsQuestion) || this.isRelativeOptionsQuestion) {
      const resultOption = this.chosenOption;
      this.toNextSimpleQuestion(resultOption);
    } else if ((this.isCheckboxesQuestion) || (this.isRelativeCheckboxesQuestion)) {
      const resultOptions = this.cardCheckboxOptions;
      this.toNextCheckboxQuestion(resultOptions);
    } else if (this.isTextQuestion || this.isScoreQuestion || this.isRelativeQuestion || this.isRelativeScoreQuestion) {
      const resultOption = this.cardOptions[0];
      this.toNextInputQuestion(resultOption);
    } else if (this.isInfoCard) {
      this.goToNextQuestion(this.questionNumber + 1);
    } else {
      // this.cardError = 'Did you forget to answer?';
      this.cardError = this.addCardMessage('no_answer');
    }
  }

  toNextInputQuestion(resultOption: CardOption): void {
    const card = this.findCardByOrder(this.questionNumber);
    if (card) {
      let notEmpty = (this.textOption !== '' && this.textOption !== null);
      if ((this.isRelativeQuestion || this.isRelativeScoreQuestion) && (this.selectedColleagueIndex === '')) {
        notEmpty = false;
      }
      const isRequired = card.isRequired;
      if (notEmpty) {
        this.toNextSimpleQuestion(resultOption);
      } else if (!isRequired) {
        this.previousQuestionNumber = this.questionNumber;
        this.textOption = '';
        this.addEmptySelection(card.id, card.orderPosition);
        this.toNextSimpleQuestion(resultOption);
      } else {
        // this.cardError = 'Did you forget to answer?';
        this.cardError = this.addCardMessage('no_answer');
      }
    } else {
      this.cardError = this.addCardMessage('next_card');
      // this.cardError = 'Error: can\'t find next card.';
    }
  }

  setRelativeSubmissionType(): void {
    const card = this.findCardByOrder(1); // Предполагается, что первым вопросом стоит выбор между самооценкой и оценкой коллеги

    // Предполагается, что второй вариант ответа на вопрос это оценка сотрудника
    this.collectSimpleSelections(card.id, card.orderPosition, card.cardOptions[1], this.textOption);
  }

  toNextSimpleQuestion(resultOption: CardOption): void {
    const card = this.findCardByOrder(this.questionNumber);
    if (card) {
      const isRequired = card.isRequired;
      if (resultOption) {
        const prevQuestionNumber = this.questionNumber;
        this.previousQuestionNumber = prevQuestionNumber;
        let nextQuestionNumber = this.questionNumber + 1;

        const cardActions = resultOption.cardActions;
        const gotoNextCard = this.checkGotoSwitch(cardActions);
        if (gotoNextCard > 0) {
          nextQuestionNumber = gotoNextCard;
          this.removeExtraAnswers(prevQuestionNumber, nextQuestionNumber);
        }

        this.collectSimpleSelections(card.id, card.orderPosition, resultOption, this.textOption);
        this.textOption = '';
        this.goToNextQuestion(nextQuestionNumber);
      } else if (!isRequired) {
        this.previousQuestionNumber = this.questionNumber;
        const nextQuestionNumber = this.questionNumber + 1;
        this.addEmptySelection(card.id, card.orderPosition);
        this.textOption = '';
        this.goToNextQuestion(nextQuestionNumber);
      } else {
        // this.cardError = 'Did you forget to answer?';
        this.cardError = this.addCardMessage('no_answer');
      }
    } else {
      // this.cardError = 'Error: can\'t find next card.';
      this.cardError = this.addCardMessage('next_card');
    }
  }

  toNextCheckboxQuestion(checkboxOptions: CheckboxOption[]): void {
    const card = this.findCardByOrder(this.questionNumber);
    if (card && checkboxOptions) {
      const isRequired = card.isRequired;
      const prevQuestionNumber = this.questionNumber;
      this.previousQuestionNumber = prevQuestionNumber;
      let nextQuestionNumber = this.questionNumber + 1;

      const selection = checkboxOptions.find(answer => answer.isSelected === true);
      if (selection) {
        let combineActions = [];
        for (const checkboxOption of checkboxOptions) {
          if (checkboxOption.isSelected) {
            const cardActions = checkboxOption.cardActions;
            combineActions = combineActions.concat(cardActions);
            this.collectCheckboxSelections(card.id, card.orderPosition, checkboxOption, this.textOption);
          }
        }

        const gotoNextCard = this.checkGotoSwitch(combineActions);
        if (gotoNextCard > 0) {
          nextQuestionNumber = gotoNextCard;
          this.removeExtraAnswers(prevQuestionNumber, nextQuestionNumber);
        }

        this.goToNextQuestion(nextQuestionNumber);
      } else if (!isRequired) {
        this.addEmptySelection(card.id, card.orderPosition);
        this.goToNextQuestion(nextQuestionNumber);
      } else {
        // this.cardError = 'Did you forget to answer?';
        this.cardError = this.addCardMessage('no_answer');
      }
    } else {
      // this.cardError = 'Error: can\'t find next card.';
      this.cardError = this.addCardMessage('next_card');
    }
  }

  checkGotoSwitch(actions: CardAction[]): number {
    for (const singleAction of actions) {
      if (singleAction.parameter === 'goto') {
        const splitValue = singleAction.text.split('#');
        if (splitValue[1]) {
          const nextCardIndex = parseInt(splitValue[1], 10);
          return nextCardIndex;
        }
      }
    }

    return 0;
  }

  removeExtraAnswers(prevQuestionNumber: number, nextQuestionNumber: number): void {
    if (nextQuestionNumber > prevQuestionNumber + 1) {
      const newSelection = this.selectedOptions.filter(selection => selection.orderPosition <= prevQuestionNumber);
      this.selectedOptions = newSelection;
    }
  }

  goToNextQuestion(nextQuestionNumber: number): void {
    const cards = this.questionnaireCards;
    if (nextQuestionNumber <= cards.length) {
      this.questionNumber = nextQuestionNumber;
      this.textOption = '';
      this.isLastQuestion = this.questionNumber === cards.length;
      this.lastRelativeColleagueIndex = this.selectedColleagueIndex;
      this.selectedColleagueIndex = '';
      this.selectedDepartmentIndex = '';

      const card = this.findCardByOrder(nextQuestionNumber);
      if (card) {
        const colorScheme = card.divisionsColorScheme;
        if (colorScheme === 'multicolor') {
          this.isScoreMulticolor = true;
        } else {
          this.isScoreMulticolor = false;
        }
        const divisionsLength = card.scoreDivisions.length;
        this.multicolorGroupSize = divisionsLength;

        this.cardOptions = card.cardOptions;
        this.questionText = card.questionText;
        this.updateQuestionType(card.cardType, card.isRequired);
        this.scoreDivisions = card.scoreDivisions;
        this.questionTitleCounter = card.orderPosition;
        this.cardCheckboxOptions = this.checkboxOptions.filter(checkbox => checkbox.cardId === card.id);
        const cardType = card.cardType;

        const answeredCard = this.selectedOptions.find(answer => answer.cardId === card.id);
        if (answeredCard) {
          for (const answer of this.selectedOptions) {
            if (answer.selectedAnswer) {
              const selection = this.cardOptions.find(selected => selected.id === answer.selectedAnswer.id);
              if (selection) {
                this.chosenOption = selection;
              }
            }
            if ((cardType === 'score') || (cardType === 'text-input')
              || (cardType === 'relative') || (cardType === 'relative-score')) {
              if (card.id === answer.cardId) {
                this.textOption = answer.userInput;
              }
            }
          }

          if ((cardType === 'relative') || (cardType === 'relative-score')
            || (cardType === 'relative-options') || (cardType === 'relative-checkboxes')) {
            this.restoreDepartmentColleague(answeredCard.relativeColleagueId);
          }
        } else {
          this.chosenOption = null;
          this.textOption = '';

          if ((cardType === 'relative') || (cardType === 'relative-score')
            || (cardType === 'relative-options') || (cardType === 'relative-checkboxes')) {
            const lastId = Number(this.lastRelativeColleagueIndex);
            this.restoreDepartmentColleague(lastId);
          }
        }

        this.currentQuestionNumber++;
      } else {
        // this.cardError = 'Problem with card, check card order';
        this.cardError = this.addCardMessage('next_card');
      }
    } else {
      this.finishQuestionnaire();
    }
  }

  onClickPreviousQuestion(): void {
    const previousQuestionNumber = this.getPreviousQuestionNumber();
    const currentOption = this.chosenOption;
    this.chosenOption = null;
    this.currentQuestionNumber--;
    if (this.isTextQuestion || this.isScoreQuestion || this.isRelativeQuestion || this.isRelativeScoreQuestion) {
      this.goToPrevQuestion(this.cardOptions[0], previousQuestionNumber);
    } else if ((this.isCheckboxesQuestion) || (this.isRelativeCheckboxesQuestion)) {
      this.goToPrevQuestion(this.cardCheckboxOptions, previousQuestionNumber);
    } else {
      this.goToPrevQuestion(currentOption, previousQuestionNumber);
    }

    // this.chosenOption = null;
  }

  getPreviousQuestionNumber(): number {
    const newSelection = this.selectedOptions.filter(option => option.orderPosition < this.questionNumber);
    const maxId = Math.max.apply(Math, newSelection.map(option => option.orderPosition));

    return maxId;
    // return this.previousQuestionNumber;
  }

  goToPrevQuestion(resultOption: CardOption, previousCardNumber: number): void {
    this.cardError = '';
    const card = this.findCardByOrder(this.questionNumber);
    if (this.isCheckboxesQuestion || this.isRelativeCheckboxesQuestion){
      for (const checkbox of this.cardCheckboxOptions) {
        if (checkbox.isSelected) {
          this.collectCheckboxSelections(card.id, card.orderPosition, checkbox, this.textOption);
        }
      }
    } else {
      this.collectSimpleSelections(card.id, card.orderPosition, resultOption, this.textOption);
    }

    this.questionNumber = previousCardNumber;
    this.isLastQuestion = false;

    const prevCard = this.findCardByOrder(previousCardNumber);
    this.cardCheckboxOptions = this.checkboxOptions.filter(checkbox => checkbox.cardId === prevCard.id);
    if (prevCard) {
      // console.log('Prev card ', prevCard);
      const colorScheme = prevCard.divisionsColorScheme;
      if (colorScheme === 'multicolor') {
        this.isScoreMulticolor = true;
      } else {
        this.isScoreMulticolor = false;
      }
      const divisionsLength = prevCard.scoreDivisions.length;
      this.multicolorGroupSize = divisionsLength;

      this.cardOptions = prevCard.cardOptions;
      this.questionText = prevCard.questionText;
      this.updateQuestionType(prevCard.cardType, prevCard.isRequired);
      this.questionTitleCounter = prevCard.orderPosition;
      this.scoreDivisions = prevCard.scoreDivisions;

      if (prevCard.cardType === 'options') {
        const findOption = this.selectedOptions.find(selected => selected.cardId === prevCard.id);
        if (findOption) {
          const chosenOption = this.cardOptions.find(option => option.id === findOption.optionId);
          this.chosenOption = chosenOption;
        }
      } else if (prevCard.cardType === 'relative-options') {
        const findOption = this.selectedOptions.find(selected => selected.cardId === prevCard.id);
        if (findOption) {
          this.restoreDepartmentColleague(findOption.relativeColleagueId);
          const chosenOption = this.cardOptions.find(option => option.id === findOption.optionId);
          this.chosenOption = chosenOption;
        }
      } else if (prevCard.cardType === 'relative-checkboxes') {
        if (this.cardCheckboxOptions && this.cardCheckboxOptions[0]) {
          const findOption = this.cardCheckboxOptions[0];
          this.restoreDepartmentColleague(findOption.relativeColleagueId);
        }
      } else if ((prevCard.cardType === 'score') || (prevCard.cardType === 'text-input')) {
        const findOption = this.selectedOptions.find(selected => selected.cardId === prevCard.id);
        if (findOption) {
          this.textOption = findOption.userInput;
        }
      } else if ((prevCard.cardType === 'relative') || (prevCard.cardType === 'relative-score')) {
        const findOption = this.selectedOptions.find(selected => selected.cardId === prevCard.id);
        if (findOption) {
          this.restoreDepartmentColleague(findOption.relativeColleagueId);
          this.textOption = findOption.userInput;
        }
      }
    }
  }

  restoreDepartmentColleague(relativeColleagueId: number): void {
    if (relativeColleagueId && (relativeColleagueId > 0)) {
      const findColleague = this.inputColleagues.find(colleague => colleague.id === relativeColleagueId);
      if (findColleague) {
        const findDepartment = this.inputDepartments.find(dep => dep.id === findColleague.departmentId);
        if (findDepartment) {
          this.selectedDepartmentIndex = String(findDepartment.id);
        } else {
          this.selectedDepartmentIndex = '0';
        }
      }
      this.setSelectedDepartment();
      this.selectedColleagueIndex = String(relativeColleagueId);
    }
  }

  addEmptySelection(cardId: number, orderPosition: number): void {
    const alreadyExists = this.selectedOptions.find(answer => answer.cardId === cardId);
    if (!alreadyExists) {
      const selection: Selection = {
        cardId: cardId,
        optionId: 0,
        selectedAnswer: 'empty',
        orderPosition: orderPosition,
        userInput: '',
        relativeColleagueId: 0,
      };

      this.selectedOptions.push(selection);
    }
    // this.chosenOption = null;
  }

  collectSimpleSelections(cardId: number, cardOrderPosition: number, chosenOption: CardOption, textOption: string): void {
    if (chosenOption && chosenOption.id) {
      const relativeIndex = Number(this.selectedColleagueIndex) || 0;
      const selection: Selection = {
        cardId: cardId,
        optionId: chosenOption.id,
        selectedAnswer: chosenOption,
        orderPosition: cardOrderPosition,
        userInput: textOption,
        relativeColleagueId: relativeIndex,
      };

      const answeredCard = this.selectedOptions.find(answer => answer.cardId === cardId);

      if (answeredCard) {
        this.selectedOptions.splice(this.selectedOptions.indexOf(answeredCard), 1);
        this.selectedOptions.push(selection);
      } else {
        this.selectedOptions.push(selection);
      }
    }
  }

  collectCheckboxSelections(cardId: number, cardOrderPosition: number, chosenOption: CheckboxOption, textOption: string): void {
    if (chosenOption) {
      const relativeIndex = Number(this.selectedColleagueIndex) || 0;
      const selection: Selection = {
        cardId: cardId,
        optionId: chosenOption.id,
        selectedAnswer: chosenOption,
        orderPosition: cardOrderPosition,
        userInput: textOption,
        relativeColleagueId: relativeIndex,
      };

      const alreadyChecked = this.selectedOptions.find(answer => answer.optionId === selection.optionId);
      if (!alreadyChecked) {
        this.selectedOptions.push(selection);
      }

      for (const box of this.checkboxOptions) {
        if (!box.isSelected) {
          const newSelectedOptions = this.selectedOptions.filter(answer => answer.optionId !== box.id);
          this.selectedOptions = newSelectedOptions;
        }
      }
    }
  }

  finishQuestionnaire(): void {
    this.experimentService.logExperimentAnswer('finish', this.userEmail, this.experimentAnswer.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        console.log('Log experiment finish ', result);
      }, error => console.log(error));

    if (this.experimentAnswer.id > 0) {
      this.setExperimentAnswerLocked(this.token, false);
      this.createColleagueCardAnswers();
    } else {
      const promiseMultipleNewAnswer = this.createNewExperimentAnswer();
      promiseMultipleNewAnswer.then((answer) => {
        this.experimentAnswer = answer;
        this.createColleagueCardAnswers();
      }).catch((error) => {
        console.log('Error on questionnaire finish', error);
      });
    }

  }

  createColleagueCardAnswers(): void {
    this.resultCardAnswers = [];
    for (const answer of this.selectedOptions) {
      const isEmpty = (answer.selectedAnswer === 'empty');
      if (!isEmpty) {
        this.answerQuestion(answer);
      }
    }

    this.experimentService.addMultipleColleagueCardAnswers(this.resultCardAnswers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response !== 'true') {
          this.isCompleted = true;
        }
      },
      error => {
        console.log('Card Answer error ', error);
      });
  }

  answerQuestion(chosenOption: Selection): void {
    const card = this.findCardByOrder(chosenOption.orderPosition);
    if (card && chosenOption) {
      this.createCardAnswer(chosenOption, card.orderPosition);
    }
  }

  createCardAnswer(chosenOption: Selection, orderPosition: number): void {
    const questionnaireCardId = chosenOption.cardId;
    const optionId = chosenOption.optionId;
    const userInput = chosenOption.userInput;
    const relativeId = chosenOption.relativeColleagueId;
    const newCardAnswer: ColleagueCardAnswer = {
      id: 0,
      experimentAnswerId: this.experimentAnswer.id,
      questionnaireCardId: questionnaireCardId,
      colleagueId: this.colleagueId,
      chosenOptionId: optionId,
      orderPosition: orderPosition,
      userInput: userInput,
      anonymous: this.isAnonymous,
      relativeColleagueId: relativeId
    };

    this.resultCardAnswers.push(newCardAnswer);
  }

  createNewExperimentAnswer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.experimentService.addNewExperimentAnswer(this.experimentAnswer)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((experimentAnswer: ExperimentAnswer) => {
          if (experimentAnswer) {
            resolve(experimentAnswer);
          }
        }, error => {
          reject(error);
        });
    });
  }

  checkForCompletion(): void {
    const experimentAnswer = this.experimentAnswer;
    const cards = this.questionnaireCards;
    if (experimentAnswer && cards && cards.length > 0) {
      const answers = this.experimentAnswer.colleagueCardAnswers;
      const answersCount = answers.length;
      const cardsCount = cards.length;

      if (answersCount >= cardsCount) {
        this.experimentService.setExperimentAnswerCompleted(experimentAnswer.id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: any) => {
            // console.log('Experiment answer ', result);
          },
          error => {
            console.log('Update experiment error ', error);
          });
      }
    }
  }

  calculateAnswerResult(cardAnswers: any): void {
    this.formResultState();

    const answerState = [];
    for (const singleAction of answerState) {
      const paramName = singleAction.parameter;
      const paramText = singleAction.text;

      this.checkInParametersList(paramName, paramText);
    }
  }

  formResultState(): void {
    const resultState = [];
    for (const singleParameter of this.packageParameters) {
      const item = {
        name: singleParameter.name,
        value: 0,
      };
      resultState.push(item);
    }

    this.resultState = resultState;
  }

  checkInParametersList(parameterName: string, parameterText: string): void {
    for (const singleParameter of this.resultState) {
      const value = singleParameter.value;

      if (singleParameter.name === parameterName) {
        const stringVariant = this.parseActionVariants(parameterText);
        const validNumber = this.getValidNumber(parameterText, stringVariant);

        if (stringVariant === '==' && validNumber) {
          singleParameter.value = validNumber;
        } else if (stringVariant === '+=' && validNumber) {
          singleParameter.value = value + validNumber;
        } else if (stringVariant === '-=' && validNumber) {
          singleParameter.value = value - validNumber;
        } else if (stringVariant === '*=' && validNumber) {
          singleParameter.value = value * validNumber;
        }
      }
    }
  }

  parseActionVariants(inputAction: string): string {
    const variants = ['+=', '-=', '==', '*='];
    for (const single of variants) {
      const actionMatch = inputAction.includes(single);
      if (actionMatch) {
        return single;
      }
    }

    return '';
  }

  getValidNumber(parameterText: string, stringVariant: string): any {
    const actualValue = parameterText.split(stringVariant);
    if (actualValue && actualValue.length === 2) {
      const parsed = parseInt(actualValue[1], 10);
      if (typeof(parsed) === 'number') {
        return parsed;
      }
    }

    return null;
  }

  addCardMessage(messageType: string): string {
    if (messageType === 'next_card') {
      const enMessage = 'Couldn\'t find a next card. Please, check order positions of your cards.';
      const ruMessage = 'Невозможно найти следующий вопрос. Пожалуйста, проверьте порядок вопросов.';
      if (this.isRussianLocale()) {
        return ruMessage;
      } else {
        return enMessage;
      }
    } else if (messageType === 'no_answer') {
      const enMessage = 'This question is required. Please give an answer';
      const ruMessage = 'Это обязательный вопрос. Пожалуйста, дайте ответ.';
      if (this.isRussianLocale()) {
        return ruMessage;
      } else {
        return enMessage;
      }
    } else {
      console.log('Error: Wrong message type');
      return '';
    }
  }

  isRussianLocale(): boolean {
    // const lang = this.sharedPackageService.currentPackageLanguage.getValue();
    const isRu = this.checkUrlForSubstring('/ru/');
    if (isRu) {
      return true;
    } else {
      return false;
    }
  }

  checkUrlForSubstring(substring: string): boolean {
    const link = window.location.href;
    if (substring && link.indexOf(substring) > -1) {
      return true;
    } else {
      return false;
    }
  }

}

