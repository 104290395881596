import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import * as QuillNamespace from 'quill';
const Quill: any = QuillNamespace;

@Component({
  selector: 'app-description-card-widget',
  templateUrl: './description-card-widget.component.html',
  styleUrls: ['./description-card-widget.component.scss', '../widgets.scss']
})
export class DescriptionCardWidgetComponent implements OnInit, OnDestroy {
  @Input() widgetTitle: string;
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() isPublicLink: boolean;
  @Input() sharedDashboardMode: boolean;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTitleUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();

  public editorEnabled: boolean = true;

  private inputTitle: string = '';
  private editorForm: FormGroup;
  private editorContent: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private config: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': ['', 'center', 'right'] }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  constructor(private formBuilder: FormBuilder) {
    this.editorForm = this.formBuilder.group({
      editor: ['', Validators.required]
    });
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
  }

  ngOnInit(): void {
    this.editorForm = this.formBuilder.group({
      editor: [this.widgetTitle, Validators.required]
    });
    this.inputTitle = this.widgetTitle;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit(): void {
    this.editorContent = this.editorForm.get('editor').value;
    this.widgetTitle = this.editorForm.get('editor').value;
  }

  onDiscard(): void {
    this.editorForm = this.formBuilder.group({
      editor: [this.inputTitle, Validators.required]
    });
  }

  onSelectionChange(): void {
    this.editorContent = this.editorForm.get('editor').value;
    this.widgetTitle = this.editorForm.get('editor').value;
    this.onTitleUpdate.emit(this.widgetTitle);
  }

  checkMaxLength(e: any): void {
    if (e.editor.getLength() > 1000) {
      console.log('max length 1000!');
    }
  }

  setEditorEnabled(): void {
    this.editorEnabled = true;
  }

  editText(): void {
    this.editorEnabled = true;
  }

  showText(): void {
    this.editorEnabled = false;
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }
}
