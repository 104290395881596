import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { parseApiCompany } from 'app/_api_validation/parse-api-company';
import { parseApiDepartment } from 'app/_api_validation/parse-api-department';

import { Company } from '../_models/company';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { Department } from 'app/_models/department';
import { Colleague } from 'app/_models/colleague';
import { parseApiColleague } from 'app/_api_validation/parse-api-colleague';
import { ColleagueInformation } from 'app/_models/colleague-information';
import { parseApiColleagueInformation } from 'app/_api_validation/parse-api-colleague-information';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companiesUrl: string = environment.API_ENDPOINT + '/v1/companies';
  private companyOwnerUpdateUrl: string = environment.API_ENDPOINT + '/v1/company_owner_update';
  private adminCompaniesUrl: string = environment.API_ENDPOINT + '/v1/admin_companies';
  private uploadUrl: string = environment.API_ENDPOINT + '/v1/upload_company_logo';
  private colleaguesUrl: string = environment.API_ENDPOINT + '/v1/colleagues';
  private colleagueUrl: string = environment.API_ENDPOINT + '/v1/colleague';
  private publicColleaguesUrl: string = environment.API_ENDPOINT + '/v1/public_colleagues';
  private companyColleaguesUrl: string = environment.API_ENDPOINT + '/v1/company_colleagues';
  private companyArchivedColleaguesUrl: string = environment.API_ENDPOINT + '/v1/company_archived_colleagues';
  private departmentsUrl: string = environment.API_ENDPOINT + '/v1/departments';
  private briefDepartmentsUrl: string = environment.API_ENDPOINT + '/v1/brief_departments';
  private questionnaireUrl: string = environment.API_ENDPOINT + '/v1/send_questionnaire';
  private validateQuestionnaireUrl: string = environment.API_ENDPOINT + '/v1/validate_questionnaire';
  private lockQuestionnaireUrl: string = environment.API_ENDPOINT + '/v1/update_questionnaire_status';
  private createSharedLinkUrl: string = environment.API_ENDPOINT + '/v1/company_colleague_shared_link';
  private publicLinkCompanyUrl: string = environment.API_ENDPOINT + '/v1/public_link_company';
  private publicLinkColleaguesUrl: string = environment.API_ENDPOINT + '/v1/public_link_colleagues';
  private addPublicLinkColleagueUrl: string = environment.API_ENDPOINT + '/v1/add_public_link_colleague';
  private colleagueInformationsUrl: string = environment.API_ENDPOINT + '/v1/colleague_informations';
  private checkColleagueInformationUrl: string = environment.API_ENDPOINT + '/v1/check_colleague_information';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  addNewCompany(companyInfo: any): Observable<Company> {
    const newCompany = JSON.stringify(companyInfo);

    return this.http
      .post<Company>(`${this.companiesUrl}`, newCompany, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCompany(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getCompanyInfo(id: number): Observable<Company> {
    const url = `${this.companiesUrl}/${id}`;
    return this.http
      .get<Company>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCompany(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getPublicLinkCompany(token: string): Observable<Company> {
    const url = `${this.publicLinkCompanyUrl}/${token}`;
    return this.http
      .get<Company>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          if (response) {
            const attributes = response.data.attributes;
            // const included = response.included;
            return this.parseCompany(attributes);
          } else {
            return response;
          }
        }),
        catchError(this.handleError)
      );
  }

  getPublicLinkColleagues(token: string): Observable<Colleague[]> {
    const url = `${this.publicLinkColleaguesUrl}/${token}`;
    return this.http
      .get<Colleague[]>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagues(response.data)),
        catchError(this.handleError)
      );
  }

  updateCompanyInfo(companyId: number, updatedParams: any): Observable<Company> {
    const url = `${this.companiesUrl}/${companyId}`;
    return this.http
      .put<Company>(url, updatedParams, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCompany(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateCompanyOwner(companyId: number, newOwnerEmail: string): Observable<Company> {
    const ownerParameters = JSON.stringify({
      id: companyId,
      new_owner_email: newOwnerEmail,
    });

    return this.http
      .post<Company>(`${this.companyOwnerUpdateUrl}`, ownerParameters, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCompany(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  createSharedLink(companyId: number): Observable<Company> {
    const companyParams = JSON.stringify({
      id: companyId
    });

    return this.http
      .post<Company>(`${this.createSharedLinkUrl}`, companyParams, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseCompany(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  addColleagueInformation(colleagueId: number): Observable<ColleagueInformation> {
    const rawItem = {
      colleague_id: colleagueId,
      has_shared_link: false,
      shared_link: '',
      colleague_filters: {},
    };

    const itemInfo = JSON.stringify(rawItem);
    return this.http
      .post<ColleagueInformation>(`${this.colleagueInformationsUrl}`, itemInfo, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleagueInformation(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getDepartment(id: number): Observable<Department> {
    const url = `${this.departmentsUrl}/${id}`;
    return this.http
      .get<Department>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseDepartment(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }

  getCompanyDepartments(): Observable<Department[]> {
    return this.http
      .get<Department[]>(`${this.departmentsUrl}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleDepartments(response.data)),
        catchError(this.handleError)
      );
  }

  getBriefDepartments(companyId: number): Observable<Department[]> {
    return this.http
      .get<Department[]>(`${this.briefDepartmentsUrl}/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleDepartments(response.data)),
        catchError(this.handleError)
      );
  }

  getCompanyAllDepartments(companyId: number): Observable<Department[]> {
    return this.http
      .get<Department[]>(`${this.departmentsUrl}/all_departments/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleDepartments(response.data)),
        catchError(this.handleError)
      );
  }

  removeDepartment(departmentId: number): Observable<any> {
    const url = `${this.departmentsUrl}/${departmentId}`;
    return this.http
      .delete<any>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  uploadCompanyLogo(fileArray: any, id: number): Observable<any> {
    const typeVariable = 'logo';
    const formData: FormData = new FormData();

    for (const file of fileArray) {
      formData.append(typeVariable, file, file.name);
    }

    const httpUploadOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
        'enctype': 'multipart/form-data',
      })
    };

    return this.http
      .post<any>(`${this.uploadUrl}/${id}`, formData, httpUploadOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getUserCompanies(userId: number): Observable<Company[]> {
    return this.http
      .get<Company[]>(`${this.companiesUrl}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleCompanies(response.data)),
        catchError(this.handleError)
      );
  }

  getAdminCompanies(): Observable<Company[]> {
    return this.http
      .get<Company[]>(`${this.adminCompaniesUrl}`, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('Get ADMIN ', response);
          return this.parseMultipleCompanies(response.data);
        }),
        catchError(this.handleError)
      );
  }

  getCompanyColleagues(companyId: number): Observable<Colleague[]> {
    return this.http
      .get<Colleague[]>(`${this.companyColleaguesUrl}/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagues(response.data)),
        catchError(this.handleError)
      );
  }

  getCompanyArchivedColleagues(companyId: number): Observable<Colleague[]> {
    return this.http
      .get<Colleague[]>(`${this.companyArchivedColleaguesUrl}/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagues(response.data)),
        catchError(this.handleError)
      );
  }

  checkColleagueInformation(colleagueId: number): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.checkColleagueInformationUrl}/${colleagueId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getColleaguesList(): Observable<Colleague[]> {
    return this.http
      .get<Colleague[]>(`${this.colleaguesUrl}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagues(response.data)),
        catchError(this.handleError)
      );
  }

  getPublicColleaguesList(companyId: number): Observable<Colleague[]> {
    return this.http
      .get<Colleague[]>(`${this.publicColleaguesUrl}/${companyId}`, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseMultipleColleagues(response.data)),
        catchError(this.handleError)
      );
  }

  updatePublicLink(id: number): Observable<Colleague> {
    const url = `${this.colleaguesUrl}/${id}/update_link`;

    return this.http
      .put<Colleague>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseColleague(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }

  getPublicColleague(publicLink: string): Observable<Colleague> {
    const url = `${this.colleagueUrl}/${publicLink}`;

    return this.http
      .get<Colleague>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleague(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getColleague(id: number): Observable<Colleague> {
    const url = `${this.colleaguesUrl}/${id}`;
    return this.http
      .get<Colleague>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleague(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  getColleagueInformation(id: number): Observable<ColleagueInformation> {
    const url = `${this.colleagueInformationsUrl}/${id}`;
    return this.http
      .get<ColleagueInformation>(url, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseColleagueInformation(response.data.attributes)),
        catchError(this.handleError)
      );
  }

  updateColleagueInformation(colleagueInformationId: number, newInfoItem: any): Observable<ColleagueInformation> {
    const url = `${this.colleagueInformationsUrl}/${colleagueInformationId}`;
    const updatedFields = JSON.stringify(newInfoItem);

    return this.http
      .put<ColleagueInformation>(url, updatedFields, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseColleagueInformation(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }


  addNewDepartment(departmentInfo: any): Observable<Department> {
    const newDepartment = JSON.stringify(departmentInfo);
    return this.http
      .post<Department>(`${this.departmentsUrl}`, newDepartment, this.getHttpOptions())
      .pipe(
        map((response: any) => this.parseDepartment(response.data.attributes)),
        catchError(this.handleError)
      );
  }


  updateColleague(colleagueId: number, colleagueInfo: any): Observable<Colleague> {
    const url = `${this.colleaguesUrl}/${colleagueId}`;
    const updatedFields = JSON.stringify(colleagueInfo);

    return this.http
      .put<Colleague>(url, updatedFields, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseColleague(response.data.attributes);
        }),
        catchError(this.handleError)
      );
  }

  addColleague(colleagueInfo: any): Observable<Colleague> {
    const newColleague = JSON.stringify(colleagueInfo);

    return this.http
      .post<Colleague>(`${this.colleaguesUrl}`, newColleague, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseColleague(response.data.attributes);
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  addPublicLinkColleague(colleagueInfo: any, token: any): Observable<Colleague> {
    const url = `${this.addPublicLinkColleagueUrl}/${token}`;
    const newColleague = JSON.stringify(colleagueInfo);

    return this.http
      .post<Colleague>(url, newColleague, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          return this.parseColleague(response.data.attributes);
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  sendQuestionnaire(colleagueEmail: string, packageId: number): Observable<any> {
    const item = JSON.stringify({
      email: colleagueEmail,
      package_id: packageId,
    });

    return this.http
      .post<any>(`${this.questionnaireUrl}`, item, this.getHttpOptions())
      .pipe(
        map((response: any) => {
          // console.log('?Send questionnaire ', response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  validateQuestionnaire(token: string): Observable<any> {
    const item = JSON.stringify({
      validation_token: token,
    });

    const httpOptionsNoToken = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };

    return this.http
      .post<any>(`${this.validateQuestionnaireUrl}`, item, httpOptionsNoToken)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  lockQuestionnaireStatus(token: string, isLocked: boolean): Observable<any> {
    const item = JSON.stringify({
      validation_token: token,
      is_locked: isLocked,
    });

    const httpOptionsNoToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http
      .post<any>(`${this.lockQuestionnaireUrl}`, item, httpOptionsNoToken)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  private parseColleagueInformation(apiResponse: any): ColleagueInformation {
    return parseApiColleagueInformation(apiResponse);
  }

  private parseColleague(apiResponse: any): Colleague {
    return parseApiColleague(apiResponse, true);
  }

  private parseMultipleColleagues(apiResponse: any): Colleague[] {
    const mappedData = apiResponse.map(x => this.parseColleague(x.attributes) as Colleague);
    return mappedData;
  }

  private parseCompany(apiResponse: any): Company {
    return parseApiCompany(apiResponse, true);
  }

  private parseMultipleCompanies(apiResponse: any): Company[] {
    const mappedData = apiResponse.map(x => this.parseCompany(x.attributes) as Company);
    return mappedData;
  }

  private parseDepartment(apiResponse: any): Department {
    return parseApiDepartment(apiResponse, true);
  }

  private parseMultipleDepartments(apiResponse: any): Department[] {
    const mappedData = apiResponse.map(x => this.parseDepartment(x.attributes) as Department);
    return mappedData;
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occured', error);
    if (error._body) {
      const error_object = JSON.parse(error._body);
      console.log('Error object ', error_object.message);
    }
    return throwError(error);
  }

}
