import { RelativeParameter } from 'app/_models/relative-parameter';
import { ApiRelativeParameter } from 'app/_api_models/api-relative-parameter';
import { environment } from '../../environments/environment';

export function parseApiRelativeParameter(apiResponse: any): RelativeParameter {
  let parameterObject;
  if (environment.production) {
    parameterObject = apiResponse;
  } else {
    parameterObject = ApiRelativeParameter.check(apiResponse);
  }

  const newParameter: RelativeParameter = {
    id: parameterObject.id,
    value: parameterObject.value,
    textValue: parameterObject.text_value,
    experimentAnswerId: parameterObject.experiment_answer_id,
    packageParameterId: parameterObject.package_parameter_id,
    colleagueId: parameterObject.colleague_id,
    relativeColleagueId: parameterObject.relative_colleague_id,
    isNullValue: parameterObject.is_null_value,
  };

  return newParameter;
}

