import { ApiPackage } from 'app/_api_models/api-package';
import { Package } from 'app/_models/package';

import { Static } from 'runtypes';
import { parseApiQuestionnaire } from 'app/_api_validation/parse-api-questionnaire';
import { PostQuestionnaireAction } from 'app/_models/post-questionnaire-action';
import { PackageParameter } from 'app/_models/package-parameter';
import { PackageParticipant } from 'app/_models/package-participant';
import { parseApiPostQuestionnaireAction } from 'app/_api_validation/parse-api-pq-action';
import { parseApiPackageParameter } from 'app/_api_validation/parse-api-package-parameter';
import { parseApiPackageParticipant } from 'app/_api_validation/parse-api-package-participant';
import { parseApiDashboardConfig } from 'app/_api_validation/parse-api-dashboard-config';

import * as moment from 'moment-timezone';
import { environment } from '../../environments/environment';
import { parseApiColleagueTableConfig } from './parse-api-colleague-table-config';
import { ColleagueTableConfig } from 'app/_models/colleague-table-config';

type Pack = Static<typeof ApiPackage>;

function sortPackageParameters(apiPackageParameters: any): any[] {
  const sortedParameters = apiPackageParameters.sort(function (a: any, b: any): number {
    const moment_a = moment.utc(a.attributes.created_at);
    const moment_b = moment.utc(b.attributes.created_at);
    const value = moment_a.diff(moment_b);
    return value;
  });

  return sortedParameters;
}

export function parseApiPackage(apiResponse: any, nestedModels: boolean, included: any): Package {
  let packObject: Pack;
  if (environment.production) {
    packObject = apiResponse;
  } else {
    packObject = ApiPackage.check(apiResponse);
  }

  const questionnaireObject = parseApiQuestionnaire(apiResponse.questionnaire, false, []);
  let dashboardConfigObject;
  if (apiResponse.dashboard_config) {
    dashboardConfigObject = parseApiDashboardConfig(apiResponse.dashboard_config, false, []);
  } else {
    dashboardConfigObject = null;
  }

  const postQuestionnaireActions: PostQuestionnaireAction[] = [];
  const packageParameters: PackageParameter[] = [];
  const participants: PackageParticipant[] = [];
  let colleagueTableConfig: ColleagueTableConfig = null;

  if (nestedModels && included) {
    const apiPostQuestionnaireActions = included.filter(item => item.type === 'post_questionnaire_action');
    if (apiPostQuestionnaireActions) {
      for (const singlePQAction of apiPostQuestionnaireActions) {
        const pqaObject = parseApiPostQuestionnaireAction(singlePQAction.attributes);
        postQuestionnaireActions.push(pqaObject);
      }
    }

    const apiPackageParameters = included.filter(item => item.type === 'package_parameter');
    if (apiPackageParameters) {
      const sortedParameters = sortPackageParameters(apiPackageParameters);
      for (const singleParameter of sortedParameters) {
        const paramObject = parseApiPackageParameter(singleParameter.attributes);
        packageParameters.push(paramObject);
      }
    }

    const apiPackageParticipants = included.filter(item => item.type === 'participant');
    if (apiPackageParticipants) {
      for (const singleParticipant of apiPackageParticipants) {
        const participantObject = parseApiPackageParticipant(singleParticipant.attributes);
        participants.push(participantObject);
      }
    }

    const apiColleagueTableConfig = included.find(item => item.type === 'colleague_table_config');
    if (apiColleagueTableConfig) {
      colleagueTableConfig = parseApiColleagueTableConfig(apiColleagueTableConfig.attributes);
    }
  }

  const newPackage: Package = {
    id: packObject.id,
    isPublic: packObject.is_public,
    name: packObject.name,
    categoryName: packObject.category_name,
    description: packObject.description,
    colleagueLink: packObject.colleague_link,
    hasScale: packObject.has_scale,
    createdFromPublic: packObject.created_from_public,
    sendToColleagues: packObject.send_to_colleagues,
    companyId: packObject.company_id,
    language: packObject.language,
    multipleSubmissions: packObject.multiple_submissions,
    questionnaire: questionnaireObject,
    postQuestionnaireActions: postQuestionnaireActions,
    packageParameters: packageParameters,
    participants: participants,
    colleagueTableConfig: colleagueTableConfig,
    dashboardConfig: dashboardConfigObject,
    maxSubmissions: packObject.max_submissions,
    relativeQuestion: packObject.relative_question,
    oneDayNotifications: packObject.one_day_notifications,
    halfTermNotifications: packObject.half_term_notifications
  };

  return newPackage;
}
