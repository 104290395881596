import { Colleague } from 'app/_models/colleague';
import { ApiColleague } from 'app/_api_models/api-colleague';
import { parseApiColleagueParameter } from 'app/_api_validation/parse-api-colleague-parameter';
import { environment } from '../../environments/environment';
import { ColleagueParameter } from 'app/_models/colleague-parameter';
import * as moment from 'moment-timezone';

function sortColleagueParameters(apiColleagueParameters: any): ColleagueParameter[] {
  const sortedParameters = apiColleagueParameters.sort(function (a: any, b: any): number {
    const moment_a = moment.utc(a.created_at);
    const moment_b = moment.utc(b.created_at);
    const value = moment_a.diff(moment_b);
    return value;
  });

  return sortedParameters;
}

export function parseApiColleague(apiResponse: any, nestedModels: boolean): Colleague {
  let colleagueObject;
  if (environment.production) {
    colleagueObject = apiResponse;
  } else {
    colleagueObject = ApiColleague.check(apiResponse);
  }

  // const departmentName = '';

  const colleagueParameters = [];
  if (nestedModels) {
    const apiColleagueParameters = apiResponse.colleague_parameters;
    if (apiColleagueParameters) {
      const sortedParameters = sortColleagueParameters(apiColleagueParameters);
      for (const singleParameter of sortedParameters) {
        const parameterObject = parseApiColleagueParameter(singleParameter);
        colleagueParameters.push(parameterObject);
      }
    }
  }

  const newColleague: Colleague = {
    id: colleagueObject.id,
    uuid: colleagueObject.uuid,
    archived: colleagueObject.archived,
    departmentId: colleagueObject.department_id,
    companyId: colleagueObject.company_id,
    email: colleagueObject.email,
    bio: colleagueObject.bio,
    firstName: colleagueObject.name,
    lastName: colleagueObject.last_name,
    slackUserId: colleagueObject.slack_user_id,
    publicLink: colleagueObject.public_link,
    colleagueParameters: colleagueParameters
  };

  return newColleague;
}
