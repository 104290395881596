<div class="wrapper-style">
  <div class="buttons-block">
    <button 
      mat-raised-button 
      color="primary" 
      (click)="export()"
      style="margin-right: 5px;"
      i18n="@@button-export-file">
        Export file
    </button>
    <button 
      mat-raised-button
      color="primary"
      (click)="showResearchDetails()"
      i18n="@@button-show-researches">
        Show researches
    </button>
  </div>

  <ng-container *ngIf="tableRows.length > 0">
    <app-material-excel-table
      [inputRows]="tableRows"
      [inputColumns]="tableColumns"
      [isVerboseMode]="isVerboseMode"
      >
    </app-material-excel-table>
  </ng-container>
  <ng-container *ngIf="tableRows.length == 0">
    <ng-container *ngIf="isBootstrapped">
      <div class="warning-message">
        <h3 i18n="@@warningMessage-ResearchesTitle">You have no researches :(</h3>
        <p i18n="@@warningMessage-ResearchesBody">Start your first research by the clicking on the "Settings" tab in the header</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isBootstrapped">
      <div class="warning-message">
        <h3 i18n="@@warningMessage-Loading">Loading...</h3>
      </div>
    </ng-container>
  </ng-container>

</div>