import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';

import { CustomAlertDialogComponent } from 'app/_dialogs/custom-alert-dialog';
import { DialogRenameComponent } from 'app/_dialogs/dialog-rename';
import { SnackBarComponent } from 'app/snack-bar';
import { PeriodFormComponent } from './period-form';

import { PackageService } from 'app/_services/package.service';
import { QuestionnaireService } from 'app/_services/questionnaire.service';
import { SharedService } from 'app/_services/shared.service';
import { SharedPackageService } from 'app/_services/shared-package.service';
import { UserService } from 'app/_services/user.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';
import { MakePublicPackageHelper } from 'app/_helpers/make-public-package-helper';

import { Questionnaire } from 'app/_models/questionnaire';
import { Package } from 'app/_models/package';
import { User } from 'app/_models/user';
import { PackageParticipant } from 'app/_models/package-participant';
import { PackageParameter } from 'app/_models/package-parameter';
import { QuestionnaireCard } from 'app/_models/questionnaire-card';

@Component({
  selector: 'app-package-settings',
  templateUrl: './package-settings.component.html',
  styleUrls: ['./package-settings.component.scss'],
  providers: [SnackBarComponent]
})
export class PackageSettingsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(PeriodFormComponent, { static: false }) periodForm: PeriodFormComponent;

  currentPackageId: number;
  currentQuestionnaireId: number;
  isResearchActive: boolean = false;
  isModelConfirmed: boolean = false;
  isResearchAnonymous: boolean = false;
  sendToColleagues: boolean = false;
  isDirectLink: boolean = false;
  isAdmin: boolean = false;
  oneDayNotifications: boolean = false;
  halfTermNotifications: boolean = false;
  allDataIsReady: boolean = false;
  showHint: boolean = false;
  packageName: string;
  packageLanguage: string;
  packageDescription: string;
  colleagueLinkString: string;
  launchOptions: string;
  anonymityState: FormGroup;
  sendColleaguesState: FormGroup;
  packageParticipants: PackageParticipant[] = [];
  questionnaireCards: QuestionnaireCard[] = [];
  parametersList: PackageParameter[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private packageService: PackageService,
    private questionnaireService: QuestionnaireService,
    private sharedService: SharedService,
    private sharedPackageService: SharedPackageService,
    private authService: AuthenticationService,
    private userService: UserService,
    private snackBar: SnackBarComponent,
    private makePublicPackageHelper: MakePublicPackageHelper,
    private amplitudeService: AmplitudeEventsService,
    public  dialog: MatDialog
  ) {
    this.anonymityState = this.formBuilder.group({
      anonymity: [false, Validators.required]
    });
    this.sendColleaguesState = this.formBuilder.group({
      sendColleagues: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    this.sharedPackageService.packageParticipants$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((participants: PackageParticipant[]) => {
        this.packageParticipants = participants;
        this.checkShowHint();
      });

    this.sharedPackageService.packageParameters$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((parameters: PackageParameter[]) => {
        this.parametersList = parameters;
        this.checkShowHint();
      });

    this.sharedPackageService.questionnaireCards$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((questionnaireCards: QuestionnaireCard[]) => {
        this.questionnaireCards = questionnaireCards;
        this.checkShowHint();
        // this.allDataIsReady = this.checkResearchDataExist();
      });

    this.sharedPackageService.currentPackageId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageId: number) => {
        this.currentPackageId = packageId;
      });

    this.sharedPackageService.currentPackageName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageName: string) => {
        this.packageName = packageName;
      });

    this.sharedPackageService.currentPackageLanguage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageLanguage: string) => {
        // console.log('package language ', packageLanguage);
        this.packageLanguage = packageLanguage;
      });

    this.sharedPackageService.oneDayNotifications$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((oneDayNotifications: boolean) => {
        this.oneDayNotifications = oneDayNotifications;
      });

    this.sharedPackageService.halfTermNotifications$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((halfTermNotifications: boolean) => {
        this.halfTermNotifications = halfTermNotifications;
        console.log('start half term: ', this.halfTermNotifications);
      });

    this.sharedPackageService.questionnaireDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((questionnaire: Questionnaire) => {
        if (questionnaire) {
          const questionnaireId = questionnaire.id;
          this.currentQuestionnaireId = questionnaireId;
          const anonymous = questionnaire.anonymous;
          this.isResearchAnonymous = anonymous;
          if (questionnaire.launchOptions) {
            this.launchOptions = questionnaire.launchOptions;
          }
          if (this.anonymityState) {
            this.anonymityState = this.formBuilder.group({
              anonymity: [anonymous, Validators.required]
            });
          }
        }
      });

    this.sharedPackageService.currentPackageDescription$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((packageDescription: string) => {
        this.packageDescription = packageDescription;
      });

    this.sharedPackageService.colleagueLink$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colleagueLink: string) => {
        this.colleagueLinkString = colleagueLink;
      });

    this.sharedPackageService.sendToColleagues$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((sendToColleagues: boolean) => {
        if (sendToColleagues) {
          this.sendToColleagues = sendToColleagues;
          if (this.sendColleaguesState) {
            this.sendColleaguesState = this.formBuilder.group({
              sendColleagues: [sendToColleagues, Validators.required]
            });
          }
        }
      });

    this.sharedPackageService.onStandardSetCreated$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isCreated: boolean) => {
        if (isCreated) {
          this.openSnackBar('Package is ready');
        } else {
          this.openSnackBar('Error while copying the package');
        }
      });

    this.sharedService.currentTabId.next(4);
    this.amplitudeService.addEvent('enter settings page');
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onAnonymityChange(event: any): void {
    if (event) {
      const isAnonymous = event.value;
      if (isAnonymous !== this.isResearchAnonymous) {
        const updatedFields = {
          anonymous: isAnonymous
        };
        this.questionnaireService.updateQuestionnaire(this.currentQuestionnaireId, updatedFields)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((resultQuestionnaire: Questionnaire) => {
            this.sharedPackageService.questionnaireDetails$.next(resultQuestionnaire);
            this.isResearchAnonymous = resultQuestionnaire.anonymous;
            this.openSnackBar('Updated');
          },
          error => {
            console.log('Error ', error);
          });
      }
    }
  }

  onColleaguesSendChange(event: any): void {
    if (event) {
      const sendToColleagues = event.value;
      const updatedFields = {
        send_to_colleagues: sendToColleagues,
      };

      this.sharedPackageService.sendToColleagues$.next(sendToColleagues);
      this.sendToColleagues = sendToColleagues;
      this.updatePackage(updatedFields);
    }
  }

  onChangeOneDayNotifications(): void {
    console.log(this.oneDayNotifications);
    const updatedFields = {
      one_day_notifications: this.oneDayNotifications,
    };

    this.sharedPackageService.oneDayNotifications$.next(this.oneDayNotifications);
    this.updatePackage(updatedFields);
  }

  onChangeHalfTermNotifications(): void {
    console.log(this.halfTermNotifications);
    const updatedFields = {
      half_term_notifications: this.halfTermNotifications,
    };

    this.sharedPackageService.halfTermNotifications$.next(this.halfTermNotifications);
    this.updatePackage(updatedFields);
  }

  updatePackage(updatedFields: any): void {
    this.packageService.updatePackage(this.currentPackageId, updatedFields)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newPackage: Package) => {
        console.log(newPackage);
        this.openSnackBar('Updated');
      }, error => console.log(error));
  }

  onLaunchOptionsUpdate(): void {
    if (this.launchOptions) {
      const updatedFields = {
        launch_options: this.launchOptions,
      };

      this.questionnaireService.updateQuestionnaire(this.currentQuestionnaireId, updatedFields)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultQuestionnaire: Questionnaire) => {
          this.sharedPackageService.questionnaireDetails$.next(resultQuestionnaire);
          this.launchOptions = resultQuestionnaire.launchOptions;
          this.openSnackBar('Updated');
        },
          error => {
            console.log('Error ', error);
          });
    }
  }


  getUserInfo(): void {
    const userId = this.authService.getCurrentUserId();

    if (userId) {
      const companyId = this.sharedPackageService.packageCompanyId$.getValue();
      this.userService.getUserInfo(userId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((resultUser: User) => {
          let isAdmin = false;
          if (resultUser.admin) {
            isAdmin = resultUser.admin;
          }
          this.isAdmin = isAdmin;
          const activeCompanyId = resultUser.activeCompanyId;
          if (companyId !== activeCompanyId) {
            this.isDirectLink = true;
          }
        },
          error => {
            this.sharedService.checkSignatureExpired(error);
          });
    }
  }

  makeCompanyActive(): void {
    const companyId = this.sharedPackageService.packageCompanyId$.getValue();
    if (companyId && companyId > 0) {
      this.userService.setActiveCompany(companyId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((activeUser: User) => {
          location.reload();
        },
          error => {
            console.log(error);
          });
    }
  }

  onDelete(packageId: number): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.onDeletePackage(packageId);
      }
    });
  }

  onDeletePackage(packageId: number): void {
    const currentUser = this.sharedService.currentUser;
    if (currentUser && packageId > 0) {
      this.packageService.removePackage(packageId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
            // console.log('Package deleted');
            this.sharedPackageService.onRefreshPackage$.emit();
            this.sharedService.currentTabId.next(0);
            this.router.navigate(['/questionnaires']);
          },
          error => console.log(error));
    } else {
      console.log('Can\'t delete the package');
    }
  }

  onLanguageUpdate(packageId: number): void {
    if (this.packageLanguage && (packageId > 0)) {
      const updatedFields = {
        language: this.packageLanguage,
      };

      this.sharedPackageService.currentPackageLanguage$.next(this.packageLanguage);
      this.updatePackage(updatedFields);
    }
  }

  onRename(packageId: number): void {
    const dialogRef = this.dialog.open(DialogRenameComponent, {
      data: {
        dialogTitle: 'Rename',
        dialogSubtitle: 'Please, pick new name.',
      },
    });

    dialogRef.afterClosed().subscribe((resultName: string) => {
      if (resultName && (resultName.length > 4)) {
        this.onRenamePackage(packageId, resultName);
      } else if (resultName) {
        this.openSnackBar('Name is not valid');
      }
    });
  }

  onRenamePackage(packageId: number, newName: string): void {
    if (packageId > 0 && newName) {
      const updatedFields = {
        name: newName,
      };

      this.sharedPackageService.currentPackageName$.next(newName);
      this.updatePackage(updatedFields);
    }
  }

  onSetColleagueLink(packageId: number, newLink: string): void {
    if (packageId > 0 && newLink) {
      const updatedFields = {
        colleague_link: newLink,
      };

      this.sharedPackageService.colleagueLink$.next(newLink);
      this.updatePackage(updatedFields);
    }
  }

  onCreateStandardSet(packageId: number, packDescription: string, packageLanguage: string): void {
    const dialogRef = this.dialog.open(DialogRenameComponent, {
      data: {
        dialogTitle: 'Standard set name',
        dialogSubtitle: 'Pick unique name'
      },
    });

    dialogRef.afterClosed().subscribe((resultName: string) => {
      if (resultName && (resultName.length > 4)) {
        this.onCopyPackage(resultName, packDescription, packageLanguage);
      } else if (resultName) {
        this.openSnackBar('Name is not valid');
      }
    });
  }

  onCopyPackage(setName: string, setDescription: string, packageLanguage: string): void {
    this.snackBar.openWithTimeout('Standard set is creating, please wait...', 50000);
    this.makePublicPackageHelper.createStandardSet(setName, setDescription, packageLanguage);
  }

  onUpdateResearchActive(isActive: boolean): void {
    this.isResearchActive = isActive;
  }

  onUpdateModelConfirmed(isConfirmed: boolean): void {
    this.isModelConfirmed = isConfirmed;
  }

  onStartResearch(anonymityState: any): void {
    const isValid = this.periodForm.checkModelValid();
    const questionnaireId = this.currentQuestionnaireId;

    if (questionnaireId && isValid) {
      const periodModel = this.periodForm.getCurrentForm();
      const startDay = periodModel.startDay;
      const startTime = periodModel.startTime;
      const finishDay = periodModel.finishDay;
      const finishTime = periodModel.finishTime;
      const timezoneString = this.periodForm.getTimezoneString();

      const fullStartDate = moment.tz(startDay + 'T' + startTime, timezoneString);
      const fullFinishDate = moment.tz(finishDay + 'T' + finishTime, timezoneString);

      const daysEnabled = this.periodForm.getEnabledDaysString();
      const startOffset = moment.tz(moment.utc(), timezoneString).utcOffset();

      const interval = periodModel.interval;
      const anonymous = anonymityState.anonymity;

      // console.log('Moment to string ', fullStartDate.toString());

      this.questionnaireService.startQuestionnaireResearch(
        questionnaireId, interval, fullStartDate.toString(), fullFinishDate.toString(), daysEnabled, startOffset, anonymous)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((researchResult) => {
            this.amplitudeService.addEvent('start research');
            this.sharedPackageService.updateActiveStatus('active');
            this.sharedPackageService.onSettingsUpdate$.emit();
          },
          error => console.log(error));
    }
  }

  onPauseResearch(): void {
    const dialogRef = this.dialog.open(CustomAlertDialogComponent, {
      data: {
        dialogTitle: 'Pause research',
        dialogSubtitle: 'Are you sure you want to pause?',
        dialogConfirmText: 'Pause',
        dialogConfirmType: 'pause',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.pauseResearch();
      }
    });
  }

  pauseResearch(): void {
    const questionnaireId = this.currentQuestionnaireId;
    if (questionnaireId) {
      this.sharedPackageService.updateActiveStatus('paused');
      this.questionnaireService.updateQuestionnaireResearchStatus(questionnaireId, 'paused')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((researchResult) => {
          this.sharedPackageService.onSettingsUpdate$.emit();
        },
          error => console.log(error));
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  checkResearchDataExist(): boolean {
    const hasCards = (this.questionnaireCards.length > 0);
    const hasParameters = (this.parametersList.length > 0);
    return (hasCards && hasParameters);
  }

  checkShowHint(): void {
    this.allDataIsReady = this.checkResearchDataExist();

    let showHint = false;
    if (this.questionnaireCards && this.parametersList && this.packageParticipants) {
      if (!this.allDataIsReady) {
        showHint = true;
      }
    }
    this.showHint = showHint;
  }

  startPackage(): void {
    this.packageService.startPackage(this.currentPackageId).subscribe();
  }

  toParametersTab(): void {
    const packId = this.currentPackageId;
    this.router.navigateByUrl(`/questionnaires/${packId}/parameters`);
  }

  toQuestionsTab(): void {
    const packId = this.currentPackageId;
    this.router.navigateByUrl(`/questionnaires/${packId}/questions`);
  }

  toParticipantsTab(): void {
    const packId = this.currentPackageId;
    this.router.navigateByUrl(`/questionnaires/${packId}/participants`);
  }
}
