import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SnackBarComponent } from 'app/snack-bar';
import { DialogNotesComponent } from 'app/_dialogs/dialog-notes/dialog-notes.component';

import { User } from 'app/_models/user';
import { Colleague } from 'app/_models/colleague';
import { ColleagueNote } from 'app/_models/colleague-note';

import { UserService } from 'app/_services/user.service';
import { UtilsService } from 'app/_services/utils.service';
import { ColleagueService } from 'app/_services/colleague.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { SharedColleagueService } from 'app/_services/shared-colleague.service';
import { ActivatedRoute } from '@angular/router';


interface Note {
  id: number;
  title: string;
  content: string;
  userId: number;
  colleagueId: number;
  author: string;
  updatedAt: string;
}

@Component({
  selector: 'app-colleague-notes',
  templateUrl: './colleague-notes.component.html',
  styleUrls: ['./colleague-notes.component.scss'],
  providers: [SnackBarComponent]
})
export class ColleagueNotesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  model: Note = null;
  currentNote: Note = null;
  colleagueNotes: ColleagueNote[] = [];
  currentColleague: Colleague = null;
  currentUser: User = null;
  currentUserId: number = 0;
  currentLanguage: string = '';
  isViewMode: boolean = true;
  isEditMode: boolean = false;
  isCreateMode: boolean = false;
  isLoading: boolean = true;
  isPublicProfile: boolean = false;
  page: number = 1;
  notesLength: number = 0;
  previousLabel: string = 'Previous';
  nextLabel: string = 'Next';

  snackBarCreated = 'Note has been successfully created'
  snackBarUpdated = 'Note has been successfully updated'
  snackBarDeleted = 'Note has been successfully deleted'

  modules: any = {};
  container: any = [
    ['bold', 'italic', 'underline', 'strike'],
    ['code-block'],
    ['blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': ['', 'center', 'right'] }],
    ['link', 'image', 'video'],
    ['clean']
  ];

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: SnackBarComponent,
    private userService: UserService,
    private utilsService: UtilsService,
    private colleagueService: ColleagueService,
    private authService: AuthenticationService,
    private sharedColleagueService: SharedColleagueService
  ) {
    this.modules = {
      imageResize: {},
      toolbar: {
        container: this.container,
      }
    };
  }

  ngOnInit(): void {
    this.currentUserId = this.authService.getCurrentUserId();
    this.currentLanguage = this.utilsService.getCurrentLanguage();

    this.setDefaultModel();
    this.setSnackBarMessages();
    this.setPaginationLabels();
    this.checkProfilePublicity();

    this.getUserInfo();
    this.getCurrentColleague();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCurrentColleague(): void {
    this.sharedColleagueService.colleague$
      .pipe(
        filter(colleague => colleague !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleague: Colleague) => {
        this.currentColleague = colleague;
        this.model.colleagueId = colleague.id;
        this.getNotesLength();
        this.getColleagueNotes();
      })
  }

  getUserInfo(): void {
    if (this.isPublicProfile) {
      return;
    }

    this.userService.getUserInfo(this.currentUserId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: User) => {
        this.currentUser = user;
      })
  }

  getColleagueNotes(page: number = 1): void {
    this.isLoading = true;
    this.colleagueService.getNotes(this.currentColleague.id, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(filter(colleagueNotes => colleagueNotes !== null))
      .subscribe((colleagueNotes: ColleagueNote[]) => {
        this.isLoading = false;
        this.colleagueNotes = colleagueNotes;
        this.formatNotes();
      })
  }

  getNotesLength(): void {
    this.colleagueService.getNotesLength(this.currentColleague.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.notesLength = +result;
      });
  }

  onTableDataChange(event: any): void {
    this.page = event;
    this.getColleagueNotes(this.page)
  }

  createNewNote(): void {
    const note = this.model;
    note.userId = this.currentUserId;
    note.colleagueId = this.currentColleague.id;
    note.content = this.currentNote.content;

    this.colleagueService.createNote(note.title, note.content, note.userId, note.colleagueId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.notesLength++;
        this.page = 1;
        this.openSnackBar(this.snackBarCreated);
        this.getColleagueNotes();
        this.onViewMode();
      });
  }

  updateNote(): void {
    this.colleagueService.updateNote(this.currentNote.id, this.currentNote.title, this.currentNote.content)
      .subscribe(() => {
        this.page = 1;
        this.openSnackBar(this.snackBarUpdated);
        this.getColleagueNotes();
        this.onViewMode();
      });
  }

  deleteNote(): void {
    this.colleagueService.deleteNote(this.currentNote.id)
      .subscribe(() => {
        this.notesLength--;
        this.page = 1;
        this.openSnackBar(this.snackBarDeleted);
        this.getColleagueNotes();
        this.onViewMode();
      });
  }

  updateCurrentNote(note?: ColleagueNote): void {
    if (note) {
      this.currentNote.id = note.id;
      this.currentNote.title = note.title;
      this.currentNote.content = note.content;
      this.currentNote.author = note.user.firstName + ' ' + note.user.lastName;
      this.currentNote.updatedAt = note.updatedAt;
    } else {
      this.currentNote.id = 0;
      this.currentNote.title = '';
      this.currentNote.content = '';
      this.currentNote.author = '';
      this.currentNote.updatedAt = '';
    }
  }

  formatNotes(): void {
    for (const note of this.colleagueNotes) {
      const timeFormat = 'D MMMM YYYY';
      const currentTimeZone = moment.tz.guess();
      note.updatedAt = moment(note.updatedAt).locale(this.currentLanguage).tz(currentTimeZone).format(timeFormat);
    }
  }

  confirmCreateNewNote(): void {
    const dialogRef = this.dialog.open(DialogNotesComponent, {
      data: {
        isCreateMode: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.createNewNote();
      } else {
        return;
      }
    });
  }

  confirmUpdateNote(): void {
    const dialogRef = this.dialog.open(DialogNotesComponent, {
      data: {
        isUpdateMode: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.updateNote();
      } else {
        return;
      }
    });
  }

  confirmDeleteNote(): void {
    const dialogRef = this.dialog.open(DialogNotesComponent, {
      data: {
        isDeleteMode: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteNote();
      } else {
        return;
      }
    });
  }

  checkProfilePublicity(): void {
    const colleaguePublicLink = this.route.parent.snapshot.params.public_link;
    if (colleaguePublicLink !== undefined) {
      this.isPublicProfile = true;
    }
  }

  disableAllModes(): void {
    this.isEditMode = false;
    this.isViewMode = false;
    this.isCreateMode = false;
  }

  onEditMode(note: ColleagueNote): void {
    this.disableAllModes();
    this.isEditMode = true;
    this.updateCurrentNote(note);
  }

  onCreateMode(): void {
    this.disableAllModes();
    this.isCreateMode = true;
    this.updateCurrentNote();
  }

  onViewMode(): void {
    this.disableAllModes();
    this.isViewMode = true;
    this.updateCurrentNote();
  }

  setPaginationLabels(): void {
    if (this.currentLanguage === 'ru') {
      this.previousLabel = 'Назад';
      this.nextLabel = 'Далее';
    }
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  setSnackBarMessages(): void {
    if (this.currentLanguage === 'ru') {
      this.snackBarCreated = 'Заметка была успешно создана';
      this.snackBarUpdated = 'Заметка была успешно обновлена';
      this.snackBarDeleted = 'Заметка была успешно удалена';
    }
  }

  setDefaultModel(): void {
    const model: Note = {
      id: 0,
      title: '',
      content: '',
      userId: 0,
      colleagueId: 0,
      author: '',
      updatedAt: ''
    };

    this.model = model;
    this.currentNote = model;
  }
}
