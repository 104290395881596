<div class="answer-header card-wrapper">
  <mat-card-subtitle>
    <label class="left-content-part"><span i18n="@@questionnaire">Questionnaire</span> №{{packageId}}</label>
    <span class="right-content-part">
          <button mat-raised-button color="primary" (click)="goBack()" i18n="@@prev-label">
      BACK
    </button>
    </span>
  </mat-card-subtitle>
  <h2 class="title"><span i18n="@@experimentAnswer-title">Experiment answers from </span> {{colleagueName}}</h2>
  <h2><span>({{experimentPeriod}})</span></h2>
  <a href="{{experimentLink}}">{{experimentLink}}</a>
</div>
<div class="card-wrapper" flex>
  <div class="left-content-part">
    <div *ngIf="isAnswered === true">
      <mat-card *ngFor="let singleCard of localCards; let i = index">
        <p><span i18n="@@question-label">Question</span><span> #{{i + 1}}</span></p>
        
        <mat-card-content>
          <!-- <h2>{{singleCard.questionText}}</h2> -->
          <div 
            *ngIf="singleCard.questionText" 
            class="remove-all-styles"
            [innerHtml]="singleCard.questionText | safeHtml">
          </div>
          <p *ngFor="let option of singleCard.cardOptions">
            <span *ngIf="isChosen(option)">
              <mat-icon mat-list-icon style="font-size: 10px;">fiber_manual_record</mat-icon>
              <ng-container *ngIf="option.text === 'INPUT'">
                {{getUserInput(option.id)}} ({{option.text}})
              </ng-container>
              <ng-container *ngIf="option.text !== 'INPUT'">
                {{option.text}}
              </ng-container>
            </span>
          </p>

          <div *ngIf="singleCard.questionType === 'text-option'">
            <mat-form-field style="width: 400px">
              <input matInput type="text" [(ngModel)]="textOption">
            </mat-form-field>
          </div>

        </mat-card-content>

      </mat-card>
    </div>
    <div *ngIf="isAnswered === false">
      <p i18n="@@answer-missed">This colleague didn't answer in the experiment.</p>
    </div>
  </div>

  <div class="right-content-part" *ngIf="isAnswered && isCalculated">
    <mat-card>
      <h2>Individual Parameters:</h2>
      <ul>
        <li *ngFor="let parameterResult of resultStateIndividual">
          {{parameterResult.name}}: {{parameterResult.value}}
        </li>
      </ul>
      <h2>Relative Parameters:</h2>
      <ul>
        <li *ngFor="let parameterResult of resultStateRelative">
          {{parameterResult.name}}: {{parameterResult.value}}
          <ng-container *ngIf="parameterResult.relativeColleague">
            (for <b>{{parameterResult.relativeColleague}}</b>)
          </ng-container>
        </li>
      </ul>
    </mat-card>
  </div>
  
</div>
