import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Experiment } from 'app/_models/experiment';
import { DashboardConfig } from 'app/_models/dashboard-config';
import { PackageParameter } from 'app/_models/package-parameter';
import { ExtendedParameter, WidgetFilters } from '../_helpers/local-models';
import { SharedPackageService } from 'app/_services/shared-package.service';

@Component({
  selector: 'app-common-text-card-widget',
  templateUrl: './common-text-card-widget.component.html',
  styleUrls: ['./common-text-card-widget.component.scss', '../widgets.scss']
})
export class CommonTextCardWidgetComponent implements OnInit, OnDestroy {
  @Input() userView: boolean;
  @Input() testData: boolean;
  @Input() inputWidgetId: number;
  @Input() isPublicLink: boolean;

  @Output() onWidgetUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWidgetDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteWidget: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFiltersUpdate: EventEmitter<any> = new EventEmitter<any>();

  public showTextMode: boolean = true;
  public parametersEditMode: boolean = false;

  private activeParameter: ExtendedParameter;
  private inputWidgetFilters: WidgetFilters;
  private widgetFilters: WidgetFilters = {};
  private experiments: Experiment[] = [];
  private localParameters: ExtendedParameter[] = [];
  private widgetText: string = 'Loading...';
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private sharedPackageService: SharedPackageService) { }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getData(): void {
    combineLatest([
      this.sharedPackageService.getDashboardConfig(),
      this.sharedPackageService.getPackageParameters(),
      this.sharedPackageService.getPackageExperiments()
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        const dashboardConfig = result[0];
        const packageParameters = result[1];
        const packageExperiments = result[2];

        this.setData(dashboardConfig, packageParameters, packageExperiments);
      });
  }

  setData(dashboardConfig: DashboardConfig, parameters: PackageParameter[], experiments: Experiment[]): void {
    this.experiments = experiments;
    this.widgetText = '';
    this.filterParameters(parameters);
    this.setWidgetFilters(dashboardConfig);
    this.checkInputWidgets();
    this.formWidgetText();
  }

  filterParameters(parameters: PackageParameter[]): void {
    const filteredParams = parameters.filter(param => param.groupName === 'common' && param.commonValue.type === 'text');
    for (const parameter of filteredParams) {
      this.localParameters.push({
        ...parameter,
        isActive: false
      });
    }
  }

  setWidgetFilters(dashboardConfig: DashboardConfig): void {
    const widget = dashboardConfig.dashboardWidgets.find(param => param.id === this.inputWidgetId);

    if (widget) {
      this.inputWidgetFilters = widget.widgetFilters;
    }
  }

  checkInputWidgets(): void {
    if (this.inputWidgetFilters && this.inputWidgetId > 0) {
      this.restoreInputWidgetsFilters();
    } else {
      this.setDefaultWidgetFilters();
    }

    if (!this.userView) {
      this.widgetFiltersUpdate();
    }
  }

  formWidgetText(): void {
    this.widgetText = '';
    if (this.experiments && this.experiments.length > 0) {
      const lastExperiment = this.getLatestExperiment();
      if (lastExperiment) {
        const textParameter = lastExperiment.experimentParameters.find(param => param.packageParameterId === this.activeParameter.id);
        if (textParameter) {
          this.widgetText = String(textParameter.commonValue.value);
        }
      }
    }
  }

  restoreInputWidgetsFilters(): void {
    const paramsIds = this.inputWidgetFilters.activeParametersIds;
    if (paramsIds && paramsIds.length > 0) {
      const activeParameterId = paramsIds[0];
      const findParam = this.localParameters.find(param => param.id === activeParameterId);
      if (findParam) {
        this.activeParameter = findParam;
      }
    }
  }

  setDefaultWidgetFilters(): void {
    this.widgetFilters['showLatestResearch'] = true;

    if (this.localParameters.length > 0) {
      this.activeParameter = this.localParameters[0];
    }
  }

  widgetFiltersUpdate(): void {
    const activeParamsIds = [];

    if (this.activeParameter) {
      activeParamsIds.push(this.activeParameter.id);
    }

    this.widgetFilters['activeParametersIds'] = activeParamsIds;

    if (!this.userView) {
      this.onFiltersUpdate.emit(this.widgetFilters);
    }
  }

  getLatestExperiment(): Experiment {
    if (this.experiments) {
      const sortedExperimens = this.experiments.sort((a: any, b: any): number => b.id - a.id);
      return sortedExperimens[0];
    }
  }

  onParametersEditMode(): void {
    this.showTextMode = false;
    this.parametersEditMode = true;
  }

  onShowTextMode(): void {
    this.showTextMode = true;
    this.parametersEditMode = false;
    this.formWidgetText();
  }

  onParametersStateChange(): void {
    this.widgetFiltersUpdate();
  }

  widgetUp(): void {
    this.onWidgetUp.emit();
  }

  widgetDown(): void {
    this.onWidgetDown.emit();
  }

  removeWidget(): void {
    this.onDeleteWidget.emit();
  }
}
