import { ApiRelativeExperimentAnswer } from 'app/_api_models/api-relative-experiment-answer';
import { parseApiRelativeExperimentParameter } from 'app/_api_validation/parse-api-relative-experiment-parameter';

import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { RelativeExperimentAnswer } from 'app/_models/ralative-experiment-answer';
import { RelativeExperimentParameter } from 'app/_models/relative-experiment-parameter';

function sortColleagueParameters(apiColleagueParameters: any): any[] {
  const sortedParameters = apiColleagueParameters.sort(function (a: any, b: any): number {
    const moment_a = moment.utc(a.attributes.created_at);
    const moment_b = moment.utc(b.attributes.created_at);
    const value = moment_a.diff(moment_b);
    return value;
  });

  return sortedParameters;
}

export function parseApiRelativeExperimentAnswer(apiResponse: any, nestedModels: boolean, included: any): RelativeExperimentAnswer {
  let answerObject;
  if (environment.production) {
    answerObject = apiResponse;
  } else {
    answerObject = ApiRelativeExperimentAnswer.check(apiResponse);
  }

  const relativeExperimentParameters: RelativeExperimentParameter[] = [];
  if (nestedModels && included) {
    const apiRelativeParameters = included.filter(item => (item.type === 'relative_experiment_parameter'));
    if (apiRelativeParameters) {
      const sortedParameters = sortColleagueParameters(apiRelativeParameters);
      for (const singleParameter of sortedParameters) {
        const parameterObject = parseApiRelativeExperimentParameter(singleParameter.attributes);
        relativeExperimentParameters.push(parameterObject);
      }
    }
  }

  const newRelativeAnswer: RelativeExperimentAnswer = {
    id: answerObject.id,
    experimentId: answerObject.experiment_id,
    questionnaireId: answerObject.questionnaire_id,
    calculated: answerObject.calculated,
    relativeExperimentParameters: relativeExperimentParameters
  };

  if (answerObject.colleague_id) {
    newRelativeAnswer.colleagueId = answerObject.colleague_id;
  }
  if (answerObject.email) {
    newRelativeAnswer.email = answerObject.email;
  }

  return newRelativeAnswer;
}
