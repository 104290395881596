import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable()
export class MembershipService {
  private membershipUrl: string = environment.API_ENDPOINT + '/v1/membership';

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) { }

  getHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + this.authService.getAccessToken(),
      })
    };

    return httpOptions;
  }

  addMembership(idPackage: number, email: string): Observable<any> {
    const url = this.membershipUrl;
    const item = JSON.stringify({user_email: email, package_id: idPackage});

    return this.http
      .post<any>(url, item, this.getHttpOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMembership(idMembership: number, idPackage: number,
    email: string, permissions: any): Observable<any> {
      const url = `${this.membershipUrl}/${idMembership}`;
      const params = {user_email: email, package_id: idPackage};
      const item = JSON.stringify(Object.assign({}, params, permissions));

      return this.http
        .put<any>(url, item, this.getHttpOptions())
        .pipe(
          catchError(this.handleError)
        );
  }

  private handleError(error: any): Observable<any> {
    return throwError(error);
  }

}
