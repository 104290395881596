import { Number, String, Record, Partial, Array, Boolean } from 'runtypes';
import { ApiColleagueParameter } from 'app/_api_models/api-colleague-parameter';

export const ApiColleague = Record({
  id: Number,
  uuid: String,
  email: String,
  bio: String,
  name: String,
  last_name: String,
  department_id: Number,
  company_id: Number,
  archived: Boolean,
  slack_user_id: String,
  public_link: String
}).And(Partial({
  colleague_parameters: Array(ApiColleagueParameter)
}));
