<div class="package-questionnaire">
  <ng-container *ngIf="!this.editQuestionMode && !this.editPQActionMode">

    <div class="package-questionnaire-wrap">
        <div class="questions-list" (cdkDropListDropped)="drop($event)" flex>
          <ng-container *ngIf="cards.length > 0 || postQuestionnaireActions.length > 0">
            <div cdkDropList (cdkDropListDropped)="dropQuestion($event)">
              <div class="question-box" cdkDrag [cdkDragData]="card" [cdkDragDisabled]="!card.isActive"
                   *ngFor="let card of cards">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>
                      <span i18n="@@question-label">Question</span> #{{card.orderPosition}}
                      <div class="question-status" [ngClass]="{ isActive: card.isActive, isPaused: !card.isActive }">
                        <ng-container *ngIf="card.isActive">
                          <div class="question-status__text" i18n="@@activeQuestion">
                            Active
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!card.isActive">
                          <div class="question-status__text" i18n="@@pausedQuestion">
                            Paused
                          </div>
                        </ng-container>
                      </div>
                    </mat-card-title>
                    <div #cardReference [attr.isActive]="card.id == activeCardId ? true : null">
                    </div>
                    <div class="button-group">
                      <span class="example-handle" cdkDragHandle *ngIf="card.isActive">
                        <svg width="18px" fill="#61728d" viewBox="0 0 24 24">
                          <path
                              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>

                      </span>
                      
                      <ng-container *ngIf="isAdmin">
                        <button
                          class="card-button card-button--copy" 
                          mat-raised-button 
                          (click)="copyQuestion(card)">
                        </button>
                      </ng-container>
                      
                      <button
                          class="card-button card-button--edit"
                          mat-raised-button
                          (click)="toEditQuestionPage(card, card.id)">
                      </button>

                      <button
                          class="card-button card-button--remove"
                          mat-raised-button
                          (click)="removeQuestion(card)">
                      </button>

                      <ng-container *ngIf="card.isActive">
                        <button
                          class="card-button card-button--pause"
                          mat-raised-button
                          (click)="updateQuestionCardStatus(card, false)">
                          <mat-icon>
                            pause
                          </mat-icon>
                        </button>
                      </ng-container>
                      
                      <ng-container *ngIf="!card.isActive">
                        <button
                          class="card-button card-button--play"
                          mat-raised-button
                          (click)="updateQuestionCardStatus(card, true)">
                          <mat-icon>
                            play_arrow
                          </mat-icon>
                        </button>
                      </ng-container>
                    </div>
                  </mat-card-header>
                  <app-single-card-view 
                    [card]="card" 
                    [errorList]="errorList"
                    [adminMode]="isAdmin"
                  >
                  </app-single-card-view>
                </mat-card>

              </div>
            </div>

            <div cdkDropList (cdkDropListDropped)="dropAction($event)" *ngIf="isAdmin">
              <div class="pqa-box" cdkDrag [cdkDragData]="pqAction"
                   *ngFor="let pqAction of postQuestionnaireActions; let idAction = index;"
              >
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>
                      <span i18n="@@PCA-title">Post Calculation Action</span> #{{idAction + 1}}
                    </mat-card-title>
                    <div class="button-group">
                      <span class="example-handle" cdkDragHandle>
                        <svg width="18px" fill="#61728d" viewBox="0 0 24 24">
                          <path
                              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>

                      </span>
                      <button
                          class="card-button card-button--edit"
                          mat-raised-button
                          (click)="toEditPQActionPage(pqAction)">

                      </button>

                      <button
                          class="card-button card-button--remove"
                          mat-raised-button
                          (click)="removePQAction(pqAction)">

                      </button>
                    </div>

                  </mat-card-header>
                  <mat-card-content>
                    <div class="actions">
                      <span>{{pqAction.parameter}}</span>
                      <i class="material-icons arrow-rotated">
                        keyboard_backspace
                      </i>
                      <span>{{pqAction.text}}</span>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="cards.length === 0" >
            <div class="warning-message">
              <h3>
                <span i18n="@@HINT-press">Press</span>
                "<span style="color: #5648fd; cursor: pointer;" (click)="toCreateQuestionPage()"  i18n="@@createQuestion">CREATE QUESTION</span>"
                <span i18n="@@HINT-pressbutton">button, to add insightful questions to your team.</span></h3>
            </div>
          </ng-container>
        </div>

      <div class="right-sidebar">
        <div class="button-add">
          <button
              mat-raised-button
              color="primary"
              (click)="toCreateQuestionPage()">
            <img src="/assets/overmind_icons/rounded-add-button-1.svg">
            <span i18n="@@createQuestion"> Create Question</span>
          </button>
        </div>
        <div class="button-add">
          <button
              mat-raised-button
              class="button-parameter-add"
              (click)="toParametersPage()">
            <img src="/assets/overmind_icons/rounded-add-button-2.svg">
            <span i18n="@@parameters-tab"> Parameters</span>
          </button>
        </div>
        <div class="button-add">
          <button
              mat-raised-button
              color="accent"
              (click)="toCreatePQActionPage()">
            <img src="/assets/overmind_icons/rounded-add-button-1.svg">
            <span i18n="@@createPQA"> ADD Post Calculation Action</span>
          </button>
        </div>
        <div class="button-view">
          <button
              [disabled]="cards.length < 1"
              mat-raised-button
              (click)="viewDemo()"
              >
            <img src="/assets/overmind_icons/demo-eye.svg">
            <span i18n="@@demoMode-title"> Preview</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="this.editQuestionMode">
    <app-edit-question
        [currentPackageId]="currentPackageId"
        [editedQuestion]="editedQuestion"
        (updateQuestion)="updateQuestion($event)"
        (changeTab)="selectTab($event)"
    >
    </app-edit-question>
  </div>

  <div *ngIf="this.editPQActionMode">
    <app-edit-pq-action
        [currentPackageId]="currentPackageId"
        [editedPQAction]="editedPQAction"
        (updatePQAction)="updatePQAction($event)"
    >
    </app-edit-pq-action>
  </div>
</div>
