import { QuestionnaireCard } from './questionnaire-card';
import { Experiment } from './experiment';

export class Questionnaire {
  id: number;
  packageId: number;
  startDate: string;
  finishDate: string;
  lastRun: string;
  nextRun: string;
  interval: string;
  activeStatus: string;
  daysEnabled: string;
  startOffset: number;
  anonymous: boolean;
  needCalculation: boolean;
  launchOptions: string;
  questionnaireCards: QuestionnaireCard[];
  experiments: Experiment[];
}
