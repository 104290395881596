import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'app/_services/user.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { CompanyService } from 'app/_services/company.service';
import { SharedService } from 'app/_services/shared.service';
import { AmplitudeEventsService } from 'app/_services/amplitude-events.service';

@Component({
  selector: 'app-quickstart',
  templateUrl: './quickstart.component.html',
  styleUrls: ['./quickstart.component.scss']
})
export class QuickstartComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private sharedService: SharedService,
    private amplitudeService: AmplitudeEventsService,
  ) { }

  ngOnInit(): void {
    const email = this.route.snapshot.paramMap.get('email');
    const pass = this.genRandomString();
    this.createUser(email, pass);
  }

  genRandomString(): string {
    const convertString = function(): string {
      return Math.random().toString(36).substr(2, 5);
    };

    let randString = convertString();
    let counter = 0;

    while ((randString.length < 20) && (counter < 20)) {
      randString = randString + convertString();
      counter = counter + 1;
    }

    const pass = randString.substr(5, 8);

    return pass;
  }

  createUser(email: string, password: string): void {
    if (email && password) {
      this.userService.addQuickstartUser('Username', 'Surname', email, password)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          if (result) {
            this.amplitudeService.addEvent('quickstart registration');
            this.login(email, password);
          }
        },
        error => {
          console.log(error);
          this.router.navigate(['/login/']);
        });
    }
  }

  login(email: string, password: string): void {
    this.authService.login(email, password)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        // console.log('AUTH LOGIN ', result);
        if (result === true) {
          const newName = `${email}'s Company`;
          this.createDefaultCompany(newName);
          this.sharedService.onLogin(email);
          this.sharedService.loginMode$.emit(false);
          // this.router.navigate(['/questionnaires/standard-sets']);
        } else {
          // this.error = 'Username or password is incorrect';
          // this.loading = false;
        }
      },
      error => {
        console.log('ERROR ', error);
      });
  }

  createDefaultCompany(newCompanyName: string): void {
    console.log('Create company ', newCompanyName);
    if (newCompanyName) {
      const companyInfo = {
        name: newCompanyName,
        packages: [],
        logo: '',
      };

      this.companyService.addNewCompany(companyInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
            if (result) {
              this.userService.setActiveCompany(result.id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(activeCompany => {
                  if (activeCompany) {
                    this.sharedService.currentCompanyId.next(activeCompany.id);
                    // this.router.navigate(['/questionnaires/standard-sets']);
                    this.router.navigate(['/home/']);
                  }
                },
                error => {
                  console.log(error);
                });
            }
          },
          error => {
            console.log(error);
          });
    }
  }


}
