import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'app/../environments/environment';
import { filter, takeUntil, first, switchMap } from 'rxjs/operators';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import * as moment from 'moment-timezone';

import { SnackBarComponent } from 'app/snack-bar/snack-bar.component';

import { UtilsService } from 'app/_services/utils.service';
import { CompanyService } from 'app/_services/company.service';
import { ExperimentService } from 'app/_services/experiment.service';
import { CompanyDataService } from 'app/_services/company-data.service';
import { SharedUserService } from 'app/_services/shared-user.service';
import { SharedColleagueService } from 'app/_services/shared-colleague.service';
import { SharedCompanyDataService } from 'app/_services/shared-company-data.service';

import { Entity } from 'app/_models/overmind_ds/entity';
import { EntityService } from 'app/_services/overmind_ds/entity.service';

import { User } from 'app/_models/user';
import { Package } from 'app/_models/package';
import { Colleague } from 'app/_models/colleague';
import { Department } from 'app/_models/department';
import { Experiment } from 'app/_models/experiment';
import { ExperimentAnswer } from 'app/_models/experiment-answer';
import { PackageParameter } from 'app/_models/package-parameter';
import { RelativeParameter } from 'app/_models/relative-parameter';
import { ParameterVisibility } from 'app/_models/parameter-visibility';
import { ColleagueInformation } from 'app/_models/colleague-information';
import { PackageData, ColleagueTable, ColleagueTableAnswer, ColleagueTableParameter, ExtendedParameter } from './local-models';



@Component({
  selector: 'app-colleague-profile',
  templateUrl: './colleague-profile.component.html',
  styleUrls: ['./colleague-profile.component.scss'],
  providers: [SnackBarComponent]
})
export class ColleagueProfileComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  companyId: number = 0;
  colleagueId: number = null;
  selectedTabId: number = null;

  fullName: string = '';
  slackUserId: string = '';
  colleagueEmail: string = '';
  colleaguePublicLink: string = '';
  fullPublicLink: string = '';
  colleagueDepartment: string = '';
  currentLanguage: string = '';
  baseUrl: string = environment.BASE_ANGULAR_URL;
  isPublicProfile: boolean = false;
  profileUrl: string = '';

  currentUser: User = null;
  packages: Package[] = [];
  experiments: Experiment[] = [];
  packagesData: PackageData[] = [];

  colleagueInfo: ColleagueInformation;
  colleagueTables: ColleagueTable[] = [];
  packageParameters: PackageParameter[] = [];
  individualParameters: ExtendedParameter[] = [];
  parameterVisibilities: ParameterVisibility[] = [];
  relativeColleagues: Colleague[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: SnackBarComponent,
    private entityService: EntityService,
    private utilityService: UtilsService,
    private companyService: CompanyService,
    private experimentService: ExperimentService,
    private companyDataService: CompanyDataService,
    private sharedUserSerivce: SharedUserService,
    private sharedColleagueService: SharedColleagueService,
    private sharedCompanyDataService: SharedCompanyDataService
  ) { }

  ngOnInit(): void {
    this.sharedColleagueService.clearTables();
    this.currentLanguage = this.utilityService.getCurrentLanguage();
    this.colleagueId = +this.route.snapshot.params.id;
    this.colleaguePublicLink = this.route.snapshot.params.public_link;
    this.setSelectedTabId();
    this.checkProfilePublicity();
    this.setProfileUrl();
    this.formColleaguesData();
    this.onColleagueUpdate();
    this.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getCurrentUser(): void {
    this.sharedUserSerivce.currentUser$
      .pipe(
        filter(user => user !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((user: User) => {
        this.currentUser = user;

        if (this.isPublicProfile) {
          this.getPublicColleague();
        } else {
          this.getColleague();
        }
      });
  }

  getPublicColleague(): void {
    this.companyService.getPublicColleague(this.colleaguePublicLink)
      .pipe(
        filter(colleague => colleague !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleague: Colleague) => {
        this.sharedColleagueService.addColleague(colleague);
        this.companyDataService.getCompanyData(colleague.companyId);
        this.formColleagueBriefData(colleague);
        this.checkColleagueInformation(this.colleagueId);
        this.getDepartmentName(colleague.departmentId);
      }, error => console.log(error));
  }

  onColleagueUpdate(): void {
    this.sharedColleagueService.onColleagueUpdate$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getColleague();
      });
  }

  getColleague(): void {
    this.companyService.getColleague(this.colleagueId)
      .pipe(
        filter(colleague => colleague !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleague: Colleague) => {
        // Если пользователь является создателем компании, в которой состоит сотрудник
        if (colleague.companyId === this.currentUser.activeCompanyId) {
          this.sharedColleagueService.addColleague(colleague);
          this.formColleagueBriefData(colleague);
          this.checkColleagueInformation(this.colleagueId);
          this.getDepartmentName(colleague.departmentId);
          this.createNewEntity(colleague);
        } else {
          this.router.navigate(['/not-found']);
        }
      }, error => console.log(error));
  }

  formColleagueBriefData(colleague: Colleague): void {
    const newFullName = colleague.firstName + ' ' + colleague.lastName;
    this.fullName = newFullName;
    this.colleagueId = colleague.id;
    this.colleagueEmail = colleague.email;
    this.slackUserId = colleague.slackUserId;
    this.companyId = colleague.companyId;
    this.colleaguePublicLink = colleague.publicLink;
    this.fullPublicLink = this.baseUrl + '/colleague/' + this.colleaguePublicLink;
  }

  getDepartmentName(departmentId: number): void {
    if (departmentId === 0) return;

    this.companyService.getDepartment(departmentId)
      .pipe(
        filter(department => department !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((department: Department) => {
        this.colleagueDepartment = department.name;
      }, error => console.log(error));
  }

  checkColleagueInformation(colleagueId: number): void {
    this.companyService.checkColleagueInformation(colleagueId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resultState: any) => {
        const hasInfo = resultState.has_information;
        const valid = resultState.valid_data;
        if (valid && !hasInfo) {
          this.createColleagueInformation(colleagueId);
        } else if (valid && hasInfo) {
          const infoId = resultState.information_id;
          this.loadColleagueInformation(infoId);
        } else {
          console.log('Error');
        }
      }, error => console.log(error));
  }

  createColleagueInformation(colleagueId: number): void {
    this.companyService.addColleagueInformation(colleagueId)
      .pipe(
        filter(colleagueInfo => colleagueInfo !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleagueInfo: ColleagueInformation) => {
        this.colleagueInfo = colleagueInfo;
      }, error => console.log(error));
  }

  loadColleagueInformation(colleagueId: number): void {
    this.companyService.getColleagueInformation(colleagueId)
      .pipe(
        filter(colleagueInfo => colleagueInfo !== null),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((colleagueInfo: ColleagueInformation) => {
        this.colleagueInfo = colleagueInfo;
      }, error => console.log(error));
  }

  // Создание сущности в overmind_ds сервисе, если она там отсутствует
  createNewEntity(colleague: Colleague): void {
    const entityData = this.formColleagueEntityData(colleague);

    this.entityService.getEntity(colleague.uuid)
      .pipe(
        filter(entity => entity === null),
        switchMap(() => this.entityService.createNewEntity(entityData)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  formColleagueEntityData(colleague: Colleague): any {
    const entityData = JSON.stringify({
      id: colleague.id,
      first_name: colleague.firstName,
      last_name: colleague.lastName,
      email: colleague.email,
      department_id: colleague.departmentId,
      company_id: colleague.companyId
    });

    const entity = JSON.stringify({ uuid: colleague.uuid, entity_type_title: 'colleague', data: entityData });

    return entity;
  }

  formColleaguesData(): void {
    combineLatest([
      this.sharedCompanyDataService.packages$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.packageParameters$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.extendedParameters$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.parameterVisibilities$.pipe(filter(result => result !== null)),
      this.sharedCompanyDataService.colleaguesList$.pipe(filter(result => result !== null))
    ])
      .pipe(
        first(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((result) => {
        this.packages = result[0];
        this.packageParameters = result[1];
        this.individualParameters = result[2];
        this.parameterVisibilities = result[3];
        this.relativeColleagues = result[4];
        this.getLastColleagueAnswers(this.packages, this.colleagueId);
      }, error => console.log(error));
  }

  getLastColleagueAnswers(packages: Package[], colleagueId: number): void {
    forkJoin(
      packages.map(pack => this.experimentService.getLastColleagueAnswer(pack.questionnaire.id, colleagueId))
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((experimentAnswers: ExperimentAnswer[]) => {
        const filteredAnswers = experimentAnswers.filter(answer => answer !== undefined);
        if (filteredAnswers.length > 0) {
          this.getExperiments(packages, filteredAnswers);
        } else {
          this.sharedColleagueService.addTables([]);
        }
      });
  }

  getExperiments(packages: Package[],experimentAnswers: ExperimentAnswer[]): void {
    forkJoin(
      experimentAnswers.map(answer => this.experimentService.getExperiment(answer.experimentId))
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((experiments: Experiment[]) => {
        experiments.forEach((experiment: Experiment) => {
          const filteredAnswer = experimentAnswers.filter(answer => answer.experimentId === experiment.id);
          experiment.experimentAnswers = filteredAnswer;
        });

        this.formPackagesData(packages, experiments);
      });
  }

  formPackagesData(verbosePackages: Package[], experiments: Experiment[]): void {
    const packagesData: PackageData[] = [];

    verbosePackages.forEach((verbosePackage: Package) => {
      const lastExperiment = experiments.find(experiment => experiment !== null && experiment.packageId === verbosePackage.id);

      const newPackageData: PackageData = {
        package: verbosePackage,
        experiment: lastExperiment
      }

      packagesData.push(newPackageData);
    })

    this.packagesData = packagesData;
    this.formExperimentsData();
  }

  formExperimentsData(): void {
    this.colleagueTables = [];

    this.packagesData.forEach((packageData: PackageData) => {
      const newColleagueTable = this.formSingleExperimentData(packageData.experiment, packageData.package);
      this.colleagueTables.push(newColleagueTable);
    })

    this.sharedColleagueService.addTables(this.colleagueTables);
  }

  getRelativeColleagues(ids: number[]): void {
    ids.forEach((id: number) => {
      this.companyService.getColleague(id)
        .subscribe((colleague: Colleague) => {
          this.relativeColleagues.push(colleague);
        })
    })
  }

  getRelativeParameters(experiment: Experiment): Array<any> {
    const experimentAnswers = experiment.experimentAnswers;

    if (experimentAnswers.length === 0) {
      return;
    }

    const relativeParameters = [];
    const colleagueIds = [];

    experimentAnswers.forEach((experimentAnswer: ExperimentAnswer) => {
      const relativeParametersLength = experimentAnswer.relativeParameters.length;

      if (relativeParametersLength > 0) {
        experimentAnswer.relativeParameters.forEach((parameter: RelativeParameter) => {
          if (parameter.relativeColleagueId === this.colleagueId) {
            relativeParameters.push(parameter)
            colleagueIds.push(parameter.colleagueId);
          }
        })
      }
    })

    return relativeParameters;
  }

  formSingleExperimentData(experiment: Experiment, pack: Package): ColleagueTable {
    if (!experiment) {
      const newTable = this.addNoDataTable(pack, 'No available questionnaire', false);
      return newTable;
    }

    if (experiment.anonymous) {
      return;
    }

    const timeFormat = 'DD MMMM YYYY';
    const formattedDate = moment(experiment.finishDate).locale(this.currentLanguage).tz(moment.tz.guess()).format(timeFormat);
    const colleagueAnswers = experiment.experimentAnswers.filter(answer => answer.colleagueId === this.colleagueId && answer.calculated);
    const relativeParameters = this.getRelativeParameters(experiment);
    const cTableAnswers: ColleagueTableAnswer[] = [];

    if (colleagueAnswers.length === 0 && relativeParameters.length === 0) {
      const newTable = this.addNoDataTable(pack, 'No colleague answers', false, formattedDate);
      return newTable;
    }

    for (const singleAnswer of colleagueAnswers) {
      let tableParameters = this.formIndividualParameters(singleAnswer);
      if (tableParameters === undefined) {
        continue;
      }
      tableParameters = tableParameters.filter(param => param.onColleaguePage)

      const dataSource = new MatTableDataSource<ColleagueTableParameter>(tableParameters);
      const tableAnswer: ColleagueTableAnswer = {
        dataSource: dataSource,
        colleagueAnswerId: singleAnswer.id,
      };

      cTableAnswers.push(tableAnswer);
    }

    const relativeParams = this.formRelativeParameters(relativeParameters).filter(param => param.onColleaguePage);
    if (relativeParameters.length > 0) {
      const dataSource = new MatTableDataSource<ColleagueTableParameter>(relativeParams);
      const tableAnswer: ColleagueTableAnswer = {
        dataSource: dataSource,
        colleagueAnswerId: 0,
      };

      cTableAnswers.push(tableAnswer);
    }

    if (cTableAnswers.length === 0) {
      const newTable = this.addNoDataTable(pack, 'No colleague answers', false, formattedDate);
      return newTable;
    }

    const newTable: ColleagueTable = {
      tableName: pack.name,
      isEnabled: true,
      lastExperimentTime: formattedDate,
      hasData: true,
      packageId: pack.id,
      tableAnswers: cTableAnswers,
      isVisible: pack.colleagueTableConfig.isVisible,
      tableConfigId: pack.colleagueTableConfig.id,
      orderPosition: pack.colleagueTableConfig.orderPosition
    };

    return newTable;
  }

  formRelativeParameters(relativeParameters: RelativeParameter[]): any {
    const tableParameters: ColleagueTableParameter[] = [];
    const existParameters = [];

    relativeParameters.forEach((parameter: RelativeParameter) => {
      const packageParam = this.packageParameters.find(param => param.id === parameter.packageParameterId);

      if (packageParam) {
        const isParameterExist = existParameters.find(param => param === packageParam.id);

        if (isParameterExist === undefined) {
          const parameterVisibility = this.parameterVisibilities.find(param => param.packageParameterId == packageParam.id);
          const valueType = packageParam.commonValue.type;
          const parameterName = packageParam.visibleName;
          const onColleagueCard = parameterVisibility.onColleagueCard;
          const onColleaguePage = parameterVisibility.onColleaguePage;
          const packageParameterId = packageParam.id;
          let parameterValue =''

          relativeParameters.forEach((param) => {
            if (parameter.packageParameterId === param.packageParameterId) {
              const author = this.relativeColleagues.find(colleague => colleague.id === param.colleagueId);
              const authorData = '<span class="parameter-author">' + author.firstName + ' ' + author.lastName + ':</span> ';
              if (valueType === 'text' && param.textValue !== '' && param.textValue !== '-') {
                parameterValue += authorData + param.textValue.replace(/(?:\r\n|\r|\n)/g, '<br>') + '<br>'; // replace \n to <br>
              } else if (valueType === 'number') {
                parameterValue += authorData + param.value.toFixed(2) + '<br>';
              }
            }
          })

          const tableParam: ColleagueTableParameter = {
            parameterName: parameterName,
            parameterValue: parameterValue,
            onColleagueCard: onColleagueCard,
            onColleaguePage: onColleaguePage,
            packageParameterId: packageParameterId
          };

          existParameters.push(packageParam.id);
          tableParameters.push(tableParam);
        }
      }
    })

    return tableParameters;
  }

  formIndividualParameters(experimentAnswer: ExperimentAnswer): ColleagueTableParameter[] {
    if (!experimentAnswer) {
      return;
    }

    const tableParameters: ColleagueTableParameter[] = [];

    const colleagueParameters = experimentAnswer.colleagueParameters;
    if (colleagueParameters.length === 0) {
      return;
    }

    for (const singleParameter of colleagueParameters) {
      if (singleParameter.value === 0 && singleParameter.isNullValue) {
        continue;
      }
      const packageParam = this.packageParameters.find(param => param.id === singleParameter.packageParameterId);

      if (packageParam) {
        const parameterVisibility = this.parameterVisibilities.find(param => param.packageParameterId == packageParam.id);
        const valueType = packageParam.commonValue.type;
        const parameterName = packageParam.visibleName;
        const onColleagueCard = parameterVisibility.onColleagueCard;
        const onColleaguePage = parameterVisibility.onColleaguePage;
        const packageParameterId = packageParam.id;
        let parameterValue;

        if (valueType === 'text') {
          parameterValue = singleParameter.textValue.replace(/(?:\r\n|\r|\n)/g, '<br>'); // replace \n to <br>

          if (singleParameter.textValue === '') {
            parameterValue = '-';
          }
        } else {
          parameterValue = singleParameter.value.toFixed(2);
        }

        const tableParam: ColleagueTableParameter = {
          parameterName: parameterName,
          parameterValue: parameterValue,
          onColleagueCard: onColleagueCard,
          onColleaguePage: onColleaguePage,
          packageParameterId: packageParameterId
        };

        tableParameters.push(tableParam);
      }
    }

    if (tableParameters.length === 0) {
      return;
    }

    return tableParameters;
  }

  addNoDataTable(pack: Package, message: string, isEnabled: boolean, finishDate: string = ''): ColleagueTable {
    const tableParameters: ColleagueTableParameter[] = [];
    const tableParam: ColleagueTableParameter = {
      parameterName: message,
      parameterValue: '',
      onColleagueCard: false,
      onColleaguePage: false,
      packageParameterId: 0
    };
    tableParameters.push(tableParam);

    const dataSource = new MatTableDataSource<ColleagueTableParameter>(tableParameters);

    const tableAnswers = [];
    const newTableAnswer: ColleagueTableAnswer = {
      dataSource: dataSource,
      colleagueAnswerId: 0,
    };
    tableAnswers.push(newTableAnswer);

    const tableConfig = pack.colleagueTableConfig;
    const packageName = pack.name;

    const newTable: ColleagueTable = {
      tableName: packageName,
      isEnabled: isEnabled,
      lastExperimentTime: finishDate,
      hasData: false,
      packageId: pack.id,
      tableAnswers: tableAnswers,
      isVisible: tableConfig.isVisible,
      tableConfigId: tableConfig.id,
      orderPosition: tableConfig.orderPosition
    };

    return newTable;
  }

  onGeneratePublicLink(): void {
    this.companyService.updatePublicLink(this.colleagueId)
      .subscribe((colleague: Colleague) => {
        this.sharedColleagueService.addColleague(colleague);
        this.formColleagueBriefData(colleague);

        let messageLinkGenerated= 'Public link has been generated';
        if (this.currentLanguage === 'ru') {
          messageLinkGenerated = 'Ссылка сгенерирована';
        }
        this.openSnackBar(messageLinkGenerated);
      })
  }

  afterLinkCopied(): void {
    let messageLinkCopied = 'Link copied';
    if (this.currentLanguage === 'ru') {
      messageLinkCopied = 'Ссылка скопирована';
    }
    this.openSnackBar(messageLinkCopied);
  }

  openSnackBar(message: string): void {
    if (message.length > 0) {
      this.snackBar.open(message);
    }
  }

  setSelectedTabId(): void {
    if (this.router.url.indexOf('/data') > -1) {
      this.selectedTabId = 0;
    } else if (this.router.url.indexOf('/notes') > -1) {
      this.selectedTabId = 1;
    } else if (this.router.url.indexOf('/edit') > -1) {
      this.selectedTabId = 2;
    } else if (this.router.url.indexOf('/parameters') > -1) {
      this.selectedTabId = 3;
    }
  }

  changeUrl(tabValue: any): void {
    this.selectedTabId = tabValue;
    if (tabValue === 0) {
      this.router.navigateByUrl(this.profileUrl + '/data');
    } else if (tabValue === 1) {
      this.router.navigateByUrl(this.profileUrl + '/notes');
    } else if (tabValue === 2) {
      this.router.navigateByUrl(this.profileUrl + '/edit');
    } else if (tabValue === 3) {
      this.router.navigateByUrl(this.profileUrl + '/parameters');
    }

  }

  sortPackages(packList: Package[], arrayOrder: number[]): void {
    packList.sort((a, b) => arrayOrder.indexOf(a.id) - arrayOrder.indexOf(b.id));
  }

  checkProfilePublicity(): void {
    const colleaguePublicLink = this.route.snapshot.params.public_link;
    if (colleaguePublicLink !== undefined) {
      this.isPublicProfile = true;
    }
  }

  setProfileUrl(): void {
    if (this.colleaguePublicLink === undefined) {
      this.profileUrl = `/colleagues/${this.colleagueId}`;
    } else {
      this.profileUrl = `/colleague/${this.colleaguePublicLink}`;
    }
  }

  onBackClick(): void {
    this.router.navigateByUrl('/colleagues');
  }
}
