import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentUser } from '../_models/current-user';

@Injectable()
export class SharedService {
  public showThumbnails: BehaviorSubject<boolean>;
  public hasCalculator: BehaviorSubject<boolean>;
  public scaleFeatureEnabled: BehaviorSubject<boolean>;

  public currentLang: BehaviorSubject<string>;
  public currentCompanyId: BehaviorSubject<number>;
  public currentTabId: BehaviorSubject<number>;
  public currentStandardSetTabId: BehaviorSubject<number>;

  public currentUser: CurrentUser;
  public onLoginEmitted: boolean = false;
  public questionState$:  boolean = false;

  public onLogin$: EventEmitter<boolean>;
  public onLogout$: EventEmitter<boolean>;
  public notFoundMode$: EventEmitter<boolean>;
  public demoMode$: EventEmitter<boolean>;
  public loginMode$: EventEmitter<boolean>;
  public editQuestionMode$: EventEmitter<boolean>;
  public editPQActionMode$: EventEmitter<boolean>;
  public onRefreshUserInfo$: EventEmitter<boolean>;
  public onChangeCurrentCompany$: EventEmitter<boolean>;
  public onFilesUpload$: EventEmitter<boolean>;
  public sendFileForUploading$: EventEmitter<boolean>;
  public afterFilesUpload$: EventEmitter<number>;
  public toggledParticipantId$: EventEmitter<number>;
  public onDashboardRefresh$: EventEmitter<Array<number>>;

  constructor() {
    this.currentUser = new CurrentUser();

    this.showThumbnails = new BehaviorSubject(false);
    this.hasCalculator = new BehaviorSubject(false);
    this.scaleFeatureEnabled = new BehaviorSubject(false);
    this.currentLang = new BehaviorSubject('');
    this.currentCompanyId = new BehaviorSubject(0);
    this.currentTabId = new BehaviorSubject(0);
    this.currentStandardSetTabId = new BehaviorSubject(0);

    this.onLogin$ = new EventEmitter();
    this.onLogout$ = new EventEmitter();
    this.demoMode$ = new EventEmitter();
    this.notFoundMode$ = new EventEmitter();
    this.loginMode$ = new EventEmitter();
    this.editQuestionMode$ = new EventEmitter();
    this.editPQActionMode$ = new EventEmitter();
    this.onFilesUpload$ = new EventEmitter();
    this.sendFileForUploading$ = new EventEmitter();
    this.afterFilesUpload$ = new EventEmitter();
    this.onRefreshUserInfo$ = new EventEmitter();
    this.onChangeCurrentCompany$ = new EventEmitter();
    this.toggledParticipantId$ = new EventEmitter();
    this.onDashboardRefresh$ = new EventEmitter();
  }

  public clearSharedService(): void {
    this.currentUser = null;
    this.showThumbnails.next(null);
    this.hasCalculator.next(null);
    this.scaleFeatureEnabled.next(null);
    this.currentLang.next(null);
    this.currentCompanyId.next(null);
    this.currentTabId.next(null);
    this.currentStandardSetTabId.next(null);
  }

  public checkSignatureExpired(error: any): void {
    if (error._body) {
      const error_object = JSON.parse(error._body);
      if (error_object.message) {
        const token1 = error_object.message.match(/Invalid token/g);
        const token2 = error_object.message.match(/Signature has expired/g);
        if (token1 || token2) {
          // this.refreshToken();
          // localStorage.removeItem('currentUser');
          // this.onLogout$.emit();
        } else {
          console.log('Unknown error ', error);
        }
      } else {
        console.log('No error message received');
      }
    }
  }

  public onLogin(email?: string): void {
    // console.log('ON LOGIN EMAIL ', email);
    this.onLogin$.emit();
    this.onLoginEmitted = true;
  }
}
